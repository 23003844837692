<template>
    <div class="categorysPage" 
        :style="{height:height>0?(height+'px'):'',overflow:isMore?'inherit':'',zIndex:isMore?'2':''}">
        <layout-rc ref="layout" class="nav-box" :isLock="isMore" :isVarCss="true" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :style="{overflow:isMore?'inherit':''}">
            <div class="nav-li verticalCenter nowrap nav-first" :class="{selected:selectIndex==0}" @click="onNavLi()"><span :class="{up:isMore}">{{navFirstName}}</span></div>
            <div class="nav-li verticalCenter " :class="{selected:selectIndex==pageStartIndex+index+1}" v-for="(item,index) in categorysPages" :key="item" @click="onNavLi(item,pageStartIndex+index+1)">
                <div class="nowrap"><slot :item="item"></slot></div>
            </div>
            <div class="page-box" v-if="categorysPagesCount>1">
                <page-turning class="page-botton" v-model="pIndex" :pageCount="categorysPagesCount" type="up"><i class="iconfont icon-a-order-up"></i></page-turning>
                <page-turning class="page-botton" v-model="pIndex" :pageCount="categorysPagesCount"><i class="iconfont icon-a-order-down"></i></page-turning>
            </div>
        </layout-rc>
    </div>
</template>

<script>
//分类分页 显示
export default {
    name:"categorysPage",
    emits:["navClick"],
    props:{
        /**行 */
        row:{
            type:Number,
            default:0
        },
        /**列 */
        col:{
            type:Number,
            default:0
        },
        data:{//分类数据
            type:Array,
            default:null
        },
        navFirstName:{//首行第一个分类显示的名称
            type:String,
            default:"全部类"
        },
        pageIndex:{
            type:Number,
            default:0
        },
        selectIndex:{
            type:Number,
            default:0
        },
        bottomH:{//展会距离底部多少
            type:[Number,Function],
            default:470
        }
    },
    data(){
        return {
            //分类分页 当前页码 
            pIndex:1,
            //是否展现更多
            isMore:false,
            maxRow:0,
            height:0,
            //一行高
            rowH:0
        }
    },
    computed:{
        arg(){
            let arg={
                row:this.row,
                col:this.col
            }
            
            if(this.isMore){
                let size=arg.col*this.maxRow;
                let num=size-(this.data.length+1);
                arg.row=this.maxRow;
                if(num>=arg.col){
                    arg.row=this.maxRow-parseInt(num/arg.col);
                }
                if(arg.row<this.row){
                    arg.row=this.row;
                }
            }
            return arg;
        },
        /**分类分页显示 */
        categorysPages(){
            let size=this.arg.row*this.arg.col-1;
            if(this.categorysPagesCount>1){
                size=this.arg.row*this.arg.col-2;
            }
            return this.data?.slice(this.pageStartIndex,this.pIndex*size);
        },
        /** 分类分页 开始位置*/
        pageStartIndex(){
            let size=this.arg.row*this.arg.col-2;
            let startIndex=(this.pIndex-1)*size;
            return startIndex;
        },
        /**分类分页 总页数 */
        categorysPagesCount(){
            let size=this.arg.row*this.arg.col;
            let num=1;
            if(this.data?.length+1>size){
                size=size-2;
                num=Math.ceil(this.data.length/size);
            }
            return num;
        },
    },
    watch:{
        isMore(){
            this.shrink();
        },
        pageIndex(){
            this.pIndex=this.pageIndex;
        },
        categorysPagesCount(){
            if(this.categorysPagesCount<this.pIndex){
                this.pIndex=1;
            }
        }
    },
    mounted(){
        window.addEventListener("click",this.blur);
    },
    updated(){
        if(this.isMore){
            let row=Math.ceil((this.categorysPages.length+1)/this.arg.col);
            if(this.categorysPagesCount>1){
                row=Math.ceil((this.categorysPages.length+2)/this.arg.col);
            }
            if(this.row<row){
                let h=this.rowH;//单个高度
                this.height=h*row+8;
            }else{
                this.height=0;
            }
        }else{
            this.height=0;
        }
    },
    methods:{
        blur(e){
            if(e.path && e.path.find(dom=>dom==this.$el)){return;}
            else if(e.target!=window && this.$el.contains(e.target)){return;}
            if(this.isMore){
                this.pIndex=1;
                this.isMore=false;
            }
            
        },
        onNavLi(item,index){
            if(item && this.isMore){
                let size=this.col*this.row;
                if(this.data?.length+1>size){
                    this.pIndex=Math.ceil(index/(size-2));
                }
            }
            if(item==undefined){
                this.isMore=!this.isMore;
                this.pIndex=1;
            }else{
                this.isMore=false;
            }
            this.$emit("navClick",item,index);
        },
        shrink(){
            if(this.isMore){
                let h=this.$refs.layout.$el.offsetHeight/this.row;//单个高度
                this.rowH=h;
                let elRect= this.$el.getBoundingClientRect();
                let winH= window.innerHeight;
                let bottomH=this.bottomH;
                if(typeof(this.bottomH)=="function"){
                    bottomH=this.bottomH();
                }
                let row=parseInt(((winH-bottomH)-elRect.top-elRect.height)/h);
                if(row>0){
                    this.pIndex=1;
                    this.maxRow=row+ this.row;
                }
            }else{
                this.maxRow=0;
            }
        }
    },
    beforeUnmount(){
        window.removeEventListener("click",this.blur);
    }
}
</script>

<style lang="scss">
.categorysPage{
    position: relative;
    
    --rcWidth:117px;
    --rcHeight:34px;
    

    .nav-box{
        flex:1;
        overflow:hidden;
        position: relative;

        .nav-li{
            float: left;
            width: var(--rcWidth);
            height: var(--rcHeight);
            line-height:  var(--rcHeight);
            font-size: var(--fontSize);
            font-family: var(--fontFamily);
            background: var(--bgColor);
            text-align: center;
            margin: 1px;
            color: var(--fontColor);
            position: relative;

            &.selected{
                background: #487BB1;
                color: #FFFFFF;
            }
        }

        .nav-first{

            &.selected{
                span{
                    &::after{
                        border-color: transparent transparent #fff transparent;
                    }

                    &.up{
                        &::after{
                            border-color: #fff transparent transparent transparent;
                        }
                    }
                }
            }

            span{

                &.up{
                    &::after{
                        top: 8px;
                        border-color: #000000 transparent transparent transparent;
                    }
                }

                position: relative;
                &::after{
                    content: "";
                    display: block;
                    border: 6px solid #000000;
                    border-color:transparent transparent #000000 transparent;
                    position: absolute;
                    top: 1px;
                    left: -16px;
                }
            }
        }

        .page-box{
            position: absolute;
            right:1px;
            bottom: 1px;
            width: var(--rcWidth);
            height: var(--rcHeight);
            font-size: var(--fontSize);
            display: flex;

            .page-botton{
                width: 100%;
                height: 100%;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2rem;
                overflow: hidden;
                margin-right: 1px;
                background: var(--bgColor);
                color: var(--fontColor);
                cursor: pointer;

                &:last-child{
                    margin-left: 1px;
                    margin-right: 0px;
                }
            }
        }
    }
}
</style>