<template>
    <modal class="userOrderModel" :isShow="isShow" >
        <div class="header">
            我的订单<span class="num">({{orderInfo?.Eat_TotalCounts||0}}份)</span>
            <div class="close-bnt" @click="hide()"><i class="iconfont icon-guanbi"></i></div>
        </div>
        <div class="modal-body">
            <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                <div class="category-box" v-for="category in categoryList" :key="category">
                    <div class="title">{{category.SubCategory_Name}}（{{category.count}}份）</div>
                    <div class="tr-li" v-for="item in category.PosFoods" :key="item" >
                        <div class="item-box">
                            <div class="tao" v-if="item.Eat_TcItemList?.length>0">套</div>
                            <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.EAT_XFName}}</div>
                            <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                <span class="unit">￥</span>
                                <span class="price">{{item.Food_Price||item.Eat_CheckPrice}}</span>
                            </div>
                            <div class="number">x{{item.Eat_Number}}</div>
                        </div>
                        <div class="details">
                            <div v-if="item.Eat_MasName" @click="editDemand(item)">口味:{{item.Eat_MasName}}</div>
                            <div class="setMeal-box" v-if="item.Eat_TcItemList?.length>0">
                                <div class="li" v-for="(set, index) in item.Eat_TcItemList" :key="index">
                                    {{set.EAT_XFName}} x{{set.Eat_Number}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="footer-box">
            <div class="flex-box">
                <div class="money"><span>应付：</span><span class="unit">￥</span>{{priceFormat(orderInfo?.Payable_Money)||""}}</div>
                <div class="describe">共计{{orderInfo?.Eat_TotalCounts||0}}份
                    <span class="service-span" v-if="orderInfo?.Service_Money>0">含服务费:￥{{orderInfo?.Service_Money}}</span>
                </div>
            </div>
            <div class="btn btn-confirm" @click="hide()">继续点餐</div>
        </div>
    </modal>
</template>

<script>
/**购物车 */
export default {
    name:"shoppingCartModel",
    emits:["close"],
    props:{
        isShow:Boolean,
        /**订单数据 */
        orderInfo:{
            type:Object,
            default:null
        },
    },
    data(){
        return {
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        categoryList(){
            let list=[];
            let key={};
            this.orderInfo?.Order_ConsumeList?.forEach(it => {
                if(!key[it.SubCategory_ID]){
                    key[it.SubCategory_ID]={
                        SubCategory_ID:it.SubCategory_ID,
                        SubCategory_Code:it.SubCategory_Code,
                        SubCategory_Name:it.SubCategory_Name,
                        count:0,
                        PosFoods:[]
                    };
                    list.push(key[it.SubCategory_ID]);
                }
                key[it.SubCategory_ID].PosFoods.push(it);
                if(it.Food_Type!=4){//非口味做法
                    key[it.SubCategory_ID].count+=it.Eat_Number;
                }
                if(it.Eat_TcItemList?.length>0){//套餐
                    it.Eat_TcItemList.forEach(tc=>{
                        key[it.SubCategory_ID].count+=tc.Eat_Number;
                    })
                    
                }
            });
            list.forEach(it=>{
                it.count=parseFloat(it.count.toFixed(4));
            })
            return list;
        }
    },
    watch:{
        isShow(){
            if(this.isShow==true){
               this.init();
            }
        }
    },
    mounted(){
        
    },
    methods:{
        init(){
        },
        hide(){
            this.$emit("close");
        },
        /*单价格式 截取两位小数 */
        priceFormat( cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
    }
}
</script>

<style lang="scss">
    @import './userOrderModel.scss'
</style>