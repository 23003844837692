<template>
    <teleport to="body">
        <div class="keyBoardHang" :style="style" ref="keyBoardHang" :class="{selected:isEnable}" @mousedown="(e)=>e.preventDefault()" @click="click()">
            <i class="iconfont icon-jianpan"></i>
        </div>
    </teleport>
</template>

<script>
export default {
    name:"keyBoardHang",
    props:{
    },
    data(){
        return {
            //是否启用键盘
            isEnable:false,
            style:null
        }
    },
    mounted(){
        document.addEventListener("focusin",this.onfocus);

        let startLeft,startTop;
        let top=0,left=0;
        let mousedown=(e)=>{
            let rect=e.currentTarget.getBoundingClientRect();
            top=rect.top;
            left=rect.left;
            if(e.type=="mousedown"){
                startLeft=e.x;
                startTop=e.y;
                document.addEventListener("mousemove",mousemove);
                document.addEventListener("mouseup",mouseup);
            }else{
                if(e.targetTouches?.length>0){
                    document.addEventListener("touchmove",mousemove);
                    document.addEventListener("touchend",mouseup);
                    startLeft=e.targetTouches[0].pageX;
                    startTop=e.targetTouches[0].pageY;
                }
            }
        }
        let mousemove=(e)=>{
            let x=top,y=left;
            if(e.type=="mousemove"){
                x=left+e.x-startLeft;
                y=top+e.y-startTop;
            }else{
                if(e.targetTouches?.length>0){
                    x=left+e.targetTouches[0].pageX-startLeft;
                    y=top+e.targetTouches[0].pageY-startTop;
                }
            }
            let padding=5;
            if(x<padding){
                x=padding;
            }else if(x>window.innerWidth-55-padding){
                x=window.innerWidth-55-padding;
            }
            if(y<padding){
                y=padding;
            }else if(y>window.innerHeight-38-padding){
                y=window.innerHeight-38-padding;
            }
            this.style={
                left:x+"px",
                top:y+"px",
            }
        }
        let mouseup=(e)=>{
            if(e.type=="mouseup"){
                document.removeEventListener("mousemove",mousemove);
                document.removeEventListener("mouseup",mouseup);
            }else{
                document.removeEventListener("touchmove",mousemove);
                document.removeEventListener("touchend",mouseup);
            }
        }
        this.$refs.keyBoardHang.addEventListener("mousedown",mousedown)
        this.$refs.keyBoardHang.addEventListener("touchstart",mousedown)
    },
    methods:{
        //校验是否是合法输入框
        verify(input){
            let type=["text","number","tel","password"]
            if(input && (input.nodeName=="INPUT" && type.indexOf(input.type)>=0) || input.nodeName=="TEXTAREA"){
                if(!input.readOnly && !input.disabled){//不能是只读 或禁用
                    if(input.getAttribute("keyBoard")!=null){//允许 弹层键盘
                        return true;
                    }  
                }
            }
            return false;
        },
        //当获取焦点时
        onfocus(e){
            if(this.verify(e.target)){
                if(this.isEnable){//启用键盘时 获取到焦点则弹出键盘
                    this.showKeyBoard();
                }
            }
        },
        click(){
            this.isEnable=!this.isEnable;
            if(this.isEnable){
                this.showKeyBoard();
            }else{
                this.$keyBoard.close(document.activeElement);
            }
        },
        showKeyBoard(){
            let input=document.activeElement;
            if(input){
                if(this.verify(input)){
                    //启动虚拟键盘
                    this.$keyBoard.show(input,{isKeyDownEnter:true,isKeyUp:true,isNumber:true,type:"bottom"});
                    input.onblur=()=>{
                        if(document.activeElement!=input){
                            this.$keyBoard.close(input);
                        }
                    }
                    input.onkeyup=(e)=>{//点击确定 关闭键盘
                        if(e.key=="Enter"){
                            this.$keyBoard.close(input);
                            input.onkeyup=null;
                            input.onclick=(e)=>{
                                this.showKeyBoard();
                                input.onclick=null;
                            };
                        }
                    }
                }
            }
        },
        closeKeyBoard(){//关闭虚拟键盘
            this.$keyBoard.close();
        },
    },
    //被卸载时
    unmounted(){
        this.closeKeyBoard();
        document.removeEventListener("focusin",this.onfocus);
    }
}
</script>

<style lang="scss">
.keyBoardHang{
    position: fixed;
    left:58px;
    bottom: 10px;
    width: 55px;
    height: 38px;
    border-radius:5px;
    line-height: 40px;
    text-align: center;
    background-color: rgba(255,255,255,0.8);
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.5);
    .iconfont{
        font-size: 32px;
        color: #b1b1b1;
    }

    &.selected{
        box-shadow: 0 0 8px 2px #487BB1;

        .iconfont{
            color: #487BB1;
        }
    }
}
</style>