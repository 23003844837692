<template>
  <modal class="serviceFeeModel" :isShow="isShow" @keyup.esc="hide">
      <div class="header">服务费操作 </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="title">请选择服务费率</div>
                    <div class="tr-li">
                        <label class="checkbox"><input type="radio" name="_serviveFeeType" @click="FeeClick(false)"/><i class="iconfont icon-iconoption"></i></label>
                        按系统设置的服务费率
                    </div>
                    <div class="tr-li">
                        <label class="checkbox"><input type="radio" name="_serviveFeeType" @click="FeeClick(true)"/><i class="iconfont icon-iconoption"></i></label>
                        自定义(10%请输入10) &nbsp;
                        <div class="from-input" style="width:185px">
                            <input type="text" ref="input" data-model='per'  v-model="per"/>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">退出</button>
            <button class="btn btn-cancel" @click="cancelClick()">取消服务费</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
import { httpAES } from '/src/services'
import { ElMessage } from "element-plus"

export default {
    name:'serviceFeeModel',
    props:{
        isShow:Boolean,
        orderInfo:Object,
        unionType:Boolean,
    },
    data(){
        return{
            /**自定义(10%请输入10) */
            per:'',
            feeTypeNum:0,
            feeType:false,
            userInfo:'',
            orderCheckIDList:[]
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.input);
        })
    },
    methods:{
        FeeClick(type){
            this.feeTypeNum=1
            this.feeType=type
        },
        /**取消服务费 */
        cancelClick(){
            const loading = this.$loading({
                text: "服务费取消中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let EatAutoIDList=[]
            let orderIdList={Order_ID:this.orderInfo.Eat_AutoID,Timestamps:this.orderInfo.Timestamps}
             EatAutoIDList.push(orderIdList)
            /**联台 */
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.map(union=>{
                        orderIdList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps}
                        EatAutoIDList.push(orderIdList)
                    })
                }
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID, //登录用户ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                SeviceFeeRate:Number(this.per),  //自定义服务费率
                Is_CancelFee:true, //是否取消服务费
                Is_DiyFee:false, //是否自定义服务费率，默认取系统设置得服务费规则
                CheckOutOrders:EatAutoIDList
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            httpAES.post("Bestech.CloudPos.CalculateServiceFee", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    return;
                }else
                {
                    console.log('取消服务费返回:',d)
                    ElMessage.success({ message: "服务费取消成功"});
                    this.$emit("serviceFeeReturn",d,"cancel")
                    return;
                } 
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        confirm(){
            console.log('orderInfo:',this.orderInfo)
            // if(this.feeType==true){
            //     if(Number(this.per)> Number(this.orderInfo.UnPaid_Money-this.orderInfo.Service_Money)){
            //         this.$message.error('自定义服务费金额不能大于账单金额');
            //         return;
            //     }
            // }
            if(this.feeTypeNum==0){
                this.$message.error('请先选择对应的服务费操作');
                return;
            }
            const loading = this.$loading({
                text: "服务费计算中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let EatAutoIDList=[]
            let orderIdList={Order_ID:this.orderInfo.Eat_AutoID,Timestamps:this.orderInfo.Timestamps}
             EatAutoIDList.push(orderIdList)
            /**联台 */
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.map(union=>{
                        orderIdList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps}
                        EatAutoIDList.push(orderIdList)
                    })
                }
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID, //登录用户ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                SeviceFeeRate:Number(this.per),  //自定义服务费率
                Is_CancelFee:false, //是否取消服务费
                Is_DiyFee:this.feeType, //是否自定义服务费率，默认取系统设置得服务费规则
                CheckOutOrders:EatAutoIDList
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            httpAES.post("Bestech.CloudPos.CalculateServiceFee", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    return;
                }else
                {
                    console.log('服务费计算返回:'+JSON.stringify(d));
                    ElMessage.success({ message: "服务费计算完成"});
                    this.$emit("serviceFeeReturn",d,"compute")
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        }
    }
}
</script>

<style lang="scss">
@import './serviceFeeModel.scss';
</style>