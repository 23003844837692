import CryptoJS from "crypto-js"

export default {
	/*加密*/
	encrypt: function(content, key) {
		let keyBytes = CryptoJS.enc.Utf8.parse(key.substr(0, 32));
		let srcs = CryptoJS.enc.Utf8.parse(content);
		//加密
		let encryptedData = CryptoJS.AES.encrypt(srcs, keyBytes, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		return encryptedData.toString();
	},
	//解密
	decrypt: function(content, key) {
		let keyBytes = CryptoJS.enc.Utf8.parse(key.substr(0, 32));
		//解密
		let decryptedData = CryptoJS.AES.decrypt(content, keyBytes, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		return CryptoJS.enc.Utf8.stringify(decryptedData);
	}
}
