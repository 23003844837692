<template>
    <modal class="bankPosModel" :isShow="isShow" @keyup.esc="close">
        <div class="header">提示
            <span class="header-x" @click="close()">×</span>
        </div>
        <div class="modal-body clearfix">
            <div class="item-li" @click="bankPosCheckIn()">
                <p class="title">银行POS机签到</p>
            </div>
            <div class="item-li" @click="bankPosCheckout()">
                <p class="title">银行POS机结算</p>
            </div>
        </div>
    </modal>
</template>

<script>
//BankPos操作
export default {
    emits:["close"],
    props:{
        //是否显示
        isShow:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        close(){
            sessionStorage.removeItem("tempUserID");
            sessionStorage.removeItem("tempName");
            this.$emit("close");
        },
        //银行POS机签到
        bankPosCheckIn(){
            let userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name
            }
            if(sessionStorage.getItem("tempUserID")){//权限验证， 上一个权限
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
            }

            const loading = this.$loading({
                text: "请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log(param)
            this.$httpAES.post("Bestech.CouponPlatform.BankPosCheckIn",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: "签到成功"});
                    this.close();
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**银行POS机结算 */
        bankPosCheckout(){
            let userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name
            }
            if(sessionStorage.getItem("tempUserID")){//权限验证， 上一个权限
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
            }

            const loading = this.$loading({
                text: "请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log(param)
            this.$httpAES.post("Bestech.CouponPlatform.BankPosCheckout",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: "结算成功"});
                    this.close();
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        }
    }
}
</script>
<style lang="scss">
@import './bankPosModel.scss';
</style>