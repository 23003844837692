<template>
    <teleport to="body" v-if="isShow" >
        <div class="numberKeyBoard" @mousedown="mousedown" :style="style">
            <div class="keyboard">
                <div class="key-li"><span @click="keyClick(7)">7</span><span @click="keyClick(8)">8</span><span
                        @click="keyClick(9)">9</span></div>
                <div class="key-li"><span @click="keyClick(4)">4</span><span @click="keyClick(5)">5</span><span
                        @click="keyClick(6)">6</span></div>
                <div class="key-li"><span @click="keyClick(1)">1</span><span @click="keyClick(2)">2</span><span
                        @click="keyClick(3)">3</span></div>
                <div class="key-li"><span @click="keyClick('.')">.</span><span @click="keyClick(0)">0</span><span
                        @click="backClick(0)"><i class="iconfont icon-zuojiantou"></i></span></div>
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    name:"numberKeyBoard",
    data(){
        return {
            isShow:false,
            style:""
        }
    },
    emits:["change"],
    props:{
        input:{
            default:undefined
        }
    },
    watch:{
        input(){
            if(this.input){//显示
                this.isShow=true;
                this.show();
            }else{
                this.isShow=false;
            }
        }
    },
    methods:{
        show(){
            let rect=this.input.getBoundingClientRect();
            if(rect){
                let top=rect.y+rect.height;
                if(window.innerHeight<top+226){
                    top=rect.y-226;
                }
                let left=rect.x;
                if(window.innerWidth<left+215){
                    left=window.innerWidth-215;
                }
                this.style="position:fixed;top:"+top+"px;left:"+left+"px";
            }
        },
        mousedown(e){
            e.preventDefault();
        },
        keyClick(num){
            let value= this.input.value+num;
            this.$emit("change",value);
        },
        backClick(){
            if((this.input.value+"").length>0){
                let value= this.input.value.substr(0,(this.input.value+"").length-1);
                this.$emit("change",value);
            }
        }
    }
}
</script>

<style lang='scss'>
@import "./numberKeyBoard.scss";
</style>