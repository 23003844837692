import {
	createRouter,
	createWebHistory
} from 'vue-router'

import {
	auth
} from './services'
/**页面 开始**/
import login from '/src/components/login/login.vue'
import register from '/src/components/register/register.vue'
import home from '/src/components/home/home.vue'
import diningTable from '/src/components/home/diningTable/diningTable.vue';
import servingOrder from '/src/components/home/diningTable/servingOrder/servingOrder.vue';
import diningDetail from '/src/components/home/diningTable/diningDetail/diningDetail.vue';
import fastFoodDetail from '/src/components/home/diningTable/fastFoodDetail/fastFoodDetail.vue'
import bill from '/src/components/home/bill/bill.vue';
import printReport from '/src/components/home/printReport/printReport.vue';
import auxiliaryScreen from '/src/components/auxiliaryScreen/auxiliaryScreen.vue'

import domTable from '/src/components/template/tableVir/domTable.vue'
import ipdUserOrderMode from '/src/components/home/ipdUserOrderMode/ipdUserOrderMode.vue'
/**页面 结束**/

/** 导入组件 路由懒加载  
 * @route 相对home 的目录
 * @name  文件名称
 * 
 * 例如：'/src/components/home/bill/billDetail/billDetail.vue'
 * getComponent('bill','billDetail')
*/
const getComponent=(route,name)=>{
	if(route){
		return ()=> import('/src/components/home/'+route+'/'+name+'/'+name+'.vue');
	}
	return ()=> import('/src/components/home/'+name+'/'+name+'.vue');
}

//路由
const routes = [{ //默认登陆页面
		path: '/', 
		name: '',
		component: login
	},
	{
		path:'/test',
		name:'test',
		component:domTable//
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/register',
		name: 'register',
		component: register
	},
	{
		path:'/auxiliaryScreen',
		name:'auxiliaryScreen',
		component:auxiliaryScreen
	},
	{
		path: '/home/', //地址
		name: 'home',
		component: home,
		meta: {
			requiresAuth: true
		}, //需要验证身份才能进入
		children: [ //子路由
			{//点单桌台界面
				path: 'diningTable',
				name: 'diningTable',
				component: diningTable,
			},
			{//点单 点餐界面
				path: 'diningDetail',
				name: 'diningDetail',
				component: diningDetail
			},
			{//上桌 上桌顺序界面
				path:'servingOrder',
				name:'servingOrder',
				component: servingOrder
			},
			{//快餐 点餐界面
				path:'fastFoodDetail',
				name:'fastFoodDetail',
				component: fastFoodDetail
			},
			{//用户点餐界面
				path:'ipdUserOrderMode',
				name:'ipdUserOrderMode',
				component: ipdUserOrderMode
			},
			{//账单界面
				path: 'bill',
				name: 'bill',
				component: bill
			},
			//账单详情页
			{
				path: 'billDetail',
				name: 'billDetail',
				component: getComponent('bill','billDetail')
			},
			/********会员***********/
			{//会员界面
				path: 'member',
				name: 'member',
				component: getComponent('','member')
			},
			/********报表***********/
			{//报表界面
				path: 'report',
				name: 'report',
				component: getComponent('','report')
			},
			/********预订***********/
			{
				path:"cloudPosPre",
				name:"cloudPosPre",
				component: getComponent('','cloudPosPre')
			},
			{//客户管理
				path:"customManage",
				name:"customManage",
				component: getComponent('cloudPosPre','customManage')
			},
			{//客户管理 编辑
				path:"customManageEdit",
				name:"customManageEdit",
				component: getComponent('cloudPosPre/customManage','customManageEdit')
			},
			/********设置**********/
			{//设置界面
				path: 'setup',
				name: 'setup',
				component: getComponent('','setup')
			},
			//沽清 设置
			{
				path: 'sellOutSetUp',
				name: 'sellOutSetUp',
				component: getComponent('setup','sellOutSetUp')
			},
			{
				path:'saveSellOutMould',
				name: 'saveSellOutMould',
				component: getComponent('setup/sellOutSetUp','saveSellOutMould')
			},
			{//沽清 设置不自动取消估清
				path:'notAutoCancelSellOut',
				name: 'notAutoCancelSellOut',
				component: getComponent('setup/sellOutSetUp','notAutoCancelSellOut')
			},
			/**打印模板编辑  */
			{
				path:'eatKitPlaceSet',
				name: 'eatKitPlaceSet',
				component: getComponent('setup','eatKitPlaceSet')
			},
			/**站点配置 */
			{
				path:'siteSet',
				name: 'siteSet',
				component: getComponent('setup','siteSet')
			},
			/**打印模板设置列表 */
			{
				path:'printModuleSet',
				name: 'printModuleSet',
				component: getComponent('setup','printModuleSet')
			},
			/**打印模板编辑  */
			{
				path:'editPrintModuleSet',
				name: 'editPrintModuleSet',
				component: getComponent('setup/printModuleSet','editPrintModuleSet')
			},
			/**站点打印机分配 */
			{
				path:'posPrintSet',
				name: 'posPrintSet',
				component: getComponent('setup','posPrintSet')
			},
			/**海鲜标准重量 */
			{
				path:'DZC_StandNum',
				name: 'DZC_StandNum',
				component: getComponent('setup','DZC_StandNum')
			},
			/**线上打印机分配 */
			{
				path:'onlinePrintSet',
				name: 'onlinePrintSet',
				component: getComponent('setup','onlinePrintSet')
			},
			/**POS业务开关 */
			{
				path:'sysSet',
				name: 'sysSet',
				component: getComponent('setup','sysSet')
			},
			/**用户密码修改 */
			{
				path:'changePwd',
				name: 'changePwd',
				component: getComponent('setup','changePwd')
			},
			/**界面布局设置 */
			{
				path:'userFormDefined',
				name: 'userFormDefined',
				component: getComponent('setup','userFormDefined')
			},
			/**厨师信息设置 */
			{
				path:'saveKitNameSet',
				name: 'saveKitNameSet',
				component: getComponent('setup','saveKitNameSet')
			},
			/**账单标签设置 */
			{
				path:'g10POSTranData',
				name: 'g10POSTranData',
				component: getComponent('setup','g10POSTranData')
			},
			/**读卡点菜 */
			{
				path:'eatBindCard',
				name: 'eatBindCard',
				component: getComponent('setup','eatBindCard')
			},
			/**菜品上下架 */
			{
				path:'eatXfbmupdown',
				name: 'eatXfbmupdown',
				component: getComponent('setup','eatXfbmupdown')
			},
			/**时价菜设置 */
			{
				path:'eatXfbPiceUpdate',
				name: 'eatXfbPiceUpdate',
				component: getComponent('setup','eatXfbPiceUpdate')
			},
			/**时价菜设置 日志 */
			{
				path:"eatXfbPiceLog",
				name: 'eatXfbPiceLog',
				component: getComponent('setup/eatXfbPiceUpdate','eatXfbPiceLog')
			},
			/**主副菜关联设置 */
			{
				path:"eatXfbmReplaceRatio",
				name: 'eatXfbmReplaceRatio',
				component: getComponent('setup','eatXfbmReplaceRatio')
			},
			/**AR账单 应收账户处理*/
			{
				path:"corpARMoney",
				name: 'corpARMoney',
				component: getComponent('setup','corpARMoney')
			},
			/**AR账单 流水报表*/
			{
				path:"transactionReport",
				name: 'transactionReport',
				component: getComponent('setup/corpARMoney','transactionReport')
			},
			/**AR账单 应收账户处理详情*/
			{
				path:"corpARMoneyDetails",
				name: 'corpARMoneyDetails',
				component: getComponent('setup/corpARMoney','corpARMoneyDetails')
			},
			/**AR账单 应收账户 撤销*/
			{
				path:"chexiaoPayAccounts",
				name: 'chexiaoPayAccounts',
				component: getComponent('setup/corpARMoney/corpARMoneyDetails','chexiaoPayAccounts')
			},
			//打印机 报表
			{
				path:'printReport',
				name:'printReport',
				component:printReport
			},
			{
				path: '',
				component: diningTable
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
router.back=()=>{
	let state=router.options.history.state;
	if(state){
		router.removeRoute(state.current);
		router.push({path:state.back})
	}
}
router.beforeEach((to,from) => {
	//找不到地址时 默认跳入 home
	if(!to.matched || to.matched.length==0){
		return {path: '/home'}
	}
	//跳转地址时发生
	// 而不是去检查每条路由记录
	// to.matched.some(record => record.meta.requiresAuth)
	if (to.meta.requiresAuth && !auth.isLoggedIn()) {
		// 此路由需要授权，请检查是否已登录
		// 如果没有，则重定向到登录页面
		return {
			path: '/login',
			// 保存我们所在的位置，以便以后再来
			query: {
				redirect: to.fullPath
			},
		}
	}
})

document.addEventListener("visibilitychange",()=>{
	if(document.hidden){
		//this.$message.warning({ message: "用户名不能为空"});
	}else{
		let routerTo=router?.currentRoute?._value
		routerTo?.matched?.map((d)=>{
			if(d.instances){
				d.instances?.default?.$forceUpdate();
			}
		})
	}
})

export default router
