import axios from 'axios'
import { aesUtil,config,newGuid} from '../common'
import CryptoJS from "crypto-js"
import pako from 'pako'
import cacheData from './cacheData'
import {global,auth} from './index.js'
import { requestWait } from '../components/template/requestWaitModal/index.js'

//const key='28B4E40E54D04EDD8BC7892289A7E3C6';
//const url='192.168.1.2:8080'
//加密
function encryptAes(method,data){
	let utc=parseInt((new Date()).getTime()/1000);
	let Access_Key=data.Access_Key||config.Access_Key||'';
	let secret=data.Secret_key||config.Secret_key||'';
	let Version='1.0';
	let Msg_ID=newGuid();
	let request={
		RequestHeader:{//头部信息不加密
			Action_Name:method,
			//消息类型
			Msg_Type:config.Msg_Type||'',
			//消息id
			Msg_ID:Msg_ID,
			//设备id
			Site_ID:data.Site_ID||config.UUID,
			/**设备硬件ID */
			Device_ID:data.Device_ID||global?.devices?.uuid,
			//功能模块
			//Function_Module:config.Function_Module||"",
			Function_Module:801,
			//商户号
			Merchant_Code:'',
			//接口访问Key
			Access_Key:Access_Key,
			Version:Version,
			//请求时间戳
			RequestTimestamp:utc,
			//开发者id
			DeveloperID:config.DeveloperID||'',
			//预留信息
			Msg_Reserve:'',
			//接口签名算法
			Algorithm:'MD5',
			//签名
			Sign:CryptoJS.MD5(method+Msg_ID+Access_Key+Version+utc+secret).toString()
		},
		//主体信息加密
		RequestBody: aesUtil.encrypt(JSON.stringify(data),secret)
	}
	return request;
}
//解密
function decryptAes(response,secret){
	if(!response){throw {message:"请求返回数据错误"};}
	if(response.status!=200){throw {message:"网络错误："+response.status+","+response.statusText};}
	if(!response.data){throw {message:"请求返回数据错误"};}
	try {
		let body=response.data.ResponseBody;
		if(body!=undefined && body!=""){
			//解密
			let data = aesUtil.decrypt(body,secret);
			//是否需要解压
			if(response.data.ResponseHeader && response.data.ResponseHeader.UncompressedData===false){
				let strData = atob(data);
				let charData = strData.split('').map(function(x){return x.charCodeAt(0);});
				let binData  = new Uint8Array(charData);
				data=pako.ungzip(binData, { to: 'string'} ); 
			}
			response.data.ResponseBody=JSON.parse(data);
		}
	} catch (error) {
		console.log(error);
	}
	return response.data;
}
//获取token
function getToken(data){
	let token= cacheData.get("token");
	//token.expired_in 比较 token 时间是否超时
	if(token && token.access_token && token.expired_in>=parseInt(new Date().getTime()/1000)){
		return new Promise(resolve => {
			resolve(token);
		});
	}
	let methodName="Bestech.CloudPos.GetToken";
	let url= data.OpenAPIUrl || config.OpenAPIUrl;
	if(!data.Body_ID){
		data.Body_ID=newGuid();
	}
	let httpParams={
		url:url+'/api/BestechCloudPos/Token',
		headers:{//跨域请求
			'Content-Type': 'application/json',
		},
		//请求返回类型 json
		ResponseType:'json',
		//请求超时时间20秒
		timeout:20000,
		//超时提示信息
		timeoutErrorMessage:'请求超时，请稍后再试或检查当前网络状态'
	}
	httpParams.method='POST';
	httpParams.data=encryptAes(methodName,data);
	httpParams.data.RequestBody=undefined;
	let secret=data.Secret_key||config.Secret_key||'';
	return axios.request(httpParams).then((response)=>{
		let d= decryptAes(response,secret);
		if(d && d.ResponseHeader && d.ResponseHeader.ResultCode==0){
            let data=d.ResponseBody
            cacheData.set("token",data); 
            return data;
        }else{
            throw {message:d.ResponseHeader.ResultDesc};
        }
	}).catch(e=>{
		if(e.message=="Network Error"){
			e.message="连接不到服务器了,请检查当前网络状态";
		}
		throw e;
	});
}
//ajax请求 
async function http(modeType,methodName,data,requestConfig){
	let url= data.OpenAPIUrl || config.OpenAPIUrl;
	if(!data.Body_ID){
		data.Body_ID=newGuid();
	}
	if(!data.User_ID){//用户id
		data.User_ID=auth.getUserID();
	}
	
	let tokenJson=await getToken(data);
	let token=tokenJson.access_token;
	let httpParams={
		url:url+'/api/BestechCloudPos/OpenAction',
		headers:{//跨域请求
			'Content-Type': 'application/json',
			'Authorization':'Bearer ' + token 
		},
		//请求返回类型 json
		ResponseType:'json',
		//请求超时时间30秒
		timeout:30*1000,
		//超时提示信息
		timeoutErrorMessage:'请求超时，请稍后再试或检查当前网络状态'
	}
	//自定义请求配置
	if(requestConfig){
		Object.assign(httpParams,requestConfig);
	}
	if(modeType=='get'){
		httpParams.method='GET';
		httpParams.params=encryptAes(methodName,data);
	}else{
		httpParams.method='POST';
		httpParams.data=encryptAes(methodName,data);
	}
	let secret=data.Secret_key||config.Secret_key||'';
	
	if(methodName=='Bestech.CloudPos.GetMqParams'){
		secret=CryptoJS.MD5(secret+"BusinessCMSDB").toString().toUpperCase()
	}
	
	let httpNum=0;
	let networkNull=0;//记录当前接口发送请求时连接不到服务器的次数 
	let httpRequest=async ()=>{
		httpNum++;
		let isNetworkError=false;
		let response=await axios.request(httpParams).catch((e)=>{
			if(e.message=="Network Error"){
				if(e.response){
					console.log(e.message,e.response);
					e.message="with status code "+e.response.status+" 连接不到服务器了,请检查当前网络状态";
				}else{
					console.log(e.message);
					e.message="连接不到服务器了,请检查当前网络状态";
				}
				
				networkNull++;
				if(networkNull<4){
					isNetworkError=true;
					return;
				}
			}
			throw e;
		});
		if(isNetworkError){//请求报 连接不上网络时 重新发一次请求
			//重新发起请求
			return await new Promise((resolve,reject) => {
				setTimeout(()=>{
					console.log(httpParams,"连接不到服务器,第"+networkNull+"次重新发送请求")
					httpRequest().then(resolve).catch(reject);
				},500);
			});
		}
		
		//只能重新请求2次
		if(httpNum<3 && response.data && response.data.ResponseHeader && response.data.ResponseHeader.ResultCode==401 && response.data.ResponseHeader.Action_Name=="Bestech.CloudPos.GetToken"){
			//token 失效 或 过期 重新获取
			cacheData.create("token");
			tokenJson=await getToken(data);
			token=tokenJson.access_token;
			httpParams.headers.Authorization='Bearer ' + token ;
			//重新发起请求
			response=await httpRequest();
		}
		return response;
	}

	let response=await httpRequest();
	
	return new Promise(resolve => {
		resolve(decryptAes(response,secret))
	})
}

let permissionFun;
//验证权限
function httpAuthority(modeType,methodName,data,requestConfig){
	return new Promise((resolve,reject) => {
		http('post',methodName,data,requestConfig).then(d=>{
			if(!data?.btntype){
				if(d.ResponseHeader.ResultCode==3003 && typeof(permissionFun)=="function"){//没有对应功能的权限
					let loading=document.querySelectorAll(".el-loading-mask.is-fullscreen");
					if(loading){
						loading.forEach((dom)=>{
							dom.style.display="none";
						})
					}
					permissionFun(d,(info)=>{//更换用户id 后  重新发送请求
						data.User_ID=info.User_ID;
						if(data.Operator_Name!=undefined || data.Operator_Name==null){
							data.Operator_Name=info.Login_Name;
						}
						httpAuthority(modeType,methodName,data,requestConfig).then(data=>{
							resolve(data);
						}).catch((d)=>{
							reject(d);
						})
					},()=>{
						//resolve(d)
					});
				}else{
					resolve(d);
				}
			}else{
				resolve(d);
			}
		}).catch(e=>{
			reject(e);
		})
	})
}
function urlParam(data){
	let param=[];
	for(let key in data){
		if(data[key]!=null && data[key]!=undefined && data[key]!=""){
			param.push(key+"="+data[key]);
		}
	}
	return param.join("&");
}
/*saas公共请求接口 用于登陆获取用户及商户*/
function tradeManageHttp(modeType,methodName, data){
	var utc=parseInt((new Date()).getTime()/1000);
	var params={
		method:methodName,//方法
		appkey:'D4BDF7F267CE4499BAEB9EB78477EE06',
		version:"1.0",//版本
		timestamp:utc,
		developerid:"test",
		sign:CryptoJS.MD5(("Bestech168").toLocaleUpperCase()+utc).toString().toLocaleUpperCase()		
	};
	
	let httpParams={//https://bestech-gateway.gmonline-all.com/InvApi/DO
		url:"https://bestech-gateway.gmonline-all.com/InvApi/DO",
		header:{//跨域请求
			'content-type': 'application/x-www-form-urlencoded',
		},
		//请求返回类型 json
		dataType:'json',
		//请求超时时间20秒
		timeout:20000,
		//不 验证 ssl 证书
		sslVerify:false
	}
		
	if(modeType=='get'){
		httpParams.method='GET';
		httpParams.url+="?"+urlParam(params);
	}else{
		httpParams.method='POST';
		httpParams.data=data;
		httpParams.url+="?"+urlParam(params);
	}
	
	return axios.request(httpParams).then(response=>{
		if(!response){throw {message:"请求返回数据错误"};}
		if(response.status!=200){throw {message:"网络错误："+response.status+","+response.statusText};}
		if(!response.data){throw {message:"请求返回数据错误"};}
		return response.data;
	}).then(d=>{
		if(d.Status==0 && d.BizData?.Value){
			return JSON.parse(d.BizData.Value);
		}
		return d;
	}).catch(e=>{
		if(typeof(e)=="string"){
			e={errMsg:e};
		}
		if(e.errMsg=="Network Error" || e.errMsg=="request:fail"){
			e.errMsg="连接不到服务器了,请检查当前网络状态";
		} else if(e.errMsg=="request:fail timeout"){ 
			e.errMsg="请求超时,请检查当前网络状态";
		} 
		throw e;
	});
}

const methodRes4001=["Bestech.CloudPos.RefundPayment","Bestech.CloudPos.RefundBill","Bestech.CloudPos.RedBill"]
/**
 * 当接口返回ResultCode=4001时，
 * 表示某些接口正在处理中，这时前端不能断开操作，
 * 建议开启一个60秒的轮询提交，继续调用此接口，5秒一次。
 * 结束后才能退出操作
 */
function postResult4001(postHttp,methodName,data){
	return new Promise((resolve,reject) => {
		postHttp('post',methodName,data).then(d=>{
			if(d.ResponseHeader.ResultCode==4001){
				let isLoing=false;//是否还在请求中
				let errorData=d;//返回的错位结果
				let waitDom=requestWait.show(d.ResponseHeader.ResultDesc,()=>{//60 秒倒计时结束
					if(!isLoing){//没有发起请求 直接结束
						waitDom.hide();
						resolve(errorData);
					}else{//还在请求 直接等待

					}
				});
				const postResult=()=>{
					isLoing=true;
					http('post',methodName,data).then(d=>{
						isLoing=false;
						if(d.ResponseHeader.ResultCode==4001){
							errorData=d;//错误提示
							waitDom.title=d.ResponseHeader.ResultDesc;//错误提示
							if(waitDom.timerCount<=0){//倒计时结束了
								waitDom?.hide();
								resolve(errorData);
								return;
							}
							if(time){
								clearTimeout(time);
							}
							time=setTimeout(postResult,5*1000);//间隔5秒后继续请求
							
						}else{
							waitDom.hide();//成功后关闭弹层
							resolve(d);
						}
					}).catch(e=>{
						isLoing=false;
						if(waitDom.timerCount<=0){//倒计时结束了
							waitDom?.hide();
							reject(errorData);
							return;
						}
						if(time){
							clearTimeout(time);
						}
						time=setTimeout(postResult,5*1000);//间隔5秒后继续请求
					})
				}
				let time=setTimeout(postResult,5*1000);
				
			}else{
				resolve(d);
			}
		}).catch(e=>{
			reject(e);
		})
	})
}

//请求加密
const httpAES={
	post:function(methodName,data,isAuth){
		if(isAuth===false){
			if(methodRes4001.indexOf(methodName)>=0){
				return postResult4001(httpAuthority,methodName,data);
			}
			return http('post',methodName,data);
		}else{
			if(methodRes4001.indexOf(methodName)>=0){
				return postResult4001(httpAuthority,methodName,data);
			}
			return httpAuthority('post',methodName,data);
		}
	},
	get:function(methodName,data,isAuth){
		if(isAuth===false){
			return http('get',methodName,data);
		}else{
			return httpAuthority('get',methodName,data);
		}
	},
	//权限许可
	permissionDenied(fun){
		permissionFun=fun;
	},
	//设置请求数据参数
	requestConfig:(requestConfig)=>{
		return {
			post:function(methodName,data,isAuth){
				if(isAuth===false){
					return http('post',methodName,data,requestConfig);
				}else{
					return httpAuthority('post',methodName,data,requestConfig);
				}
			},
			get:function(methodName,data,isAuth){
				if(isAuth===false){
					return http('get',methodName,data,requestConfig);
				}else{
					return httpAuthority('get',methodName,data,requestConfig);
				}
			},
		}
	},
	/*saas公共请求接口 用于登陆获取用户及商户*/
	tradeManageHttp(methodName,data){
		return tradeManageHttp('post',methodName,data);
	}
}

export default httpAES
