<template>
    <modal class="comboFoodModel" :isShow="isShow" isIfShow @keyup.esc="hide">
        <div class="modal-body">
            <div class="filter-nav">
                <div class="nav-box" v-mouse-scroll v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'navPage',isNavFirstSticky:true}"  ref="nav">
                    <div class="nav-li selected"  data-index="0">全部</div>
                    <div class="nav-li" v-for="(item,index) in comboFoodInfo?.ComboKinds" :key="item"  :data-index="index"> {{item.Kind_Name}} </div>
                </div>
                <div class="page-box">
                    <span class="last-page iconfont icon-zuo" ref="nav-up" ></span>
                    <span class="page-number">{{navPage.pageIndex}}/{{navPage.pageSize}} 页</span>
                    <span class="next-page iconfont icon-you" ref="nav-down" ></span>
                </div>
            </div>
            <div class="meal-box" v-mouse-scroll v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.pane-box',marginH:0,paddingH:0,isRow:false}" v-nav-roll="{nav:'nav'}">
                <div class="pane-box" v-for="item in comboKindFilter" :key="item">
                    <div class="title">
                        {{item.Kind_Name}}
                        <span v-html="getKindTitle(item)"></span>
                    </div>
                    <div class="item-box">
                        <div class="item-li selected" v-for="set in item.ComboKindFoods" :key="set">
                            
                            <div class="tit" :class="{selected:set.Is_Selected || item.Select_Type==0}" >
                                <tag-sold-out v-if="set.Is_SoldOut"></tag-sold-out>
                                <div class="name" @click="foodClick(set,item)">
                                    <div class="price">￥{{set.Food_Price}}</div>
                                    <div class="inlineNowrap2">{{set.Food_Name}}</div>
                                    <i class="iconfont icon-gou-fine"></i>
                                </div>
                                <div class="flavour_bnt" v-if="set.Is_BindEatMas" @click="flavourClick(set)">
                                    <i class="iconfont icon-bianji"></i>
                                </div>
                            </div>
                            <div class="flex-box" v-if="set.Is_Selected">
                                <div class="tally-box" >
                                    <div class="bnt-jian" @click="setMealNumreduce(set,item)"><b class="iconfont icon-jian"></b></div>
                                    <div class="num">{{set.Food_Number}}</div>
                                    <div class="bnt-jia" @click="setMealNumAdd(set,item)"><b class="iconfont icon-jia"></b></div>
                                </div>
                                <div class="describe nowrap2" v-if="set._Eat_MasName">要求：{{set._Eat_MasName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                <key-board-input class="search"
                    type="text" v-model="filterText" placeholder="按菜品名称检索"
                    isKeyEnterClose isBlurClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
                <div class="total-box">
                    合计：￥<span class="money">{{totalMoney.money}}</span>  共 {{totalMoney.number}}份
                </div>
                <div class="float-right">
                    <div class="bnt-fangxiang" ref="item-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="item-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <div class="food-box">
                <div class="tit" v-if="comboFoodInfo">
                    <div class="name" >
                        <div class="price">￥{{comboFoodInfo.Food_Price}}/{{comboFoodInfo.Food_Size}}</div>
                        <div class="inlineNowrap2">{{comboFoodInfo.Food_Name}}</div>
                    </div>
                    <div class="flavour_bnt" v-if="comboFoodInfo.Is_BindEatMas" @click="flavourClick(comboFoodInfo)">
                        <i class="iconfont icon-bianji"></i>
                    </div>
                </div>
                <div class="flex-box" v-if="comboFoodInfo">
                    <div class="tally-box" >
                        <div class="bnt-jian" @click="reduce()"><b class="iconfont icon-jian"></b></div>
                        <div class="num">{{comboFoodInfo.Food_Number}}</div>
                        <div class="bnt-jia" @click="add()"><b class="iconfont icon-jia"></b></div>
                    </div>
                    <div class="describe nowrap2" v-if="comboFoodInfo._Eat_MasName">要求：{{comboFoodInfo._Eat_MasName}}</div> 
                </div>
            </div>
            <div class="bnts-box">
                <button class="btn btn-cancel" @click="hide()">取消</button>
                <button class="btn btn-confirm" @click="confirm()">确认</button>
            </div>
        </div>
        <!-- 菜品要求操作 -->
        <modal-load :isShow="demandShow" :isUnload="true">
            <demand-model :isShow="demandShow" @closeModel="demandShow=false" :BindPosFoods="BindPosFoods" :masKindsList="masKindsList" @BindPosconfirm="BindPosconfirm"></demand-model>
        </modal-load>
    </modal>
</template>

<script>
import { deepClone } from '/src/common'
import DemandModel from '../demandModel/demandModel.vue'
/**组合菜品 */
export default {
    name:"comboFoodModel",
    emits:["close","confirm"],
    components:{
        DemandModel
    },
    props:{
        /**弹层是否显示 */
        isShow:{
            typeof:Boolean,
            default:false
        },
        /**组合菜品数据 */
        data:{
            typeof:Object,
            default:null
        },
        /**要修改的数据 */
        editInfo:{
            typeof:Object,
            default:null
        },
        /**菜品数据 */
        posFoodData:{
            typeof:Array,
            default:null
        }
    },
    data(){
        return {
            /**套餐数量 */
            setMealNum:1,
            /**菜品组合数据 */
            comboFoodInfo:null,
            //分类
            filternav:0,
            //分类 分页
            navPage:{
                pageIndex:1,
                pageSize:1
            },
            //菜品名称检索
            filterText:"",
            /**口味要求弹层 */
            demandShow:false,
            BindPosFoods:null,
            //菜品口味
            masKindsList:null,
            //有默认口味未选择时 是否提示
            isDemand:true 
        }
    },
    watch:{
        isShow(){
            if(this.isShow){
                this.init();
            }
        }
    },
    computed:{
        /**所有菜品 */
        posFoodDataAll(){
            let data=[];
            this.posFoodData.forEach(category=> {
                category.FoodSubCategorys?.forEach(sub=>{
                    sub.PosFoods.forEach(item=>{
                        data.push(item);
                        item.MultipleSizeFoods?.forEach(it=>{//多规格
                            data.push(it);
                        })
                    })
                })
            });
            return data;
        },
        /**检索 菜品 */
        comboKindFilter(){
            let filterText=this.filterText.trim().toUpperCase();
            if(filterText){
                let comboKinds=[];
                this.comboFoodInfo?.ComboKinds?.forEach(it=>{
                    comboKinds.push(Object.assign({},it,{
                        ComboKindFoods:it.ComboKindFoods.filter(item=>item.Food_Name.indexOf(filterText)>=0 || (item.Food_PinYin||"").toUpperCase().indexOf(filterText)>=0 || (item.Food_Code||"").toUpperCase().indexOf(filterText)>=0)
                    }));
                })
                console.log(comboKinds)
                return comboKinds;
            }else{
                return this.comboFoodInfo?.ComboKinds;
            }
        },
        /**合计 */
        totalMoney(){
            let money= 0,number=0;
            if(this.comboFoodInfo){
                money= this.comboFoodInfo.Food_Price*this.comboFoodInfo.Food_Number;
                number=this.comboFoodInfo.Food_Number;
                this.comboFoodInfo._FoodModifys?.forEach(mod=>{
                    if(mod.Is_AddMoney){
                        let number=1;
                        if(mod.Price_Kind==0){
                            number=this.comboFoodInfo.Food_Number;
                        }
                        money+= mod.Mas_Price*number;
                    }
                })
                this.comboFoodInfo?.ComboKinds?.forEach(it=>{
                    it.ComboKindFoods.forEach(item=>{
                        if(item.Is_Selected){
                            money+= item.Food_Price*item.Food_Number;
                            number+=item.Food_Number;

                            item._FoodModifys?.forEach(mod=>{
                                if(mod.Is_AddMoney){
                                    let number=1;
                                    if(mod.Price_Kind==0){
                                        number=item.Food_Number;
                                    }
                                    money+= mod.Mas_Price*number;
                                }
                            })
                        }
                    })
                })
                money=Number(money.toFixed(4));
                number=Number(number.toFixed(4));
            }
            return {
                money:money,
                number:number
            };
        }
    },
    mounted(){
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        /*初始化数据*/
        init(){
            this.filterText="";
            this.comboFoodInfo=deepClone(this.data);
            if(this.comboFoodInfo){
                let foodInfo=this.posFoodDataAll.find(it=>it.Food_ID==this.comboFoodInfo.Food_ID);
                Object.assign(this.comboFoodInfo,foodInfo,{Food_Number:1});
                this.comboFoodInfo.ComboKinds=this.comboFoodInfo?.ComboKinds?.filter(kind=>{
                    if(kind.Scen?.indexOf("1")>=0){
                        kind.ComboKindFoods=kind.ComboKindFoods?.filter(item=>{
                            let food=this.posFoodDataAll.find(it=>it.Food_ID==item.Food_ID);
                            if(food){
                                Object.assign(item,food);
                                return true;
                            }
                            return false;
                        })
                        return true;
                    }
                    return false;
                })

                //有需要修改的数据
                if(this.editInfo){
                    Object.assign(this.comboFoodInfo,{
                        Food_Number:Number(Number(this.editInfo.Eat_Number).toFixed(4)),
                        _Eat_MasName:this.editInfo.Eat_MasName,
                        _FoodModifys:this.editInfo.FoodModifys,
                    })
                    if(this.editInfo.Eat_ConsumeID){//有明细id 则是修改组合 否则只是默认主菜数量
                        let orderComboConsumes=this.editInfo.OrderComboConsumes||[];
                        this.comboFoodInfo.ComboKinds?.forEach(kind=>{
                            kind.ComboKindFoods?.forEach(item=>{
                                let consume=orderComboConsumes.find(it=>it.Kind_Name==kind.Kind_Name && (it.Food_ID==item.Food_ID || it.EAT_XFBMID==item.Food_ID));
                                if(consume){
                                    item.Is_Selected=true;
                                    item.Food_Number=Number(consume.Eat_Number);
                                    item._Eat_MasName=consume.Eat_MasName
                                    item._FoodModifys=consume.FoodModifys;
                                }else{
                                    item.Is_Selected=false;
                                }
                            })
                        })
                    }
                }
            }
        },
        //显示 分组 类型 方式
        getKindTitle(item){
            if(item.Select_Type==1){
                let html=["自由搭配"];
                if(item.Select_Min>0 || item.Select_Max>0){
                    html.push("必选项");
                    if(item.Select_Min>0){
                        html.push("最少选"+item.Select_Min+"份");
                    }
                    if(item.Select_Max>0){
                        html.push("最多选"+item.Select_Max+"份");
                    }
                }
                return "("+html.join(",")+")";
            }else{
                return "（固定搭配）";
            }
        },
        /**减少 */
        reduce(){
            let Food_Number=this.comboFoodInfo.Food_Number;
            if(Food_Number>1){
                this.comboFoodInfo.Food_Number=Number((Food_Number-1).toFixed(4));
            }
        },
        /**增加 */
        add(){
            this.comboFoodInfo.Food_Number=Number((this.comboFoodInfo.Food_Number+1).toFixed(4));
        },
        /**数量增加 */
        setMealNumAdd(set,item){
            
            if(item.Select_Type==1){
                let itemNumCount=0;
                item.ComboKindFoods?.forEach(i=>{
                    if(i.Is_Selected){
                        itemNumCount+=i.Food_Number;
                    }
                })

                if(item.Select_Max>0 && itemNumCount>=item.Select_Max){
                    this.$message.error('选择数量超过最大可选数量');
                }else{
                    set.Food_Number++;
                }
            }else{
                //固定搭配
                this.$message.warning("该分类是固定搭配，不能调整菜品数量");
            }
        },
        /**数量减少 */
        setMealNumreduce(set,item){
            if(item.Select_Type==1){
                if(set.Food_Number>1){
                    set.Food_Number--;
                }else{
                    set.Is_Selected=false;
                }
            }else{
                //固定搭配
                this.$message.warning("该分类是固定搭配，不能调整菜品数量");
            }
        },
        /**选择菜品 */
        foodClick(set,item){
            if(item.Select_Type==1){//1：自由搭配
                if(set.Is_SoldOut && !set.Is_Selected){
                    this.$message.warning(set.Food_Name+'已沽清,无法选择');
                    return
                }
                
                let number=0;
                item.ComboKindFoods.forEach(it=>{
                    if(it.Is_Selected){
                        number+=it.Food_Number;
                    }
                })
                
                if(set.Is_Selected){
                    if(item.Select_Min==0 || (item.Select_Min>0 && item.Select_Min<=number-1)){
                        set.Is_Selected=false;
                    }
                }else{
                    if(item.Select_Max==1 && item.Select_Min==1){
                        item.ComboKindFoods?.find(i=>{
                            if(i.Is_Selected){
                                i.Is_Selected=false;
                                return true;
                            }
                        })
                        set.Is_Selected=true;
                        return;
                    }


                    if(item.Select_Max==0 || (item.Select_Max>0 && item.Select_Max>=number+1)){
                        set.Is_Selected=true;
                    }
                }
            }else{//固定搭配
                this.$message.warning("该分类是固定搭配，不能调整菜品数量");
            }
            
        },
        hide(){
            this.$emit("close")
        },
        confirm(){
            let typecount=0,SoldType=0;
            let error=[];
            if(!this.comboFoodInfo?.ComboKinds){
                this.$message.warning('组合菜品没有子项菜品,无法添加到菜单');
                return
            }
            if(this.isDemand && this.comboFoodInfo.Is_BindEatMas && !(this.comboFoodInfo._FoodModifys?.length>0)){//有口味做法要求
                error.push("【"+this.comboFoodInfo.Food_Name+"】");
            }
            for(let i=0;i<this.comboFoodInfo?.ComboKinds.length;i++){
                let itemNumCount=0;
                let item=this.comboFoodInfo?.ComboKinds[i];
                for(let j=0;j<item.ComboKindFoods.length;j++){
                    let set=item.ComboKindFoods[j];
                    if(set.Is_Selected){
                        if(set.Is_SoldOut){
                            this.$message.warning(set.Food_Name+'已估清,无法添加到点菜列表中');
                            return
                        }
                        itemNumCount+=set.Food_Number

                        if(this.isDemand && set.Is_BindEatMas && !(set._FoodModifys?.length>0)){//有口味做法要求
                            error.push("【"+set.Food_Name+"】");
                        }
                    }
                }
                if(item.Select_Type==1){
                    
                    if(item.Select_Max>0 && itemNumCount<item.Select_Min){
                        let title=item.Kind_Name+"分类下【最少选"+item.Select_Min+"份】，您选择了"+itemNumCount+"份，请修改【"+item.Kind_Name+"】分类下菜品份数！"
                        this.$alert(title, "提示", {confirmButtonText: "确定"});
                        //this.$message.warning(item.Kind_Name+'分类下选择数量不满足限制条件');
                        this.$refs.nav?.querySelectorAll(".nav-li")[i+1]?.click();
                        return;
                    }
                    if(item.Select_Min>0 && itemNumCount>item.Select_Max){
                        let title=item.Kind_Name+"分类下【最多选"+item.Select_Max+"份】，您选择了"+itemNumCount+"份，请修改【"+item.Kind_Name+"】分类下菜品份数！"
                        this.$alert(title, "提示", {confirmButtonText: "确定"});
                        //this.$message.warning(item.Kind_Name+'分类下选择数量超过限制条件');
                        this.$refs.nav?.querySelectorAll(".nav-li")[i+1]?.click();
                        return;
                    }
                }
            }

            if(error.length>0){
                this.$confirm("组合菜品中"+error.join(",")+'菜品未选择做法，是否继续点单？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.$emit("confirm",this.comboFoodInfo)
                }).catch(() => {});
                return
            }
            this.$emit("confirm",this.comboFoodInfo)
        },
        /**点击 口味要求 */
        flavourClick(item){
            this.demandShow=true;
            this.BindPosFoods= item;
        },
        /**口味选择 返回 */
        BindPosconfirm(optFlavors){
            this.demandShow=false;
            if(optFlavors?.length>0){
                this.BindPosFoods._Eat_MasName=optFlavors.map(it=>it.Mas_Name).join(",")
                this.BindPosFoods._FoodModifys=optFlavors;
            }else{
                this.BindPosFoods._Eat_MasName="";
                this.BindPosFoods._FoodModifys=null;
            }
        }
    }
}
</script>

<style lang="sass">
@import "./comboFoodModel.scss";
</style>