
import {reactive,h,render} from 'vue'
import requestWaitModal from './requestWaitModal.vue'
//将 弹层 改为js 方法调用来直接显示或隐藏
const initInstance = (props, container) => {
    const vnode = h(requestWaitModal, props);
    render(vnode, container);
    document.body.appendChild(container.firstElementChild);
    return vnode.component;
};
let vm=null;
const requestWait={
    show(txt,onSuccess){
        if(vm){
            render(null, divDom);
            vm=null;
        }
        const props = reactive({
            errorTitle: txt,
            isShow:true,
            onClose(){
                render(null, divDom);
                vm=null;
            },
            onSuccess:onSuccess
        })
        const divDom = document.createElement('div');
        const instance = initInstance(props,divDom);
        vm = instance.proxy;
        vm.show();
        return vm;
    },
    hide(){
        if(vm){
            vm.hide();
        }
    }
}
export {
    requestWaitModal,
    requestWait
}