<template>
    <div class="searchFoodModel" v-show="isShow" v-dom-resize @resize="resize()">
        <div class="header">
            <div class="back-bnt" @click="hide()"><i class="iconfont icon-arrow-left"></i></div>
            <div class="search-box">
                <i class="iconfont icon-sousuo"></i>
                <input type="text" v-model="searchText" ref="input" @click="showKey" @focus="(e)=>e.target.select()"/>
                <i class="close-bnt iconfont icon-a-bg-close3x" @click="searchText=''"></i>
            </div>
            <div class="search-bnt">搜索</div>
        </div>
        <el-scrollbar class="scroll-box"  max-height="100%"  ref="scrollBox" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
            <div class="visual-box" :style="style">
                <div class="li" v-for="item in posFoods" :key="item" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                    <div class="picture">
                        <el-image :src="item.Food_SmallImage" fit="fill">
                            <template #placeholder>
                                <div class="image-slot verticalCenter">Loading<span class="dot">...</span></div>
                            </template>
                            <template #error>
                                <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png)'}"></div>
                            </template>
                        </el-image>
                    </div>
                    <div class="text-box">
                        <div class="displayFlex">
                            <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}</div>
                            <div class="tag-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}份</div>
                        </div>
                        <div class="money-box">
                            <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                <span class="price"><span class="unit">￥</span>{{item.Food_Price}}</span><span class="unit">/份</span>
                            </div>
                            <div class="bnt-box" v-if="item.Is_MultipleSize">
                                <div class="nultiUnit-bnt" @click="addItem(item)">选规格<span class="num" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</span></div>
                            </div>
                            <div class="bnt-box" v-else>
                                <div class="remove" @click="removeItem(item)" :class="{disabled:!(cartNumber[item.Food_ID]?.Eat_Number>1)}" v-if="cartNumber[item.Food_ID]?.Eat_Number>0"><i class="iconfont icon-jian"></i></div>
                                <div class="number" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</div>
                                <div class="add" @click="addItem(item)"><i class="iconfont icon-jia"></i></div>
                            </div>
                        </div>
                        <div class="proMoney" v-if="item.Is_Promotion">
                            <span class="tag">促</span>
                            <span class="unit">￥</span>
                            <span>{{item.Promotion_Price}}</span>
                        </div>
                        <!-- vip 价格 -->
                        <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                            <div class="vipTag">VIP</div>
                            <div class="price"> 
                                <div class="unit">￥</div>{{item.Food_VipPrice}}
                            </div>
                        </div>
                        <div class="sellout"><img src="/images/soldout.png"></div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
/**搜索菜品 */
export default {
    name:"searchFoodModel",
    emits:["close","data","removeItem","addItem"],
    props:{
        isShow:Boolean,
        /**所有菜品 */
        foodAll:{
            type:Array,
            default:null
        },
        /**购物车 菜品 数量 */
        cartNumber:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            /**检索 */
            searchText:"",
            style:{},
        }
    },
    watch:{
        isShow(){
            if(this.isShow){
                this.searchText="";
                this.$nextTick(()=>{
                    this.$refs.input.focus();
                })
            }
        },
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        /**菜品 */
        posFoods(){
            let searchText=this.searchText.toLowerCase().trim();
            if(searchText){
               
                let data= this.foodAll?.filter(it=>(it.Food_Name||"").toLowerCase().indexOf(searchText)>=0 
                                            ||(it.Food_Code||"").toLowerCase().indexOf(searchText)>=0 
                                            || (it.Food_PinYin||"").toLowerCase().indexOf(searchText)>=0).slice(0,8*2);
                console.log(data)                           
                return data
            }
            return [];
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.resize();
            this.$refs.input.focus();
            this.showKey();
        })
    },
    methods:{
        showKey(){//安卓 弹出软键盘
            if(this.$webBrowser.isMobile.Android){
                this.$webBrowser.openWindKeyBoard();
            }
        },
        hide(){
            this.$emit("close");
        },
        /**计算宽高 */
        resize(){
            if(this.$refs.scrollBox?.scrollbar){
                let scrollbar=this.$refs.scrollBox?.scrollbar;
                let width= scrollbar.offsetWidth-75*2-20;
                let height= scrollbar.offsetHeight;
                let w=(width/4-10);
                if(w>0){
                    this.style["--liW"]=w+"px";
                }
                let h=(height/2-25);
                if(h>0){
                    this.style["--liH"]=h+"px";
                }
            }
        },
        removeItem(item){
            this.$emit("removeItem",item)
        },
        addItem(item){
            this.$emit("addItem",item)
        }
    }
}
</script>

<style lang="scss">
    @import './searchFoodModel.scss'
</style>