<template>
  <modal class="scanCodePayModel" :isShow="isShow" @mousedown.prevent @keyup.esc="hide">
    <div class="header">扫码付
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
        <div class="clearfix">
            <div class="left-box">
                <div class="money-info" v-if="orderInfo">
                    <div class="lable-txt" v-if="!rechargeMoney">
                        待支付金额：<b class="noney red">{{Number(orderMoney).toFixed(2)}}</b>
                        <span class="edit-money" @click="isShowNumber=true">修改金额</span>
                    </div>
                    <div class="lable-txt" v-else>待支付金额：<b class="noney red">{{rechargeMoney}}</b></div>
                </div>
                <div class="tr-li">
                    请扫码或输入付款码：
                    <div class="from-input code-box" style="width:300px" @mousedown.stop>
                        <input type="text" ref="input" data-model='payCode' v-model="payCode" :readonly="layer" @keyup.enter="confirm()" />
                        <i v-show="$webBrowser?.isMobile?.Android" class="iconfont icon-a-saoma13x" @click="scanCode"></i>
                    </div>
                    <div class="timer" v-show="timerCount>0">
                        <i class="iconfont icon-shijian"></i><span class="timer-shijian">{{timerCount}}秒</span>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
                <div class="tr-box" v-show="this.memberPay!=1">
                    <el-switch v-model="Is_NotRockover" :inline-prompt="true" active-text="开启付款成功后，不直接翻台" inactive-text=""></el-switch>
                </div>
            </div>
        </div>
        <div class="tip-box">
            {{errorTitle}}
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">
            <span v-show="this.ispaySuccess==false"><i class="iconfont icon-suo"></i></span>取消
        </button>
        <button class="btn btn-border" @click="select()" v-show="this.memberPay!=1">重新查询支付结果</button>
        <button class="btn btn-confirm" @click="confirm()">
            <span v-show="this.ispaySuccess==false"><i class="iconfont icon-suo"></i></span>确认
        </button>
    </div>
    <modal-load :isShow="isShowNumber">
        <number-modal :isShow="isShowNumber" :value="orderList?.UnPaid_Money" @close="isShowNumber=false" @confirm="moneyChange"/>
    </modal-load>
  </modal>
</template>

<script>
import {initKeyBoard,newGuid} from '/src/common' 

/**会员支付 */
export default {
    name:"scanCodePayModel",
    props:{
        //是否显示
        isShow:{
            type:Boolean,
            default:false
        },
        //订单数据
        orderInfo:{
            type:Object,
            default:null
        },
        //支付方式数据
        payTypeSSPay:{},
        //会员支付 默认 正常的扫码结账支付 有 重新查询支付结果  1 不需要 重新查询支付结果
        memberPay:{},
        //待支付金额
        rechargeMoney:{},
        //当前桌台数据
        posDesks:{
            type:Object
        },
        //是否存在记录的微生活信息
        wlivePayExist:{
            type:Boolean,
            default:false
        },
        //是否联台
        unionType:{
            type:Boolean,
            default:false
        },
        //会员信息
        cardMemberList:{
            type:[Object,String]
        }
    },
    data(){
        return {
            payCode:"",//付款码
            /**是否付款成功 */
            ispaySuccess:true,
            /**变动时间戳 */
            ordertimestamps:'',
            /**错误返回 */
            errorTitle:'',
            orderList:'',
            /**是否触发确定回车功能 */
            layer:false,
            /**默认倒计时 */
            timerCount:0,
            /**支付预览成功保存信息 */
            PayPreviewdata:'',
            //是否 付款成功后，不直接翻台
            Is_NotRockover:false,
            /**待支付的订单金额 */
            orderMoney:0,
            //修改金额
            isShowNumber:false,
        }
    },
    mounted(){
       this.$nextTick(()=> {
            this.ispaySuccess=true
            this.payCode=''
            this.errorTitle=''
            this.ordertimestamps=''
            this.PayPreviewdata=''
            initKeyBoard.call(this,this.$el,this.$refs.input);
            this.Is_NotRockover=this.$cacheData.get("Is_NotRockover");
            this.EmptyDatas();
            this.orderMoney=this.orderList?.UnPaid_Money;
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.ispaySuccess=true
                this.payCode=''
                this.errorTitle=''
                this.ordertimestamps=''
                this.PayPreviewdata=''
                this.layer=false
                this.EmptyDatas();
                this.orderMoney=this.orderList?.UnPaid_Money;
                this.Is_NotRockover=this.$cacheData.get("Is_NotRockover");
            }else{
                this.create();
            }
        },
        orderInfo:{
            immediate:true,
            handler(){
                this.payCode=''
                this.errorTitle=''
                this.PayPreviewdata=''
                this.ordertimestamps=''
                this.orderList = JSON.parse(JSON.stringify(this.orderInfo));
                this.orderMoney=this.orderList?.UnPaid_Money;
            }
        },
        timerCount:{
            handler() {
                setTimeout(() => {
                    if(this.timerCount>1){
                        this.timerCount--;
                    }else{
                        this.timerCount=0
                        this.ispaySuccess=true
                    }
                }, 1000);
            },
            immediate: true
        },
        ispaySuccess(newVal){//解锁
            if(newVal && this._timePay){
                clearTimeout(this._timePay);//清除定时器
            }
        },
        Is_NotRockover(){
            this.$cacheData.set("Is_NotRockover",this.Is_NotRockover);
        }
    },
    methods:{
        /**安卓 扫码 */
		scanCode() {
			if (window.webview) {
				this.$webBrowser.hasCamera().then((isQr) => {
					if (isQr == "1") {
						this.$webBrowser.qr().then((d) => {
							this.payCode=d;
                            this.confirm();
						}).catch((e) => {
							this.$message({ message: "扫码失败:" + e, type: "warning" });
						});
					} 
				});
			} 
		},
        /**修改支付金额 */
        moneyChange(money){
            if(Number(money)>Number(Number(this.orderList.UnPaid_Money).toFixed(2))){
                this.$message.warning("不能大于待支付金额!");
                return;
            }
            if(!(money>0)){
                this.$message.warning("金额必须大于0!");
                return;
            }
            this.orderMoney=money;
            this.isShowNumber=false;
        },
        /**默认input焦点 */
        EmptyDatas(){
            this.orderList = JSON.parse(JSON.stringify(this.orderInfo))
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
            this.timerCount=0
        },
        hide(){
            if(this.ispaySuccess==true){
                this.$emit("scanCodeclose")
            }
        },
        /**查询支付结果 */
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.orderList.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Site_Serial, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.orderList.Timestamps.toString(), //账单时间戳
            }
            if(this.ordertimestamps!=''){
                param.Timestamps = this.ordertimestamps.toString();
            }
            //是否是部分支付
            let Is_PartialSsPay = Number(this.orderMoney)!=Number(Number(this.orderList?.UnPaid_Money).toFixed(2));
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    if(this.timerCount<=0){
                        this.ispaySuccess=true
                    }else{
                        this.ispaySuccess=false
                    }
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.ispaySuccess=true
                        this.timerCount=0
                        this.payCode=''
                        if(d.ResponseBody?.Timestamps){
                            this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                        }
                    }else if(d.ResponseHeader.ResultCode==4001){//重新查询
                        this.timeoutPayment();
                    }else if(d.ResponseHeader.ResultCode==3002){
                        this.$emit("refreshOrder")
                        this.ispaySuccess=true
                        this.$message.success("已更新最新的订单时间戳");
                    }
                    if(d.ResponseHeader.ResultCode==-4){
                        this.ispaySuccess=true
                    }
                    if(d.ResponseHeader.ResultCode==1){
                        this.$emit("refreshOrder")
                        this.ispaySuccess=true
                        this.timerCount=0
                        this.$message.warning("原交易预览已作废!可重新发起交易申请");
                    }
                    return;
                }else{
                    let ResultDesc = '未查询到订单的支付结果'
                    let ResuType=0
                    if(d.ResponseHeader.ResultDesc==''){
                        d.ResponseHeader.ResultDesc = ResultDesc
                        ResuType = 1
                    }
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.ordertimestamps = d.ResponseBody.Timestamps;
                            this.ispaySuccess=true;
                            this.$emit("ThePaymentResultClic",d,ResuType,this.PayPreviewdata,this.Is_NotRockover||Is_PartialSsPay)
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**确定和回车事件 */
        confirm(){
            if(!this.payCode){
                this.$message.error('付款码不能为空');
                return;
            }
            if(Number(this.orderMoney).toFixed(2)==0){
                this.$message.error('单笔订单额度受限，建议使用其他付款方式');
                return;
            }
            if(this.memberPay==1){
                this.$emit("memberCodePayModel",this.payCode)
                return;
            }else{
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                if(this.ispaySuccess==true){
                    this.timerCount=60
                    if(this.layer==true){
                        return
                    }else{
                        this.layer=true
                    }
                    const loading = this.$loading({
                        text: "支付提交中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    let FactMoney=[];
                    //是否是部分支付
                    let Is_PartialSsPay = Number(this.orderMoney)!=Number(Number(this.orderList?.UnPaid_Money).toFixed(2));
                    FactMoney.push({
                        Payment_ID:this.payTypeSSPay.PAY_ID, //支付方式ID
                        Payment_Code:this.payTypeSSPay.PAY_CODE,  //支付方式编号
                        Payment_Name:this.payTypeSSPay.PAY_NAME,  //支付方式名称
                        Payment_Type:this.payTypeSSPay.INTERFACE_TYPE,  //支付方式类型
                        Payment_Money:Number(Number(this.orderMoney).toFixed(2)), //支付方式金额
                        Payment_FactMoney:Number(Number(this.orderMoney).toFixed(2)),  //支付实收金额
                        Qr_Code:this.payCode, //扫码付款码值
                        Card_No:"", //卡号
                        CouponInfo:null, //该支付金额核销的券信息
                    });
                    //支付预览
                    let param={
                        Eat_AutoID:this.orderList.Eat_AutoID, //结账单ID
                        Eat_CheckIndex:this.orderList.Eat_CheckIndex, //结账单号
                        Eat_ID:this.orderList.Eat_ID,//账单流水号
                        UnionDesk_IDList:null,//联台桌面id
                        Timestamps:this.orderList.Timestamps.toString(),//账单时间戳
                        User_ID:this.userInfo?.User_ID,  //用户id
                        Operator_Name:this.userInfo?.Login_Name, //操作员
                        Operate_EquipmentID:'',
                        Pos_StationName:this.userInfo?.Site_Name,//站点名称
                        Eat_DeskName:this.orderList.Eat_DeskName,//桌牌号
                        Service_Charge:this.orderList.Service_Money,//服务费
                        MantissaAdjustment_Money:this.orderList.Mantissa_Money, //尾数调整金额
                        Member:null,//会员信息
                        Payments:FactMoney,//支付方式
                    }
                    // if(this.cardMemberList){
                    //     param.Member=this.cardMemberList;
                    // }
                    if(this.ordertimestamps!=''){
                        param.Timestamps = this.ordertimestamps.toString();
                    }
                    this.$httpAES.requestConfig({
                        timeout:8*1000//设置8秒钟 超时时间
                    }).post("Bestech.CloudPos.PayPreview", param).then((d)=>{
                        setTimeout(() => {
                            this.$refs.input.focus();
                        },100);
                        if(d.ResponseBody?.Timestamps){
                            this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                        }
                        this.layer=false
                        //返回了联台的时间戳
                        if(d.ResponseBody?.UnionOrders){
                            this.PayPreviewdata=d
                        }
                        if(d.ResponseHeader.ResultCode!=0){
                            loading.close()
                            let _error = d.ResponseHeader.ResultDesc
                            if(d.ResponseHeader.ResultDesc=='\r\n不支持的支付方式：,类型：0'){
                                _error ='该门店不支持聚合支付,请去后台设置相关功能'
                                this.timerCount=0
                            }
                            
                            if(d.ResponseHeader.ResultCode==4001 || d.ResponseHeader.ResultCode==3002){
                                if(d.ResponseHeader.ResultCode==4001){
                                    if(d.ResponseHeader.ResultDesc.indexOf("密码")>=0 && this.$cacheData.globalVariable?.GlobalSysSetting?.SilverStone_SsPay_NeedPwdVoice){//语音播报 请输入支付密码
                                        this.$webBrowser.tts("请输入支付密码");
                                    }
                                }
                                this.ispaySuccess=false
                                this.timeoutPayment();
                            }
                            if(d.ResponseHeader.ResultCode==4003){
                                this.ispaySuccess=true
                                this.payCode=''
                                this.timerCount=0
                            }
                            this.errorTitle=_error
                            //this.$message.error(_error);
                            return;
                        } else{
                            /**保存新时间戳 */
                            if(d.ResponseBody?.UnionOrders){
                                if(this.orderList.UnionOrderInfoList){
                                    this.orderList.UnionOrderInfoList.forEach(union=>{
                                        d.ResponseBody.UnionOrders?.forEach(preunion=>{
                                            if(union.Eat_AutoID==preunion.Order_ID){
                                                union.Timestamps = preunion.Timestamps.toString()
                                            }
                                        })
                                    })
                                }
                            }
                            if(this.Is_NotRockover || Is_PartialSsPay){//不翻台 部分支付不翻台
                                loading.close()
                                this.$emit("ThePaymentResultClic",d,1,this.PayPreviewdata,true);
                                return;
                            }
                            //是否需要调用提交接口 0不需要，1需要
                            if(d.ResponseBody.Is_NeedSubmit==0 && this.wlivePayExist==false){
                                

                                let EatAutoIDList=[]
                                let orderEatAutoList={Order_ID:this.orderList.Eat_AutoID,Timestamps:this.ordertimestamps.toString(),Service_Charge:this.orderList.Service_Money}
                                EatAutoIDList.push(orderEatAutoList)
                                if(this.unionType==true){
                                    if(this.orderList.UnionOrderInfoList){
                                        this.orderList.UnionOrderInfoList.map(union=>{
                                            orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps,Service_Charge:union.Service_Money}
                                            EatAutoIDList.push(orderEatAutoList)
                                        })
                                    }
                                }
                                param={
                                    User_ID:this.userInfo?.User_ID,  //用户id
                                    CheckOutOrders:EatAutoIDList, //结账订单ID
                                    MantissaAdjust_Money:this.orderList.Mantissa_Money, //尾数调整金额
                                    //Service_Charge:this.orderInfo.Service_Money, //服务费
                                    Operator_Name:this.userInfo?.Login_Name, //操作员
                                    Pos_StationName:this.userInfo?.Site_Name, //站点名称
                                    Print_YN:true,
                                    ElectronicInvoice_PrintForbidden:this.$global.invoiceOff,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
                                    PhaseID:this.$global.PhaseID,//指定时段ID
                                }
                                this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut", param).then((d)=>{
                                    loading.close()
                                    if(d.ResponseHeader.ResultCode!=0){
                                        if(d.ResponseHeader.ResultCode>0){
                                            this.errorTitle = d.ResponseHeader.ResultDesc
                                            this.ispaySuccess=false;
                                            return;
                                        }
                                        this.$message.error(d.ResponseHeader.ResultDesc);
                                        return;
                                    }else{
                                        if(param.Print_YN==true){//返回结账单打印内容
                                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                                        }
                                        if(d.ResponseBody.OpenCashBox==true){
                                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                                if(d?.state!=0){
                                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                                }
                                            });
                                        }
                                        this.$message.success("结账完成");
                                        this.$router.push({path: 'diningTable',name:'diningTable'});
                                        return;
                                    }
                                }).catch((e)=>{
                                    loading.close()
                                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                                });
                            }else{
                                loading.close()
                                this.$emit("ThePaymentResultClic",d,1,this.PayPreviewdata)
                            }
                            return;
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.timerCount=0;
                        this.layer=false;
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    });
                }
            }
        },
        /**重新获取订单最新时间戳 */
        GetOrderInfo(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            //获取订单信息
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.posDesks.Eat_AutoID, //订单Id
                Is_UnionOrder:true,
                Is_LockDesk:true,
            }
            const loading = this.$loading({
                text: '获取最新订单时间戳',
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0)",
            });
            this.$httpAES.post('Bestech.CloudPos.GetOrderInfo',param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode!=0){
                    if(data?.ResponseHeader.ResultDesc==''){
                        this.$message.error('网络错误,请稍后再尝试');
                    }else{
                        this.$message.error(data?.ResponseHeader.ResultDesc);
                    }
                }else{
                    this.orderList = data.ResponseBody;
                    this.ordertimestamps = data.ResponseBody?.Timestamps.toString();
                    this.errorTitle='';
                    this.$message.success("已更新最新的订单时间戳");
                    this.ispaySuccess=true;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**定时查看结果 */
        timeoutPayment(){
            if(this._timePay) clearTimeout(this._timePay);
            this._timePay=setTimeout(()=>{
                if(!this.ispaySuccess){
                    this.select();
                }
            },5000);
        },
        create(){
            if(this._timePay) clearTimeout(this._timePay);
        }
    }
}
</script>

<style lang="sass">
@import "./scanCodePayModel.scss";
</style>