<template>
    <div class="tablePageBtn">
        <template v-if="isPageSizes">
            <el-pagination 
                    layout="prev, pager, next,sizes"
                    :current-page="currentPage"
                    @current-change="current_change"
                    :total="tableListTotal"
                    :page-size="page_size"
                    background
                    :page-sizes="pageSizes"
                    @size-change="handleSizeChange"
                >
            </el-pagination>
        </template>
        <template v-else>
            <el-pagination 
                    layout="prev, pager, next"
                    :current-page="currentPage"
                    @current-change="current_change"
                    :hide-on-single-page="istag"
                    :total="tableListTotal"
                    :page-size="pageSize"
                    background
                >
            </el-pagination>
        </template>
    </div>
</template>

<script>
export default {
    name:"tablePageBtn",
    emits:["currentChange","sizeChange","pageChange"],
    props:{
        data:{
            type:Array
        },
        pageSize:{//每页的数据条数
            type:Number,
            default:10
        },
        defaultIndex:{
            type:Number,
            default:1
        },
        pageSizes:{
            type:Array,
            default:null
        }
    },
    data(){
        return {
            //当前页码
            currentPage:1,
            page_size:0,
            istag:true
        }
    },
    computed:{
        //表的数据条数
        tableListTotal(){
            return (this.data||[]).length;
        },
        //表的 分页数据 
        tableListPage(){
            return (this.data||[]).slice((this.currentPage-1)*this.page_size,this.currentPage*this.page_size);
        },
        isPageSizes(){
            return this.pageSizes?.length>1;
        }
    },
    watch:{
        //分页数据变化时
        tableListPage:{
            handler(){
                this.$emit("pageChange",this.tableListPage);
            },
            immediate:true,
        },
        defaultIndex:{
            handler(newVal){
                this.currentPage=newVal;
            },
            immediate:true,
        },
        pageSize:{
            handler(newVal){
                this.page_size=newVal;
            },
            immediate:true,
        }
    },
    methods:{
        //当前页码修改时
        current_change(currentPage){
            this.currentPage = currentPage;
            this.$emit("currentChange",currentPage);
        },
        handleSizeChange(pageSize){
            this.page_size = pageSize;
            console.log(pageSize)
            this.$emit("sizeChange",pageSize);
        }
    }
}
</script>

<style>

</style>