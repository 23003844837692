<template>
    <modal class="choiceDeskModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">
           请选择桌号
        </div>
        <!--:class="{type3:type==3}"-->
        <div class="modal-body">
            <desk-box :PosRooms="PosRooms" :deskFilter="deskFilter" v-on:select="click"></desk-box>
        </div>
        <div class="footer-box">
            <button class="btn btn-back" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>
import deskBox from './deskBox/deskBox.vue'

/**锁台操作 */
export default {
  components: {deskBox},
    name:"choiceDeskModel",
    props:{
        isShow:Boolean,
    },
    data(){
        return {
            /**餐厅数据 */
            PosRooms:[]
        }
    },
    mounted(){
        const loading = this.$loading({
            text: "数据加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        this.$cacheData.UserRooms().then((d)=>{
            loading.close();
            this.PosRooms=d;
            console.log(d)
            let _allDesk=[];//所有桌台
            (this.PosRooms||[]).forEach((room)=>{
                _allDesk=_allDesk.concat(room.PosDesks);
            })
            this.PosRooms._allDesk=_allDesk;
        }).catch(e=>{
            loading.close();
            console.log('e:'+e)
            this.$alert('未找到桌台数据', "提示", {confirmButtonText: "确定"});
        })
    }, 
    methods:{
        deskFilter(desk){
            return !desk.Is_VisualDesk;
        },
        hide(){
            this.$emit("closeModel")
        },
        click(desk){
            this.PosRooms.some(pos=>{
                pos.PosDesks.some(item=>{
                    if(item.Desk_AutoID==desk.Desk_AutoID){
                        desk = Object.assign(desk,{Room_AutoID:pos.Room_AutoID})
                    }
                })
            })
            console.log('desk:'+JSON.stringify(desk))
            this.$emit("deskclose",desk);
        }
    }
}
</script>

<style lang="sass">
@import "./choiceDeskModel.scss";
</style>