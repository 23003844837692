<template>
    <teleport to="body">
    <div class="analogKeyBoard" ref="analogKeyBoard" :class="{numberBox:isNumber}" v-show="isOpen" :style="style" @mousedown="mousedown" @click="click">
        <template v-if="isNumber">
            <div class="grid-box grid-num-box">
                <div class="grid-item" @click="input(7)">{{7}}</div>
                <div class="grid-item" @click="input(8)">{{8}}</div>
                <div class="grid-item" @click="input(9)">{{9}}</div>
                <div class="grid-item item-clean" @click="clean()">清除</div>
                <div class="grid-item" @click="input(4)">{{4}}</div>
                <div class="grid-item" @click="input(5)">{{5}}</div>
                <div class="grid-item" @click="input(6)">{{6}}</div>
                <div class="grid-item item-back verticalCenter" @click="back()"><i class="iconfont icon-huitui"></i></div>
                <div class="grid-item" @click="input(1)">{{1}}</div>
                <div class="grid-item" @click="input(2)">{{2}}</div>
                <div class="grid-item" @click="input(3)">{{3}}</div>
                <div class="grid-item" @click="input(0)">{{0}}</div>
                <div class="grid-item" @click="input('.')">.</div>
                <div class="grid-item item-confirm" @click="confirm()">确认</div>
            </div>
        </template>
        <template v-else>
            <template v-if="styleType==2">
                <div class="grid-box grid-keyboard-box" :class="{numberKey:isShowNumber}">
                    <template v-if="isShowNumber" >
                        <div class="grid-item" v-for="item in keyCode2[0]" :key="item" @click="input(item)">{{item}}</div>
                    </template>
                    <div class="grid-item" v-for="item in keyCode2[1]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item item-back verticalCenter" @click="back()"><i class="iconfont icon-huitui"></i></div>
                    <div class="grid-item" v-for="item in keyCode2[2]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item item-number" @click="isShowNumber=!isShowNumber">数字</div>
                    <div class="grid-item item-switch" @click="onSwitch()">切换</div>
                    <div class="grid-item" v-for="item in keyCode2[3]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item item-clean" @click="clean()">清除</div>
                    <div class="grid-item item-hide" @click="close()">隐藏</div>
                    <div class="grid-item item-confirm" @click="confirm()">确认</div>
                </div>
            </template>
            <template v-else>
                <div class="grid-box grid-zm-box">
                    <div class="grid-item" v-for="item in keyCode[0]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item" v-for="item in keyCode[1]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item" v-for="item in keyCode[2]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item" v-for="item in keyCode[3]" :key="item" @click="input(item)">{{item}}</div>
                    <div class="grid-item item-clean" @click="clean()">清除</div>
                    <div class="grid-item item-hide" @click="close()">隐藏</div>
                </div>
                <div class="grid-box grid-num-box grid-right-num-box">
                    <div class="grid-item" @click="input(7)">{{7}}</div>
                    <div class="grid-item" @click="input(8)">{{8}}</div>
                    <div class="grid-item" @click="input(9)">{{9}}</div>
                   
                    <div class="grid-item" @click="input(4)">{{4}}</div>
                    <div class="grid-item" @click="input(5)">{{5}}</div>
                    <div class="grid-item" @click="input(6)">{{6}}</div>
                    <div class="grid-item item-back verticalCenter" @click="back()"><i class="iconfont icon-huitui"></i></div>
                    <div class="grid-item" @click="input(1)">{{1}}</div>
                    <div class="grid-item" @click="input(2)">{{2}}</div>
                    <div class="grid-item" @click="input(3)">{{3}}</div>
                    <div class="grid-item item-switch" @click="onSwitch()">切换</div>
                    <div class="grid-item" @click="input(0)">{{0}}</div>
                    <div class="grid-item item-confirm" @click="confirm()">确认</div>
                </div>
            </template>
        </template>
    </div>
    </teleport>
</template>

<script>
export default {
    name:"analogKeyBoard",
    emits:['update:modelValue','confirm'],
    props:{
        modelValue: {
            type: [String, Number],
            default: ''
        },
        isShow:{
            type:Boolean,
            default:false
        },
        /**点击确认是否触发 keydown 回撤时间 */
        isKeyDownEnter:{
            type:Boolean,
            default:false
        },
        /**点击确认是否触发 keyup 回撤时间 */
        isKeyUpEnter:{
            type:Boolean,
            default:false
        },
        /**是否只展现数字键盘 */
        isNumber:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        val(newVal){
            this.$emit("update:modelValue",newVal);
            if(this.inputObj){
                this.inputObj.value=newVal;
                this.inputObj.dispatchEvent(new Event("input"));
            }
        },
        isShow(newVal){
            this.isOpen=newVal;
        },
        isKeyDownEnter(newVal){
            this.isKeyDown=newVal;
        },
        isKeyUpEnter(newVal){
            this.isKeyUp=newVal;
        },
        inputObj(){
            this.$keyBoard.inputObj=this.inputObj;
        },
        isOpen(newVal){
            this.$keyBoard.isShow=newVal;
        }
    },
    data(){
        let keyCode=[
            ['A','B','C','D','E','F','G'],
            ['H','I','J','K','L','M','N'],
            ['O','P','Q','R','S','T','U'],
            ['V','W','X','Y','Z']
        ];
        let keyCode2=[
            ['1','2','3','4','5','6','7','8','9','0'],
            ['Q','W','E','R','T','Y','U','I','O','P'],
            ['A','S','D','F','G','H','J','K','L'],
            ['Z','X','C','V','B','N','M']
        ];
        return {
            keyCode:keyCode,
            keyCode2:keyCode2,
            //样式风格
            styleType:1,
            val:"",
            style:"display:none",
            isOpen:this.isShow,
            inputObj:undefined,
            isKeyDown:this.isKeyDownEnter,
            isKeyUp:this.isKeyUpEnter,
            //是否显示数字键
            isShowNumber:true
        }
    },
    mounted(){
        let KeyBoardStyle=this.$cacheData.get("KeyBoardStyle");
        if(KeyBoardStyle){
            this.styleType=KeyBoardStyle;
        }
        this.$keyBoard.isShow=this.isOpen;
    },
    methods:{
        mousedown(e){
            e.preventDefault();
        },
        click(){
            if(this.inputObj && document.activeElement!=this.inputObj){
                this.inputObj.focus();
                this.$nextTick(()=>{
                    if(this.inputObj){
                        let leng=this.inputObj?.value?.length||100;
                        this.inputObj.selectionStart=leng;
                        this.inputObj.selectionEnd=leng;
                    }
                })
            }
        },
        //显示
        show(element,options){
            this.$nextTick(()=>{
                this.isOpen=true;
                options=options||{};
                if(options.isKeyDown!=undefined && options.isKeyDown!=null) this.isKeyDown=options.isKeyDown;
                if(options.isKeyUp!=undefined && options.isKeyUp!=null) this.isKeyUp=options.isKeyUp;
                
                this.$nextTick(()=>{
                    if(element){
                        let width= this.$refs.analogKeyBoard.offsetWidth+10;
                        let height=this.$refs.analogKeyBoard.offsetHeight+10;
                        this.inputObj=element;
                        this.val=element.value;
                        let rect=element.getBoundingClientRect();
                        let padding=3;
                        let left="left:"+rect.left+"px";
                        if(!this.isNumber && rect.left<60){
                            left="left:60px";
                        }else if(rect.width+(window.innerWidth-rect.right)<width){
                            left="right:0px";
                        }
                        let top="bottom:"+(window.innerHeight-rect.bottom+rect.height+padding)+"px";
                        if(rect.top<280){
                            top="top:"+(rect.top+rect.height+padding)+"px";
                        }

                        if(options.type=="bottom"){
                            if(rect.top+rect.height+padding+height>window.innerHeight){//超过屏幕高度
                                top="bottom:"+(window.innerHeight-rect.bottom+rect.height+padding)+"px";
                            }else{
                                top="top:"+(rect.top+rect.height+padding)+"px";
                            }
                        }else if(options.type=="top"){
                            if(rect.bottom+rect.height+padding+height>window.innerHeight){//超过屏幕高度
                                top="top:"+(rect.top+rect.height+padding)+"px";
                            }else{
                                top="bottom:"+(window.innerHeight-rect.bottom+rect.height+padding)+"px";
                            }
                        }

                        this.style=top+";"+left;
                    }else{
                        this.style="display:none"
                    }
                })
            
                this.diskey(this.inputObj);
            })
        },
        close(element){
            if(element){
                if(this.inputObj==element){
                    this.isOpen=false;
                    if(this.inputObj){
                        this.inputObj=undefined;
                        this.clickConfirm=undefined;
                    }
                }
            }else{
                this.isOpen=false;
                if(this.inputObj){
                    this.inputObj=undefined;
                    this.clickConfirm=undefined;
                }
            }
        },
        input(key){
            this.diskey(this.inputObj);
            if(this.inputObj){
                this.val=this.inputObj.value;
            }
            this.$nextTick(()=>{
                let value=this.val;
                //当前input上有焦点
                if(document.activeElement==this.inputObj && this.inputObj.selectionStart<=this.inputObj.selectionEnd){
                    let selectStart=this.inputObj.selectionStart;
                    this.val=value.slice(0,this.inputObj.selectionStart)+key+value.slice(this.inputObj.selectionEnd);
                    this.$nextTick(()=>{
                        if(this.inputObj && selectStart>=0){
                            this.inputObj.selectionStart=selectStart+1;
                            this.inputObj.selectionEnd=selectStart+1;
                        }
                    })
                }else{
                    this.val=value+key;
                }
                
            });
        },
        confirm(){
            this.$emit("confirm",this.val);
            if(this.inputObj && (this.isKeyDown || this.isKeyUp)){
                this.diskey(this.inputObj);
                if(this.isKeyDown){
                    this.inputObj.dispatchEvent(new KeyboardEvent("keydown",{
                        key:"Enter",
                        keyCode:13
                    }));
                }
                
                if(this.isKeyUp){
                    this.inputObj.dispatchEvent(new KeyboardEvent("keyup",{
                        key:"Enter",
                        keyCode:13
                    }));
                }
            }
        },
        clean(){
            this.val='';
            this.diskey(this.inputObj);
        },
        /**切换 */
        onSwitch(){
            this.styleType= this.styleType==2?1:2;
            if(this.styleType==2){
                this.isShowNumber=true;
            }
            this.$cacheData.set("KeyBoardStyle",this.styleType);
        },
        back(){
            this.diskey(this.inputObj);
            let value=this.inputObj.value;
            let isBo=false;
            let selectStart;
            if(this.inputObj && document.activeElement==this.inputObj){
                selectStart=this.inputObj.selectionStart;
                if(this.inputObj.selectionStart<this.inputObj.selectionEnd){
                    value=value.slice(0,this.inputObj.selectionStart)+value.slice(this.inputObj.selectionEnd);
                    this.val=value;
                    isBo=true;
                }
                //this.val=this.inputObj.value;
                if(selectStart==0 && this.inputObj.selectionEnd==0){
                    return;
                }
            }
            this.$nextTick(()=>{
                if(!isBo && value && value.toString().length>0){
                    let leng=value.toString().length;
                    if(selectStart>=0){
                        this.val=value.slice(0,selectStart-1)+value.slice(selectStart);
                        selectStart=selectStart-1;
                    }else{
                        this.val=value.toString().substr(0,leng-1);
                    }
                }
                this.$nextTick(()=>{
                    if(this.inputObj && selectStart>=0){
                        this.inputObj.selectionStart=selectStart;
                        this.inputObj.selectionEnd=selectStart;
                    }
                })
            })
        },
        //禁止安卓弹层软键盘
        diskey(input){
            if(input){
                let isReadonly=input.readOnly;
                if(!isReadonly){
                    input.setAttribute("readonly","readonly");
                    if(input._setTime) clearTimeout(input._setTime);
                    input._setTime=setTimeout(()=>{
                        input?.removeAttribute("readonly");
                    },100)
                }
            }
        }
    }
}
</script>

<style lang="scss">
.analogKeyBoard{
    position: fixed;
    z-index: 2001;
    background: #ABB1BD;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.40);
    padding:5px;
    display: flex;
    max-width: 700px;
    width: 55%;

    .left-bnt{
        position: absolute;
        left:-60px;
        top:0;

        .item-bnt{
            background: #D6D7DB;
            box-shadow: 0 2px 4px -1px rgba(83, 83, 83, 0.5);
            border-radius: 6px;
            border:1px solid #ABB1BD;
            color: #000000;
            font-weight: 500;
            text-align: center;
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 14px;
            margin-top: 4px;
        }
    }
   

    .grid-box{
         
         display: grid;
         grid-gap:4px 3px;
         

        &.grid-zm-box{
            flex: 1;
            grid-template-columns: auto auto auto auto auto auto auto;
        }

        &.grid-num-box{
            width: 35.4%;
            margin-left: 11px;
            grid-template-columns: auto auto auto auto ;

            .item-back{
                grid-row: 2 / span 2;
                grid-column: 4;
            }

            .item-clean{
                font-size: 14px;
            }

            .item-confirm{
                grid-column: 2/5;
            }
        }

        &.grid-right-num-box{
            &.grid-num-box{

                .item-back{
                    grid-row: 1 / span 2;
                }
            }
        }

        &.grid-keyboard-box{
            flex: 1;
            grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;

            &.numberKey{
                .item-back{
                    grid-row: 1 / span 2;
                }
            }
            .item-back{
                grid-row: 1 / span 1;
                grid-column: 11;
            }

            .item-confirm{
                grid-column: 10/12;
            }
        }

        .grid-item{
            background: #FFFFFF;
            box-shadow: 0 2px 4px -1px rgba(83,83,83,0.50);
            border-radius: 6px;
            line-height: 60px;
            font-size: 30px;
            color: #000000;
            font-weight: 500;
            text-align: center;
            min-width: 45px;
        }

        .item-hide,.item-switch,.item-number{
            font-size: 14px;
            background: #D6D7DB;
        }

        .item-back{
            background: #D6D7DB;
            box-shadow: 0 2px 4px -1px rgba(83,83,83,0.50);
            border-radius: 4px;
        }

        .item-clean{
            background: #D6D7DB;
            box-shadow: 0 2px 4px -1px rgba(83,83,83,0.50);
            border-radius: 4px;
            font-size: 14px;
        }

        .item-confirm{
            background: #487BB1;
            box-shadow: 0 2px 4px -1px rgba(83,83,83,0.50);
            border-radius: 4px;
            font-size: 14px;
            color: #FFFFFF;
        }
    }

    &.numberBox{
        width: 246px;

        .grid-num-box{
            width:100%;
        }

        .grid-box{
            &.grid-num-box{
                margin: 0;
                .item-confirm{
                    grid-column:3/5;
                }
            }
        }
    }
}
</style>