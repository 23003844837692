<template>
    <el-table class="elTableVir" ref="elTable" :data="virData">
        <el-table-column class-name="is-fixed" label="选择"  width="40" v-if="isSelection" >
            <template #header>
                <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange" ></el-checkbox>
            </template>
            <template #default="scope">
                <el-checkbox v-model="selects[scope.$index]"></el-checkbox>
            </template>
        </el-table-column>
        <slot></slot>
    </el-table>
</template>

<script>
import { render,h,withDirectives } from 'vue'
import { ElTable } from 'element-plus'

const resizeEvent={
    addResizeListener(el,fn){
        if(!el.__ro__){
            el.__ro__= new ResizeObserver(fn);
            el.__ro__.observe(el)
        }
    },
    removeResizeListener(el){
        if(el.__ro__){
            el.__ro__.unobserve(el);
        }
    }
}
export default {
    name:"elTableVir",
    props:{
        data:{
            type:Array,
            default:null
        },
        /**tr 默认高度25px */
        trHeight:{
            type:Number,
            default:25
        },
        isSelection:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        data(){
            this.selects=Array(this.data?.length||0);
            this.$nextTick(()=>{
                let twrapper=this.$refs.elTable.$el.querySelector(".el-table__body-wrapper");
                twrapper.scrollTop=0;
                this.initAin();
            })
        },
        tbVisible(){
            if(this.tbVisible){
                let twrapper=this.$refs.elTable.$el.querySelector(".el-table__body-wrapper");
                let height=twrapper.clientHeight;
                if(twrapper._scrollTop!=twrapper.scrollTop){
                    twrapper.scrollTop=twrapper._scrollTop;
                }
                this.pagesize= parseInt(height/this.trHeight)+this.buffsize*2;
            }
        },
        pagesize(){
            if(this.pagesize>0){
                this.initAin();
            }
        }
    },
    data(){
        return {
            virData:[],
            pagesize:0,
            buffsize:4,
            tbVisible:false,
            /**每条数据的行高 */
            trH:[],
            /**每列数据的列宽 */
            columnW:[],
            selects:[],
            /**是否全选 */
            checkAll:false,
            isIndeterminate:true,
            //记录 table dom 数据
            tableDom:{
                colgroup:[],
                tbody:[],
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            let twrapper=this.$refs.elTable.$el.querySelector(".el-table__body-wrapper");
            let height=twrapper.clientHeight;
            this.pagesize= parseInt(height/this.trHeight)+this.buffsize*2;
            let scrollTop=0;
            let tbody=twrapper.querySelector(".el-table__body");

            /**固定列 */
            let fixedBodyWrapper=this.$refs.elTable.$refs?.fixedBodyWrapper;
            let fixedTBody=fixedBodyWrapper?.querySelector('.el-table__body');
            let th=this;
            twrapper.addEventListener("scroll",(e)=>{
                //if(th.tableDom?.tbody.length>0){
                if(th._tableBody?.children?.length>0){
                    if(scrollTop!=e.target.scrollTop){
                        scrollTop=e.target.scrollTop;
                        let p=th.getPosition(scrollTop);
                        tbody.style.transform='translateY('+p.top+'px)';
                        if(fixedTBody){
                            fixedTBody.style.transform='translateY('+p.top+'px)';
                        }

                        if(!scroll._ainInv){
                            th.ain(e.target);
                        }
                    }
                    
                }
                //this.setColumn();
            })
        })
    },
    updated(){
        this.$nextTick(()=>{
            this.tbVisible=this.isVisible(this.$refs.elTable.$el);
            //this.setColumn();
        })
    },
    methods:{
        isVisible(elem){
            return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
        },
        _toggleRowSelection(row,bo){
            let index= this.data.findIndex(it=>it==row);
            if(index>=0){
                this.selects[index]=bo;
            }
        },
        /**全选 */
        _handleCheckAllChange(val){
            this.isIndeterminate = false
            this.data?.forEach((it,index)=>{
                this.selects[index]=val;
            })
        },
        //更新栏目
        _setColumn(){
            if(!this.tbVisible) return;
            let elTable=this.$refs.elTable;
            let tableHeader=elTable?.$refs?.tableHeader;
            let bodyWrapper=elTable.$refs.bodyWrapper;
            if(tableHeader){
                let scrollLeft=bodyWrapper.scrollLeft;
                let columns=[];
                let fiexdLeftW=0;
                let isFiexd=true;
                let leftW=0;
                let left=0;

                for(let i=0;i<tableHeader.columns?.length;i++){
                    let col=tableHeader.columns[i];
                    let width=(col.realWidth||col.width);
                    col.index=i;
                    if(isFiexd && col.className=="is-fixed"){
                        columns.push(col);
                        fiexdLeftW+=width;
                    }else{
                        isFiexd=false;
                        if(scrollLeft<fiexdLeftW+leftW+width && leftW-scrollLeft<bodyWrapper.clientWidth-fiexdLeftW){//可视区域展现
                            columns.push(col);
                        }else if(scrollLeft>leftW+width){
                            left+=width;
                        }else{
                            break;
                        }
                        leftW+=width;
                    }
                }

                if(columns.length>0){//栏目变化时更新
                    if(!this.columns?.length>0 || columns.some((it,index)=>it!=this.columns[index])){
                        this.columns=columns;
                        let tableBody=bodyWrapper.querySelector(".el-table__body");
                        let colDivs=[];
                        let totalW=0;
                        this.columns.forEach(col=>{
                            let index=col.index;
                            let width=col.realWidth||col.width;
                            totalW+=width;
                            let div=this.tableDom.colgroup[index];
                            if(!div){
                                div=document.createElement("col");
                                div.setAttribute("width",width);
                                div.className=col.id;
                                this.tableDom.colgroup[index]=div;
                            }
                            colDivs.push(div);
                        })
                        tableBody.style.width=totalW+"px";
                        tableBody.style.position="absolute";
                        tableBody.style.left= left+'px';
                        Array.from(tableBody.children[0].children).forEach(it=>{
                            if(!colDivs.some(dom=>dom==it)){//移除不需要的
                                it.remove();
                            }
                        })
                        let colgroup=tableBody.children[0];
                        let colChildren=Array.from(colgroup.children);
                        colDivs.forEach((it,i)=>{//插入数据
                            if(!colChildren.some(dom=>dom==it)){//没有的插入
                                if(i<colChildren.length){
                                    colChildren[i].before(it);
                                }else{
                                    colgroup.append(it);
                                }
                                colChildren=Array.from(colgroup.children);
                            }
                        })
                        this.draw();
                    }
                }else if(columns.length==0 && this.columns?.length>0){
                    let tableBody=bodyWrapper.querySelector(".el-table__body");
                    tableBody.style.left= '';
                    Array.from(tableBody.children[0].children).forEach(it=>{
                        it.remove();
                    })
                    Array.from(tableBody.children[1].children).forEach(it=>{
                        it.remove();
                    })
                }
            }
        },
        _initAin(){
            let elTable=this.$refs.elTable;
            this.trH=[];
            if(this.tableDom){
                this.tableDom.colgroup=[];
                this.tableDom.tbody.forEach(({tr,tds})=>{
                    tds.forEach(td=>{
                        render(null,td);
                    })
                    tr.remove();
                })
                this.tableDom.tbody=[];
            }
            if(this.data?.length>0){
                this.data.forEach(()=>{
                    this.trH.push(this.trHeight);//默认行高
                })
                if(elTable.$refs.emptyBlock)
                elTable.$refs.emptyBlock.style.display="none";
                if(elTable.$refs.footerWrapper){
                    elTable.$refs.footerWrapper.style.display="";
                }
                if(elTable.$refs.fixedFooterWrapper){
                    elTable.$refs.fixedFooterWrapper.style.display="";
                } 
                let twrapper=elTable.$el.querySelector(".el-table__body-wrapper");
                let tbody=twrapper.querySelector(".el-table__body");
                let bx=twrapper.querySelector(".bx");
                if(!bx){
                    bx=document.createElement('div');
                    bx.classList.add("bx");
                    tbody.before(bx);
                }
                Object.assign(bx.style,{
                    height:0,
                    width:elTable.bodyWidth,
                })
                let by=twrapper.querySelector(".by");
                if(!by){
                    by=document.createElement('div');
                    by.classList.add("by");
                    tbody.before(by);
                }
                Object.assign(by.style,{
                    width:0,
                    height:(this.data.length*this.trHeight)+"px",
                    float:'left'
                })
                

                if(this.tbVisible){
                    this.draw();
                }
               
            }else{
                if(elTable.$refs.emptyBlock)
                elTable.$refs.emptyBlock.style.display="";
                if(elTable.$refs.footerWrapper){
                    elTable.$refs.footerWrapper.style.display="none";
                }
                if(elTable.$refs.fixedFooterWrapper){
                    elTable.$refs.fixedFooterWrapper.style.display="none";
                } 

                let bys=elTable.$el.querySelectorAll(".by");
                Array.from(bys).forEach(by=>{
                    by.style.display="none";
                })
                let bxs=elTable.$el.querySelectorAll(".bx");
                Array.from(bxs).forEach(bx=>{
                    bx.style.display="none";
                })
                let bodyWrapper=elTable.$refs?.bodyWrapper;
                let tbody=bodyWrapper.querySelector('.el-table__body tbody');
                tbody.innerHTML="";
                let fixedBodyWrapper=elTable.$refs?.fixedBodyWrapper;
                if(fixedBodyWrapper){
                    let ftbody=fixedBodyWrapper.querySelector('.el-table__body tbody');
                    ftbody.innerHTML="";
                }
            }
        },
        /**计算位置 */
        _getPosition(scrollTop){
            let _trs=this.tableDom.tbody;
            let trH=0,index=0;
            for(let i=0;i<_trs.length;i++){
                let height=_trs[i]._height||this.trHeight;
                if(height+trH>scrollTop){
                    break;
                }
                trH+=height;
                index=i;
            }

            index=index-(this.buffsize-1);
            if(index+this.pagesize>this.data.length-this.buffsize){
                index=this.data.length-this.pagesize;
            }
            if(index<0){
                index=0;
            }
            let top=0;
            for(let j=0;j<_trs.length;j++){
                
                if(index==j){
                    break;
                }
                let height=_trs[j]._height||this.trHeight;
                top+=height;
            }
            return {
                index,
                top
            }
        },
        _ain(scroll){
            if(scroll._scrollTop!=scroll.scrollTop){
                scroll._ainInv=true;
                let scrollTop=scroll.scrollTop;
                scroll._scrollTop=scrollTop;
                let p=this.getPosition(scrollTop);
                let index=p.index;
                if(scroll._ainInvIndex!=index){
                    scroll._ainInvIndex=index;
                    //this.virData=this.data.slice(index,index+this.pagesize);
                    //this.setData(index)
                    this.draw(index);
                    requestAnimationFrame(()=>{
                        this.ain(scroll);
                    });
                }
            }else{
                scroll._ainInv=false;
            }
        },
        /**渲染表格 */
        _draw(startIndex){
            if(!this.columns?.length>0){
                this.setColumn()
            }
            if(!startIndex){
                startIndex=this._startIndex||0;
            }
            this._startIndex=startIndex;
            let elTable=this.$refs.elTable;
            let bodyWrapper=elTable.$refs.bodyWrapper;
            let tableBody=bodyWrapper.querySelector(".el-table__body");
            let trs= tableBody.children[1].children;
            let newTbody=[];
            let data=this.data?.slice(startIndex,startIndex+this.pagesize);
            data.forEach((row,index)=>{
                let rowIndex=startIndex+index;
                let tr=this.tableDom.tbody[rowIndex]?.tr;
                if(!tr){
                    tr= document.createElement("tr");
                    tr.dataset.vid=rowIndex;
                    tr.className="el-table__row";
                    if(typeof(elTable.rowClassName)=="function"){
                        let className=elTable.rowClassName({ row,  rowIndex});
                        className && tr.classList.add(className);
                    }
                    if(typeof(elTable.rowStyle)=="function"){
                        Object.assign(tr.style,elTable.rowStyle({ row, rowIndex }))
                    }
                    if(!this.tableDom.tbody[rowIndex]){
                        this.tableDom.tbody[rowIndex]={ tr, tds:[] };
                    }else{
                        this.tableDom.tbody[rowIndex].tr=tr;
                    }
                }
                let tds=[]
                this.columns?.forEach(column=>{
                    let columnIndex=column.index;
                    let td=this.tableDom.tbody[rowIndex].tds[columnIndex];
                    if(!td){
                        td=document.createElement("td");
                        td.className=column.id+" "+(column.align||"")+" "+(column.className||"");
                        td.rowSpan=1;
                        td.colSpan=1;
                        //合并单元格
                        if(typeof(elTable.spanMethod)=="function"){
                            let span=elTable.spanMethod({row, column, rowIndex, columnIndex});
                            if(span?.length==2){
                                td.rowSpan=span[0];
                                td.colSpan=span[1];
                            }
                        }
                        if(td.colSpan==0 || td.rowSpan==0){
                            td.style.display="none";
                        }

                        if(typeof(elTable.cellClassName)=="function"){
                            let className=elTable.rowClassName({ row, column, rowIndex, columnIndex });
                            className && tr.classList.add(className);
                            
                        }
                        if(typeof(elTable.cellStyle)=="function"){
                            Object.assign(tr.style,elTable.cellStyle({ row, column, rowIndex, columnIndex }))
                        }
                        let cell;
                        if(column.type!="selection"){
                            cell=column.renderCell({row:row,column:column,$index:rowIndex});
                            render(cell,td);
                        }
                        
                        this.tableDom.tbody[rowIndex].tds[columnIndex]=td;
                    }
                    
                    tds.push(td);
                })
                newTbody.push({tr,tds});
            })


            Array.from(trs).forEach(it=>{
                let newTr=newTbody.find(dom=>dom.tr==it);
                if(newTr){//移除没有td
                    Array.from(it.children).forEach(td=>{
                        if(!newTr.tds.some(newTd=>newTd==td)){
                            td.remove();
                        }
                    })
                }else{//移除没有的tr;
                    it.remove();
                }
            })
            
            let oldTbody=tableBody.children[1];
            let oldTrs=Array.from(oldTbody.children);
            newTbody.forEach(({tr,tds},i)=>{//插入tr数据
                let oldTr=oldTrs.find(dom=>dom==tr);
                if(oldTr){//更新 tr 中td 的数据
                    let oldTds=Array.from(oldTr.children);
                    tds.forEach((td,tdIndex)=>{
                        if(!oldTds.some(dom=>dom==td)){//没有时 插入新td
                            if(tdIndex<oldTds.length){//已有节点数小于要更新的节点数据
                                oldTds[tdIndex].before(td);//指定位置插入
                            }else{
                                oldTr.append(td);
                            }
                            oldTds=Array.from(oldTr.children);
                        }
                    })
                }else{//没有的tr插入
                    Array.from(tr.children).forEach(td=>{//清空 缓存中tr节点
                        td.remove();
                    })

                    tds.forEach(td=>{//插入tr节点中需要的td
                        tr.append(td);
                    })

                    if(i<oldTrs.length){
                        oldTrs[i].before(tr);
                    }else{
                        oldTbody.append(tr);
                    }
                    oldTrs=Array.from(oldTbody.children);
                }
            })

        },
        initAin(){
            let elTable=this.$refs.elTable;
            let tableHeader=elTable.$refs?.tableHeader;
            if(this._tableBody){
                Array.from(this._tableBody.children).forEach(tr=>{
                    Array.from(tr.children).forEach(td=>{
                        render(null,td);
                    })
                })
            }
            this._tableBody=document.createElement("tbody");//缓存渲染
            this._cells=[];
            

            if(this.data?.length>0){
                elTable.$refs.emptyBlock.style.display="none";
                if(elTable.$refs.footerWrapper){
                    elTable.$refs.footerWrapper.style.display="";
                }
                if(elTable.$refs.fixedFooterWrapper){
                    elTable.$refs.fixedFooterWrapper.style.display="";
                } 

                this.data?.forEach((row,rowIndex)=>{
                    let tr= document.createElement("tr");
                    tr.dataset.vid=rowIndex;
                    tr.className="el-table__row";
                    if(typeof(elTable.rowClassName)=="function"){
                        let className=elTable.rowClassName({ row,  rowIndex});
                        className && tr.classList.add(className);
                    }
                    if(typeof(elTable.rowStyle)=="function"){
                        Object.assign(tr.style,elTable.rowStyle({ row, rowIndex }))
                    }
                    
                    tableHeader?.columns?.forEach((column,columnIndex)=>{
                        let td=document.createElement("td");
                        td.className=column.id+" "+(column.align||"")+" "+(column.className||"");
                        td.rowSpan=1;
                        td.colSpan=1;
                        //合并单元格
                        if(typeof(elTable.spanMethod)=="function"){
                            let span=elTable.spanMethod({row, column, rowIndex, columnIndex});
                            if(span?.length==2){
                                td.rowSpan=span[0];
                                td.colSpan=span[1];
                            }
                        }
                        if(td.colSpan==0 || td.rowSpan==0){
                            td.style.display="none";
                        }
                    
                        if(typeof(elTable.cellClassName)=="function"){
                            let className=elTable.rowClassName({ row, column, rowIndex, columnIndex });
                            className && tr.classList.add(className);
                            
                        }
                        if(typeof(elTable.cellStyle)=="function"){
                            Object.assign(tr.style,elTable.cellStyle({ row, column, rowIndex, columnIndex }))
                        }
                        let cell=column.renderCell({row:row,column:column,$index:rowIndex});
                       
                        render(cell,td);
                        if(!this._cells[rowIndex]){
                            this._cells[rowIndex]=[]
                        }
                        this._cells[rowIndex][columnIndex]=td.children[0];
                        tr.append(td);
                    })
                    this._tableBody.append(tr);
                })
                
                let twrapper=elTable.$el.querySelector(".el-table__body-wrapper");
                let tbody=twrapper.querySelector(".el-table__body");
                let by=twrapper.querySelector(".by");
                if(!by){
                    by=document.createElement('div');
                    by.classList.add("by");
                    tbody.before(by);
                }
                Object.assign(by.style,{
                    width:0,
                    height:(this.data.length*this.trHeight)+"px",
                    float:'left'
                })

                /**固定列 */
                let fixedBodyWrapper=elTable.$refs?.fixedBodyWrapper;
                let fixedTrBody,fixedTbody;
                if(fixedBodyWrapper){
                    fixedTbody=fixedBodyWrapper.querySelector(".el-table__body");
                    let fixedBy=fixedTbody.querySelector(".by");
                    if(!fixedBy){
                        fixedBy=document.createElement('div');
                        fixedBy.classList.add("by");
                        fixedTbody.before(fixedBy);
                    }
                    Object.assign(fixedBy.style,{
                        width:0,
                        height:(this.data.length*this.trHeight)+"px",
                        float:'left'
                    })
                    fixedTrBody=fixedTbody.querySelector('tbody');
                }
                
                let trBody=tbody.querySelector('tbody');
                

                twrapper._ainInvIndex=0;

                let leng=Math.min(this.pagesize,this.data.length)
                //this.virData=[[]];
                trBody.innerHTML="";
                for(let i=0;i<leng;i++){
                    let _tr=this._tableBody.children.item(i)
                    let tr=_tr.cloneNode(true);
                    this.addEvent(tr)
                    trBody.append(tr);
                }

                Array.from(trBody.children||[]).forEach(tr=>{
                    let id=Number(tr.dataset.vid)
                    if(tr.clientHeight>0){
                        this._tableBody.children.item(id)._height=tr.clientHeight;
                    }
                })
                
                let p=this.getPosition(twrapper.scrollTop);
                tbody.style.transform='translateY('+p.top+'px)';
                /**固定列 */
                if(fixedBodyWrapper){
                    fixedTrBody.innerHTML="";
                    fixedBodyWrapper.style.height=(twrapper.clientHeight-6)+"px";
                    for(let i=0;i<leng;i++){
                        let _tr=this._tableBody.children.item(i);
                        let tr=_tr.cloneNode(true);
                        tableHeader?.columns?.forEach((column)=>{
                            if(column.fixed===false){
                                tr.lastElementChild.remove();
                            }
                        })
                        
                        this.addEvent(tr)
                        fixedTrBody.append(tr);
                    }
                    fixedTbody.style.transform='translateY('+p.top+'px)';
                }
                this.ain(twrapper);
            }
            else{
                elTable.$refs.emptyBlock.style.display="";
                if(elTable.$refs.footerWrapper){
                    elTable.$refs.footerWrapper.style.display="none";
                }
                if(elTable.$refs.fixedFooterWrapper){
                    elTable.$refs.fixedFooterWrapper.style.display="none";
                } 

                let bys=elTable.$el.querySelectorAll(".by");
                Array.from(bys).forEach(by=>{
                    by.remove();
                })
                let bodyWrapper=elTable.$refs?.bodyWrapper;
                let tbody=bodyWrapper.querySelector('.el-table__body tbody');
                tbody.innerHTML="";
                let fixedBodyWrapper=elTable.$refs?.fixedBodyWrapper;
                if(fixedBodyWrapper){
                    let ftbody=fixedBodyWrapper.querySelector('.el-table__body tbody');
                    ftbody.innerHTML="";
                }
            }
            
        },
        ain(scroll){
            if(scroll._scrollTop!=scroll.scrollTop){
                scroll._ainInv=true;
                let scrollTop=scroll.scrollTop;
                scroll._scrollTop=scrollTop;
                let p=this.getPosition(scrollTop);
                let index=p.index;
                if(scroll._ainInvIndex!=index){
                    scroll._ainInvIndex=index;
                    //this.virData=this.data.slice(index,index+this.pagesize);
                    this.setData(index)
                    
                    requestAnimationFrame(()=>{
                        this.ain(scroll);
                    });
                }
            }else{
                scroll._ainInv=false;
            }
        },
        /**计算位置 */
        getPosition(scrollTop){
            let _trs=this._tableBody?.children;
            let trH=0,index=0;
            for(let i=0;i<_trs.length;i++){
                let height=_trs[i]._height||this.trHeight;
                if(height+trH>scrollTop){
                    break;
                }
                trH+=height;
                index=i;
            }

            index=index-(this.buffsize-1);
            if(index+this.pagesize>this.data.length-this.buffsize){
                index=this.data.length-this.pagesize;
            }
            if(index<0){
                index=0;
            }
            let top=0;
            for(let j=0;j<_trs.length;j++){
                
                if(index==j){
                    break;
                }
                let height=_trs[j]._height||this.trHeight;
                top+=height;
            }
            return {
                index,
                top
            }
        },
        setData(startIndex){
            let elTable=this.$refs.elTable;
            let bodyWrapper=elTable.$refs?.bodyWrapper;
            let tbody=bodyWrapper.querySelector('.el-table__body tbody');
            let trs= tbody.children;
            let _trs=this._tableBody.children;
            
            let fistIndex=Number(trs.item(0).dataset?.vid);
            let diff=startIndex-fistIndex;
            let _cells=this._cells;
            if(Math.abs(diff)<=-2){
                if(diff>0){//向下滚动
                    let lastIndex=Number(tbody.lastElementChild?.dataset?.vid)+1;
                    if(lastIndex+diff>_trs.length){
                        diff=Math.max(_trs.length-lastIndex,0);
                    }

                    for(let i=0;i<diff;i++){
                        trs.item(i).remove();
                        if(lastIndex+i<_trs.length){
                            let tr=_trs.item(lastIndex+i);
                            this.addEvent(tr);
                            tbody.appendChild(tr.cloneNode(true));
                        }
                    }
                }else{//向上滚动
                    diff=Math.abs(diff);
                    if(fistIndex-1-diff<0){
                        diff=fistIndex;
                    }
                    for(let i=0;i< diff;i++){
                        tbody.lastElementChild.remove();
                        if(fistIndex-1-i>=0){
                            let tr=_trs.item(fistIndex-1-i);
                            this.addEvent(tr);
                            tbody.insertBefore(tr.cloneNode(true),tbody.firstElementChild);
                        }
                    }
                }
            }else{
                Array.from(trs).forEach((tr,index)=>{
                    let _tr=_trs.item(startIndex+index);
                    if(_tr){
                        tr.dataset.vid=_tr.dataset.vid;
                        tr.className=_tr.className;
                        if(_tr.getAttribute("style")!=tr.getAttribute("style")){
                            tr.setAttribute("style",_tr.getAttribute("style")||"")
                        }
                        
                        Array.from(tr.children).forEach((td,tdIndex)=>{
                            let _td= _tr.children.item(tdIndex);
                            td.className=_td.className;
                            if(_td.getAttribute("style")!=td.getAttribute("style")){
                                td.setAttribute("style",_td.getAttribute("style")||"")
                            }
                            td.rowSpan=_td.rowSpan;
                            td.colSpan=_td.colSpan;
                            
                            td.innerHTML="";
                            td.append(_cells[startIndex+index][tdIndex]);
                        })
                    }
                })
            }

            Array.from(tbody.children||[]).forEach(tr=>{
                let id=Number(tr.dataset.vid)
                if(tr.clientHeight>0){
                    _trs.item(id)._height=tr.clientHeight;
                }
            })

            this.setFixed();
        },
        /**设置固定列数据 */
        setFixed(){
            let elTable=this.$refs.elTable;
            let fixedBodyWrapper=elTable.$refs?.fixedBodyWrapper;
            if(fixedBodyWrapper){
                let bodyWrapper=elTable.$refs?.bodyWrapper;
                let fixedTBody=fixedBodyWrapper.querySelector('.el-table__body tbody');
                let fixedTrs= fixedTBody.children;
                let tbody=bodyWrapper.querySelector('.el-table__body tbody');
                let trs= tbody.children;
                Array.from(fixedTrs).forEach((tr,index)=>{
                    let _tr=trs.item(index);
                    tr.dataset.vid=_tr.dataset.vid;
                    tr.className=_tr.className;
                    tr.setAttribute("style",_tr.getAttribute("style")||"");
                    tr.style.height=_tr.clientHeight+"px";
                    Array.from(tr.children).forEach((td,tdIndex)=>{
                        let _td= _tr.children.item(tdIndex);
                        td.className=_td.className;
                        td.setAttribute("style",_td.getAttribute("style")||"")
                        td.rowSpan=_td.rowSpan;
                        td.colSpan=_td.colSpan;
                        td.innerHTML="";
                        td.append(_td.children[0]);
                    })
                })
            }
        },
        //添加事件
        addEvent(tr){
            let elTable=this.$refs.elTable;
            let tableHeader=elTable.$refs?.tableHeader;
            tr.addEventListener("click",(e)=>{
                let id=Number(tr.dataset?.vid);
                let column,cell;
                if(e.target!==tr){
                    let index= Array.from(tr.children).findIndex(it=>it.contains(e.target));
                    column=tableHeader?.columns[index];
                    cell=tr.children.item(index);
                }
                this.$emit("cell-click",this.data[id], column,cell, e)
            });
            tr.addEventListener("dblclick",(e)=>{
                let id=Number(tr.dataset?.vid);
                let column,cell;
                if(e.target!==tr){
                    let index= Array.from(tr.children).findIndex(it=>it.contains(e.target));
                    column=tableHeader?.columns[index];
                    cell=tr.children.item(index);
                }
                this.$emit("cell-dblclick",this.data[id], column, cell, e)
            });

            tr.addEventListener("mouseenter",(e)=>{
                let id=Number(tr.dataset?.vid);
                let column,cell;
                if(e.target!==tr){
                    let index= Array.from(tr.children).findIndex(it=>it.contains(e.target));
                    column=tableHeader?.columns[index];
                    cell=tr.children.item(index);
                }
                tr.classList.add("hover-row");
                this.$emit("cell-mouse-enter",this.data[id], column, cell, e)
            });

            tr.addEventListener("mouseleave",(e)=>{
                let id=Number(tr.dataset?.vid);
                let column,cell;
                if(e.target!==tr){
                    let index= Array.from(tr.children).findIndex(it=>it.contains(e.target));
                    column=tableHeader?.columns[index];
                    cell=tr.children.item(index);
                }
                tr.classList.remove("hover-row")
                this.$emit("cell-mouse-leave",this.data[id], column, cell, e)
            });

            tr.addEventListener("contextmenu",(e)=>{
                let id=Number(tr.dataset?.vid);
                let column,cell;
                if(e.target!==tr){
                    let index= Array.from(tr.children).findIndex(it=>it.contains(e.target));
                    column=tableHeader?.columns[index];
                    cell=tr.children.item(index);
                }
                this.$emit("cell-contextmenu",this.data[id], column, cell, e)
            });
            
        }
    },
    unmounted(){
        resizeEvent.removeResizeListener(this.$el);
    },
    beforeUnmount(){
        
        if(this._tableBody){
            Array.from(this._tableBody.children).forEach(tr=>{
                Array.from(tr.children).forEach(td=>{
                    render(null,td);
                })
            })
        }
    }
}
</script>

<style>

</style>