<template>
    <div class="minFoodDisplay" v-dom-resize @resize="resize()" :style="style">
        <div class="flex-scale-box" v-show="isScale">
            <div class="title">{{foodCategory?.SubCategory_Name}}</div>
            <div class="scale-box" v-touch="slideX">
                <div class="food-box">
                    <div class="li" v-for="{item,index} in scaleFoods" :key="item" :style="tXArr[index-scaleIndex+2]" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                        <i class="close-bnt iconfont icon-a-bg-close3x" @click="close(index)" v-if="index==scaleIndex"></i>
                        <div class="picture">
                            <el-image :src="item.Food_SmallImage" fit="fill">
                                <template #placeholder>
                                    <div class="image-slot verticalCenter">Loading<span class="dot">...</span></div>
                                </template>
                                <template #error>
                                    <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png)'}"></div>
                                </template>
                            </el-image>
                        </div>
                        <div class="text-box">
                            <div class="displayFlex">
                                <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}</div>
                                <div class="tag-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}{{item.Food_Size}}</div>
                            </div>
                            <div class="money-box">
                                <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                    <span class="price"><span class="unit">￥</span>{{item.Food_Price}}</span><span class="unit">/{{item.Food_Size}}</span>
                                </div>
                                <div class="bnt-box" v-if="item.Is_MultipleSize">
                                    <div class="nultiUnit-bnt" @click="addItem(item)">选规格<span class="num" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</span></div>
                                </div>
                                <div class="bnt-box" v-else>
                                    <div class="remove" @click="removeItem(item)" :class="{disabled:!(cartNumber[item.Food_ID]?.Eat_Number>1)}" v-if="cartNumber[item.Food_ID]?.Eat_Number>0"><i class="iconfont icon-jian"></i></div>
                                    <div class="number" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</div>
                                    <div class="add" @click="addItem(item)"><i class="iconfont icon-jia"></i></div>
                                </div>
                            </div>
                            <div class="proMoney" v-if="item.Is_Promotion">
                                <span class="tag">促</span>
                                <span class="unit">￥</span>
                                <span>{{item.Promotion_Price}}</span>
                            </div>
                            <!-- vip 价格 -->
                            <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                <div class="vipTag">VIP</div>
                                <div class="price"> 
                                    <div class="unit">￥</div>{{item.Food_VipPrice}}
                                </div>
                            </div>
                            <div class="sellout"><img src="/images/soldout.png"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="indicators-box"  v-mouse-scroll>
                <div class="li" :class="{selected:scaleIndex==item-1}" v-for="item in this.foodCategory?.PosFoods?.length" :key="item" @click="goScale(item)"></div>
            </div>
        </div>
        <el-scrollbar class="scroll-box"  max-height="100%"  ref="scrollBox" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}" v-show="!isScale">
            <div class="visual-box" ref="layoutCol" :style="{height:scrollH+'px',paddingTop:marginTop+'px'}">
                <div class="category-box" v-for="(category,index) in visualData" :key="index">
                    <div class="title" v-if="category.isTitle">{{category.SubCategory_Name}}</div>
                    <div class="food-box clearfix">
                        <div class="li" v-for="item in category.PosFoods" :key="item" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                            <div class="picture" @click="clickScale(category,item)">
                                <el-image :src="item.Food_SmallImage" fit="fill" lazy>
                                    <template #placeholder>
                                        <div class="image-slot verticalCenter">Loading<span class="dot">...</span></div>
                                    </template>
                                    <template #error>
                                        <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png)'}"></div>
                                    </template>
                                </el-image>
                            </div>
                            <div class="text-box">
                                <div class="displayFlex">
                                    <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}</div>
                                    <div class="tag-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}{{item.Food_Size}}</div>
                                </div>
                                <div class="money-box">
                                    <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                        <span class="price"><span class="unit">￥</span>{{item.Food_Price}}</span><span class="unit">/{{item.Food_Size}}</span>
                                    </div>
                                    <div class="bnt-box" v-if="item.Is_MultipleSize">
                                        <div class="nultiUnit-bnt" @click="addItem(item)">选规格<span class="num" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</span></div>
                                    </div>
                                    <div class="bnt-box" v-else>
                                        <div class="remove" @click="removeItem(item)" :class="{disabled:!(cartNumber[item.Food_ID]?.Eat_Number>1)}" v-if="cartNumber[item.Food_ID]?.Eat_Number>0"><i class="iconfont icon-jian"></i></div>
                                        <div class="number" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</div>
                                        <div class="add" @click="addItem(item)"><i class="iconfont icon-jia"></i></div>
                                    </div>
                                </div>
                                <div class="proMoney" v-if="item.Is_Promotion">
                                    <span class="tag">促</span>
                                    <span class="unit">￥</span>
                                    <span>{{item.Promotion_Price}}</span>
                                </div>
                                <!-- vip 价格 -->
                                <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                    <div class="vipTag">VIP</div>
                                    <div class="price"> 
                                        <div class="unit">￥</div>{{item.Food_VipPrice}}
                                    </div>
                                </div>
                                <div class="sellout"><img src="/images/soldout.png"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
/**小图模式 */
export default {
    name:"minFoodDisplay",
    emits:["currentChange","removeItem","addItem"],
    props:{
        /**菜品 */
        foodCategoryData:{
            type:Array,
            default:null
        },
        /**当前选中分类 */
        currentIndex:{
            type:Number,
            default:0
        },
        /**购物车 菜品 数量 */
        cartNumber:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            style:{},
            /**每行菜品高度 */
            trH:310,
            visualH:768,
            /**滚动条位置 */
            scrollTop:0,
            visualData:[],
            /**是否放大左右滑动 */
            isScale:false,
            /**当前分类的菜品 */
            foodCategory:null,
            /**放大样式 */
            scaleStyle:{},
            /**左右滑动 当前位置 */
            scaleIndex:0,
            /**滑动动画 */
            tXArr:[
                {transform:"translateX(-140%) scale(0.7)"},
                {transform:"translateX(-105%) scale(0.8)"},
                {transform:"translateX(0) translateY(-20px) scale(1.05)", zIndex: 1},
                {transform:"translateX(105%) scale(0.8)"},
                {transform:"translateX(140%) scale(0.7)"},
            ]
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        /**滚动条高度 */
        scrollH(){
            let height=0;
            this.foodCategoryData?.forEach(it=>{
                height+=30+2;//分类高
                let num=Math.ceil(it.PosFoods.length/4);
                height+=(this.trH+24)*num;
            })
            return height;
        },
        marginTop(){
            if(this.visualData?.length>0){
                return this.visualData[0].top;
            }
            return 0;
        },
        /**分类 */
        navIndex(){
            if(this.scrollTop>0 && this.foodCategoryData.length>0){
                let height=0;
                for(let i=0;i<this.foodCategoryData.length;i++){
                    let category=this.foodCategoryData[i];
                    let trH=30+2;//分类高
                    let num=Math.ceil(category.PosFoods.length/4);
                    let fooodH=(this.trH+24)*num;
                    if(this.scrollTop>=height && this.scrollTop<height+trH+fooodH){
                        return i;
                    }
                    height+=trH+fooodH;
                }
            }
            return 0;
        },
        /**左右滑动 分类菜品 */
        scaleFoods(){
            let data=[];
            if(this.foodCategory?.PosFoods?.length>1){
                let end=this.scaleIndex+3;
                let start=Math.max(this.scaleIndex-2,0);
                data=this.foodCategory?.PosFoods.slice(start,end).map((item,index)=>{
                    return {item,index:index+start}
                });
                return data;
            }
            return (this.foodCategory?.PosFoods||[]).map((item,index)=>{
                    return {item,index}
                });
        },
    },
    watch:{
        currentIndex:{
            handler(){
                setTimeout(()=>{
                    if(this.navIndex!=this.currentIndex && this.foodCategoryData.length>0){
                        let height=0;
                        for(let i=0;i<this.currentIndex;i++){
                            let category=this.foodCategoryData[i];
                            let trH=30+2;//分类高
                            let num=Math.ceil(category.PosFoods.length/4);
                            let fooodH=(this.trH+24)*num;
                            height+=trH+fooodH;
                        }
                        if(this.$refs.scrollBox?.wrap){
                            this.$refs.scrollBox.wrap.scrollTop=height;
                            console.log(height)
                        }
                    }
                },0)
            },
            immediate:true
        },
        scrollH(){
            this.setVisualData();
        },
        scrollTop(){
            this.setVisualData();
        },
        visualH(){
            this.setVisualData();
        },
        navIndex(){
            if(this.navTime){
                clearTimeout(this.navTime);
            }
            this.navTime=setTimeout(() => {
                this.$emit("currentChange",this.navIndex);
            }, 100);
        },
        foodCategoryData(){
            this.isScale=false;
            this.setVisualData(true);
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.resize();
            this.setVisualData();
            if(this.$refs.scrollBox.wrap){
                this.$refs.scrollBox.wrap.addEventListener("scroll",(e)=>{
                    this.scrollTop=e.srcElement.scrollTop;
                })
            }
        })
    },
    methods:{
        /**计算宽高 */
        resize(){
            if(this.$refs.scrollBox?.scrollbar){
                let scrollbar=this.$refs.scrollBox?.scrollbar;
                let width= scrollbar.offsetWidth-10;
                let height= scrollbar.offsetHeight;
                let w=(width/4-8);
                if(w>0){
                    this.style["--liW"]=w+"px";
                }
                let h=((height-32)/2-24);
                if(h>0){
                    this.style["--liH"]=h+"px";
                    this.trH=h;
                    this.visualH=height;
                }
                if(height>0){
                    this.visualH=height;
                }
            
            }
        },
        /**可视数据 */
        setVisualData(isRefresh){
            if(this.time){clearTimeout(this.time);}
            this.time=setTimeout(()=>{
                let diffH=this.visualH;//缓冲区高度
                if(this.foodCategoryData?.length>0){
                    let data=[];
                    let height=0;
                    for(let i=0;i<this.foodCategoryData?.length;i++){
                        let category=this.foodCategoryData[i];
                        
                        let json={SubCategory_ID:category.SubCategory_ID,SubCategory_Name:category.SubCategory_Name,isTitle:false}
                        if(this.scrollTop-diffH<=height && this.scrollTop+this.visualH+diffH>=height){
                            json.isTitle=true;
                            json.PosFoods=[];
                            json.top=height;
                            data.push(json);
                        }
                        height+=30+2;//分类高
                        
                        let num=Math.ceil(category.PosFoods.length/4);
                        for(let j=0;j<num;j++){
                            if(this.scrollTop-diffH<=height && this.scrollTop+this.visualH+diffH>=height){
                                if(!json.isTitle && !json.PosFoods){
                                    json.PosFoods=[];
                                    json.top=height;
                                    data.push(json);
                                }
                                json.PosFoods=json.PosFoods.concat(category.PosFoods.slice(j*4,(j+1)*4));
                            }
                            height+=(this.trH+24);
                        }
                    }
                    if(isRefresh || data.length!=this.visualData.length || (data.length>0 && data[0].top!=this.marginTop)){
                        this.visualData=data;
                    }
                }else{
                    this.visualData=[];
                }
            },10);
            
        },
        goScale(index){
            this.scaleIndex=index-1;
        },
        /**点击图片 方法 */
        clickScale(category,item){
            this.foodCategory=this.foodCategoryData.find(it=>it.SubCategory_ID==category.SubCategory_ID);
            if(this.foodCategory){
                let index=this.foodCategory.PosFoods.findIndex(it=>it.Food_ID==item.Food_ID);
                this.isScale=true;
                this.goScale(index+1);
            }
        },
        /**关闭放大轮播 */
        close(index){
            this.isScale=false;
            /**计算小图滚动位置 */
            let height=0;
            for(let i=0;i<this.foodCategoryData.length;i++){
                let it=this.foodCategoryData[i];
                height+=30+2;//分类高
                if(this.foodCategory.SubCategory_ID!=it.SubCategory_ID){
                    let num=Math.ceil(it.PosFoods.length/4);
                    height+=(this.trH+24)*num;
                }else{
                    height+=(this.trH+24)* parseInt(index/4);
                    break;
                }
            }
            this.$nextTick(()=>{
                if(this.$refs.scrollBox?.wrap){
                    this.$refs.scrollBox.wrap.scrollTop=height;
                    console.log(height)
                }
            });
        },
        /**左右滑动 */
        slideX(e,fx){
            if(fx=="right" && this.scaleIndex>0){
                this.scaleIndex--
            }else if(fx=="left" && this.scaleIndex<this.foodCategory?.PosFoods?.length-1){
                this.scaleIndex++
            }
            
        },
        removeItem(item){
            this.$emit("removeItem",item)
        },
        addItem(item){
            this.$emit("addItem",item)
        }
    }
}
</script>

<style lang="scss">
    @import './minFoodDisplay.scss'
</style>