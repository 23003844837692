<template>
    <modal class="fastKunLunMemberPayModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">昆仑会员支付<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body" >
            <div ref="CardMoney">
            <div class="filter-nav">
                <div class="nav-box">
                    <tab-nav class="nav-li">按会员卡号支付</tab-nav>
                </div>
            </div>
             <div></div>
            <div class="clearfix" >
                <div class="left-box">
                    <tab-pane>
                        <div class="tr-li">
                            请扫码或输入付款码：
                            <div class="from-input" style="width:325px">
                                <input type="text" ref="input" class="key-focus" data-model='idCode' v-model="idCode" @keyup.enter="onMemberInfo()" />
                                <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                            </div>
                            <button class="btn" @click="readCard()">读卡</button>
                        </div>
                        <div class="table-box">
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo.Card_No}}</span></div>
                                <div class="lable-txt" style="width:200px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo.Bestech_Card_KindName}}</span></div>
                                <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo.MemberName}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">
                                    <span style="letter-spacing:0.5em;">手机</span>号:
                                    <span style="color: #000;font-size: 14px;">{{meberInfo.Mobile}}</span>
                                </div>
                                <div class="lable-txt" style="width:200px;">
                                    性<span style="padding-left:25px;">别</span>：
                                    <span style="color: #000;font-size: 14px;" v-if="meberInfo.Gender>-1">{{meberInfo.Gender==0?'男':'女'}}</span>
                                </div>
                                <div class="lable-txt" style="width:145px;">生日：<span style="color: #000;font-size: 14px;">{{meberInfo.Birthday}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">
                                    余<span style="padding-left:25px;">额</span>:
                                    <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo.Card_Money>0">{{Number(meberInfo.Card_Money)}}</span>
                                    <span style="color: red;" v-if="meberInfo.Gift_Money>0" >（赠{{Number(meberInfo.Gift_Money)}}）</span>
                                </div>
                                <div class="lable-txt" style="width:200px;">
                                    <span style="letter-spacing:0.6em;">可用</span>券：
                                    <span style="color: red;font-size: 17px;font-weight:600;">{{meberInfo.Live_CouponsNum}}</span>
                                    <span style="color: red;padding-left:5px;" v-if="meberInfo.Live_CouponsNum">张</span>
                                </div>
                                <div class="lable-txt" style="width:145px;">积分：
                                    <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo.Card_PilePoint>0">{{Number(meberInfo.Card_PilePoint)}}</span>
                                </div>
                            </div>
                        </div>
                        <tabs class="coupons-box">
                            <div class="filter-nav">
                                <div class="nav-box">
                                    <tab-nav class="nav-li">代金券</tab-nav>
                                    <tab-nav class="nav-li">商品券</tab-nav>
                                </div>
                                <div class="float-right">
                                    <div class="bnt-fangxiang" ref="item-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                    <div class="bnt-fangxiang" ref="item-down"><i class="iconfont icon-fangxiang-down"></i></div>
                                </div>
                            </div>
                            <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',paddingH:8,marginH:12,isRow:false}">
                                <tab-pane class="tab-pane">
                                    <div class="item-li " :class="{selected:item.Check==true}" v-for="item in cashCoupon" :key="item" @click="oncashClick(item)">
                                        <div class="title">{{item.Coupon_Name}}</div>
                                        <div class="top-tag">
                                            <div class="left">{{item.Coupon_Code}}</div>
                                            <div class="right">{{Number(item.Coupon_FaceValue)}}</div>
                                        </div>
                                        <div class="left-bottom-tag">
                                            {{item.Begin_Date}}<br/>
                                            {{item.End_Date}}
                                        </div>
                                         <i class="iconfont icon-gou"></i>
                                    </div>
                                </tab-pane>
                                <tab-pane class="tab-pane">
                                    <div class="item-li " :class="{selected:item.Check==true}" v-for="item in commodityVoucher" :key="item" @click="oncashClick(item)">
                                        <div class="title">{{item.Coupon_Name}}</div>
                                        <div class="top-tag">
                                            <div class="left">{{item.Coupon_Code}}</div>
                                            <div class="right">{{discountShow(item)}}</div>
                                        </div>
                                        <div class="left-bottom-tag">
                                            {{item.Begin_Date}}<br/>
                                            {{item.End_Date}}
                                        </div>
                                         <i class="iconfont icon-gou"></i>
                                    </div>
                                </tab-pane>
                            </div>
                        </tabs>
                    </tab-pane> 
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="money-info">
                <div class="lable-txt">待支付金额：
                    <b class="noney red">{{paymentMoney}}</b>
                </div>
                <div class="lable-txt">储值支付：
                    <input-pattern type="text" class="key-focus" style="color:red;" 
                        pattern="number"
                        :dplaces="2"
                        ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()"/>  
                </div>
                <div class="lable-txt">积分支付：
                    <input-pattern type="text" class="key-focus" style="color:red;" 
                        pattern="number"
                        :dplaces="0"
                        :placeholder="PointPay_Scale" 
                        ref="PointPayinput" data-model='couponsPay' 
                        v-model="PointPay" @click="onIntegralClick()"/>
                </div>
                <div class="lable-txt">券抵扣：<b class="red">{{couponsMoney.toFixed(2)}}</b></div>
            </div>
            </div>
        </tabs>
        <div class="footer-box">
            <!-- <div style="position:absolute;width:91%;height:10%;"></div> -->
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询支付结果</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <!--会员卡信息-->
        <modal-load :isShow="cardMemberShow">
            <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
        </modal-load>
        <modal-load :isShow="cardCodeShow">
            <card-code-model :isShow="cardCodeShow" v-on:closeModel="cardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
        </modal-load>
        <!--密码验证-->
        <modal-load :isShow="passwordShow">
            <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
        </modal-load>
    </modal>
</template>


<script>
import cardCodeModel from '../../diningDetail/model/cardCodeModel.vue'
import passwordConfirmModel from '../../diningDetail/model/passwordConfirmModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
import { newGuid } from '/src/common/index.js'


/**会员支付 */
export default {
    name:"newEnjoyShowModel",
    components:{
        cardMemberModel,
        cardCodeModel,
        passwordConfirmModel
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        payType:Object,
        //订单 待支付金额
        snackPaidMoney:Number,
        orderMenuList:Object,
        MantissaMoney:Number,
        headingInfo:Object,
        Channellike:String,
        RoomAutoID:String,
        DeskName:String,
        paymentHistory:Object,
        EatAutoIDs:String,
        Timestamps:String,
        totalMoney:Number,
        discountMoneuy:Number
    },
    data(){
        return {
            cardMemberShow:false,

            cardCodeShow:false,
            passwordShow:false,
            is_AllowMixUsing:false, //是否有不可混用券
           
            is_AllowMixUsingCoupon_Name:'', //不可混用券
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            cashCoupon:'', //代金券
            commodityVoucher:'',//商品券
            couponsCount:0,  //选择的券总数量
            couponsMoney:0, //券的抵扣金额
            unPaidMoney:0, //待付金额

            couponsPay:'', //储值支付金额
            PointPayMoney:0,//积分支付金额
            PointPay:'', //积分支付积分

            choiceCoupons:[], //选择的券
            errCounc:1,
            memberorderInfo:'',  //临时订单数据
            

            ordertimestamps:'',
            eatAutoID:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           initKeyBoard.call(this,this.$refs.zf);
           this.openimp()
        })
    },
    computed:{
        /**积分 抵扣比例 */
        PointPay_Scale(){
            if(this.meberInfo){
               return  this.meberInfo.PointPay_Scale+':1';
            }
            return "";
        },
        //剩余要支付的金额
        paymentMoney(){
            if(this.unPaidMoney>0){
                //待支付 金额  =  订单金额 - 储值支付金额 - 积分抵扣金额 - 卡券抵扣金额
                return Number(Number(this.snackPaidMoney).compute(this.couponsPay,this.PointPayMoney,this.couponsMoney,'-').toFixed(2));
            }
            return 0;
        },
    },
    watch: {
        snackPaidMoney:{
            immediate:true,
            handler(vla){
                this.EmptyDatas();
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
                if(this.memberorderInfo){
                    // this.memberorderInfo.map((order,i)=>{
                    //     order = Object.assign(order,{isCoupon:false,CouponNumber:0})
                    //     if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                    //         this.memberorderInfo.splice(i, 1)
                    //         return 
                    //     }
                    // })
                    for(let i=0; i<this.memberorderInfo.length; i++){
                        this.memberorderInfo[i] = Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                        if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                            this.memberorderInfo.splice(i,1)
                            i--
                        }
                    }
                }
                this.unPaidMoney=this.snackPaidMoney;//待支付金额
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.openimp()
            }
        },
        //储值支付金额 变动
        couponsPay(){
            if(!this.meberInfo){
                return;
            }
            if(this.couponsPay<0){
               this.couponsPay=0
            }else{
                if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                    this.$message.warning('您的可用余额为:'+this.meberInfo.Card_Money+'');
                    this.$nextTick(()=>{
                        this.couponsPay=Number(this.meberInfo.Card_Money);
                    })
                    return
                }
                //待支付金额 - 券抵扣金额
                let unPaidMoney=Number(this.snackPaidMoney).compute(this.couponsMoney,'-');
                if(unPaidMoney<0){
                    unPaidMoney=0;
                }
                if(unPaidMoney<Number(this.couponsPay).compute(this.PointPayMoney)){//积分支付金额+储值支付金额 超过待支付金额
                    if(unPaidMoney<Number(this.couponsPay)){//储值支付金额 超过 待支付金额
                        this.$nextTick(()=>{
                            this.couponsPay=unPaidMoney;//最多抵扣金额
                        })
                        return
                    }
                }
            }

            let money=Number(Number(this.snackPaidMoney).compute(this.couponsMoney,this.couponsPay,'-').toFixed(2));//剩余要支付的金额
            if(money<this.PointPayMoney){//积分支付金额 大于 剩余要支付的金额
                if(money<0){
                    money=0;
                }
                this.PointPayMoney=money;
                this.PointPay=this.PointPayMoney*(this.meberInfo.PointPay_Scale||1);//最多输入积分
            }
            
        },
        //支付积分变动
        PointPay(){
            if(!this.meberInfo){
                return;
            }
            if(this.PointPay<0){
               this.PointPay=0;
               this.PointPayMoney=0;
            }else{
                if(Number(this.PointPay)>this.meberInfo.Card_PilePoint){
                    this.$message.warning('您的可用积分余额为:'+this.meberInfo.Card_PilePoint+'');
                    this.$nextTick(()=>{
                        this.PointPay=Number(this.meberInfo.Card_PilePoint);
                    })
                    return;
                }
                
                if(this.meberInfo.PointPay_Scale){//换算出 积分抵扣金额
                    this.PointPayMoney = Number((Number(this.PointPay) / this.meberInfo.PointPay_Scale).toFixed(2));//抵扣金额 只用两位小数位
                }
                
                //待支付金额 - 券抵扣金额
                let unPaidMoney=Number(Number(this.snackPaidMoney).compute(this.couponsMoney,'-').toFixed(2));
                if(unPaidMoney<0){
                    unPaidMoney=0;
                }
                if(unPaidMoney<Number(this.PointPayMoney).compute(this.couponsPay)){//积分支付金额+储值支付金额 超过待支付金额
                    if(unPaidMoney<Number(this.PointPayMoney)){//积分支付金额 超过 待支付金额
                        
                        this.$nextTick(()=>{
                            this.PointPayMoney=unPaidMoney;//最多抵扣金额
                            this.PointPay=this.PointPayMoney*(this.meberInfo.PointPay_Scale||1);//最多输入积分
                        })
                        return;
                    }
                }

            }
            
            let money=Number(Number(this.snackPaidMoney).compute(this.couponsMoney,this.PointPayMoney,'-').toFixed(2));//剩余要支付的金额
            if(money>=0){
                if(money>this.meberInfo.Card_Money){
                    money=this.meberInfo.Card_Money;
                }
                this.couponsPay=money;//储值支付 金额
            }
        },
        /**券抵扣金额变动 */
        couponsMoney(){
            if(!this.meberInfo){
                return;
            }
            if(this.paymentMoney<0){//
                if(this.couponsPay+this.paymentMoney>0){
                    this.couponsPay=this.couponsPay+this.paymentMoney;
                }else if(this.couponsPay!=0){
                    this.couponsPay=0;
                }else{
                    this.PointPay = this.PointPay+ this.paymentMoney*this.meberInfo.PointPay_Scale;
                }
            }else if(this.paymentMoney>0){
                this.couponsPay=this.couponsPay+this.paymentMoney;
            }
        }
    },
    methods:{
        openimp(){
            this.EmptyDatas();
            this.idCode='',this.errorTitle='',this.PayResultCode=0
            this.initializaTion()
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
            if(this.memberorderInfo){
                for(let i=0; i<this.memberorderInfo.length; i++){
                    Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                    if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                        this.memberorderInfo.splice(i,1)
                        i--
                    }
                }
            }

            this.unPaidMoney=this.snackPaidMoney;
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**查询支付结果 */
        select(){
            if(this.EatAutoIDs!=''){
                this.eatAutoID = this.EatAutoIDs
                this.ordertimestamps = this.Timestamps
            }
            if(this.eatAutoID==''){
                this.$message.warning('没有需要查询的支付结果');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.eatAutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Site_Serial, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.ordertimestamps.toString(), //账单时间戳
            }
            if(this.ordertimestamps!=''){
                param.Timestamps = this.ordertimestamps.toString();
            }
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    this.ispaySuccess=1
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.ispaySuccess=0
                        this.payCode=''
                        this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                    }
                    return;
                }else{
                    let ResultDesc = '未查询到订单的支付结果'
                    if(d.ResponseHeader.ResultDesc==''){
                        d.ResponseHeader.ResultDesc = ResultDesc
                    }
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.ordertimestamps = d.ResponseBody.Timestamps;
                            this.ispaySuccess=0;
                            this.$emit("ThePaymentResultClic",d,this.payCode)
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(this.PayResultCode==1){
                this.$message.warning('有待支付的订单,无法调整');
                return
            }
            if(!this.idCode){
                this.$message.warning('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Member_Type:22,//昆仑Lps会员
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetCouponUsingRules:true,
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                        if(this.meberInfo.OverDraftLimitedMoney){
                            this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
                        }
                        this.cardInfoShow()
                        this.$emit('memberCardBack',this.meberInfo)
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**券选择 */
        oncashClick(item){
            if(this.PayResultCode==1){
                this.$message.warning('有待支付的订单,无法调整');
                return
            }
            if(item.Check==true){
                if(item.Coupon_Kind==2){
                    this.memberorderInfo.forEach(order=>{
                        this.choiceCoupons.forEach(cho=>{
                            if(order.Eat_ConsumeID==cho.Eat_ConsumeID && item.Coupon_AutoID==cho.Coupon_AutoID){
                                if(order.CouponNumber>=1){
                                    order.CouponNumber = order.CouponNumber -1
                                }
                                if(order.CouponNumber<order.Eat_Number){
                                    order.isCoupon=false
                                }
                            }
                        })
                    })
                    this.commodityJudge(item,2)
                }else{
                    this.couponsMoney = Number(this.couponsMoney)-Number(item.Coupon_FaceValue)
                }
                this.couponsCount--;
                item.Check =false;
                this.choiceCoupons = this.choiceCoupons.filter(t => t.Coupon_AutoID != item.Coupon_AutoID)
                /**券的使用规则 */
                if(item.CouponUsingDetailedRules){
                    /**是否可以混用 */
                    let tktype=0
                    this.choiceCoupons.map(ch=>{
                        if(ch.Tk_AutoID==item.Tk_AutoID){
                            tktype=1
                        }
                    })
                    if(this.is_AllowMixUsing==true && tktype!=1){
                        this.is_AllowMixUsing = false;
                    }
                }
            }else{
                if(Number(this.snackPaidMoney).compute(this.couponsMoney,'-')<=0){
                    this.$message.warning('付款金额已够,无需再选用多余的券');
                    return
                }
                
                 /**券的使用规则 */
                if(item.CouponUsingDetailedRules){
                    this.errCounc =1
                    let _count=0;
                    //每次消费最多可用张数
                    let _UseMaxNum = item.CouponUsingDetailedRules.UseMaxNum;
                    if(_UseMaxNum!=0){
                        this.choiceCoupons.map(cho=>{
                            if(cho.Tk_AutoID==item.Tk_AutoID){
                                _count++;
                            }
                        })
                        if(_count>=_UseMaxNum){
                            this.$message.warning('超出每次消费使用次数');
                            this.errCounc++
                            return
                        }
                    }
                    //判断是否存在不可混用券
                    if(this.is_AllowMixUsing==true){
                        if(item.Coupon_Name!=this.is_AllowMixUsingCoupon_Name){
                            this.$message.warning(this.is_AllowMixUsingCoupon_Name+'不可与其他券混用');
                            this.errCounc++
                            return;
                        }
                    }
                    //判断是否存在部分不可混用的券
                    if(this.couponsCount>0){
                        let _coupname=''
                        let _coupType=0
                        this.choiceCoupons.map(cho=>{
                            if(cho.CouponUsingDetailedRules){
                                if(cho.CouponUsingDetailedRules.Is_AllowMixUsing==3){
                                    cho.CouponUsingDetailedRules.ForbidMixUsingCoupons.map(coupon=>{
                                        if(coupon.TK_AutoID == item.Tk_AutoID){
                                            _coupType=1
                                            _coupname=''+item.Coupon_Name+'不可与'+cho.Coupon_Name+'混用'
                                            this.errCounc++
                                            return;
                                        }
                                    })
                                }
                            }            
                        })
                        if(_coupType==1){
                             this.$message.warning(_coupname);
                             return;
                        }
                    }
                    //是否可以混用 1.可以 2.不可以 3.部分不可以
                    if(item.CouponUsingDetailedRules.Is_AllowMixUsing==2){
                        if(this.couponsCount>0){
                            let _coupname=''
                            let _coupType=0
                            this.choiceCoupons.map(cho=>{
                                if(cho.Tk_AutoID!=item.Tk_AutoID){
                                    _coupType=1
                                    _coupname=item.Coupon_Name+'不可与其他券混用'
                                    this.errCounc++
                                    return;
                                }   
                            })
                            if(_coupType==1){
                                this.$message.warning(_coupname);
                                return;
                            }   
                        }
                    }else if(item.CouponUsingDetailedRules.Is_AllowMixUsing==3){
                        if(this.couponsCount>0){
                            let _coupname=''
                            let _coupType=0
                            item.CouponUsingDetailedRules.ForbidMixUsingCoupons.map(pons=>{
                                this.choiceCoupons.map(cho=>{
                                    if(pons.TK_AutoID==cho.Tk_AutoID){
                                        _coupType=1
                                        _coupname=''+item.Coupon_Name+'不可与'+cho.Coupon_Name+'混用'
                                        this.errCounc++
                                        return;
                                    }
                                })
                            })
                            if(_coupType==1){
                                this.$message.warning(_coupname);
                                return;
                            }
                        }
                    }
                    //判断日期
                    if(item.CouponUsingDetailedRules.UseWeeks){
                        let _week = RegExp(this.setNowTimes());
                        let _UseWeeks = item.CouponUsingDetailedRules.UseWeeks;
                        if(!_week.test(_UseWeeks)){
                            this.$message.warning('该券今日不可使用');
                            this.errCounc++
                            return;
                        }
                    }
                    //时间段控制 0所有时段,1指定时段
                    if(item.CouponUsingDetailedRules.TimeFrame_ControlType==1){
                        let date = new Date(); //当前时间
                        let hh = date.getHours()+':'+date.getMinutes(); //时:分
                        let hm =hh.split(':')
                        let timeError =''
                        if(item.CouponUsingDetailedRules.UsingTimeFrames.length>0){
                            item.CouponUsingDetailedRules.UsingTimeFrames.map(time=>{
                                let Begin_Time = time.Begin_Time.split(':')
                                let End_Time = time.End_Time.split(':')
                                if(date.setHours(Begin_Time[0],Begin_Time[1]) > date.setHours(hm[0],hm[1]) || date.setHours(hm[0],hm[1]) > date.setHours(End_Time[0],End_Time[1])){
                                    timeError ='该券当前时间不可使用'
                                    this.errCounc++
                                }
                            })
                        }else{
                            timeError ='该券当前时间不可使用'
                            this.errCounc++
                        }
                        if(timeError!=''){
                            this.$message.warning(timeError);
                            return
                        }
                    }
                    //节假日控制 0 不限制节假日   1 剔除指定的节假日
                    if(item.CouponUsingDetailedRules.Holiday_ControlType==1){
                        let _timeName=''
                        let _timeType=0
                        let now =  new Date(); //当前时间
                        let time =  now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()
                        item.CouponUsingDetailedRules.ForbidUsingCouponHolidays.map(days=>{
                            days.Holiday_Dates.map(date=>{
                                if(new Date(time).Format("yyyy-MM-dd") ==date){
                                    _timeType=1
                                    _timeName='该券不可在'+days.Holiday_Name+'使用'
                                    this.errCounc++
                                    return
                                }
                            })
                        })
                        if(_timeType==1){
                            this.$message.warning(_timeName);
                            return
                        }
                    }
                    //商品券
                    if(item.Coupon_Kind==2){
                        let _coupTypes = 0
                        let _coupname=''
                        let _coupType=0
                        let Eat_Number=0
                        let Eat_ConsumeID=''
                        //如果是折扣券
                        if(item.DiscountType==2){
                            let isFindFood=0
                            this.memberorderInfo.forEach(order=>{
                                if(isFindFood==0){
                                    if((item.Coupon_Exchange_Dishes||[]).length==0 || item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1){
                                        if(order.isCoupon==false && order?.Is_Discounted==true){
                                            if(order.Eat_CheckPrice != order.Eat_SalePrice){
                                                _coupname= order.EAT_XFName+'价格已变动,无法使用折扣券'
                                                _coupType=1
                                            }else{
                                                _coupname=''
                                                _coupType=1
                                                isFindFood=1
                                            }
                                        }else{
                                            _coupname='订单里没有可折的菜品,无法使用折扣券'
                                            _coupType=1
                                        }
                                    }else{
                                        _coupname='订单里没有可折的菜品,无法使用折扣券'
                                        _coupType=1
                                    }
                                }
                            })
                        }
                        if(!_coupname){
                            this.memberorderInfo.some(order=>{
                                let _types = 0
                                if((item.Coupon_Exchange_Dishes||[]).length==0 ||  item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1){
                                    if(order.isCoupon==false && order.Eat_CheckPrice == order.Eat_SalePrice){
                                        _coupTypes=1
                                        Eat_Number = Number(Eat_Number) + Number(order.Eat_Number)
                                        Eat_ConsumeID = order.Eat_ConsumeID
                                        item = Object.assign(item,{Eat_ConsumeID:Eat_ConsumeID})
                                        _types=1
                                    }else{
                                        _coupname='对应的菜品已享受优惠,无法选择多张'
                                        _coupType=1
                                    }
                                }else{
                                    _coupname='没有可抵扣的菜品,无法使用该券'
                                    _coupType=1
                                }
                                if(_types==1){
                                    return true
                                }
                            })
                        }
                        if(_coupType==1 && _coupTypes==0){
                            this.errCounc++
                            this.$message.warning(_coupname);
                            return
                        }
                    }

                    let _ConsumeMoney = item.CouponUsingDetailedRules.ConsumeMoney;
                    let _ConsumeMoney_ControlType = item.CouponUsingDetailedRules.ConsumeMoney_ControlType;
                    //判断金额控制ConsumeMoney_ControlType 0不限金额,1为账单满多少金额可用,2为每满多少金额后可用1张
                    this.CouponProducts(item,_ConsumeMoney,_ConsumeMoney_ControlType)
                    if(this.errCounc==1){
                        this.commodityJudge(item,1)
                        this.CouponsSelect(item)
                    }
                }else{

                    //商品券
                    if(item.Coupon_Kind==2){
                        let _coupTypes = 0
                        let _coupname=''
                        let _coupType=0
                        let Eat_Number=0
                        let Eat_ConsumeID=''

                        //如果是折扣券
                        if(item.DiscountType==2){
                            let isFindFood=0
                            this.memberorderInfo.forEach(order=>{
                                if(isFindFood==0){
                                    if((item.Coupon_Exchange_Dishes||[]).length==0 || item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1){
                                        if(order.isCoupon==false && order?.Is_Discounted==true){
                                            if(order.Eat_CheckPrice != order.Eat_SalePrice){
                                                _coupname= order.EAT_XFName+'价格已变动,无法使用折扣券'
                                                _coupType=1
                                            }else{
                                                _coupname=''
                                                _coupType=1
                                                isFindFood=1
                                            }
                                        }else{
                                            _coupname='订单里没有可折的菜品,无法使用折扣券'
                                            _coupType=1
                                        }
                                    }else{
                                        _coupname='订单里没有可折的菜品,无法使用折扣券'
                                        _coupType=1
                                    }
                                }
                            })
                        }
                        if(_coupname==''){
                            this.memberorderInfo.some(order=>{
                                let _types = 0
                                if((item.Coupon_Exchange_Dishes||[]).length==0 ||  item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1){
                                    if(order.isCoupon==false && order.Eat_CheckPrice == order.Eat_SalePrice){
                                        _coupTypes=1
                                        Eat_Number = Number(Eat_Number) + Number(order.Eat_Number)
                                        Eat_ConsumeID = order.Eat_ConsumeID
                                        item = Object.assign(item,{Eat_ConsumeID:Eat_ConsumeID})
                                        _types=1
                                    }else{
                                        _coupname='对应的菜品已享受优惠,无法选择多张'
                                        _coupType=1
                                    }
                                }else{
                                    _coupname='没有可抵扣的菜品,无法使用该券'
                                    _coupType=1
                                }
                                if(_types==1){
                                    return true
                                }
                            })

                        }
                        if(_coupType==1 && _coupTypes==0){
                            this.errCounc++
                            this.$message.warning(_coupname);
                            return
                        }
                    }

                    this.commodityJudge(item,1)
                    this.CouponsSelect(item)
                }
            }
        },
         /** Products_ControlType: 0 适用所有菜品  1 指定的菜品满足金额才可使用券，2.指定菜品方案规则，满足菜品方案规则条件下的菜品总价才能使用对应券 */
         /**ControlType:0 不限制起用金额并不开启消费菜品控制 , 1：消费总金额满足才可使用券 2.消费总金额每满足指定价格可使用1张 */
        CouponProducts(item,_ConsumeMoney,ControlType){
            /**0:所有菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==0){
                if(ControlType==1){
                    if((Number(this.totalMoney) + Number(this.discountMoneuy))<_ConsumeMoney){
                        this.errCounc++
                        this.$message.warning('账单需要满'+Number(_ConsumeMoney)+'元才可以使用这张券');
                        return
                    }
                }
                if(ControlType==2){
                    if(_ConsumeMoney > (Number(this.totalMoney) + Number(this.discountMoneuy))){
                        this.errCounc++
                        this.$message.warning('账单每满'+Number(_ConsumeMoney)+'元可以使用1张此券');
                        return
                    }else{
                        let money =0;
                        this.memberorderInfo.map(order=>{
                            money = Number(money) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                        })
                        let _choiceNumber=1
                        let ErrorTitle=''
                        let ErrorType=0
                        if(this.choiceCoupons.length>0){
                            this.choiceCoupons.map(()=>{
                                _choiceNumber = _choiceNumber+1
                                if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            })
                        }else{
                            if(money< item.CouponUsingDetailedRules.ConsumeMoney ){
                                this.errCounc++
                                ErrorType=1
                                ErrorTitle = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                            }
                        }
                        if(ErrorType==1){
                            this.$message.warning(ErrorTitle);
                            return
                        }   
                    }
                }
            }
            /**指定菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==1){
                /**查询下单菜品中是否符合该券使用条件 */
                let _Products = item.CouponUsingDetailedRules.AppointProducts;
                let _productmoney =0;
                this.memberorderInfo.map(order=>{
                    if(_Products.indexOf(order.EAT_XFCode)!=-1){
                        _productmoney = Number(_productmoney) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                    }
                })
                if(ControlType==1){
                    if(_productmoney<item.CouponUsingDetailedRules.ConsumeMoney){
                        this.errCounc++
                        this.$message.warning('该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用');
                        return
                    }
                }
                if(ControlType==2){
                    let _choiceNumber =1
                    let ErrorTitle=''
                    let ErrorType=0
                    if(this.choiceCoupons.length>0){
                        this.choiceCoupons.map(cho=>{
                            if(cho.Tk_AutoID == item.Tk_AutoID){
                                _choiceNumber = _choiceNumber+1
                                if(_productmoney < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }else{
                                if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }
                        })
                    }else{
                        if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                    if(ErrorType==1){
                        this.$message.warning(ErrorTitle);
                        return
                    }
                }
                
            }
            /**指定菜品适用规则 */
            if(item.CouponUsingDetailedRules.Products_ControlType==2){
                if(ControlType==1){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorType=1
                                this.errCounc++
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorType=1
                                this.errCounc++
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.errCounc++
                            ErrorCount++
                            ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                        }
                    }
                    if(ErrorCount!=3){
                        this.errCounc=1
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        this.$message.warning(ErrorTitle);
                        return
                    }

                }
                if(ControlType==2){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    if(ErrorCount!=3){
                        this.errCounc=1
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        this.$message.warning(ErrorTitle);
                        return
                    }
                }
            }
        },
        CouponMoneyType(money,item,_ConsumeMoney){
            let _choiceNumber=1
            let ErrorType=0
            let ErrorTitle=''
            if(this.choiceCoupons.length>0){
                this.choiceCoupons.map(cho=>{
                    if(cho.Tk_AutoID == item.Tk_AutoID){
                        _choiceNumber = _choiceNumber+1
                        if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }else{
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                })
            }else{
                if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                    this.errCounc++
                    ErrorType=1
                    ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                }
            }
            let retError={ErrorType:ErrorType,ErrorTitle:ErrorTitle}
            return retError
        },   
        /**券选中事件 */
        CouponsSelect(item){
            if(item.Coupon_Kind==1){
                this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue);
            }
            if(item.CouponUsingDetailedRules){
                if(item.CouponUsingDetailedRules.Is_AllowMixUsing==2){
                    if(this.couponsCount==0){
                        this.is_AllowMixUsing = true;
                        this.is_AllowMixUsingCoupon_Name = item.Coupon_Name;
                    }
                }
            }
            this.couponsCount++;
            item.Check = true;
            this.choiceCoupons.push(item);
            this.memberorderInfo.forEach(order=>{
                order.CouponNumber=0
                this.choiceCoupons.forEach(cho=>{
                    if(order.Eat_ConsumeID==cho.Eat_ConsumeID){
                        if(order.CouponNumber<order.Eat_Number){
                            order.CouponNumber = order.CouponNumber+1
                            if(order.CouponNumber>=order.Eat_Number){
                                order.isCoupon=true
                            }
                        }else{
                            order.isCoupon=true
                        }
                    }
                })
            })
        },
        /**商品券判断 type:1 选中, 2取消选中 */
        commodityJudge(item,type){
            if(item.Coupon_Kind==2){
                /**商品券价值类型，0：按菜品金额抵扣 ，1：按固定金额抵扣 注：如果固定金额超过菜品金额则按菜品金额抵扣， 2：按折扣率抵扣 注：5折填写50 */
                if(item.DiscountType==0){
                    let _num=0
                    this.memberorderInfo.forEach(order=>{
                        if(_num==0 && order?.isCoupon==false){
                            if(( (item.Coupon_Exchange_Dishes||[]).length==0 ||item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1) && item.Eat_ConsumeID==order.Eat_ConsumeID){
                                _num=1
                                if(type==1){
                                    this.couponsMoney =Number(( Number(this.couponsMoney) + Number(order.Eat_CheckPrice)).toFixed(4));
                                    item.Coupon_FaceValue = Number(order.Eat_CheckPrice)
                                }else{
                                    this.couponsMoney = Number((Number(this.couponsMoney) - Number(order.Eat_CheckPrice)).toFixed(4));
                                    item.Coupon_FaceValue = 0
                                }
                                return
                            }
                        }
                    })
                }else if(item.DiscountType==1){
                    let _num=0
                    this.memberorderInfo.forEach(order=>{
                        if(_num==0 && order?.isCoupon==false){
                            if(item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1){
                                _num=1
                                /**如果固定金额超过菜品金额则按菜品金额抵扣 */
                                if(Number(item.DiscountValue)>order.Eat_CheckPrice){
                                    item.Coupon_FaceValue = order.Eat_CheckPrice
                                }else{
                                    item.Coupon_FaceValue = item.DiscountValue
                                }
                                if(type==1){
                                    this.couponsMoney = Number(( Number(this.couponsMoney) + Number(item.Coupon_FaceValue)).toFixed(4));
                                }else{
                                    this.couponsMoney = Number(( Number(this.couponsMoney) - Number(item.Coupon_FaceValue)).toFixed(4));
                                }
                            }
                        }
                    })
                }else if(item.DiscountType==2){
                    let _num=0
                     this.memberorderInfo.map(order=>{
                         if(_num==0 && order?.Is_Discounted==true && order?.isCoupon==false){
                             if(item.Coupon_Exchange_Dishes?.indexOf(order.EAT_XFCode)!=-1 && order.Eat_CheckPrice==order.Eat_SalePrice){
                                 _num=1
                                let money = Number(( Number(order.Eat_CheckPrice) * (1-(item.DiscountValue / 100))).toFixed(4));
                                if(type==1){
                                    this.couponsMoney =  Number((Number(this.couponsMoney) + Number(money) ).toFixed(4));
                                    item.Coupon_FaceValue = money
                                }else{
                                    this.couponsMoney = Number(( Number(this.couponsMoney) -  Number(item.Coupon_FaceValue) ).toFixed(4));
                                    item.Coupon_FaceValue = 0
                                }
                            }
                         }
                    })
                    return
                }
            }
        },
        /**商品券展示类型 */
        discountShow(item){
            if(item.DiscountType==0){
                return '完全抵用'
            }
            if(item.DiscountType==1){
                return '抵扣'+item.DiscountValue+'元'
            }
            if(item.DiscountType==2){
                return '抵扣'+ (100 - item.DiscountValue)+'%'
            }
        },
        hide(){
            if(this.PayResultCode==1){
                this.$message.warning('有待支付的订单,无法调整');
                return
            }
            this.$emit("close")
        },
        /**确定付款 */
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(Number(this.couponsPay)>0){
                if(Number(this.couponsPay)>this.snackPaidMoney){
                    this.$message.error('储值金额超过待支付金额');
                    return
                }
            }
            if(Number(this.PointPay)>0){
                if(this.meberInfo.Card_PilePoint<Number(this.PointPay)){
                    this.$message.error('您的积分不足');
                    return
                }
                if(this.PointPayMoney>this.snackPaidMoney){
                    this.$message.error('积分可兑换金额超过待支付金额');
                    return
                }
            }
            if(Number(this.couponsPay)>0 && this.PointPayMoney>0){
                if((Number(this.couponsPay)+this.PointPayMoney)>this.snackPaidMoney){
                    this.$message.error('储值金额和积分总额超出待支付金额');
                    return
                }
            }
            if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                this.$message.error('卡内余额不足,本次支付所需'+this.couponsPay+'，卡内余额：'+this.meberInfo.Card_Money);
                return
            }
            // if(this.choiceCoupons.length==0){
            //     this.$message.warning('请先选择需要核销的券');
            //     return
            // }
            if(this.PayResultCode==0){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                let _meberInfo=''
                /** 预支付总金额 */
                let _counMoney=0
                /**支付方式 */
                let _payment=[] 
                let _meber = this.meberInfo
                /**会员信息 */
                _meberInfo ={
                    Member_Sys:22,//昆仑Lps会员
                    DynamicType:_meber.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                    Card_AutoID:_meber.Bestech_Card_AutoID, //卡主键
                    Card_No:_meber.Card_No, //卡号
                    Member_Name:_meber.MemberName, //姓名
                    MemberCard_Kind:_meber.Bestech_Card_KindName, //会员等级
                    Mobile:_meber.Mobile,//会员手机号
                    NotJoinPointProgram:this.PointChecked,//不参与线上积分
                    Invoice_Money:0, //本次消费开票金额
                    Invoice_No:'',//发票号码
                }
                //券支付方式
                if(this.couponsMoney>0){
                    //获取券的信息
                    let _paymoney = this.snackPaidMoney;
                    this.choiceCoupons.forEach(cho=>{
                        if(Number(cho.Coupon_FaceValue)<_paymoney){
                            _paymoney = Number((_paymoney - Number(cho.Coupon_FaceValue)).toFixed(4))
                        }else{
                            cho.Coupon_FaceValue = _paymoney
                        }
                        let _choice={
                            Coupon_AutoId:cho.Coupon_AutoID,
                            Coupon_Code:cho.Coupon_Code,
                            Coupon_Name:cho.Coupon_Name,
                            Coupon_Kind:cho.Coupon_Kind,
                            Coupon_Num:1,
                            Coupon_CostPrice:cho.Coupon_CostMoney
                        }
                        _payment.push({
                            Payment_ID:'CardCouponsPay',  //支付方式ID
                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                            Payment_Money: Number(cho.Coupon_FaceValue), //支付金额
                            Payment_FactMoney:0, //计营业额
                            Qr_Code:'', //扫码付款的码值
                            Card_No:_meber.Card_No, //卡号
                            CouponInfo:_choice, //券的详情
                        })
                        _counMoney = Number((_counMoney + Number(cho.Coupon_FaceValue)).toFixed(4))
                    })
                }
                //积分支付
                if(this.PointPay>0){
                    _payment.push({
                        Payment_ID:'CardPointPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money:this.PointPayMoney, //支付金额
                        Payment_FactMoney:0, //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:_meber.Card_No, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = _counMoney + Number(this.PointPayMoney)
                }
                //储值
                if(this.couponsPay>0){
                    _payment.push({
                        Payment_ID:'CardMoneyPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money: Number(this.couponsPay), //支付金额
                        Payment_FactMoney:0, //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:_meber.Card_No, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = _counMoney + Number(this.couponsPay);
                }
                /**结合其他付款方式 */
                if(this.paymentHistory){
                    this.paymentHistory.filter(it=>!it.Eat_PayDetailID).forEach(pay=>{
                        _payment.push({
                            Payment_ID:pay.Payment_ID,  //支付方式ID
                            Payment_Code:pay.Payment_Code, //支付方式编号
                            Payment_Name:pay.Payment_Name, //支付方式名称
                            Payment_Type:pay.Payment_Type, //支付方式类型
                            Payment_Money: pay.Payment_Money, //支付金额
                            Payment_FactMoney:pay.Payment_FactMoney, //计营业额
                            Qr_Code:pay.Qr_Code, //扫码付款的码值
                            Card_No:pay.Card_No, //卡号
                            CouponInfo:pay.CouponInfo, //券的详情
                        })
                    })
                }
                let _memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
                let _orderConsumes=this.$global.getFastOrderConsumes(_memberorderInfo);
                let param={
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Operator_Name:this.userInfo?.Login_Name,//操作员
                    Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                    Channel_ID:this.Channellike,//订单渠道ID
                    Room_ID:this.RoomAutoID,//餐厅ID
                    Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                    Eat_DeskName:this.DeskName, //桌台名称
                    Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                    Eat_AllRemark:'', //订单全单备注
                    Order_Remark:this.headingInfo.orderRemark,//订单备注
                    Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                    NotSendToKitPrint:false, //下单不送打厨房
                    MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                    Member:_meberInfo,//会员信息
                    Payments:_payment, //支付方式列表
                    OrderConsumes:_orderConsumes,//订单消费明细
                }
                if(this.EatAutoIDs){
                    param.Eat_AutoID = this.EatAutoIDs
                    param.Timestamps = this.Timestamps
                }
                console.log("支付预览 请求",param)
                /**支付预览 */
                const loading = this.$loading({
                    text: "支付预览中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                    console.log("支付预览 返回",data)
                    loading.close();
                    this.payPreviewData = data
                    if(data.ResponseBody?.Timestamps){
                        this.ordertimestamps = data.ResponseBody?.Timestamps.toString();
                        this.eatAutoID = data.ResponseBody?.Eat_AutoID
                    }
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                        }
                        if(data.ResponseBody?.Timestamps){
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                    }else{
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        this.memberorderInfo.Mantissa_Money = data.ResponseBody.MantissaAdjustMoney
                        this.tempUserId = data.ResponseBody?.User_ID
                        this.tempOperatorName = data.ResponseBody?.User_Name
                        /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                        if(data.ResponseBody.Is_NeedSMSCode==1){
                            this.cardCodeShow = true
                            return
                        }
                        /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                        if(data.ResponseBody.Is_NeedPassWord==1){
                            this.passwordShow=true
                            return
                        }
                        /**支付提交判断 0不需要 1需要 */
                        if(data.ResponseBody.Is_NeedSubmit==1){
                            this.PaySubmit(data)
                        }else{

                            if(!data.ResponseBody?.IsMultipleCardPay){
                                if(data.ResponseBody?.PrintsInfo){
                                    setTimeout(()=>{
                                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                                    },100);
                                }
                                this.$message.success({ message: "支付完成"});
                                this.$emit('paySuccessConfirm');
                            }else{
                                this.$emit('payMultipleConfirm',data.ResponseBody);
                            }
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else if(this.PayResultCode==1){
                this.PaySubmit(this.payPreviewData)
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
               let param={
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                    Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                    MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                    Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        //this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                        }
                        if(data.ResponseHeader.ResultCode==4003){
                            this.PayResultCode=0
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.$message.success({ message: "支付完成"});
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        this.$emit('paySuccessConfirm');
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**获取星期 */
        setNowTimes(){
            let myDate = new Date()
            let wk = myDate.getDay()
            let weeks = ['7', '1', '2', '3', '4', '5', '6']
            let week = weeks[wk]
            return week;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.choiceCoupons=[]
            this.cashCoupon=''
            this.commodityVoucher=''

            this.is_AllowMixUsing=false
            this.is_AllowMixUsingCoupon_Name=''
            this.PayResultCode=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName=''
            this.unPaidMoney = this.snackPaidMoney;
            this.couponsPay='';
            this.PointPay='';
            this.memberorderInfo.forEach(order=>{
                order.isCoupon=false,
                order.CouponNumber=0
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.$emit('memberCardBack',this.meberInfo)
            this.cardInfoShow()
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            if(this.meberInfo){
                let _cashArr = [];  //定义一个数组
                let _commArr=[];
                if(this.meberInfo?.Coupons){
                    this.meberInfo.Coupons.map(item=>{
                        if(item.Coupon_Kind==1){
                            _cashArr.push(Object.assign(item,{Check:false}));
                        }else{
                            _commArr.push(Object.assign(item,{Check:false}));
                        }
                    })
                }
                this.cashCoupon=_cashArr;
                this.commodityVoucher=_commArr;

                //会员余额 大于 待支付金额
                if(Number(this.meberInfo.Card_Money)>=this.snackPaidMoney){
                    this.couponsPay=this.snackPaidMoney;
                }else{
                    this.couponsPay=Number(this.meberInfo.Card_Money);
                }
            }
        },
        /**验证码返回 */
        cardCodeConfirm(code){
            this.verifyCode=code
            this.cardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd
            this.passwordShow = false
            this.PaySubmit(this.payPreviewData)
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        onIntegralClick(){
            setTimeout(()=>{
                this.$refs.PointPayinput?.select();
            },100)
        },
    }
}
</script>

<style lang="sass">
@import "./fastKunLunMemberPayModel.scss";
</style>