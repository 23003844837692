<template>
    <div class="domTable" @click="isBo=!isBo">
        <div class="filter-box">
            <div class="from-box">设置 列数据: 
                <el-select v-model="rowNum">
                    <el-option :value="0" label="0"></el-option>
                    <el-option :value="10" label="10"></el-option>
                    <el-option :value="11" label="11"></el-option>
                    <el-option :value="100" label="100"></el-option>
                    <el-option :value="1000" label="1000"></el-option>
                    <el-option :value="10000" label="10000"></el-option>
                </el-select>
            </div>
            <div class="from-box">数据条数:
                <el-select v-model="dataNum">
                    <el-option :value="0" label="0"></el-option>
                    <el-option :value="10" label="10"></el-option>
                    <el-option :value="100" label="100"></el-option>
                    <el-option :value="1000" label="1000"></el-option>
                    <el-option :value="10000" label="1w"></el-option>
                    <el-option :value="50000" label="5w"></el-option>
                    <el-option :value="100000" label="10w"></el-option>
                </el-select>
            </div>
        </div>

        <table-vir :data="tableData"
            :show-summary="true"
            :summary-method="getSummaries" 
            :span-method="spanMethod"
            @selection-change="change"
        >
            <table-column-vir fixed type="index" label="序号" align="left"/>
            <table-column-vir fixed type="selection" label="序号" />
            <table-column-vir fixed prop="id" label="ID" width="100" align="left"/>
            <table-column-vir fixed :sortable="true" prop="name" label="名称" min-width="100" align="left"/>
            <table-column-vir fixed prop="amount1" label="编号"  min-width="130" align="left"/>
            <table-column-vir prop="amount2" label="列1" min-width="100" align="right"/>
            <table-column-vir prop="amount3" label="列2" min-width="100" align="right" />
            <table-column-vir label="列3">
                <table-column-vir label="列3-1">
                    <template #header>
                        列3-1 header
                    </template>
                </table-column-vir>
                <table-column-vir label="列3-2"  >
                    <table-column-vir label="列3-2-1">
                        <template #default="scope">
                            <el-checkbox v-model="scope.row.isCheck" ></el-checkbox>
                        </template>
                    </table-column-vir>
                    <table-column-vir label="列3-2-2">

                    </table-column-vir>
                </table-column-vir>
            </table-column-vir>
            <table-column-vir label="合并列4" >
                <table-column-vir :label="'列4-'+(item+1)" v-for="item in rowNum" :key="item">
                    <template #default="scope">
                        <el-checkbox v-model="scope.row['check-'+item]" ></el-checkbox>
                    </template>
                </table-column-vir>
            </table-column-vir>
            <table-column-vir fixed label="操作" min-width="100">
                <template #default>
                    <el-button  type="text" size="small">查看</el-button>
                </template>
            </table-column-vir>
        </table-vir>
    </div>
</template>

<script>
import tableVir from './tableVir.vue'
import tableColumnVir from './tableColumnVir.vue'
export default {
    name:"domTable",
    components: { tableVir,tableColumnVir },
    data(){
        return {
            isBo:false,
            tableData:[],
            rowNum:10,
            dataNum:100
        }
    },
    watch:{
        dataNum(){
            let data=[];
            for(let i=0;i<this.dataNum;i++){
                data.push({
                    id:"00000"+i,
                    name:"table"+i,
                    amount1:"水电费水电收到费水"+i,
                    amount2:"amount2"+i,
                    amount3:"amount3"+i,
                    isTotal:i%10==0,
                    isCheck:false
                })
            }

            this.tableData=data;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            let data=[];
            for(let i=0;i<this.dataNum;i++){
                data.push({
                    id:"00000"+i,
                    name:"table"+i,
                    amount1:"水电费水收到电费水"+i,
                    amount2:"amount2"+i,
                    amount3:"amount3"+i,
                    isTotal:i%10==0,
                    isCheck:false
                })
            }

            this.tableData=data;
        })
    },
    methods:{
        change(row){
            console.log(row)
        },
        getSummaries({ columns, data }) {
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) { // 只找第一列放合计
                    sums[index] = '合计：'
                    return
                }else {
                    sums[index] = '--'
                }
            });
            return sums
        },
        /**合并行列 */
        spanMethod({ row, column, rowIndex, columnIndex }){
            if(row.isTotal){
                if(columnIndex==4){
                    return [1,3];
                }else if(columnIndex>4 && columnIndex<=6){
                    return [0,0];
                }
            }
        },
    }
}
</script>

<style lang="scss">
.domTable{
    width: 100%;
    height: 800px;

    .filter-box{
        padding: 10px;
        display: flex;
        align-items: center;

        .from-box{
            padding: 0 10px;
            display: flex;
            align-items: center;

            .el-select{
                margin-left: 10px;
            }
        }
    }

    .tableVir{
        width: 100%;
        height: 100%;

        td,th{
            height: 25px;
            padding: 0;

            .el-button--small{
                min-height: 25px;
                padding: 0;
            }
        }
    }
    
}
</style>