<template>
    <div v-if="isLoad">
        <teleport to="body">
        <slot ></slot>
        </teleport>
    </div>
</template>

<script>
export default {
    name: 'modalLoad',
    data(){
        return {
            isLoad:false
        }
    },
    props:{
        /**是否加载去加载组件*/
        isShow:{
            type:Boolean,
            default:false
        },
        /**是否允许 隐藏时卸载组件 */
        isUnload:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        isShow(val) {
            if(this.isUnload){
                if(val==true){
                    this.isLoad=val;
                }else{
                    setTimeout(()=>{this.isLoad=false},200);
                }
            }else{
                if(val==true) {
                    this.isLoad=true;
                }
            }
            
        }
    }
}
</script>

<style>

</style>