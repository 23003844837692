<template>
    <modal class="successModel" :isShow="isShow" >
        <div class="success-box">
            <div class="pic"><i class="iconfont icon-gou"></i></div>
            <p>下单成功</p>
        </div>
    </modal>
</template>

<script>
//下单成功提示
export default {
    name:"successModel",
    emits:["close"],
    props:{
        isShow:Boolean,
    },
    watch:{
        isShow:{
            handler(){
                if(this.isShow){
                    if(this.time){
                        clearTimeout(this.time);
                    }
                    this.time=setTimeout(()=>{
                        this.hide();
                    },3000)
                }else{
                    if(this.time){
                        clearTimeout(this.time);
                    }
                }
            },
            immediate:true
        }
    },
    methods:{
        hide(){
            this.$emit("close")
        }
    },
    unmounted(){//被移除时
        if(this.time){
            clearTimeout(this.time);
        }
    },
}
</script>

<style lang="scss">
    @import './successModel.scss'
</style>