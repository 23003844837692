

export default {
    install(app){
        let row,col;
        /**
         * 
         * @param {*} el 元素
         * @param {*} data 数据{className：'指定的数据类名 例如：.item-li (默认是查找 子集中 div[rc-item] 元素)',
         * marginW:'元素的外边距 计算每个元素宽度需要'，marginH:'元素的外边距 计算每个元素高度需要',row:'多少行',col:'多少列'}
         * @returns 
         */
        function cheage(el,data){
            let scrollEl=el.parentElement;
            el.style.minHeight='100%'
            let li= el.querySelectorAll(data.className||'div[rc-item]');
            
            let w=el.offsetHeight;
            let h=scrollEl.offsetHeight- (scrollEl.scrollHeight- el.offsetHeight);
            if(!(w>0 && h>0)){return;}
            el.style.minHeight="";
            if(data.row>0){
                row=data.row;
                let height= (h/row)-(data.marginH||0);
                //let height = (h- (row -1)*5*2 -5*2)/row;
                li.forEach((item)=>{
                    item.style.height=height+"px";
                })
            }
            let rects=el.getBoundingClientRect();
            w=rects.width;
            if(data.col>0){
                col=data.col;
                if(li.length>(row||0)*col)
                {//一屏内容过多 
                    if(scrollEl.scrollHeight<=scrollEl.clientHeight){//不存在滚动条
                        w=w-6;//减去滚动条宽度
                    }
                    scrollEl.style.overflow="";
                }else{//刚好在一屏内显示 
                    if(scrollEl.scrollHeight>scrollEl.clientHeight){//存在滚动条
                        // w=w+6;//减去滚动条宽度
                    }
                    scrollEl.style.overflow="hidden";
                    w=el.getBoundingClientRect().width;
                }
                let width= (w/col)-(data.marginW||0);
                //let width = (w- (col -1)*5*2 -5*2)/col;
                li.forEach((item)=>{
                    item.style.width=width+"px";
                })
                el.dispatchEvent(new Event("scroll"));
            }

        }
        /**
         * 使用 例如： 上级一级元素必须设置有滚动条 不然高度计算会有问题
         * <div class="item-box clearfix" v-layout-rc="arg"> 
         *      <div class="item-li" rc-item></div>
         * </div>
         * export default {
         *      data() {
         *          return {
         *              arg:{row:4,col:3,marginW:'12',marginH:'12'}
         *          }
         *      }
         *  }
         */
        app.directive('layout-rc',{
            mounted(el,binding){//绑定元素的父组件被挂载时调用
                let data=binding.value;
                if(typeof(data)=='object'){cheage(el,data)}
                let time;
                el.resize=()=>{
                    if(time) clearTimeout(time);
                    time=setTimeout(()=>{
                        cheage(el,data);
                        if(el._isOneResize)el._isOneResize=false;
                    },100);
                    
                }
                window.addEventListener('resize',el.resize);
            },
            updated(el,binding){//在包含组件的 VNode 及其子组件的 VNode 更新之后调用
                let data=binding.value;
                if(typeof(data)=='object'){cheage(el,data)}
            },
            unmounted(el) {
                window.removeEventListener('resize',el.resize);
            }
        })
    }
}