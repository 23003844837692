<template>
    <modal class="updateBillInvModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">开票金额修改
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
            <div class="clearfix">
                <div class="left-box"></div>
                <div class="right-box">
                    <div class="tr-li">
                        <div class="lable-txt">金额调整：</div>
                        <div class="from-input" style="width:50%"><input type="text" ref="Invomoney"  data-model='Invomoney' v-model="Invomoney" @keyup="Invomoney=Invomoney.toString().replace(/[^\d.]/g,'').replace(/\.{3,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d\d).*$/,'$1$2.$3').replace(/^\./g,'')" /></div>
                    </div>
                    <div class="keyboard" style="margin-top:5%">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>
<script>
import {initKeyBoard} from '/src/common' 

/**会员支付 */
export default {
    name:"updateBillInvModel",
    props:{
        isShow:Boolean,
        UpdateBillitem:Object
    },
    data(){
        return {
            Invomoney:0,
        }
    },
    mounted(){
       this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.Invomoney);
            this.Invomoney= Number(this.UpdateBillitem.Eat_InvoiceMoney)
            this.EmptyDatas()
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            this.Invomoney= Number(this.UpdateBillitem.Eat_InvoiceMoney)
            this.EmptyDatas();
        }
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.Invomoney?.focus();
                this.$refs.Invomoney?.select();
            },100);
        },
        hide(){
            this.$emit("closeModel")
        },
        /**确定和回车事件 */
        confirm(){
            if(!this.Invomoney.toString()){
                this.$message.warning('金额不能为空');
                return
            }
            this.$emit("billInvReturn", Number(this.Invomoney))
        },
    }
}
</script>
<style lang="scss">
    @import "./updateBillInvModel.scss"
</style>