<template>
  <modal class="modiRiseModel" :isShow="modiRiseShow" @keyup.esc="CloseClicks">
    <div class="header">
		<span>修改抬头操作</span>
    </div>
    <!--左边基本信息div-->
    <div class="Popup-Basic">
		<div class="tr-li">
			<div class="td-5">
				<div class="lable-txt" style="top: -5px; position: relative">
					<span style="position: relative">
						<i class="iconfont icon-zhuotai"  style="font-size: 30px; left: -5px; position: relative"></i>
					</span>
					<span  style="position: relative; top: 20px; right: 32px; color: #bcbbc0" >桌号</span>
				</div>
				<div class="from-input" @click="this.choiceDeskShow = true">
					<input type="text" placeholder="请选择对应桌台" v-model="DeskName" readonly/>
					<i class="iconfont icon-gengduo"></i>
				</div>
			</div>
			<div class="td-5">
				<div class="lable-txt">人数:</div>
					<div class="from-input">
					<input type="text" placeholder="请输入就餐人数" ref="input"
						class="key-focus" data-model="DinersNum" v-model="DinersNum"
						oninput="value=value.replace(/[^\d]/g,'')"  @keyup.enter="inputCursor(1)" @click="selectValue($event, DinersNum)"  
						@focus="(e) => e.target.select()" />
				</div>
			</div>
		</div>
		<div class="tr-li">
			<div class="td-5">
				<div class="lable-txt">用餐渠道:</div>
				<div class="from-input" @click="orderChannelClick()">
					<input type="text" placeholder="请选择" v-model="orderChannelName" readonly/>
					<i class="iconfont icon-yewuliucheng"></i>
				</div>
			</div>
			<div class="td-5">
				<div class="lable-txt">牌号:</div>
				<div class="from-input">
				<input type="text" placeholder="输入牌号" ref="brand" class="key-focus" v-model="brandNumber" @keyup.enter="inputCursor(2)" @click="selectValue($event, brandNumber)"/>
				</div>
			</div>
		</div>
		<div class="tr-li">
			<div class="td-6">
				<div class="lable-txt">订单备注:</div>
				<div class="from-input">
				<input type="text" ref="orderNote" class="key-focus" v-model="orderRemark" @keyup.enter="inputCursor(3)" @focus="$webBrowser.openInputBoard()"/>
				</div>
			</div>
		</div>
		<div class="tr-li" style="width: 96%" v-show="cardShowType == true">
			<el-divider>会员信息
				<i class="el-icon-arrow-up" @click="cardShowType = !cardShowType"></i>
			</el-divider>
		</div>
		<div class="tr-li" style="width: 96%" v-show="cardShowType == false">
			<el-divider>会员信息
				<i class="el-icon-arrow-down" @click="cardShowType = !cardShowType" ></i>
			</el-divider>
		</div>
		<transition name="cardShow">
			<div v-show="cardShowType" style="position: absolute">
			<div class="tr-li">
				<div class="td-7">
					<div class="lable-txt">卡号:</div>
					<div class="from-input">
						<template v-if="sysSetting?.RunModifyOrderMemberForbidInputMobile">
							<input-pattern type="text" ref="cardfocus" 
								placeholder="请输入会员卡号" v-model="CardID"
								readonly isDisableBarcode
								@click="selectValue($event,CardID)"  @barcode="onMemberInfo()" />
						</template>
						<template v-else>
							<input type="text" ref="cardfocus" class="key-focus" placeholder="请输入会员卡号" v-model="CardID"
							@click="selectValue($event,CardID)"  @keydown.enter="onMemberInfo()" />
						</template>
						<i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
					</div>
				</div>
				<div class="td-5">
					<button class="btn" @click="readCard()">读卡</button>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-card">
					<div class="lable-txt">会员等级:</div>
					<div class="from-input">
						<input type="text" placeholder="" v-model="CardLevel" readonly />
					</div>
				</div>
				<div class="td-card">
					<div class="lable-txt" style="width: 68px">会员姓名:</div>
					<div class="from-input">
						<input type="text" placeholder="" v-model="ClientName" readonly style="width: 162px" />
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-card">
					<div class="lable-txt">手机号码:</div>
					<div class="from-input">
						<input type="text" :value="cardPhoneSecret" readonly />
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt" style="width: 72px">生日:</div>
					<el-date-picker class="from-date" :clearable="false" :editable="false" type="date" placeholder="" readonly v-model="birthday" :default-value="new Date()" >
					</el-date-picker>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-6">
					<div class="lable-txt">客户喜好:</div>
					<div class="from-input">
						<input type="text" v-model="ClientLike" readonly />
					</div>
				</div>
			</div>
			</div>
		</transition>
    </div>
    <!--右边数字键盘div-->
    <div class="Popup-Basic-right">
		<div class="keyboard">
			<div class="key-li">
				<span data-value="7">7</span><span data-value="8">8</span><span data-value="9">9</span>
			</div>
			<div class="key-li">
				<span data-value="4">4</span><span data-value="5">5</span><span data-value="6">6</span>
			</div>
			<div class="key-li">
				<span data-value="1">1</span><span data-value="2">2</span><span data-value="3">3</span>
			</div>
			<div class="key-li">
				<span data-value=".">.</span><span data-value="0">0</span><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span>
			</div>
		</div>
		<div class="other-box">
			<el-switch v-model="isCursorCodeNo" :inline-prompt="true" active-text="默认定位光标在卡号" inactive-text=""></el-switch>
		</div>
    </div>
    <div class="Popup-Bsic-bottom">
		<button class="Popup-Basic-left-bottom" @click="CloseClicks()"> 取消 </button>
		<button class="Popup-Basic-right-bottom" @click="SetDeskClick()">  确定 </button>
    </div>
    <!--用餐渠道-->
    <modal-load :isShow="orderChanneShow">
		<order-Channel-Model
			:isShow="orderChanneShow"
			v-on:closeModel="orderChanneShow = false"
			:Openorderclass="orderclass"
			:OrderList="OrderList"
			:orderChannel="orderChannel"
			@orderChanneConfirm="orderChanneConfirm"
		></order-Channel-Model>
    </modal-load>
    <!--选择桌号-->
    <modal-load :isShow="choiceDeskShow">
		<choice-Desk-Model
			:isShow="choiceDeskShow"
			v-on:closeModel="choiceDeskShow = false"
			@deskclose="deskclose"
		></choice-Desk-Model>
    </modal-load>
    <!--选择会员信息-->
    <modal-load :isShow="cardMemberShow">
		<card-member-Model
			:isShow="cardMemberShow"
			v-on:closeModel="cardMemberShow = false"
			:data="cardMemberList"
			@confirm="selectCardInfo"
		></card-member-Model>
    </modal-load>
  </modal>
</template>

<script>
// import waiterSalesModel from './waiterSalesModel/waiterSalesModel.vue'
import orderChannelModel from "../../model/orderChannelModel/orderChannelModel.vue";
import choiceDeskModel from "../choiceDeskModel/choiceDeskModel.vue";
// import consumeTypesModel from './consumeTypesModel/consumeTypesModel.vue'
import { initKeyBoard } from "/src/common";
//import modal from "../../../template/modal/modal";
import { httpAES, cacheData } from "/src/services";
import { newGuid } from "/src/common/index.js";
import CardMemberModel from "../../model/cardMemberModel/cardMemberModel.vue";

/**全局初始化数据 */
let _data = {
  OpenDeskPersonNum: 0 /**控制开台输入人数上限(人数为0不限制) */,
  RunFeastDeskNum: false /**启用席数功能 */,
  RunConsumeType: false /**启用账单登记消费类型功能 */,
};

export default {
	name: "opening",
	components: {
		// modal,
		orderChannelModel,
		choiceDeskModel,
		// consumeTypesModel,
		CardMemberModel,
	},
	props: {
		/**是否显示 */
		modiRiseShow:{
			type:Boolean,
			default:false
		},
		/**订单信息 */
		orderMenuList:Object,
		/** 抬头 信息*/
		headingInfo:Object,
		/**会员信息 */
		memberInfo:{
			type:Object,
			default:null
		}
	},
	data() {
		let globalSysSetting = cacheData?.globalVariable?.GlobalSysSetting;
		if (globalSysSetting) {
		_data.OpenDeskPersonNum = globalSysSetting.TheLimitedOpenDeskPersonNum;
		_data.RunFeastDeskNum = globalSysSetting.RunFeastDeskNum;
		_data.RunConsumeType = globalSysSetting.RunConsumeType;
		}
		return {
			waiSaleShow: false,
			orderChanneShow: false,
			cardMemberShow: false,
			choiceDeskShow: false,
			consumeTypes: "",
			card_AutoID: "",
			cardSNR: "", //内卡号
			DeskName: "", //桌台名
			DeskAutoID: "", //桌台ID
			RoomAutoID: "", //餐厅ID
			DinersNum: 1, //就餐人数
			CardID: "", //会员卡号
			ClientName: "", //客户名称
			CardLevel: "", //会员等级
			ClientLike: "", //客户喜好
			gender: "",
			CardPhone: "", //手机号
			orderRemark: "", //订单备注
			OrderList: [],
			orderChannel: "", //订单渠道,默认是0线下门店固定POS
			orderChannelName: "",
			ConsumptionList: [],
			ConsumptionType: "0", //消费类型
			birthday: "", //生日
			waiSaleType: 0, //1.选择的服务员 2.选择的销售员
			orderChannelAutoID: -1, //订单类型选择
			orderclass: "", //订单选择
			consumeTypeAutoid: -1, //消费类型选择
			consumeAutoID: "", //消费选择
			consumeAutoIDName: "",
			consuShow: false,
			isread: false, //是否是读卡
			iscardMemberShow: false, //是否是多张卡选择返回
			cardMemberList: [], //会员卡信息
			cardShowType: true, //读卡展示
			currentTargetTxt: "", //选中的值
			memberType: 0, //是否触发获取会员信息按钮
			RunFeastDeskNum: false /**启用席数功能 */,
			headingInfos: "" /**抬头信息 */,
			brandNumber: "" /**牌号 */,
			/**会员信息 */
			tempMeberInfo: null,
			//默认关闭在卡号上
			isCursorCodeNo:this.$cacheData.get("opening_isCursorCodeNo")||false,
		};
	},
	
	mounted() {
		this.$nextTick(() => {
			this.defaultFocus();
			initKeyBoard.call(this, this.$el);
			initKeyBoard.call(this, this.$refs.zf);
			this.EmptyDatas();
		});
	},
	watch: {
		isCursorCodeNo(newVal){
			this.$cacheData.set("opening_isCursorCodeNo",newVal);
		},
		//用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
		modiRiseShow() {
			if (this.modiRiseShow) {
				this.EmptyDatas();
				this.defaultFocus();
			}
		},
		DinersNum() {
			if (_data.OpenDeskPersonNum > 0) {
				if (Number(this.DinersNum) > _data.OpenDeskPersonNum) {
					this.$message.warning("超过就餐人数的最大限制");
					(this.DinersNum = ""), (this.currentTargetTxt = "");
					return;
				}
			}
			let TargetTxt = this.currentTargetTxt;
			if (TargetTxt.length >= 1) {
				TargetTxt = TargetTxt.slice(0, TargetTxt.length - 1);
			}
			if ( Number(this.DinersNum) != Number(TargetTxt) && this.currentTargetTxt != "") {
				this.DinersNum = this.DinersNum.substring(this.DinersNum.length - 1);
			}
			if (Number(this.DinersNum) == Number(TargetTxt) && this.currentTargetTxt != "" ) {
				this.DinersNum = "";
			}
			this.currentTargetTxt = "";
		},
		CardID() {
			if (this.iscardMemberShow == false) {
				this.memberType = 0;
			}
			let TargetTxt = this.currentTargetTxt;
			if (TargetTxt.length >= 1) {
				TargetTxt = TargetTxt.slice(0, TargetTxt.length - 1);
			}
			if (this.CardID != TargetTxt && this.currentTargetTxt != "") {
				this.CardID = this.CardID.substring(this.CardID.length - 1);
			}
			if (this.CardID == TargetTxt && this.currentTargetTxt != "") {
				this.CardID = "";
			}
			this.currentTargetTxt = "";
			this.iscardMemberShow = false;
		},
	},
	computed: {
		//手机号 保密处理
		cardPhoneSecret() {
			return this.$global.phonePrivacy(this.CardPhone);
		},
		//系统参数
		sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
	},
	methods: {
		/**默认光标位置 */
		defaultFocus(){
			if(this.isCursorCodeNo){
				setTimeout(() => {
					this.$refs.cardfocus.focus();
				},100);
			}else{
				setTimeout(() => {
					this.$refs.input.focus();
				},100);
			}
		},
		/**回车控制光标 */
		inputCursor(type) {
			if (type == 1) {
				this.$refs.brand.focus();
			}else if (type == 2) {
				this.$refs.orderNote.focus();
			}else if (type == 3) {
				this.$refs.cardfocus.focus();
			}
		},
		/**默认input焦点 */
		EmptyDatas() {
			//this.empty()
			this.DeskName = this.headingInfo?.DeskName;
			this.DeskAutoID = this.headingInfo?.DeskAutoID;
			this.RoomAutoID = this.headingInfo?.RoomAutoID;
			this.DinersNum = this.headingInfo?.DinersNum;
			this.orderChannelName = this.headingInfo?.orderChannelName;
			this.orderclass = this.headingInfo?.OrderChannel_AutoID;
			this.brandNumber = this.headingInfo?.brandNumber;
			this.orderRemark = this.headingInfo?.orderRemark;
			this.tempMeberInfo=this.memberInfo;
			if(this.tempMeberInfo){
				this.CardID = this.tempMeberInfo?.Card_No;
				this.birthday = this.tempMeberInfo.Birthday;
				this.ClientName = this.tempMeberInfo.MemberName;
				this.CardPhone = this.tempMeberInfo.Mobile;
				this.CardLevel = this.tempMeberInfo.Bestech_Card_KindName;
				this.ClientLike = this.tempMeberInfo.MemberAnalysisData?.Habits;
			}
			console.log(this.headingInfo)
			if (this.consumeTypes != "") {
				this.consuShow = true;
			}
			this.RunFeastDeskNum = _data.RunFeastDeskNum;
			let userInfo = this.$auth.getUserInfo(); //获取登录信息
			this.OrderList = [];
			this.$cacheData.OrderChannel().then((d) => {
				this.orderChannel = d;
				this.orderChannel?.forEach((order) => {
					order.OrderChannelList.forEach((item) => {
						if (this.orderclass == "") {
							if (item.OrderChannel_AutoID == userInfo?.Default_ChannelID) {
								this.orderChannelName = item.OrderChannel_Name;
								this.orderclass = item.OrderChannel_AutoID;
							}
						} else {
							if (item.OrderChannel_AutoID == this.orderclass) {
								this.orderChannelName = item.OrderChannel_Name;
								this.orderclass = item.OrderChannel_AutoID;
							}
						}
						Object.assign(item, {
							is_type: 0,
							Kind_AutoID: order.OrderChannelKind_AutoID,
						});
						this.OrderList.push(item);
					});
				});
			}).catch(() => {
				this.$alert("暂无订单渠道", "提示", { confirmButtonText: "确定" });
			});
			/**消费类型*/
			this.OrderList = this.unique(this.OrderList);
			this.ConsumptionList = [];
			this.$cacheData.ConsumeTypes().then((d) => {
				this.consumeTypes = d;
				this.consumeTypes?.forEach((consu) => {
					consu.ConsumeTypeList.forEach((item) => {
						if (this.consumeAutoID == item.ConsumeType_AutoID) {
							this.consumeAutoID = item.ConsumeType_AutoID;
							this.consumeAutoIDName = item.ConsumeType_Name;
						}
						Object.assign(item, {
							is_type: 0,
							Kind_AutoID: consu.ConsumeTypeKind_AutoID,
						});
						this.ConsumptionList.push(item);
					});
				});
			}).catch(() => {
				this.$alert("暂无消费类型", "提示", { confirmButtonText: "确定" });
			});
		},
		/**获取会员信息
		 * @param isBo 是否未输入会员卡号时回测保存
		 */
		onMemberInfo(){
			if(!this.CardID){
				this.$message.warning('请先输入会员卡号');
				return;
			}
			let userInfo = this.$auth.getUserInfo(); //获取用户id
			
			let ConsumeList = [];
			this.orderMenuList.forEach((order) => {
				let item = {
					Eat_ConsumeID: order.Eat_ConsumeID,
					Eat_XFBMID: order.EAT_XFBMID,
					Eat_XFBMName: order.EAT_XFName,
					Eat_Number: order.Eat_Number,
					Eat_Price: order.Eat_SalePrice, //折前价格
					Eat_ZkPrice: order.Eat_CheckPrice, //折扣后的价格
				};
				ConsumeList.push(item);
			});
			let param = {
				Body_ID: newGuid(), //BodyID
				User_ID: userInfo?.User_ID,
				DynamicNo: this.CardID,
				Card_AutoID: this.card_AutoID,
				Card_SN: this.cardSNR,
				IsGetAnalysisData: true,
				Need_CheckBillMoney: true /**是否在读卡时核算账单消费优惠金额 */,
				OrderConsumes: ConsumeList, //核算账单优惠时，请求时传入账单菜品明细
			};
			
			const loading = this.$loading({
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			this.$httpAES.post("Bestech.CloudPos.GetMemberInfo", param).then((data) => {
				loading.close();
				console.log("获取的会员信息:" ,data);
				if (data.ResponseHeader.ResultCode != 0) {
					this.$message.error(data.ResponseHeader.ResultDesc);
				} else {
					this.memberType = 1;
					if (data.ResponseBody.length > 1) {
						this.cardMemberList = data.ResponseBody;
						this.cardMemberShow = true;
					} else {
						this.cardMemberList = data.ResponseBody;
						this.isread = true;
						this.tempMeberInfo = data.ResponseBody[0];
						this.birthday = data.ResponseBody[0]?.Birthday;
						this.ClientName = data.ResponseBody[0]?.MemberName;
						this.CardPhone = data.ResponseBody[0]?.Mobile;
						this.CardLevel = data.ResponseBody[0]?.Bestech_Card_KindName;
						this.ClientLike = data.ResponseBody[0].MemberAnalysisData?.Habits;
					}
				}
			}).catch((e) => {
				loading.close();
				this.$alert(e.message, "提示", { confirmButtonText: "确定" });
			});
		},
		/**滑出*/
		CardShowClick() {
			if (this.cardShowType == 1) {
				this.cardShowType = 0;
			} else {
				this.cardShowType = 1;
			}
		},
		/**选择所有 */
		selectValue(e, value) {
			e.currentTarget.select();
			this.currentTargetTxt = value;
			console.log("选中所有:" + this.currentTargetTxt);
		},
		//关闭弹出框
		CloseClicks() {
			this.$emit("closeModel");
		},
		/**订单渠道点击 */
		orderChannelClick() {
			if (this.orderChanneShow == false) {
				this.orderChanneShow = true;
			} else {
				this.orderChanneShow = false;
			}
		},
		/**订单渠道返回 */
		orderChanneConfirm(item) {
			this.orderclass = item.OrderChannel_AutoID;
			this.orderChannelName = item.OrderChannel_Name;
			this.orderChanneShow = false;
		},
		/**实体卡读卡 */
		readCard() {
			this.$webBrowser.redCardM1().then((d) => {
				if (d) {
					if (d.state == 0) {
						this.card_AutoID = d.data.cardID;
						this.CardID = d.data.cardNo;
						this.cardSNR = d.data.snr;
						this.onMemberInfo();
					} else {
						this.$message.error("卡信息读取失败：" + d.message);
					}
				}
			});
		},
		//提交快餐修改数据
		SetDeskClick() {
			this.headingInfos = {
				DeskName: this.DeskName,
				DeskAutoID: this.DeskAutoID,
				RoomAutoID: this.RoomAutoID,
				DinersNum: this.DinersNum,
				orderChannelName: this.orderChannelName,
				OrderChannel_AutoID: this.orderclass,
				brandNumber: this.brandNumber,
				orderRemark: this.orderRemark,
			};
			this.$emit("setDeskclose", this.headingInfos,this.tempMeberInfo);
		},
		/**会员选择返回*/
		selectCardInfo(data) {
			this.isread = true;
			this.CardID = data?.Card_No;
			this.ClientName = data?.MemberName;
			this.CardPhone = data?.Mobile;
			this.CardLevel = data?.Bestech_Card_KindName;
			this.birthday = data?.Birthday;
			this.ClientLike = data.MemberAnalysisData?.Habits;
			this.tempMeberInfo=data;
			this.cardMemberShow = false;
			this.iscardMemberShow = true;
		},
		/**桌台选择返回 */
		deskclose(desk) {
			console.log("desk:" + JSON.stringify(desk));
			this.DeskName = desk.Desk_Name;
			this.DeskAutoID = desk.Desk_AutoID;
			this.RoomAutoID = desk.Room_AutoID;
			this.choiceDeskShow = false;
			setTimeout(() => {
				this.$refs.input.focus();
			}, 100);
		},
		/**清空 */
		empty() {
			this.isread = false;
			this.ClientName = "";
			this.CardPhone = "";
			this.CardLevel = "";
			this.CardID = "";
			this.DinersNum = "";
			this.consumeAutoIDName = "";
			this.consumeAutoID = "";
			this.orderChannelName = "";
			this.orderclass = "";
			this.orderRemark = "";
			this.birthday = "";
			this.ClientLike = "";
			this.tempMeberInfo = null;
		},
		/**去除重复 */
		unique(newarr) {
			const res = new Map();
			return newarr.filter( (newarr) => !res.has(newarr.OrderChannel_AutoID) && res.set(newarr.OrderChannel_AutoID, 1) );
		},
	},
};
</script>
<style lang="scss">
@import "./modiRiseModel.scss";
.cardShow {
  transition: all ease 0.1s;
}
.cardShow-enter-active {
  animation: identifier 0.1s;
  overflow: hidden;
}
.cardShow-leave-active {
  animation: against 0.1s;
  overflow: hidden;
}
@keyframes identifier {
  from {
    height: 0;
  }
  to {
    height: 0px;
  }
}
@keyframes against {
  from {
    height: 0px;
  }

  to {
    height: 0;
  }
}
</style>