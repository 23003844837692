<template>
    <modal class="memberPaymentModel" :isShow="isShow" data-v-detail @keyup.esc="close">
        <div class="header">会员支付<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body" >
            <div class="cover" v-show="PayResultCode==1"></div>
            <div ref="CardMoney">
                <div class="filter-nav">
                    <div class="nav-box">
                        <tab-nav class="nav-li" @click="CardPayClick(1)">按会员卡号支付</tab-nav>
                        <tab-nav class="nav-li" @click="CardPayClick(2)">按会员身份码直接支付</tab-nav>
                    </div>
                    <div class="PointCheck">
                        <el-checkbox v-model="PointChecked">不参与线上积分活动</el-checkbox>
                    </div>
                </div>
                <div></div>
                <div class="clearfix" >
                    <div class="left-box">
                        <tab-pane>
                            <div class="tr-li">
                                请扫码或输入付款码：
                                <div class="from-input" style="width:325px">
                                    <input type="text" ref="input" class="key-focus" data-model='idCode' @focus="(e)=>e.target.select()" v-model="idCode" @keyup.enter="onMemberInfo()" />
                                    <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                                </div>
                                <button class="btn" @click="readCard()">读卡</button>
                            </div>
                            <div class="table-box">
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                    <div class="lable-txt" style="width:200px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo?.Bestech_Card_KindName}}</span></div>
                                    <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:220px;">
                                        <span style="letter-spacing:0.5em;">手机</span>号:
                                        <span style="color: #000;font-size: 14px;">{{$global.phonePrivacy(meberInfo?.Mobile)}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:200px;">
                                        性<span style="padding-left:25px;">别</span>：
                                        <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:145px;">生日：<span style="color: #000;font-size: 14px;">{{meberInfo?.Birthday}}</span></div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:220px;">
                                        余<span style="padding-left:25px;">额</span>:
                                        <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo?.Card_Money>0" >{{Number(meberInfo?.Card_Money)}}</span>
                                        <span style="color: red;"  v-if="meberInfo?.Gift_Money>0" >（赠{{Number(meberInfo?.Gift_Money)}}）</span>
                                    </div>
                                    <div class="lable-txt" style="width:200px;">
                                        <span style="letter-spacing:0.6em;">可用</span>券：
                                        <span style="color: red;font-size: 17px;font-weight:600;">{{meberInfo?.Live_CouponsNum}}</span>
                                        <span style="color: red;padding-left:5px;" v-if="meberInfo?.Live_CouponsNum">张</span>
                                    </div>
                                    <div class="lable-txt" style="width:145px;">积分：
                                        <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo?.Card_PilePoint>0">{{Number(meberInfo?.Card_PilePoint)}}</span>
                                    </div>
                                </div>
                            </div>
                            <tabs class="coupons-box">
                                <div class="filter-nav">
                                    <div class="nav-box">
                                        <tab-nav class="nav-li">代金券</tab-nav>
                                        <tab-nav class="nav-li">商品券</tab-nav>
                                    </div>
                                    <div class="float-right">
                                        <div class="bnt-fangxiang" ref="item-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                        <div class="bnt-fangxiang" ref="item-down"><i class="iconfont icon-fangxiang-down"></i></div>
                                    </div>
                                </div>
                                <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',paddingH:8,marginH:12,isRow:false}">
                                    <tab-pane class="tab-pane">
                                        <div class="item-li " :class="{selected:item.Check==true}" v-for="item in cashCoupon" :key="item" @click="oncashClick(item)">
                                            <div class="title">{{item.Coupon_Name}}</div>
                                            <div class="top-tag">
                                                <div class="left">{{item.Coupon_Code}}</div>
                                                <div class="right">{{Number(item.Coupon_FaceValue)}}</div>
                                            </div>
                                            <div class="left-bottom-tag">
                                                {{item.Begin_Date}}<br/>
                                                {{item.End_Date}}
                                            </div>
                                            <i class="iconfont icon-gou"></i>
                                        </div>
                                    </tab-pane>
                                    <tab-pane class="tab-pane">
                                        <div class="item-li " :class="{selected:item.Check==true}" v-for="item in commodityVoucher" :key="item" @click="oncashClick(item)">
                                            <div class="title">{{item.Coupon_Name}}</div>
                                            <div class="top-tag">
                                                <div class="left">{{item.Coupon_Code}}</div>
                                                <div class="right">{{discountShow(item)}}</div>
                                            </div>
                                            <div class="left-bottom-tag">
                                                {{item.Begin_Date}}<br/>
                                                {{item.End_Date}}
                                            </div>
                                            <i class="iconfont icon-gou"></i>
                                        </div>
                                    </tab-pane>
                                </div>
                            </tabs>
                            <div class="limitClass">注:剩余可开票金额&nbsp;
                                <span class="limitSpan" v-if="meberInfo.InvoiceMoney_Remain">{{Number(meberInfo.InvoiceMoney_Remain)}}</span>
                                <span class="limitSpan" v-else>0</span>
                            </div>
                            <div class="limitClass" v-if="meberInfo.Is_Limit_BalancePay==1">注:储值限制金额&nbsp;
                                <span class="limitSpan">{{Number(meberInfo.BalancePay_Limit)}}</span>
                            </div>
                        </tab-pane> 
                        <tab-pane>
                            <div class="tr-li">
                                扫会员动态身份码直接支付：<br/>
                                <div class="from-input" style="width:435px">
                                    <input type="text" ref="payCode" class="key-focus" data-model='payCode' v-model="payCode" @keyup.enter="confirm()" />
                                </div>
                                <button class="btn" @click="confirm()">扫码付款</button>
                            </div>
                        </tab-pane>
                    </div>
                    <div class="right-box">
                        <div class="keyboard">
                            <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                    data-value="9">9</span></div>
                            <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                    data-value="6">6</span></div>
                            <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                    data-value="3">3</span></div>
                            <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                    data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                        </div>
                        <div class="tr-box">
                            <el-switch v-model="Is_NotRockover" :inline-prompt="true" active-text="开启付款成功后，不直接翻台" inactive-text=""></el-switch>
                        </div>
                    </div>
                </div>
                <div class="money-info">
                    <div class="lable-txt">待支付金额：
                        <b class="noney red" v-if="unPaidMoney>0">{{paymentMoney}}</b>
                        <b class="noney red" v-if="unPaidMoney<=0">0</b>
                    </div>
                    <div class="lable-txt" v-if="is_CardPayShow">储值支付：
                        <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()" />
                    </div>
                    <div class="lable-txt" v-if="is_CardPayShow">积分支付：
                        <input type="text" class="key-focus"  style="color:red;" ref="PointPayinput" data-model='PointPay' :placeholder="PointPay_Scale"  v-model="PointPay" @click="onIntegralClick()"/>
                    </div>
                    <div class="lable-txt" v-if="is_CardPayShow">券抵扣：<b class="red">{{couponsMoney.toFixed(2)}}</b>&nbsp;&nbsp;共<b class="red">{{couponsCount}}</b>张</div>
                </div>
            </div>
        </tabs>
        <div class="footer-box">
            <div style="position:absolute;width:91%;height:10%;" v-if="PayResultCode==1" ></div>
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询支付结果</button>
            <button class="btn btn-border" @click="quchongzhi()" >去充值</button>
            <button class="btn btn-border" v-show="cancelVipPrice==true"  @click="cancelCardPrice()" >取消会员价</button>
             <!--@click="scanCodePay=true"-->
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <!--付款金额不够弹出-->
        <div class="RechargePopup" :class="{over:Rechargeshow==true}" v-if="Rechargeshow">
            <div class="Rechargeheader">
                <span class="RecharTitle">支付方式</span>
                <span class="RecharTitle">支付金额</span>
                <span class="RecharTitle">剩余金额:{{paymentMoney.toFixed(2)}}</span>
            </div>
            <div class="clearfix" ref="zf">
                <div class="tab-content">
                    <div class="left-box">
                        <div class="table-box">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width:110px;">选择</th>
                                        <th >支付方案</th>
                                        <th style="width:140px;">金额（元）</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="height:0">
                                        <td style="width:110px;height:0px"></td>
                                        <td style="height:0px"></td>
                                        <td style="width:140px;height:0px"></td>
                                    </tr>
                                    <tr class="tr-box" v-for="(item,index) in rechargeList"  :key="index" 
                                        v-show="item.Payment_Type!='801010301'|| (item.Payment_Type=='801010301' && isJHPay)"
                                        :class="{selected:rechargeclass==item.rechargeId}" @click="radioClick(item,index)" >
                                        <td>
                                            <div class="left-shadow" v-if="PayResultCode==1" ></div>
                                            <label class="checkbox" ><input type="radio" name="_fa_radio" :checked="rechargeclass==item.rechargeId" @click.stop="radioClick(item,index)" /><i class="iconfont icon-iconoption"></i></label>
                                        </td>
                                        <td class="alignLeft">{{item.rechargeName}}</td>
                                        <td class="input-money" >
                                            <input-pattern  v-if="item.rechargeId!=3" class="key-focus" pattern="money" v-model="item.rechargeMoney" :ref="(e)=>zfInput[index]=e"/>
                                        </td>
                                    </tr>
                                    <tr class="tr-expanded-cell" v-if="rechargeclass==3 && rechargeList[3].payConsumeList?.length>0">
                                        <td colspan="3">
                                            <p v-for="item in rechargeList[3].payConsumeList" :key="item">{{item.PAY_NAME}}支付￥{{item.payMoney}}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="right-box">
                        <div class="right-shadow" v-if="PayResultCode==1" ></div>
                        <div class="keyboard">
                            <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span data-value="9">9</span></div>
                            <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span data-value="6">6</span></div>
                            <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span data-value="3">3</span></div>
                            <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                        </div>
                        <div class="timer" v-show="timerCount>0">
                            <i class="iconfont icon-shijian"></i><span class="timer-shijian">{{timerCount}}秒</span>
                        </div>
                    </div>
                </div>
                <div class="footer-box">
                    <button class="btn btn-cancels"  @click="Rechargehide()">
                        <span v-show="PayResultCode==1"><i class="iconfont icon-suo"></i></span>取消</button>
                    <button class="btn btn-confirms"  @click="Rechargedefine()">确认</button>
                </div>
            </div>
        </div>
        
        <!--付款金额不够选择其它类型弹出-->
        <modal-load :isShow="payKindShow" :isUnload="true">
            <batch-pay-select-model :isShow="payKindShow" :payType="payType" 
                :payConsumeList="rechargeList[3].payConsumeList"
                :paymentMoney="paymentMoney" @close="payKindShow=false" @confirm="PayClick"></batch-pay-select-model>
        </modal-load>
        <!-- 扫码付 -->
        <scanCode-Pay-model  :isShow="scanCodePay" :memberPay="1" v-on:closeModel="scanCodePay=false,timerCount=0" :rechargeMoney="rechargeMoney" :orderInfo="orderInfo" :payTypeSSPay="payTypeSSPay" @memberCodePayModel="memberCodePayModel" @scanCodeclose="scanCodeclose" ></scanCode-Pay-model>
        <!--会员卡信息-->
        <modal-load :isShow="cardMemberShow">
            <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
        </modal-load>
        <modal-load :isShow="cardCodeShow" isUnload>
            <card-code-model :isShow="cardCodeShow" 
                :expireTime="meberInfo?.SMS_ExpireMinutes" :mobile="meberInfo?.Mobile" 
                @closeModel="cardCodeShow=false" 
                @cardCodeConfirm="cardCodeConfirm"></card-code-model>
        </modal-load>
        <!--密码验证-->
        <modal-load :isShow="passwordShow">
            <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
        </modal-load>
        <!--券数量修改-->
        <modal-load :isShow="numShow" :isUnload="true">
            <num-modify-model :isShow="numShow" @numCloseModel="numCloseModel" @cashConfirm="cashConfirm"></num-modify-model>
        </modal-load>
    </modal>
</template>


<script>
import cardCodeModel from '../model/cardCodeModel.vue'
import passwordConfirmModel from '../model/passwordConfirmModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
import { newGuid } from '/src/common/index.js'
import { cacheData } from '/src/services'
import scanCodePayModel from '../scanCodePayModel/scanCodePayModel.vue'
import ModalLoad from '../../../../template/modal/modalLoad.vue'
import numModifyModel from '../numModifyModel/numModifyModel.vue'
import batchPaySelectModel from './batchPaySelectModel/batchPaySelectModel.vue'

/**全局初始化数据 */
let _data={
    AllowCashierCancalVipPrice:false /**会员卡支付界面是否允许取消会员价，true:允许，false:不允许 */
};
/**会员支付 */
export default {
    name:"memberPaymentModel",
    components:{
        scanCodePayModel,
        cardMemberModel,
        cardCodeModel,
        ModalLoad,
        passwordConfirmModel,
        numModifyModel,
        batchPaySelectModel
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        payType:Object,
        unionEatAutoID:Object,
        unionType:Boolean
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.AllowCashierCancalVipPrice = globalSysSetting.AllowCashierCancalVipPrice;
        }
        return {
            //是否 付款成功后，不直接翻台
            Is_NotRockover:this.$cacheData.get("crm3.0Pay_Is_NotRockover")===true,
            cardMemberShow:false,
            scanCodePay:false,
            payTypeSSPay:'',
            payKindShow:false,
            //付款金额不够弹出
            Rechargeshow:false,
            //付款金额不够弹层中的 输入框
            zfInput:[],
            RechargeType:0,
            is_CardPayShow:true,
            cardCodeShow:false,
            passwordShow:false,
            is_AllowMixUsing:false, //是否有不可混用券
            is_AllowMixUsingCoupon_Name:'', //不可混用券
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            cashCoupon:'', //代金券
            commodityVoucher:'',//商品券
            couponsCount:0,  //选择的券总数量
            couponsMoney:0, //券的抵扣金额
            couponsPay:'', //储值支付金额
            PointPay:'', //积分支付积分
            PointPayCouponMoney:'',
            PointPayMoney:0, //积分转换成的金额
            PointPay_Scale:'', //积分比例
            unPaidMoney:0, //待付金额
            choiceCoupons:[], //选择的券
            errCounc:1,
            memberorderInfo:'',  //临时订单数据
            /**充值基础显示 */
            rechargeList:[
                {rechargeName:'现金',rechargeId:'1',rechargeMoney:0,Payment_ID:'',Payment_Code:'1',Payment_Type:1},
                {rechargeName:'聚合支付',rechargeId:'2',rechargeMoney:0,Payment_ID:'SSPay',Payment_Code:'SSPay',Payment_Type:801010301},
                {rechargeName:'多卡支付',rechargeId:'4',rechargeMoney:0,Payment_ID:'',Payment_Code:'',Payment_Type:1},
                {rechargeName:'其他',rechargeId:'3',rechargeMoney:0,Payment_ID:'',Payment_Code:'',Payment_Type:0}
            ],
            rechargeMoney:0,
            /**选择样式 */
            rechargeclass:0,
            /**选择券 */
            ispayid:0,
            ispayName:'',
            otherPay:'',
            Payment_Code:'',
            Payment_Type:0,
            scanPayCode:'',
            /**联台结账订单新时间戳 */
            UnionOrders:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**是否触发过会员查询 */
            isGetMember:false,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**是否直接用动态身份码支付 false:否,true:是*/
            isActiveCodePay:false,
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            tempidCode:'',
            cancelVipPrice:false,/**是否取消会员价 */
            /**默认倒计时 */
            timerCount:0,
            numShow:false,
            /**弹框时选中的券 */
            presentCoupon:'',
            sameCoupon:[],//与选中券一直的券集合
            selectSameCoupon:[],//选中相同券的集合
            MaxSelectSameNum:0,//最大可选数量
            isexistunavailable:false

        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           
           if(this.isActiveCodePay==false){
                this.$refs.input?.focus();
            }else{
                this.$refs.payCode.select();
                this.$refs.payCode.focus();
            }
           this.openimp()
        })
    },
    computed:{
        //剩余要支付的金额 = 待支付金额-积分优惠券 金额
        paymentMoney(){
            if(this.unPaidMoney>0){
                return Number((Number(this.unPaidMoney)-Number(this.PointPayCouponMoney)).toFixed(2))
            }
            return 0;
        },
        /**是否有聚合支付 */
        isJHPay(){
            /**支付方式中没有聚合支付时 弹出更多支付时需要隐藏聚合支付 */
            return this.payType?.some(it=>it.INTERFACE_TYPE=="801010301");
        }
    },
    watch: {
        //是否 付款成功后，不直接翻台
        Is_NotRockover(newVal){
            this.$cacheData.set("crm3.0Pay_Is_NotRockover",newVal);
        },
        orderInfo:{
            immediate:true,
            handler(vla){
                this.couponsPay=0
                this.couponsCount=0,
                this.couponsMoney=0,
                this.choiceCoupons=[]
                this.EmptyDatas()
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                if(this.memberorderInfo.Order_ConsumeList){
                    //合并联台的订单信息
                    if(this.unionType==true){
                        this.memberorderInfo.UnionOrderInfoList.map(union=>{
                            union.Order_ConsumeList.map(unOrder=>{
                                this.memberorderInfo.Order_ConsumeList.push(unOrder)
                            })
                        })
                        this.UnionOrders = JSON.parse(JSON.stringify(this.unionEatAutoID))
                    }
                    // this.memberorderInfo.Order_ConsumeList.map((order,i)=>{
                    //     order = Object.assign(order,{isCoupon:false,CouponNumber:1})
                    //     if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                    //         this.memberorderInfo.Order_ConsumeList.splice(i, 1)
                    //         return 
                    //     }
                    // })
                    for(let i=0; i<this.memberorderInfo.Order_ConsumeList.length; i++){
                        this.memberorderInfo.Order_ConsumeList[i] = Object.assign(this.memberorderInfo.Order_ConsumeList[i],{isCoupon:false,CouponNumber:0})
                        if(this.memberorderInfo.Order_ConsumeList[i].Is_Gift==true || (this.memberorderInfo.Order_ConsumeList[i].Eat_Number==0 && this.memberorderInfo.Order_ConsumeList[i].Refund_Number>0)){
                            this.memberorderInfo.Order_ConsumeList.splice(i,1)
                            i--
                        }
                    }
                }
                this.memberorderInfo.Order_ConsumeList.sort(this.compare('Eat_CheckPrice',''));
                console.log('memberorderInfo:',this.memberorderInfo)
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                if(this.memberorderInfo.Member_CardNo){
                    if(this.idCode==''){
                        this.idCode = this.memberorderInfo.Member_CardNo
                    }
                }
                this.payType.forEach(pay=>{
                    if(pay.INTERFACE_TYPE==1){
                        this.rechargeList[0].Payment_ID=pay.PAY_ID
                        this.rechargeList[0].Payment_Code = pay.PAY_CODE
                        this.rechargeList[0].Payment_Type = pay.INTERFACE_TYPE
                    }
                    if(pay.INTERFACE_TYPE==801010301){
                        this.rechargeList[1].Payment_ID=pay.PAY_ID
                        this.rechargeList[1].Payment_Code = pay.PAY_CODE
                        this.rechargeList[1].Payment_Type = pay.INTERFACE_TYPE
                    }
                })
                this.cardInfoShow()
                this.PointPayCouponMoneyInfo(0)
                //this.unPaidMoney=this.snackPaidMoney
                
            }
        },
        timerCount:{
            handler(value) {
                setTimeout(() => {
                    if(this.timerCount>1){
                        this.timerCount--;
                    }else{
                        this.timerCount=0
                        this.PayResultCode=0
                    }
                }, 1000);
            },
            immediate: true
        },
        PayResultCode(){
            if(this.PayResultCode==0){
                clearTimeout(this._timePay);//清除定时器
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.openimp()
            }else{
                this.create();
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
               this.couponsPay=0
            }
            this.PointPayCouponMoneyInfo(1)
        },
        PointPay(){
            if(this.PointPay<0){
               this.PointPay=0
            }else{
                if(Number(this.PointPay)>this.meberInfo.Card_PilePoint){
                    this.$message.warning('您的可用积分为:'+Number(this.meberInfo.Card_PilePoint)+'');
                    this.PointPay= Number(this.meberInfo.Card_PilePoint)
                    return
                }
                if(this.meberInfo.PointPay_Scale){
                    this.PointPayMoney = Number(this.PointPay) / this.meberInfo.PointPay_Scale
                }
            }
            this.PointPayCouponMoneyInfo(2)
        },
        //付款金额不够弹出
        Rechargeshow(newVal){
            if(newVal){
                this.$nextTick(()=>{
                    initKeyBoard.call(this,this.$refs.zf);
                })
            }
        }
    },
    methods:{
        //关闭弹层
        close(){
            if(this.Rechargeshow){//关闭其他支付
                this.Rechargehide();
            }else{
                this.hide();
            }
        },
        quchongzhi(){
            this.$router.push({name: 'member', params:{memberPay:1,Desk_AutoID:this.orderInfo.Eat_DeskID,Eat_AutoID:this.orderInfo.Eat_AutoID}});
            return
        },
        openimp(){
            this.rechargeList=[
                {rechargeName:'现金',rechargeId:'1',rechargeMoney:0,Payment_ID:'',Payment_Code:'1',Payment_Type:1},
                {rechargeName:'聚合支付',rechargeId:'2',rechargeMoney:0,Payment_ID:'SSPay',Payment_Code:'SSPay',Payment_Type:801010301},
                {rechargeName:'多卡支付',rechargeId:'4',rechargeMoney:0,Payment_ID:'',Payment_Code:'',Payment_Type:1},
                {rechargeName:'其他',rechargeId:'3',rechargeMoney:0,Payment_ID:'',Payment_Code:'',Payment_Type:0}
            ]
            this.idCode='',this.errorTitle='',this.tempidCode='',this.selectSameCoupon=[]
            this.initializaTion()
            this.EmptyDatas()
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            if(this.memberorderInfo.Order_ConsumeList){
                //合并联台的订单信息
                if(this.unionType==true){
                    this.memberorderInfo.UnionOrderInfoList.map(union=>{
                        union.Order_ConsumeList.map(unOrder=>{
                            this.memberorderInfo.Order_ConsumeList.push(unOrder)
                        })
                    })
                    this.UnionOrders = JSON.parse(JSON.stringify(this.unionEatAutoID))
                }
                // this.memberorderInfo.Order_ConsumeList.map((order,i)=>{
                //     order = Object.assign(order,{isCoupon:false,CouponNumber:1})
                //     if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                //         this.memberorderInfo.Order_ConsumeList.splice(i, 1)
                //         return 
                //     }
                // })
                for(let i=0; i<this.memberorderInfo.Order_ConsumeList.length; i++){
                    this.memberorderInfo.Order_ConsumeList[i] = Object.assign(this.memberorderInfo.Order_ConsumeList[i],{isCoupon:false,CouponNumber:0})
                    if(this.memberorderInfo.Order_ConsumeList[i].Is_Gift==true || (this.memberorderInfo.Order_ConsumeList[i].Eat_Number==0 && this.memberorderInfo.Order_ConsumeList[i].Refund_Number>0)){
                        this.memberorderInfo.Order_ConsumeList.splice(i,1)
                        i--
                    }
                }
            }
            this.memberorderInfo.Order_ConsumeList.sort(this.compare('Eat_CheckPrice',''));
            console.log('memberorderInfo:',this.memberorderInfo)
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            if(this.memberorderInfo.Member_CardNo){
                this.idCode = this.memberorderInfo.Member_CardNo
                this.onMemberInfo()
            }
            
            this.payType.map(pay=>{
                if(pay.INTERFACE_TYPE==1){
                    this.rechargeList[0].Payment_ID=pay.PAY_ID
                    this.rechargeList[0].Payment_Code = pay.PAY_CODE
                    this.rechargeList[0].Payment_Type = pay.INTERFACE_TYPE
                }
                if(pay.INTERFACE_TYPE==801010301){
                    this.rechargeList[1].Payment_ID=pay.PAY_ID
                    this.rechargeList[1].Payment_Code = pay.PAY_CODE
                    this.rechargeList[1].Payment_Type = pay.INTERFACE_TYPE
                }
            })
            this.cancelVipPrice = _data.AllowCashierCancalVipPrice
            this.PointPayCouponMoneyInfo(0)
        },
        /**聚合支付取消 */
        scanCodeclose(){
            this.scanCodePay=false
            this.timerCount=0
            this.create()
        },
        /**默认input焦点 */
        EmptyDatas(){
            if(this.isActiveCodePay==false){
                setTimeout(() => {
                    this.$refs.input.focus();
                },100);
            }else{
                setTimeout(() => {
                    this.$refs.payCode.focus();
                },100);
            }
        },
        blurRechargeMoney(){
            if(this.unPaidMoney.toFixed(2)<0){
                this.unPaidMoney =0
            }
        },
        /**获取支付方式*/
        onChoicePayType(pay){    
            if(this.ispayid != pay.PAY_ID){
                this.ispayid = pay.PAY_ID
                this.ispayName = pay.PAY_NAME
                this.Payment_Code = pay.PAY_CODE
                this.Payment_Type = pay.INTERFACE_TYPE
            }
        },
        Rechargedefine(){
            this.RechargeType=1
            let money=0
            let NewMoney = this.unPaidMoney.toFixed(2)
            this.rechargeList.forEach(re=>{
                money = Number(money) + Number(re.rechargeMoney)
            })
            if(money!=((NewMoney-Number(this.couponsPay)-this.PointPayMoney).toFixed(2))){
                this.$message.error('应付金额和剩下需要付款金额不相等');
            }else{
                this.confirm()
            }
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**其他支付 */
        PayClick(payConsumeList,payMoney){
            this.rechargeList[3].payConsumeList=payConsumeList;
            this.rechargeList[3].rechargeMoney=payMoney;
            this.payKindShow=false
        },
        /**查询支付结果 */
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    //订单时间戳已变更需要去刷新订单时间,并且把锁解开
                    if(data.ResponseHeader.ResultCode==3002){
                        /**刷新订单数据 */
                        this.PayResultCode=0
                        this.$emit("refreshOrder")
                    }
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        this.$message.success({ message:data.ResponseHeader.ResultDesc});
                    }
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    //this.ispaySuccess=0;
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**会员支付 */
        CardPayClick(type){
            if(type==1){
                this.is_CardPayShow=true
                this.isActiveCodePay=false
                let _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
                if(this.meberInfo.Card_Money){
                    if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                        this.couponsPay = _unPaidMoney
                    }else{
                        this.couponsPay = Number(this.meberInfo.Card_Money)
                    }
                }
                setTimeout(() => {
                    this.$refs.input.focus();
                },100);
            }else{
                this.is_CardPayShow=false
                this.isActiveCodePay=true
                this.couponsCount=0
                this.couponsPay=0,this.PointPay=0,this.couponsMoney=0
                if(this.choiceCoupons){
                    this.choiceCoupons.map(item=>{
                        this.unPaidMoney = Number(this.unPaidMoney) + Number(item.Coupon_FaceValue)
                    })
                }
                if(this.cashCoupon){
                    this.cashCoupon.map(item=>{
                        item.Check = false
                    })
                }
                if(this.commodityVoucher){
                    this.commodityVoucher.map(item=>{
                        item.Check = false
                    })
                }
                this.choiceCoupons=[]
                setTimeout(() => {
                    this.$refs.payCode.focus();
                },100);
                
            }
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode && !this.cardSNR){
                this.$message.warning('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            //this.$refs.input.blur();
            this.$refs.input?.select();
            if(this.isGetMember==true){
                if(this.tempidCode!=this.idCode){
                    this.idCode = this.tempidCode
                }
                return
            }else{
                this.isGetMember=true
            }
            if(!this.tempidCode){
                this.tempidCode=this.idCode
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()

           

            let OrderConsumes=this.memberorderInfo?.Order_ConsumeList?.map(it=>{
                return {
                    Eat_ConsumeID:it.Eat_ConsumeID,
                    Eat_XFBMID:it.EAT_XFBMID,
                    Eat_XFBMName:it.EAT_XFName,
                    Eat_Number:it.Eat_Number,
                    Eat_Price:it.Eat_SalePrice,
                    Eat_ZkPrice:it.Eat_CheckPrice,
                    Remark:it.Remark
                }
            })

            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Operator_Name:this.userInfo?.Login_Name, //操作员
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetCouponUsingRules:true,
                Member_Type:2, //2：正品CRM3.0 会员 ，4：微生活会员。(如果不传，则按照顺序判断系统开通了哪个会员对接)，5：新享会，6：正品G9会员卡
                OrderConsumes:OrderConsumes,
                Need_CheckBillMoney:true
            }
            this.cardSNR='';
            this.card_AutoID='';
            console.log(param)
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                this.isGetMember=false
                this.tempidCode=''
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                         
                        if(this.meberInfo.OverDraftLimitedMoney){
                            this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
                        }
                        this.cardInfoShow()
                        this.idCode = this.meberInfo.Card_No;
                        if(this.meberInfo.Is_Have_ParPrice==1){
                            this.memberVipMoney(this.meberInfo)
                        }
                        if(data.ResponseBody[0]?.IsBindOrder==true){
                            this.$emit("BindCardCleanup",data.ResponseBody[0],'update')
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.isGetMember=false
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**券选择 */
        oncashClick(item){
            if(item.Check==true){
                if(item.Coupon_Kind==2){
                    this.memberorderInfo.Order_ConsumeList.map(order=>{
                        this.choiceCoupons.map(cho=>{
                            if(order.Eat_ConsumeID==cho.Eat_ConsumeID && item.Coupon_AutoID==cho.Coupon_AutoID){
                                if(order.CouponNumber>=1){
                                    order.CouponNumber = order.CouponNumber -1
                                }
                                if(order.CouponNumber<order.Eat_Number){
                                    order.isCoupon=false
                                }
                            }
                        })
                    })
                    this.commodityJudge(item,2);
                    if(Number(this.couponsPay)< this.unPaidMoney){//待支付金额大于 储值支付金额 时
                       this.couponsPay = Number(Number(this.unPaidMoney)-Number(this.PointPayMoney)).toFixed(2);
                    }
                    if(Number(this.meberInfo?.Card_Money)<this.couponsPay){//会员余额小于 储值支付时 
                        this.couponsPay = Number(this.meberInfo?.Card_Money);
                    }
                }else{
                    this.unPaidMoney =this.unPaidMoney + Number(item.Coupon_FaceValue)
                    this.couponsMoney = Number(this.couponsMoney)-Number(item.Coupon_FaceValue)
                    this.couponsPay = Number(Number(this.couponsPay)-Number(item.Coupon_FaceValue)).toFixed(2)
                    if(Number(this.couponsPay)< this.unPaidMoney){
                       this.couponsPay = Number(Number(this.unPaidMoney)-Number(this.PointPayMoney)).toFixed(2)
                    }
                    if(Number(this.meberInfo?.Card_Money)<this.couponsPay){
                        this.couponsPay = Number(this.meberInfo?.Card_Money)
                    }
                    item.Coupon_FaceValue = item.CouponOriMoney
                }
                this.couponsCount--;
                item.Check =false;
                this.choiceCoupons = this.choiceCoupons.filter(t => t.Coupon_AutoID != item.Coupon_AutoID)
                this.selectSameCoupon=this.selectSameCoupon.filter(t => t.Coupon_AutoID != item.Coupon_AutoID)
                /**券的使用规则 */
                if(item.CouponUsingDetailedRules){
                    /**是否可以混用 */
                    let tktype=0
                    this.choiceCoupons.map(ch=>{
                        if(ch.Tk_AutoID==item.Tk_AutoID){
                            tktype=1
                        }
                    })
                    if(this.is_AllowMixUsing==true && tktype!=1){
                        this.is_AllowMixUsing = false;
                    }
                }
            }else{
                if(this.unPaidMoney<=0){
                    this.$message.error('付款金额已够,无需再选用多余的券');
                    return
                }
                this.coumputesamecoupon(item);
                if(this.isexistunavailable){return;}
                if(this.sameCoupon&&this.sameCoupon?.length>1)
                {
                    this.presentCoupon=item
                    this.numShow=true
                }
                else
                {
                     /**券的使用规则 */
                    if(item.CouponUsingDetailedRules){
                        this.IsexistCouponUsingDetailedRules(item);
                        let _ConsumeMoney = item.CouponUsingDetailedRules.ConsumeMoney;
                        let _ConsumeMoney_ControlType = item.CouponUsingDetailedRules.ConsumeMoney_ControlType;
                        //判断金额控制ConsumeMoney_ControlType 0不限金额,1为账单满多少金额可用,2为每满多少金额后可用1张
                        this.CouponProducts(item,_ConsumeMoney,_ConsumeMoney_ControlType)
                        if(this.errCounc==1){
                            this.commodityJudge(item,1)
                            this.CouponsSelect(item)
                        }
                    }else{
                        this.commodityJudge(item,1)
                        this.CouponsSelect(item)
                    }
                }
                
            }
        },
        //券的使用规则
        IsexistCouponUsingDetailedRules(item){
            this.errCounc =1
            let _count=0;
            //每次消费最多可用张数
            let _UseMaxNum = item.CouponUsingDetailedRules.UseMaxNum;
            if(_UseMaxNum!=0){
                this.choiceCoupons.map(cho=>{
                    if(cho.Tk_AutoID==item.Tk_AutoID){
                        _count++;
                    }
                })
                if(_count>=_UseMaxNum){
                    this.$message.error('超出每次消费使用次数');
                    this.errCounc++
                    this.isexistunavailable=true;
                    return
                }
            }
            //判断是否存在不可混用券
            if(this.is_AllowMixUsing==true){
                if(item.Coupon_Name!=this.is_AllowMixUsingCoupon_Name){
                    this.$message.error(this.is_AllowMixUsingCoupon_Name+'不可与其他券混用');
                    this.errCounc++
                    this.isexistunavailable=true;
                    return;
                }
            }
            //判断是否存在部分不可混用的券
            if(this.couponsCount>0){
                let _coupname=''
                let _coupType=0
                this.choiceCoupons.map(cho=>{
                    if(cho.CouponUsingDetailedRules){
                        if(cho.CouponUsingDetailedRules.Is_AllowMixUsing==3){
                            cho.CouponUsingDetailedRules.ForbidMixUsingCoupons.map(coupon=>{
                                if(coupon.TK_AutoID == item.Tk_AutoID){
                                    _coupType=1
                                    _coupname=''+item.Coupon_Name+'不可与'+cho.Coupon_Name+'混用'
                                    this.errCounc++
                                    return;
                                }
                            })
                        }
                    }            
                })
                if(_coupType==1){
                    this.$message.error(_coupname);
                    this.isexistunavailable=true;
                    return;
                }
            }
            //是否可以混用 1.可以 2.不可以 3.部分不可以
            if(item.CouponUsingDetailedRules.Is_AllowMixUsing==2){
                if(this.couponsCount>0){
                    let _coupname=''
                    let _coupType=0
                    this.choiceCoupons.map(cho=>{
                        if(cho.Tk_AutoID!=item.Tk_AutoID){
                            _coupType=1
                            _coupname=item.Coupon_Name+'不可与其他券混用'
                            this.errCounc++
                            return;
                        }   
                    })
                    if(_coupType==1){
                        this.$message.error(_coupname);
                        this.isexistunavailable=true;
                        return;
                    }   
                }
            }
            if(item.CouponUsingDetailedRules.Is_AllowMixUsing==3){
                if(this.couponsCount>0){
                    let _coupname=''
                    let _coupType=0
                    item.CouponUsingDetailedRules.ForbidMixUsingCoupons.map(pons=>{
                        this.choiceCoupons.map(cho=>{
                            if(pons.TK_AutoID==cho.Tk_AutoID){
                                _coupType=1
                                _coupname=''+item.Coupon_Name+'不可与'+cho.Coupon_Name+'混用'
                                this.errCounc++
                                return;
                            }
                        })
                    })
                    if(_coupType==1){
                        this.$message.error(_coupname);
                        this.isexistunavailable=true;
                        return;
                    }
                }
            }
            //判断日期
            if(item.CouponUsingDetailedRules.UseWeeks){
                let _week = RegExp(this.setNowTimes());
                let _UseWeeks = item.CouponUsingDetailedRules.UseWeeks;
                if(!_week.test(_UseWeeks)){
                    this.$message.error('该券今日不可使用');
                    this.errCounc++
                    this.isexistunavailable=true;
                    return;
                }
            }
            //时间段控制 0所有时段,1指定时段
            if(item.CouponUsingDetailedRules.TimeFrame_ControlType==1){
                let date = new Date(); //当前时间
                let hh = date.getHours()+':'+date.getMinutes(); //时:分
                let hm =hh.split(':')
                let timeError =''
                let isused=false;
                if(item.CouponUsingDetailedRules.UsingTimeFrames.length>0){
                    item.CouponUsingDetailedRules.UsingTimeFrames.map(time=>{
                        if(isused){return}
                        let Begin_Time = time.Begin_Time.split(':')
                        let End_Time = time.End_Time.split(':')
                        if(date.setHours(Begin_Time[0],Begin_Time[1]) > date.setHours(hm[0],hm[1]) || date.setHours(hm[0],hm[1]) > date.setHours(End_Time[0],End_Time[1])){
                            isused=false;
                        }
                        else{
                            isused=true;
                        }

                    })
                    if(!isused)
                    {
                        timeError ='该券当前时间不可使用'
                        this.errCounc++
                    }
                }else{
                    timeError ='该券当前时间不可使用'
                    this.errCounc++
                }
                if(timeError!=''){
                    this.$message.error(timeError);
                    this.isexistunavailable=true;
                    return
                }
            }
            //节假日控制 0 不限制节假日   1 剔除指定的节假日
            if(item.CouponUsingDetailedRules.Holiday_ControlType==1){
                let _timeName=''
                let _timeType=0
                let now =  new Date(); //当前时间
                let time =  now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()
                item.CouponUsingDetailedRules.ForbidUsingCouponHolidays.map(days=>{
                    days.Holiday_Dates.map(date=>{
                        if(new Date(time).Format("yyyy-MM-dd") ==date){
                            _timeType=1
                            _timeName='该券不可在'+days.Holiday_Name+'使用'
                            this.errCounc++
                            return
                        }
                    })
                })
                if(_timeType==1){
                    this.$message.error(_timeName);
                    this.isexistunavailable=true;
                    return
                }
            }
            //商品券
            if(item.Coupon_Kind==2){
                let _coupTypes = 0
                let _coupname=''
                let _coupType=0
                let Eat_Number=0
                let Eat_ConsumeID=''
                //如果是折扣券
                if(item.DiscountType==2){
                    let isFindFood=0
                    this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                        if(isFindFood==0){
                            if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1){
                                if(order.isCoupon==false && order?.Is_Discounted==true){
                                    if(order.Eat_CheckPrice != order.Eat_SalePrice){
                                        _coupname= order.EAT_XFName+'价格已变动,无法使用折扣券'
                                        _coupType=1
                                    }else{
                                        _coupname=''
                                        _coupType=1
                                        isFindFood=1
                                    }
                                }else{
                                    _coupname='订单里没有可折的菜品,无法使用折扣券'
                                    _coupType=1
                                }
                            }else{
                                _coupname='订单里没有可折的菜品,无法使用折扣券'
                                _coupType=1
                            }
                        }
                    })
                }
                if(!_coupname){
                    //&& order.Eat_CheckPrice == order.Eat_SalePrice
                    this.memberorderInfo.Order_ConsumeList.some(order=>{
                        let _types = 0
                        if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && order.Food_Type!=4){
                            if(order.isCoupon==false){
                                _coupTypes=1
                                Eat_Number = Number(Eat_Number) + Number(order.Eat_Number)
                                Eat_ConsumeID = order.Eat_ConsumeID
                                item = Object.assign(item,{Eat_ConsumeID:Eat_ConsumeID})

                                _types=1
                            }else{
                                _coupname='对应的菜品已享受优惠,无法选择多张'
                                _coupType=1
                            }
                        }else{
                            _coupname='订单需要有:'+item.Coupon_Exchange_Dishes+'编号菜品才可使用该券'
                            _coupType=1
                        }
                        if(_types==1){
                            return true
                        }
                    })
                }
                if(_coupType==1 && _coupTypes==0){
                    this.errCounc++
                    this.$message.error(_coupname);
                    this.isexistunavailable=true;
                    return
                }
            }
        },
        /**计算是否有相同的券信息 */
        coumputesamecoupon(item){
            this.isexistunavailable=false;
            //先判断当前券规则是否适用此订单
            this.IsexistCouponUsingDetailedRules(item);
            if(item.CouponUsingDetailedRules){
                let _ConsumeMoney = item.CouponUsingDetailedRules.ConsumeMoney;
                let _ConsumeMoney_ControlType = item.CouponUsingDetailedRules.ConsumeMoney_ControlType;
                if(item.CouponUsingDetailedRules.Products_ControlType==0){
                    this.MaxSelectSameNum=0
                    if(_ConsumeMoney_ControlType==1){
                        //+this.memberorderInfo.Mantissa_Money
                        if((this.memberorderInfo.Eat_CheckMoney)<_ConsumeMoney){
                            this.$message.error('账单需要满足'+Number(_ConsumeMoney)+'才可以使用这张券');
                            this.isexistunavailable=true;
                            return
                        }
                    }
                    if(_ConsumeMoney_ControlType==2){
                        //+ this.memberorderInfo.Mantissa_Money
                        if(_ConsumeMoney > (this.memberorderInfo.Eat_CheckMoney)){
                            this.$message.error('账单每满足'+Number(_ConsumeMoney)+'可以使用1张此券');
                            this.isexistunavailable=true;
                            return
                        }else{
                            let money =0;
                            this.memberorderInfo.Order_ConsumeList.map(order=>{
                                money = Number(money) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            })
                            let _choiceNumber=1
                            if(this.choiceCoupons.length>0){
                                this.choiceCoupons.map(()=>{
                                    _choiceNumber = _choiceNumber+1
                                    if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                        this.$message.error('账单每满足'+Number(_ConsumeMoney)+'可以使用1张此券');
                                        this.isexistunavailable=true;
                                        return
                                    }
                                })
                            }else{
                                if(money< item.CouponUsingDetailedRules.ConsumeMoney ){
                                    this.$message.error('账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张');
                                    this.isexistunavailable=true;
                                    return
                                }
                            }  
                        }
                    }
                }
                
                /**指定菜品 */
                if(item.CouponUsingDetailedRules.Products_ControlType==1){
                    this.MaxSelectSameNum=0
                    /**查询下单菜品中是否符合该券使用条件 */
                    let _Products = item.CouponUsingDetailedRules.AppointProducts;
                    let _productmoney =0;
                    this.memberorderInfo.Order_ConsumeList.map(order=>{
                        if(_Products.indexOf(order.EAT_XFCode)!=-1){
                            _productmoney = Number(_productmoney) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                        }
                    })
                    if(_ConsumeMoney_ControlType==1){
                        if(_productmoney<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.$message.error('该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用');
                            this.isexistunavailable=true;
                            return
                        }
                    }
                    if(_ConsumeMoney_ControlType==2){
                        let ErrorTitle=''
                        let _choiceNumber =1
                        if(this.choiceCoupons.length>0){
                            this.choiceCoupons.map(cho=>{
                                if(cho.Tk_AutoID == item.Tk_AutoID){
                                    _choiceNumber = _choiceNumber+1
                                    if(_productmoney < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                        ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                    }
                                }else{
                                    if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                                        ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                    }
                                }
                            })
                        }else{
                            if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                                ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                            }
                        }
                        if(ErrorTitle!=''){
                            this.$message.error(ErrorTitle);
                            this.isexistunavailable=true;
                            return
                        }
                    }
                    
                }
                /**指定菜品适用规则 */
                if(item.CouponUsingDetailedRules.Products_ControlType==2){
                    this.MaxSelectSameNum=0
                    if(_ConsumeMoney_ControlType==1){
                        let ErrorTitle=''
                        /**指定的菜品类型,菜品小类编号 */
                        let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                        if(_AppointProductCategorys!=''){
                            let money=0
                            this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                                if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                    money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                                }
                            })
                            if(money==0){
                                ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            }else{
                                if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                    ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                                }
                            }
                        }
                        /**菜品小类列表中不可用的菜品编号 */
                        let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                        if(_InCategoryForbidProducts!=''){
                            let money=0
                            this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                                if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                    money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                                }
                            })
                            if(money==0){
                                ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            }else{
                                if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                    ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                                }
                            }
                        }
                        /**菜品小类列表中可用的菜品编号 */
                        let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                        if(_OutCategoryAppointProducts!=''){
                            let money=0
                            this.memberorderInfo.Order_ConsumeList.map(order=>{
                                if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                    money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                                }
                            })
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                            }
                        }
                        if(ErrorTitle!=''){
                            this.$message.error(ErrorTitle);
                            this.isexistunavailable=true;
                            return
                        }
                    }
                    if(_ConsumeMoney_ControlType==2){
                        let ErrorTitle=''
                        /**指定的菜品类型,菜品小类编号 */
                        let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                        if(_AppointProductCategorys!=''){
                            let money=0
                            this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                                if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                    money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                                }
                            })
                            if(money==0){
                                ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
        
                            }else{
                                let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                                ErrorTitle = cm.ErrorTitle
                            }
                        }
                        /**菜品小类列表中不可用的菜品编号 */
                        let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                        if(_InCategoryForbidProducts!=''){
                            let money=0
                            this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                                if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                    money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                                }
                            })
                            if(money==0){
                                ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
            
                            }else{
                                let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                                ErrorTitle = cm.ErrorTitle
                            }
                        }
                        /**菜品小类列表中可用的菜品编号 */
                        let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                        if(_OutCategoryAppointProducts!=''){
                            let money=0
                            this.memberorderInfo.Order_ConsumeList.map(order=>{
                                if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                    money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                                }
                            })
                            if(money==0){
                                ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            }else{
                                let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                                ErrorTitle = cm.ErrorTitle
                            }
                        }

                        if(ErrorTitle!=''){
                            this.$message.error(ErrorTitle);
                            this.isexistunavailable=true;
                            return
                        }
                    }
                }
                
                    
            }
            this.sameCoupon=[];
            if(item.Coupon_Kind==1){
                this.cashCoupon.map(cho=>{
                    if(item.Tk_AutoID==cho.Tk_AutoID && item.Begin_Date==cho.Begin_Date&&item.End_Date==cho.End_Date){
                        this.sameCoupon.push(cho);
                    }
                })
            }else
            {
                this.commodityVoucher.map(cho=>{
                    if(item.Tk_AutoID==cho.Tk_AutoID && item.Begin_Date==cho.Begin_Date&&item.End_Date==cho.End_Date){
                        this.sameCoupon.push(cho);
                    }
                })
            }
        },
        /**执行会员价 */
        memberVipMoney(meberInfo){
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //订单ID
                Member_CardNo:meberInfo.Card_No, //会员卡号
                Timestamps:this.memberorderInfo.Timestamps, //订单时间戳
            }
            const loading = this.$loading({
                text: "正在尝试账单是否可以执行会员价...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.DoVipPrice",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$emit("cardPriceReturn",data,1)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**取消会员价 */
        cancelCardPrice(){
            if(this.meberInfo){
                this.$confirm('是否取消会员价', '提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                .then(() => {
                    this.$emit("cancelCardPrice",this.memberorderInfo.Timestamps.toString())
                    this.PointPay=''
                }).catch(() => {});
            }else{
                this.$message.error('请先获取会员身份信息');
            }
        },
         /** Products_ControlType: 0 适用所有菜品  1 指定的菜品满足金额才可使用券，2.指定菜品方案规则，满足菜品方案规则条件下的菜品总价才能使用对应券 */
         /**ControlType:0 不限制起用金额并不开启消费菜品控制 , 1：消费总金额满足才可使用券 2.消费总金额每满足指定价格可使用1张 */
        CouponProducts(item,_ConsumeMoney,ControlType){
            /**0:所有菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==0){
                if(ControlType==1){
                    //+this.memberorderInfo.Mantissa_Money
                    if((this.memberorderInfo.Eat_CheckMoney)<_ConsumeMoney){
                        this.errCounc++
                        this.$message.error('账单需要满足'+Number(_ConsumeMoney)+'才可以使用这张券');
                        return
                    }
                }
                if(ControlType==2){
                    //+ this.memberorderInfo.Mantissa_Money
                    if(_ConsumeMoney > (this.memberorderInfo.Eat_CheckMoney)){
                        this.errCounc++
                        this.$message.error('账单每满足'+Number(_ConsumeMoney)+'可以使用1张此券');
                        return
                    }else{
                        let money =0;
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            money = Number(money) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                        })
                        let _choiceNumber=1
                        let ErrorTitle=''
                        let ErrorType=0
                        if(this.choiceCoupons.length>0){
                            this.choiceCoupons.map(()=>{
                                _choiceNumber = _choiceNumber+1
                                if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            })
                        }else{
                            if(money< item.CouponUsingDetailedRules.ConsumeMoney ){
                                this.errCounc++
                                ErrorType=1
                                ErrorTitle = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                            }
                        }
                        if(ErrorType==1){
                            this.$message.error(ErrorTitle);
                            return
                        }   
                    }
                }
            }
            /**指定菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==1){
                /**查询下单菜品中是否符合该券使用条件 */
                let _Products = item.CouponUsingDetailedRules.AppointProducts;
                let _productmoney =0;
                this.memberorderInfo.Order_ConsumeList.map(order=>{
                    if(_Products.indexOf(order.EAT_XFCode)!=-1){
                        _productmoney = Number(_productmoney) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                    }
                })
                if(ControlType==1){
                    if(_productmoney<item.CouponUsingDetailedRules.ConsumeMoney){
                        this.errCounc++
                        this.$message.error('该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用');
                        return
                    }
                }
                if(ControlType==2){
                    let _choiceNumber =1
                    let ErrorTitle=''
                    let ErrorType=0
                    if(this.choiceCoupons.length>0){
                        this.choiceCoupons.map(cho=>{
                            if(cho.Tk_AutoID == item.Tk_AutoID){
                                _choiceNumber = _choiceNumber+1
                                if(_productmoney < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }else{
                                if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }
                        })
                    }else{
                        if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                    if(ErrorType==1){
                        this.$message.error(ErrorTitle);
                        return
                    }
                }
                
            }
            /**指定菜品适用规则 */
            if(item.CouponUsingDetailedRules.Products_ControlType==2){
                if(ControlType==1){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorType=1
                                this.errCounc++
                                ErrorCount++
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorType=1
                                this.errCounc++
                                ErrorCount++
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.errCounc++
                            ErrorCount++
                            ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                        }
                    }
                    if(ErrorCount!=3){
                        this.errCounc=1
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        this.$message.error(ErrorTitle);
                        return
                    }
                }
                if(ControlType==2){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    if(ErrorCount!=3){
                        this.errCounc=1
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        this.$message.error(ErrorTitle);
                        return
                    }
                }
            }
        },
        // 多选判断
        /** Products_ControlType: 0 适用所有菜品  1 指定的菜品满足金额才可使用券，2.指定菜品方案规则，满足菜品方案规则条件下的菜品总价才能使用对应券 */
        /**ControlType:0 不限制起用金额并不开启消费菜品控制 , 1：消费总金额满足才可使用券 2.消费总金额每满足指定价格可使用1张 */
        MultipleCouponProducts(item,_ConsumeMoney,ControlType,Error){
            let _count=0;
            //每次消费最多可用张数
            let _UseMaxNum = item.CouponUsingDetailedRules.UseMaxNum;
            if(_UseMaxNum!=0){
                this.choiceCoupons.map(cho=>{
                    if(cho.Tk_AutoID==item.Tk_AutoID){
                        _count++;
                    }
                })
                if(_count>=_UseMaxNum){
                    Error='超出每次消费使用次数';
                }
            }
            /**0:所有菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==0){
                if(ControlType==1){
                    //+this.memberorderInfo.Mantissa_Money
                    if((this.memberorderInfo.Eat_CheckMoney)<_ConsumeMoney){
                        Error='账单需要满足'+Number(_ConsumeMoney)+'才可以使用这张券';
                    }
                }
                if(ControlType==2){
                    //+ this.memberorderInfo.Mantissa_Money
                    if(_ConsumeMoney > (this.memberorderInfo.Eat_CheckMoney)){
                        Error='账单每满足'+Number(_ConsumeMoney)+'可以使用1张此券'
                    }else{
                        let money =0;
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            money = Number(money) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                        })
                        let _choiceNumber=1
                        if(this.choiceCoupons.length>0){
                            this.choiceCoupons.map(()=>{
                                _choiceNumber = _choiceNumber+1
                                if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    Error = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            })
                        }else{
                            if(money< item.CouponUsingDetailedRules.ConsumeMoney ){
                                Error = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                            }
                        }   
                    }
                }
            }
            /**指定菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==1){
                /**查询下单菜品中是否符合该券使用条件 */
                let _Products = item.CouponUsingDetailedRules.AppointProducts;
                let _productmoney =0;
                this.memberorderInfo.Order_ConsumeList.map(order=>{
                    if(_Products.indexOf(order.EAT_XFCode)!=-1){
                        _productmoney = Number(_productmoney) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                    }
                })
                if(ControlType==1){
                    if(_productmoney<item.CouponUsingDetailedRules.ConsumeMoney){
                        Error='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                    }
                }
                if(ControlType==2){
                    let _choiceNumber =1
                    if(this.choiceCoupons.length>0){
                        this.choiceCoupons.map(cho=>{
                            if(cho.Tk_AutoID == item.Tk_AutoID){
                                _choiceNumber = _choiceNumber+1
                                if(_productmoney < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    Error = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }else{
                                if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                                    Error = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }
                        })
                    }else{
                        if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                            Error = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                }
                
            }
            /**指定菜品适用规则 */
            if(item.CouponUsingDetailedRules.Products_ControlType==2){
                if(ControlType==1){
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            Error = item.Coupon_Name +'不适用此单里的菜品'
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                Error='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            Error = item.Coupon_Name +'不适用此单里的菜品'
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                Error='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            Error='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                        }
                    }
                }
                if(ControlType==2){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        Error=ErrorTitle;
                    }
                }
            }
            return Error;
        },
        CouponMoneyType(money,item,_ConsumeMoney){
            let _choiceNumber=1
            let ErrorType=0
            let ErrorTitle=''
            if(this.choiceCoupons.length>0){
                this.choiceCoupons.map(cho=>{
                    if(cho.Tk_AutoID == item.Tk_AutoID){
                        _choiceNumber = _choiceNumber+1
                        if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }else{
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                })
            }else{
                if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                    this.errCounc++
                    ErrorType=1
                    ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                }
            }
            let retError={ErrorType:ErrorType,ErrorTitle:ErrorTitle}
            return retError
        },      
        /**券选中事件 */
        CouponsSelect(item){
            if(item.Coupon_Kind==1){
                if(Number(item.Coupon_FaceValue)>Number(this.unPaidMoney)){
                    item.Coupon_FaceValue = Number(Number(this.unPaidMoney).toFixed(2))
                }
                this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue)
                if((Number(this.couponsPay)+Number(item.Coupon_FaceValue))>=Number(this.unPaidMoney)){
                    let _tempMoney = (Number(this.couponsPay)+Number(item.Coupon_FaceValue))-Number(this.unPaidMoney)
                    this.unPaidMoney = Number(Number(Number(this.unPaidMoney) - Number(item.Coupon_FaceValue)).toFixed(2))
                    this.couponsPay = Number(Number(this.couponsPay) - _tempMoney).toFixed(2)
                }else if((Number(this.couponsPay)+Number(item.Coupon_FaceValue))<Number(this.unPaidMoney)){
                    this.unPaidMoney = Number(Number(Number(this.unPaidMoney) - Number(item.Coupon_FaceValue)).toFixed(2))
                }
                // else{
                //     this.unPaidMoney = this.unPaidMoney - Number(item.Coupon_FaceValue)
                //     this.couponsPay = Number(Number(this.couponsPay) - Number(item.Coupon_FaceValue)).toFixed(2)
                // }
            }
            if(item.CouponUsingDetailedRules){
                if(item.CouponUsingDetailedRules.Is_AllowMixUsing==2){
                    if(this.couponsCount==0){
                        this.is_AllowMixUsing = true;
                        this.is_AllowMixUsingCoupon_Name = item.Coupon_Name;
                    }
                }
            }
            this.couponsCount++;
            item.Check = true;
            this.choiceCoupons.push(item);
            this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                order.CouponNumber=0
                this.choiceCoupons.forEach(cho=>{
                    if(order.Eat_ConsumeID==cho.Eat_ConsumeID){
                        if(order.CouponNumber<order.Eat_Number){
                            order.CouponNumber = order.CouponNumber+1
                            if(order.CouponNumber>=order.Eat_Number){
                                order.isCoupon=true
                            }
                        }else{
                            order.isCoupon=true
                        }
                    }
                })
            })
        },
        /**商品券判断 type:1 选中, 2取消选中 */
        commodityJudge(item,type){
            if(item.Coupon_Kind==2){
                /**商品券价值类型，0：按菜品金额抵扣 ，1：按固定金额抵扣 注：如果固定金额超过菜品金额则按菜品金额抵扣， 2：按折扣率抵扣 注：5折填写50 */
                if(item.DiscountType==0){
                    let _num=0
                    this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                        if(_num==0 && order?.isCoupon==false){
                            if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && item.Eat_ConsumeID==order.Eat_ConsumeID){
                                _num=1
                                if(type==1){
                                    if(order.CouponNumber>order.Eat_Number){
                                        let _num = 1-(order.CouponNumber - order.Eat_Number);
                                        item.Coupon_FaceValue =Number(Number(order.Eat_CheckPrice * _num).toFixed(2));
                                    }
                                    else if(this.unPaidMoney<order.Eat_CheckPrice){
                                        item.Coupon_FaceValue = this.unPaidMoney;
                                    }else{
                                        item.Coupon_FaceValue = Number(order.Eat_CheckPrice);
                                    }
                                    this.unPaidMoney = this.unPaidMoney - Number(item.Coupon_FaceValue) ;
                                    this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue);
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(item.Coupon_FaceValue)).toFixed(2);
                                }else{
                                    this.unPaidMoney = this.unPaidMoney + Number(item.Coupon_FaceValue) ;
                                    this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue);
                                    this.couponsPay = Number(Number(this.couponsPay) + Number(item.Coupon_FaceValue)).toFixed(2);
                                    item.Coupon_FaceValue = 0;
                                }
                                return
                            }
                        }
                    })
                }else if(item.DiscountType==1){
                    let _num=0
                    this.memberorderInfo.Order_ConsumeList.map(order=>{
                        if(_num==0 && order?.isCoupon==false){
                            if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1){
                                _num=1
                                /**如果固定金额超过菜品金额则按菜品金额抵扣 */
                                if(Number(item.DiscountValue)>order.Eat_CheckPrice){
                                    item.Coupon_FaceValue = order.Eat_CheckPrice
                                }else{
                                    item.Coupon_FaceValue = item.DiscountValue
                                }
                                if(type==1){
                                    this.unPaidMoney = this.unPaidMoney - Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue)
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(item.Coupon_FaceValue)).toFixed(2)
                                }else{
                                    this.unPaidMoney = this.unPaidMoney + Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue)
                                    this.couponsPay = Number(Number(this.couponsPay) + Number(item.Coupon_FaceValue)).toFixed(2)
                                }
                            }
                        }
                    })
                }else if(item.DiscountType==2){
                    let _num=0
                     this.memberorderInfo.Order_ConsumeList.map(order=>{
                         if(_num==0 && order?.Is_Discounted==true && order?.isCoupon==false){
                             if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && order.Eat_CheckPrice== order.Eat_SalePrice){
                                 _num=1
                                let money =  Number(order.Eat_CheckPrice) * (1-(item.DiscountValue / 100))
                                if(type==1){
                                    this.unPaidMoney = this.unPaidMoney - Number(money) 
                                    this.couponsMoney = Number(this.couponsMoney) + Number(money)
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(money)).toFixed(2)
                                    item.Coupon_FaceValue = Number(money)
                                }else{
                                    this.unPaidMoney = this.unPaidMoney +Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue) 
                                    this.couponsPay = Number(Number(this.couponsPay) + Number(item.Coupon_FaceValue)).toFixed(2)
                                    item.Coupon_FaceValue = 0
                                }
                            }
                         }
                        
                    })
                    return
                }
            }
        },
        /**商品券展示类型 */
        discountShow(item){
            if(item.DiscountType==0){
                return '完全抵用'
            }
            if(item.DiscountType==1){
                return '抵扣'+item.DiscountValue+'元'
            }
            if(item.DiscountType==2){
                return '抵扣'+ (100 - item.DiscountValue)+'%'
            }
        },
        /**积分点击 */
        onIntegralClick(){
            setTimeout(()=>{
                this.$refs.PointPayinput?.select();
            },100)
            /**是否允许会员支付 0不允许,1允许 */
            if(this.meberInfo){
                if(this.meberInfo.Is_Allowed_PointPay==0){
                    this.$alert('该卡暂不允许积分支付!', '提示', {
                            confirmButtonText: '确定',
                    });
                }
            }
        },
        hide(){
            if(this.PayResultCode!=1){
                this.$emit("memberReturn",this.memberorderInfo,this.meberInfo,1)
            }
        },
        cashConfirm(allNum){
            if(allNum>this.sameCoupon.length)
            {
                this.$message.error('数量不能大于相同券的总数量，当前相同券数量为'+this.sameCoupon.length);
                return;
            }
            if(this.selectSameCoupon.length>0)//是否存在已经选中的数据，先取消选中
            {
                let deleteSameCoupon=this.selectSameCoupon.slice()
                for (let i = 0; i < deleteSameCoupon.length; i++) {
                    if(deleteSameCoupon[i].Tk_AutoID==this.presentCoupon.Tk_AutoID
                    &&deleteSameCoupon[i].Begin_Date==this.presentCoupon.Begin_Date
                    &&deleteSameCoupon[i].End_Date==this.presentCoupon.End_Date)
                    {
                        deleteSameCoupon[i].Check=true;
                        this.oncashClick(deleteSameCoupon[i]);
                        //this.choiceCoupons = this.choiceCoupons.filter(t => t.Coupon_AutoID != this.selectSameCoupon[i].Coupon_AutoID)
                        //this.selectSameCoupon.splice(i,1);
                        //i--
                    }
                }
            }
            let conditionMet =true;
            let newsamecoupon=this.sameCoupon.slice();
            let A = 1;
            while(A <=allNum && conditionMet){
                conditionMet = false; // 每次循环开始时先将条件置为false
                for (let i = 0; i < newsamecoupon.length; i++) {
                    let _ConsumeMoney = newsamecoupon[i].CouponUsingDetailedRules.ConsumeMoney;
                    let _ConsumeMoney_ControlType = newsamecoupon[i].CouponUsingDetailedRules.ConsumeMoney_ControlType;
                    if(this.unPaidMoney<=0){
                        this.$message.error('最多可选中'+(A-1)+'张券，选中第'+A+'张券时失败,失败原因:付款金额已够,无需再选用多余的券');
                        this.numShow=false
                        this.$keyavail=false
                        return
                    }
                    
                    let item=newsamecoupon[i];
                    //商品券
                    if(item.Coupon_Kind==2){
                        let _coupTypes = 0
                        let _coupname=''
                        let _coupType=0
                        let Eat_Number=0
                        let Eat_ConsumeID=''
                        //如果是折扣券
                        if(item.DiscountType==2){
                            let isFindFood=0
                            this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                                if(isFindFood==0){
                                    if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1){
                                        if(order.isCoupon==false && order?.Is_Discounted==true){
                                            if(order.Eat_CheckPrice != order.Eat_SalePrice){
                                                _coupname= order.EAT_XFName+'价格已变动,无法使用折扣券'
                                                _coupType=1
                                            }else{
                                                _coupname=''
                                                _coupType=1
                                                isFindFood=1
                                            }
                                        }else{
                                            _coupname='订单里没有可折的菜品,无法使用折扣券'
                                            _coupType=1
                                        }
                                    }else{
                                        _coupname='订单里没有可折的菜品,无法使用折扣券'
                                        _coupType=1
                                    }
                                }
                            })
                        }
                        if(!_coupname){
                            //&& order.Eat_CheckPrice == order.Eat_SalePrice
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                let _types = 0
                                if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && order.Food_Type!=4){
                                    if(order.isCoupon==false){
                                        _coupTypes=1
                                        Eat_Number = Number(Eat_Number) + Number(order.Eat_Number)
                                        Eat_ConsumeID = order.Eat_ConsumeID
                                        item = Object.assign(item,{Eat_ConsumeID:Eat_ConsumeID})

                                        _types=1
                                    }else{
                                        _coupname='对应的菜品已享受优惠,无法选择多张'
                                        _coupType=1
                                    }
                                }
                                if(_types==1){
                                    return true
                                }
                            })
                        }
                        if(_coupType==1 && _coupTypes==0){
                            this.$message.error('最多可选中'+(A-1)+'张券，选中第'+A+'张券时失败,失败原因:'+_coupname);
                            this.numShow=false
                            this.$keyavail=false
                            return;
                        }
                    }
                    //判断金额控制ConsumeMoney_ControlType 0不限金额,1为账单满多少金额可用,2为每满多少金额后可用1张
                    let title=this.MultipleCouponProducts(newsamecoupon[i],_ConsumeMoney,_ConsumeMoney_ControlType,'');
                    if(title!=''){
                        this.$message.error('最多可选中'+(A-1)+'张券，选中第'+A+'张券时失败,失败原因:'+title);
                        this.numShow=false
                        this.$keyavail=false
                        return;
                    }
                    else{
                        this.commodityJudge(newsamecoupon[i],1)
                        this.CouponsSelect(newsamecoupon[i])
                        this.selectSameCoupon.push(newsamecoupon[i]);
                        newsamecoupon.splice(i,1);
                        i--;
                        conditionMet = true; // 标记找到符合条件的元素
                        break; // 找到符合条件的元素后立即跳出内部循环
                    }
                }
                A++; // 更新A的值
            }
            this.numShow=false
            this.$keyavail=false
        },
        numCloseModel(){
            this.numShow=false
            this.$keyavail=false
            this.presentCoupon=''
        },
        Rechargehide(){
            if(this.PayResultCode!=1){
                this.rechargeclass=0;
                this.RechargeType = 0;
                this.rechargeList?.forEach(it=>{
                    it.rechargeMoney=0
                });
                this.rechargeList[3].payConsumeList=[];
                this.Rechargeshow = false;
            }
        },
        /**确定付款 */
        confirm(){
            if(this.isActiveCodePay==false){
                if(!this.meberInfo){
                    this.$message.warning('请先读取会员卡');
                    return
                }
                if(Number(this.couponsPay)>0){
                    if(Number(this.couponsPay)>this.unPaidMoney.toFixed(2)){
                        this.$message.error('储值金额超过待支付金额');
                        return
                    }
                }
                if(Number(this.PointPay)>0){
                    if(this.meberInfo.Card_PilePoint<Number(this.PointPay)){
                        this.$message.error('您的积分不足');
                        return
                    }
                    if(this.PointPayMoney>this.unPaidMoney.toFixed(2)){
                        this.$message.error('积分可兑换金额超过待支付金额');
                        return
                    }
                }
                if(Number(this.couponsPay)>0 && this.PointPayMoney>0){
                    if((Number(this.couponsPay)+this.PointPayMoney)>this.unPaidMoney.toFixed(2)){
                        this.$message.error('储值金额和积分总额超出待支付金额');
                        return
                    }
                }
                if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                    this.$message.error('卡内余额不足,本次支付所需'+this.couponsPay+'，卡内余额：'+this.meberInfo.Card_Money);
                    return
                }
            }else{
                if(this.payCode==''){
                    this.$message.error('会员动态身份码不能为空');
                    return
                }
            }
            if(this.rechargeclass==2 && this.scanPayCode==''){
                this.rechargeMoney= this.paymentMoney;//剩余支付金额
                this.scanCodePay = true;
            }else{
                if(this.PayResultCode==0){
                    this.userInfo=this.$auth.getUserInfo(); //获取用户id
                    let _order = this.memberorderInfo
                    let _meberInfo=''
                    /** 预支付总金额 */
                    let _counMoney=0
                    /**支付方式 */
                    let _payment=[]
                    if(this.isActiveCodePay==true){
                        _meberInfo={
                            Member_Sys:2,
                            DynamicType:'', //查询码类型 5i会员类型：1手机会员、2卡会员
                            Card_AutoID:'', //卡主键
                            Card_No:this.payCode, //卡号
                            Member_Name:'', //姓名
                            MemberCard_Kind:'', //会员等级
                            Mobile:'',//会员手机号
                            NotJoinPointProgram:false,//不参与线上积分
                            Invoice_Money:0, //本次消费开票金额
                            Invoice_No:'',//发票号码
                            is_ActiveCodePay:true
                        }
                        _payment.push({
                            Payment_ID:'CardMoneyPay',  //支付方式ID
                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                            Payment_Money: Number(this.unPaidMoney), //支付金额
                            Payment_FactMoney:0, //计营业额
                            Qr_Code:'', //扫码付款的码值
                            Card_No:this.payCode, //卡号
                            CouponInfo:null, //券的详情
                        })
                        _counMoney = Number(this.unPaidMoney)
                    } else{  
                        let _meber = this.meberInfo
                        /**会员信息 */
                        _meberInfo ={
                            Member_Sys:2, //会员系统类型 2：正品CRM3.0，4：微生活
                            DynamicType:_meber.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                            Card_AutoID:_meber.Bestech_Card_AutoID, //卡主键
                            Card_No:_meber.Card_No, //卡号
                            Member_Name:_meber.MemberName, //姓名
                            MemberCard_Kind:_meber.Bestech_Card_KindName, //会员等级
                            Mobile:_meber.Mobile,//会员手机号
                            NotJoinPointProgram:this.PointChecked,//不参与线上积分
                            Invoice_Money:0, //本次消费开票金额
                            Invoice_No:'',//发票号码
                        }
                        //券支付方式
                        if(this.couponsMoney>0 && this.isActiveCodePay==false){
                            //订单未支付价格 + this.memberorderInfo.Mantissa_Money
                            let _paymoney = (this.memberorderInfo.UnPaid_Money)
                            //获取券的信息
                            this.choiceCoupons.map(cho=>{
                                if(Number(cho.Coupon_FaceValue)<_paymoney){
                                    _paymoney = _paymoney - Number(cho.Coupon_FaceValue)
                                }else{
                                    cho.Coupon_FaceValue = _paymoney
                                }
                                let _choice={
                                    Coupon_AutoId:cho.Coupon_AutoID,
                                    Coupon_Code:cho.Coupon_Code,
                                    Coupon_Name:cho.Coupon_Name,
                                    Coupon_Kind:cho.Coupon_Kind,
                                    Coupon_Num:1,
                                    Coupon_CostPrice:cho.Coupon_CostMoney
                                }
                                _payment.push({
                                    Payment_ID:'CardCouponsPay',  //支付方式ID
                                    Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                                    Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                                    Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                                    Payment_Money: Number(cho.Coupon_FaceValue), //支付金额
                                    Payment_FactMoney:0, //计营业额
                                    Qr_Code:'', //扫码付款的码值
                                    Card_No:_meber.Card_No, //卡号
                                    CouponInfo:_choice, //券的详情
                                })
                                _counMoney = _counMoney + Number(cho.Coupon_FaceValue)
                            })
                        }
                        //积分支付
                        if(this.PointPay>0 && this.isActiveCodePay==false){
                            _payment.push({
                                Payment_ID:'CardPointPay',  //支付方式ID
                                Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                                Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                                Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                                Payment_Money:this.PointPayMoney, //支付金额
                                Payment_FactMoney:0, //计营业额
                                Qr_Code:'', //扫码付款的码值
                                Card_No:_meber.Card_No, //卡号
                                CouponInfo:null, //券的详情
                            })
                            _counMoney = _counMoney + Number(this.PointPayMoney)
                        }
                        //储值
                        if(this.couponsPay>0 && this.isActiveCodePay==false){
                            _payment.push({
                                Payment_ID:'CardMoneyPay',  //支付方式ID
                                Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                                Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                                Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                                Payment_Money: Number(this.couponsPay), //支付金额
                                Payment_FactMoney:0, //计营业额
                                Qr_Code:'', //扫码付款的码值
                                Card_No:_meber.Card_No, //卡号
                                CouponInfo:null, //券的详情
                            })
                            _counMoney = _counMoney + Number(this.couponsPay)
                            //是否限量余额使用 0：否， 1：是
                            if(_meber.Is_Limit_BalancePay==1 && Number(this.couponsPay)>_meber.BalancePay_Limit){
                                this.$message.warning('储值支付金额不能大于储值限制金额');
                                return
                            }
                        }
                        //其他支付方式
                        if(this.RechargeType==1 && this.isActiveCodePay==false){
                            this.rechargeList.map(re=>{
                                if(re.rechargeMoney>0){
                                    if(re.rechargeName=='多卡支付'){
                                        _payment.push({
                                            Payment_ID:'CardMultiplePay',  //支付方式ID
                                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                                            Payment_Money: Number(re.rechargeMoney), //支付金额
                                            Payment_FactMoney:0, //计营业额
                                            Qr_Code:this.scanPayCode, //扫码付款的码值
                                            Card_No:_meber.Card_No, //卡号
                                            CouponInfo:null, //券的详情
                                        })
                                    }else{
                                        if(re.rechargeId==3){//其它支付
                                            re.payConsumeList.forEach(pay=>{
                                                _payment.push({
                                                    Payment_ID:pay.PAY_ID,  //支付方式ID
                                                    Payment_Code:pay.PAY_CODE, //支付方式编号
                                                    Payment_Name:pay.PAY_NAME, //支付方式名称
                                                    Payment_Type:pay.INTERFACE_TYPE, //支付方式类型
                                                    Payment_Money: Number(pay.payMoney), //支付金额
                                                    Payment_FactMoney:0, //计营业额
                                                    Qr_Code:this.scanPayCode, //扫码付款的码值
                                                    Card_No:_meber.Card_No, //卡号
                                                    CouponInfo:null, //券的详情
                                                })
                                            })
                                        }else{
                                            _payment.push({
                                                Payment_ID:re.Payment_ID,  //支付方式ID
                                                Payment_Code:re.Payment_Code, //支付方式编号
                                                Payment_Name:re.rechargeName, //支付方式名称
                                                Payment_Type:re.Payment_Type, //支付方式类型
                                                Payment_Money: Number(re.rechargeMoney), //支付金额
                                                Payment_FactMoney:0, //计营业额
                                                Qr_Code:this.scanPayCode, //扫码付款的码值
                                                Card_No:_meber.Card_No, //卡号
                                                CouponInfo:null, //券的详情
                                            })
                                        }
                                        
                                    }
                                    _counMoney = _counMoney + Number(re.rechargeMoney)
                                }
                            })
                        }
                    }
                    let param={
                        Body_ID:newGuid(),
                        User_ID:this.userInfo?.User_ID, //登陆用户ID
                        Eat_AutoID: _order.Eat_AutoID,//结账单ID
                        UnionOrders:this.UnionOrders,//联台订单ID集合
                        Timestamps:_order.Timestamps.toString(), //账单时间戳
                        Operator_Name:this.userInfo?.Login_Name,//操作员
                        Eat_DeskName:_order.Eat_DeskName, //桌牌号
                        Service_Charge:_order.Service_Money,  //服务费
                        MantissaAdjustment_Money:_order.Mantissa_Money,  //尾数调整金额
                        Member:_meberInfo,//会员信息
                        Payments:_payment, //支付方式列表
                    }
                    /**计算总金额,金额不够弹出充值通道 +this.memberorderInfo.Mantissa_Money */
                    if(Number(_counMoney.toFixed(2))<Number((this.memberorderInfo.UnPaid_Money).toFixed(2))){
                        if(this.RechargeType==1){
                            this.$message.error('金额不足');
                        }else{
                            this.Rechargeshow = true
                            this.errorTitle=''
                        }
                        return
                    }
                    /**支付预览 */
                    const loading = this.$loading({
                        text: "支付预览中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.1)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                        loading.close();
                        this.payPreviewData = data
                        this.$emit("unionTimeUpdate",data.ResponseBody)
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                            if(data.ResponseBody?.Timestamps){
                                this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                            }
                            if(this.isActiveCodePay==true){
                                this.payCode=''
                                this.$refs.payCode.focus();
                            }
                            //订单时间戳已变更需要去刷新订单时间,并且把锁解开
                            if(data.ResponseHeader.ResultCode==3002){
                                /**刷新订单数据 */
                                this.PayResultCode=0
                                this.$emit("refreshOrder")
                            }
                        }else{
                            if(data.ResponseBody?.OnlineDiscountMoney>0){
                                /**刷新订单数据 */
                                this.$emit("refreshOrder")
                            }
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                            this.memberorderInfo.Mantissa_Money = data.ResponseBody.MantissaAdjustMoney
                            this.tempUserId = data.ResponseBody?.User_ID
                            this.tempOperatorName = data.ResponseBody?.User_Name
                            /**保存新时间戳 */
                            if(data.ResponseBody?.UnionOrders){
                                this.UnionOrders=data.ResponseBody.UnionOrders
                            }
                            /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                            if(data.ResponseBody.Is_NeedSMSCode==1){
                                this.cardCodeShow = true
                                return
                            }
                            /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                            if(data.ResponseBody.Is_NeedPassWord==1){
                                this.passwordShow=true
                                return
                            }
                            /**支付提交判断 0不需要 1需要 */
                            if(data.ResponseBody.Is_NeedSubmit==0){
                                this.$message.success({ message:data.ResponseHeader.ResultDesc});
                                if(this.Is_NotRockover){//是否不结账
                                    this.$emit("memberReturn",this.memberorderInfo,this.meberInfo,2,data.ResponseBody);
                                }else{
                                    this.PerformingCheckOut(data);
                                    
                                }
                            }else{
                                if(this.scanCodePay==false){
                                    this.PaySubmit(data);
                                }
                            }
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }else if(this.PayResultCode==1){
                    this.PaySubmit(this.payPreviewData)
                }else{
                    this.scanCodePay = true;
                }
            }
        },
        /**支付提交方法 
         * @param remark 操作备注
        */
        PaySubmit(data,remark){
            if(data.ResponseBody.Is_NeedSubmit==1){
               let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                    Member_Type:data.ResponseBody.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码,密码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments,
                    Operator_Remark:remark//操作备注
                }
                if(this.tempUserId!='' && this.tempOperatorName!=''){
                    param.User_ID = this.tempUserId
                    param.Operator_Name = this.tempOperatorName
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.PaySubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseBody.Timestamps){
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                            this.payPreviewData.ResponseBody.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        //等待支付，需要执行查询支付结果的操作
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                            if(this.timerCount==0){
                                this.timerCount=60
                            }
                            this.timeoutPayment();
                        }
                        //聚合支付结果已确认失败
                        if(data.ResponseHeader.ResultCode==4003){
                            this.PayResultCode=0
                            this.scanPayCode=''
                            this.scanCodePay = true
                        }
                        //混合支付提交，部分支付金额已经支付成功，此时前端需要获取订单最新数据
                        if(data.ResponseHeader.ResultCode==4008){
                            /**刷新订单数据 */
                            this.PayResultCode=0
                            this.rechargeList.map(re=>{
                                re.rechargeMoney=0
                            })
                            this.$emit("refreshOrder")
                        }
                        //订单时间戳已变更需要去刷新订单时间,并且把锁解开
                        if(data.ResponseHeader.ResultCode==3002){
                            /**刷新订单数据 */
                            this.PayResultCode=0
                            this.$emit("refreshOrder")
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        if(!this.Is_NotRockover && data.ResponseBody.TryCheckOut){//是否可以结账
                            this.PerformingCheckOut(data)
                        }else{
                            this.$emit("memberReturn",this.memberorderInfo,this.meberInfo,2,data.ResponseBody);
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**结账方法 */
        PerformingCheckOut(data){
            let EatAutoIDList=[]
            let orderEatAutoList={Order_ID:this.memberorderInfo.Eat_AutoID,Timestamps:data.ResponseBody.Timestamps.toString(),Service_Charge:this.memberorderInfo.Service_Money}
            EatAutoIDList.push(orderEatAutoList)
            if(this.memberorderInfo.UnionOrderInfoList!=null){
                this.memberorderInfo.UnionOrderInfoList.map(union=>{
                    if(this.UnionOrders){
                        this.UnionOrders.map(preunion=>{
                            if(union.Eat_AutoID==preunion.Order_ID){
                                union.Timestamps = preunion.Timestamps.toString()
                            }
                        })
                    }
                    orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps.toString(),Service_Charge:union.Service_Money}
                    EatAutoIDList.push(orderEatAutoList)
                })
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID, //登陆用户ID
                CheckOutOrders:EatAutoIDList, //结账订单ID
                MantissaAdjust_Money:this.memberorderInfo.Mantissa_Money, //尾数调整金额
                Service_Charge:this.memberorderInfo.Service_Money,//服务费
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Print_YN:true, //是否返回结账单打印内容
                ElectronicInvoice_PrintForbidden:this.$global.invoiceOff,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
                PhaseID:this.$global.PhaseID,//指定时段ID
            }
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.tempOperatorName
            }
            const loading = this.$loading({
                text: "结账中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    if(data.ResponseBody?.Timestamps){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    }
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    //订单时间戳已变更需要去刷新订单时间,并且把锁解开
                    if(data.ResponseHeader.ResultCode==3002){
                        /**刷新订单数据 */
                        this.PayResultCode=0
                        this.$emit("refreshOrder")
                    }
                }else{
                    if(param.Print_YN==true){//返回结账单打印内容
                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                    }
                    if(data.ResponseBody.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    this.$message.success({ message: "结账成功"});
                }
                this.$emit("memberPayClick",data)
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**获取星期 */
        setNowTimes(){
            let myDate = new Date()
            let wk = myDate.getDay()
            let weeks = ['7', '1', '2', '3', '4', '5', '6']
            let week = weeks[wk]
            return week;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.couponsPay=''
            this.PointPay=''
            this.PointPayMoney=0
            this.PointPay_Scale=''
            this.choiceCoupons=[]
            this.cashCoupon=''
            this.commodityVoucher=''
            this.Rechargeshow=false
            this.payKindShow=false
            this.rechargeclass=0
            this.RechargeType=0
            this.is_AllowMixUsing=false
            this.is_AllowMixUsingCoupon_Name=''
            this.scanPayCode=''
            this.PayResultCode=0
            this.timerCount=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName=''
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.memberorderInfo.Order_ConsumeList.map(order=>{
                order.isCoupon=false,
                order.CouponNumber=0
            })
        },
        /**样式选择 */
        radioClick(item,index){
            if(this.PayResultCode==1){
                this.$message.error('有待支付的订单,暂时无法修改支付方式');
                return
            }
            if(this.PayResultCode!=1){
                this.rechargeclass = item.rechargeId
                this.rechargeList.forEach(re=>{
                    if(re.rechargeId!=item.rechargeId){
                        re.rechargeMoney=0
                    }
                })

                
                
                if(item.rechargeId==3){
                    this.otherPay = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney).toFixed(2);
                    this.payKindShow=true;
                    
                }else{
                    this.rechargeList[3].payConsumeList=[];
                    if(item.rechargeMoney==0 && item.rechargeMoney!=(this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney)){
                        item.rechargeMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney).toFixed(2);
                    }
                    this.$nextTick(()=>{
                        this.zfInput[index]?.focus();
                    })
                }

            }
        },
        /**聚合支付返回 */
        memberCodePayModel(payCode){
            this.scanCodePay = false
            this.scanPayCode=payCode
            this.PayResultCode=0
            this.create()
            this.timerCount=0
            this.confirm()
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.cardInfoShow()
            if(this.meberInfo.Is_Have_ParPrice==1){
                this.memberVipMoney(this.meberInfo)
            }
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            let _cashArr = [];  //定义一个数组
            let _commArr=[];
            if(this.meberInfo?.Coupons){
                this.meberInfo.Coupons.forEach(item=>{
                    if(item.Coupon_Kind==1){
                        if(item?.CouponOriMoney && item.CouponOriMoney!=item.Coupon_FaceValue){
                            item.Coupon_FaceValue = item.CouponOriMoney
                        }
                        _cashArr.push(Object.assign(item,{Check:false,CouponOriMoney:item.Coupon_FaceValue}))
                        this.cashCoupon=_cashArr;
                    }else{
                        _commArr.push(Object.assign(item,{Check:false}))
                        this.commodityVoucher=_commArr;
                    }
                    this.PointPay_Scale =  this.meberInfo.PointPay_Scale+':1'
                })
            }
            let _unPaidMoney =0
            if((this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)==0){
                _unPaidMoney = this.unPaidMoney.toFixed(2)
            }else{
                _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            }
            if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                this.couponsPay = _unPaidMoney
            }else{
                this.couponsPay = Number(this.meberInfo.Card_Money)
            }
        },
        /**验证码返回
         * @param code 验证码
         * @param lookSMSCodeRemarks 点击查看验证码 备注信息 支付提交用
         */
        cardCodeConfirm(code,lookSMSCodeRemarks){
            this.verifyCode=code;
            this.cardCodeShow = false;
            this.PaySubmit(this.payPreviewData,lookSMSCodeRemarks)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd
            this.passwordShow = false
            this.PaySubmit(this.payPreviewData)
        },
        PointPayCouponMoneyInfo(type){
            if(this.meberInfo){
                if(type==0){
                    this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                }
                if(type==1){
                    this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                }
                if(type==2){
                    if(Number(this.couponsPay)+Number(this.PointPayMoney)<this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                        if(this.isActiveCodePay==false){
                            this.couponsPay = Number(this.couponsPay)+Number(this.accSub(this.PointPayCouponMoney,this.unPaidMoney))
                        }
                    }else if(Number(this.couponsPay)+Number(this.PointPayMoney)==this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                    }else{
                        /**this.unPaidMoney - Number(this.PointPayMoney) */
                        this.couponsPay =this.accSub(Number(this.PointPayMoney),this.unPaidMoney) 
                        this.PointPayCouponMoney = this.unPaidMoney
                    }
                }
            }else{
                this.PointPayCouponMoney=0
            }
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return ((arg2*m-arg1*m)/m).toFixed(n);
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val2 - val1 || Times1 - Times2;
            }
        },
        /**定时查看结果 */
        timeoutPayment(){
            if(this._timePay) clearTimeout(this._timePay);
            this._timePay=setTimeout(()=>{
                if(this.PayResultCode!=0){
                    this.confirm();
                }
            },5000);
        },
        create(){
            if(this._timePay) clearTimeout(this._timePay);
        }
    }
}
</script>

<style lang="sass">
@import "./memberPaymentModel.scss";
</style>