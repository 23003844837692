<template>
    <div class="auxiliaryScreen">
        <div class="carousel-box">
            <el-carousel trigger="click" height="100%" :interval="loopInterval" arrow="never" v-if="imgList?.length>0" >
                <el-carousel-item v-for="item in imgList" :key="item"  >
                    <el-image :src="item" fit="fill" />
                </el-carousel-item>
            </el-carousel>
            <el-carousel trigger="click" height="100%" arrow="never" v-else>
                <div class="default-bg"><img src="/images/bgimg.png"/></div>
            </el-carousel>
            <!-- <div class="ppay-bg" v-if="isPPay"><img src="/images/ppay.jpg"></div> -->
        </div>
        <template v-if="showType==1">
            <div class="bill-box" v-if="info && isShowBill">
                <div class="panel-table">
                    <div class="panel-head">
                        <div class="tr">
                            <div class="td ">品项</div>
                            <div class="td num">数量</div>
                            <div class="td subtotal">小计</div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="scroll-box" v-if="bill?.length>0">
                            <div class="content-box" v-for="item in bill" :key="item">
                                <div class="tr">
                                    <div class="td ">
                                        <span class="tag tao" v-if="item.FoodProperty_Dimension">套</span>
                                        {{item.EAT_XFName}}
                                    </div>
                                    <div class="td num">x{{item.Eat_Number}}</div>
                                    <div class="td subtotal">¥{{Conversion((item.Eat_CheckPrice * item.Eat_Number))}}</div>
                                </div>
                                <div class="describe">
                                    <div class="nowrap flavor" >
                                        <span v-if="item.Eat_MasName && item.Eat_MasName!='/'" >
                                            口味:<span>{{item.Eat_MasName}}</span>
                                        </span>
                                    </div>
                                    <div class="tag-box">
                                        <!--赠,折-->
                                        <span class="tag give" v-if="item.Is_Gift">赠</span>
                                        <span class="tag give" v-if="item.is_discount">折</span>
                                        <!--厨房状态-->
                                        <span class="tag delimit" v-if="item.KitSpeed_Dimension" >{{item.KitSpeed_Dimension}}</span> 
                                        <!--退菜-->
                                        <span class="tag retreat" v-if="item.Refund_Number>0">退{{item.Refund_Number}}</span>
                                    </div>
                                    <!--折扣-->
                                    <div class="right-box" v-if="item.ConsumeOperations && item.ConsumeOperations[0]?.OperateType==1">
                                        -¥{{Conversion((item.Eat_SalePrice - item.Eat_CheckPrice) * item.Eat_Number)}}
                                    </div>
                                    <!--会员价-->
                                    <div class="right-box" v-else-if="item.ConsumeOperations && item.ConsumeOperations[0]?.OperateType==4">
                                        -¥{{Conversion((item.Eat_SalePrice - item.Eat_VipPrice) * item.Eat_Number)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="empty verticalCenter" v-else>暂无内容</div>
                    </div>
                    <div class="panel-footer">
                        <div class="td5">
                            <div class="total">共{{total.number}}份 合计：¥{{total.totalMoney}}</div>
                            <div class="discount" v-if="total.discountMoney>0">优惠：¥{{total.discountMoney}}</div>
                        </div>
                        <div class="td5 right-box">
                            应收：<span class="money">¥{{total.money}}</span>
                        </div>
                    </div>
                </div>
                <div class="pay-status" v-if="isShowStatus">
                    <div class="status-box">
                        <span class="iconfont icon-danxuan_fuxuan"></span>
                        <div class="status-text">支付成功</div>
                        <div class="content">
                            共计{{tempTotal.number}}份 总计<span class="money">¥{{tempTotal.totalMoney}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="showType==2">
            <div class="order-box" v-if="orderInfo">
                <div class="tr-box">
                    <div class="name">桌台:</div>
                    <div class="money">{{orderInfo.Eat_DeskName}}</div>
                </div>
                <div class="tr-box">
                    <div class="name">合计:</div>
                    <div class="money">¥{{orderInfo.Eat_SaleMoney}}</div>
                </div>
                <p v-show="orderInfo.discountMoney>0 || orderInfo.Service_Money>0">
                    其中<span v-show="orderInfo.discountMoney>0">优惠金额:¥{{orderInfo.discountMoney}}</span>
                    <span v-show="orderInfo.Service_Money>0">服务费:¥{{orderInfo.Service_Money}}</span>
                </p>
                <div class="tr-box red">
                    <div class="name">应付:</div>
                    <div class="money">¥{{orderInfo.Payable_Money}}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {NumberConversion} from '../../common'
export default {
    name:"auxiliaryScreen",
    data(){
        return {
            info:{},
            imgList:[],
            time:null,
            //显示支付状态
            isShowStatus:false,
            //是否显示 账单
            isShowBill:true,
            //轮播间隔默认 6秒
            loopInterval:6000,
            //账单数据
            bill:[],
            
            total:{
                totalMoney:0,//合计金额
                number:0,//多少份
                discountMoney:0,//优惠
                money:0//应收
            },
            //支付成功 临时合计
            tempTotal:{
                totalMoney:0,//合计金额
                number:0,//多少份
            },
            /**是否显示请付款 */
            isPPay:false,
            /**显示方式 类型 1 快餐 2中餐 */
            showType:0,
            //中餐订单数据
            orderInfo:null,
        }
    },
    watch:{
        info:{
            handler(newVal){
                //支付状态
                if(typeof newVal?.PayStatus =="boolean"){  
                    this.tempTotal.totalMoney= this.total.totalMoney;
                    this.tempTotal.number= this.total.number;        
                    this.showPayStatus(newVal?.PayStatus);
                }
                if(typeof newVal?.isPPay =="boolean"){  
                    this.isPPay= newVal?.isPPay;
                }
                if(typeof newVal.showType =='number'){//显示类型
                    this.showType=newVal.showType;
                }

                //账单
                if(!newVal || newVal?.bill || newVal?.bill===null){
                    this.bill=newVal?.bill;
                    if(newVal?.total){
                        this.total=newVal?.total;
                    }else{
                        this.total={
                            totalMoney:0,//合计金额
                            number:0,//多少份
                            discountMoney:0,//优惠
                            money:0//应收
                        };
                        this.isPPay= false;
                    }
                }

                if(newVal?.orderInfo!=undefined){//中餐 订单数据
                    this.orderInfo=newVal?.orderInfo;
                }
                //配置信息
                if(!newVal || newVal?.PosGuestScreen || newVal?.PosGuestScreen===null){
                    this.setPosGuestScreen(newVal?.PosGuestScreen);
                }
            },
            immediate:true,
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.info=this.$cacheData.get("auxiliaryScreenData");
            //监听数据
            this.$webBrowser.on("auxiliaryScreenData",(d)=>{
                this.info=d;
            })
        })
    },
    methods:{
        //配置信息 信息
        setPosGuestScreen(d){
            if(d){
                this.imgList=d.PosGuestScreen_LoopImages||[];
                this.isShowBill= !d.PosGuestScreenNotShowingBillDetail;//是否显示账单
                let loop = d.PosGuestScreen_LoopInterval>0?d.PosGuestScreen_LoopInterval:6;
                this.loopInterval=loop*1000;
            }else{//默认配置信息
                this.imgList=[];
                this.isShowBill=false;
                this.loopInterval=6000;
                this.loadDeskPosconfigure();
            }
        },
        /**读取配置数据 */
        loadDeskPosconfigure(){
            this.$cacheData.DeskPosconfigure().then((d)=>{
                if(d){
                    this.imgList=d.PosGuestScreen_LoopImages||[];
                    this.isShowBill= !d.PosGuestScreenNotShowingBillDetail;//是否显示账单
                    if(d.PosGuestScreen_LoopInterval>0){
                        this.loopInterval =d.PosGuestScreen_LoopInterval*1000;
                    }
                    
                }
            }).catch(e=>{
                console.log("基本配置获取失败："+e.message);
            })
        },
        //显示支付状态
        showPayStatus(){
            this.isShowStatus=true;
            if(this.time){
                clearTimeout(this.time);
            }
            this.time=setTimeout(()=>{
                this.isShowStatus=false;
            },3000);
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
    },
    /**卸载 */
    unmounted(){
        this.$webBrowser.off("auxiliaryScreenData");
    },
}
</script>

<style lang="scss">
	@import "./auxiliaryScreen.scss";
</style>