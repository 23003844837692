<template>
    <el-date-picker class="datePicker from-date"
            v-model="businessHours"
            :type="type"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            :default-time="defaultTime"
            :editable="false"
            @change="change"
            :disabled='disabled'
            :disabled-date="disabledDate"
        > </el-date-picker>
</template>

<script>
import { Component} from 'vue'
import { keys } from 'localforage';
export default {
    name:"datePicker",
    emits:['update:modelValue','change'],
    props:{
        modelValue: {
            type: [Array, Date],
            default: null
        },
        type:{
            type:String,
            default:"daterange",
        },
        startPlaceholder:{
            type:String,
            default:"开始日期",
        },
        endPlaceholder:{
            type:String,
            default:"结束日期",
        },
        defaultTime:{ },
        disabled:{
            type:Boolean,
            default:false
        },
        deffDay:{
            type:Number,
            default:0
        }
    },
    watch:{
        modelValue:{
            handler(newVal){
                this.businessHours=newVal;
            },
            immediate:true,
        },
        businessHours(newVal){
            this.$emit('update:modelValue', newVal);
        }
    },
    data(){
        return {
            businessHours:[],
            pick:null
        }
    },
    mounted(){
        this.$nextTick(()=>{
            let id= this.$el.nextElementSibling.getAttribute("ariadescribedby");
            let dateTables= document.querySelectorAll("#"+id+" .el-picker-panel .el-date-table");
            Array.from(dateTables).forEach((table)=>{
                table.children[0].addEventListener("click",(e)=>{
                    let target=e.target;
                    if(target.nodeName.toLocaleLowerCase()=="span"){
                        target=target.parentElement.parentElement;
                    }else if(target.nodeName.toLocaleLowerCase()=="div" && target.className==""){
                        target=target.parentElement;
                    }
                    console.log(target,target.classList)
                    if(target.classList.contains("available")){
                        if((!this.pick || (this.pick.minDate && this.pick.maxDate))){
                            let header=table.parentElement.querySelector(".el-date-range-picker__header").textContent;
                            let yearMonth=header.replace(/[^\d ]/g,"").trim().replace(/[ ]+/g," ").replace(" ","-");
                            this.pick={
                                minDate:new Date(yearMonth+"-"+target.textContent)
                            };
                        }else{
                            this.pick=null;
                        }
                    }
                })
            })
        })
    },
    methods:{
        disabledDate(d){
            if(this.deffDay>0 && this.pick){
                let minData=this.pick?.minDate;
                let maxDate=this.pick?.maxDate;
                if(!maxDate){
                    const oneDay =  24 * 3600 * 1000;
                    const day = this.deffDay * oneDay;

                    if(minData.getTime()-day>=d.getTime() || minData.getTime()+day-oneDay<d.getTime()){
                        return true;
                    }
                }
            }
            return false;
        },
        change(d){
            this.pick=null;
            this.$emit("change",d)
        }
    }
}
</script>

<style>

</style>