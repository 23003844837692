<template>
  <modal class="changeNumPriceModel" :isShow="isShow" @keyup.esc="hide">
      <div class="header">{{ isPrice?'改价操作':'改量操作' }} </div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品名称：</div>
                        <div class="from-input" style="width:310px"><input type="text" :value="disRadioOrder.EAT_XFName" readonly /></div>
                    </div>
                    <div class="tr-li" v-if="isPrice">
                        <div class="lable-txt">菜品原价：</div>
                        <div class="from-input" style="width:115px"><input type="text" :value="disRadioOrder.Eat_SalePrice" readonly /></div>
                        <div class="lable-txt">改后单价：</div>
                        <div class="from-input" style="width:115px"><input type="text" class="key-focus"  ref="priceInput" data-model='price' @focus="$event.target.select()"  v-model="price" onkeyup="value=this.value.replace(/\D+.\D+/g,'')" /></div>
                        <div class="title">选择改价原因</div>
                        <div class="reason-box" v-mouse-scroll v-if="isPrice==true">
                            <div class="reason-tag" :class="{selected:item.OperateReason_AutoID==reasontype}" v-for="(item,index) in RefundReasonsList" :key="index" @click="clickReason(item)" >{{item.OperateReason_Name}}</div>
                        </div>
                        <div class="reason-box" v-mouse-scroll v-else>
                            <div class="reason-tag" :class="{selected:item.OperateReason_AutoID==reasontype}" v-for="(item,index) in RefundReasonsList" v-show="item.OperateReason_Kind==1" :key="index" @click="clickReason(item)" >{{item.OperateReason_Name}}</div>
                        </div>
                    </div>
                    <div class="tr-li" v-else>
                        <div class="lable-txt">点单数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" :value="disRadioOrder.Eat_Number" readonly/></div>
                        <div class="lable-txt">改后数量：</div>
                        <div class="from-input" style="width:115px"><input type="text"  class="key-focus" ref="numInput" data-model='num' @focus="$event.target.select()"  @input="changeRate($event,num)" v-model="num"/></div>
                    </div>
                    <div class="title" v-show="disRadioOrder.Eat_AssSize!='' && isPrice==false">辅助单位:{{disRadioOrder.Eat_AssSize}}</div>
                    <div class="tr-li" v-show="disRadioOrder.Eat_AssSize!='' && isPrice==false">
                        <div class="lable-txt">辅助数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" :value="disRadioOrder.Eat_AssNumber" readonly/></div>
                        <div class="lable-txt">改后数量：</div>
                        <div class="from-input" style="width:115px"><input type="text"  class="key-focus" ref="assSizeNumber" data-model='assSizeNumber' @focus="$event.target.select()" @input="changeRate($event,number)" v-model="assSizeNumber"/></div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'changeNumPriceModel',
    props:{
        isShow:Boolean,
        isCheckOut:Boolean,
        isPrice:{//是否改价  默认是改量
            type:Boolean,
            default:false
        },
        /**点单数量 */
        orderNum:Number,
       disRadioOrder:Object,
       orderInfo:Object,
       unionType:Boolean
    },
    data(){
        return{
            /**改后数量 */
            num:'',
            /**改后单价 */
            price:'',
            /**改后辅助数量 */
            assSizeNumber:'',
            /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            reasontype:0,
            reasontContent:'',
            /**考虑到联台情况获取对应时间戳 */
            Timestamps:'',
        }
    },
    watch: {
        isShow() { 
            this.num=''
            this.price=''
            this.assSizeNumber=''
            this.reasontype=0;
            this.reasontContent='';
            this.EmptyDatas();
            if(this.isShow==true){
                this.Time()
            }
            if(this.isPrice){
                this.OperateReason();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            this.Time()
            this.EmptyDatas();
            if(this.isPrice){
                this.OperateReason();
            }
        })
    },
    methods:{
        changeRate(e,input){
            e.target.value=(e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
            this.input=e.target.value
        },
        /**操作原因*/
        OperateReason(){
            this.$cacheData.OperateReasons().then((d)=>{
                this.RefundReasonsList = d;
                console.log('改价原因:'+JSON.stringify(d))
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        EmptyDatas(){
            if(this.isPrice){
                this.price=this.disRadioOrder?.Eat_SalePrice;
                setTimeout(() => {
                    this.$refs.priceInput.focus();
                },100);
            }else{
                if(this.disRadioOrder.Eat_AssSize!='' && this.isPrice==false){
                    this.assSizeNumber=this.disRadioOrder?.Eat_AssNumber;
                }else{
                    this.assSizeNumber=0
                }
                setTimeout(() => {
                    this.$refs.numInput.focus();
                },100);
            }
        },
        /**原因 */
        clickReason(item){
            if(this.isPrice==true){
                this.reasontype = item.OperateReason_AutoID;
                this.reasontContent = item.OperateReason_Name;
            }else{
                this.reasontype = item.OperateReason_AutoID;
                this.reasontContent = item.OperateReason_Name;
            }
        },
        /**确认 */
        confirm(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            /**参数 */
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_ConsumeID:this.disRadioOrder.Eat_ConsumeID, //菜品明细ID
                Eat_SalePrice:this.price, //菜品单价
                IsReturn_CheckOutInfo:true,//是否返回结账信息
                Is_UnionOrder:true,  //是否获取连台信息
                Timestamps:this.Timestamps
            }
            if(this.isPrice){
                if(this.price==0){
                     this.$alert('改价金额不能为0', '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.$refs.priceInput.focus();
                        }
                    });
                    return;
                }
                if(this.price.toString().replace(/^\d+(\.\d+)?$/,'')){
                    this.$alert('请输入正确修改价格!', '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.$refs.priceInput.focus();
                        },
                    });
                    return;
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                if(this.reasontContent){
                    param = Object.assign(param,{Reason:this.reasontContent})
                }
                if(this.reasontContent==''){
                    this.$alert('改价原因不能为空!', '提示', {
                        confirmButtonText: '确定',
                    });
                    return;
                }
                /**改价 */
                const loading = this.$loading({
                    text: "菜品修改中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.ChangeFoodPrice",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        ElMessage.success({ message: "改价成功"});
                        this.$emit("changepriceClick",Number(this.price).toFixed(2),data)
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else{
                let nums = /^\d+(\.\d{1,4})?$/;
                if(this.disRadioOrder.Eat_AssSize){
                    if(Number(this.assSizeNumber)<=0){
                        this.$alert('辅助数量不能为0!', '提示', {
                            confirmButtonText: '确定',
                        });
                        return;
                    }
                }
                if(Number(this.num)>=100000000 || Number(this.assSizeNumber)>=100000000){
                    this.$alert('超过数量修改上限!', '提示', {
                        confirmButtonText: '确定',
                    });
                    return;
                }
                if (!nums.test(this.assSizeNumber)){
                    this.assSizeNumber = Number(this.assSizeNumber).toFixed(2)
                }
                if (!nums.test(this.num)){
                    this.num = Number(this.num).toFixed(2)
                }
                /**改量 */
                param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID,
                    Operator_Name:this.userInfo?.Login_Name,
                    Eat_ConsumeID:this.disRadioOrder.Eat_ConsumeID, //菜品明细ID
                    Eat_Number: Number(this.num).toFixed(2), //菜品数量
                    IsReturn_CheckOutInfo:true, //是否返回结账信息
                    Timestamps:this.Timestamps,
                    Is_UnionOrder:true,  //是否获取连台信息
                    Eat_AssNumber:this.assSizeNumber,
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                const loading = this.$loading({
                    text: "菜品修改中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.ChangeFoodNum",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        ElMessage.success({ message: "改量成功"});
                        this.$emit("changenumClick",this.num,data,this.assSizeNumber)
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**取消 */
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**获取订单时间戳 */
        Time(){
            this.Timestamps="";
            if(this.orderInfo?.Timestamps){
                this.Timestamps=this.orderInfo.Timestamps.toString();
            }
           
            
            if(this.orderInfo.UnionOrderInfoList){
                this.orderInfo.UnionOrderInfoList.map(union=>{
                    union.Order_ConsumeList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            this.Timestamps = union.Timestamps.toString()
                        }
                    })
                })
            }
        },
    }
}
</script>

<style lang="sass">
@import './changeNumPriceModel.scss';
</style>