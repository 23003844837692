export default {
    install(app){
        app.directive('dom-resize',{
            mounted(el){//绑定元素的父组件被挂载时调用
                if(el.style.overflow!="hidden"){
                    el._oldOverflow=el.style.overflow;
                    el.style.overflow="hidden";
                }
                el.resize=()=>{
                    let height=el.offsetHeight,width=el.offsetWidth;
                    if(height!=el._oldoffsetHeight || width!=el._oldoffsetWidth){
                        el._oldoffsetHeight=height;
                        el._oldoffsetWidth=width;
                        el.dispatchEvent(new Event("resize",{}));
                    }
                }
                // window.addEventListener('resize',el.resize);
                el.__ro__= new ResizeObserver(()=>{
                    el.resize();
                });
                el.__ro__.observe(el);
            },
            updated(el,binding){//在包含组件的 VNode 及其子组件的 VNode 更新之后调用
                binding.instance.$nextTick(()=>{
                    el.resize();
                })
            },
            unmounted(el) {
                //window.removeEventListener('resize',el.resize);
                el.__ro__.unobserve(el);
            }
        })
    }
}
