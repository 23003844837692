<template>
    <div class="weighing" v-if="isEnableScale">
        <div class="tr-li">
            <div class="lable-txt">净重({{unit}}):</div>
            <div class="content number">{{Eat_NumberFixed}}</div>
        </div>
        <div class="tr-li">
            <div class="lable-txt">皮重({{unit}}):</div>
            <div class="content" >{{peelNumFixed}}</div>
        </div>
        <div class="bnt" @click="setPeel()" v-if="this.peelNum==0">去皮</div>
        <div class="bnt" @click="cleanPeel()" v-else>置零</div>
    </div>
</template>

<script>
export default {
    name:"weighing",
    emits:['update:modelValue'],
    props:{
        modelValue: {
            type: [String, Number],
            default: ''
        },
    },
    computed:{
        unit(){
            return this.isTranJin?"斤":"KG";
        },
        Eat_NumberFixed(){
            return this.Eat_Number.toFixed(3).replace(/0$/g,"");
        },
        peelNumFixed(){
            return this.peelNum.toFixed(3).replace(/0$/g,"");
        }
    },
    watch:{
        modelValue(newVal){
            this.Eat_Number=newVal;
        },
        Eat_Number(newVal){
            this.$emit('update:modelValue', newVal);
        },
        //称重重量
        weight(newVal){
            //菜品重量=重量-去皮重量 
            this.Eat_Number=parseFloat((newVal-this.peelNum).toFixed(3));
        },
        peelNum(){
            //菜品重量=重量-去皮重量 
            this.Eat_Number=parseFloat((this.weight-this.peelNum).toFixed(3));
        }
    },
    data(){
        return {
            //皮重
            peelNum:0,
            //净重
            Eat_Number:0,
            //重量
            weight:0,
            /**基础配置信息 */
            /**电子秤品牌*/
            brand:2,//1 中科英泰s343  2 海石Histone-HT526R
            port:1,
            baud:9600,
            //启用将电子秤称重数转为市斤
            isTranJin:false,
            //是否启用电子秤功能
            isEnableScale:false,
        }
    },
    mounted(){
        //皮重
        this.peelNum=this.$cacheData.get("peelNum")||0;
        this.$webBrowser.on("posScale",(data)=>{
            console.log("称重 posScale:",data)
            if(data){
                var num=parseFloat((parseFloat(data.weight)||0).toFixed(3));
                if(this.isTranJin){//公斤转市斤
                    this.weight=num*2;
                }else{
                    this.weight=num;
                }
            }
        })
        if(this.$webBrowser.isMobile.Windows){
            this.brand=1;//pc  默认 中科英泰s343
        }
        this.$webBrowser.getElectronicScale().then(d=>{//获取电子秤配置信息
            if(d){
                this.isEnableScale=d.isEnableScale==true;
                this.brand=d.brand||this.brand;
                this.port=d.port||1,
                this.baud=parseInt(d.baud)||9600,
                this.isTranJin=d.isTranJin==true;
                //开启电子秤
                this.openPosScale();
            }
        });
        
    },
    methods:{
        //去皮
        setPeel(){
            this.$confirm("您确认将"+this.weight+this.unit+"去皮吗", "提示", {
                confirmButtonText: "确认",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.peelNum=this.weight;
                        this.$cacheData.set("peelNum",this.peelNum);
                    }
                }
            });
        },
        //置零
        cleanPeel(){
            this.$confirm("您确认将去皮置零吗？", "提示", {
                confirmButtonText: "确认",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.peelNum=0;
                        this.$cacheData.set("peelNum",0);
                    }
                }
            });
        },
        /**打开电子秤  */
        openPosScale(){
            if(this.isEnableScale){
                this.$webBrowser.openPosScale(this.brand,this.port,this.baud).then((d)=>{
                    console.log("打开电子秤:",d)
                    if(d && d.state==0){
                    // this.isOpenWeight=true;
                    }else{
                        this.$message.warning('电子秤启动失败：'+(d?.message||""));
                    }
                })
            }
        },
    },
    /**卸载 */
    unmounted(){
        //关闭电子秤
        this.$webBrowser.closePosScale();
        this.$webBrowser.off("posScale");
    },
}
</script>

<style lang="scss">
.weighing{
    display: flex;
    background-color: #fff;
    z-index: 3;
    position: relative;

    .tr-li{
        flex: 1;
        border-radius: 4px;
        margin-right: 3px;
        height: 35px;
        background-color: #000;
        display: flex;
        align-items:center;
        padding: 0 5px;
        overflow: hidden;
        display: flex;

        .lable-txt{
            font-size: 11px;
            color: #fff;
        }

        .content{
            flex: 1;
            font-size: 16px;
            color: #fff;
            text-align:right;
        }

        .number{
            color: #f1e659;
            font-size: 24px;
        }

        
    }

    .bnt{
        font-size: 16px;
        color: #FFFFFF;
        background: #487BB1;
        border-radius: 4px;
        width: 45px;
        text-align: center;
        height: 35px;
        line-height: 35px;
    }
}
</style>