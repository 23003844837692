<template>
    <modal class="nultiUnitModel" :isShow="isShow">
        <div class="header">
            选规格
            <div class="close-bnt" @click="hide()"><i class="iconfont icon-guanbi"></i></div>
        </div>
        <div class="modal-body" v-if="data">
            <div class="unit-box">
                <div class="picture">
                    <el-image :src="data?.Food_SmallImage" fit="fill">
                        <template #placeholder>
                            <div class="image-slot">Loading<span class="dot">...</span></div>
                        </template>
                        <template #error>
                            <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png'}"></div>
                        </template>
                    </el-image>
                    <div class="soldout"><img src="/images/soldout.png"></div>
                </div>
                <div class="unit-flex">
                    <div class="displayFlex">
                        <div class="name nowrap2">{{unitInfo?.Food_Name}}</div>
                        <div class="price-box">
                            <div class="price" :class="{lineThrough:unitInfo?.Is_Promotion}"><span class="unit">￥</span>{{unitInfo?.Food_Price}}<span class="unit">/{{unitInfo?.Food_Size}}</span></div>
                            <div class="proMoney" v-if="unitInfo?.Is_Promotion">
                                <span class="tag">促</span>
                                <span class="unit">￥</span>
                                <span>{{unitInfo?.Promotion_Price}}</span>
                            </div>
                            <!-- vip 价格 -->
                            <div class="vipPrice" v-else-if="unitInfo?.Food_VipPrice>0 && unitInfo?.Food_VipPrice!=unitInfo?.Food_Price">
                                <div class="vipTag">VIP</div>
                                <div class="price"> 
                                    <div class="unit">￥</div>{{unitInfo?.Food_VipPrice}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bnt-box">
                        <div class="remove" @click="reduce()" :class="{disabled:!(number>1)}" v-if="number>0"><i class="iconfont icon-jian"></i></div>
                        <div class="number" v-if="number>0">{{number||0}}</div>
                        <div class="add" @click="add()"><i class="iconfont icon-jia"></i></div>
                    </div>
                </div>
            </div>
            <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                <div class="item-box">
                    <div class="tr-li" :class="{selected:unitInfo?.Food_ID==item.Food_ID}" v-for="item in multipleList" :key="item" @click="unitInfo=item;">
                        <div class="price"><span class="unit">￥</span>{{item.Food_Price}}</div>
                        <div class="tit-unit">{{item.Food_Size}}</div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="footer-box">
            <div class="btn btn-cancel" @click="hide()">取消</div>
            <div class="btn btn-confirm" @click="confirm()">选好了</div>
        </div>
    </modal>
</template>

<script>

/**多规格 */
export default {
    name:"ipdSetMealModel",
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            default:null,
            type:Object
        },
    },
    data(){
        return {
            //菜品数据
            info:null,
            number:1,
            //选中的规格
            unitInfo:null
        }
    },
    computed:{
        multipleList(){
            let multipList=[];
            if(this.data){
                ////是否padmenu点菜不显示 true: 不显示， false:显示
                this.data.MultipleSizeFoods?.filter(it=>!it.Is_ForbidShowInMenuPad)?.forEach(item=>{
                    Object.assign(item,{
                        MultipleSizeParent:this.data.Food_ID,
                        RemainNum:this.data.RemainNum,
                        Is_Limited:this.data.Is_Limited,
                        Is_BindEatMas:this.data.Is_BindEatMas,
                        FoodEatMasList:this.data.FoodEatMasList,
                        FoodMasGroups:this.data.FoodMasGroups,
                    })
                    multipList.push(item);
                })

                multipList.push({
                    Food_ID:this.data.Food_ID,
                    Food_Code:this.data.Food_Code,
                    Food_Name:this.data.Food_Name,
                    Food_EnglishName:this.data.Food_EnglishName,
                    SubCategory_ID:this.data.SubCategory_ID,
                    Food_Property:this.data.Food_Property,
                    Food_PinYin:this.data.Food_PinYin,
                    Food_Size:this.data.Food_Size,
                    Food_Price:this.data.Food_Price,
                    Food_VipPrice:this.data.Food_VipPrice,
                    Food_CostPrice:this.data.Food_CostPrice,
                    Is_SoldOut:this.data.Is_SoldOut,
                    Is_Discounted:this.data.Is_Discounted,
                    Is_Seasonal:this.data.Is_Seasonal,
                    Is_Limited:this.data.Is_Limited,
                    RemainNum:this.data.RemainNum,
                    Is_ChangedNum:this.data.Is_ChangedNum,
                    Is_ChargeForService:this.data.Is_ChargeForService,
                    Is_SetMeal:this.data.Is_SetMeal,
                    Is_Special:this.data.Is_Special,
                    KitPlace_ID:this.data.KitPlace_ID,
                    Kit_Level:this.data.Kit_Level,
                    Food_AssSize:this.data.Food_AssSize,
                    Is_BindEatMas:this.data.Is_BindEatMas,
                    FoodEatMasList:this.data.FoodEatMasList,
                    Promotion_Price:this.data?.Promotion_Price,
                    Is_Promotion:this.data?.Is_Promotion,
                    FoodMasGroups:this.data.FoodMasGroups,
                    MultipleSizeParent:null
                })
            }
            
            return multipList;
        }
    },
    watch:{
        isShow(){
            if(this.isShow==true){
               this.init();
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        init(){
            this.number=1;
            this.unitInfo=null;
            if(this.multipleList?.length>0){
                this.unitInfo=this.multipleList[0];
            }
        },
        hide(){
            this.$emit("close")
        },
        confirm(){
            if(!this.unitInfo){
                this.$message.error('请先选择菜品分量!');
                return;
            }
            if(!this.number>0){
                this.$message.error('请最少添加一份菜品!');
                return;
            }
            this.$emit("confirm",this.unitInfo,this.number);
        },
        /**数量增加 */
        add(){
            this.number++;
        },
        /**数量减少 */
        reduce(){
            this.number--;
            if(this.number<1){
                this.number=1;
            }
        },
    }
}
</script>

<style lang="scss">
    @import './nultiUnitModel.scss'
</style>