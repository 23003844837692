<template>
    <modal class="readyKitchenModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">厨房操作</div>
        <div class="modal-body">
            <div class="filter-nav">
                <div class="nav-box">
                    <tab-nav class="nav-li " :class="{selected:foodSubIndex==-1}" style="position: relative;" @click="onClickAll()" >全部小类</tab-nav>
                </div>
                <div class="nav-box" style="position: relative;top: -45px;left: 90px;width: 80%;" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'navPage'}">
                    <div class="nav-li" :class="{selected:item.SubCategory_ID==foodSubIndex}" v-for="item in tempfoodSub" :key="item" @click="onClickAll(item)">{{item.SubCategory_Name}}</div>
                </div>
                <div class="float-right page-box">
                    <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up"></span>
                    <span class="page-number">{{navPage.pageIndex}}/{{navPage.pageSize}} 页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down"></span>
                </div>
            </div>
            <div class="clearfix">
                <div class="left-box">
                    <div class="item-box" v-if="temporderMenuList"  v-scroll-anime="{up:'item-up2',down:'item-down2',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                        <div class="item-li" :class="{selected:item.is_type==1}" v-for="item in temporderMenuList" :key="item" v-show="(item.SubCategory_ID==foodSubIndex || foodSubIndex==-1) && item.Food_Status=='-1'" @click="onDescClick(item)" >
                        <p class="title">{{item.EAT_XFName}}</p>
                        <div class="describe " :class="{selected:item.is_type==1}" :title="hoverShow(item.Eat_MasName)" >
                            <span>{{item.Food_Speed}}</span>
                            <span v-if="item.Eat_MasName">口味：{{item.Eat_MasName}}</span>
                            <span v-else></span>
                        </div>  
                        <i class="iconfont icon-gou-fine"></i>
                        </div>
                    </div>
                    <div class="item-operation">
                        <label class="checkbox allBox"><input type="checkbox" @change="onDeskSelectAll()" :checked="isSelectAll" /><i class="iconfont icon-iconoption"></i>全选</label>
                        <div class="btn-box">
                            <button class="btn" :class="{selected:isFoodSpeed=='等叫'}" @click="onFoodSpeed('等叫',1)">等叫</button>
                            <button class="btn" :class="{selected:isFoodSpeed=='加急'}" @click="onFoodSpeed('加急',1)">加急</button>
                            <button class="btn" :class="{selected:isFoodSpeed=='先上'}" @click="onFoodSpeed('先上',1)">先上</button>
                            <button class="btn" :class="{selected:isFoodSpeed=='后上'}" @click="onFoodSpeed('后上',1)">后上</button>
                        </div>
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up2"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down2"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                    <div class="tip-box">
                        <!--提示：此处显示提示文本，没有提示则不显示-->
                    </div>
                </div>
                <div class="right-box">
                    <button class="btn" @click="onFoodSpeed('等叫',2)">全单等叫</button>
                    <button class="btn" @click="onFoodSpeed('加急',2)">全单加急</button>
                    <button class="btn" @click="onFoodSpeed('即',2)">全单即起</button>
                    <button class="btn" @click="onFoodSpeed('即',2)">取消为即 </button>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

export default {
    name:'readyKitchenModel',
    data(){
        return {
            navPage:{pageIndex:0,pageSize:0},
            /**小类菜品 */
            tempfoodSub:[],
            foodSubIndex:-1,
            /**临时菜品 */
            temporderMenuList:[],
            /**是否全选 */
            isSelectAll:false,
            /**选择的菜品速度 */
            isFoodSpeed:'',
            targorderMenuList:'',
        }
    },
    props:{
        isShow:Boolean,
        orderMenuList:Object,
        allfoodSubCategorys:Object,
        readyorderMenuList:Object
    },
    mounted(){
        this.$nextTick(()=> {
           this.openimp()
        })
    },
    watch:{
        /**监控，打开厨房页面 */
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        }
    },
    methods:{
        /**打开执行 */
        openimp(){
            this.isFoodSpeed=''
            this.temporderMenuList=[],
            this.tempfoodSub=[],
            this.targorderMenuList = JSON.parse(JSON.stringify(this.readyorderMenuList));
            this.targorderMenuList.map(item=>{
                this.allfoodSubCategorys.map(sub=>{
                    if(sub.SubCategory_ID==item.SubCategory_ID && item.Food_Status==-1){
                        this.tempfoodSub.push(sub);
                    }
                })
                let _is_type=0;
                if(item.FoodSetmeals){
                    let food={
                        SubCategory_ID:item.SubCategory_ID,
                        EAT_XFBMID:item.EAT_XFBMID,
                        EAT_XFCode:item.EAT_XFCode,
                        EAT_XFName:item.EAT_XFName,
                        Eat_Number:item.Eat_Number,
                        Eat_CheckPrice:item.Eat_CheckPrice,
                        Eat_SalePrice:item.Eat_SalePrice,
                        Eat_MasName:item.Eat_MasName,
                        Food_Status:item.Food_Status,
                        Is_SetMeal:true,
                        is_type:_is_type,
                        FoodSetmeal_ID:item.EAT_XFBMID,
                        Food_Speed:item.Food_Speed
                    }
                    this.temporderMenuList.push(food);
                    item.FoodSetmeals.map(food=>{
                        food = {
                            SubCategory_ID:item.SubCategory_ID,
                            EAT_XFBMID:item.EAT_XFBMID,
                            EAT_XFCode:item.EAT_XFCode,
                            EAT_XFName:food.FoodSetmeal_Name,
                            Eat_Number:item.Eat_Number,
                            Eat_CheckPrice:item.Eat_CheckPrice,
                            Eat_SalePrice:item.Eat_SalePrice,
                            Eat_MasName:item.Eat_MasName,
                            Food_Status:item.Food_Status,
                            Is_SetMeal:true,
                            FoodSetmeal_ID:food.FoodSetmeal_ID,
                            FoodSetmeal_Code:food.FoodSetmeal_Code,
                            FoodSetmeal_Number:food.FoodSetmeal_Number,
                            FoodSetmeal_Price:food.FoodSetmeal_Price,
                            FoodModifys:food.FoodModifys,
                            is_type:_is_type,
                            Food_Speed:food.Food_Speed
                        }
                        this.temporderMenuList.push(food);
                    })
                }else{
                    if(item.Food_Status==-1){
                        item = Object.assign(item, {is_type:_is_type,Food_Speed:item.Food_Speed})
                        this.temporderMenuList.push(item);
                    }
                }
            })
            this.tempfoodSub = this.unique(this.tempfoodSub);
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        confirm(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("kitchenClickBack",this.temporderMenuList)
        },
         /**鼠标悬浮展示 */
        hoverShow(Eat_MasName){
            let title=''
            if(Eat_MasName==''||Eat_MasName==null){
                title=''
            }else{
                title='口味:'+Eat_MasName
            }
            return title;
        },
        /**头部菜单点击 */
        onClickAll(item){
            if(item){
                if(this.foodSubIndex!=item.SubCategory_ID){
                    this.isSelectAll=false;
                }
                this.foodSubIndex = item.SubCategory_ID
            }else{
                if(this.foodSubIndex!=-1){
                    this.isSelectAll=false;
                }
                this.foodSubIndex=-1
            }
        },
        /**菜品点击 */
        onDescClick(item){
            if(item.is_type==0){
                item.is_type=1;
            }else{
                item.is_type=0;
            }
        },
        /**全选 */
        onDeskSelectAll(){
            if(this.isSelectAll==false){
                if(this.foodSubIndex==-1){
                    this.temporderMenuList.map(item=>{
                        if(item.Food_Status=='-1'){
                            item.is_type=1;
                        }
                    })
                }else{
                    this.temporderMenuList.map(item=>{
                        if(item.SubCategory_ID==this.foodSubIndex && item.Food_Status=='-1'){
                            item.is_type=1;
                        }
                    })
                }
                this.isSelectAll=true;
            }else{
                if(this.foodSubIndex==-1){
                    this.temporderMenuList.map(item=>{
                        if(item.Food_Status=='-1'){
                            item.is_type=0;
                        }
                    })
                }else{
                    this.temporderMenuList.map(item=>{
                        if(item.SubCategory_ID==this.foodSubIndex && item.Food_Status=='-1'){
                            item.is_type=0;
                        }
                    })
                }
                this.isSelectAll=false;
            }
        },
        /**等叫,加急,先上,后上 */
        onFoodSpeed(status,type){
            if(type==1){
                let _type=0;
                 this.temporderMenuList.map(item=>{
                    if(item.is_type==1){
                        _type=1;
                    }
                })
                if(_type==0){
                    this.$alert('请先选择对应菜品', '提示', {
                                confirmButtonText: '确定',
                    });
                    return;
                }
                this.temporderMenuList.map(item=>{
                    if(item.is_type==1){
                        item.Food_Speed=status;
                    }
                })
                this.isFoodSpeed = status;
            }else{
                this.temporderMenuList.map(item=>{
                        if(item.Food_Status=='-1'){
                            item.Food_Speed=status;
                        }
                })
                this.isFoodSpeed = status;
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
                this.$emit("kitchenClick",this.temporderMenuList)
            }
        },
        /**去除重复 */
        unique(newarr) {
            const res = new Map();
            return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
        }
    }
}
</script>

<style lang="scss">
@import "./readyKitchenModel.scss";
</style>