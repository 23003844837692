<template>
  <modal class="daNianPayModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">大拈会员支付<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body" >
            <div ref="CardMoney">
                <div class="clearfix" >
                    <div class="left-box">
                        <tab-pane>
                            <div class="tr-li">
                                请输入查询码：
                                <div class="from-input" style="width:325px" >
                                    <input type="text" ref="input" @focus="(e)=>e.target.select()" 
                                        class="key-focus" data-model='idCode' 
                                        v-model="idCode" isBarcode 
                                        @barcode="onMemberInfo()" 
                                        @keydown.enter="onMemberInfo()" />
                                    <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                                </div>
                                <button class="btn" @click="readCard()">读卡</button>
                            </div>
                            <div class="table-box">
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:210px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                    <div class="lable-txt" style="width:200px;">会员等级：
                                        <el-select class="kindSelect" v-if="CardKindList.length>1" v-model="meberInfo.Card_KindName">
                                            <el-option v-for="kind in CardKindList" :key="kind" :value="kind" :label="kind"></el-option>
                                        </el-select>
                                        <span style="color: #000;font-size: 14px;" v-else>{{meberInfo?.Bestech_Card_KindName}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:135px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:210px;">
                                        <span style="letter-spacing:3px;">手机号：</span><span style="color: #000;font-size: 14px;">{{meberInfo?.Mobile}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:200px;">
                                        性<span style="padding-left:25px;">别</span>：
                                        <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:135px;">余额：
                                        <span style="color: #000;font-size: 14px;" v-if="meberInfo">{{Number(meberInfo?.Card_Money).toFixed(2)}}</span>
                                    </div>
                                </div>
                            </div>
                        </tab-pane> 
                    </div>
                    <div class="right-box">
                        <div class="keyboard">
                            <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                    data-value="9">9</span></div>
                            <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                    data-value="6">6</span></div>
                            <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                    data-value="3">3</span></div>
                            <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                    data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                        </div>
                    </div>
                </div>
                <div class="money-info">
                    <div class="lable-txt">待支付金额：
                        <b class="noney red" v-if="unPaidMoney>0">{{(Conversion((unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)))}}</b> 
                        <b class="noney red" v-if="unPaidMoney<=0">0</b>
                    </div>
                    <div class="lable-txt" v-if="is_CardPayShow">储值支付：
                        <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()" />
                    </div>
                </div>
            </div>
        </tabs>
        <div class="footer-box">
            <div style="position:absolute;width:91%;height:10%;" v-if="PayResultCode==1" ></div>
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询支付结果</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
     <!--会员卡信息-->
    <modal-load :isShow="cardMemberShow">
        <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
    </modal-load>
    <modal-load :isShow="cardCodeShow">
        <card-code-model :isShow="cardCodeShow" v-on:closeModel="cardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
    </modal-load>
    <!--密码验证-->
    <modal-load :isShow="passwordShow">
        <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
    </modal-load>
  </modal>
</template>


<script>
import cardCodeModel from '../model/cardCodeModel.vue'
import passwordConfirmModel from '../model/passwordConfirmModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 

/**大拈会员支付 */
export default {
    name:"westStoredModel",
    emits:["daNianMemberReturn","discountSchemeClic","memberReturn","memberPayClick","refreshOrder"],
    components:{
        cardMemberModel,
        cardCodeModel,
        passwordConfirmModel,
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        payType:Object,
        unionEatAutoID:Object,
        unionType:Boolean,
        //折扣方案数据
        discountSchemeList:{
            type:Object,
            default:null
        }
    },
    computed:{
        //大拈会员制卡后密码
        DaNianMakeCardPW(){
            return (this.$cacheData?.globalVariable?.GlobalSysSetting?.DaNian_MakeCardSecret||"").replaceAll(" ","");
        },
        //卡等级 多个 选择
        CardKindList(){
            let cardKind=[];
            if(this.meberInfo?.Bestech_Card_KindName){
                cardKind= this.meberInfo?.Bestech_Card_KindName.split(",");
            }
            return cardKind;
        }
    },
    data(){
        return {
            cardMemberShow:false,
            is_CardPayShow:true,
            cardCodeShow:false,
            passwordShow:false,
            equipmentSn:"",//卡SN，读卡支付时不允许为空
            sectorData:"",//卡扇区数据
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            cashCoupon:'', //代金券
            commodityVoucher:'',//商品券
            couponsPay:'', //储值支付金额
            PointPay:'', //积分支付积分
            PointPayMoney:'',//积分支付金额
            PointPayCouponMoney:'',
            unPaidMoney:0, //待付金额
            memberorderInfo:'',  //临时订单数据
            scanPayCode:'',
            /**联台结账订单新时间戳 */
            UnionOrders:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            isCardPayPwd:'',
            queryCode:'',//查询码
            PointPay_Scale:'', //积分比例
        }
    },
    mounted(){
        this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           this.openimp()
        })
    },
    watch: {
        orderInfo:{
            immediate:true,
            handler(vla){
                this.EmptyDatas();
                this.couponsPay=0
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                if(this.memberorderInfo.Order_ConsumeList){
                    //合并联台的订单信息
                    if(this.unionType==true){
                        this.memberorderInfo.UnionOrderInfoList.map(union=>{
                            union.Order_ConsumeList.map(unOrder=>{
                                this.memberorderInfo.Order_ConsumeList.push(unOrder)
                            })
                        })
                    }
                    this.memberorderInfo.Order_ConsumeList.map((order,i)=>{
                        order = Object.assign(order,{isCoupon:false,CouponNumber:1})
                        if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                            this.memberorderInfo.Order_ConsumeList.splice(i, 1)
                            return 
                        }
                    })
                }
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                if(this.memberorderInfo.Member_CardNo){
                    if(this.idCode==''){
                        this.idCode = this.memberorderInfo.Member_CardNo
                    }
                }
                this.cardInfoShow()
                this.PointPayCouponMoneyInfo(0)
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.openimp()
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
               this.couponsPay=0
            }
            this.PointPayCouponMoneyInfo(1)
        },
        PointPayMoney(){
            if(this.PointPayMoney<0){
               this.PointPayMoney=0
            }else{
                if(Number(this.PointPayMoney)>this.meberInfo.ReditMoney){
                    this.$message.warning('您的可用积分余额为:'+this.meberInfo.ReditMoney+'');
                    this.PointPayMoney=this.meberInfo.ReditMoney
                    return
                }
            }
            this.PointPayCouponMoneyInfo(2)
        },
    },
    methods:{
        openimp(){
            this.EmptyDatas();
            this.sectorData ="";
            this.equipmentSn = "";
            this.idCode='',this.errorTitle='';
            this.initializaTion()
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            if(this.memberorderInfo.Order_ConsumeList){
                //合并联台的订单信息
                if(this.unionType==true){
                    this.memberorderInfo.UnionOrderInfoList.forEach(union=>{
                        union.Order_ConsumeList.forEach(unOrder=>{
                            this.memberorderInfo.Order_ConsumeList.push(unOrder)
                        })
                    })
                }
                this.memberorderInfo.Order_ConsumeList.forEach((order,i)=>{
                    Object.assign(order,{isCoupon:false,CouponNumber:1})
                    if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                        this.memberorderInfo.Order_ConsumeList.splice(i, 1)
                        return 
                    }
                })
            }
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            if(this.memberorderInfo.Member_CardNo){
                this.idCode = this.memberorderInfo.Member_CardNo
                this.onMemberInfo()
            }
            this.PointPayCouponMoneyInfo(0)
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input?.focus();
            },100);
        },
        blurRechargeMoney(){
            if(this.unPaidMoney.toFixed(2)<0){
                this.unPaidMoney =0
            }
        },
        /**实体卡读卡 */
        readCard(){
            //大拈读卡
            this.$webBrowser.redCardM1({type:"daNianVip",password:this.DaNianMakeCardPW}).then((d)=>{
                if(d){
                    if(d.state==0){
                        this.sectorData = d.data.cardID;
                        this.equipmentSn = d.data.snr;
                        this.onMemberInfo(true)
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**查询支付结果 */
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        this.$message.success({ message:data.ResponseHeader.ResultDesc});
                    }
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    //this.ispaySuccess=0;
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**获取会员信息 
         * @param isRedCard 是否是读卡
        */
        onMemberInfo(isRedCard){
            if(isRedCard){//读卡
                this.idCode="";
            }else{
                if(!this.idCode){
                    this.$message.error('查询码不能为空');
                    return
                }
                this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
                this.sectorData ="";
                this.equipmentSn = "";
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()

            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Member_Type:23,//大拈会员
                Dynamic_Type:isRedCard?"CardSN":"ActivePayCode",
                DynamicNo:isRedCard?this.sectorData:this.idCode,
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    if(data.ResponseBody?.length==0){
                        this.$message.warning('查询不到对应的会员信息!');
                        this.idCode=''
                        return
                    }else{
                        if(data.ResponseBody?.length>1){
                            this.cardMemberList = data.ResponseBody;
                            this.cardMemberShow=true;
                        }else{
                            this.meberInfo=data.ResponseBody[0];
                            
                            if(this.CardKindList.length>1){
                                this.meberInfo.Card_KindName=this.CardKindList[0];
                            }else{
                                this.meberInfo.Card_KindName=this.meberInfo.Bestech_Card_KindName;
                            }
                            this.cardInfoShow()
                            this.DealWithTheDiscount();
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**折扣方案操作*/
        DealWithTheDiscount(){
            if(this.meberInfo?.MemberDiscountPlanCodes.length>0){
                //只查第一个折扣方案
                let schemePlan=this.discountSchemeList?.DiscountScheme_PlanList?.find(it=>it.DiscountScheme_Code==this.meberInfo?.MemberDiscountPlanCodes[0]);
                if(schemePlan){//折扣方案
                    let param={
                        User_ID:this.userInfo?.User_ID,
                        Operator_Name:this.userInfo?.Login_Name,
                        EAT_AUTOID:this.orderInfo.Eat_AutoID,
                        Kind:1,//1：折扣方案，5：金额折扣 ，3：全单折扣，4：单品折扣
                        DiscountScheme_AutoID:schemePlan.DiscountScheme_AutoID,
                        IsReturn_CheckOutInfo:true,
                        Is_UnionOrder:true,
                        Timestamps:this.orderInfo.Timestamps.toString(),
                    }
                    const loading = this.$loading({
                        text: "全提交中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.DealWithTheDiscount",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            //this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            //this.$message.success({ message: "折扣计算完成"});
                            this.$emit("discountSchemeClic",data)
                        }
                    }).catch((e)=>{
                        loading.close();
                        //this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }
            }
        },
        hide(){
            if(this.PayResultCode!=1){
                this.$emit("daNianMemberReturn",this.memberorderInfo,this.meberInfo,1)
            }
        },
        /**确定付款 */
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(Number(this.couponsPay)>0){
                if(Number(this.couponsPay)> Number(this.unPaidMoney.toFixed(2))){
                    this.$message.error('储值金额超过待支付金额');
                    return
                }
            }
            //是否需要验证密码
            if(this.meberInfo.flag==true && this.isCardPayPwd==''){
                this.passwordShow=true
                return
            }
            if(this.PayResultCode==0){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                let _order = this.memberorderInfo
                let _meberInfo=''
                /** 预支付总金额 */
                let _counMoney=0
                /**支付方式 */
                let _payment=[]
                let _meber = this.meberInfo
                /**会员信息 */
                _meberInfo ={
                    Member_Sys:23, //会员系统类型 23：大拈会员
                    DynamicType:_meber?.DynamicType, //查询码类型 
                    Card_AutoID:_meber?.Bestech_Card_AutoID, //卡主键
                    Card_No:_meber.Card_No, //卡号
                    Member_Name:_meber?.MemberName, //姓名
                    MemberCard_Kind:_meber?.Card_KindName, //会员等级
                    Mobile:_meber?.Mobile,//会员手机号
                    NotJoinPointProgram:this.PointChecked,//不参与线上积分
                    Invoice_Money:0, //本次消费开票金额
                    Invoice_No:'',//发票号码
                    DaNianExtendInfo:{//大拈会员扩展参数 
                        idCode:this.idCode,
                        equipmentSn:this.equipmentSn,
                        sectorData:this.sectorData,
                    }
                }
                //积分支付
                if(this.PointPayMoney>0){
                    _payment.push({
                        Payment_ID:'CardPointPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money:this.PointPayMoney, //支付金额
                        Payment_FactMoney:0, //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:_meber.Card_No, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = _counMoney + Number(this.PointPayMoney)
                }
                //储值
                if(this.couponsPay>0){
                    _payment.push({
                        Payment_ID:'CardMoneyPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money: Number(this.couponsPay), //支付金额
                        Payment_FactMoney:Number(this.couponsPay), //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:_meber.Card_No, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = _counMoney + Number(this.couponsPay)
                }
                if(this.meberInfo.flag){
                    _meberInfo = Object.assign(_meberInfo,{Card_PWD:this.isCardPayPwd})
                }
                let param={
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: _order.Eat_AutoID,//结账单ID
                    UnionOrders:this.unionEatAutoID,//联台订单ID集合
                    Timestamps:_order.Timestamps.toString(), //账单时间戳
                    Operator_Name:this.userInfo?.Login_Name,//操作员
                    Eat_DeskName:_order.Eat_DeskName, //桌牌号
                    Service_Charge:_order.Service_Money,  //服务费
                    MantissaAdjustment_Money:Number(Number(_order.Mantissa_Money).toFixed(2)),  //尾数调整金额
                    Member:_meberInfo,//会员信息
                    Payments:_payment, //支付方式列表
                }
                console.log("支付预览",param)
                /**支付预览 */
                const loading = this.$loading({
                    text: "支付预览中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                    console.log("支付预览返回",data)
                    loading.close();
                    this.payPreviewData = data
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                        this.isCardPayPwd=''
                        if(data.ResponseBody?.Timestamps){
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        /**保存新时间戳 */
                        if(data.ResponseBody?.UnionOrders){
                            this.UnionOrders=data.ResponseBody.UnionOrders
                        }
                    }else{
                        if(data.ResponseBody?.OnlineDiscountMoney>0){
                            /**刷新订单数据 */
                            this.$emit("refreshOrder")
                        }
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        this.memberorderInfo.Mantissa_Money = data.ResponseBody.MantissaAdjustMoney
                        this.tempUserId = data.ResponseBody?.User_ID
                        this.tempOperatorName = data.ResponseBody?.User_Name
                        /**保存新时间戳 */
                        if(data.ResponseBody?.UnionOrders){
                            this.UnionOrders=data.ResponseBody.UnionOrders
                        }
                        /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                        if(data.ResponseBody.Is_NeedSMSCode==1){
                            this.cardCodeShow = true
                            return
                        }
                        /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                        if(data.ResponseBody.Is_NeedPassWord==1){
                            this.passwordShow=true
                            return
                        }
                        /**支付提交判断 0不需要 1需要 */
                        if(data.ResponseBody.Is_NeedSubmit==0){
                            if(this.Conversion((this.unPaidMoney.toFixed(2)-Number(this.PointPayCouponMoney)).toFixed(2))<=0){
                                this.PerformingCheckOut(data)
                            }else{
                                this.$emit("daNianMemberReturn",this.memberorderInfo,data.ResponseBody)
                            }
                        }
                        if(this.scanCodePay==false){
                            this.PaySubmit(data)
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else if(this.PayResultCode==1){
                this.PaySubmit(this.payPreviewData)
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
               let param={
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                    Member_Type:data.ResponseBody.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码,密码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                if(this.tempUserId!='' && this.tempOperatorName!=''){
                    param.User_ID = this.tempUserId
                    param.Operator_Name = this.tempOperatorName
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.PaySubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseBody.Timestamps){
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                        }
                        if(data.ResponseHeader.ResultCode==4003){
                            this.PayResultCode=0
                            this.scanPayCode=''
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        if(data.ResponseBody.TryCheckOut==true){
                            this.PerformingCheckOut(data)
                        }else{
                            this.$emit("memberReturn",this.memberorderInfo,this.meberInfo,2,data.ResponseBody)
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**结账方法 */
        PerformingCheckOut(data){
            let EatAutoIDList=[]
            let orderEatAutoList={Order_ID:this.memberorderInfo.Eat_AutoID,Timestamps:data.ResponseBody.Timestamps.toString(),Service_Charge:this.memberorderInfo.Service_Money}
            EatAutoIDList.push(orderEatAutoList)
            if(this.memberorderInfo.UnionOrderInfoList!=null){
                this.memberorderInfo.UnionOrderInfoList.forEach(union=>{
                    if(this.UnionOrders){
                        this.UnionOrders.find(preunion=>{
                            if(union.Eat_AutoID==preunion.Order_ID){
                                union.Timestamps = preunion.Timestamps.toString();
                                return true;
                            }
                            return false;
                        })
                    }
                    orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps.toString(),Service_Charge:union.Service_Money}
                    EatAutoIDList.push(orderEatAutoList)
                })
            }
            let param={
                User_ID:this.userInfo.User_ID, //登陆用户ID
                CheckOutOrders:EatAutoIDList, //结账订单ID
                MantissaAdjust_Money:this.memberorderInfo.Mantissa_Money, //尾数调整金额
                Service_Charge:this.memberorderInfo.Service_Money,//服务费
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Print_YN:true, //是否返回结账单打印内容
                ElectronicInvoice_PrintForbidden:this.$global.invoiceOff,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
                PhaseID:this.$global.PhaseID,//指定时段ID
            }
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.tempOperatorName
            }
            const loading = this.$loading({
                text: "结账中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    if(data.ResponseBody?.Timestamps){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    }
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(param.Print_YN==true){//返回结账单打印内容
                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                    }
                    if(data.ResponseBody.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    this.$message.success({ message: "结账成功"});
                }
                this.$emit("memberPayClick",data)
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.couponsPay=''
            this.PointPayCouponMoney=''
            this.choiceCoupons=[]
            this.cashCoupon=''
            this.commodityVoucher=''
            this.RechargeType=0
            this.scanPayCode=''
            this.PayResultCode=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName=''
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.memberorderInfo.Order_ConsumeList.map(order=>{
                order.isCoupon=false,
                order.CouponNumber=1
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.cardInfoShow();
            this.DealWithTheDiscount();
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            let _cashArr = [];  //定义一个数组
            let _commArr=[];
            if(this.meberInfo?.Coupons){
                this.meberInfo.Coupons.map(item=>{
                    if(item.Coupon_Kind==1){
                        _cashArr.push(Object.assign(item,{Check:false}))
                        this.cashCoupon=_cashArr;
                    }else{
                        _commArr.push(Object.assign(item,{Check:false}))
                        this.commodityVoucher=_commArr;
                    }
                })
            }
            //储值支付金额=待支付金额  大拈会员 不判断余额 没有余额也能支付
            this.couponsPay=this.unPaidMoney;
        },
        /**验证码返回 */
        cardCodeConfirm(code){
            this.verifyCode=code
            this.cardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd;
            this.passwordShow = false;
            this.isCardPayPwd=pwd;
            this.confirm();
        },
        swipeConfirm(Cardid){
            this.swipeShow=false
            this.idCode = Cardid
            this.onMemberInfo(true)
        },
        PointPayCouponMoneyInfo(type){
            if(this.meberInfo){
                if(type==0){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==1){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==2){
                    if(Number(this.couponsPay)<this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                        // if(this.isActiveCodePay==false){
                        //     this.couponsPay = Number(this.couponsPay)+Number(this.accSub(0,this.unPaidMoney))
                        // }
                    }else if(Number(this.couponsPay)==this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                    }else{
                        this.couponsPay =this.accSub(0,this.unPaidMoney) 
                        this.PointPayCouponMoney = this.unPaidMoney
                    }
                }
            }
            else{
                this.PointPayCouponMoney=0
            }
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return ((arg2*m-arg1*m)/m).toFixed(n);
        },
    }
}
</script>

<style lang="sass">
@import "./daNianPayModel.scss";
</style>