<template>
    <modal class="WholeRetreatModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">整桌退菜</div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">桌台号：</div>
                        <div class="from-input" style="width:310px">
                            <input type="text" class="key-focus" readonly :value="orderInfo.Eat_DeskName" />
                        </div>
                    </div>
                    <div class="title">选择退菜原因</div>
                    <div class="reason-box" v-mouse-scroll>
                        <div class="reason-tag" :class="{selected:item.RefundReason_AutoID==reasontype}" v-for="(item,index) in RefundReasonsList" :key="index" @click="clickReason(item)" >{{item.RefundReason_Name}}</div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'WholeRetreatModel',
    props:{
        isShow:Boolean,
        disRadioOrder:Object,
        orderInfo:Object,
        isCheckOut:Boolean,
    },
    data(){
        return{
            orderNum:'A001',
            reasontype:0,
            reasontContent:'',
             /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            /**考虑到联台情况获取对应时间戳 */
            Timestamps:'',
            NumberType:0
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.RefundReasons()
            initKeyBoard.call(this,this.$el);
        })
    },
    watch:{
        isShow(){
            if(this.isShow==true){
                this.RefundReasons()
            }
        },
        orderNum(){
            if( Number(this.orderNum)<0){
                this.orderNum=0
            }
        }
    },
    methods:{
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**退菜原因*/
        RefundReasons(){
            this.Timestamps=''
            if(this.$global.PosRooms){
                this.$global.PosRooms.forEach(pos=>{
                    pos.PosDesks.forEach(desk=>{
                        if(desk.Desk_AutoID==this.orderInfo.Eat_DeskID){
                            //console.log('desk:'+JSON.stringify(desk))
                            this.Timestamps = desk.Timestamps.toString()
                        }
                    })
                })
            }
            // console.log('orderInfo:'+JSON.stringify(this.orderInfo))
            console.log('Timestamps:'+this.Timestamps)
            this.NumberType=0
            this.orderInfo.Order_ConsumeList.some(order=>{
                if(order.Eat_Number>0){
                    this.NumberType=1
                    return true
                }
            })
            this.$cacheData.RefundReasons().then((d)=>{
                this.RefundReasonsList = d;
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        }, 
        confirm(){
            if(this.NumberType==0){
                 this.$message.error('退菜数量不能为0或小于0!');
                 return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Desk_AutoID:this.orderInfo.Eat_DeskID, //桌台ID
                Reason:this.reasontContent.toString(),  //退菜原因
                OrderTimestamps:this.orderInfo.Timestamps.toString(), //时间戳
                DeskTimestamps:this.Timestamps,//桌台时间戳
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            if(this.reasontContent==''){
                this.$alert('原因不能为空!', '提示', {
                    confirmButtonText: '确定',
                });
                return;
            }
            const loading = this.$loading({
                text: "退菜中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.RefundAllDeskFood",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseHeader.ResultCode==3002){
                        this.$global.PosRooms.forEach(pos=>{
                            pos.PosDesks.forEach(desk=>{
                                if(desk.Desk_AutoID==this.orderInfo.Eat_DeskID){
                                    this.Timestamps = desk.Timestamps.toString()
                                }
                            })
                        })
                    }
                }else{
                    ElMessage.success({ message: "整桌退菜成功"});
                    this.$emit("WholeReturn",data)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**原因 */
        clickReason(item){
            this.reasontype = item.RefundReason_AutoID;
            this.reasontContent = item.RefundReason_Name;
        }
    }
}
</script>

<style lang="scss">
@import './WholeRetreatModel.scss';
</style>