<template>
    <modal class="selectOrderOperatorModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">选择点菜员</div>
        <div class="modal-body">
            <div class="search-box">
                <div class="search"><input ref="search" type="text" placeholder="请输入编码、姓名检索" v-model="searchText" @keyup="searchKeyup"></div>
                <div class="search-bnt">搜索</div>
            </div>
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">
                        <div class="td selectChecked">选择</div>
                        <div class="td code">编码</div>
                        <div class="td name">名称</div>
                    </div>
                </div>
                <div class="panel-body" ref="box">
                    <div class="tr" v-for="item in posUsersPage" :key="item" :class="{selected:item.User_ID==seleUser?.User_ID}" 
                        @click="posUsersClick(item)" 
                        @dblclick="doubleClick(item)"> 
                        <div class="td selectChecked">
                            <span class="iconfont " :class="item.User_ID==seleUser?.User_ID?'icon-iconoption1':'icon-iconoption'"></span>
                        </div>
                        <div class="td code">{{item.User_Code}}</div>
                        <div class="td name">
                            <span>{{item.User_Name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                &nbsp; &nbsp; {{pageIndex}}/{{pageCount}} 页 &nbsp; {{posUsersFilter?.length}}条
                <div class="float-right">
                    <page-turning class="bnt-fangxiang" v-model="pageIndex" :pageCount="pageCount" type="up"><i class="iconfont icon-fangxiang-up"></i></page-turning>
                    <page-turning class="bnt-fangxiang" v-model="pageIndex" :pageCount="pageCount"><i class="iconfont icon-fangxiang-down"></i></page-turning>
                    <!-- <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div> -->
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" v-show="isPlace" @click="placeConfirm()">不修改直接下单</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

/**选择点菜员 */
export default {
    name:"selectOrderOperatorModel",
    emits:["close","confirm"],
    props:{ 
        //是否显示弹层
        isShow:{
            type:Boolean,
            default:false
        },
        //是否是下单选择点菜员
        isPlace:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return{
            /**用户数据 */
            posUsers:null,
            /**选中的用户 */
            seleUser:null,
            /**搜索 */
            searchText:'',
            //参数 点击确定需要回传
            param:{},
            pageIndex:1,
            pageSize:10
        }
    },
    mounted(){

        const loading = this.$loading({
            text: "获取用户数据中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        //所有人
        this.$cacheData.PosUsers().then((d)=>{
            loading.close();
            if(d){
                this.posUsers=d;
            }
        }).catch((e)=>{
            loading.close();
            this.$message.error('加载用户数据失败：'+e.message);
            console.log('加载用户数据失败：'+e);
        })

        this.$nextTick(()=>{
            this.init();
        });
    },
    computed:{
        posUsersFilter(){
            let data=this.posUsers||[];
            let MenuTxt=(this.searchText||"").trim().toLowerCase();
            if(MenuTxt!=''){
                data=data?.filter(item=>(item.User_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (item.User_Name||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            return data;
        },
        /**分页数据 */
        posUsersPage(){
            return this.posUsersFilter.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        /**总页数 */
        pageCount(){
            return Math.ceil(this.posUsersFilter.length/this.pageSize)||1;
        }
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        searchText(){
            this.seleUser=null;
            this.pageIndex=1;
        },
        pageIndex(){
            this.seleUser=null;
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.seleUser=null;
            this.searchText='';
            setTimeout(()=>{
                if(this.$refs.search){
                    this.$refs.search.focus();
                }
            },100)
        },
        /**选择点菜员 */
        posUsersClick(item){
            this.seleUser = item;
        },
        /**双击测试 */
        doubleClick(item){
            this.seleUser = item;
            this.confirm();
        },
        hide(){
            this.$emit("close");
        },
        //不修改直接下单
        placeConfirm(){
            this.$emit("confirm",null,this.param);
        },
        confirm(){
            if(this.isPlace){
                if(!this.seleUser){
                    this.$message.warning("请先选择点菜员！");
                    return;
                }
                this.$confirm('你确认本次下单点菜员是'+this.seleUser.User_Name+'吗？', "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.$emit("confirm",this.seleUser.User_Name,this.param);
                        }
                    },
                });
            }else{
                this.$emit("confirm",this.seleUser?.User_Name,this.param);
            }
        },
        //判断滚动条位置
        scrollBy(){
            let dom=this.$refs.box.querySelector(".selected");
            if(dom){
                let boxRect=this.$refs.box.getBoundingClientRect();
                let rect=dom.getBoundingClientRect();
                if(boxRect.top>rect.top){
                    this.$refs.box.scrollTop=this.$refs.box.scrollTop-(boxRect.top-rect.top);
                }else if(boxRect.top+boxRect.height<rect.top+rect.height){
                    this.$refs.box.scrollTop=this.$refs.box.scrollTop+(rect.top+rect.height-(boxRect.top+boxRect.height));
                }
            }
        },
        /**检索 输入框 输入 */
        searchKeyup(e){
            if(e.keyCode==40){//下
                let index=this.posUsersPage?.findLastIndex(it=>it.User_ID==this.seleUser?.User_ID)+1;
                if(this.posUsersPage?.length>index){
                    this.seleUser=this.posUsersPage[index];
                    this.$nextTick(()=>{
                        this.scrollBy();
                    })
                }
            }else if(e.keyCode==38){//上
                let index=this.posUsersPage?.findIndex(it=>it.User_ID==this.seleUser?.User_ID)-1;
                if(index>=0){
                    this.seleUser=this.posUsersPage[index];
                    this.$nextTick(()=>{
                        this.scrollBy();
                    })
                }else{
                    this.seleUser=null;
                }
            }else if(e.keyCode==37){
                if(this.pageIndex>1){
                    this.pageIndex--;
                }
            }else if(e.keyCode==39){
                if(this.pageIndex<this.pageCount){
                     this.pageIndex++;
                }
            }else if(e.keyCode==13){//回车
                if(this.seleUser){
                    this.confirm();
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import "./selectOrderOperatorModel.scss";
</style>