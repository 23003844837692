<template>
  <modal class="consumeTypesModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">消费类型
        </div>
        <div class="modal-body">
             <div class="clearfix">
                <div class="left-box float-box">
                    <div class="filter-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodPracticePage',isNavFirstSticky:true}">
                            <div class="nav-li " :class="{selected:consumeTypeAutoid==-1}" @click="consumeClickAll()" >全部</div>
                            <div class="nav-li " :class="{selected:consumeTypeAutoid==item.ConsumeTypeKind_AutoID}" v-for="item in consumeTypes" :key="item" @click="consumeClickAll(item)">
                                {{item.ConsumeTypeKind_Name}}
                            </div>
                        </div>
                        <div class="float-right page-box"><span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up"></span>
                            <span class="page-number">{{foodPracticePage.pageIndex}}/{{foodPracticePage.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>
                    <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                        <div class="tab-pane" v-if="ConsumptionList">
                            <div class="item-li category " :class="{selected:consumeAutoID==eat.ConsumeType_AutoID}" v-for="eat in ConsumptionList" v-show="eat.Kind_AutoID==consumeTypeAutoid ||consumeTypeAutoid==-1" :key="eat"  @click="consumeTypeClick(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.ConsumeType_Name}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
            <!-- <button class="btn btn-confirm" @click="confirm()">确认</button> -->
        </div>
  </modal>
</template>

<script>
import { deepClone } from '/src/common'
import { httpAES,cacheData } from '/src/services'
/**全单要求操作 */
export default {
    name:'consumeTypesModel',
    props:['isShow','ConsumptionList','consumeTypes'],
    data(){
        return{
            foodPracticePage:{pageIndex:0,pageSize:0},
            consumeTypeAutoid:-1,
            consumeAutoID:'', //订单选择
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.openimp()
        })
    },
    watch:{
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        },
    },
    methods:{
        openimp(){
            this.consumeTypeAutoid=-1
            this.consumeAutoID=''
        },
        hide(){
            this.$emit("closeModel")
        },
        consumeClickAll(item){
            if(item){
                this.consumeTypeAutoid=item.ConsumeTypeKind_AutoID
            }else{
                this.consumeTypeAutoid=-1
            }
        },
        /**订单单元点击 */
        consumeTypeClick(item){
            this.consumeAutoID = item.ConsumeType_AutoID
            this.$emit("consumeTypeConfirm",item)
        }
    }
}
</script>

<style lang="scss">
@import './consumeTypesModel.scss';
</style>