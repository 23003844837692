import cacheData from './cacheData'
import { reactive,watch } from 'vue'

//全局变量
const global=reactive({
	invoiceOff:false,//发票开关 默认false 打印发票;  true 不打印发票
	NumberkeyOff:cacheData.get("Numberkey")||false, //点单界面数字横向键盘开关
	servingPosRooms:Object,//存储桌台
	servingRoomsList:Object, //存储上菜顺序里选择的桌台
	servingTheFood:Object,//存储上菜顺序选中的桌台
	servingUrlType:0, //是否在上桌顺序页面 0不在 1在
	isLableData:cacheData.get("isLableData")||false,//本站点默认隐藏标签数据
	PhaselD:null,//指定时段ID 中餐结账时用
	currentTime:new Date(),//当前时间变化
});

//返回 菜品操作详情 
function getConsumeOperations(it){
	let operation=Object.assign([],it.ConsumeOperations);
	if(it.isFullReduce && it.fullReducePrice>=0){//有 满减 扣除
		operation.push({
			OperateType:6,//满减 扣除
			Deducte_Price:it.fullReducePrice,
			Operator:"",
			OperateReason:"",
			Project_AutoID:"",
			Project_Name:"",
			Project_Kind:0,
			Project_Rate:0,
			Old_Price:0,
		})
	}
	
	if(it.isPromotion2){//第二份折扣
		operation.push({
			OperateType:7,//第二份折扣
			Deducte_Price:it.zkPromotionPrice,
			Operator:"",
			OperateReason:"",
			Project_AutoID:"",
			Project_Name:"",
			Project_Kind:0,
			Project_Rate:0,
			Old_Price:0,
		})
	}
	return operation;
}
//返回快餐订单信息 组装快餐预览时菜品明细信息
global.getFastOrderConsumes=(orderMenuList)=>{
	let _orderConsumes=[];
	orderMenuList?.forEach((item)=>{
		if(item.Food_Status==-1 && !item.is_taste && !item.Is_Combo){
			let SizeNumber = Number(item?.AssSizeNumber)
			let Food_Types =1;
			let _seep= item.Food_Speed
			if(item.Food_Speed=='' || item.Food_Speed==null){
				_seep='即'
			}
			if(item.FoodSetmeals?.length>0){//套餐
				Food_Types=2
				SizeNumber=0
			}
			if(item.DiyTcType){
				Food_Types=item.DiyTcType
			}
			if(item?.Food_Type==4){
				Food_Types=4
			}


			let orders ={
				Food_ID:item.EAT_XFBMID,
				Food_Code:item.EAT_XFCode,
				Food_Type:Food_Types,
				Food_Speed:_seep,
				Food_Size:item.Eat_XFSize,
				Food_Number:item.Eat_Number,
				Food_Price:item.Eat_SalePrice,
				Food_CheckPrice:item.isPromotion2?item.zkPromotionPrice:item.Eat_CheckPrice,
				FoodModifys:item.FoodModifys,
				FoodSetmeals:item.FoodSetmeals,
				OrderComboConsumes:item.OrderComboConsumes?.map(it=>{
					return {
						Food_ID:it.EAT_XFBMID,
						Food_Code:it.EAT_XFCode,
						Food_Name:it.EAT_XFName,
						Food_Size:it.Eat_XFSize,
						Food_Type:1,
						Food_Speed:it.Food_Speed||'即',
						Food_Number:it.Eat_Number,
						Food_AssNumber:Number(it.AssSizeNumber),
						Food_Price:it.Eat_SalePrice,
						Food_CheckPrice:it.isPromotion2?it.zkPromotionPrice:it.Eat_CheckPrice,
						ConsumeOperations:getConsumeOperations(it),
						FoodModifys:it.FoodModifys
					}
				}),
				Food_Name:item?.EAT_XFName,
				Food_AssNumber:SizeNumber,
				ConsumeOperations:getConsumeOperations(item),
				Food_Remark:item.Remark?item.Remark:''
			};
			if(item?.Mas_ParentID){
				orders = Object.assign(orders,{Mas_ParentID:item?.Mas_ParentID})
			}
			_orderConsumes.push(orders);
		} 
	})
	return _orderConsumes;
}

/**
 * 尾数调整
 * @param  kind 尾数调整类型
 * @param  money 调整金额
 */
global.mantissaAdjust=(kind,money)=>{
	money=Number(Number(money).toFixed(4));
	if(kind==1){//角上四舍五不入
		let intMoney=parseInt(money);
		if(Number((money-intMoney).toFixed(2))<0.5){//角上小于0.5 舍去
			return intMoney;
		}
		return intMoney+0.5; //角上大于等于0.5 只用 0.5
	}else if(kind==2){//角上四舍五入
		return Math.round(money);//四舍五入取整
	}else if(kind==3){//角上舍位取整
		return Math.floor(money);//取整 舍去小数位
	}else if(kind==4){//角上进位取整
		return Math.ceil(money);//取整 角上有小数位进一位
	}else if(kind==5){//分上四舍五不入
		let intMoney=parseInt(money*10)/10;//金额*10
		if(Number((money-intMoney).toFixed(2))<0.05){//角上小于0.5 舍去
			return intMoney;
		}
		return Number((intMoney+0.05).toFixed(2)); //角上大于等于0.5 只用 0.5
	}else if(kind==6){//分上四舍五入
		return Math.round(money*10)/10;//金额*10 四舍五入取整 在除去10
	}else if(kind==7){//分上舍位取整
		return Math.floor(money*10)/10;//金额*10 取整 舍去小数位 在除去10
	}else if(kind==8){//分上进位取整
		return Math.ceil(money*10)/10;//金额*10 取整 角上有小数位进一位 在除去10
	}
	return money;
}
/**
 * 手机号 保密处理
 * @param phone 手机号
 */
global.phonePrivacy=(phone)=>{
	if(phone && cacheData?.globalVariable?.GlobalSysSetting?.RunPosMemberMobileShowWithHideChar){//启用POS界面中，会员手机号用** 脱敏显示， true:开启 false:未开启
		return phone.replace(/^(\d{3})\d*(\d{4})$/, "$1****$2");
	}
	return phone;
}

watch(
	() => global.isLableData,//监听
	(newVal, oldVal) => {
		cacheData.set("isLableData",newVal);//存储
	}
)
//点单界面数字横向键盘开关
watch(
	()=>global.NumberkeyOff,
	(newVal, oldVal) => {
		cacheData.set("Numberkey",newVal);//存储
	}
)

//全局变量
export {
	global
}