<template>
  <modal class="changeUnitModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">菜品换单位:{{multipleSizeFoods.Food_Name}}</div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="item-box" v-scroll-anime="{up:'item-up2',down:'item-down2',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                        <div class="item-li" :class="{selected:item.Food_ID==FoodID}" v-for="item in MultipleList" :key="item" @click="onDescClick(item)" >
                            <p class="title">￥{{item.Food_Price}}</p>
                            <p class="tit-unit">{{item.Food_Size}}</p>
                            <!-- 促销价 -->
                            <div class="cxPrice" v-if="item.Is_Promotion">
                                <div class="cxTag">促</div>
                                <div class="unit">￥</div>{{item.Promotion_Price}}
                            </div>
                            <!-- vip 价格 -->
                            <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                <div class="vipTag">VIP</div>
                                <div class="price"> 
                                    <div class="unit">￥</div>{{item.Food_VipPrice}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up2"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down2"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

export default {
    name:'changeUnitModel',
    data(){
        return {
            FoodID:-1,
            MultipleFoods:'',
            MultipleList:[]
        }
    },
    props:{
        isShow:Boolean,
        multipleSizeFoods:Object
    },
    mounted(){
        this.$nextTick(()=> {
           this.openimp()
        })
    },
    watch:{
        /**打开换单位页面 */
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        }
    },
    methods:{
        /**打开执行 */
        openimp(){
            this.MultipleList=[]
            let MultipList={
                Food_ID:this.multipleSizeFoods.Food_ID,
                Food_Code:this.multipleSizeFoods.Food_Code,
                Food_Name:this.multipleSizeFoods.Food_Name,
                Food_EnglishName:this.multipleSizeFoods.Food_EnglishName,
                SubCategory_ID:this.multipleSizeFoods.SubCategory_ID,
                Food_Property:this.multipleSizeFoods.Food_Property,
                Food_PinYin:this.multipleSizeFoods.Food_PinYin,
                Food_Size:this.multipleSizeFoods.Food_Size,
                Food_Price:this.multipleSizeFoods.Food_Price,
                Food_VipPrice:this.multipleSizeFoods.Food_VipPrice,
                Food_CostPrice:this.multipleSizeFoods.Food_CostPrice,
                Is_SoldOut:this.multipleSizeFoods.Is_SoldOut,
                Is_Discounted:this.multipleSizeFoods.Is_Discounted,
                Is_Seasonal:this.multipleSizeFoods.Is_Seasonal,
                Is_Limited:this.multipleSizeFoods.Is_Limited,
                RemainNum:this.multipleSizeFoods.RemainNum,
                Is_ChangedNum:this.multipleSizeFoods.Is_ChangedNum,
                Is_ChargeForService:this.multipleSizeFoods.Is_ChargeForService,
                Is_SetMeal:this.multipleSizeFoods.Is_SetMeal,
                Is_Special:this.multipleSizeFoods.Is_Special,
                KitPlace_ID:this.multipleSizeFoods.KitPlace_ID,
                Kit_Level:this.multipleSizeFoods.Kit_Level,
                Food_AssSize:this.multipleSizeFoods.Food_AssSize,
                Is_BindEatMas:this.multipleSizeFoods.Is_BindEatMas,
                FoodEatMasList:this.multipleSizeFoods.FoodEatMasList,
                Promotion_Price:this.multipleSizeFoods?.Promotion_Price,
                Is_Promotion:this.multipleSizeFoods?.Is_Promotion,
                SubCategory_Code:this.multipleSizeFoods?.SubCategory_Code,
            }
            this.multipleSizeFoods.MultipleSizeFoods.map(item=>{
                item = Object.assign(item,{Is_BindEatMas:this.multipleSizeFoods.Is_BindEatMas,FoodEatMasList:this.multipleSizeFoods.FoodEatMasList})
                this.MultipleList.push(item)
            })
            this.MultipleList.push(MultipList)
        },
        hide(){
            this.$emit("closeModel")
        },
        confirm(){
            if(!this.MultipleFoods){
                 this.$message.error('请先选择菜品分量!');
            }else{
                this.$emit("onPlaceFoods",this.MultipleFoods)
            }
        },
        /**菜品点击 */
        onDescClick(item){
            this.MultipleFoods = item
            this.FoodID = item.Food_ID
        },
        /**去除重复 */
        unique(newarr) {
            const res = new Map();
            return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
        }
    }
}
</script>

<style lang="scss">
@import "./changeUnitModel.scss";
</style>