import {webBrowser} from '../common'

let mqEvent={}
let isStart=false;
/**开启推送监听 */
function start(){
    if(!isStart){
        isStart=true;
        console.log('推送监听开启');
        webBrowser.on("mqCloudPosData",(data)=>{
            if(data && mqEvent[data.DataType]){//推送数据的类型
                if(!mqEvent[data.DataType].pushData){mqEvent[data.DataType].pushData=[];}
                mqEvent[data.DataType].pushData.push(data);//推送过来的数据缓存
                consumerData(mqEvent[data.DataType]);
            }
        });
    }
}

//推送数据是否已处理完
let isConsumer=false;
function consumerData(eventData){
    if(eventData.data){
        if(!isConsumer){
            isConsumer=true;
            /**循环去消费 推送过来的数据 */
            for(let i=0;i<eventData.pushData.length;i++){
                let data=eventData.pushData[i];
                if(eventData.data){//是否存在数据
                    if(typeof(eventData.diffFun)=="function"){
                        try {//比较数据
                            if(eventData.diffFun(data,data.DataInfo)===false){
                                //当前数据与推送数据中间 有遗漏 重新加载数据
                                eventData.loadFun().then((d)=>{
                                    eventData.data=d;
                                    if(typeof(eventData.thenFun)=="function"){
                                        eventData.thenFun(eventData.data);
                                    }
                                    //处理完一条删除一条
                                    eventData.pushData.splice(i,1);
                                    isConsumer=false;
                                    //加载 到数据后在去消费数据
                                    consumerData(eventData);
                                }).catch(()=>{
                                    //如果重新加载数据报错  等待有新的数据来时在去消费
                                    isConsumer=false;
                                    if(eventData.pushData.length>1){
                                        consumerData(eventData);
                                    }
                                });
                                return;
                            }
                        } catch (error) {
                            console.log(error);
                        }
                        //处理完一条删除一条
                        eventData.pushData.splice(i,1);
                        i--;
                    }
                }
            }
            isConsumer=false;
        }
    }else{
        eventData.pushData=[];
    }
}
/**关闭推送监听 */
function close(){
    if(isStart){
        console.log('推送监听关闭');
        isStart=false;
        mqEvent={};
        webBrowser.off("mqCloudPosData");
    }
}

/**
 * 
 * @param {*} loadFun 加载数据的方法
 * @param {*} type mq 推送数据的类型
 * @param {*} diffFun 比较数据 返回false 表示需要重新获取数据
 * @param {*} thenFun 获取得到数据时调用
 * @param {*} isRefresh 是否重新获取数据
 * @returns 
 */
 function mqCloudPosData(loadFun,type,diffFun,thenFun,isRefresh){
    if(!mqEvent[type]){
        mqEvent[type]={};
    }
    if(isRefresh===true){
        mqEvent[type].data=undefined;
    }
    mqEvent[type].diffFun=diffFun;
    mqEvent[type].loadFun=loadFun;
    mqEvent[type].thenFun=thenFun;
    if(mqEvent[type] && mqEvent[type].data){//判断 是否有数据
        if(typeof(thenFun)=="function"){
            thenFun(mqEvent[type].data);
        }
    }else if(typeof(loadFun)=="function"){
        loadFun()?.then((d)=>{
            mqEvent[type].data=d;//缓存数据
            if(typeof(thenFun)=="function"){
                thenFun(mqEvent[type].data);
            }
        })
    }
    return mqEvent[type];
}


export default {
    start,
    close,
    mqCloudPosData
}