<template>
    <modal class="updater" :isShow="isShow">
        <div class="header">
            系统升级
        </div>
        <div class="modal-body">
            <div class="image"><img src="/images/updater.png"/></div>
            <div class="title">发现新版本<span class="version">{{Version_No}}</span></div>
            <div class="scroll-box" v-html="remarks"></div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()" v-if="!isForcedUpdate">暂不升级</button>
            <button class="btn btn-confirm" @click="confirm()">立即升级</button>
        </div>
    </modal>
</template>

<script>
export default {
    name:"updater",
    emits:["closeModel","confirm"],
    props:{
        isShow:Boolean,
        isForcedUpdate:Boolean,
        data:{
            default:{}
        }
    },
    data(){
        return {
            remarks:"",
            //版本
            Version_No:""
        }
    },
    watch: {
        data:{
            handler(newVal) {
                this.remarks=newVal.Upgrade_Note?.replaceAll("\\r\\n","<br/>");
                this.Version_No=newVal.Version_No;
            },
            deep: true,
            immediate:true
        }
    },
    mounted(){
    }, 
    methods:{
        confirm(){
            this.$webBrowser.updater(this.data?.File_Address,this.data?.Upgrade_LotID).then((d)=>{
                if(d?.state!=0){
                    this.$message.error('升级失败:'+(d?.message||""));
                }
            });
            if(this.$webBrowser.isMobile.Android){//安卓设备
                this.$httpAES.post("Bestech.CloudPos.DeviceUpgrade",{
                    Upgrade_LotID:this.data?.Upgrade_LotID
                })
            }
        },
        hide(){
            this.$emit("closeModel")
        }
    }
}
</script>

<style lang="sass">
@import "./updater.scss";
</style>