import Cookie from 'js-cookie'
import {
	aesUtil,webBrowser
} from '../common'
import router from '../router.js'
import cacheData from './cacheData'
import mqTTClient from './mqTTClient'

let key = '6A6F7C8F712C46CCA2D2FAE240F96D9D';
let userInfo;

function getUserInfo() {
	if (!userInfo) {
		try {
			let data = localStorage.getItem("userInfo");
			if(data){
				userInfo = JSON.parse(aesUtil.decrypt(data, key));
			}
		} catch (error) {
			localStorage.setItem("userInfo",'');
			console.log(error);
		}
	}
	return userInfo;
}

//验证用户是否登陆用
export default {
	//登陆
	login(data) {
		//与上次登录比较 不是同一人时清除缓存
		if(this.getUserID()!=data.id){
			cacheData.initCache();
		}
		mqTTClient.close();
		mqTTClient.start();
		userInfo = data;
		userInfo._time=(new Date()).getTime();//登录时间
		localStorage.setItem('userInfo', aesUtil.encrypt(JSON.stringify(userInfo), key));

		cacheData.DeskPosconfigure().then((d)=>{
			let PosGuestScreen={};
            if(d){
                PosGuestScreen.PosGuestScreen_LoopImages=d.PosGuestScreen_LoopImages;//POS副屏轮播图
				PosGuestScreen.PosGuestScreenNotShowingBillDetail=d.PosGuestScreenNotShowingBillDetail;//关闭POS副屏显示账单明细
				PosGuestScreen.PosGuestScreen_LoopInterval=d.PosGuestScreen_LoopInterval;//POS副屏轮播间隔，单位：秒
            }
			webBrowser.openAuxiliaryScreen({
				PosGuestScreen
			});//显示副屏
        }).catch(e=>{
            console.log("基本配置获取失败："+e.message);
			webBrowser.openAuxiliaryScreen();//显示副屏
        })
	},
	//退出登陆
	logout() {
		return router.push('/login').then((d)=>{
			userInfo = undefined;
			localStorage.setItem('userInfo', '');
			mqTTClient.close();
			webBrowser.mqStop();//关闭推送服务
			webBrowser.openAuxiliaryScreen();//显示副屏
		});
	},
	//判断登陆状态
	isLoggedIn() {
		let info = getUserInfo();
		if (info && info.id) { //判断用户信息是否存在
			return true;
		}
		return false;
	},
	//获取用户信息
	getUserInfo,
	getUserID(){
		let info = getUserInfo();
		if(info){
			return info.id;
		}
	},
	/**修改 营业日期*/
	setRpt_Date(Rpt_Date){
		let info = getUserInfo();
		if(info && info.Rpt_Date!=Rpt_Date){
			info.Rpt_Date=Rpt_Date;
			sessionStorage.setItem('userInfo', aesUtil.encrypt(JSON.stringify(info), key));
		}
	},
	/**修改门店数据 */
	setDepartment(data){
		let info = getUserInfo();
		if(info){
			info.Department_Code=data.Department_Code,//机构编号
			info.Department_Name=data.Department_Name,//机构名称
			sessionStorage.setItem('userInfo', aesUtil.encrypt(JSON.stringify(info), key));
		}
	}
}
