<template>
    <modal :isShow="isShow" ref="modal" class="requestWaitModal" @close="handleClose">
        <div class="modal-body">
            <div class="title">正在处理中,请勿操作耐心等待</div>
            <div class="timer" v-show="timerCount>0">
                <i class="iconfont icon-shijian"></i><span class="timer-shijian">{{timerCount}}秒</span>
            </div>
            <div class="tip-box">
                {{title}}
            </div>
        </div>
    </modal>
</template>

<script>
import modal from '../modal/modal.vue'
export default {
    emits:["close","success"],
    components:{
        modal
    },
    props:{
        //提示信息
        errorTitle:{
            type:String,
            default:""
        }
    },
    watch:{
        isShow(){
            console.log(this.isShow)
        }
    },
    data(){
        return {
            title:this.errorTitle,
            isShow:false,
            //倒计时
            timerCount:60,
            outTime:null
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.startCountDown();
        })
    },
    methods:{
        /**开始倒计时 */
        startCountDown(){
            if(this.outTime) clearInterval(this.outTime);
            this.outTime=setInterval(()=>{
                this.timerCount--;
                if(this.timerCount<=0){
                    clearInterval(this.outTime);
                    this.$emit("success");
                    return;
                }
            },1000)
        },
        handleClose(){//关闭动画完成
            this.$emit("close");
        },
        show(){
            this.isShow=true;
        },
        hide(){
            this.isShow=false;
            this.$refs.modal.close();
        }
    },
    //被卸载时
    unmounted(){
        if(this.outTime) clearInterval(this.outTime);
    }
}
</script>

<style lang="scss">
.requestWaitModal{
    z-index: 10000;
    background-color:transparent;
    .modal-dialog{ 
        width: 600px;
        max-width: 600px;
       
        .modal-content{
            right: inherit;
            width: 100%;
            border-radius: 8px;
            padding-bottom: 10px;
        }
    }

    .modal-body{
        padding: 10px;

        .title{
            font-size: 24px;
            color: #000;
        }

        .timer{
            font-size: 25px;
            .timer-shijian{
                padding-left: 10px;
                color: red;
                font-weight: bold;
            }
        }

        .tip-box{
            margin-top: 10px;
            font-size: 24px;
            color: #F56464;
            letter-spacing: 0.75px;
            text-align: justify;
            font-weight: 400;
        }
    }
}
</style>