<template>
  <div v-layout-rc='layoutRc'>
      <slot v-for="(item,index) in list" :key="item" :item="item" :index="index"></slot>
      <div class="clearfix" ref="foot"></div>
  </div>
</template>

<script>
export default {
    name:'for-each',
    props:{
        data:{
            type:Array
        },
        minLength:{
            type:Number,
            default:100
        },
        layoutRc:{
            row:3,
            col:6,
            marginW:10,
            marginH:10
        },
        scroll:Object
    },
    data(){
        return {
            list:[],
            setT:undefined,
            pageIndex:0
        }
    },
    watch:{
        data(){
            this.initList();
        },
        scroll(){
            if(this.scroll){
                this.scroll.addEventListener('scroll',(e)=>{
                    this.changeHeight(e.target.scrollTop);
                })
            }
        }
    },
    mounted(){
        this.initList();
        if(this.scroll){
            this.scroll.addEventListener('scroll',(e)=>{
                this.changeHeight(e.target.scrollTop);
            })
        }
        
    },
    methods:{
        initList(){
            if(this.data && this.data.length>this.minLength){
                let row=this.layoutRc.row + 4;
                let col=this.layoutRc.col;
                this.list=this.data.slice(0,row*col);
                if(this.setT){ clearTimeout(this.setT); }
                this.setT=setTimeout(()=>{
                     //this.list=this.data;
                    this.changeHeight(this.scroll?.scrollTop||0);
                },100);
            }else{
                this.list=this.data;
                this.$refs.head.style.height='0';
                this.$refs.foot.style.height='0';
            }
            
        },
        changeHeight(scrollTop){
            if(this.data && this.data.length>this.minLength){
                let li=this.$el.querySelector('div[rc-item]');
                if(li){
                    let height=li.getBoundingClientRect().height+ parseFloat((this.layoutRc.marginH||0));
                    let col=this.layoutRc.col;
                    let trNum=Math.ceil(this.data.length/col);
                    let row=this.layoutRc.row;
                    let topNum= parseInt(scrollTop/height)-row;
                    topNum=topNum<0?0:topNum;
                    let bottomNum=trNum-(Math.ceil(scrollTop/height)+(row*2));
                    this.$refs.head.style.height=(topNum*height)+'px';
                    this.list=this.data.slice(topNum*col,(trNum-topNum-bottomNum)*col);
                    this.$refs.foot.style.height=(bottomNum*height)+'px';
                }
                
            }else{
                this.$refs.head.style.height='0';
                this.$refs.foot.style.height='0';
            }
        }
    }
}
</script>

<style>

</style>