<template>
    <modal class="orderStatusModal" :isShow="isShow">
        <div class="modal-body">
            <template v-if="status">
                <div class="bg"><img src="/images/orderSuccess.png"/></div>
                <div class="title">扫码成功，请取票~</div>
            </template>
            <template v-else>
                <div class="bg"><img src="/images/orderAbnormal.png"/></div>
                <div class="title">操作异常</div>
                <div class="message">{{message}}</div>
            </template>
        </div>
        <div class="footer-box">
            <div class="bnt" @click="close()">关闭（{{countdown}}s）</div>
        </div>
    </modal>
</template>

<script>
export default {
    name:'orderStatusModal',
    emits:["close"],
    props:{
        isShow:Boolean,
        /**状态 true 成功 false  失败 */
        status:{
            type:Boolean,
            default:true
        },
        /**错误提示文字 */
        message:{
            type:String,
            default:""
        },
        /**倒计时 多少秒 */
        timeNum:{
            type:Number,
            default:3
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.init();
            }else{
                if(this._time){
                    clearTimeout(this._time);
                }
            }
        }
    },
    data(){
        return {
            //倒计时
            countdown:3
        }
    },
    mounted(){
        if(this.isShow){
            this.init();
        }
    },
    methods:{
        /**关闭 */
        close(){
            this.$emit("close");
        },
        init(){
            this.countdown=this.timeNum;
            this.timing();
        },
        timing(){
            if(this._time){
                clearTimeout(this._time);
            }
            if(this.countdown<=0){
                this.close();
                return;
            }
            this._time=setTimeout(()=>{
                this.countdown--;
                if(this.countdown<=0){
                    this.close();
                }
                this.timing();
            },1000);
        }
    },
    unmounted(){
        if(this._time){
            clearTimeout(this._time);
        }
    }
}
</script>

<style lang="scss">
	@import './orderStatusModal.scss'
</style>