<template>
    <div class="maxFoodDisplay">
        <div class="title">{{foodCategory?.SubCategory_Name}}</div>
        <div class="flex-scale-box" v-show="isScale">
            <div class="scale-box" v-touch="slideX">
                <div class="food-box">
                    <div class="li" v-for="{item,index} in scaleFoods" :key="item" :style="tXArr[index-scaleIndex+2]" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                        <i class="close-bnt iconfont icon-a-bg-close3x" @click="close(index)" v-if="index==scaleIndex"></i>
                        <div class="picture">
                            <el-image :src="item.Food_SmallImage" fit="fill">
                                <template #placeholder>
                                    <div class="image-slot verticalCenter">Loading<span class="dot">...</span></div>
                                </template>
                                <template #error>
                                    <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png)'}"></div>
                                </template>
                            </el-image>
                        </div>
                        <div class="text-box">
                            <div class="displayFlex">
                                <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}</div>
                                <div class="tag-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}{{item.Food_Size}}</div>
                            </div>
                            <div class="money-box">
                                <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                    <span class="price"><span class="unit">￥</span>{{item.Food_Price}}</span><span class="unit">/{{item.Food_Size}}</span>
                                </div>
                                <div class="bnt-box" v-if="item.Is_MultipleSize">
                                    <div class="nultiUnit-bnt" @click="addItem(item)">选规格<span class="num" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</span></div>
                                </div>
                                <div class="bnt-box" v-else>
                                    <div class="remove" @click="removeItem(item)" :class="{disabled:!(cartNumber[item.Food_ID]?.Eat_Number>1)}" v-if="cartNumber[item.Food_ID]?.Eat_Number>0"><i class="iconfont icon-jian"></i></div>
                                    <div class="number" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</div>
                                    <div class="add" @click="addItem(item)"><i class="iconfont icon-jia"></i></div>
                                </div>
                            </div>
                            <div class="proMoney" v-if="item.Is_Promotion">
                                <span class="tag">促</span>
                                <span class="unit">￥</span>
                                <span>{{item.Promotion_Price}}</span>
                            </div>
                            <!-- vip 价格 -->
                            <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                <div class="vipTag">VIP</div>
                                <div class="price"> 
                                    <div class="unit">￥</div>{{item.Food_VipPrice}}
                                </div>
                            </div>
                            <div class="sellout"><img src="/images/soldout.png"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="indicators-box"  v-mouse-scroll>
                <div class="li" :class="{selected:scaleIndex==item-1}" v-for="item in this.foodCategory?.PosFoods?.length" :key="item" @click="goScale(item)"></div>
            </div>
        </div>
        <div class="noScale-box" v-show="!isScale">
            <div class="flex-box" v-dom-resize @resize="resize()" :style="style" >
                <el-scrollbar class="scroll-box"  max-height="100%"  ref="scrollBox" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                    <div class="food-box" :style="{width:scrollW,paddingLeft:paddingLeft+'px'}">
                        <div class="li" v-for="(item,index) in pagePosFoods" :key="index" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                            <div class="picture" @click="clickScale(index+pagePosIndex)">
                                <el-image :src="item.Food_SmallImage" fit="fill">
                                    <template #placeholder>
                                        <div class="image-slot verticalCenter">Loading<span class="dot">...</span></div>
                                    </template>
                                    <template #error>
                                        <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png)'}"></div>
                                    </template>
                                </el-image>
                            </div>
                            <div class="text-box">
                                <div class="displayFlex">
                                    <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}</div>
                                    <div class="tag-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}{{item.Food_Size}}</div>
                                </div>
                                <div class="money-box">
                                    <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                        <span class="price"><span class="unit">￥</span>{{item.Food_Price}}</span><span class="unit">/{{item.Food_Size}}</span>
                                    </div>
                                    <div class="bnt-box" v-if="item.Is_MultipleSize">
                                        <div class="nultiUnit-bnt" @click="addItem(item)">选规格 <span class="num" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</span></div>
                                    </div>
                                    <div class="bnt-box" v-else>
                                        <div class="remove" @click="removeItem(item)" :class="{disabled:!(cartNumber[item.Food_ID]?.Eat_Number>1)}" v-if="cartNumber[item.Food_ID]?.Eat_Number>0"><i class="iconfont icon-jian"></i></div>
                                        <div class="number" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</div>
                                        <div class="add" @click="addItem(item)"><i class="iconfont icon-jia"></i></div>
                                    </div>
                                </div>
                                <div class="proMoney" v-if="item.Is_Promotion">
                                    <span class="tag">促</span>
                                    <span class="unit">￥</span>
                                    <span>{{item.Promotion_Price}}</span>
                                </div>
                                <!-- vip 价格 -->
                                <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                    <div class="vipTag">VIP</div>
                                    <div class="price"> 
                                        <div class="unit">￥</div>{{item.Food_VipPrice}}
                                    </div>
                                </div>
                                <div class="sellout"><img src="/images/soldout.png"></div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="indicators-box"  v-mouse-scroll>
                <div class="li" :class="{selected:pageIndex==item}" v-for="item in pageCount" :key="item" @click="goLeft(item)"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"maxFoodDisplay",
    emits:["currentChange","removeItem","addItem"],
    props:{
        /**菜品 */
        foodCategory:{
            type:Object,
            default:null
        },
        /**购物车 菜品 数量 */
        cartNumber:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            scrollBox:null,
            style:{},
            liW:545,
            pageIndex:1,
            scrollLeft:0,
            isScale:false,
            scaleStyle:{},
            scaleIndex:0,
            tXArr:[
                {transform:"translateX(-140%) scale(0.7)"},
                {transform:"translateX(-105%) scale(0.8)"},
                {transform:"translateX(0) translateY(-20px) scale(1.05)", zIndex: 1},
                {transform:"translateX(105%) scale(0.8)"},
                {transform:"translateX(140%) scale(0.7)"},
            ]
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        /**页数 */
        pageCount(){
            if(this.foodCategory?.PosFoods?.length>2){
                return this.foodCategory?.PosFoods.length-1;
            }
            return 1;
        },
        scaleFoods(){
            let data=[];
            if(this.foodCategory?.PosFoods?.length>1){
                let end=this.scaleIndex+3;
                let start=Math.max(this.scaleIndex-2,0);
                data=this.foodCategory?.PosFoods.slice(start,end).map((item,index)=>{
                    return {item,index:index+start}
                });
                return data;
            }
            return (this.foodCategory?.PosFoods||[]).map((item,index)=>{
                    return {item,index}
                });
        },
        /**总共 宽度 */
        scrollW(){
            if(this.foodCategory?.PosFoods?.length>0){
                return (this.foodCategory.PosFoods.length*this.liW)+"px";
            }
            return "100%";
        },
        /**分页 开始位置 */
        pagePosIndex(){
            let index=parseInt(this.scrollLeft/this.liW)-2;
            if(index>0){
                return index;
            }
            return 0;
        },
        /**左边看不见的宽度 */
        paddingLeft(){
            return this.pagePosIndex*this.liW;
        },
        /**可见的 数据 */
        pagePosFoods(){
            if(this.foodCategory?.PosFoods.length>0){
                return this.foodCategory?.PosFoods?.slice(this.pagePosIndex,this.pagePosIndex+6);
            }else{
                return [];
            }
        }
    },
    watch:{
        scrollLeft(){
            let scrollbar=this.$refs.scrollBox.scrollbar;
            if(scrollbar){
                let width= scrollbar.offsetWidth/2;
                this.pageIndex=(parseInt(this.scrollLeft/width)+1)||1;
            }
        },
        foodCategory(){
            this.pageIndex=1;
            this.scrollLeft=0;
            this.isScale=false;
            this.scaleIndex=0;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.resize();
            if(this.$refs.scrollBox.wrap){
                this.$refs.scrollBox.wrap.addEventListener("scroll",this.scrollX)
            }
        })
    },
    methods:{
        /**计算宽高 */
        resize(){
            console.log(this.$refs.scrollBox)
            if(this.$refs.scrollBox?.scrollbar){
                let scrollbar=this.$refs.scrollBox?.scrollbar;
                let width= scrollbar.offsetWidth;
                let w=(width/2-20);
                if(w>0){
                    this.liW=width/2;
                    this.style["--liW"]=w+"px";
                }

                if(width>0){
                    this.scaleStyle["--liW"]=width*0.6;
                }
            }
        },
        scrollX(e){
            this.scrollLeft=e.srcElement.scrollLeft;
        },
        goLeft(index){
            if(this.pageCount<index){
                index=this.pageCount;
            }
            this.pageIndex=index;

            this.$nextTick(()=>{
                let scrollbar=this.$refs.scrollBox?.scrollbar;
                if(scrollbar){
                    let width= scrollbar.offsetWidth/2;
                    this.$refs.scrollBox.wrap.scrollLeft=width*(index-1);
                }
            })
            
        },
        goScale(index){
            this.scaleIndex=index-1;
        },
        /**点击图片 方法 */
        clickScale(index){
            this.isScale=true;
            this.goScale(index+1);
        },
        /**关闭放大轮播 */
        close(index){
            this.isScale=false;
            this.goLeft(index+1);
        },
        /**左右滑动 */
        slideX(e,fx){
            if(fx=="right" && this.scaleIndex>0){
                this.scaleIndex--
            }else if(fx=="left" && this.scaleIndex<this.foodCategory?.PosFoods?.length-1){
                this.scaleIndex++
            }
            
        },
        removeItem(item){
            this.$emit("removeItem",item)
        },
        addItem(item){
            this.$emit("addItem",item)
        }
    }
}
</script>

<style lang="scss">
    @import './maxFoodDisplay.scss'
</style>