<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
//用来统计 多个 modal 弹层 是否有显示 或 全部关闭
export default {
    name: 'modalList',
    emits:["modalShow","modalClose"],
    data(){
        return {
            list:[]
        }
    },
    computed:{
        modalShow(){//存在弹层有显示的
            return this.list?.some(it=>it.isShow);
        }
    },
    watch:{
        modalShow(){
            this.modalInit();
        }
    },
    methods:{
        modalInit(){
            if(this._time){
                clearTimeout(this._time);
            }
            this._time=setTimeout(()=>{
                if(this.modalShow){
                    this.$emit("modalShow");
                }else{
                    this.$emit("modalClose");
                }
            },100);
        },
        add(modal){
            this.list.push(modal);
        },
        remove(modal){
            let index = this.list.findIndex(it=>it.modalId==modal.modalId);
            if(index > -1){
                this.list.splice(index, 1);
            }
        },
    },
    unmounted(){
        if(this._time){
            clearTimeout(this._time);
        }
    }
}
</script>

<style>

</style>