<template> 
    <div class="modal fade"  :class="showClass" :style="showStyle" @mousedown="mousedown" @keyup="keyup" @focusout="focusout">
        <div ref="tab" tabindex="-1"></div>
        <div class="modal-dialog" @click="(e)=>{if(isMask)e.stopPropagation()}">
            <div class="modal-content">
                <slot v-if="(isIfShow && showStyle!='') || !isIfShow"></slot>
            </div>
        </div>
    </div>
</template>

<script>
let modalId=1;
export default {
    name: 'modal',
    emits:["close","keyup"],
    data() {
        let id="modal_"+(modalId++);
        return {
            showClass:"",
            showStyle:"",
            modalData:{
                modalId:id,
                isShow:this.isShow
            }
        }
    },
    props:{
        isShow:Boolean,
        isMask:{
            default:false,
            type:Boolean
        },
        isIfShow:{//是否允许 每次显示弹层时重新渲染dom
            default:false,
            type:Boolean
        }
    },
    mounted(){ 
        this.modalData.isShow=this.isShow;
        let parent=this.$parent?.$parent
        if(parent?.$?.type?.name=="modalLoad"){
            parent=parent.$parent;
        }
        if(parent?.$?.type?.name=="modalList"){
            parent.add(this.modalData);//弹层显示了
        }
        this.init();
    },
    watch:{
        isShow() {
            this.modalData.isShow=this.isShow;
            this.init();
        }
    },
    methods:{
        init(){
            if(this.isShow) {
                this.showStyle="display:block";
                if(this.time){
                    clearTimeout(this.time);
                }
                this.time= setTimeout(()=>{ this.showClass="show";},100);
                this.$nextTick(()=>{
                    this.$refs.tab.focus();
                })
            }else{
                this.showClass="";
                if(this.time){
                    clearTimeout(this.time);
                }
                this.time=setTimeout(()=>{
                    this.showStyle="";
                    this.$emit("close");
                    this.afterCloseFocus();
                },150);
            }
        },
        close(){
            this.showClass="";
            if(this.time){
                clearTimeout(this.time);
            }
            this.time=setTimeout(()=>{
                this.showStyle="";
                this.$emit("close");
                this.afterCloseFocus();
            },150);
        },
        show(){
            
        },
        mousedown(e){
            if(!(e.target.nodeName=="INPUT" || e.target.nodeName=="TEXTAREA")){//只有不是 输入框就 阻止默认事件
                e.preventDefault();
            }
        },
        keyup(e){
            if(e.key=="Escape"){
                if(!this.isTopLayer()){
                    return;
                }
            }
            this.$emit("keyup",e);
        },
        //是否在最上层
        isTopLayer(){
            let target=this.$el;
            if(!(target && target.getBoundingClientRect)){
                return false;
            }
            const rect = target.getBoundingClientRect();
            if(rect.width==0 || rect.height==0){//没显示
                return false;
            }
            const centerX = rect.left + rect.width / 2; 
            const centerY = rect.top + rect.height / 2; 
            let divs= Array.from(document.elementsFromPoint(centerX,centerY)||[]);//当前位置有的所有元素

            for(let i=0;i<divs.length;i++){
                let element=divs[i];
                let classList=element.classList;
                if(element === target || element.contains(target)){
                    return true;
                }else if(classList.contains("modal") || (classList.contains("el-loading-mask") && classList.contains("is-fullscreen")) || classList.contains("el-overlay")){
                    //在当前输入层上方 有loding 或 modal 弹层 以及 有确认 取消 提示框 时  
                    return false;
                }
            }
            return true;
        },
        //弹层关闭、隐藏 或 卸载 后,焦点位置默认设置到最上层的地方去
        afterCloseFocus(){
            const rect = document.body.getBoundingClientRect(); 
            const centerX = rect.left + rect.width / 2; 
            const centerY = rect.top + rect.height / 2; 
            let divs= Array.from(document.elementsFromPoint(centerX,centerY)||[]);//当前位置有的所有元素
            let input=document.activeElement;//当前焦点位置
            for(let i=0;i<divs.length;i++){
                let element=divs[i];
                let classList=element.classList;
                if(classList.contains("modal") && element!=this.$el){//找到最上层弹层
                    if(!(element === input || element.contains(input))){//如果焦点不在最上层弹层上
                        let tab=element.querySelector("div[tabindex='-1']");//变更焦点到最上层弹层
                        if(tab){
                            tab.focus();
                        }
                    }
                    break;
                }
            }
        },
        //焦点失去时
        focusout(){
            setTimeout(()=>{//失去焦点必然会有获取焦点，用settimeout在失去焦点后去做判断
                if(this.isShow && this.isTopLayer()){//并且元素是显示的 并且当前弹层在最上层
                    let input=document.activeElement;//当前焦点位置
                    if(!this.$el.contains(input)){//当前焦点不在当前弹层内
                        this.$refs.tab.focus();
                    }
                }
            },0);
        }
    },
    unmounted(){
        if(this.time){
            clearTimeout(this.time);
        }
        this.afterCloseFocus();

        let parent=this.$parent?.$parent;
        if(parent?.$?.type?.name=="modalLoad"){
            parent=parent.$parent;
        }
        if(parent?.$?.type?.name=="modalList"){
            parent.remove(this.modalData);//弹层显示了
        }
    }
}
</script>

<style>

</style>