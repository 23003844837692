<template>
<modal class="unionBillModel" :isShow="unionisShow" @keyup.esc="CloseClicks">
        <div class="Popup-Title">
          <span class="Popup-Title-left">联台结账</span>
           <div class="float-left search">
            <input v-board type="text" placeholder="桌号检索" v-model="DeskAutoSelce"  />
          </div>
        </div>
        <div class="filter-nav clearfix">
            <div class="nav-box"  v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage'}">
                <div class="nav-li" :class="{ selected: filternav == -1 }" @click="NavigationClick(-1)" >全部</div>
                <div class="nav-li" :class="{ selected: filternav ==index }" v-for="(item,index) in unPosRooms" :key="item"  @click="NavigationClick(index)" >
                    {{item.Room_Name}}
                </div>
            </div>
            <div class="float-right page-box">
                <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
            </div>
        </div>
        <div class="desk content clearfix" style="width: 100%;height: 40%;overflow: auto;">
            <div v-if="getDeskList">
                <div><!--v-show="!item.OpenDeskInfo.Eat_UnionID"-->
                    <div class="desk-box opening" :class="{'empty': item.Desk_Status == 0,'preKnot':item.Desk_Status==3}" v-for="item in getDeskList" :key="item" @click="DeskMergeClick(item,1)"  >
                        <div class="top" v-if="item.OpenDeskInfo" >
                            <span>{{item.OpenDeskInfo.Person_Num}}人</span>
                            <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span> 
                        </div>
                        <p class="title">{{item.Desk_Name}}</p>
                        <div class="bottom">
                        <span v-if="item.OpenDeskInfo">{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> 
                        <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_CheckMoney}}</span>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="Popup-InterTxt">
            <span>如下台号将联台结账,请确认桌台号</span>
        </div>
         <div class="desk content clearfix" style="width: 100%;height: 20%;overflow: auto;">
            <div class="desk-box opening" :class="{'empty': item.Desk_Status == 0,'preKnot':item.Desk_Status==3}" v-for="(item,index) in posDeskList" :key="item" @click="RevokeDeskChangeClick(item,index)">
                <div class="top" v-if="item.OpenDeskInfo" >
                    <span>{{item.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                    <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
                </div>
                <p class="title">{{item.Desk_Name}}</p>
                <div class="bottom">
                  <span v-if="item.OpenDeskInfo" >{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                  <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_CheckMoney}}</span> <!--点餐总金额-->
                </div>
            </div>
        </div>
        <div class="Popup-Bsic-bottom">
            <button class="Popup-Basic-left-bottom" @click="CloseClicks()" >取消</button>
            <button class="Popup-Basic-left-bottom" @click="RemoveClick()" >解除联台</button>
            <button class="Popup-Basic-right-bottom" @click="SetDeskClick()">确定</button>
        </div>
</modal>
</template>

<script>
import modal from "../../../template/modal/modal";
import { httpAES,cacheData } from '/src/services'
import { newGuid,differenceTime } from '/src/common' 
import { ElMessage } from "element-plus"

/**全局初始化数据 */
let _data={
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
};

export default {
    name: 'merging',
    components:{
        modal
    },
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
        }
        return {
             /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            filternav:-1,
            DeskAutoSelce:'', //查询条件栏
            showPosDeskList:[],//显示桌台数据
            posDeskList:[], //
            posDeskAutoIDList:[],
            TempList:[], //临时数组
            DeskStatus:1, //桌台状态，0 未开台 1 已开台
            unPosRooms:'',
            MapConsumeMoney:false
        }
    },
    props:['unionisShow','orderInfo','BillType','posRooms'],
    mounted(){
        this.$nextTick(()=> {
            this.loadunion()
        })
    },
     watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        unionisShow() { 
            this.loadunion()
        }
    },
    methods:{
        loadunion(){
            if(this.unionisShow==true){
                this.posDeskList=[];
                this.posDeskAutoIDList=[]
                if(this.posRooms){
                    this.unPosRooms = this.posRooms
                }else{
                    this.unPosRooms = this.$global.PosRooms
                }
                this.MapConsumeMoney=_data.RunCloudPosHideDeskMapConsumeMoney
                if(this.BillType==1){
                    this.unPosRooms.map(pos=>{
                        pos.PosDesks.map(desk=>{
                            if(desk.Desk_AutoID==this.orderInfo.Eat_DeskID){
                                 this.posDeskList.push(desk);
                            }
                            if(this.orderInfo.UnionOrderInfoList){
                                this.orderInfo.UnionOrderInfoList.map(union=>{
                                    if(desk.Desk_AutoID==union.Eat_DeskID){
                                        this.posDeskList.push(desk);
                                    }
                                })
                            }
                        })
                    })
                }
                
                this.NavigationClick(-1);
            }
        },
        //餐厅名称导航条切换
        NavigationClick(index) {
            //清空查询条件栏
            if(this.DeskAutoSelce.length>0){
                this.DeskAutoSelce='';
            }
            this.filternav = index;
            if(index==-1){
                this.showPosDeskList=this.unPosRooms._allDesk;
                
            }else{
                this.showPosDeskList=this.unPosRooms[index].PosDesks;
            }
        },
        //桌号检索
        DeskTextClick() {
            this.filternav=-1;
        },
         //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            return differenceTime(beginTime);
        },  
       
        //关闭弹出框
        CloseClicks(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel");
        },
        //解除联台
        RemoveClick(){
            let Desk_Name=''
            let unionID=''
            if(this.posDeskList.length==0){
                this.$message.warning('请先选择需要解除联台的桌台');
                return
            }
            this.posDeskList.map(pos=>{
                if(pos.OpenDeskInfo.Eat_UnionID){
                    Desk_Name += pos.Desk_Name+','
                    unionID=pos.OpenDeskInfo.Eat_UnionID
                }
            })
            if(!unionID){
                this.$message.warning('选择的桌台没有联台,不需要解除');
                return
            }
            Desk_Name.length-1
            this.$confirm('是否确认解除联台'+Desk_Name.substring(0,Desk_Name.lastIndexOf(','))+'', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
            .then(() => {
                let userInfo=this.$auth.getUserInfo(); //获取用户id
                let param ={
                    Body_ID:newGuid(),
                    User_ID:userInfo.User_ID,
                    Eat_UnionAutoid:unionID, //解除联台的联台ID
                    Operator_Name:userInfo?.Login_Name
                }
                const loading = this.$loading({
                    text: "数据传输中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                httpAES.post("Bestech.CloudPos.CancelUnionDesk", param).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode!=0){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        return;
                    }else {
                        ElMessage.success({ message: "解除联台完成"});
                        this.posDeskList=[]
                        this.$emit("CloseunionClick",d); //关闭并台弹窗
                        return;
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                });
            }).catch(() => {
                this.$message.error('解除联台取消');        
            });
        },
        //提交并台数据
        SetDeskClick(){
            //获得选择的数据
            if(this.posDeskList.length<=1){
                this.$message.warning('请选择需要联台的桌台,联台桌台数不能少于2台');
                return
            }
            let Desk_Name=''
            let typeUnionIdNull=0
            let unionID=''
            let posDeskList=[]
            this.posDeskList.map(pos=>{
                Desk_Name += pos.Desk_Name+','
                if(pos?.OpenDeskInfo.Eat_UnionID){
                    typeUnionIdNull=1
                    unionID=pos?.OpenDeskInfo.Eat_UnionID
                }
                posDeskList.push(pos?.Desk_AutoID)
            })
            console.log('posDeskList:'+JSON.stringify(posDeskList))
            Desk_Name.length-1
            if(typeUnionIdNull==0){
                this.$confirm('是否确认联台'+Desk_Name.substring(0,Desk_Name.lastIndexOf(','))+'', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                .then(() => {
                    let userInfo=this.$auth.getUserInfo(); //获取用户id
                    let param ={
                        Body_ID:newGuid(),
                        User_ID:userInfo.User_ID,
                        Desk_AutoIDList:posDeskList, //换往目标桌台的ID //posDeskAutoIDList
                        Operator_Name:userInfo?.Login_Name
                    }
                    const loading = this.$loading({
                        text: "数据传输中",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    if(sessionStorage.getItem("tempUserID")){
                        param.User_ID = sessionStorage.getItem("tempUserID")
                        param.Operator_Name = sessionStorage.getItem("tempName")
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    console.log('联台:'+JSON.stringify(param))
                    httpAES.post("Bestech.CloudPos.UnionDesk", param).then((d)=>{
                        loading.close();
                        console.log('联台返回d:'+JSON.stringify(d))
                        if(d.ResponseHeader.ResultCode!=0){
                            this.$message.error(d.ResponseHeader.ResultDesc);
                            return;
                        }else {
                            ElMessage.success({ message: "联台完成"});
                            this.posDeskList=[]
                            this.$emit("CloseunionClick",d); //关闭并台弹窗
                            return;
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    });
                }).catch(() => {
                    this.$message.error('联台取消');        
                });
            }
            if(typeUnionIdNull==1){
                this.$confirm('是否确认修改联台'+Desk_Name.substring(0,Desk_Name.lastIndexOf(','))+'', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                .then(() => {
                    let userInfo=this.$auth.getUserInfo(); //获取用户id
                    let param ={
                        Body_ID:newGuid(),
                        Eat_UnionAutoid:unionID,
                        Desk_AutoIDList:posDeskList, //换往目标桌台的ID
                        User_ID:userInfo.User_ID,
                        Operator_Name:userInfo?.Login_Name
                    }
                    const loading = this.$loading({
                        text: "数据传输中",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    console.log('param:'+JSON.stringify(param))
                    if(sessionStorage.getItem("tempUserID")){
                        param.User_ID = sessionStorage.getItem("tempUserID")
                        param.Operator_Name = sessionStorage.getItem("tempName")
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    httpAES.post("Bestech.CloudPos.ModifyUnionDesk", param).then((d)=>{
                        loading.close();
                        if(d.ResponseHeader.ResultCode!=0){
                            this.$message.error(d.ResponseHeader.ResultDesc);
                            return;
                        }else {
                            ElMessage.success({ message: "修改联台完成"});
                            this.posDeskList=[]
                            this.$emit("CloseunionClick",d); //关闭并台弹窗
                            return;
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    });
                }).catch(() => {
                    this.$message.error('修改联台失败');        
                });
            }
        },
        //获取点击的桌台数据,isdeta:从结账界面进入
        DeskMergeClick(desk,isdeta){
            if(this.posDeskList.findIndex(arr => arr.Desk_AutoID == desk.Desk_AutoID)==-1)
            {
                if(!desk.OpenDeskInfo.Eat_UnionID){
                    this.posDeskList.push(desk);
                }
            }
            if(this.posDeskAutoIDList.findIndex(arr => arr.Desk_AutoID == desk.Desk_AutoID)==-1){
                this.posDeskAutoIDList.push(desk.Desk_AutoID);
            }
            let uniondeskList = this.getDeskList
            if(desk?.OpenDeskInfo?.Eat_UnionID){
                let type=0
                this.posDeskList.some(pos=>{
                    if(pos.OpenDeskInfo.Eat_UnionID){
                        type=1
                    }
                })
                if(type==1 && isdeta!=2){
                    this.$message.error('选择的桌台中已存在联台的桌台,请先移除!');
                    return
                }
                uniondeskList.map(union=>{
                    if(desk.OpenDeskInfo.Eat_UnionID==union.OpenDeskInfo.Eat_AutoID || union.OpenDeskInfo.Eat_UnionID == desk.OpenDeskInfo.Eat_UnionID){
                        if(this.posDeskList.findIndex(arr => arr.OpenDeskInfo.Eat_AutoID == union.OpenDeskInfo.Eat_AutoID)==-1)
                        {
                            this.posDeskList.push(union);
                        }
                    }
                })
                let _tempdeskList=[]
                this.posDeskList.map((pos,index)=>{
                    if(!pos?.OpenDeskInfo.Eat_UnionID){
                        _tempdeskList.push(pos)
                        this.posDeskList.splice(index,1)
                    }
                })
                if(_tempdeskList){
                    _tempdeskList.map(temp=>{
                        this.posDeskList.push(temp)
                    })
                }
                let _tempitem=''
                this.posDeskList.some((pos,index)=>{
                    if(pos.OpenDeskInfo.Eat_AutoID==pos.OpenDeskInfo.Eat_UnionID && index!=0){
                        _tempitem = pos
                        this.posDeskList.splice(index,1)
                        this.posDeskList.unshift(_tempitem)
                        return true
                    }
                })
            }
            console.log('posDeskList:'+JSON.stringify(this.posDeskList))
        },
        //撤销选择的数据
        RevokeDeskChangeClick(desk,index){
            if(desk && index>=0){
                this.posDeskList.splice(index,1);
                this.posDeskAutoIDList.splice(index,1);
            }
        },
    },
    computed:{
        getDeskList(){
            let data=this.showPosDeskList.filter((item)=>{
                return item.Desk_Status=='1' || item.Desk_Status=='3' || item.Desk_Status=='4';
            });
            if(this.DeskAutoSelce!=''){
                let searchTxt=this.DeskAutoSelce.toLowerCase();
                return data.filter((item)=>{
                    return (item.Desk_Code||"").toLowerCase().indexOf(searchTxt)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(searchTxt)>=0;
                });
            }
            return data;
        },
    }
}

</script>

<style lang="scss">
@import "./unionBillModel.scss";
</style>