<template>
  <modal class="fastGPaymentModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">G9会员卡支付<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body" >
            <div ref="CardMoney">
            <div class="filter-nav">
                <div class="nav-box">
                    <tab-nav class="nav-li" @click="CardPayClick()">按会员卡号支付</tab-nav>
                </div>
            </div>
             <div></div>
            <div class="clearfix" >
                <div class="left-box">
                    <tab-pane>
                        <div class="tr-li">
                            请输入付款码：
                            <div class="from-input" style="width:325px" >
                                <input-pattern type="text" :disabled="isInputCardNo" ref="input" @focus="(e)=>e.target.select()" class="key-focus" data-model='idCode' v-model="idCode" isBarcode @barcode="onMemberInfo(true)" @keydown.enter="onMemberInfo()" />
                                <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                            </div>
                            <button class="btn" @click="readCard()">读卡</button>
                        </div>
                        <div class="table-box">
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                <div class="lable-txt" style="width:180px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo?.Bestech_Card_KindName}}</span></div>
                                <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">
                                    <span style="letter-spacing:0.5em;">手机</span>号:
                                    <span style="color: #000;font-size: 14px;">{{meberInfo?.Mobile}}</span>
                                </div>
                                <div class="lable-txt" style="width:180px;">
                                    性<span style="padding-left:25px;">别</span>：
                                    <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                </div>
                                <div class="lable-txt" style="width:160px;">余额：
                                    <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Card_Money>0">{{Number(meberInfo?.Card_Money).toFixed(2)}}</span>
                                    <span style="color: red;"  v-if="meberInfo?.Gift_Money>0" >（赠{{Number(meberInfo?.Gift_Money)}}）</span>
                                </div>
                                
                            </div>
                        </div>
                    </tab-pane> 
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="money-info">
                <!-- -->
                <div class="lable-txt">待支付金额：
                    <b class="noney red" v-if="unPaidMoney>0">{{(Conversion((unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)))}}</b> 
                    <b class="noney red" v-if="unPaidMoney<=0">0</b>
                </div>
                <div class="lable-txt" v-if="is_CardPayShow">储值支付：
                    <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()" />
                </div>
            </div>
            </div>
        </tabs>
        <div class="footer-box">
            <div style="position:absolute;width:91%;height:10%;" v-if="PayResultCode==1" ></div>
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询支付结果</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
     <!--会员卡信息-->
    <modal-load :isShow="cardMemberShow">
        <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
    </modal-load>
    <modal-load :isShow="cardCodeShow">
        <card-code-model :isShow="cardCodeShow" v-on:closeModel="cardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
    </modal-load>
    <!--密码验证-->
    <modal-load :isShow="passwordShow">
        <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
    </modal-load>
    <!--会员刷卡-->
    <modal-load :isShow="swipeShow">
        <swipe-card-model :isShow="swipeShow" v-on:closeModel="swipeShow=false" @swipeConfirm="swipeConfirm"></swipe-card-model>
    </modal-load>
  </modal>
</template>


<script>
import cardCodeModel from '../../diningDetail/model/cardCodeModel.vue'
import passwordConfirmModel from '../../diningDetail/model/passwordConfirmModel.vue'
import swipeCardModel from '../../diningDetail/model/swipeCardModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
import { cacheData } from '/src/services'
import { newGuid } from '/src/common/index.js'

/**全局初始化数据 */
let _data={
    Is_G9CardVerifyPWD:false, /**G9实体卡消费是否需要验证密码，true:需要，false:不需要 */
    Is_G9CardForbidInputCardNo:false /**G9实体卡消费是否必须读卡，不允许手动输入卡号，true：只能刷卡，false:可以允许手输卡号 */
};
/**会员支付 */
export default {
    name:"gNinePaymentModel",
    emits:["ThePaymentResultClic","discountSchemeClic","close","ThePaymentResultClic","paySuccessConfirm","payMultipleConfirm"],
    components:{
        cardMemberModel,
        cardCodeModel,
        passwordConfirmModel,
        swipeCardModel
    },
    props:{
        //是否显示弹层
        isShow:Boolean,
        //订单
        orderInfo:Object,
        //支付方式
        choicePayType:Object,
        payType:Object,
        //待付金额
        snackPaidMoney:Number,
        //订单菜品列表
        orderMenuList:Object,
        //尾数调整金额
        MantissaMoney:Number,
        //订单头信息
        headingInfo:Object,
        //订单渠道ID
        Channellike:String,
        //餐厅ID
        RoomAutoID:String,
        //桌台名称
        DeskName:String,
        //已付款后的订单id
        EatAutoIDs:String,
        //已付款后的时间戳
        Timestamps:String,
        //其他付款方式
        paymentHistory:Object,
        totalMoney:Number,
        discountMoneuy:Number,
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.Is_G9CardVerifyPWD = globalSysSetting.Is_G9CardVerifyPWD;
            _data.Is_G9CardForbidInputCardNo = globalSysSetting.Is_G9CardForbidInputCardNo
        }
        return {
            cardMemberShow:false,
            payTypeSSPay:'',
            RechargeType:0,
            is_CardPayShow:true,
            cardCodeShow:false,
            passwordShow:false,
            swipeShow:false,
            is_AllowMixUsing:false, //是否有不可混用券
            is_AllowMixUsingCoupon_Name:'', //不可混用券
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            cashCoupon:'', //代金券
            commodityVoucher:'',//商品券
            couponsCount:0,  //选择的券总数量
            couponsMoney:0, //券的抵扣金额
            couponsPay:'', //储值支付金额
            PointPayCouponMoney:'',
            unPaidMoney:0, //待付金额
            choiceCoupons:[], //选择的券
            errCounc:1,
            memberorderInfo:'',  //临时订单数据
            /**选择券 */
            ispayid:0,
            ispayName:'',
            otherPay:'',
            Payment_Code:'',
            Payment_Type:0,
            scanPayCode:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            isInputCardNo:false,/**是否允许手动输入卡号 true：只能刷卡，false:可以允许手输卡号 */
            isCardPayPwd:''
        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           initKeyBoard.call(this,this.$refs.zf);
           this.openimp()
        })
    },
    watch: {
        orderInfo:{
            immediate:true,
            handler(vla){
                this.EmptyDatas();
                this.couponsPay=0
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
                if(this.memberorderInfo.Order_ConsumeList){
                    for(let i=0; i<this.memberorderInfo.length; i++){
                        Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                        if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                            this.memberorderInfo.splice(i,1)
                            i--
                        }
                    }
                }
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                if(this.memberorderInfo.Member_CardNo){
                    if(this.idCode==''){
                        this.idCode = this.memberorderInfo.Member_CardNo
                    }
                }
                this.cardInfoShow()
                this.PointPayCouponMoneyInfo(0);
                
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.openimp()
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
               this.couponsPay=0
            }
            this.PointPayCouponMoneyInfo(1)
        },
    },
    methods:{
        openimp(){
            this.EmptyDatas();
            this.idCode='',this.errorTitle=''
            this.isInputCardNo = _data.Is_G9CardForbidInputCardNo
            this.initializaTion()
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
            if(this.memberorderInfo){
                for(let i=0; i<this.memberorderInfo.length; i++){
                    Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                    if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                        this.memberorderInfo.splice(i,1)
                        i--
                    }
                }
            }
            this.unPaidMoney= Number(Number(this.snackPaidMoney).toFixed(2));
            if(this.memberorderInfo.Member_CardNo){
                this.idCode = this.memberorderInfo.Member_CardNo
                this.onMemberInfo()
            }
            //需要刷卡
            if(this.isInputCardNo){
                this.swipeShow=true
            }
            this.PointPayCouponMoneyInfo(0)
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        blurRechargeMoney(){
            if(this.unPaidMoney.toFixed(2)<0){
                this.unPaidMoney =0
            }
        },
        /**实体卡读卡 */
        readCard(){
            if(this.isInputCardNo){
                this.swipeShow=true
                return
            }
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**查询支付结果 */
        select(){
            if(this.EatAutoIDs!=''){
                this.eatAutoID = this.EatAutoIDs
                this.ordertimestamps = this.Timestamps
            }
            if(this.eatAutoID==''){
                this.$message.error('没有需要查询的支付结果');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.eatAutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Site_Serial, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.ordertimestamps.toString(), //账单时间戳
            }
            if(this.ordertimestamps!=''){
                param.Timestamps = this.ordertimestamps.toString();
            }
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    this.ispaySuccess=1
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.ispaySuccess=0
                        this.payCode=''
                        this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                    }
                    return;
                }else{
                    let ResultDesc = '未查询到订单的支付结果'
                    let ResuType=0
                    if(d.ResponseHeader.ResultDesc==''){
                        d.ResponseHeader.ResultDesc = ResultDesc
                        ResuType = 1
                    }
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.ordertimestamps = d.ResponseBody.Timestamps;
                            this.ispaySuccess=0;
                            this.$emit("ThePaymentResultClic",d,this.payCode)
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**会员支付 */
        CardPayClick(){
            this.is_CardPayShow=true
            let _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            if(this.meberInfo.Card_Money){
                if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                    this.couponsPay = _unPaidMoney
                }else{
                    this.couponsPay = Number(this.meberInfo.Card_Money)
                }
            }
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        /**获取会员信息 
         * @param isBarcode 是否是扫码传入的
        */
        onMemberInfo(isBarcode){
            if(!this.idCode){
                this.$message.error('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                Member_Type:7, //2：正品CRM3.0 会员 ，4：微生活会员。(如果不传，则按照顺序判断系统开通了哪个会员对接)，5：新享会，6：正品G9会员卡
            }
            if(isBarcode){
                param.Dynamic_Type="CardSN";
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                         
                        if(this.meberInfo.OverDraftLimitedMoney){
                            this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
                        }
                        this.cardInfoShow()
                        this.idCode = this.meberInfo.Card_No;
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        hide(){
            if(this.PayResultCode!=1){
                this.$emit("close")
            }
        },
        /**确定付款 */
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(Number(this.couponsPay)>0){
                if(Number(this.couponsPay)> Number(this.unPaidMoney.toFixed(2))){
                    this.$message.error('储值金额超过待支付金额');
                    return
                }
            }
            if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                this.$message.error('卡内余额不足,本次支付所需'+this.couponsPay+'，卡内余额：'+this.meberInfo.Card_Money);
                return
            }
            //是否需要验证密码
            if(_data.Is_G9CardVerifyPWD && this.isCardPayPwd==''){
                this.passwordShow=true
                return
            }
            if(this.PayResultCode==0){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                let _order = this.memberorderInfo
                let _meberInfo=''
                /** 预支付总金额 */
                let _counMoney=0
                /**支付方式 */
                let _payment=[]  
                let _meber = this.meberInfo
                /**会员信息 */
                _meberInfo ={
                    Member_Sys:23, //会员系统类型 23：大拈会员
                    DynamicType:_meber.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                    Card_AutoID:_meber.Bestech_Card_AutoID, //卡主键
                    Card_No:_meber.Card_No, //卡号
                    Member_Name:_meber.MemberName, //姓名
                    MemberCard_Kind:_meber.Card_KindName, //会员等级
                    Mobile:_meber.Mobile,//会员手机号
                    NotJoinPointProgram:this.PointChecked,//不参与线上积分
                    Invoice_Money:0, //本次消费开票金额
                    Invoice_No:'',//发票号码
                    DaNianExtendInfo:{//大拈会员扩展参数 
                        idCode:this.idCode,
                        equipmentSn:this.equipmentSn,
                        sectorData:this.sectorData,
                    }
                }
                //储值
                if(this.couponsPay>0){
                    _payment.push({
                        Payment_ID:'CardMoneyPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money: Number(this.couponsPay), //支付金额
                        Payment_FactMoney:Number(this.couponsPay) * ((Number(this.meberInfo?.Card_Money)-Number(this.meberInfo?.Gift_Money))/Number(this.meberInfo?.Card_Money)), //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:_meber.Card_No, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = _counMoney + Number(this.couponsPay)
                }
                /**结合其他付款方式 */
                if(this.paymentHistory){
                    this.paymentHistory.forEach(pay=>{
                        if(pay.is_pay==false){
                            _payment.push({
                                Payment_ID:pay.Payment_ID,  //支付方式ID
                                Payment_Code:pay.Payment_Code, //支付方式编号
                                Payment_Name:pay.Payment_Name, //支付方式名称
                                Payment_Type:pay.Payment_Type, //支付方式类型
                                Payment_Money: pay.Payment_Money, //支付金额
                                Payment_FactMoney:pay.Payment_FactMoney, //计营业额
                                Qr_Code:pay.Qr_Code, //扫码付款的码值
                                Card_No:pay.Card_No, //卡号
                                CouponInfo:pay.CouponInfo, //券的详情
                            })
                        }
                    })
                }
                let _orderConsumes=this.$global.getFastOrderConsumes(this.orderMenuList);
                
                let param={
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID:'',//结账单ID
                    Timestamps:'', //账单时间戳
                    Operator_Name:this.userInfo?.Login_Name,//操作员
                    Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                    Channel_ID:this.Channellike,//订单渠道ID
                    Room_ID:this.RoomAutoID,//餐厅ID
                    Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                    Eat_DeskName:this.DeskName, //桌台名称
                    Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                    Eat_AllRemark:'', //订单全单备注
                    Order_Remark:this.headingInfo.orderRemark,//订单备注
                    Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                    NotSendToKitPrint:false, //下单不送打厨房
                    MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                    Member:_meberInfo,//会员信息
                    Payments:_payment, //支付方式列表
                    OrderConsumes:_orderConsumes,//订单消费明细
                }
                if(this.EatAutoIDs){
                    param.Eat_AutoID = this.EatAutoIDs
                    param.Timestamps = this.Timestamps
                }
                /**支付预览 */
                const loading = this.$loading({
                    text: "支付预览中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                    loading.close();
                    this.payPreviewData = data
                    if(data.ResponseBody?.Timestamps){
                        this.ordertimestamps = data.ResponseBody?.Timestamps.toString();
                        this.eatAutoID = data.ResponseBody?.Eat_AutoID
                    }
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.tempUserId = data.ResponseBody?.User_ID
                        this.tempOperatorName = data.ResponseBody?.User_Name
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                        if(data.ResponseBody.Is_NeedSMSCode==1){
                            this.cardCodeShow = true
                            return
                        }
                        /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                        if(data.ResponseBody.Is_NeedPassWord==1){
                            this.passwordShow=true
                            return
                        }
                        /**支付提交判断 0不需要 1需要 */
                        if(data.ResponseBody.Is_NeedSubmit==1){
                            this.PaySubmit(data)
                        }else{
                            if(data.ResponseBody?.IsMultipleCardPay==false || !data.ResponseBody?.IsMultipleCardPay){
                                if(data.ResponseBody?.PrintsInfo){
                                    setTimeout(()=>{
                                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                                    },100);
                                }
                                this.$message.success({ message: "支付完成"});
                                this.$emit('paySuccessConfirm');
                            }else if(data.ResponseBody?.IsMultipleCardPay==true){
                                this.$emit('payMultipleConfirm',data.ResponseBody);
                            }
                            
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else if(this.PayResultCode==1){
                this.PaySubmit(this.payPreviewData)
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
                let param={
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                    MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                    Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseBody?.Timestamps){
                            this.payPreviewData.ResponseBody.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        //Timestamps = data.ResponseBody.Timestamps.toString()
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        this.$message.success({ message: "支付完成"});
                        this.$emit('paySuccessConfirm');
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**获取星期 */
        setNowTimes(){
            let myDate = new Date()
            let wk = myDate.getDay()
            let weeks = ['7', '1', '2', '3', '4', '5', '6']
            let week = weeks[wk]
            return week;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.couponsPay=''
            this.PointPayCouponMoney=''
            this.choiceCoupons=[]
            this.cashCoupon=''
            this.commodityVoucher=''
            this.RechargeType=0
            this.is_AllowMixUsing=false
            this.is_AllowMixUsingCoupon_Name=''
            this.scanPayCode=''
            this.PayResultCode=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName=''
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.memberorderInfo.Order_ConsumeList.map(order=>{
                order.isCoupon=false,
                order.CouponNumber=1
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.cardInfoShow()
            if(this.meberInfo.Is_Have_ParPrice==1){
                this.memberVipMoney(this.meberInfo)
            }
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            let _cashArr = [];  //定义一个数组
            let _commArr=[];
            if(this.meberInfo?.Coupons){
                this.meberInfo.Coupons.map(item=>{
                    if(item.Coupon_Kind==1){
                        _cashArr.push(Object.assign(item,{Check:false}))
                        this.cashCoupon=_cashArr;
                    }else{
                        _commArr.push(Object.assign(item,{Check:false}))
                        this.commodityVoucher=_commArr;
                    }
                })
            }
            let _unPaidMoney =0
            if((this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)==0){
                _unPaidMoney = this.unPaidMoney.toFixed(2)
            }else{
                _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            }
            if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                this.couponsPay = _unPaidMoney
            }else{
                this.couponsPay = Number(this.meberInfo.Card_Money)
            }
        },
        /**验证码返回 */
        cardCodeConfirm(code){
            this.verifyCode=code
            this.cardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd
            this.passwordShow = false
            if(_data.Is_G9CardVerifyPWD){
                this.isCardPayPwd=pwd
                this.confirm()
            }else{
                this.PaySubmit(this.payPreviewData)
            }
        },
        swipeConfirm(Cardid){
            this.swipeShow=false
            this.idCode = Cardid
            this.onMemberInfo(true)
        },
        PointPayCouponMoneyInfo(type){
            if(this.meberInfo){
                if(type==0){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==1){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==2){
                    if(Number(this.couponsPay)<this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                        if(this.isActiveCodePay==false){
                            this.couponsPay = Number(this.couponsPay)+Number(this.accSub(0,this.unPaidMoney))
                        }
                    }else if(Number(this.couponsPay)==this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                    }else{
                        this.couponsPay =this.accSub(0,this.unPaidMoney) 
                        this.PointPayCouponMoney = this.unPaidMoney
                    }
                }
            }
            else{
                this.PointPayCouponMoney=0
            }
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return ((arg2*m-arg1*m)/m).toFixed(n);
        },
    }
}
</script>

<style lang="sass">
@import "./fastGPaymentModel.scss";
</style>