<template>
    <div class="countDown">
        <i v-if="isIcon" class="iconfont icon-shijian"></i><span class="timer-shijian">{{outNum}}{{unit}}</span>
    </div>
</template>

<script>
export default {
    name:"countDown",
    emits:["success"],
    props:{
        //倒计时 时间 默认 10s
        duration:{
            type:Number,
            default:10
        },
        /**单位 */
        unit:{
            type:String,
            default:"s"
        },
        /**是否显示图标 */
        isIcon:{
            type:Boolean,
            default:false
        },
        /**是否 用css 控制显示 */
        isCss:{
            type:Boolean,
            default:false
        },
        /** 开始倒计时 */
        isStart:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        isStart(newVal){
            if(newVal){
                this.startCountDown();
            }else{
                if(this.outTime) clearInterval(this.outTime);
            }
        }
    },
    data(){
        return {
            //倒计时
            outNum:10,
            outTime:null,
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.startCountDown();
        })
    },
    methods:{
        /**开始倒计时 */
        startCountDown(){
            if(this.outTime) clearInterval(this.outTime);
            this.outNum=this.duration;
            this.outTime=setInterval(()=>{
                this.outNum--;
                if(this.outNum<=0){
                    clearInterval(this.outTime);
                    this.$emit("success")
                    return;
                }
            },1000)
        },
    },
    //被卸载时
    unmounted(){
        if(this.outTime) clearInterval(this.outTime);
    }
}
</script>