<template>
    <div class="fontSizePopBnt">
        <el-popover placement="top" :width="400" trigger="click">
            <template #reference><div><slot>字体大小</slot></div></template>
            <div class="slider-font-box">
                <div class="title">{{title}}</div>
                <div class="font-show" :style="{fontSize:fontSize+'px'}">默认大小</div>
                <div class="font-size flex-box">
                    <span class="start">A</span>
                    <el-slider class="flex-1" v-model="fontSize"  :max="40" :min="12" @change="sizeChange"/>
                    <span class="end">A</span>
                </div>
                <div class="font-show-weight" :style="{fontWeight:fontWeight}">{{weightName}}</div>
                <div class="font-weight flex-box">
                    <span class="start">A</span>
                    <el-slider class="flex-1" v-model="fontWeight" :step="100" :max="900" :min="100" show-stops :marks="marks" @change="weightChange"/>
                    <span class="end">A</span>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
//设置字体大小
export default {
    name:"fontSizePopBnt",
    emits:["update:size","update:weight"],
    props:{
        title:{
            type:String,
            default:"设置字体大小"
        },
        //字体大小
        size:{
            type:Number,
            default:12
        },
        //字体粗细
        weight:{
            type:Number,
            default:500
        }
    },
    data(){
        return {
            fontSize:0,
            fontWeight:0,
            marks:[100,200,300,400,500,600,700,800,900]
        }
    },
    computed:{
        weightName(){
            if(this.fontWeight>500){
                return "默认粗细 粗";
            }else if(this.fontWeight<500){
                return "默认粗细 细";
            }
            return "默认粗细";
        }
    },
    watch:{
        size:{
            handler(newVal){
                this.fontSize=newVal;
            },
            immediate:true,
        },
        weight:{
            handler(newVal){
                this.fontWeight=newVal;
            },
            immediate:true,
        }
    },
    methods:{
        sizeChange(){
            this.$emit("update:size",this.fontSize);
        },
        weightChange(){
            this.$emit("update:weight",this.fontWeight);
        }
    }
}
</script>

<style lang="scss">
.el-popover{
    .slider-font-box{
        .title{
            color: #000;
            font-size: 14px;
        }

        .font-show{
            height: 40px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .font-show-weight{
            text-align: center;
        }

        .flex-box{
            display: flex;
            align-items: center;
            font-size: 16px;

            .start,.end{
                width: 30px;
                text-align: center;
            }

            .flex-1{
                flex:1;
                padding: 0 15px;
            }
        }

        .font-size{
            .start{
                font-size: 12px;
            }

            .end{
                font-size: 40px;
            }
        }

        .font-weight{
            .start{
                font-weight: 100;
            }

            .end{
                font-weight: 900;
            }
        }
    }
}
</style>