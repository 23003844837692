<template>
<modal class="merging" :isShow="mergeisShow" @keyup.esc="CloseClicks">
        <div class="Popup-Title">
          <span class="Popup-Title-left">并台操作</span>
           <div class="float-left search">
            <div class="DeskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                <span class="iconfont icon-jianpan"></span>
            </div>
            <input type="text" placeholder="桌号检索" v-model="DeskAutoSelce" ref="searchBox" @blur="searchInputBlur" />
          </div>
        </div>
        <div class="filter-nav clearfix">
            <div class="nav-box"  v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage'}">
                <div class="nav-li" :class="{ selected: filternav == -1 }" @click="NavigationClick(-1)" >全部</div>
                <div class="nav-li" :class="{ selected: filternav ==index }" v-for="(item,index) in posRooms" :key="item"  @click="NavigationClick(index)" >
                    {{item.Room_Name}}
                </div>
            </div>
            <div class="float-right page-box">
                <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
            </div>
        </div>
        <div class="desk content clearfix" style="width: 100%;height: 40%;overflow: auto;">
            <div v-if="getDeskList">
                <div>
                    <div class="desk-box opening" v-for="item in getDeskList" :key="item" @click="DeskMergeClick(item)"  v-show="!item.OpenDeskInfo.Eat_UnionID && item.showType==0 && item.Is_Desk_Locked==false" >
                        <div class="top" v-if="item.OpenDeskInfo" >
                            <span>{{item.OpenDeskInfo.Person_Num}}人</span>
                            <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span> 
                        </div>
                        <p class="title">{{item.Desk_Name}}</p>
                        <div class="bottom">
                        <span v-if="item.OpenDeskInfo">{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> 
                        <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_CheckMoney}}</span>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="Popup-InterTxt">
            <span>如下台号将合并,请确认桌台号</span>
        </div>
         <div class="desk content clearfix" style="width: 100%;height: 20%;overflow: auto;">
            <div class="desk-box opening" v-for="(item,index) in posDeskList" :key="item" @click="RevokeDeskChangeClick(item,index)">
                <div class="top" v-if="item.OpenDeskInfo" >
                    <span>{{item.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                    <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
                </div>
                <p class="title">{{item.Desk_Name}}</p>
                <div class="bottom">
                  <span v-if="item.OpenDeskInfo" >{{DifferenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                  <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_SaleMoney}}</span> <!--点餐总金额-->
                </div>
            </div>
        </div>
        <div class="Popup-Bsic-bottom">
            <button class="Popup-Basic-left-bottom" @click="CloseClicks()" >取消</button>
            <button class="Popup-Basic-right-bottom" @click="SetDeskClick()">确定</button>
        </div>
        <analog-key-board ref="keyBoard" ></analog-key-board>
</modal>
</template>

<script>
import modal from "../../../template/modal/modal";
import { httpAES,cacheData} from '/src/services'
import { differenceTime } from '/src/common';

/**全局初始化数据 */
let _data={
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
};

export default {
    name: 'merging',
    components:{
        modal
    },
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
        }
        return {
             /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            filternav:-1,
            DeskAutoSelce:'', //查询条件栏
            showPosDeskList:[],//显示桌台数据
            posDeskList:[], //
            TempList:[], //临时数组
            DeskStatus:1, //桌台状态，0 未开台 1 已开台
            MapConsumeMoney:false
        }
    },
    props:['mergeisShow','posRooms'],
    mounted(){
    },
     watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        mergeisShow() { 
            this.EmptyDatas();
        }
    },
    methods:{
         //每次打开弹窗都是一次新换台，需要清理之前的数据
        EmptyDatas(){
            if(this.mergeisShow==true){
                this.posDeskList=[];
                this.TempList=[]
                this.showPosDeskList=[]
                this.DeskAutoSelce=''
                this.MapConsumeMoney = _data.RunCloudPosHideDeskMapConsumeMoney
                this.NavigationClick(-1);
            }
        },
        //餐厅名称导航条切换
        NavigationClick(index) {
            //清空查询条件栏
            if(this.DeskAutoSelce.length>0){
                this.DeskAutoSelce='';
            }
            this.filternav = index;
            if(index==-1){
                this.showPosDeskList=this.posRooms._allDesk;
            }else{
                this.showPosDeskList=this.posRooms[index].PosDesks;
            }
        },
        //桌号检索
        DeskTextClick() {
            this.filternav=-1;
        },
         //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            return differenceTime(beginTime);
        },  
       
        //关闭弹出框
        CloseClicks(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("CloseMergingClick");
        },
        //提交并台数据
        SetDeskClick(){
            if(this.posDeskList.length!=2){
                this.$message.error("只有选中两个桌台才可以合并");
                return;
            }
            //获得选择的数据
            let mergeDesk_AutoID =this.posDeskList[1].Desk_AutoID;
            let setAutoID =this.posDeskList[0].Desk_AutoID;
            let mregDeskName= this.posDeskList[0].Desk_Name;
            let setAutoName =this.posDeskList[1].Desk_Name;
            this.$confirm('是否确认合并'+mregDeskName+','+setAutoName+'桌台', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
            .then(() => {
                let userInfo=this.$auth.getUserInfo(); //获取用户id
                let param ={
                    User_ID:userInfo?.User_ID,
                    Desk_AutoID:setAutoID, //桌台id 
                    MergeDesk_AutoID:mergeDesk_AutoID, //换往目标桌台的ID
                    Operator_Name:userInfo?.Login_Name
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                const loading = this.$loading({
                    text: "数据传输中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                httpAES.post("Bestech.CloudPos.MergeDesk", param).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode!=0){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        return;
                    }else {
                        this.$emit("setMergeDesk",this.posDeskList);
                        this.$emit("CloseMergingClick"); //关闭并台弹窗
                        this.$message({type: 'success',message: '并台成功'});
                        return;
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                });
            }).catch(() => {
                this.$message({type: 'info',message: '并台取消'});  
                this.posDeskList[1].showType=0
                this.posDeskList.splice(this.posDeskList.length-1,1);  
            });
        },
        //获取点击的桌台数据
        DeskMergeClick(desk){
            desk.showType=1
            if(this.posDeskList.length<2){
                if(this.posDeskList.findIndex(arr => arr.Desk_AutoID == desk.Desk_AutoID)==-1)
                {
                    this.posDeskList.push(desk);
                }
                if(this.posDeskList.length==2){
                    this.SetDeskClick()
                }
            }else{
                this.$message.error("并台目前最多只支持2个桌台");
                return;
            }
        },
        //撤销选择的数据
        RevokeDeskChangeClick(desk,index){
            desk.showType=0
            if(desk && index>=0){
                this.posDeskList.splice(index,1);
            }
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    },
    computed:{
        getDeskList(){
            //this.showPosDeskList = Object.assign(this.showPosDeskList,{showType:0})
            let data=this.showPosDeskList.filter((item)=>{
                return item.Desk_Status=='1';
            });
            if(this.DeskAutoSelce!=''){
                let searchTxt=this.DeskAutoSelce.toLowerCase();
                return data.filter((item)=>{
                    return (item.Desk_Code||"").toLowerCase().indexOf(searchTxt)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(searchTxt)>=0;
                });
            }
            data.map(item=>{
               item = Object.assign(item,{showType:0})
            })
            return data;
        },
    }
}

</script>

<style lang="scss">
@import "./merging.scss";
</style>