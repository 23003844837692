<template>
  <modal class="cardCodeModel" :isShow="isShow" @keyup.esc="hide">
      <div class="header">短信验证</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">短信验证</div>
                    <div class="tr-li">
                        <div class="lable-txt">请输入验证码</div>
                        <div class="from-input"><input type="text" class="key-focus"  ref="priceInput" v-model="CodeNum" @keyup.enter="confirm()"/></div>
                        <template v-if="mobile">
                            <count-down class="send-code loop-code" v-if="isCodeTime" :duration="60" unit="秒" @success="isCodeTime=false">{{codeTime}}秒</count-down>    
                            <div class="send-code" @click="readCode()" v-else>验证码</div> 
                        </template>
                    </div>
                    <div class="tr-li" v-if="wliveCardMember">
                        <div class="lable-txtcard">当前验证的会员卡号:</div>
                        <div class="lable-txtNo">{{wliveCardMember.Card_No}}</div>
                    </div>
                    <template v-if="mobile">
                        <div class="tip">提示：短信验证码{{expireTime>0?expireTime:5}}分钟内有效！重新发送后，上一个验证码作废，以最新发送的为准！</div>
                        <div class="tip" v-if="sysSetting.BestCrm30_ConsumeAllowQuerySmsCode">
                            当用户收不到验证码时，可点击<span class="more-bnt" @click="GetMobileSMSVerifyCode">【去查看】</span>短信验证码
                            <p class="red">注意:如本次通过查看验证码方式完成扣卡，后台报表备注记录</p>
                        </div>
                    </template>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'

export default {
    name:'cardCodeModel',
    emits:["closeModel","cardCodeConfirm"],
    props:{
        isShow:Boolean,
        wliveCardMember:Object,
        //短信有效分钟数
        expireTime:{
            type:Number,
            default:5
        },
        //手机号 用来发送验证码
        mobile:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            /**短信验证码 */
            CodeNum:'',
            /*是否 倒计时开始*/
            isCodeTime:false,
            /**XXX授权YY查看验证码完成扣卡消费 或 XXX 查看验证码完成扣卡消费*/
            lookSMSCodeRemarks:'',
            tempUserID:"",//授权的用户id
            tempUserName:""//授权的用户名称
        }
    },
    computed:{
        //系统参数配置
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
    },
    watch: {
        isShow() { 
            if(this.isShow){
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            this.EmptyDatas();
        })
    },
    methods:{
        EmptyDatas(){
            this.CodeNum='';
            this.lookSMSCodeRemarks='';
            this.$refs.priceInput?.focus();
            if(this.mobile){//必须有手机号
                this.isCodeTime=true;
            }
            
        },
        /**确认 */
        confirm(){
            this.$emit("cardCodeConfirm",this.CodeNum,this.lookSMSCodeRemarks);
        },
        /**取消 */
        hide(){
            this.isCodeTime=false;
            this.$emit("closeModel");
        },
        /**发送验证码 */
        readCode(){
            if(!this.mobile){
                this.$message.error('用户未绑定手机号，无法发送验证码！');
                return
            }
            const loading = this.$loading({
                text: "发送验证码中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            
            this.$httpAES.post("Bestech.CloudPos.SendMobileSMSCode",{
                App_Type:5,//1：卡绑定   2：实体卡开卡验证码  3：修改手机号验证   4：卡解绑验证 5:卡消费验证
                Mobile:this.mobile,
                Verify_Type:0,
                Verify_Code:''
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.lookSMSCodeRemarks='';
                    this.isCodeTime=true;
                    this.$message.success("验证码发送成功,请注意查收");
                }else{
                    this.$message.error("验证码发送失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('验证码发送失败：'+e);
                console.log('验证码发送失败：'+e);
            })
        },
        //获取POS短信验证码
        GetMobileSMSVerifyCode(){
            const loading = this.$loading({
                text: "获取验证码中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                SmsType:5,//1：卡绑定   2：实体卡开卡验证码  3：修改手机号验证   4：卡解绑验证 5:卡消费验证
                Mobile:this.mobile,
                Operator_Name:userInfo.Login_Name,//操作员
            }
            if(this.tempUserID){//授权用户操作
                param.User_ID=this.tempUserID;
                param.Operator_Name=this.tempUserName;
            }
            console.log('请求 GetMobileSMSVerifyCode:',param)
            this.$httpAES.post("Bestech.CloudPos.GetMobileSMSVerifyCode",param).then((d)=>{
                if(param.User_ID!=userInfo.User_ID){//当前用户没有权限 换人授权操作了
                    this.tempUserID=param.User_ID;
                    this.tempUserName=param.Operator_Name;
                }
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(this.tempUserID!=userInfo.User_ID){//当前用户没有权限 换人授权操作了
                        this.tempUserID=param.User_ID;
                        this.tempUserName=param.Operator_Name;
                        this.lookSMSCodeRemarks=this.tempUserName+'授权'+userInfo.Login_Name+'查看验证码完成扣卡消费';
                    }else{
                        this.lookSMSCodeRemarks=userInfo.Login_Name+'查看验证码完成扣卡消费';
                    }

                    
                    this.$confirm('您本次发送的短信验证码:<span style="color:#4382b9;">'+d.ResponseBody?.SMS_Code+'</span>', "提示", {
                        dangerouslyUseHTMLString:true,
                        type:'warning',
                        confirmButtonText: "确定并结账",
                        cancelButtonText:"知道了",
                        callback: (name) => {
                            if(name=='confirm'){
                                this.CodeNum=d.ResponseBody?.SMS_Code;
                                this.confirm();
                            }
                        },
                    });
                }else{
                    this.$message.error("获取验证码失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('获取验证码失败：'+e);
                console.log('获取验证码失败：',e);
            })
        }
    }
}
</script>

<style lang="sass">
@import './cardCodeModel.scss';
</style>