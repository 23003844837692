

export default {
    install(app){
        function tableHeight(el,binding){
            let height= el.clientHeight;
            if(binding.value && binding.instance.$refs[binding.value]){
                let elTable=binding.instance.$refs[binding.value];
                let bodyWrapper=elTable.$refs.bodyWrapper;
                let headerWrapper=elTable.$refs.headerWrapper;
                if(bodyWrapper.scrollHeight>0){
                    if(bodyWrapper.scrollHeight>height-headerWrapper.clientHeight){
                        elTable.$el.style.height=height+"px";
                        elTable.$el.classList.add('el-table--scrollable-y');
                    }else{
                        elTable.$el.style.height="";
                        //elTable.$el.classList.remove('el-table--scrollable-y');
                    }
                }
            }
        }
        app.directive('table-el-height',{
            mounted(el,binding){//绑定元素的父组件被挂载时调用
                tableHeight(el,binding);
            },
            updated(el,binding){//在包含组件的 VNode 及其子组件的 VNode 更新之后调用
                tableHeight(el,binding);
            }
        })
    }
}