<template>
    <modal class="shoppingCartModel" :isShow="isShow" isMask @click="hide()">
        <div class="header">
            <div class="name">购物车<span>(共计{{totalMoney?.number||0}}份)</span></div>
            <div class="allWaitCall" @click="allWaitCall(!isAllWaitCall)" :class="{selected:isAllWaitCall}">
                <i class="iconfont icon-xuanze1"></i><i class="iconfont icon-xuanze"></i>
                整单等叫
            </div>
            <div class="clear" @click="$emit('clearCart')"><i class="iconfont icon-shanchu"></i>清空</div>
        </div>
        <div class="modal-body">
            <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                <div class="empty-box verticalCenter" v-if="!data || data.length==0">
                    <div class="content-box">
                        <div class="bg" :style="{backgroundImage:'url(/images/emptyCart.png)'}"></div>
                        <p>购物车空空如也～</p>
                    </div>
                </div>
                <template v-else>
                <div class="tr-li" v-for="item in data" :key="item" >
                    <div class="item-box">
                        <div class="name" @click="isMoreSetMealID=isMoreSetMealID==item.Eat_ConsumeID?null:item.Eat_ConsumeID">
                            <div class="tag" v-if="!item.is_taste && item.Is_SetMeal">套</div>
                            <div class="text nowrap2">
                                <i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.EAT_XFName}}
                            </div>
                            <template v-if="!item.is_taste && item.Is_SetMeal">
                                <i v-if="isMoreSetMealID==item.Eat_ConsumeID" class="iconMore iconfont icon-fangxiangquan-bottom"></i>
                                <i v-else class="iconMore iconfont icon-fangxiangquan-top"></i>
                            </template>
                        </div>
                        <div class="money-box">
                            <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                <span class="unit">￥</span>
                                <span class="price">{{item.Food_Price||item.Eat_CheckPrice}}</span>
                            </div>
                            <div class="proMoney" v-if="item.Is_Promotion">
                                <span class="tag">促</span>
                                <span class="unit">￥</span>
                                <span>{{item.Promotion_Price}}</span>
                            </div>
                        </div>
                        <div class="demand" @click="itemDemand(item)" v-if="!item.is_taste"><i class="iconfont icon-a-beizhu2"></i>要求</div>
                        <div class="demand" v-else></div>
                        <div class="waitCall" @click="item.isWaitCall=!item.isWaitCall">
                            <i class="iconfont icon-xuanze1" v-if="item.isWaitCall"></i>
                            <i class="iconfont icon-xuanze" v-else></i>
                            等叫
                        </div>
                        <div class="edit">
                            <div class="remove" @click="cartRemove(item)" :class="{disabled:!(item.Eat_Number>1)}"><i class="iconfont icon-jian"></i></div>
                            <key-board-input class="number"
                                type="text" 
                                :modelValue="item.Eat_Number"
                                @change="(number)=>{updateCartNum(item,number)}"
                                pattern="number" :dplaces="3" :min="0" 
                                :isBoard="true"
                                :isKeyEnterClose="true"
                                :isBlurClose="true"
                                isClickKeyBoard
                                isKeyEnterBlur
                                :keyOptions="{isKeyDownEnter:true,isNumber:true}"
                            ></key-board-input>
                            <!-- <div class="number">{{item.Eat_Number}}</div> -->
                            <div class="add" @click="cartAdd(item)"><i class="iconfont icon-jia"></i></div>
                        </div>
                    </div>
                    <div class="details">
                        <div v-if="item.Eat_MasName" @click="editDemand(item)">口味:{{item.Eat_MasName}}</div>
                        <div class="setMeal-box" v-if="item.Is_SetMeal && isMoreSetMealID==item.Eat_ConsumeID">
                            <div class="li" v-for="(set, index) in item.FoodSetmeals" :key="index">
                                {{set.FoodSetmeal_Name}}<span v-if="set.FoodSetmeal_Price>0">（加价：{{set.FoodSetmeal_Price}}元）</span> x{{set.FoodSetmeal_Number}}
                                <span class="masDemand nowrap2" v-if="set.Eat_MasName">口味:{{set.Eat_MasName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </template>
            </el-scrollbar>
        </div>
        <div class="footer-box" >
            <div class="number">
                <i class="iconfont icon-gouwuche1"></i>
                <span class="tag">{{totalMoney?.number||0}}</span>
            </div>
            <div class="money-box"><span class="unit">￥</span>{{totalMoney?.totalMoney||0}}</div>
            <div class="remarks-bnt" @click="itemDemand(null,true)"><i class="iconfont icon-zhengdanbeizhu"></i>整单备注</div>
            <div class="confirm-bnt" @click="confirm()">确认下单</div>
        </div>
    </modal>
</template>

<script>
/**购物车 */
export default {
    name:"shoppingCartModel",
    emits:["close","confirm","clearCart","cartAdd","cartRemove","itemDemand"],
    props:{
        isShow:Boolean,
        /**购物车数据 */
        data:{
            type:Array,
            default:null
        }
    },
    data(){
        return {
            isMoreSetMealID:null
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        /**是否全部等叫 */
        isAllWaitCall(){
            if(this.data?.length>0){
                return !this.data?.some(it=>!it.isWaitCall);
            }
            return false;
        },
        totalMoney(){
            let total={
                totalMoney:0.00,
                unPaidMoney:0,//应收金额
                MantissaMoney:0,//位数调整金额
                number:0
            };
            this.data?.forEach((it)=>{
                total.totalMoney += Number(Number(it.Eat_CheckPrice * Number(it.Eat_Number)).toFixed(2));
                if(it.Food_Type!=4 && !it?.is_taste){
                    total.number += Number(it.Eat_Number); //总份量
                }
                if(it?.FoodSetmeals){
                    it.FoodSetmeals.map(food=>{
                        if(food?.Food_Type!=4){
                            total.number += Number(food.FoodSetmeal_Number); //总份量
                        }
                        total.totalMoney += Number(Number(Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number)))
                    })
                }
            })

            total.totalMoney=Number(total.totalMoney.toFixed(2));
            total.unPaidMoney=Number(total.unPaidMoney.toFixed(2));
            total.number=Number(total.number.toFixed(2));

            //计算尾数调整
            total.unPaidMoney=this.$global.mantissaAdjust(this.$global.globalSysSetting?.FastFoodMantissaAdjustKind,total.totalMoney);
            //尾数调整金额 应收 - 合计金额
            total.MantissaMoney=Number((total.unPaidMoney-total.totalMoney).toFixed(2));
            return total;
        }
    },
    watch:{
        isShow(){
            
        }
    },
    mounted(){
        
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        /**下单 */
        confirm(){
            if(this.data.length>0){
                this.$emit("confirm");
            }
        },
        cartRemove(item){
            this.$emit("cartRemove",item);
        },
        cartAdd(item){
            this.$emit("cartAdd",item);
        },
        /**更新购物车 菜品数量 */
        updateCartNum(item,num){
            if(num>0){
                console.log(num)
                let number=Number((item.Eat_Number-Number(num)).toFixed(4));
                if(number>0){//减少
                    this.$emit("cartRemove",item,number);
                }else if(number<0){//增加
                    this.$emit("cartAdd",item,Math.abs(number));
                }
            }
        },
        /** 整单等叫 */
        allWaitCall(isBo){
            this.data?.forEach(it => {
                it.isWaitCall=isBo;
            });
        },
        /**菜品要求 */
        itemDemand(item,isAll){
            if(isAll && this.data.length==0){
                this.$message.warning('没有需要备注的菜品，请先添加菜品！');
                return
            }
            this.$emit("itemDemand",item,isAll);
        }
    }
}
</script>

<style lang="scss">
    @import './shoppingCartModel.scss'
</style>