
import tableVir from './tableVir.vue'
import tableColumnVir from './tableColumnVir.vue'

const components=[
    tableVir,
    tableColumnVir
];


export default {
    install(app){
        components.forEach(component => {
            app.component(component.name, component);
        });
    }
}