<template>
    <modal class="orderTrackDetailsModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">订单详情<i class="close iconfont icon-a-guanbi11" @click="hide()"></i></div>
        <div class="modal-body">
            <div class="diandan"><i class="dan iconfont icon-dingdan"></i>{{tracksearchinfo?.Operate_KindName}}</div>
            <div class="czy">操作人：<span class="name">{{tracksearchinfo?.Operator_Name}}</span>
                <div class="time">操作时间：<span class="name">{{shortDateFormat(tracksearchinfo?.Operate_Time)}}</span></div>
            </div>
        <div class="table-box">
            <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                ref="tableEl"
                border
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                :data="ordereatList">
                <el-table-column type="index" label="序号" min-width="80" ></el-table-column>
                <el-table-column prop="Food_Code" label="菜品编码" min-width="80" align="left"></el-table-column>
                <el-table-column prop="Food_Name" label="菜品名称" min-width="100" align="left" ></el-table-column>
                <el-table-column prop="Food_Number" min-width="80" align="right" data-format="number">
                    <template #header>
                        <template v-if="tracksearchinfo?.Operate_Kind=='820'">退菜数量</template>
                        <template v-else>下单数量</template>
                    </template>
                </el-table-column>
                <el-table-column prop="Food_Mas" label="口味做法" min-width="85" align="left"></el-table-column>
            </el-table>
        </div>
        <div class="heji" v-if="sumFood>0">合计:{{sumFood}}道 {{sumFood_Number}}份</div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
</template>
  
<script>
import { newGuid } from '/src/common/index.js'
export default {
    name:'orderTrackDetailsModel',
    props:{
        isShow:{
            typeof:Boolean,
            default:false
        },
        /**穿透关联信息 */
        tracksearchinfo:{
            typeof:Object,
            default:null
        },
    },
    data(){
        return{
            ordereatList:[
            ],
            sumFood:0,
            sumFood_Number:0,
            numberLable:"下单数量"
        }
    },
    watch:{
        isShow(value){
            if(value){//显示弹层时 初始化数据
                this.loadData();
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.userInfo = this.$auth.getUserInfo(); //获取用户id
            this.loadData();
        })
    },
    computed(){
    },
    methods:{
        /**加载节点详情*/
        loadData(){
            if(this.tracksearchinfo?.Detail_LinkID.length<=0){
                this.$alert("关联ID不能为空", "提示", { confirmButtonText: "确定" });
            }
            let param = {
                Body_ID: newGuid(),
                User_ID: this.userInfo?.User_ID,
                Track_Kind: this.tracksearchinfo.Track_Kind,
                Detail_LinkID: this.tracksearchinfo.Detail_LinkID,
                Rpt_Date:this.tracksearchinfo.Rpt_Date
            }
            console.log(param);
            this.$httpAES.post("Bestech.CloudPos.GetOrderTrackDetail", param).then((data) => {
                if (data.ResponseHeader.ResultCode != 0) {
                    this.$message.error(data.ResponseHeader.ResultDesc);
                } else {
                    this.ordereatList=data.ResponseBody;
                    if(this.ordereatList?.length>0){
                        this.sumFood=this.sumFood_Number=0;
                        this.ordereatList.map((item)=>{
                            this.sumFood++;
                            this.sumFood_Number+=item.Food_Number
                        })
                    }
                    console.log(this.ordereatList);
                }
            }).catch((e) => {
                this.$alert(e.message, "提示", { confirmButtonText: "确定" });
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        /*时间格式 年月日时分秒*/
        shortDateFormat(cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy.MM.dd hh:mm:ss');
        }
    }
}
</script>
<style lang="scss">
    @import "./orderTrackDetailsModel.scss"
</style>