<template>
	<div class="login">
		<header class="header clearfix">
			<div class="float-left title">正品贵德云POS管理中心</div>
			<div class="float-right">
				<div class="tel"><span class="iconfont icon-new_whiteboardphone"></span>咨询电话：400-888-2891</div>
				<div class="bnt_close" @click="close()"><span class="iconfont icon-guanji"></span></div>
			</div>
		</header>
		<div class="positionCenter">
			<div class="loginbox">
				<div class="title">Bestech.CloudPos</div>
				<div class="flex-box">
					<div class="flex-grow-0">
						<div class="login-title"><img width="40" height="42" src="/images/BSLogo.png" />
						<span style="position:absolute;">{{deviceName}}</span>
						</div>
						<div class="li">
							<div class="lable-txt"><span class="iconfont icon-yonghu"></span></div>
							<input type="text" ref="name" class="key-focus" data-model='userName' @focus="tabIndex=1" v-model="userName" placeholder="请输入用户名" @keyup.enter="KeyClick(1)" />
						</div>
						<div class="li">
							<div class="lable-txt"><span class="iconfont icon-password"></span></div>
							<input type="password" ref="pasword" class="key-focus" @focus="tabIndex=2" data-model='userPW' v-model="userPW" placeholder="请输入密码" @keyup.enter="KeyClick(2)" />
						</div>
						<div class="li"><button type="button" class="btn bnt-login" @click="login()">登录</button></div>
					</div>
					<div class="flex-grow-1 keyboard">
						<div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
								data-value="9">9</span></div>
						<div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
								data-value="6">6</span></div>
						<div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
								data-value="3">3</span></div>
						<div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
								data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
						<div class="key-li"><span data-value="clean">清除</span><span class="bnt-confirm" data-value=""
								@click="next">确认</span></div>
					</div>
				</div>
			</div>
			<div class="loginModeBox">
				<div class="title">其他登录方式</div>
				<div class="bnt-mode">
					<span class="iconfont icon-weixin"></span>
					<span class="iconfont icon-dingding"></span>
					<span class="iconfont icon-QQ"></span>
				</div>
			</div>
		</div>

		<footer class="footer">
			<span>版权所有 ：©2016-2020 上海正品贵德软件有限公司 </span>
		</footer>
		<div class="poplayer" v-if="layer==true">
			<div class="title">
				<span class="left-span">提示</span>
				<span class="right-span" @click="knowClick()">×</span>
			</div>
			<div class="Tips">
				<div  v-for="(item,index) in BusinessAudits?.filter(d=>d.Audit_Type!=4 && d.Audit_Type!=5)" :key="index" style="margin-top:10px;position:relative;" >
					<div class="Circle" v-if="item.Audit_Type==1">!</div>
					<div class="Triangle" v-else>
						<span style="position:relative;left:-3px;top:2px;">!</span>
					</div>
					<div class="wintxt">
						{{item.Audit_Notify}}
					</div>
				</div>
			</div>
			<div style="padding-left:65%;position:relative;height:60px;">
				<button type="button" class="bnt-know" @click="knowClick()">我知道了</button>
			</div>
		</div>
		<modal-load :isShow="isUpdater" :isUnload="true">
			<updater :isShow="isUpdater" :isForcedUpdate="isForcedUpdate" :data="updateData"  @closeModel="updateClose()"></updater>	
		</modal-load>
	</div>
</template>

<script>
import { ElMessage } from "element-plus";
import {webBrowser,initKeyBoard} from '/src/common'
import { httpAES,auth } from '/src/services'
import updater from '../home/updater/updater.vue'
	
export default {
	components: { updater },
		name: 'login',
		data() {
			return {
				userName:'',
				userPW:'',
				deviceName:'',
				tabIndex:0,
				isLogin:false,
				layer:false,
				BusinessAudits:'',
				LoginPosSys:'',
				/**是否显示升级提示 */
				isUpdater:false,
				/**是否强制 升级 */
				isForcedUpdate:false,
				/**升级信息 */
				updateData:"",
				//是否开启读卡登录 功能
				isRedCardLogin:false,
				//是否停止读卡登录
				isStopRedCard:true,
				//卡号
				Card_ID:"",
				//输入框监听 卡号
				inputCard:"",
				//第一次输入时间
				inputStartTime:0,
				// 输入间隔 100毫秒
				inoutSpaceTime:500
			}
		},
		watch:{
			userName(newVal,oldVal){
				if(!oldVal || (oldVal && (newVal||"").indexOf(oldVal)==0)){
					let time=(new Date()).getTime();
					if(this.inputStartTime+this.inoutSpaceTime<time){
						this.inputStartTime=time;
						this.inputCard="";
					}
					let oldLeng=oldVal?.length||0;
					this.inputCard+=newVal.substring(oldLeng);
				}
			}
		},
		mounted() {
			if(!this.$global.devices || !this.$global.devices.uuid){
				this.$webBrowser.getDevices().then(data=>{
					if(data){
						this.$global.devices=data;
					}
				})
			}
			
			this.$nextTick(()=> {
				initKeyBoard.call(this,this.$el);
				this.$global.invoiceOff=false;
				this.$global.servingPosRooms=Object;
				this.$global.servingTheFood=Object;
				//默认焦点
				this.$refs.name.focus();
				webBrowser.getConfig().then(config=>{
					if(!config?.Access_Key){
						//this.$router.push('/register');
					}else{
						let deviceName=this.$cacheData.get("deviceName");
						this.deviceName=deviceName;
						
						httpAES.post("Bestech.CloudPos.GetDepartmentInfo",{}).then((d)=>{
							if(d.ResponseHeader.ResultCode!=0){
								this.$alert(d.ResponseHeader.ResultDesc, "提示", {confirmButtonText: "确定"});
							}else{
								this.deviceName = d.ResponseBody.DEPARTMENT_NAME;
								this.$cacheData.set("deviceName",d.ResponseBody.DEPARTMENT_NAME);
							}
						}).catch((e)=>{
							console.log('e:'+JSON.stringify(e));
							this.$alert(e.message, "提示", {confirmButtonText: "确定"});
						})
					}
				})

				this.$webBrowser.getBaseSetup().then(d=>{
					if(d?.isRedCardLogin){
						this.isRedCardLogin=d?.isRedCardLogin;
						this.startCardRed();//开启读卡
					}
				})
				
				
			})
		},
		methods: {
			close() {
				// const loading = this.$loading({
				// 	text: "退出登录中...",
				// 	spinner: "el-icon-loading",
				// 	background: "rgba(0, 0, 0, 0.7)",
				// });
				// let param={
				// 	Body_ID:newGuid(),
				// 	User_ID:'', //登陆用户ID
				// 	User_Name:''
				// }
				// this.$httpAES.post("Bestech.CloudPos.LogoutPos",param).then((data)=>{
				// 	loading.close();
					
				// }).catch((e)=>{
				// 	loading.close();
				// 	this.$message.error('退出登录：'+e);
				// 	console.log('退出登录：'+e);
				// });
				webBrowser.close();
			},
			/**input回车 */
			KeyClick(type){
				let time=(new Date()).getTime();
				if(type==1 && this.inputCard?.length>6 && this.inputStartTime+this.inoutSpaceTime>time){//输入名称回撤
					this.login({
						Login_Type:3,//刷卡登录
						Card_ID:this.inputCard
					})
					return;
				}


				if(type==1){
					this.$refs.pasword.focus();
				}else{
					if(this.layer==true){
						this.knowClick()
					}else{
						this.login();
					}
				}
			},
			next(){
				if(this.tabIndex==1){
					this.diskey(this.$refs.pasword);
					this.$refs.pasword.focus();
				}
				else if(this.tabIndex==2){
					this.login();
				}
			},
			//开启读卡
			startCardRed(){
				if(this.isRedCardLogin && this.isStopRedCard){
					this.isStopRedCard=false;
					setTimeout(()=>{
						this.redCardLoain();
					},2000);//间隔2秒 读卡
				}
			},
			redCardLoain(){
				if(this.isStopRedCard || this.isLogin || this.layer){
					return;
				}
				this.$webBrowser.redCardM1().then((d)=>{
					if(d && !this.isStopRedCard){
						if(d.state==0){
							if(!d.data.snr){
								this.$message.warning({ message: "卡号为空,请换张卡!"});
							}else{
								// this.card_AutoID=d.data.cardID;
								// this.cardNo=d.data.cardNo;
								// this.cardSNR=d.data.snr
								this.isStopRedCard=true;
								this.Card_ID=d.data.snr;
								this.login({
									Login_Type:3,//刷卡登录
									Card_ID:d.data.snr
								})
								return;
							}
						}
						setTimeout(()=>{
							this.redCardLoain();
						},2000);//间隔2秒 读卡
					}
				})
			},
			login(paramData){
				console.log("登录")
				if(this.layer==true){
					return
				}
				this.LoginPosSys=''
				this.BusinessAudits=''
				let param={
					Sys_ID:801,
					Sys_VersionID:this.$global.getSysVersion?.Version_ID,//版本号
					//0--账号密码登录   1--手机号+验证码登录   2--扫码登录
					//Login_Type:0,
					//手机号
					//Login_Mobile:'',
					//身份类型 "Wechat"    "Ali"   "DingTalk"  "QQ"
					//Identity_Type:'',
					//线上身份ID
					//Identity_OpenId:''
				}
				if(paramData){
					Object.assign(param,paramData);
				}
				else{
					Object.assign(param,{
						Sys_ID:801,
						//0--账号密码登录   1--手机号+验证码登录   2--扫码登录
						Login_Type:0,
						Login_Name:this.userName,
						Login_Pwd:this.userPW,
						Sys_VersionID:this.$global.getSysVersion?.Version_ID//版本号
						
					});
					if (this.userName == "" || this.userName == null) {
						this.$message.warning({ message: "用户名不能为空"});
						return;
					}
				}
				if(this.isLogin) return;
				this.isLogin=true;
				const loading = this.$loading({
					text: "请求加载中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				httpAES.post("Bestech.CloudPos.LoginPos", param).then((d)=>{
					loading.close();
					if(d.ResponseHeader.ResultCode!=0){
						this.isLogin=false;
						if(d.ResponseHeader.ResultCode==2002 || d.ResponseHeader.ResultCode==2004){//未注册
							this.$message.error({ message:d.ResponseHeader.ResultDesc});
							this.$router.push('/register');
							return;
						}
						if(d.ResponseHeader.ResultDesc!=''){
							this.$message.error(d.ResponseHeader.ResultDesc);
						}else{
							this.$message.error('登录失败,请确认本地网络正常');
						}
						this.startCardRed();
						return;
					}

					if(d.ResponseBody.Department_Logo){
						this.$webBrowser.setDepartmentLogo(d.ResponseBody.Department_Logo);
					}
					if(d.ResponseBody?.BillPrintDiyImages){
						this.$webBrowser.setBillDiyPrintImages(d.ResponseBody.BillPrintDiyImages);
					}

					if(d.ResponseBody?.BusinessAudits.length>0){
						this.LoginPosSys = d
						this.BusinessAudits = d.ResponseBody?.BusinessAudits;
						let busAudit=this.BusinessAudits?.find(it=>it.Audit_Type==4 || it.Audit_Type==5);
						if(busAudit){//升级
							this.isUpdater=true;
							this.updateData=busAudit.Audit_Data;
							if(busAudit.Audit_Type==5){
								this.isForcedUpdate=true;
							}
						}else{
							this.layer=true
						}
					}else{
						this.loginpush(d)
					}
				}).catch((e)=>{
					this.isLogin=false;
					this.startCardRed();
					loading.close();
					this.$alert(e.message, "提示", {confirmButtonText: "确定"});
				})
			},
			//禁止安卓弹层软键盘
			diskey(input){
				if(input){
					let isReadonly=input.getAttribute("readonly");
					if(!isReadonly){
						input.setAttribute("readonly","readonly");
						if(input._setTime) clearTimeout(input._setTime);
						input._setTime=setTimeout(()=>{
							input?.removeAttribute("readonly");
						},100)
					}
				}
			},
			knowClick(){
				this.layer=false;
				let busError =''
				this.BusinessAudits.map(bus=>{
					if(bus.Audit_Type==3){
						busError = bus
					}
				})
				if(busError){
					this.$refs.name.focus();
					this.isLogin=false;
					this.startCardRed();
					this.$message.error('登录失败:'+busError.Audit_Notify);
				}else{
					this.loginpush(this.LoginPosSys);
				}
			},
			loginpush(d){
				let _busType=[]
				d.ResponseBody?.Business_Types.map(bus=>{
					let _name=''
					if(bus==0){
						_name='中餐'
					}
					if(bus==1){
						_name='快餐'
					}
					if(bus==2){
						_name='零售'
					}
					if(bus==3){
						_name='美食广场'
					}
					let item={value:bus,lable:_name}
					_busType.push(item)
				})
				auth.login({
					id:d.ResponseBody.User_ID,
					User_ID:d.ResponseBody.User_ID, //登录用户ID
					User_Code:d.ResponseBody.User_Code,//用户编号
					Rpt_Date:d.ResponseBody.Rpt_Date,//营业日期
					Department_Code:d.ResponseBody.Department_Code,//机构编号
					Department_Name:d.ResponseBody.Department_Name,//机构名称
					Login_Name:d.ResponseBody.Login_Name,//用户名称
					Site_Serial:d.ResponseBody.Site_Serial, //设备序列号
					Site_Name:d.ResponseBody.Site_Name, //设备序名称
					Sys_Version:d.ResponseBody?.Sys_Version, //系统版本号
					Default_ChannelID:d.ResponseBody?.Default_ChannelID, //默认渠道
					Is_PrintServer:d.ResponseBody.Is_PrintServer,//当前登录设备是否打印服务站点，true:是，false:不是
					BusinessAudits:d.ResponseBody?.BusinessAudits, //营业稽核信息
					Business_Types:_busType, //站点业态 0：中餐，1：快餐，2：零售，3：美食广场。
					TheLimitedGiftingMoney:d.ResponseBody?.TheLimitedGiftingMoney, //登录用户的最大赠送金额
					IsAllowOperateMoreDepartments:d.ResponseBody?.IsAllowOperateMoreDepartments,//是否多门店
					OrgDepartments:d.ResponseBody?.OrgDepartments,//可选门店
				});
				//系统项目列表
				this.$cacheData.SysProjects.set(d.ResponseBody.SysProjects);
				const baseLoading = this.$loading({
					text: "基础数据加载中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				let dataNum=0;
				let hideLoaing=()=>{
					dataNum++;
					if(dataNum==3){
						this.isLogin=false;
						baseLoading.close();
						ElMessage.success({ message: "登录成功"});
						this.$router.push('/home/diningTable');
					}
				}; 
				this.$cacheData.cacheBaseAll([
						"GlobalSysSetting","OrderChannel","ConsumeTypes","SalesPromotions","ComboFood","EatRooms",
						'PosUsers','PosSellers'
					]).then((e)=>{
					hideLoaing();
					console.log(e)
				}).catch((e)=>{
					hideLoaing();
					console.log('基础数据加载失败:',e);
				})
				this.$cacheData.PosFoods().then(()=>{
					hideLoaing();
				}).catch(e=>{
					hideLoaing();
					console.log('未找到菜品数据:',e)
				});
				if(this.$webBrowser?.isMobile?.Android){
					/**安卓设备开启mq 服务 */
					this.$httpAES.post("Bestech.CloudPos.GetMqParams", {}).then((d)=>{
						hideLoaing();
						if(d && d.ResponseHeader.ResultCode == 0){
							let mQTTSetClass=d.ResponseBody;
							this.$webBrowser.mqStart({
								emq_url:"wss://" + mQTTSetClass.Ons_NameSrv + "/mqtt",
								emq_name:mQTTSetClass.Ons_AccessKey,
								emq_password:mQTTSetClass.Ons_SecretKey,
								emq_id:mQTTSetClass.Ons_GroupId + "@" + mQTTSetClass.MerID + "@" + mQTTSetClass.DEPARTMENTCODE + "@" + mQTTSetClass.Site_Serial + "@" + mQTTSetClass.Client_Random,
								emq_top:mQTTSetClass.Ons_Topic + "/" + mQTTSetClass.MerID + "/" + mQTTSetClass.DEPARTMENTID,
								SubGroupNames:mQTTSetClass.SubGroupNames
							}).then(()=>{
								console.debug('mq开启成功');
							}).catch(e=>{
								console.debug('mq 服务开启失败:',e);
							});
						}
						
					}).catch(e=>{
						hideLoaing();
						console.debug('mq 服务开启失败:'+e)
					})
				}else{
					this.$webBrowser.mqStart();
					hideLoaing();
				}
			},
			updateClose(){
				this.isUpdater=false;
				let busAudit=this.BusinessAudits?.filter(d=>d.Audit_Type!=4 && d.Audit_Type!=5);
				if(busAudit?.length>0){
					this.layer=true;
				}else{
					this.loginpush(this.LoginPosSys);
				}
				
			}
		},
		//被卸载时发生
		unmounted(){
			this.isStopRedCard=true;//停止读卡
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	@import './login.scss'
</style>
