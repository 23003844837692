<template>
  <modal class="orderChannelModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">{{title}} </div>
        <div class="modal-body">
             <div class="clearfix">
                <div class="left-box float-box">
                    <div class="filter-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodPracticePage',isNavFirstSticky:true}">
                            <div class="nav-li " :class="{selected:orderChannelAutoID==-1}" @click="onorderClickAll()" >全部</div>
                            <div class="nav-li " :class="{selected:orderChannelAutoID==item.OrderChannelKind_AutoID}" v-for="item in orderChannel" :key="item" @click="onorderClickAll(item)">
                                {{item.OrderChannelKind_Name}}
                            </div>
                        </div>
                        <div class="float-right page-box"><span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up"></span>
                            <span class="page-number">{{foodPracticePage.pageIndex}}/{{foodPracticePage.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>
                    <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                        <div class="tab-pane" v-if="OrderList && billType==undefined">
                            <div class="item-li category " :class="{selected:orderclass==eat.OrderChannel_AutoID}" v-for="eat in OrderList" v-show="eat.Kind_AutoID==orderChannelAutoID || orderChannelAutoID==-1" :key="eat"  @click="orderClick(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.OrderChannel_Name}}</span></div>
                            </div>
                        </div>
                        <div class="tab-pane" v-if="OrderList && billType==1">
                            <div class="item-li category " :class="{selected:eat.is_type==1}" v-for="eat in OrderList" v-show="eat.Kind_AutoID==orderChannelAutoID || orderChannelAutoID==-1" :key="eat"  @click="orderClick(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.OrderChannel_Name}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box" v-if="billType==undefined">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
        <div class="footer-boxbill" v-if="billType==1">
            <button class="btn btn-cancel" @click="hide()">返回</button>
            <button class="btn btn-confirm" @click="billConfirm()">确认</button>
        </div>
        
  </modal>
</template>

<script>
/**全单要求操作 */
export default {
    name:'orderChannelModel',
    props:{
        isShow:Boolean,
        OrderList:Object,
        orderChannel:Object,
        Openorderclass:String,
        billType:[Object,Number],
        //标题
        title:{
            type:String,
            default:"订单渠道"
        }
    },
    data(){
        return{
            foodPracticePage:{pageIndex:0,pageSize:0},
            orderChannelAutoID:-1,
            orderclass:'', //订单选择
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.openimp()
        })
    },
    watch:{
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        },
    },
    methods:{
        openimp(){
            console.log('OrderList:',this.OrderList)
            this.orderChannelAutoID=-1
            this.orderclass=this.Openorderclass
        },
        hide(){
            this.$emit("closeModel")
        },
        onorderClickAll(item){
            if(item){
                this.orderChannelAutoID=item.OrderChannelKind_AutoID
            }else{
                this.orderChannelAutoID=-1
            }
        },
        /**订单单元点击 */
        orderClick(item){
            if(item.is_type==0){
                item.is_type=1
            }else{
                item.is_type=0
            }
            console.log('item:',item)
            this.orderclass = item.OrderChannel_AutoID
            if(this.billType==undefined){
                this.$emit("orderChanneConfirm",item)
            }
        },
        billConfirm(){
            this.$emit("orderChanneConfirm",this.OrderList)
        },
    }
}
</script>

<style lang="scss">
@import './orderChannelModel.scss';
</style>