<template>
    <div class="foodMenuBox">
        <div class="food-main-box"  style="overflow:inherit"  v-if="orderMap_Type==2 ||orderMap_Type==1" :style="foodMainStyle">
            <categorys-page class="filter-nav panel" 
                :data="foodMainCategory" 
                :row="foodMainArg.row" 
                :col="foodMainArg.col" 
                :selectIndex="mainCategoryIndex"
                :bottomH="setBottomH"
                :pageIndex="foodMainPageIndex"
                navFirstName="全部大类"
                v-slot="{item}" @navClick="onFoodNav">
                {{item.MainCategory_Name}}
            </categorys-page>
        </div>
        <div class="food-sub-box"  style="overflow:inherit;margin-top:5px" v-if="orderMap_Type==3 ||orderMap_Type==1" :style="foodSubStyle">
            <categorys-page class="filter-nav panel" 
                :data="foodSubCategorys" 
                :row="foodSubArg.row" 
                :col="foodSubArg.col" 
                :selectIndex="subCategoryIndex"
                :bottomH="setBottomH"
                :pageIndex="foodSubPageIndex"
                navFirstName="全部小类"
                v-slot="{item}" @navClick="onFoodSubNav">
                {{item.SubCategory_Name}}
                <b v-show="subCategoryNumberKey[item.SubCategory_ID]>0">{{subCategoryNumberKey[item.SubCategory_ID]}}</b>
            </categorys-page>
        </div>
        <div class="panel menu menu-flex-box" :style="menuStyle">
            <div class="keyboard" v-if="$global.NumberkeyOff">
                <div class="key-li tr-li" >
                    <span @click="keyNumber(item)" v-for="(item) in [0,1,2,3,4,5,6,7,8,9,'.']" :key="item">
                        <p class="bubbing" v-show="orderNumber!=='' && !(orderNumber<0) && orderNumberType==item">{{orderNumber}}</p>
                        {{item}}
                    </span>
                    <span @click="keyNumber(-1)"><i class="iconfont icon-zuojiantou"></i></span>
                </div>
            </div>
            <!-- 菜单 -->
            <div class="item-flex-box">
                <layout-rc class="item-box clearfix" :isVarCss="true" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :list="foodsPages">
                    <div class="item-li" :class="{selected:item.Food_ID==choicePosFoods}" v-for="item in foodsPages" :key="item"  @click="parent.onPlaceFoods(item)">
                        <div class="title">
                            <i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}
                        </div>
                        <b v-show="getFoodNumber(item)>0">{{getFoodNumber(item)}}</b>
                        <div class="bottom-box" v-if="item.Is_Limited && item.Is_LimitedProject">
                            <div class="text-left" v-if="item.Is_Promotion || (item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price)">剩: <span class="red">{{item.RemainNum}}</span></div>
                            <div class="bottom-flex-box">
                                <!-- 促销价 -->
                                <div class="cxPrice" v-if="item.Is_Promotion">
                                    <div class="cxTag">促</div>
                                    <div class="unit">￥</div>{{item.Promotion_Price}}
                                </div>
                                <!-- vip 价格 -->
                                <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                    <div class="vipTag">VIP</div>
                                    <div class="price"> 
                                        <div class="unit">￥</div>{{item.Food_VipPrice}}
                                    </div>
                                </div>
                                <div class="text-left" v-else>剩: <span class="red">{{item.RemainNum}}</span></div>
                                <div class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</div>
                            </div>
                        </div>
                        <div class="bottom-box bottom-flex-box" v-else>
                            <!-- 促销价 -->
                            <div class="cxPrice" v-if="item.Is_Promotion">
                                <div class="cxTag">促</div>
                                <div class="unit">￥</div>{{item.Promotion_Price}}
                            </div>
                            <!-- vip 价格 -->
                            <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                <div class="vipTag">VIP</div>
                                <div class="price"> 
                                    <div class="unit">￥</div>{{item.Food_VipPrice}}
                                </div>
                            </div>
                            <div class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</div>
                        </div>
                        <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                        <div class="tag tag-grey" v-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                    </div>
                </layout-rc>
            </div>
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="varietySelect" ref="searchBox" placeholder="菜品模糊检索"
                    @keydown="keyCursor"
                    isBarcode @barcode="barcode"
                    :focusLock="true"
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
                <div class="bnt menuOrder-bnt" @click="menuOrder()" v-if="sysSetting?.Running_BestMenu_YN">
                    <img src="/images/menuBnt.png"/>
                </div>
                <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                <div class="page-box-bottom">
                    <span class="last-page iconfont icon-fangxiangquan-left" @click="pageIndex<=1?1:pageIndex--"></span>
                    <span class="page-number">{{ pageIndex }}/{{ pageCount }}页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" @click="pageIndex>=pageCount?pageCount:pageIndex++" ></span>
                </div>
            </div>
        </div>
        
        <div class="panel footer-flex-box" :style="footerStyle">
            <layout-rc class="btn-box" :isVarCss="true" :list="userBottomMenuPages" :row="footerArg.row" :col="footerArg.col" :marginW="2" :marginH="2">
                <div class="btn" :class="{btnKey:item.ProjectItem_Key=='goCheckOut',btnReturn:item.ProjectItem_Key=='OrderReturn'}" v-show="item.Is_Show==true" 
                    v-for="(item,ProjectItem_Index) in userBottomMenuPages" :key="ProjectItem_Index" 
                    @click="parent.BottomMenuClick(item)">
                    {{item.ProjectItem_Name}}
                    <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                </div>
                <div class="page-box" v-if="userBottomMenuPagesCount>1">
                    <page-turning class="page-botton" v-model="userBottomPageIndex" :pageCount="userBottomMenuPagesCount" type="up"><i class="iconfont icon-a-order-up"></i></page-turning>
                    <page-turning class="page-botton" v-model="userBottomPageIndex" :pageCount="userBottomMenuPagesCount"><i class="iconfont icon-a-order-down"></i></page-turning>
                </div>
            </layout-rc>
        </div>
    </div>
</template>

<script>
import {config} from '../../../../common'
//点餐 右边界面 菜品分类+菜品数据+底部按钮 
export default {
    name:"foodMenuBox",
    data(){
        let arg={row:6,col:6,marginW:'6',marginH:'6'}
        let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            arg={row:globalSysSetting.OrderMap_X,col:globalSysSetting.OrderMap_Y,marginW:'6',marginH:'6'};
        }
        return {
            //餐厅id
            Eat_RoomID:"",
            //大类 行列数
            foodMainArg:{row:2,col:8},
            //大类 样式
            foodMainStyle:{},
            //小类 行列数
            foodSubArg:{row:2,col:8},
            //小类 样式
            foodSubStyle:{},
            //菜品 行列 数  
            arg:arg,    
            //横排底部按钮样式 样式
            footerStyle:{},
            //横排底部按钮样式 行列数
            footerArg:{row:1,col:8},
            //点菜 数字键位置 样式
            menuStyle:{},
            //点菜界面横排自定义按钮 当前页码 
            userBottomPageIndex:1,
            //餐厅关联的菜品类别
            linkFoodKinds:[],
            //大类分页 当前页码 
            foodMainPageIndex:1,
            mainCategoryIndex:0,
            //小类分页 当前页码 
            foodSubPageIndex:1,
            subCategoryIndex:0,
            /**菜品页码 分页用 */
            pageIndex:1,
            /**菜品检索框 输入的内容 */
            varietySelect:'',
            //记录上一次检索 不记录则按菜品检索
            oldVarietySelect:"",
            //菜品检索用
            menuTxt:"",
            /**选择的菜品 */
            choicePosFoods:0,
            //点菜数量 标签 点击的是那个数 0-9 .
            orderNumberType:-1,
        }
    },
    computed:{
        //父级
        parent(){
            return this.$parent;
        },
        //桌台是否锁定
        DeskLocked(){
            return this.parent.DeskLocked;
        },
        //锁定后 可操作的 uuid
        lockEqId(){
            return this.parent.lockEqId;
        },
        //桌台数据
        posDesks(){
            return this.parent.posDesks;
        },
        //系统参数配置
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
        /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
        orderMap_Type(){
            return this.sysSetting.OrderMap_Type||1;
        },
        //数量
        orderNumber(){
            return this.parent.orderNumber;//
        },
        //所以菜品数据
        getPosFoodData(){
            return this.parent.getPosFoodData;
        },
        //大类
        foodMainCategory(){
            let mainCategory=[];
            if(this.linkFoodKinds?.length>0){
                this.getPosFoodData?.forEach(it=>{
                    let FoodSubCategorys=it.FoodSubCategorys?.filter(sub=>this.linkFoodKinds.indexOf(sub.SubCategory_ID)>=0)//过滤小类
                    if(FoodSubCategorys?.length>0){
                        mainCategory.push(Object.assign({},it,{FoodSubCategorys:FoodSubCategorys}));
                    }
                })
            }else{
                mainCategory=this.getPosFoodData?.filter(it=>{
                    if(it.Is_ForwardMainCategory){//推荐菜
                        return it.FoodSubCategorys.some(sub=>!sub.isStop);//是否有 没停售的分类
                    }
                    return true;
                });
            }
            return mainCategory;
        },
        //全部小类
        allSubCategorys(){
            let all=[];
            this.foodMainCategory?.forEach(it=>{
                all=all.concat(it.FoodSubCategorys);
            });
            return all;
        },
        //小类
        foodSubCategorys(){
            let foodSubCategorys=this.allSubCategorys;
            if(this.mainCategoryIndex>0){//大类分类 第几
                foodSubCategorys=this.foodMainCategory[this.mainCategoryIndex-1].FoodSubCategorys;
            }
            return foodSubCategorys.filter(it=>!it.isStop);//排除暂停售卖的
        },
        /**全部菜品 过滤推荐菜分类*/
        allFoods(){
            let list=[];
            this.allSubCategorys?.filter(it=>!it.Is_ForwardSubCategory)?.forEach(it=>{
                list=list.concat(it.PosFoods);
            })
            return list;
        },
        /**全部推荐菜 */
        allForwardSub(){
            return this.allSubCategorys?.filter(it=>it.Is_ForwardSubCategory);
        },
        /**菜品分页显示 */
        foodsPages(){
            return this.posFoodsFilter?.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        //一页多少条
        pageSize(){
            let size=this.arg.row*this.arg.col;
            return size;
        },
        //总条数
        pageCount(){
            return Math.ceil(this.posFoodsFilter.length/this.pageSize);
        },
        //分类 下菜品
        posFoods(){
            let list=[];
            if(this.subCategoryIndex>0){//分类 下菜品
                list=this.foodSubCategorys[this.subCategoryIndex-1].PosFoods;
            }else{//全部分类 下菜品  过滤推荐菜分类
                this.foodSubCategorys?.filter(it=>!it.Is_ForwardSubCategory).forEach(it=>{
                    list=list.concat(it.PosFoods);
                })
            }
            return list;
        },
        //筛选 当前 菜品数据
        posFoodsFilter(){
            let list=this.posFoods;
            let menuTxt=this.menuTxt?.toLowerCase();
            if(menuTxt){
                list=list.filter(it=>(it.Food_Name||"").toLowerCase().indexOf(menuTxt)>=0 ||
                                     (it.Food_PinYin||"").toLowerCase().indexOf(menuTxt)>=0 || 
                                     (it.Food_Code||"").toLowerCase().indexOf(menuTxt)>=0
                                );
            }
            return list;
        },
        //点单底部按钮 数据
        userBottomMenu(){
            return this.parent.userBottomMenu;
        },
        /**点单底部按钮 分页显示 */
        userBottomMenuPages(){
            let size=this.footerArg.row*this.footerArg.col;
            if(this.userBottomMenuPagesCount>1){
                size=size-1;
            }
            return this.userBottomMenu?.slice((this.userBottomPageIndex-1)*size,this.userBottomPageIndex*size);
        },
        /**点单底部按钮 总页数 */
        userBottomMenuPagesCount(){
            let size=this.footerArg.row*this.footerArg.col;
            let num=1;
            if(this.userBottomMenu?.length>size){
                size=size-1;
                num=Math.ceil(this.userBottomMenu.length/size);
            }
            return num;
        },
        orderMenuList(){
            return this.parent.orderMenuList
        },
        /**点单菜品的数量  切非加价做法 */
        foodNumberKey(){
            let data={};
            this.orderMenuList?.filter(it=>!it.is_taste).forEach(it=>{
                if(data[it.EAT_XFBMID]){
                    data[it.EAT_XFBMID]=Number(data[it.EAT_XFBMID]).compute(it.Eat_Number);
                }else{
                    data[it.EAT_XFBMID]=Number(it.Eat_Number);
                }
                it.Eat_TcItemList?.forEach(item=>{
                    if(data[item.EAT_XFBMID]){
                        data[item.EAT_XFBMID]=Number(data[item.EAT_XFBMID]).compute(item.Eat_Number);
                    }else{
                        data[item.EAT_XFBMID]=Number(item.Eat_Number);
                    }
                })
                it.FoodSetmeals?.forEach(item=>{
                    if(data[item.FoodSetmeal_ID]){
                        data[item.FoodSetmeal_ID]=Number(data[item.FoodSetmeal_ID]).compute(item.FoodSetmeal_Number); 
                    }else{
                        data[item.FoodSetmeal_ID]=Number(item.FoodSetmeal_Number);
                    }
                })
            })
            return data;
        },
        /**小类下 点单菜品的数量  */
        subCategoryNumberKey(){
            let subCategory={};
            this.foodSubCategorys?.forEach(it=>{
                let number=0;
                it.PosFoods?.forEach(item=>{
                    if(this.foodNumberKey[item.Food_ID]>0){
                        number+=this.foodNumberKey[item.Food_ID];
                    }

                    item.MultipleSizeFoods?.forEach(it=>{
                        if(this.foodNumberKey[it.Food_ID]>0){
                            number+=this.foodNumberKey[it.Food_ID];
                        }
                    })
                })
                if(number>0){
                    number=Number(number.toFixed(2));
                    subCategory[it.SubCategory_ID]=number;
                }
            })
            return subCategory;
        }
    },
    watch:{
        /**菜品搜索 */
        varietySelect(newVal,oldVal){
            console.log(newVal,oldVal);
            if(newVal && (newVal.indexOf('+')>=0 || newVal.indexOf('-')>=0 )){
                this.$nextTick(()=>{
                    this.varietySelect=oldVal;
                })
                return;
            }
            let menuTxt=this.varietySelect.toLowerCase();
            if(menuTxt=="" && this.oldVarietySelect){
                menuTxt=this.oldVarietySelect.toLowerCase();
            }else{
                this.oldVarietySelect="";
            }
            this.menuTxt=menuTxt;
            if(menuTxt){
                this.mainCategoryIndex=0;
                this.subCategoryIndex=0;
                if(this.foodsPages?.length>0){
                    this.choicePosFoods=this.foodsPages[0].Food_ID;
                }
            }
            
        },
        "sysSetting.DisplayingConfigs":{//桌台和点单界面显示设置
            handler(newVal){
                newVal?.forEach((it)=>{
                    let style={};
                    if(it.ScreenPx>0){
                        style["height"]=it.ScreenPx+"%";
                    }
                    if(it.Button_Color){
                        style["--bgColor"]=it.Button_Color;
                    }
                    if(it.Font_Name){
                        style["--fontFamily"]=it.Font_Name;
                    }
                    if(it.Font_Size){
                        style["--fontSize"]=it.Font_Size+"px"
                    }
                    if(it.Font_Color){
                        style["--fontColor"]=it.Font_Color
                    }
                    let arg={row:it.IntRow,col:it.IntCol};
                    if(it.Config_Kind==11){//菜品大类显示类型
                        this.foodMainArg=arg;
                        this.foodMainStyle=style;
                    }else if(it.Config_Kind==12){//为菜品小类显示类型   
                        this.foodSubArg=arg;
                        this.foodSubStyle=style;
                    }else if(it.Config_Kind==13){//为菜品显示类型   
                        this.arg={row:it.IntRow,col:it.IntCol,marginW:'6',marginH:'6'};
                        this.menuStyle=style;
                    }else if(it.Config_Kind==14){//为功能键显示类型   
                        this.footerArg=arg;
                        this.footerStyle=style;
                    }
                })
            },
            immediate:true
        },
        orderNumber(newVal){
            if(newVal==""){//清空数量 标签隐藏
                this.orderNumberType=-1
            }
        },
        /**当前时间变化了 */
        "$global.currentTime"(){
            this.forwardOperate();
        },
        allForwardSub(newVal,oldVal){
            if(oldVal==null || newVal?.length==oldVal?.length && !oldVal.some(old=>!newVal.some(it=>it.SubCategory_ID==old.SubCategory_ID))){//推荐菜没有发生变化 或 第一次加载菜品成功后 不提示
                this.forwardOperate(true);
            }else{
                this.forwardOperate();
            }
        },
        pageCount(){
            if(this.pageCount<this.pageIndex){
                this.pageIndex=1;
            }
        }
    },
    mounted(){
        //桌台 餐厅id
        this.Eat_RoomID=this.$route.params.Eat_RoomID;
        this.$nextTick(()=>{
            this.searchBoxFocus();
        })
        //餐厅区域数据
        this.$cacheData.EatRooms().then((d)=>{
            if(d){
                this.linkFoodKinds=d?.find(it=>it.Room_AutoID==this.Eat_RoomID)?.Room_LinkFoodKinds||[];//餐厅关联的菜品类别
            }
        }).catch((e)=>{
            console.log('加载餐厅区域数据失败：'+e);
        })
        this.forwardOperate();
    },
    methods:{
        /**推荐菜 时段 判断
         * @param isNoTip 是否不提示变化了
         */
        forwardOperate(isNoTip){
            if(this.allForwardSub?.length>0){//推荐菜分类
                let date=new Date();//当前时间
                let hourMinute=date.Format("hh:mm");//时分
                let weekDay=([7,1,2,3,4,5,6])[date.getDay()];//周

                let time=new Date(date.Format("yyyy-MM-ddT00:00:00"));//日期
                let outMes=[],newMes=[];
                this.allForwardSub.forEach(item=>{
                    let isBo=true;
                    if(item.Is_ControlSellDate){//是否控制该类别的售卖日期
                        if(time>=new Date(item.Begin_Date) && time<=new Date(item.End_Date)){//比较日期 还在范围内
                            isBo=true;
                        }else{
                            isBo=false;
                        }
                    }
                    if(isBo && item.WeekDays && !/(?=.*[1])(?=.*[2])(?=.*[3])(?=.*[4])(?=.*[5])(?=.*[6])(?=.*[7])/.test(item.WeekDays)){//是否控制该类别的售卖星期
                        if(item.WeekDays.indexOf(weekDay)>=0){//在星期 内
                            isBo=true;
                        }else{
                            isBo=false;
                        }
                    }
                    if(isBo && item.MenuSellTimes?.length>0){//菜单售卖时间段，如果该字段为空，则不限制时段
                        isBo=item.MenuSellTimes.some(it=>{
                            if(it.Start_Time<=it.End_Time){//结束时段 大于开始时段
                                if(hourMinute<it.Start_Time || hourMinute>it.End_Time){//当前时间 不在指定范围内 
                                    return false
                                }
                            }else{
                                if(hourMinute>it.End_Time && hourMinute<it.Start_Time){//当前时间 不在指定范围内 
                                    return false
                                }
                            }
                            return true;
                        })
                    }

                    if(item.isStop && isBo){//暂停售卖 后 开启售卖
                        item.isStop=false;
                        newMes.push(item.SubCategory_Name);
                    }else if(!item.isStop && !isBo){//开始售卖后 结束售卖了
                        item.isStop=true;
                        outMes.push(item.SubCategory_Name);
                    }
                })

                
                if(!isNoTip && (newMes.length>0 || outMes.length>0)){//上一次提示没有关闭时不用在弹出新的提示
                    //订单数据加载中不提示
                    if(this.parent.orderInfo?.Eat_AutoID && !this.isMessage){
                        this.isMessage=true;
                        this.$alert("推荐菜发生变化", '提示', {
                            dangerouslyUseHTMLString:true,
                            confirmButtonText: '知道了',
                            callback: () => {
                                this.isMessage=false;
                            }
                        })
                    }
                    this.mainCategoryIndex=0;
                    this.subCategoryIndex=0;
                    if(this.foodsPages?.length>0){
                        this.choicePosFoods=this.foodsPages[0].Food_ID;
                    }
                }
            }
        },
        //检索框 获取焦点
        searchBoxFocus(){
            this.$refs.searchBox.focus();
        },
        //显示 检索框 键盘
        showKeyBoard(){
            this.$refs.searchBox.focus();
            //判断键盘 未标记前是否是显示的
            if(this.$refs.searchBox?.oldIsShow){
                this.$refs.searchBox?.showKeyBoard();//显示键盘
            }
        },
        //关闭 检索框 键盘
        closeKeyBoard(){
            this.$refs.searchBox?.close(true);//关闭键盘并标记
        },
        // 选择菜品时记录 上次选中的菜品
        setOldvarietySelect(){
            if(this.varietySelect) {
                this.oldVarietySelect=this.varietySelect;
            }
            this.varietySelect='';
        },
        //计算距离检索框的位置
        setBottomH(){
            let top=this.$refs.searchBox?.getBoundingClientRect().top;
            if(top>0){
                return window.innerHeight-top;
            }
            return 0;
        },
        //
        /**点单界面横排数量按钮 */
        keyNumber(number){
            number=number+"";
            let orderNumber=this.orderNumber;
            if(orderNumber.indexOf('.')!=-1 && number=='.'){
                return 
            }
            
            if(number>0 || number==="0" || number==='.'){
                if(/^\d*[.]\d{4}/.test(orderNumber)){//小数位长度已经到4位 
                    return;
                }
                this.orderNumberType=number;
            }

            if(number=='-1' && orderNumber.length>0){
                orderNumber= orderNumber.substring(0,orderNumber.length-1);
            }else{
                if(orderNumber=="" && number=='.'){
                    orderNumber =  "0.";
                }else{
                    orderNumber =  orderNumber+number;
                }
                

                if(!/^(([-]?([1-9]\d*)|\d?)([.]\d*)?$)/g.test(orderNumber)){
                    if(/^((([0-9]\d*)|\d?)([.]\d*)?$)/g.test(orderNumber)){
                        if(/^0+$/g.test(orderNumber)){
                            orderNumber="0";
                        }else{
                            orderNumber=orderNumber.replace(/^0*(?![.])/g,"");
                        }
                    }
                }
            }

            if(Number(orderNumber)<0){
                orderNumber="";
            }

            let index=orderNumber?.indexOf(".");
            if(index>=0){//保留小数位4位
               let str= orderNumber.substring(0,index+5);
                if(str!=orderNumber){
                    orderNumber=str;
                }
            }
            this.parent.orderNumber=orderNumber;
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                this.mainCategoryIndex=index;
            }else{//所有
                this.mainCategoryIndex=0;
            }
            this.foodSubPageIndex=0;
            this.$nextTick(()=>{
                this.foodSubPageIndex=1;
            })
            this.onFoodSubNav(null,0)
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            this.subCategoryIndex=index;
            this.varietySelect="";
            this.oldVarietySelect="";
            this.menuTxt="";
        },
        //扫描枪 建索商品
        barcode(){
            console.log("barcode")
            let menuTxt=(this.varietySelect||"").toLowerCase().trim();
            if(menuTxt){
                let item=this.allFoods?.find(it=>(it.Food_BarCode||"").toLowerCase()==menuTxt);
                if(item){
                    this.parent.onPlaceFoods(item);
                    //扫码的 清除记录
                    this.oldVarietySelect=""
                }
            }
        },
        /**控制菜品搜索框 上下左右光标 */
        keyCursor(e){
            if(e.keyCode=='37' || e.keyCode=='38' || e.keyCode=='39' || e.keyCode=='40' || e.keyCode=='13'){
                e.preventDefault();
                if(this.foodsPages && this.foodsPages.length>0){
                    let index=this.foodsPages.findIndex(item=>item.Food_ID==this.choicePosFoods);
                    
                    if(e.keyCode=='37'){//左边
                        index--;
                    }else if(e.keyCode=='38'){//上
                        index=index-this.arg.col
                    }else if(e.keyCode=='39'){//右边
                        index++;
                    }else if(e.keyCode=='40'){//下
                        index=index+this.arg.col;
                    }else if(e.keyCode=='13'){
                        if(this.foodsPages.length>0){
                            if(index>=0 && this.getFoodNumber(this.foodsPages[index])>0 && this.choicePosFoods==this.foodsPages[index].Food_ID){
                                this.$confirm("【"+this.foodsPages[index].Food_Name+"】已点，是否继续添加一份?", "提示", {
                                    confirmButtonText: "确定",
                                    cancelButtonText:"取消",
                                    callback: (name) => {
                                        if(name=='confirm'){
                                            this.parent.onPlaceFoods(this.foodsPages[index]);
                                        }
                                    },
                                });
                            }else{
                                this.parent.onPlaceFoods(this.foodsPages[index]);
                            }
                        }else{
                            this.$message.warning('未找到菜品');
                            return
                        }
                        return;
                    }
                    if(index<0) index=0;
                    if(index>=0 && index<this.foodsPages.length){
                        this.choicePosFoods=this.foodsPages[index].Food_ID;
                    }
                }
            }
        },
        /**菜品点菜数量 标识 */
        getFoodNumber(item){
            let num=0;
            if(this.foodNumberKey[item.Food_ID]>0){
                num=this.foodNumberKey[item.Food_ID];
            }
            if(item.MultipleSizeFoods?.some(it=>this.foodNumberKey[it.Food_ID]>0)){
                item.MultipleSizeFoods.forEach(it=>{
                    if(this.foodNumberKey[it.Food_ID]>0){
                        num+=this.foodNumberKey[it.Food_ID];
                    }
                })
                num=Number(num.toFixed(4));
            }
            return num;
        },
        //刷新菜品数据
        RefreshData(){
            const loading = this.$loading({
                text: "信息加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.RefreshSoldInfo().then(()=>{
                loading.close();
            }).catch((e)=>{ 
                loading.close();
                this.$message.error("刷新失败:"+e.message);
            });
        },
        /**跳转到菜单点菜 */
        menuOrder(){
            if(this.DeskLocked && this.lockEqId!=  config.UUID){
                this.$alert("本桌号已锁定，请先解锁后进入菜单点餐！")
            }else{
                this.$router.push({
                    name:'ipdUserOrderMode',
                    params:{
                        Desk_AutoID:this.posDesks.Desk_AutoID,
                        Eat_AutoID:this.posDesks.Eat_AutoID,
                        Eat_RoomID:this.Eat_RoomID,//餐厅id
                    }
                });
            }
        },
    }
}
</script>

<style>

</style>