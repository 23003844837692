<template>
    <div class="printReport">
        <tabs class="flex-column" v-model="navTabIndex">
            <div class="hander-box">
                <div class="nav-box" >
                    <tab-nav class="nav-li" @click="loadPrinterOverview()">打印机一览表</tab-nav>
                    <tab-nav class="nav-li">打印任务一览表</tab-nav>
                    <tab-nav class="nav-li">转打任务一览表</tab-nav>
                </div>
                <div class="flex-box">
                    <el-popover placement="bottom" :width="315" trigger="click" v-if="$global.printInfo" ref="popover">
                        <template #reference>
                            <div class="bnt">服务监控<i class="iconfont icon-a-order-down"></i></div>
                        </template>
                        <div class="pop-box">
                            <div class="li-box">
                                <div class="name">
                                    1.打印服务语音播报
                                    <el-switch v-model="$global.isPrintVoice" :inline-prompt="true" active-text="" inactive-text=""></el-switch>
                                </div>
                            </div>
                            <div class="li-box">
                                <div class="name">2:打印服务状态</div>
                                <div class="content">
                                    <div class="li-flex print" :class="{stop:!$global.printInfo?.Is_PrintOnline}">
                                        <div class="lable-text"><i class="iconfont icon-a-dayinmoban1"></i></div>
                                        <div class="from-input" v-if="$global.printInfo?.Is_PrintOnline">正常运行中...</div>
                                        <div class="from-input" v-else>
                                            {{$global.printInfo?.printService?"启动中...":"离线中..."}}
                                            <div class="bnt-print" v-if="!$global.printInfo?.printService" @click="openPrint()">重启打印服务</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="tip" v-if="!$global.printInfo?.Is_PrintOnline && !$global.printInfo?.printService">提示：打印服务离线中,请点击【重启打印服务】</p>
                        </div>
                    </el-popover>
                </div>
                <div class="right-btn">
                    <button class="btn" v-if="navTabIndex==0" @click="loadPrinterOverview()">手动刷新</button>
                    <button class="btn" v-if="navTabIndex==0" @click="sendPrintTest()">发送测试页</button>
                    <button class="btn" @click="$emit('backMenu')">返回</button>
                </div>
            </div>
            <div class="content-box">
                <tab-pane v-slot="{isShow}">
                    <div class="data-box" v-if="isShow">
                        <div class="table-box grey-table">
                            <el-table class="el-table--scrollable-y" :data="printOvertiewList"  style="width: 100%;height:100%" 
                            ref="printOvertiew"
                            @selection-change="(val)=>{multipleTable=val}"
                            >
                                <el-table-column type="selection" width="55" />
                                <el-table-column prop="Print_Name" label="打印机端口/IP" align="left" min-width="120"></el-table-column>
                                <el-table-column prop="Print_KindName" label="打印机类型" align="left" min-width="120"></el-table-column>
                                <el-table-column prop="Print_Desc" label="打印机描述" align="left" min-width="120"></el-table-column>
                                <el-table-column prop="TotalTask" label="所有任务数" min-width="80"></el-table-column>
                                <el-table-column prop="FinishTask" label="已完成" min-width="80"></el-table-column>
                                <el-table-column prop="NowTask" label="处理中" min-width="80"></el-table-column>
                                <el-table-column prop="ErrorTask" label="打印失败" min-width="80"></el-table-column>
                                <el-table-column prop="ErrorMsg" label="打印机状态错误描述" align="left" min-width="120"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </tab-pane>
                <tab-pane v-slot="{isShow}">
                    <div class="filter-box">
                        <div class="inline-block">
                            <span class="lable-txt">营业日期：</span>
                            <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="businessHours"
                            type="date"
                            placeholder=""
                            :default-value="new Date()">
                            </el-date-picker>
                        </div>
                        <div class="inline-block">
                            <span class="lable-txt">时间段：</span>
                            <el-time-picker class="from-time" 
                                is-range
                                :editable="false"
                                range-separator="-"
                                v-model="timePeriod"
                                @change="(val)=>fileTimePeriod=timePeriod"
                                format="HH:mm">
                            </el-time-picker>
                        </div>
                        <div class="inline-block">
                            <span class="lable-txt">任务状态：</span>
                            <el-select v-model="printState">
                                <el-option :value="-1" label="所有任务"></el-option>
                                <el-option label="打印处理中" :value="0"></el-option>
                                <el-option label="打印完成" :value="1"></el-option>
                                <el-option label="打印失败" :value="2"></el-option>
                                <el-option label="未设置打印类型" :value="3"></el-option>
                                <el-option label="下单不送厨房打印" :value="4"></el-option>
                            </el-select>
                        </div>
                        <div class="inline-block search-box">
                            <div class="search">
                                <input type="text" placeholder="按桌号、菜品检索" v-model="deskNumberSearch">
                            </div>
                            <div class="bnt-search" @click="loadBillList(0)">搜索</div>
                        </div>
                    </div>
                    <div class="data-box" v-if="isShow">
                        <div class="table-box grey-table" ref="tableBox" v-dom-resize @resize="resize(1)">
                            <el-table class="el-table--scrollable-y" :data="PrintOrderPageList"  style="width: 100%;"  
                            border
                            :span-method="printOrderSpanMethod"
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                                <el-table-column prop="ITEMNAME" label="任务类型" min-width="100"></el-table-column>
                                <el-table-column prop="Eat_DeskName" label="桌号" min-width="120" align="left"></el-table-column>
                                <el-table-column prop="Eat_IssueName" label="出单类型" min-width="100" align="left"></el-table-column>
                                <el-table-column prop="Print_Name" label="出单打印机" min-width="120" align="left">
                                    <template #default="scope">
                                        {{scope?.row?.Print_Desc}}<span v-if="scope?.row?.Print_Name">({{scope.row.Print_Name}})</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Print_Serial" label="打印流水号" min-width="155" align="left"></el-table-column>
                                <el-table-column prop="Create_Time" label="任务生成时间" :formatter="formatter" width="140"></el-table-column>
                                <el-table-column prop="PrintStateDesc" label="状态" min-width="100"></el-table-column>
                                <el-table-column prop="Err_Msg" label="失败原因" min-width="140" align="left"></el-table-column>
                                <el-table-column prop="Eat_XFName" label="菜品名称" min-width="120" align="left"></el-table-column>
                                <el-table-column prop="Eat_Number" label="数量" min-width="80"></el-table-column>
                                <el-table-column prop="Eat_Czy" label="点单人"  min-width="80"></el-table-column>
                                <el-table-column prop="Eat_Time" label="点单时间" :formatter="formatter"  width="140"></el-table-column>
                                <el-table-column prop="Finish_Time" label="完成打印时间" :formatter="formatter" width="140"></el-table-column>
                                <el-table-column prop="Printdiffmin" label="时间差/分" min-width="70"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="page-box">
                        <span>当前第 {{tablePageIndex}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{tablePageSum}}页</span>
                        <div class="page-button">
                            <page-Turning class="but but-up" type="up" v-model="tablePageIndex" :pageCount="tablePageSum">上一页</page-Turning>
                            <page-Turning class="but but-down" type="down" v-model="tablePageIndex" :pageCount="tablePageSum">下一页</page-Turning>
                        </div>
                    </div>
                </tab-pane>
                <tab-pane v-slot="{isShow}">
                    <div class="filter-box">
                        <div class="inline-block">
                            <span class="lable-txt">营业日期：</span>
                            <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="businessHours2"
                            type="date"
                            placeholder=""
                            :default-value="new Date()">
                            </el-date-picker>
                        </div>
                        <div class="inline-block">
                            <span class="lable-txt">时间段：</span>
                            <el-time-picker class="from-time" 
                                is-range
                                :editable="false"
                                range-separator="-"
                                v-model="timePeriod2"
                                @change="(val)=>fileTimePeriod2=timePeriod2"
                                format="HH:mm">
                            </el-time-picker>
                        </div>
                        <div class="inline-block">
                            <span class="lable-txt">任务状态：</span>
                            <el-select v-model="printState2">
                                <el-option :value="-1" label="所有任务"></el-option>
                                <el-option label="打印处理中" :value="0"></el-option>
                                <el-option label="打印完成" :value="1"></el-option>
                                <el-option label="打印失败" :value="2"></el-option>
                            </el-select>
                        </div>
                        <div class="inline-block search-box">
                            <div class="search">
                                <input type="text" placeholder="按桌号、菜品检索" v-model="deskNumberSearch2">
                            </div>
                            <div class="bnt-search" @click="loadBillList(1)">搜索</div>
                        </div>
                        
                    </div>
                    <div class="data-box" v-if="isShow">
                        <div class="table-box grey-table" ref="tableBox" v-dom-resize @resize="resize()">
                            <el-table class="el-table--scrollable-y" :data="TransPrintOrderPageList"  style="width: 100%;"   
                            border
                            :span-method="transPrintOrderSpanMethod"
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                                <el-table-column prop="ITEMNAME" label="任务类型" min-width="100"></el-table-column>
                                <el-table-column prop="Eat_DeskName" label="桌号" min-width="120" align="left"></el-table-column>
                                <el-table-column prop="Eat_IssueName" label="出单类型" min-width="100" align="left"></el-table-column>
                                <el-table-column prop="Print_Name" label="原出单打印机" min-width="120" align="left">
                                    <template #default="scope">
                                        {{scope.row.Print_Desc}}<span v-if="scope.row.Print_Name">({{scope.row.Print_Name}})</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="TransPrint_Name" label="转往出单打印机" min-width="120" align="left">
                                    <template #default="scope">
                                        {{scope.row.TransPrint_Desc}}<span v-if="scope.row.TransPrint_Name">({{scope.row.TransPrint_Name}})</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Trans_Time" label="转打时间" :formatter="formatter" width="140"></el-table-column>
                                <el-table-column prop="Print_Serial" label="打印流水号" min-width="155" align="left"></el-table-column>
                                <el-table-column prop="Create_Time" label="任务生成时间" :formatter="formatter" width="140"></el-table-column>
                                <el-table-column prop="PrintStateDesc" label="状态" min-width="100"></el-table-column>
                                <el-table-column prop="Err_Msg" label="失败原因" min-width="140" align="left"></el-table-column>
                                <el-table-column prop="Eat_XFName" label="菜品名称" min-width="120" align="left"></el-table-column>
                                <el-table-column prop="Eat_Number" label="数量" min-width="80"></el-table-column>
                                <el-table-column prop="Eat_Czy" label="点单人"  min-width="80"></el-table-column>
                                <el-table-column prop="Eat_Time" label="点单时间" :formatter="formatter"  width="140"></el-table-column>
                                <el-table-column prop="Finish_Time" label="完成打印时间" :formatter="formatter" width="140"></el-table-column>
                                <el-table-column prop="Printdiffmin" label="时间差/分" min-width="70"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="page-box">
                        <span>当前第 {{tablePageIndex2}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{tablePageSum2}}页</span>
                        <div class="page-button">
                            <page-Turning class="but but-up" type="up" v-model="tablePageIndex2" :pageCount="tablePageSum2">上一页</page-Turning>
                            <page-Turning class="but but-down" type="down" v-model="tablePageIndex2" :pageCount="tablePageSum2">下一页</page-Turning>
                        </div>
                    </div>
                </tab-pane>
            </div>
        </tabs>
    </div>
</template>

<script>
export default {
    data(){
        return{
            //营业日期
            businessHours:'',
            //按桌号、结账单号检索
            deskNumberSearch:'',
            printState:-1,
            /**时间段 */
            timePeriod:'',
            fileTimePeriod:'',
            //营业日期
            businessHours2:'',
            //按桌号、结账单号检索
            deskNumberSearch2:'',
            printState2:-1,
            /**时间段 */
            timePeriod2:'',
            fileTimePeriod2:'',
            //打印机信息
            printOvertiewList:[],
            //打印任务一览表
            PrintOrderList:[],
            tablePageSize:15,
            tablePageIndex:1,
            //转打任务一览表
            TransPrintOrderList:[],
            tablePageSize2:15,
            tablePageIndex2:1,
            navTabIndex:0,
            multipleTable:[],
        }
    },
    computed:{
        /**打印任务 过滤数据 */
        PrintOrderFilter(){
            let list=this.PrintOrderList;
            if(list && list.length>0){
                if(this.deskNumberSearch!=''){//搜索
                    let seach=this.deskNumberSearch.toUpperCase();
                    list=list.filter((item)=>(item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0 || (item.Eat_XFName||'').toUpperCase().indexOf(seach)>=0)
                }
                if(this.fileTimePeriod){
                    let start=this.fileTimePeriod[0];
                    let end=this.fileTimePeriod[1];
                    list=list.filter(it=>this.getTimeHM(it.Eat_Time)>=this.getTimeHM(start) && this.getTimeHM(it.Eat_Time)<=this.getTimeHM(end))
                }
            }
            return list;
        },
        /**打印任务 分页数据 */
        PrintOrderPageList(){
            if(this.PrintOrderFilter && this.PrintOrderFilter.length>0){
                 return this.PrintOrderFilter.slice(((this.tablePageIndex-1)*this.tablePageSize),(this.tablePageIndex)*this.tablePageSize);
            }
            return [];
        },
        //打印任务数据 分页总页数 
        tablePageSum(){
            let lenth=1;
            if(this.PrintOrderFilter && this.PrintOrderFilter.length>0){
                lenth=Math.ceil(this.PrintOrderFilter.length/this.tablePageSize)||1;
            }
            return lenth;
        },
        /**转打任务 过滤数据 */
        TransPrintOrderFilter(){
            let list=this.TransPrintOrderList;
            if(list && list.length>0){
                if(this.deskNumberSearch2!=''){//搜索
                    let seach=this.deskNumberSearch2.toUpperCase();
                    list=list.filter((item)=>(item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0 || (item.Eat_XFName||'').toUpperCase().indexOf(seach)>=0)
                }
                if(this.fileTimePeriod2){
                    let start=this.fileTimePeriod2[0];
                    let end=this.fileTimePeriod2[1];
                    list=list.filter(it=>this.getTimeHM(it.Eat_Time)>=this.getTimeHM(start) && this.getTimeHM(it.Eat_Time)<=this.getTimeHM(end))
                }
            }
            return list;
        },
        /**转打任务 分页数据 */
        TransPrintOrderPageList(){
            if(this.TransPrintOrderFilter && this.TransPrintOrderFilter.length>0){
                 return this.TransPrintOrderFilter.slice(((this.tablePageIndex2-1)*this.tablePageSize2),(this.tablePageIndex2)*this.tablePageSize2);
            }
            return [];
        },
        ///转打任务数据 分页总页数 
        tablePageSum2(){
            let lenth=1;
            if(this.TransPrintOrderFilter && this.TransPrintOrderFilter.length>0){
                lenth=Math.ceil(this.TransPrintOrderFilter.length/this.tablePageSize2)||1;
            }
            return lenth;
        },
    },
    watch:{
        tablePageSum(){
            this.tablePageIndex=1;
        },
        tablePageSum2(){
            this.tablePageIndex2=1;
        },
        deskNumberSearch(val){
            if(val!=''){
                this.tablePageIndex=1;
            }
        },
        deskNumberSearch2(val){
            if(val!=''){
                this.tablePageIndex2=1;
            }
        }
    },
    mounted(){
        let userInfo= this.$auth.getUserInfo();
        if(userInfo && userInfo.Rpt_Date){//营业日期
            this.businessHours=userInfo.Rpt_Date;
            this.businessHours2=userInfo.Rpt_Date;
        }
        this.loadPrinterOverview();

        this.$nextTick(()=>{
            this.$refs.popover?.show();
        })
    },
    methods:{
        resize(tagIndex){
            this.$nextTick(()=>{
                if(this.$refs.tableBox){
                    let height=this.$refs.tableBox.clientHeight - 40;
                    if(tagIndex==1){
                        this.tablePageSize=parseInt(height/40);
                    }else{
                        this.tablePageSize2=parseInt(height/40);
                    }
                }
            })
        },
        /*时间格式 */
        formatter(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /**截取时间 小时 和 分钟  */
        getTimeHM(date){
            let d=new Date(date);
            return d.getHours()*60+d.getMinutes();
        },
        /**获取打印机信息 */
        loadPrinterOverview(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrinterOverview",{
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.printOvertiewList=d.ResponseBody||[];
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载打印机信息一览表数据失败：',e);
            })
        },
        //打印任务一览表
        loadBillList(IsTransPrint){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrintTaskOverview",{
                Rpt_Date:IsTransPrint==0?this.businessHours:this.businessHours2,//营业日期
                OrderStatus:IsTransPrint==0?this.printState:this.printState2,//任务状态
                IsTransPrint:IsTransPrint//0非转打 1转打
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(IsTransPrint==1){
                        this.TransPrintOrderList=d.ResponseBody||[];
                    }else{
                        this.PrintOrderList=d.ResponseBody||[];
                    }
                }else{
                    if(IsTransPrint==1){
                        this.TransPrintOrderList=[];
                    }else{
                        this.PrintOrderList=[];
                    }
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载'+(IsTransPrint==1?'打印任务':'转打任务')+'一览表数据失败：',e);
            })
        },
        printOrderSpanMethod({row, column, rowIndex, columnIndex}){
            if(row && columnIndex>=0 && columnIndex<=7){
                let data=this.PrintOrderPageList;
                if(rowIndex>0){
                    if(data[rowIndex-1].AutoID==row.AutoID && data[rowIndex-1][column.property]==row[column.property]){
                        return [0, 0];
                    }
                }
                let count=1;
                for(let i=rowIndex+1;i<data.length;i++){
                    if(data[i].AutoID!=row.AutoID || data[i][column.property]!=row[column.property]){
                        break;
                    }
                    count++;
                }
                return [count,1];
            }
        },
        transPrintOrderSpanMethod({row, column, rowIndex, columnIndex}){
            if(row && columnIndex>=0 && columnIndex<=8){
                let data=this.TransPrintOrderPageList;
                if(rowIndex>0){
                    if(data[rowIndex-1].AutoID==row.AutoID){
                        return [0, 0];
                    }
                }
                let count=1;
                for(let i=rowIndex+1;i<data.length;i++){
                    if(data[i].AutoID!=row.AutoID){
                        break;
                    }
                    count++;
                }
                return [count,1];
            }
        },
        /** 发送测试页*/
        sendPrintTest(){
            if(this.multipleTable.length==0){
                this.$message.warning("请先选择需要发送测试页的打印机!");
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.PrintTestPage",{
                Operator_Name:userInfo.Login_Name,
                PrinterIds:this.multipleTable?.map(it=>it.Print_Autoid)
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("成功发送测试页");
                    this.$refs.printOvertiew?.clearSelection();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("发送测试页失败:"+e.message);
                console.log('发送测试页失败:',e);
            })
        },
        //开启打印服务
        openPrint(){
            
            this.$webBrowser.startPrintService().then(()=>{
                this.$global.printInfo.printService=true;//启动中
                setTimeout(()=>{
                    this.getPrintServer();
                },5000)
            }).catch((e)=>{
                this.$global.printInfo.printService=false;//启动中
                this.$message.error('开启本地打印服务失败',e);
            })
        },
        /** 判断打印服务 状态 */
		getPrintServer(){
			let userInfo=this.$auth.getUserInfo();
			if(userInfo?.Is_PrintServer){
				this.$httpAES.post("Bestech.CloudPos.GetPrintServer",{}).then((d)=>{
					console.log(d)
					if(d.ResponseHeader.ResultCode==0){
						if(d.ResponseBody?.Site_Serial==userInfo?.Site_Serial){
							this.$global.printInfo=d.ResponseBody;//打印服务信息 放入全局变量
						}else{
							this.$global.printInfo=null;
						}
					}
				}).catch((e)=>{
					console.error('获取打印服务站点数据失败:',e);
				})
			}
		},
    }
}
</script>

<style lang="scss">
@import './printReport.scss';
</style>