<template>
    <modal class="servingPenetrateModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">上桌确认</div>
        <div class="modal-body">
            <div class="foodBox">
                <div class="lable">
                    <div class="lable-left">桌&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:</div>
                    <div class="lable-right">{{deskName}}</div>
                </div>
                <div class="lable">
                    <div class="lable-left">菜品名称:</div>
                    <div class="lable-right">
                        {{selectFood.Food_Name}}
                    </div>
                    <p class="taste">{{selectFood.Eat_MasName}}</p>
                </div>

                <div class="lable">
                    <div class="lable-left">数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量:</div>
                    <div class="lable-right">{{selectFood.Food_Number}}<p class="time-txt">份</p></div>
                </div>
                <div class="lable">
                    <div class="lable-left">达&nbsp;&nbsp;成&nbsp;&nbsp;率:</div>
                    <div class="lable-right red">{{selectFood.CompleteRate}}%</div>
                </div>
                <div class="lable">
                    <div class="lable-left">标准时长:</div>
                    <div class="lable-right red">{{selectFood.CookMin}}<p class="time-txt">分</p></div>
                    <div class="lable-tow-left" v-show="selectFood.TimeFlag=='超' && selectFood.Eat_CountDown>0">实际时长:</div>
                    <div class="lable-tow-right red" v-show="selectFood.TimeFlag=='超' && selectFood.Eat_CountDown>0">{{selectFood.Eat_CountDown+selectFood.CookMin}}<p class="time-txt">分</p>
                        <div class="EndCook" :style="orderColor(5,'',1)" v-show="selectFood.TimeFlag=='超' && selectFood.Eat_CountDown>0">超</div>
                    </div>
                </div>
                <div class="lable">
                    <div class="lable-left">当前岗位:</div>
                    <div class="lable-right">{{selectFood.Food_StatusInsideDesc}}</div>
                </div>
            </div>
            <div class="service">
                <div class="title">服务注意事项</div>
            </div>
            <div class="tip-box">
                <div class="tooltip">
                    <div class="txt" v-html="noticeDescHtml"></div>
                </div>
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认上桌</button>
        </div>
  </modal>
</template>

<script>
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'servingPenetrateModel',
    props:{
        isShow:Boolean,
        selectFood:Object,
        deskName:String,
        KdsFoodColors:Object,
        // isReturn:{//是否退菜  默认是赠送
        //     type:Boolean,
        //     default:false
        // },
        // disRadioOrder:Object,
        // orderInfo:Object,
        // isCheckOut:Boolean,
    },
    data(){
        return{
            orderNum:'',
            reasontype:0,
            reasontContent:'',
            /**辅助数量 */
            assSizeNum:'',
             /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            /**考虑到联台情况获取对应时间戳 */
            Timestamps:'',
        }
    },
    mounted(){
        this.$nextTick(()=> {
            
        })
    },
    computed:{
        noticeDescHtml(){
            
            return (this.selectFood?.NoticeDesc||"").replaceAll("\r\n","<br/>").replaceAll("\n","<br/>")
        }
    },
    watch:{
        isShow(){
            
        },
    },
    methods:{
        orderColor(level,IsOver,type){
            let _color=''
            this.KdsFoodColors.map(kds=>{
                if(level==kds.Speed_Status){
                    _color='background:'+kds.Color_Value+';'
                }
            })
            if(type==2 && level!=23 && level!=24){
                _color=''
            }
            return _color
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        confirm(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            if(this.selectFood.Food_StatusInsideDesc=='已上桌'){
                this.$message.warning('已上桌的菜品,无法重复上桌!');
                return
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_ConsumeID:this.selectFood.Eat_ConsumeID //菜品的明细
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            const loading = this.$loading({
                text: "菜品上桌中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.ConfirmOnDesk",param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode!=0){
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }else{
                    ElMessage.success({ message: "上桌完成"});
                    this.$emit("confirmDeskComplete")
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        }
    }
}
</script>

<style lang="scss">
@import './servingPenetrateModel.scss';
</style>