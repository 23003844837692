<template>
    <modal class="cardMemberModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">卡信息</div>
        <div class="modal-body">
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">  
                        <div class="td selectChecked">选择</div>
                        <div class="td no">卡号</div>
                        <div class="td type">会员等级</div>
                        <div class="td money">余额</div>
                        <div class="td points">积分</div>
                        <div class="td name">姓名</div>
                    </div>
                </div>
                <div class="panel-body" ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                    <div class="tr" v-for="item in data" :key="item" :class="{selected:item.Bestech_Card_AutoID==SelectClient?.Bestech_Card_AutoID}" @click="ClientsClick(item)" @dblclick="dblClick(item)"> 
                        <div class="td selectChecked">
                            <span class="iconfont " :class="item.Bestech_Card_AutoID==SelectClient?.Bestech_Card_AutoID?'icon-iconoption1':'icon-iconoption'"></span>
                        </div>
                        <div class="td no">{{item.Card_No}}</div>
                        <div class="td type">{{item.Bestech_Card_KindName}}</div>
                        <div class="td money">{{item.Card_Money}}</div>
                        <div class="td points">{{item.Card_PilePoint}}</div>
                        <div class="td name">{{item.MemberName}}</div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                <div class="float-right">
                    <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

/**选择卡信息  */
export default {
    name:"cardMemberModel",
    emits:["closeModel","confirm"],
    props:{ 
        isShow:Boolean,
        /**卡信息 */
        data:Object
    },
    data(){
        return{
            SelectClient:null
        }
    },
    mounted(){
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.SelectClient=null;
        },
        /**单机 */
        ClientsClick(item){
            this.SelectClient = item
        },
        /**双击 */
        dblClick(item){
            this.SelectClient = item;
            this.confirm();
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            if(!this.SelectClient){
                this.$message.warning('请先选择一个卡信息');
                return;
            }
            this.$emit("confirm",this.SelectClient);
        }
    }
}
</script>

<style lang="scss">
@import "./cardMemberModel.scss";
</style>