<template>
  <modal class="demandModel" :isShow="isShow" @keyup.esc="close">
    <div class="demand" v-if="!isSync">
        <div class="header">菜品要求操作
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="口味或做法模糊检索"
                    @keyup.enter="onkeysearchText()"
                    isBlurClose isKeyEnterClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
            </div>
        </div>
        <div class="modal-body ">
            <div class="clearfix">
                <div class="left-box float-box">
                    <div class="title">做法</div>
                    <div class="filter-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodPracticePage',isNavFirstSticky:true}">
                            <div class="nav-li " :class="{selected:masKindSelectIndex==-1}" @click="onClickAll(-1)" >全部</div>
                            <div class="nav-li" :class="{selected:masKindSelectIndex==index}" v-show="item.isShow==0"  v-for="(item,index) in allMasKindList" :key="item" @click="onClickAll(index,item)">{{item.MasKind_Name}}</div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up"></span>
                            <span class="page-number">{{foodPracticePage.pageIndex}}/{{foodPracticePage.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>
                    <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}" >
                        <div class="tab-pane" v-if="masKinds" >
                            <div class="item-li category" :class="{selected:eat.Is_type==1}" v-show="eat.isShow==0" v-for="(eat,index) in masKinds" :key="index" @click="clickOptFlavor(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.Mas_Name}}</span></div>
                                <div class="left-tag nowrap" v-if="eat.Mas_Price">加{{eat.Mas_Price}}.00元</div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="left-box float-box">
                    <div class="title">口味</div>
                    <div class="filter-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up2',down:'nav-down2',fx:'x',liClass:'.nav-li',page:'foodPracticePage2',isNavFirstSticky:true}">
                            <div class="nav-li " :class="{selected:practiceSelectIndex==-1}" @click="onClickAll2(-1)" >全部</div>
                            <div class="nav-li" :class="{selected:practiceSelectIndex==index}" v-show="item.isShow==0"  v-for="(item,index) in practiceList" :key="item" @click="onClickAll2(index,item)">{{item.MasKind_Name}}</div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up2"></span>
                            <span class="page-number">{{foodPracticePage2.pageIndex}}/{{foodPracticePage2.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down2" ></span>
                        </div>
                    </div>
                    <div class="item-box" v-scroll-anime="{up:'item-up2',down:'item-down2',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}" >
                        <div class="tab-pane" v-if="practiceMasKinds" >
                            <div class="item-li category" :class="{selected:eat.Is_type==1}" v-show="eat.isShow==0" v-for="(eat,index) in practiceMasKinds" :key="index" @click="clickOptFlavor(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.Mas_Name}}</span></div>
                                <div style="float:left;position: relative;bottom: 13px;font-size: 12px;" v-if="eat.Mas_Price">加{{eat.Mas_Price}}.00元</div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up2" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down2" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                
                <div class="food-box">
                    <div class="item-name">
                        已选择口味做法的菜品：<p class="foodstyle">{{foodName}}</p>
                    </div>

                    <div class="demand-tag clearfix">
                        <div class="tag-box" v-mouse-scroll>
                            <div class="tag-li" v-for="(item,index) in optFlavors" :key="item"  @click="clickOptFlavorRedu(item,index)">
                                <div class="title inlineNowrap2"><span>{{item.Mas_Name}}</span></div>
                            </div>
                        </div>
                        <div class="btn-sync" v-show="BindPosFoods==''" @click="isSync=true"><i class="iconfont icon-gengxin"></i>同步其他菜品</div>
                    </div>
                </div>
                <div class="remarks-box">
                    <span class="lable">自定义要求</span>
                    <textarea v-board class="remarks" placeholder="请输入要求" v-model="remarksName" ></textarea>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </div>
    <div class="syncDemand" v-if="isSync">
        <div class="header">菜品要求操作</div>
        <div class="modal-body">
            <div class="filter-nav">
                <div class="nav-box"  v-scroll-anime="{up:'nav-left',down:'nav-rign',fx:'x',liClass:'.nav-li',page:'navPage',isNavFirstSticky:true}">
                    <div class="nav-li" :class="{selected:foodSubIndex==-1}" @click="onClicksyncAll()" >全部小类</div>
                    <div class="nav-li" :class="{selected:item.SubCategory_ID==foodSubIndex}" v-for="item in tempfoodSub" :key="item" @click="onClicksyncAll(item)">{{item.SubCategory_Name}}</div>
                </div>
                <div class="float-right page-box">
                    <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-left"></span>
                    <span class="page-number">{{navPage.pageIndex}}/{{navPage.pageSize}} 页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-rign"></span>
                </div>
            </div>
            <div class="clearfix">
                <div class="item-box" v-scroll-anime="{up:'item-up3',down:'item-down3',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                    <div class="tab-pane" v-if="temporderMenuList">
                        <div class="item-li category" :class="{selected:item.is_type==1}" v-for="item in temporderMenuList" :key="item" v-show="(item.SubCategory_ID==foodSubIndex || foodSubIndex==-1) && item.Food_Status=='-1'" @click="onDescClick(item)">
                            <p class="title">{{item.EAT_XFName}}</p>
                            <div class="describe">
                                <span>{{item.Food_Speed}}</span>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-operation">
                    <label class="checkbox allBox"><input type="checkbox" @change="onDeskSelectAll()" :checked="isSelectAll" /><i class="iconfont icon-iconoption"></i>全选</label>
                    
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="item-up3" ><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="item-down3" ><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
                <div class="food-box">
                    <div class="item-name">
                        已选择口味做法的菜品：{{foodName}}
                    </div>
                    <div class="demand-tag clearfix">
                        <div class="tag-box" v-mouse-scroll>
                            <span class="tag-li" v-for="(item,index) in optFlavors" :key="item"  @click="clickOptFlavorRedu(item,index)">
                                <div class="title inlineNowrap2"><span>{{item.Mas_Name}}</span></div>
                            </span>
                        </div>
                    </div>
                    <div class="tip-box">
                        <!--提示：此处显示提示文本，没有提示则不显示-->
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="isSync=false">取消</button>
            <button class="btn btn-confirm" @click="syncConfirm()">确认</button>
        </div>
    </div>
  </modal>
</template>

<script>
import { deepClone } from '/src/common/index.js'
import { cacheData } from '/src/services'
/**菜品要求 */
//import { initKeyBoard } from '/src/common'
export default {
    name:'demandModel',
    props:['isShow','masKindsList','disRadioOrder','allfoodSubCategorys','orderMenuList','foodtype','BindPosFoods'],
    data(){
        return{
            /**是否显示同步数据 */
            isSync:false,
            foodPracticePage:{pageIndex:0,pageSize:0},
            foodPracticePage2:{pageIndex:0,pageSize:0},
            navPage:{pageIndex:0,pageSize:0},
            /**做法类别 */
            allMasKindList:[],
            /**做法导航 */
            masKindSelectIndex:-1,
             /**口味类别 */
            practiceList:[],
            /**口味导航 */
            practiceSelectIndex:-1,
            /**显示做法 数据 */
            masKinds:[],
            /** 口味数据*/
            practiceMasKinds:[],
            /**已选择的做法 */
            optFlavors:[], 
            /** */
            foodName:'',
            /**是否全选 口味做法*/
            isSelectAllOptFlavor:false,
            /**菜品小类 */
            tempfoodSub:[],
            targorderMenuList:[],
            /**未下单的菜品 */
            temporderMenuList:[],
            foodSubIndex:-1,
            /**是否全选 */
            isSelectAll:false,
            /**检索 口味 或 做法*/
            searchText:'',
            /**自定义口味做法 */
            remarksName:''
        }
    },
    mounted(){
        this.$nextTick(()=> {
            /**获取系统自定义菜品的基本配置 */
            //  this.$cacheData.PosDiyFoods().then((d)=>{
            //     console.log('自定义菜品:'+JSON.stringify(d))
            // }).catch(e=>{
            //     this.$alert("基本配置获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            // })
           this.openimp()
        })
    },
    watch:{
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        },
        searchText(newValue){
            if(newValue!=''){
                this.onClickAll(-1);
                this.onClickAll2(-1);
                let MenuTxt=newValue.toLowerCase();
                this.masKinds=this.masKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
                this.practiceMasKinds=this.practiceMasKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.onClickAll(-1);
                this.onClickAll2(-1);
            }
        }
    },
    methods:{
        /**打开执行 */
        openimp(){
            this.searchText=''
            if(this.foodtype==0){
                this.foodName=this.disRadioOrder.EAT_XFName
            }
            if(this.foodtype==1){
                this.foodName=this.disRadioOrder.FoodSetmeal_Name
            }
            let allMasKindList=[],practiceList=[];
            allMasKindList._all=[];
            practiceList._all=[];
            let masKindsList= deepClone(this.masKindsList);
            /**没绑定口味菜品 */
            masKindsList?.forEach((item)=>{
                if(item.TYPEGROUP==1 || item.TYPEGROUP==2){
                    let _all=[];
                    item.EatMasList.forEach(eat=>{
                        _all.push(Object.assign({},eat,{Is_type:0,isShow:0}));
                    })
                    item._all=_all;
                    item = Object.assign(item,{isShow:0})
                    if(item.TYPEGROUP==1){//做法
                        allMasKindList.push(item);
                        allMasKindList._all=allMasKindList._all.concat(_all);
                    }else if(item.TYPEGROUP==2){//口味
                        practiceList.push(item);
                        practiceList._all=practiceList._all.concat(_all);
                    }
                }
            })
            this.allMasKindList=allMasKindList;//做法
            this.practiceList=practiceList;//口味
            this.isSync=false;
            this.temporderMenuList=[];
            if(this.orderMenuList){
                this.targorderMenuList = JSON.parse(JSON.stringify(this.orderMenuList));
            }
           
            this.targorderMenuList?.map(item=>{
                this.allfoodSubCategorys.map(sub=>{
                    if(sub.SubCategory_ID==item.SubCategory_ID && item.Food_Status=='-1'){
                        this.tempfoodSub.push(sub);
                    }
                })
                item = Object.assign(item, {is_type:0,Food_Speed:item.Food_Speed})
                if(item.FoodSetmeals){
                    item.FoodSetmeals.map(food=>{
                        if(food.FoodSetmeal_ID !=this.disRadioOrder.FoodSetmeal_ID){
                            food = {
                                Eat_ConsumeID:food.Eat_ConsumeID,
                                SubCategory_ID:item.SubCategory_ID,
                                EAT_XFBMID:item.EAT_XFBMID,
                                EAT_XFCode:item.EAT_XFCode,
                                EAT_XFName:food.FoodSetmeal_Name,
                                Eat_Number:item.Eat_Number,
                                Eat_CheckPrice:item.Eat_CheckPrice,
                                Eat_SalePrice:item.Eat_SalePrice,
                                Eat_MasName:item.Eat_MasName,
                                Food_Status:item.Food_Status,
                                Is_SetMeal:true,
                                FoodSetmeal_ID:food.FoodSetmeal_ID,
                                FoodSetmeal_Code:food.FoodSetmeal_Code,
                                FoodSetmeal_Number:food.FoodSetmeal_Number,
                                FoodSetmeal_Price:food.FoodSetmeal_Price,
                                FoodModifys:food.FoodModifys,
                                is_type:0,
                                Food_Speed:food.Food_Speed
                            }
                            this.temporderMenuList.push(food);
                        }
                    })
                }else{
                    if(item.Food_Status=='-1' && !item?.is_taste){
                        this.temporderMenuList.push(item);
                    }
                }
            })
            this.tempfoodSub = this.unique(this.tempfoodSub);
            this.onClickAll(-1);
            this.onClickAll2(-1);
            this.clearData();
        },
        /**清空上次的选择 */
        clearData(){
            this.isSelectAll=false;
            this.optFlavors=[];
            //清空上次的选择
            this.remarksName=''
            /**绑定了口味和做法的菜品 */
            if(this.BindPosFoods){
                this.foodName=this.BindPosFoods.Food_Name
                this.allMasKindList.forEach(mask=>{
                    if(this.BindPosFoods.FoodEatMasList?.some(it=>it.Mas_KindID==mask.MasKind_AutoID)){
                        mask.isShow=0
                    }else{
                        mask.isShow=1
                    }
                })  
                this.masKinds.forEach(item=>{
                    if(this.BindPosFoods.FoodEatMasList?.some(it=>it.Mas_AutoID==item.Mas_AutoID)){
                        item.isShow=0
                    }else{
                        item.isShow=1
                    }

                    if(this.BindPosFoods._FoodModifys){
                        this.BindPosFoods._FoodModifys.find(food=>{
                            if(food.Mas_AutoID==item.Mas_AutoID && !food?.IsAllRequired){
                                item.Is_type=1;
                                this.optFlavors.push(item);
                                return true; 
                            }
                            return false; 
                        })
                    }
                })
                this.practiceList.forEach(prac=>{
                    if(this.BindPosFoods.FoodEatMasList?.some(it=>it.Mas_KindID==prac.MasKind_AutoID)){
                        prac.isShow=0
                    }else{
                        prac.isShow=1
                    }
                }) 
                this.practiceList?._all?.forEach(item=>{
                    if(this.BindPosFoods.FoodEatMasList?.some(it=>it.Mas_AutoID==item.Mas_AutoID)){
                        item.isShow=0
                    }else{
                        item.isShow=1
                    }

                    if(this.BindPosFoods._FoodModifys){
                        this.BindPosFoods._FoodModifys.find(food=>{
                            if(food.Mas_AutoID==item.Mas_AutoID && !food?.IsAllRequired){
                                item.Is_type=1;
                                this.optFlavors.push(item);
                                return true; 
                            }
                            return false; 
                        })
                    }
                })
            }else{
                this.masKinds.forEach(item=>{
                    if(this.disRadioOrder.FoodModifys){
                        this.disRadioOrder.FoodModifys.find(food=>{
                            if(food.Mas_AutoID==item.Mas_AutoID && food?.IsAllRequired==0){
                                item.Is_type=1;
                                this.optFlavors.push(food);
                                return true; 
                            }
                            return false; 
                        })
                    }
                })
                this.practiceList?._all?.forEach(item=>{
                    if(this.disRadioOrder.FoodModifys){
                        this.disRadioOrder.FoodModifys.find(food=>{
                            if(food.Mas_AutoID==item.Mas_AutoID && food?.IsAllRequired==0){
                                item.Is_type=1;
                                this.optFlavors.push(food)
                                return true; 
                            }
                            return false; 
                        })
                    }
                })

                let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
                if(globalSysSetting?.DiyEatMas){//指定义口味
                    this.disRadioOrder?.FoodModifys?.find(food=>{
                        if(food.Mas_AutoID==globalSysSetting.DiyEatMas.MAS_AUTOID && food?.IsAllRequired==0){
                            this.remarksName=food.Mas_Name;
                            return true; 
                        }
                        return false; 
                    })
                }
            }
        },
        //关闭
        close(){
            if(this.isSync){//关闭同步
                this.isSync=false;
            }else{
                this.hide();//关闭弹层
            }
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        confirm(){
            if(sessionStorage.getItem("tempUserID")){
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            if(this.remarksName){
                let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
                if(globalSysSetting?.DiyEatMas){
                    let EatMas = {
                            Mas_AutoID:globalSysSetting.DiyEatMas.MAS_AUTOID,
                            Mas_Code:globalSysSetting.DiyEatMas.MAS_CODE,
                            Mas_Name:this.remarksName,
                            Is_AddMoney:globalSysSetting.DiyEatMas.IS_ADDMONEY,
                            Price_Kind:globalSysSetting.DiyEatMas.PRICE_KIND,
                            Mas_Price:globalSysSetting.DiyEatMas.MAS_PRICE
                            }
                    this.optFlavors.push(EatMas)
                }else{
                    this.$message.warning('请先去后台配置自定义口味相关设置');
                    return
                }
            }
            // if(this.BindPosFoods && this.optFlavors.length==0){
            //     this.$message.warning('该菜品必须选择至少一种口味或做法');
            //     return
            // }
            if(this.optFlavors.length>10){
                this.$message.warning('超过选择的数量上限');
                return
            }
            if(this.BindPosFoods){
                this.$emit("BindPosconfirm",this.optFlavors)
            }else{
                this.$emit("confirm",this.optFlavors,this.disRadioOrder,'')
            }
        },
        /**点击口味类别  */
        onClickAll(index,item){
            if(index!=this.masKindSelectIndex){
                this.isSelectAllOptFlavor=false;
                this.masKindSelectIndex=index;
            }
            if(item){
                if(!item._all){
                    let _all=[]; //全部
                    item.EatMasList.forEach(item=>{
                        let _EatMas ={
                            Mas_AutoID:item.Mas_AutoID,
                            Mas_Code:item.Mas_Code,
                            Mas_Name:item.Mas_Name,
                            Is_AddMoney:item.Is_AddMoney,
                            Price_Kind:item.Price_Kind,
                            Mas_Price:item.Mas_Price,
                            Is_type:0,
                            isShow:0
                        }
                        _all = _all.concat(_EatMas);
                    })
                    item._all=_all;
                }
                this.masKinds=item._all;
            }else{
                this.masKinds = this.allMasKindList?._all;
            }
        },
        /**点击做法类别  */
        onClickAll2(index,item){
            if(index!=this.practiceSelectIndex){
                this.practiceSelectIndex=index;
            }
            if(item){
                if(!item._all){
                    let _all=[]; //全部
                    item.EatMasList.forEach(item=>{
                        _all = _all.concat(Object.assign({},item,{Is_type:0,isShow:0}));
                    })
                    item._all=_all;
                }
                this.practiceMasKinds=item._all;
            }else{
                this.practiceMasKinds = this.practiceList?._all;
            }
        },
        onkeysearchText(){
            if(this.searchText!=''){
                this.onClickAll(-1);
                this.onClickAll2(-1);
                let MenuTxt=this.searchText.toLowerCase();
                this.masKinds=this.masKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
                this.practiceMasKinds=this.practiceMasKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.onClickAll(-1);
                this.onClickAll2(-1);
            }
        },
        /**选择的口味 */
        clickOptFlavor(item){
            if(item.Is_type==0){
                this.optFlavors.push(item);
                item.Is_type=1;
            }else if(item.Is_type==1){
                this.optFlavors.map((opt,index)=>{
                    if(item.Mas_AutoID==opt.Mas_AutoID){
                        this.optFlavors.splice(index,1);
                        item.Is_type=0;
                    }
                })
            }
        },
        /**还原选项 口味*/
        clickOptFlavorRedu(item,index){
            if(item.Is_type==1){
                this.optFlavors.splice(index,1);
                item.Is_type=0;
            }
        },
        /**点击菜品类别 */
        onClicksyncAll(item){
            if(item){
                if(item.SubCategory_ID!=this.foodSubIndex){
                    this.isSelectAll=false;
                }
                this.foodSubIndex = item.SubCategory_ID
            }else{
                if(this.foodSubIndex!=1){
                    this.isSelectAll=false;
                }
                this.foodSubIndex=-1
            }
        },
        /**点击菜品 */
        onDescClick(item){
            if(item.is_type==0){
                item.is_type=1;
            }else{
                item.is_type=0;
            }
        },
        /**全选 */
        onDeskSelectAll(){
            if(this.isSelectAll==false){
                if(this.foodSubIndex==-1){
                    this.temporderMenuList.map(item=>{
                        if(item.Food_Status=='-1'){
                            item.is_type=1;
                        }
                    })
                }else{
                    this.temporderMenuList.map(item=>{
                        if(item.SubCategory_ID==this.foodSubIndex && item.Food_Status=='-1'){
                            item.is_type=1;
                        }
                    })
                }
                this.isSelectAll=true;
            }else{
                if(this.foodSubIndex==-1){
                    this.temporderMenuList.map(item=>{
                        if(item.Food_Status=='-1'){
                            item.is_type=0;
                        }
                    })
                }else{
                    this.temporderMenuList.map(item=>{
                        if(item.SubCategory_ID==this.foodSubIndex && item.Food_Status=='-1'){
                            item.is_type=0;
                        }
                    })
                }
                this.isSelectAll=false;
            }
        },
        /**同步确认 */
        syncConfirm(){
            if(this.remarksName){
                let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
                if(globalSysSetting.DiyEatMas.MAS_AUTOID){
                    let EatMas = {
                        Mas_AutoID:globalSysSetting.DiyEatMas.MAS_AUTOID,
                        Mas_Code:globalSysSetting.DiyEatMas.MAS_CODE,
                        Mas_Name:this.remarksName,
                        Is_AddMoney:globalSysSetting.DiyEatMas.IS_ADDMONEY,
                        Price_Kind:globalSysSetting.DiyEatMas.PRICE_KIND,
                        Mas_Price:globalSysSetting.DiyEatMas.MAS_PRICE
                        }
                    this.optFlavors.push(EatMas)
                }else{
                    this.$alert("门店缺少自定义口味的配置信息", {confirmButtonText: "确定"});
                    return
                }
            }
            this.$emit("confirm",this.optFlavors,this.disRadioOrder,this.temporderMenuList)
        },
        /**去除重复 */
        unique(newarr) {
            const res = new Map();
            return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
        }
    }
}
</script>

<style lang="scss">
@import './demandModel.scss';
</style>