<template>
  <modal class="enterBindingModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">企业团餐绑定<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body">
            <div class="clearfix" ref="CardMoney">
                <div class="left-box">
                        <div class="querymodule">
                            <div>订餐日期:</div>
                            <div class="timediv">
                                <el-date-picker class="from-date"
                                    :clearable="false"
                                    :editable="false"
                                    v-model="businessHours"
                                    type="date"
                                    placeholder=""
                                    :default-value="new Date()">
                                </el-date-picker>
                            </div>
                            <div class="orderType">订单类型:</div>
                            <div class="orderdiv">
                                <el-select v-model="salesValue" placeholder="请选择" class="from-order" >
                                    <el-option value="" label="全部"></el-option>
                                    <el-option
                                        v-for="item in rdGroupKindsList"
                                        :key="item.ConsumeType_AutoID"
                                        :label="item.ConsumeType_Name"
                                        :value="item.ConsumeType_AutoID">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="search">
                                <input type="text" placeholder="企业编码、企业名称、订餐号码检索" ref="idConding" v-model="selePretxt" @keyup.enter="getRdGroupMeals()"  />
                                <div class="deskAuto" @click="showKeyBoard($refs.idConding)" @mousedown="(e)=>e.preventDefault()">
                                    <span class="iconfont icon-jianpan"></span>
                                </div> 
                            </div>
                            <button class="btn" @click="getRdGroupMeals()">查询</button>
                        </div>
                        <div class="data-box">
                            <div class="panel-heading">
                                <div class="td order">序号</div>
                                <div class="td PreKind">订单类型</div>
                                <div class="td name">订餐人</div>
                                <div class="td deposit">人均标准/元</div>
                                <div class="td number">团餐人数</div>
                                <div class="td time">预定用餐时间</div>
                                <div class="td Field1">企业编码</div>
                                <div class="td Field2">企业名称</div>
                            </div>
                            <div class="scroll-box">
                                <div class="item-box" :class="{selected:selectData==item}" v-for="(item,index) in billPageList" :key="index" @click="currentChange(item)">
                                    <div class="tr">
                                        <div class="tag tag-limited" v-show="item.Eat_State==1">{{retState(item.Eat_State)}}</div>
                                        <div class="td order">{{(pageIndex-1)*pageSize+index+1}}</div>
                                        <div class="td PreKind">{{item.Eat_PreKind}}</div>
                                        <div class="td name">{{item.Eat_Name}}</div>
                                        <div class="td deposit red">¥{{Number(item.Eat_YDMsset).toFixed(2)}}</div>
                                        <div class="td number">{{item.Eat_ManNum}}</div>
                                        <div class="td time">{{(new Date(item.Eat_PreTime)).Format("yyyy-MM-dd hh:mm:ss")}}</div>
                                        <div class="td Field1">{{item.App_Field1}}</div>
                                        <div class="td Field2">{{item.App_Field2}}</div>
                                    </div>
                                    <div class="more-box" v-show="item.Eat_Tel!=''">
                                        <p>订餐号码：{{item.Eat_Tel}}</p>
                                    </div>
                                    <div class="more-box PreID" v-show="item.Eat_PreID!=''">
                                        <p>订单号：{{item.Eat_PreID}}</p>
                                    </div>
                                    <div class="more-box Bz" v-show="item.Eat_Bz!=''">
                                        <p>备注：{{item.Eat_Bz}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-search-box">
                            <div class="page-box-bottom">
                                <page-turning class="last-page" :pageCount="pageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                                <span class="page-number">{{pageIndex}}/{{pageCount}}页</span>
                                <page-turning class="next-page" :pageCount="pageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                            </div>
                        </div>
                </div>
            </div>
        </tabs>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-cancelzk" @click="bindRdGroupMeal(0)">取消绑定</button>
            <button class="btn btn-confirm" @click="bindRdGroupMeal(1)">绑定</button>
        </div>
        <analog-key-board ref="keyBoard" :isKeyDownEnter="true"></analog-key-board>
  </modal>
</template>


<script>
import {initKeyBoard} from '/src/common' 
import { httpAES,cacheData } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

/**全局初始化数据 */
let _data={
    /**中台对接的消费类型列表 */
    RdGroupMealKinds:'',
    }
/**会员支付 */
export default {
    name:"enterBindingModel",
    props:{
        isShow:Boolean,
        orderInfo:Object
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RdGroupMealKinds = globalSysSetting.RdGroupMealKinds;//中台对接的消费类型列表
        }
        return {
            //当前选中列
            currentInfo:null,
            corpInfoList:[],
            salesValue:'',
            //营业日期
            businessHours:new Date(),
            pageSize:8,
            pageIndex:1,
             /**用户数据 */
            userInfo:'',
            selePretxt:'',
            /**选中的数据 */
            selectData:null,
            /**订单类型 */
            rdGroupKindsList:[],
        }
    },
    mounted(){
       this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el);
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.rdGroupKindsList = _data.RdGroupMealKinds
            this.getRdGroupMeals()
            this.EmptyDatas()
        })
    },
    watch: {
        isShow(){ 
            if(this.isShow==true){
                this.EmptyDatas()
            }
        },
    },
    computed:{
        /**分页数据 */
        billPageList(){
            if(this.corpInfoList && this.corpInfoList.length>0){
                 return this.corpInfoList.slice(((this.pageIndex-1)*this.pageSize),this.pageIndex*this.pageSize);
            }
            return [];
        },
        pageCount(){
            let lenth=1;
            if(this.corpInfoList && this.corpInfoList.length>0){
                lenth=Math.ceil(this.corpInfoList.length/this.pageSize)||1;
            }
            return lenth;
        }
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.idConding.focus();
            },100);
        },
        hide(){
            this.$emit("closeModel")
        },
        retState(EatState){
            let retName=''
            if(EatState==1){
                retName='已绑定'
            }
            return retName
        },
        getRdGroupMeals(){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登录用户ID
                Eat_PreDate:(new Date(this.businessHours)).Format('yyyy-MM-dd'), //预定日期
                Eat_PreKind:this.salesValue, //消费类型ID
                SearchText:this.selePretxt, //查询字段
           }
            /**获取数据中 */
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetRdGroupMeals",param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode!=0){
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }else{
                    this.corpInfoList = data.ResponseBody
                    this.corpInfoList.map(co=>{
                        if(_data.RdGroupMealKinds){
                            _data.RdGroupMealKinds.map(rd=>{
                                if(co.Eat_PreKind==rd.ConsumeType_AutoID){
                                    co.Eat_PreKind = rd.ConsumeType_Name
                                }
                            })
                        }
                    })
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        currentChange(currentRow){
            this.selectData=currentRow
        },
        /**确认绑定 */
        bindRdGroupMeal(type){
            if(this.selectData){
                if(this.selectData.Eat_State==1 && type!=0){
                    this.$message.warning("该团餐已被绑定,无法重复绑定");
                    return
                }
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登录用户ID
                    Eat_State:type,//状态 0:取消绑定 1：绑定
                    Eat_PreAutoID:this.selectData.Eat_PreAutoID, //团餐订单主键
                    Eat_AutoID:this.orderInfo.Eat_AutoID, //订单主键
                    OrderTimestamps:this.orderInfo.Timestamps.toString(), //订单时间戳
                    Operate_User:this.userInfo?.Login_Name,//操作员
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operate_User = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                const loading = this.$loading({
                    text: "团餐绑定中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                console.log('绑定传参param:'+JSON.stringify(param))
                this.$httpAES.post("Bestech.CloudPos.BindRdGroupMeal",param).then((data)=>{
                    loading.close();
                    console.log('绑定返回data:'+JSON.stringify(data))
                    if(data?.ResponseHeader.ResultCode!=0){
                        this.$message.error(data?.ResponseHeader.ResultDesc);
                    }else{
                        
                        this.corpInfoList.map(co=>{
                            if(co.Eat_PreAutoID==this.selectData.Eat_PreAutoID){
                                co.Eat_State=type
                            }
                        })
                        this.selectData=''
                        if(type==1){
                            this.$message.success("团餐绑定成功");
                        }
                        if(type==2)
                        {
                            this.$message.success("团餐取消绑定成功");
                        }
                        this.$emit("RdGroupcloseModel",data.ResponseBody?.Timestamps)
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else{
                this.$message.warning('请先选中需要绑定的团餐数据');
            }
        },
        showKeyBoard(obj){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(obj);
                this.$refs.idConding.focus();
            }
        
        },
        /**清空页面数据 */
        emptyData(){
             this.corpInfoList=[]
             this.currentInfo=null
        },
    }
}
</script>

<style lang="scss">
@import "./enterBindingModel.scss";
</style>