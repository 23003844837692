<template>
  <div class="tab-nav" :class="{selected:show}" :tabindex="index" @click="tabClick()">
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: "tabNav",
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        index:0
      }
    },
    computed: {
      show(){
        if(this.$parent.currentName === this.index){
          return true;
        }
        return false;
      }
    },
    methods:{
      tabClick(e){
        if(this.$parent && this.$parent.handTabNavClick)
        this.$parent.handTabNavClick(this.index, this, e)
      }
    },
    mounted() {
      //添加nav
      if(this.$parent.addNav)
      this.$parent.addNav(this);
    },
    beforeUnmount() {
        if(this.$el && this.$el.parentNode){
            this.$el.parentNode.removeChild(this.$el);
        }
      // 当前组件销毁时清除父级 对当前对象的记录
      if(this.$parent && this.$parent.removeNav)
      this.$parent.removeNav(this);
    }
}
</script>

<style>

</style>