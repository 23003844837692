
/**账单类型 枚举 */
const orderEnum={
    /** 堂食中餐*/
    0:'堂食中餐',
    1:'堂食快餐',
    2:'外卖',
    3:'自提'
}

export {
	orderEnum
} 