<template>
    <div class="qrCode" ref="qrCode"></div>
</template>

<script>
import QRCode from 'qrcode'
export default {
    name:"qrCode",
    data() {
        return {
        };
    },
    props: {
        text: {
            type: String,
            default: "测试二维码",
        },
        width: {
            type: Number,
            default: 204,
        },
        height: {
            type: Number,
            default: 204,
        },
        colorDark: {
            type: String,
            default: "#000",
        },
        colorLight: {
            type: String,
            default: "#fff",
        },
        logoImage: {
            type: String,
            default: "",
        },
        logoMargin:{
            type: Number,
            default: 2,
        }
    },
    watch: {
        text() {
            this.createQrCode();
        },
    },
    mounted() {
        this.createQrCode();
    },
    methods: {
        createQrCode() {
            // 先清空
            this.$refs.qrCode.innerHTML = "";
            // 生成二维码
            QRCode.toCanvas(this.text||"测试二维码", {
                margin: 0,
                width: this.width, // 二维码宽度 不支持100%
                height: this.height, // 二维码高度 不支持100%
                colorDark: this.colorDark, // 二维码颜色
                colorLight: this.colorLight, // 二维码背景色
                correctLevel: "H", // 容错率L/M/H
            }).then(canvas=>{
                this.$refs.qrCode.appendChild(canvas);
                // 添加二维码中间的图片
                if(this.logoImage) {
                    let logo = new Image();
                    logo.setAttribute("crossOrigin", "Anonymous");
                    logo.src = this.logoImage;
                    logo.onload = () => {
                        let w=56,h=56;
                        let x=(this.width/2)-w/2,y=(this.height/2)-h/2;
                        let margin=this.logoMargin;
                        let ctx = canvas.getContext("2d");
                        ctx.fillStyle="#fff";
                        ctx.fillRect(x,y,w,h);
                        // 设置logo的大小为二维码图片缩小的3.7倍
                        ctx.drawImage(logo, x+margin,y+margin,w-margin*2,h-margin*2);
                    }
                }
            });
            
            
        }
    },
}
</script>

<style>

</style>