<template>
    <div class="tagSoldOut">
        <el-image style="width: 60%; height: 60%" src="/images/soldout.png" fit="contain" />
    </div>
</template>

<script>
export default {
    name:"tagSoldOut"
}
</script>

<style lang="scss">
.tagSoldOut{
    position:absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>