<template>
  <modal class="changeSetMealModel" :isShow="isShow" v-if="posFoodSetMealInfoList.SetMealInfo" @keyup.esc="confirm">
    <div class="header">
        已选择套餐名称：{{disRadioOrder.EAT_XFName}}￥{{disRadioOrder.Eat_SalePrice}}/份  &nbsp;&nbsp;  x {{disRadioOrder.Eat_Number}}份
    </div>
    <div class="modal-body clearfix">
        <div class="left-nav" ref="nav" >
            <div class="nav-li selected" data-index="0"><span>全部</span></div>
            <div class="nav-li" v-for="(item,index) in posFoodSetMealInfoList.SetMealInfo.SetMealKinds" :key="item" :data-index="index"><span>{{item.Kind_Name}}</span></div>
        </div>
        <div class="right-box clearfix">
            <div class="meal-box" v-mouse-scroll v-nav-roll="{nav:'nav'}">
                <div class="pane-box" v-for="item in posFoodSetMealInfoList.SetMealInfo.SetMealKinds" :key="item">
                    <div class="title">
                        {{item.Kind_Name}}<span>（必选项，最少选{{item.Select_Number}}份，最多选{{item.Count_Number}}份）</span>
                    </div>
                    <div class="item-box clearfix">
                        <!--selected-->
                        <div class="item-li verticalCenter" :class="{selected:disPosFoodClass==set.Food_ID}" v-for="set in item.SetMealDetails" :key="set"  v-show="set.Is_Selected==true && set.Food_Number>0" >
                            <div class="name" @click="FoodClikc(set,item)">
                                <div>{{set.Food_Name}}</div>
                                <div>({{set.Food_Number}}份)</div>
                            </div>
                            <i class="iconfont icon-gou-fine"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="exchangeSetMeal">
                <div class="title" >{{disPosFoodSet.Food_Name}}——可替换菜品选择</div>
                <div class="item-box clearfix" v-scroll-anime="{up:'right-order-up',down:'right-order-down',liClass:'.tr'}">
                    <div v-for="item in replacePosFoodList" :key="item">
                        <!--selected-->
                        <div class="item-li verticalCenter" v-for="set in item.SetMealDetails" :key="set" v-show="set.Is_Selected==false && set.Is_SoldOut==false">
                            <div class="name" @click="MealClick(set)">{{set.Food_Name}}</div>
                            <i class="iconfont icon-gou-fine"></i>
                        </div>
                    </div>
                    
                </div>
                <div class="item-operation">
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="right-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="right-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-confirm" @click="confirm()">返回</button>
    </div>
  </modal>
</template>

<script>
import {deepClone} from '/src/common' 
import { httpAES} from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus";

/**换套餐 */
export default {
    name:"changeSetMealModel",
    data(){
        return {
            /**套餐数量 */
            setMealNum:1,
            /**套餐数据 */
            posFoodSetMealInfoList:[],
            /**替换菜品数据 */
            replacePosFoodList:[],
            /**选择的菜品 */
            disPosFoodSet:'',
            disPosFoodClass:'',
            /**登录的用户信息*/
            userInfo:'',
            /**换菜成功返回数据 */
            ExchangeData:'',
            /**临时时间戳 */
            Timestamps:'',
            EatConsumeID:'',
            /**套餐数据 */
            SetMealKinds:[{
                Kind_Name:'主食',
                SetMealDetails:[{Food_Name:'凉面'}]
            },{
                Kind_Name:'小菜',
                SetMealDetails:[{Food_Name:'米饭'}]
            },{
                Kind_Name:'小食',
                SetMealDetails:[{Food_Name:'可乐'}]
            },{
                Kind_Name:'甜点',
                SetMealDetails:[{Food_Name:'凉面'}]
            }]
        }
    },
    props:{
        isShow:Boolean,
        disRadioOrder:Object,
        posFoodSetMealInfos:Object,
        orderInfo:Object
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        /*初始化数据*/
        init(){
            this.Timestamps = this.orderInfo.Timestamps.toString()
            this.ExchangeData=0
            this.disPosFoodSet=''
            this.disPosFoodClass=''
            this.setMealNum=1;
            this.posFoodSetMealInfoList=deepClone((this.posFoodSetMealInfos||[])[0]||{});
            this.posFoodSetMealInfoList.SetMealInfo.SetMealKinds.map(set=>{
                 set.SetMealDetails.map(item=>{
                     item.Is_Selected = false
                     this.disRadioOrder.Eat_TcItemList.map(eat=>{
                        if(item.Food_ID==eat.EAT_XFBMID){
                            item.Food_Number = eat.Eat_Number
                            item.Is_Selected = true
                        }
                     })
                 })
            })
        },
        /**选择菜品点击 */
        FoodClikc(set,item){
            if(set.Is_Fixed==true){
                this.$message.warning('该菜品是固定菜品,无法换菜');
                return
            }
            this.replacePosFoodList=[]
            this.replacePosFoodList.push(item)
            this.disRadioOrder.Eat_TcItemList.map(eat=>{
                if(eat.EAT_XFBMID==set.Food_ID){
                   this.EatConsumeID=eat.Eat_ConsumeID
                }
            })
            this.disPosFoodClass = set.Food_ID
            this.disPosFoodSet = set
        },
        /**替换菜品点击 */
        MealClick(set){
             this.$confirm('您确定将套餐中的【'+this.disPosFoodSet.Food_Name+'】换成【'+set.Food_Name+'】', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.userInfo=this.$auth.getUserInfo(); //获取用户id
                    console.log('orderInfo:'+JSON.stringify(this.orderInfo.Timestamps))
                    let param={
                            Body_ID:newGuid(),
                            User_ID:this.userInfo.User_ID,
                            Operator_Name:this.userInfo.Login_Name,
                            Eat_ConsumeID:this.EatConsumeID, //原菜品明细ID
                            EAT_XFBMID:set.Food_ID, //换菜菜品ID
                            Eat_AutoID:this.orderInfo.Eat_AutoID, //订单主键
                            OrderTimestamps:this.Timestamps  //桌台时间戳
                    }
                    if(sessionStorage.getItem("tempUserID")){
                        param.User_ID = sessionStorage.getItem("tempUserID")
                        param.Operator_Name = sessionStorage.getItem("tempName")
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    console.log('param:'+JSON.stringify(param))
                    const loading = this.$loading({
                        text: "换菜中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.ExchangeSetMealFood",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            ElMessage.success({ message: "换菜成功"});
                            this.Timestamps = data.ResponseBody.Timestamps.toString()
                            this.ExchangeData = data.ResponseBody
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                    this.disPosFoodSet.Is_Selected = false
                    set.Is_Selected = true
                    set.Food_Number = this.disPosFoodSet.Food_Number
                    this.replacePosFoodList=[]
                    this.disPosFoodSet=''
                    this.disPosFoodClass=''
                }).catch(() => {
                });
        },
        confirm(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("changeSetClick",this.ExchangeData)
        },
    },
    watch:{
        isShow(){
            if(this.isShow==true){
               this.init();
            }
        }
    }
}
</script>

<style lang="sass">
@import "./changeSetMealModel.scss";
</style>