<script>
import { createVNode,h,Fragment} from 'vue'
import { ElTableColumn,ElCheckbox} from 'element-plus'
let columnId=1;
export default {
	name:"tableColumnVir",
	props:{
		type:{
			type:String,
			default:""
		},
		prop:{
			type:String,
			default:""
		},
		label:{
			type:String,
			default:""
		},
		width:{
			type:[Number,String],
			default:""
		},
		minWidth:{
			type:[Number,String],
			default:0
		},
		/**对齐方式 left 左对齐 right 右对齐  */
		align:{
			type:String,
			default:""
		},
		/**列 冻结 */
		fixed:{
			type:String,
			default:null
		},
		className:{
			type:String,
			default:''
		},
		/**格式化 */
		formatter:{
			type:Function,
			default:null
		},
		/**导出时用 是否 使用渲染中的内容 默认true*/
		isRender:{
			type:Boolean,
			default:true 
		},
		/**导出时用 {"number":'n',"text":"s","boolean":"b","date":"d"} b布尔值，n数字，e错误，s字符串，d日期，z存根 */
		format:{
			type:String,
			default:'text'
		},
		/**导出时用 例：format:'number' ,formatz:'#.00' 设置数字小数位格式 */
		formatz:{
			type:String,
			default:''
		},
		/**排序 */
		sortable:{
			type:[String,Boolean],
			default:false
		}
	},
	watch:{
		fixed:{
            handler(){
                if(this.$parent?.tableColumns){
					if(this.fixed=="" || this.fixed=="left" || this.fixed=="right"){
						this.column.isFixed=true;
					}else{
						this.column.isFixed=false;
					}
				}
            },
            immediate:true
        },
		className(newVal){
			if(this.column)
			this.column.className=newVal;
		},
		prop(){
			if(this.column){
				this.column.property=this.prop;
			}
		}
	},
	setup(props,{slots}){
		return ()=>{
			let children=[];
			try {
				if(slots.default){
					const renderDefault =slots.default.call(slots,{ row: {}, column: {}, $index: -1 });
					if (renderDefault instanceof Array) {
						for (const childNode of renderDefault) {
							if(childNode.type?.name==="tableColumnVir"){
								children.push(childNode);
							}else if (childNode.type === Fragment && childNode.children instanceof Array) {
								renderDefault.push(...childNode.children);
							}
						}
					}
				}
			} catch (error) {
				children=[];
			}
			
			return h("div",children)
		}
	},
	data(){
		let tableId=this.$parent?.tableId||this.$parent?.column?.id||"";
		let id="_column_"+(columnId++);
		let width=parseInt(this.width)||null;
		let minWidth=parseInt(this.minWidth)||80;
		let renderdefault,renderHeader;
		if(this.type=="index"){
			if(!this.minWidth){
				minWidth=50;
			}
			if(!this.width){
				width=50;
			}
			
			renderdefault=({$index})=>{
				return createVNode("div",{
					class:"cell",
				},($index+1));
			}
			renderHeader=({column})=>{
				let vnode= column.label || '#';
				return createVNode("div",{
					class:"cell",
				},vnode);
			}
		}else if(this.type=="selection"){
			if(!this.minWidth){
				minWidth=40;
			}
			if(!this.width){
				width=40;
			}
			renderdefault=({row,column,store,$index})=>{
				let vnode=h(ElCheckbox,{
					disabled: column.selectable
						? !column.selectable.call(null, row, $index)
						: false,
					onInput: () => {
						store?.commit('rowSelectedChanged', row);
					},
					onClick: (event) => event.stopPropagation(),
					modelValue:store.isSelected(row),
				})
				return createVNode("div",{
					class:"cell",
				},[vnode]);
			}
			renderHeader=({store})=>{
				function isDisabled() {
					return store.states.data && store.states.data.length === 0;
				}
				let vnode=h(ElCheckbox, {
					disabled: isDisabled(),
					indeterminate: store.states.selection.length > 0 &&
						!store.states.isAllSelected,
					'onUpdate:modelValue': store.toggleAllSelection,
					modelValue: store.states.isAllSelected,
				});

				return createVNode("div",{
					class:"cell",
				},[vnode]);
			}
		}else{
			if(width>0 && !this.minWidth){
				minWidth=width;
			}
		}
		if(!renderdefault){
			if(this.$slots.default){
				renderdefault=({ row, column, $index})=>{
					let vnode=this.$slots.default({ row, column, $index});
					return createVNode("div",{
						class:"cell",
					},vnode);
				}
				
			}else{
				renderdefault=({ row, column})=>{
					let vnode=null;
					if(column && row){
						if(typeof(this.formatter)=="function"){
							vnode=this.formatter(row, column, row[column.property]);
						}else{
							vnode=row[column.property];
						}
					}
					return createVNode("div",{
						class:"cell",
					},vnode);
				}
			}
		}
		if(!renderHeader){
			if(this.$slots.header){
				renderHeader=({ column, $index})=>{
					let vnode=[this.$slots.header({ column, $index})];
					if(this.sortable){
						let order=h('span',{class:'caret-wrapper'},[
							h('i',{class:"sort-caret ascending"}),
							h('i',{class:"sort-caret descending"})
						])
						vnode.push(order)
					}
					return createVNode("div",{
						class:"cell",
					},vnode);
				}
			}else{
				renderHeader=({ column})=>{
					let vnode=[];
					if(column){
						vnode=[column.label];
					}
					if(this.sortable){
						let order=h('span',{class:'caret-wrapper'},[
							h('i',{class:"sort-caret ascending"}),
							h('i',{class:"sort-caret descending"})
						])
						vnode.push(order)
					}
					return createVNode("div",{
						class:"cell",
					},vnode);
				}
			}
		}

		return {
			columnId:id,
			column:{
				id:tableId+id,
				property:this.prop,
				width:width,
				minWidth:minWidth||80,
				label:this.label,
				renderCell:renderdefault,
				renderHeader:renderHeader,
				colspan:1,//列跨度
				rowspan:1,//行跨度
				align:this.align,
				className:this.className,
				formatter:this.formatter,
				isRender:this.isRender,
				format:this.format,
				formatz:this.formatz,
				sortable:this.sortable,
				type:this.type
			}
		}
	},
	mounted(){
		if(this.$parent?.tableColumns){
			let tableColumns=this.$parent.tableColumns;
			if(!tableColumns[0]){
				tableColumns[0]=[];
			}
			let nodes=this.$el.parentNode.children;
			let index=Array.from(nodes).findIndex(dom=>dom==this.$el);
			if(tableColumns[0].length>index){
				this.column.no=tableColumns[0].length;
				tableColumns[0].splice(index,0,this.column);//添加
			}else{
				this.column.no=tableColumns[0].length;
				tableColumns[0].push(this.column);//添加
			}
		}else if(this.$parent?.$?.type?.name==="tableColumnVir"){
			this.$parent.addChildren(this.column);
		}
	},
	methods:{
		//添加子集
		addChildren(column){
			if(!this.column.children){
				this.column.children=[];
			}else{
				this.column.colspan+=column.colspan;
			}
			column.no=this.column.children.length;
			
			this.column.children.push(column);
			this.addParentColumns(column,1);
		},
		//移除子集
		removeChildren(column){
			let index=this.column.children?.findIndex(it=>it.id==column.id);
			if(index>=0){//
				this.column.children.splice(index,1);
				if(this.column.children.length==0){
					delete this.column.children
				}
				this.column.colspan-=column.colspan;
				if(this.column.colspan<1){
					this.column.colspan=1;
				}
				this.reomveParentColumns(column,1);
			}
		},
		//向父级添加
		addParentColumns(column,row){
			if(this.$parent?.$?.type?.name==="tableColumnVir"){
				this.$parent?.addParentColumns(column,row+1);
			}else if(this.$parent?.tableColumns){
				let tableColumns=this.$parent.tableColumns;
				if(!tableColumns[row]){
					tableColumns[row]=[]
				}
				tableColumns[row].push(column);
			}
		},
		//向父级移除
		reomveParentColumns(column,row){
			if(this.$parent?.$?.type?.name==="tableColumnVir"){
				this.$parent?.reomveParentColumns(column,row+1);
			}else if(this.$parent?.tableColumns){
				let tableColumns=this.$parent.tableColumns;
				if(tableColumns[row]){
					let columnRows=tableColumns[row];
					let index=columnRows?.findIndex(it=>it.id==column.id);
					if(index>=0){//
						columnRows.splice(index,1);
						if(columnRows.length==0){
							tableColumns.splice(row,1);
						}
					}
				}
			}
		}
	},
	unmounted(){//移除
		if(this.$parent?.tableColumns){
			let columnRows=this.$parent.columnRows[0];
			let index=columnRows?.findIndex(it=>it.id==this.column.id);
			if(index>=0){//
				columnRows.splice(index,1)
			}

		}else if(this.$parent?.$?.type?.name==="tableColumnVir"){
			this.$parent.removeChildren(this.column);
		}
	}
}
</script>

<style>

</style>