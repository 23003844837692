<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
    name:"tabs",
    emits: ['update:modelValue','tabClick'],
    props: {
      modelValue: {
        type: [String, Number],
        default: ''
      }
    },
    data(){
      return {
        tabPanes: [],//导航显示内容 记录
        tabNavs:[],//导航记录
        currentName: this.modelValue || 0 //当前选中的 导航记录
      }
    },
    methods: {
        addNav(nav){
            this.tabNavs.push(nav);
            this.cheageNav();
        },
        removeNav(nav){
            let index = this.tabNavs.indexOf(nav);
            if(index > -1){
                this.tabNavs.splice(index, 1);
                if(this.currentName==index){
                    if(this.currentName>0){
                        this.currentName--;
                    }
                }else if(this.currentName>=this.tabNavs.length){
                    this.setCurrentName(this.tabNavs.length-1);
                }
                this.cheageNav();
            }
        },
        addPane(pane){
            this.tabPanes.push(pane);
            this.cheageNav();
        },
        removePane(pane){
            let index = this.tabPanes.indexOf(pane);
            if(index > -1){
                this.tabPanes.splice(index, 1);
                this.cheageNav();
            }
        },
        /**重新排序导航 */
        cheageNav(){
            this.tabNavs?.forEach((item,index)=>item.index=index);
            this.tabPanes?.forEach((item,index)=>item.index=index);
        },
        setCurrentName(name){//当前导航 选中
            if(this.currentName !== name){
                this.currentName = name;
                this.$emit('update:modelValue', name);
            }
        },
        // 标签页点击事件
        handTabNavClick(name, nav, e){
            if(this.currentName === name || nav.disabled){
                return;
            }
            this.setCurrentName(name);
            this.$emit('tabClick', nav, e);
        }
    },
    watch: {
        modelValue(newVal){
            this.setCurrentName(newVal);
        }
    }
}
</script>

<style>

</style>