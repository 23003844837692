
import webBrowser from './webBrowser.js'

const openBoard= function(app){
    app.directive("board", {
        mounted(el, binding) {//打开写字板
            let target=el;
            if(!target.addEventListener){
                target= el.querySelector("input");
            }

            //input 获取焦点打开写字板
            target.addEventListener("focus", ()=> {
                webBrowser.openInputBoard()
            });
        }
    })
}

/**input 控制键盘默认英文 */
const boardEnglish= function(app){
    app.directive("modeEn", {
        mounted(el, binding) {//打开写字板
            let target=el;
            
            if(!target.addEventListener || target.nodeName!="INPUT"){
                target= el.querySelector("input");
            }
            if(target){
                el._binding=binding;
                let type=target.type;
                //input 获取焦点打开写字板
                target.addEventListener("click", ()=> {
                    if(!(el._binding?.value===false)){
                        target.type="tel";
                        setTimeout(()=>{
                            target.type=type;
                        },100)

                        webBrowser.keyUpper();
                    }
                });
                target.addEventListener("focus", ()=> {
                    if(!(el._binding?.value===false)){
                        target.type="tel";
                        setTimeout(()=>{
                            target.type=type;
                        },100)
                        webBrowser.keyUpper();
                    }
                });
                
            }
        },
        updated(el,binding){
            el._binding=binding;
        }
    })
}


/**复制文本 */
const copyText=function(app){
    app.directive("copyText", {
        mounted(el) {
            let target=el;
            if(!target.addEventListener){
                target=el.querySelector("div");
            }
            target.addEventListener("click", (e)=> {
                selectText(target);
                copy(target.innerText||target.value);
                tipTxt(target)
            });
        }
    })

    /**选中文本 */
    function selectText(element) {
        element.style["user-select"]="text";
        element.style["-webkit-user-select"]="text";
        element.style["-webkit-touch-callout"]="text";

        if (document.body.createTextRange) {
            let range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
    /**复制 */
    function copy(text) {
        if (navigator.clipboard) {
            // clipboard api 复制
            navigator.clipboard.writeText(text);
        } else {
            var textarea = document.createElement('textarea');
            document.body.appendChild(textarea);
            // 隐藏此输入框
            textarea.style.position = 'fixed';
            textarea.style.clip = 'rect(0 0 0 0)';
            textarea.style.top = '10px';
            // 赋值
            textarea.value = text;
            // 选中
            textarea.select();
            // 复制
            document.execCommand('copy', true);
            // 移除输入框
            document.body.removeChild(textarea);
        }
    }
    let outTime,copy_success;
    function tipTxt(element){
        if(!copy_success){
            copy_success=document.createElement("div");
            copy_success.className="copy_success";
            copy_success.innerText="复制成功";
            document.body.appendChild(copy_success);
        }
        
        copy_success.style.display="";
        let rect=element.getBoundingClientRect();
        copy_success.style.top=(rect.top-40)+"px";

        let left=rect.left+rect.width/2-68/2;
        if(left>0){
            copy_success.style.left=left+"px";
        }else{
            copy_success.style.left=0;
        }
        if(outTime){
            clearTimeout(outTime);
        }
        outTime=setTimeout(()=>{
            copy_success.style.display="none";
            element.style["user-select"]="";
            element.style["-webkit-user-select"]="";
            element.style["-webkit-touch-callout"]="";
        },1500)
    }
}

export default {
    install(app){
        app.use(openBoard);
        app.use(boardEnglish);
        app.use(copyText);
    }
}
