<template>
    <div class="tableVirBody" :class="{isEmpty:!data?.length>0}">
        <div class="scroll-box" @scroll="scroll" v-mouse-scroll>
            <div class="table__body-box" :style="{height:bodyHeight+'px',width:bodyWidth+'px'}">
                
                <div class="table__flex-box" :style="{width:bodyWidth+'px',transform: 'translateY('+top+'px)'}">
                    <div class="span-box" v-if="visualSpan.length>0">
                        <div class="tdSpan" v-for="span in visualSpan" :key="span" 
                            :style="getSpanStyle(span)" 
                            :class="[getClass(span.column,{index:span.rowIndex,data:span.data})]"
                            @click="e=>cellClick(span.data,span.column,e)"
                            @dblclick="e=>cellDblclick(span.data,span.column,e)">
                            <cell :renderCell="()=>span.column.renderCell({$index:span.rowIndex,row:span.data,column:span.column,store:$parent.store})" ></cell>
                        </div>
                    </div>
                    <div class="fixed-left" v-if="visualColLeft.length>0" :style="{transform:'translateX('+scrollLeft+'px)'}">
                        <div class="span-box" v-if="visualLeftSpan.length>0">
                            <div class="tdSpan" v-for="span in visualLeftSpan" :key="span" 
                            :style="getSpanStyle(span)" 
                            :class="[getClass(span.column,{index:span.rowIndex,data:span.data})]"
                            @click="e=>cellClick(span.data,span.column,e)"
                            @dblclick="e=>cellDblclick(span.data,span.column,e)">
                                <cell :renderCell="()=>span.column.renderCell({$index:span.rowIndex,row:span.data,column:span.column,store:$parent.store})" ></cell>
                            </div>
                        </div>
                        <table class="table__body" :style="{width:fixedLeftWidth+'px'}" cellspacing="0" cellpadding="0" border="0">
                            <colgroup>
                                <col v-for="column in visualColLeft" :key="column" :name="column.id" :width="column.realWidth" />
                            </colgroup>
                            <tbody class="table_body">
                                <tr v-for="(item) in visualData" :key="item" 
                                    :class="trClassNames[item.index]"
                                    :data-vid="item.index" 
                                    :ref="(e)=>setRef(e)" 
                                    @click="rowClick(item.index,item.data)"
                                    :style="{height:trDataH[item.index]+'px'}">
                                    <td v-for="column in visualColLeft" :key="column" 
                                        :class="getClass(column,item)"
                                        @click="e=>cellClick(item.data,column,e)"
                                        @dblclick="e=>cellDblclick(item.data,column,e)"
                                    >
                                        <cell v-if="!colRowSpan[item.index+':'+column.index]" :renderCell="()=>column.renderCell({$index:item.index,row:item.data,column:column,store:$parent.store})" ></cell>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="body-box">
                        <table class="table__body" :style="{width:visualWidth+'px',transform: 'translateX('+left+'px)'}" 
                        cellspacing="0" cellpadding="0" border="0">
                            <colgroup>
                                <col v-for="column in visualColumns" :key="column" :name="column.id" :width="column.realWidth" />
                            </colgroup>
                            <tbody class="table_body">
                                <tr v-for="(item) in visualData" :key="item" 
                                    :data-vid="item.index" 
                                    :class="trClassNames[item.index]"
                                    :ref="(e)=>setRef(e)" 
                                    :style="{height:trDataH[item.index]+'px'}">
                                    <td v-for="column in visualColumns" :key="column" 
                                        :class="getClass(column,item)"
                                        @click="e=>cellClick(item.data,column,e)"
                                        @dblclick="e=>cellDblclick(item.data,column,e)"
                                    >
                                        <cell v-if="!colRowSpan[item.index+':'+column.index]" :renderCell="()=>column.renderCell({$index:item.index,row:item.data,column:column,store:$parent.store})" ></cell>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="fixed-right" v-if="visualColRight.length>0" :style="{transform:'translateX('+fixedRight+'px)'}">
                        <div class="span-box" v-if="visualRightSpan.length>0">
                            <div class="tdSpan" v-for="span in visualRightSpan" :key="span" 
                            :style="getSpanStyle(span)" 
                            :class="[getClass(span.column,{index:span.rowIndex,data:span.data})]"
                            @click="e=>cellClick(span.data,span.column,e)"
                            @dblclick="e=>cellDblclick(span.data,span.column,e)">
                                <cell :renderCell="()=>span.column.renderCell({$index:span.rowIndex,row:span.data,column:span.column,store:$parent.store})" ></cell>
                            </div>
                        </div>
                        <table class="table__body" :style="{width:fixedRightWidth+'px'}" cellspacing="0" cellpadding="0" border="0">
                            <colgroup>
                                <col v-for="column in visualColRight" :key="column" :name="column.id" :width="column.realWidth" />
                            </colgroup>
                            <tbody class="table_body">
                                <tr v-for="(item) in visualData" :key="item" 
                                    :class="trClassNames[item.index]"
                                    :data-vid="item.index" 
                                    :ref="(e)=>setRef(e)" :style="{height:trDataH[item.index]+'px'}">
                                    <td v-for="column in visualColRight" :key="column" 
                                        :class="getClass(column,item)"
                                        @click="e=>cellClick(item.data,column,e)"
                                        @dblclick="e=>cellDblclick(item.data,column,e)"
                                    >
                                        <cell v-if="!colRowSpan[item.index+':'+column.index]" :renderCell="()=>column.renderCell({$index:item.index,row:item.data,column:column,store:$parent.store})" ></cell>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="table__empty-block"><span class="table__empty-text">暂无数据</span></div>
    </div>
</template>

<script>
import { ElTable } from 'element-plus'
import cell from './cell.vue'
export default {
    name:"tableVirBody",
    components:{cell},
    emits:['cell-dblclick'],
    props:{
        /**横向栏目 */
        columns:{
            type:Array,
            default:new Array()
        },
        data:{
            type:Array,
            default:new Array()
        },
        summaryMethod:{
            type:Function,
            default:null
        },
        bodyWidth:{
            type:Number,
            default:null
        },
        /**行默认高度 */
        trHeight:{
            type:Number,
            default:25
        },
        spanMethod:{//合并行列方法
            type:Function,
            default:null
        },
        rowClassName:{//设置tr类名
            type:Function,
            default:null
        },
        cellClassName:{//设置td类名
            type:Function,
            default:null
        },
        highlightCurrentRow:{//高亮
            type:Boolean,
            default:false
        }
    },
    computed:{
        bodyHeight(){
            let height=0;
            this.trDataH.forEach(trH=>{
                height+=trH||this.trHeight;
            })
            return height;
        },
        /**可看到的宽度 */
        visualWidth(){
            let width=0;
            this.visualColumns.forEach(it=>{
                width+=it.realWidth||it.width||it.minwidth;
            })
            return width;
        },
        fixedLeftWidth(){
            let width=0;
            this.visualColLeft.forEach(it=>{
                width+=it.realWidth||it.width||it.minwidth;
            })
            return width;
        },
        fixedRightWidth(){
            let width=0;
            this.visualColRight.forEach(it=>{
                width+=it.realWidth||it.width||it.minwidth;
            })
            return width;
        },
        trClassNames(){
            let classNames=[]
            if(typeof(this.rowClassName)=="function"){
                this.visualData?.forEach(it=>{
                    classNames[it.index]=this.rowClassName({row:it.data})
                })
            }
            if(this.highlightCurrentRow!==false ){
                this.visualData?.forEach(it=>{
                    if(this.currentRow==it.data){
                        if(!classNames[it.index]) classNames[it.index]="";
                        classNames[it.index]+=" current-row"
                    }
                })
            }
            return classNames;
        },
        /**合并行列 */
        colRowSpan(){
            let span={};
            if(typeof(this.spanMethod)=="function"){
                this.data?.forEach((it,index)=>{
                    this.columns?.forEach((col,colIndex)=>{
                        let d=this.spanMethod({ row:it, column:col, rowIndex:index, columnIndex:colIndex });
                        if(d instanceof Array && d.length==2 && !(d[0]==1 && d[1]==1)){
                            span[index+":"+colIndex]={
                                rowspan:d[0],//行数
                                colspan:d[1],//列数
                                rowIndex:index,
                                colIndex:colIndex,
                                data:it,
                                column:col
                            };
                        }
                    })
                })
                
                Object.keys(span).forEach(key=>{
                    let d=span[key];
                    let rowIndex=d.rowIndex;
                    if(d.rowspan>1){
                        for(let i=d.rowIndex-1;i>=Math.max(d.rowIndex-d.rowspan,0);i--){//向上找
                            let perSpan=span[i+":"+key.colIndex];
                            if(perSpan?.rowspan[0]==0 && perSpan?.rowspan[0]==0){//
                                rowIndex=i;
                            }else{
                                break;
                            }
                        }
                    }
                    let colIndex=d.colIndex;
                    if(d.colspan>1){
                        for(let i=d.colIndex-1;i>=Math.max(d.colIndex-d.colspan,0);i--){//向左边找
                            let perSpan=span[rowIndex+":"+i];
                            if(perSpan?.rowspan[0]==0 && perSpan?.rowspan[0]==0){//
                                colIndex=i;
                            }else{
                                break;
                            }
                        }
                    }

                    if(d.rowspan>1 || d.colspan>1){
                        d.top=rowIndex;
                        d.left=colIndex;
                        for(let i=0;i<d.rowspan;i++){
                            for(let j=0;j<d.colspan;j++){
                                if(span[(rowIndex+i)+":"+(colIndex+j)]!=d){
                                    span[(rowIndex+i)+":"+(colIndex+j)]=d;
                                }
                            }
                        }
                    }
                })
            }
            return span;
        },
        /**可视位置的合并行列 */
        visualSpan(){
            let span=[];
            if(Object.keys(this.colRowSpan).length>0){
                this.visualData?.forEach(it=>{
                    this.visualColumns?.forEach(col=>{
                        let d=this.colRowSpan[it.index+":"+col.index];
                        if(d && !span.some(s=>s==d)){
                            span.push(d);
                        }
                    })
                })
            }
            return span;
        },
        /**可视位置的合并行列 */
        visualLeftSpan(){
            let span=[];
            if(Object.keys(this.colRowSpan).length>0){
                this.visualData?.forEach(it=>{
                    this.visualColLeft?.forEach(col=>{
                        let d=this.colRowSpan[it.index+":"+col.index];
                        if(d && !span.some(s=>s==d)){
                            span.push(d);
                        }
                    })
                })
            }
            return span;
        },
        /**可视位置的合并行列 */
        visualRightSpan(){
            let span=[];
            if(Object.keys(this.colRowSpan).length>0){
                this.visualData?.forEach(it=>{
                    this.visualColRight?.forEach(col=>{
                        let d=this.colRowSpan[it.index+":"+col.index];
                        if(d && !span.some(s=>s==d)){
                            span.push(d);
                        }
                    })
                })
            }
            return span;
        },
        fixedRight(){
            let left=0
            if(this.$el.clientWidth>0){
                if(this.$el.clientHeight<this.bodyHeight){
                    left= (this.scrollLeft+this.$el.clientWidth-6)-this.bodyWidth;
                }else{
                    left= (this.scrollLeft+this.$el.clientWidth)-this.bodyWidth;
                }
                
                if(left>0){
                    left=0;
                }
            }
            return left;
        }
    },
    watch:{
        bodyHeight(){
            this.$nextTick(()=>{
                if(this.$parent){
                    this.$parent.isScrollY=this.$el.clientHeight<this.bodyHeight;
                }
            })
        },
        scrollLeft:{
            handler(newVal){
                this.$parent.scrollLeft=newVal;
                this.visualCol();
            },
            immediate:true
        },
        scrollTop(){
            this.visual();
        },
        "data.length"(){
            this.trDataH=[];
            this.data?.forEach((it,i)=>{
                this.trDataH[i]=this.trHeight;
            })
            this.visual();
        },
        data:{
            handler(){
                this.trDataH=[];
                this.data?.forEach((it,i)=>{
                    this.trDataH[i]=this.trHeight;
                })
                this.visual();
            },
            immediate:true
        },
        columns:{
            handler(){
                this.doLayout();
            },
            immediate:true
        },
        visible(newVal){
            if(newVal){
                this.doLayout();
            }
        },
        bodyWidth(){
            // 宽度超过可视宽度 500px 时 启动虚拟列滚动
            if(this.$el?.clientWidth+500>this.bodyWidth){
                this.isColVir=true;
            }else{
                this.isColVir=false
            }
        },
        currentRow(){
            this.$parent.$emit('current-change',this.currentRow);
        }
    },
    data(){
        return {
            scrollLeft:0,
            scrollTop:0,
            /**记录每一个tr的高度 */
            trDataH:[],
            /**可视 数据 */
            visualData:[],
            /**可视 列数据 */
            visualColumns:[],
            visualColRight:[],
            visualColLeft:[],
            /**是否显示状态 */
            visible:false,
            always:true,
            top:0,
            left:0,
            /**上下滚动 缓冲高度 */
            buffH:100,
            /**左右滚动 缓冲宽度 */
            buffW:300,
            //列 虚拟滚动
            isColVir:false,
            //行 虚拟滚动
            isRowVir:false,
            //选中行
            currentRow:null
        }
    },
    updated(){
        this.$nextTick(()=>{
            this.visible=this.isVisible(this.$el);
        })
    },
    mounted(){
    },
    methods: {
        doLayout(){
            this.$nextTick(()=>{
                this.visualCol();
                setTimeout(()=>{
                    this.updateTrH();
                    this.visual();
                },5)
            })
        },
        scroll(e){
            this.scrollLeft=e.target.scrollLeft;
            this.scrollTop=e.target.scrollTop;
        },
        isVisible(elem){
            return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
        },
        /**可视区域计算 */
        visual(){
            if(this.visible){
                let visualH=this.$el.clientHeight;
                let scrollTop=this.scrollTop;
                let newData=[];
                let totalH=0;
                let top=0;
                for(let i=0;i<this.data.length;i++){
                    totalH+=this.trDataH[i]||this.trHeight;
                    if(scrollTop-this.buffH>totalH){
                        top=totalH;
                    }else if(totalH>scrollTop+visualH+this.buffH){
                        break;
                    }else{
                        newData.push({index:i,data:this.data[i]});
                    }
                }
                this.top=top;
                this.setData(newData);
            }
        },
        /**可视 列 区域计算 */
        visualCol(){
            if(this.visible){
                //左边列冻结
                let leftCol=[]
                let fixedLeft=0;
                for(let j=0;j<this.columns.length;j++){
                    if(!this.columns[j].isFixed){
                        break;
                    }
                    fixedLeft+=this.columns[j].realWidth;
                    leftCol.push(this.columns[j]);
                }
                this.visualColLeft=leftCol;
                
                //右边列冻结
                let rightCol=[]
                let fixedRight=0,rightIndex=this.columns.length;
                for(let k=this.columns.length-1;k>=0;k--){
                    if(!this.columns[k].isFixed){
                        break;
                    }
                    fixedRight+=this.columns[k].realWidth;
                    rightIndex=k;
                    rightCol.push(this.columns[k]);
                }
                this.visualColRight=rightCol;
                
                let newColumns=[];
                let left=0;
                if(this.isColVir){
                    for(let i=leftCol.length;i<rightIndex;i++){
                        let col=this.columns[i];
                        newColumns.push(col);
                    }
                }else{
                    let visualW=this.$el.clientWidth-fixedLeft-fixedRight;//可视宽度
                    let scrollLeft=this.scrollLeft;
                    let totalW=0;
                    for(let i=leftCol.length;i<rightIndex;i++){
                        let col=this.columns[i];
                        totalW+=col.realWidth;
                        if(scrollLeft-this.buffW>totalW){
                            left=totalW;
                        }else if(totalW>scrollLeft+visualW+this.buffW){
                            break;
                        }else{
                            newColumns.push(col);
                        }
                    }
                    
                }
                this.left=left;
                this.setColumn(newColumns);
            }
        },
        setColumn(newColumns){
            if(this.visualColumns.length>newColumns.length){
                this.visualColumns.splice(Math.max(newColumns.length-1,0),this.visualColumns.length);//
            }
            newColumns.forEach((it,index)=>{
                this.visualColumns[index]=it;
            })
        },
        setData(newData){
            if(this.visualData.length>newData.length){
                this.visualData.splice(Math.max(newData.length-1,0),this.visualData.length);//
            }
            newData.forEach((it,index)=>{
                if(!this.visualData[index]){
                    this.visualData[index]={};
                }
                this.visualData[index].index=it.index;
                this.visualData[index].data=it.data;
            })
            //this.setSpan();
        },
        /**设置合并 行列 */
        setSpan(){
            if(typeof(this.spanMethod)=="function"){
                this.visualData?.forEach(it=>{
                    if(!it.span){
                        it.span=[];
                    }
                    
                    this.visualColumns.forEach(col=>{
                        let span=[1,1];
                        if(typeof(this.spanMethod)=="function"){
                            span= this.spanMethod({ row:it.data, column:col, rowIndex:it.index, columnIndex:col.index});
                            if(!(span instanceof Array) || span?.length!=2){
                                span=[1,1];
                            }
                        }
                        if(!it.span[col.index]){
                            it.span[col.index]={};
                        }
                        it.span[col.index].rowspan=span[0];
                        it.span[col.index].colspan=span[1];
                    })
                })
            }
        },
        setRef(e){
            if(this.visible && e){
                this.$nextTick(()=>{
                    let index=e.dataset.vid;
                    if(index>=0){
                        if(this.trDataH[index]<e.clientHeight){
                            this.trDataH[index]=e.clientHeight;
                        }
                    }
                })
                
            }
        },
        getStyle(column){
            let json={}
            if(column.fixed=='left'){
                json.left= column.fixedLeft+'px';
            }else if(column.fixed=='right'){
                json.right= column.fixedRight+'px';
            }
            return json;
        },
        /**返回类名 */
        getClass(column,item){
            let className=[column.id,column.className];
            if(column.align=="left"){
                className.push('is-left');
            }else if(column.align=="right"){
                className.push('is-right');
            }

            if(typeof(this.cellClassName)=="function"){
                className.push(this.cellClassName({ row:item.data, column, rowIndex:item.index, columnIndex:column.index  }));
            }
            return className;
        },
        /**返回 合并行列 的 定位位置 及 宽 高 */
        getSpanStyle({left,top,rowspan,colspan}){
            let style={
                left:0,
                top:0,
                width:0,
                height:0
            }
            for(let i=0;i<this.columns.length;i++){
                if(i<left){
                    style.left+=this.columns[i].realWidth;
                }else if(i<left+colspan){
                    style.width+=this.columns[i].realWidth;
                }else{
                    break;
                }
            }
            for(let j=0;j<this.trDataH.length;j++){
                if(j<top){
                    style.top+=this.trDataH[j];
                }else if(j<top+rowspan){
                    style.height+=this.trDataH[j];
                }else{
                    break;
                }
            }
            return {
                left:style.left+"px",
                transform: 'translateY('+(style.top-this.top)+'px)',
                width:style.width+"px",
                height:style.height+"px",
            };
        },
        updateTrH(){
            this.data?.forEach((it,i)=>{
                this.trDataH[i]=this.trHeight;
            })
        },
        //列双击
        cellDblclick(row, column, event){
            this.$parent.$emit('cell-dblclick',row, column, event.currentTarget, event);
        },
        rowClick(rowIndex,row){
            this.currentRow=row
        },
        //点击列
        cellClick(row, column, event){
            this.currentRow=row;
            this.$parent.$emit('cell-click',row, column, event.currentTarget, event);
        }
    },
}
</script>

<style lang="scss">
.tableVirBody{
    border-spacing: 0;
    position: relative;

    &.isEmpty{
        .table__empty-block{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            z-index: 1;
        }

        .scroll-box{
            position:initial;
        }

        .table__body-box{
            position: relative;
            z-index: 2;

            .flex-box{
                display: none;
            }
        }

        .fixed-left{
            display: none;
        }

        .fixed-right{
            display: none;
        }
    }

    .span-box{
        position: relative;
        height: 0;
    }
    .tdSpan{
        position: absolute;
        z-index: 1;
        background-color: #fff;
        border-right: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);
        text-align: center;

        &.isFixed{
            position: sticky;
            z-index: 9;
        }

        &::after{
            content: "";
            display: inline-block;
            height: 100%;
            width: 0;
            vertical-align: middle;
        }

        .cell{
            display: inline-block;
            vertical-align: middle;
            width: 100%;
        }
        
        &.is-left{
            text-align: left;
        }

        &.is-right{
            text-align: right;
        }
    }

    
    .table__flex-box{
        position: absolute;
        display: flex;
        min-height: 100%;

        .fixed-left{
            //position: sticky;
            position: relative; 
            //left:0;
            z-index: 9;
            -webkit-box-shadow: 1px 0 10px rgba(0,0,0,0.21);
            box-shadow:  1px 0 10px rgba(0,0,0,0.21);
            overflow: hidden;
        }

        .body-box{
            flex: 1;
        }

        .fixed-right{
            //position: sticky;
            position: relative; 
            //right:0;
            z-index: 9;
            -webkit-box-shadow: 0 0 0 1px var(--borderColor), -1px 0 10px rgba(0,0,0,0.21);
            box-shadow: 0 0 0 1px var(--borderColor), -1px 0 10px rgba(0,0,0,0.21);
            overflow: hidden;
        }
    }
    .sticky{
        position: sticky;
        left:0
    }

    .flex{
        flex: 1;
    }

    .stickyRight{
        position: sticky;
        right: 0;
    }

    .table__empty-block{
        display: none;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        min-height: 60px;
        text-align: center;
        width: 100%;
        

        .table__empty-text{
            line-height: 60px;
            width: 50%;
            color: #909399;
        }
    }

    // .is-fixed-shadow{

    //     &.is-fixed-right{
    //         box-shadow: -2px 0 6px rgba(0, 0, 0, 0.2);
    //     }
        
    //     &.is-fixed-left{
    //         box-shadow: 2px 0 6px rgba(0,0,0,0.2);
    //     }
    // }

    .scroll-box{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: 1;
    }
    
    .el-scrollbar__view{
        position: relative;
    }
    .table__body-box{
        overflow: hidden;
        min-height: 100%;
    }
    // .table__body{
    //     position: absolute;
    //     left:0;
    // }

    th,td{
        margin: 0;
        padding: 0;
    }

    .current-row {
        background: #FFF5DF !important;
        td{
            background: #FFF5DF;
        }
    }
    .table-thead,.table-footer{
        background-color: #fff;
        margin: 0;
        padding: 0;
    }
}
</style>