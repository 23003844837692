<template>
  <modal class="paymentlayerModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">付款确认
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body" ref="paymetMoney">
        <div>
            <div class="paymentMoney" :class="{type:this.choicePayType.INTERFACE_TYPE==1}">待支付金额 :
                <span class="pay-money">￥{{Number(this.unPaidMoney).toFixed(2)}}</span>
            </div>
            <div class="payChangeMoney" v-show="this.choicePayType.INTERFACE_TYPE==1">找&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;零 :
                <span class="pay-money">{{this.ChangMoney}}</span>
            </div>
            <div class="paymethod">付 款 方 式 :
                <span class="paymethod-lower">{{this.choicePayType.PAY_NAME}}</span>
            </div>
            <div class="payamount">付 款 金 额 :
                <input-pattern ref="money" data-model='money' v-model="money" @keyup.enter="inputCursor()"  @focus="(e)=>e.target.select()"  pattern="money" />
            </div>
        </div>
        <div class="keyboard">
            <div class="right-key">
                <table>
                    <tr>
                        <td><span data-value="10">10</span></td>
                        <td><span data-value="7">7</span></td>
                        <td><span data-value="8">8</span></td>
                        <td><span data-value="9">9</span></td>
                        <td><span data-value="clean">C</span></td>
                    </tr>
                    <tr>
                        <td><span data-value="20">20</span></td>
                        <td><span data-value="4">4</span></td>
                        <td><span data-value="5">5</span></td>
                        <td><span data-value="6">6</span></td>
                        <td><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span></td>
                    </tr>
                    <tr>
                        <td><span data-value="50">50</span></td>
                        <td><span data-value="1">1</span></td>
                        <td><span data-value="2">2</span></td>
                        <td><span data-value="3">3</span></td>
                        <td rowspan='2'><button class="confirm-btn" @click="confirm()">确认</button></td>
                    </tr>
                    <tr>
                        <td><span data-value="100">100</span></td>
                        <td><span data-value=".">.</span></td>
                        <td><span data-value="0">0</span></td>
                        <td><span data-value="00">00</span></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 

/**会员支付 */
export default {
    name:"paymentlayerModel",
    props:{
        isShow:Boolean,
        unPaidMoney:Number,
        choicePayType:Object
    },
    data(){
        return {
            money:'',
            ChangMoney:''
        }
    },
    mounted(){
       this.$nextTick(()=> {
            initKeyBoard.call(this,this.$refs.paymetMoney,this.$refs.money.$el);
            this.money =  Number((Number(this.unPaidMoney)).toFixed(2))
            //this.number=this.disRadioOrder?.Eat_Number;
            this.EmptyDatas()
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            this.EmptyDatas();
        },
        money(){
            if(this.money>Number((Number(this.unPaidMoney)).toFixed(2))){
                this.ChangMoney = Number(Number(this.money - this.unPaidMoney).toFixed(2))
            }else{
                this.ChangMoney=''
            }
        },
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.money.focus();
            },100);
        },
        inputCursor(){
            this.confirm()
        },
        hide(){
            this.$emit("closeModel")
        },
        /**确定和回车事件 */
        confirm(){
            if(Number(this.money)>=0){
                this.$emit('cashConfirm',this.money)
            }else{
                this.$message.warning("付款金额必须大于0!");
                return
            }
            
        },
    }
}
</script>

<style lang="sass">
@import "./paymentlayerModel.scss";
</style>