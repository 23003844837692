<template>
    <div class="editBtns">
        <div class="btn" v-for="item in bntList" :key="item"  @click="item.click">
            <template v-if="item.isSvg">
                <svg class="iconfont icon-svg" aria-hidden="true"><use :xlink:href="item.icon"></use></svg>
            </template><i class="iconfont" :class="item.icon" v-else></i>{{item.text}}
            <template v-if="item.num>=0">
                <b class="btn-number">{{item.num}}</b>
            </template>
        </div>

        
        <slot></slot>
    </div>
</template>

<script>
export default {
    name:"editBtns",
    emits:["queryClick","saveClick","deleteClick","batchDeleteClick","readCardClick","qingzhangClick","zhuanzhangClick","yufukuanClick","buzhangClick","chexiaoClick"
            ,"lookLogClick","outputClick","refreshClick","goBackClick","exitClick",'notAutoCancelSellOutClick'],
    props:{
        /**
         * query 查询 save 保存 delete 删除 readCard 读卡 lookLog 查看日志
         * output 输出 refresh 刷新 goBack 返回 exit 退出
         */
        bnts:{
            typeof:Array,
            default:['save','output','refresh','goBack']
        },
        /**是否按 传入的数组排序 */
        isOrder:{
            typeof:Boolean,
            default:false
        }
    },
    computed:{
        bntList(){
            if(this.isOrder){
                let list=[];
                this.bnts?.forEach(it=>{
                    let item;
                    if(typeof(it)=='string'){
                        item= this.list.find(item=>item.key==it);
                    }else if(typeof(it)=='object'){
                        item= this.list.find(item=>item.key==it.key);
                        if(item){
                            item=Object.assign({},item,it);
                        }
                    }
                    if(item){
                        list.push(item);
                    }
                })
                console.log(list)
                return list;
            }
            let list=[];
            this.list.forEach(it=>{
                let item=this.bnts?.find(item=>item==it.key || item.key==it.key);
                if(item){
                    if(typeof(item)=='object'){
                        list.push(Object.assign({},it,item));
                    }else{
                        list.push(it);
                    }
                }
            })
            return list;
        }
    },
    data(){
        return {
            list:[
                {key:"query",text:"查询",icon:"icon-chaxun",click:()=>{this.$emit('queryClick')}},
                {key:"save",text:"保存",icon:"icon-Save",click:()=>{this.$emit('saveClick')}},
                {key:"delete",text:"删除",icon:"#icon-delete",click:()=>{this.$emit('deleteClick')},isSvg:true},
                {key:"batchDelete",text:"批量删除",icon:"#icon-delete",click:()=>{this.$emit('batchDeleteClick')},isSvg:true},
                {key:"crear",text:"清除缓存",icon:"icon-shanchu",click:()=>{this.$emit('crearClick')}},
                {key:"readCard",text:"读卡",icon:"icon-duka",click:()=>{this.$emit('readCardClick')}},
                {key:"lookLog",text:"查看日志",icon:"icon-chakan",click:()=>{this.$emit('lookLogClick')}},

                {key:"add",text:"新增",icon:"icon-xinzeng",click:()=>{this.$emit('addClick')}},
                {key:"copyAdd",text:"复制新增",icon:"icon-fuzhixinzeng",click:()=>{this.$emit('copyAddClick')}},
                {key:"renovate",text:"停用",icon:"icon-tingyong",click:()=>{this.$emit('renovateClick')}},
                {key:"startusing",text:"启用",icon:"icon-qiyong",click:()=>{this.$emit('startusingClick')}},

                {key:"transactionReport",text:"第三方交易流水表",icon:'icon-jieban',click:()=>{this.$emit('transactionReportClick')}},
                {key:"qingzhang",text:"清账",icon:"icon-qingzhang",click:()=>{this.$emit('qingzhangClick')}},
                {key:"zhuanzhang",text:"转账",icon:"icon-buzhang-1",click:()=>{this.$emit('zhuanzhangClick')}},
                {key:"yufukuan",text:"预付款",icon:"icon-yufukuan",click:()=>{this.$emit('yufukuanClick')}},
                {key:"yufukuanhexiao",text:"预付款核销",icon:"icon-yufukuanhexiao",click:()=>{this.$emit('yufukuanhexiaoClick')}},
                {key:"buzhang",text:"补账",icon:"icon-buzhang",click:()=>{this.$emit('buzhangClick')}},
                {key:"chexiao",text:"撤销",icon:"icon-chexiao",click:()=>{this.$emit('chexiaoClick')}},

                {key:"lishi",text:"历史限量",icon:"icon-lishixianliang",click:()=>{this.$emit('lishiClick')}},
                {key:"moban",text:"模板限量",icon:"icon-mobanxianliang",click:()=>{this.$emit('mobanClick')}},
                {key:"weihu",text:"维护模板限量",icon:"icon-weihu",click:()=>{this.$emit('weihuClick')}},
                {key:"notAutoCancelSellOut",text:"设置不自动取消沽清",icon:"icon-weihu",click:()=>{this.$emit('notAutoCancelSellOutClick')}},
                {key:"copy",text:"一键复制",icon:"icon-yijianfuzhi",click:()=>{this.$emit('copyClick')}},

                {key:"recalculate",text:"按设置重新核算",icon:"icon-tuihuizhongxinhesuan",click:()=>{this.$emit('recalculateClick')}},

                {key:"hide",text:"批量隐藏",icon:"icon-yincang",click:()=>{this.$emit('hideClick')}},
                {key:"show",text:"查看隐藏",icon:"icon-xianshi",click:()=>{this.$emit('showClick')}},

                {key:"printJieZhang",text:"重印结账单",icon:"icon-zhongyinjiezhangdan",click:()=>{this.$emit('printJieZhangClick')}},
                {key:"printXiaoFei",text:"重印消费单",icon:"icon-zhongyinxiaofeidan",click:()=>{this.$emit('printXiaoFeiClick')}},
                {key:"editOrderTaiTou",text:"修改账单抬头",icon:"icon-xiugai1",click:()=>{this.$emit('editOrderTaiTouClick')}},
                {key:"refCheckOut",text:"结账恢复",icon:"icon-jiezhanghuifu",click:()=>{this.$emit('refCheckOutClick')}},
                {key:"tuicai",text:"退菜",icon:"icon-tuicai1",click:()=>{this.$emit('tuicaiClick')}},
                {key:"chonghong",text:"冲红",icon:"icon-chonghong",click:()=>{this.$emit('chonghongClick')}},
                {key:"orderTrack",text:"订单轨迹",icon:"icon-dingdanguiji",click:()=>{this.$emit('orderTrackClick')}},

                {key:"output",text:"输出",icon:"icon-shuchu",click:()=>{this.$emit('outputClick')}},
                {key:"refresh",text:"刷新",icon:"icon-shuaxin",click:()=>{this.$emit('refreshClick')}},
                {key:"goBack",text:"返回",icon:"icon-fanhui",click:()=>{this.$emit('goBackClick')}},
                {key:"exit",text:"退出",icon:"icon-tuichu2",click:()=>{this.$emit('exitClick')}},
            ]
        }
    },
    methods:{
    }
}
</script>

<style lang="scss">
.editBtns{
    box-shadow: 0 2px 6px 3px rgba(198,198,198,0.10);
    border-radius: 4px;
    background-color: #fff;

    .btn{
        position: relative;
        background: #FFFFFF;
        line-height: 40px;
        font-size: 14px;
        color: #8a8a8a;
        min-width: 60px;
        padding: 0 20px;
        
        display: inline-block;
        cursor: pointer;

        .iconfont{
            font-size: 16px;
            margin-right: 5px;
        }

        &::after{
            position: absolute;
            top:calc(50% - 8px);
            right: -1px;
            content: "";
            vertical-align: middle;
            display:inline-block;
            height: 16px;
            border: 1px solid transparent;
            border-color: transparent #8a8a8a transparent #bdbdbd ;
        }

        &:last-child{
            &::after{
                display: none;
            }
        }

        .btn-number{
            position: absolute;
            top: 5px;
            right: 10px;
            background-color: #f56464;
            border-radius:14px;
            color: #fff;
            font-size: 10px;
            height: 14px;
            line-height: 14px;
            display: inline-block;
            min-width: 14px;
            text-align: center;
            padding: 0 3px;
        }
    }
}
</style>