<template>
  <modal class="checkUserRightModel" :isShow="isShow" @keyup.esc="close">
    <div class="modal-header">
        授权其他用户进行操作
        <div class="close" @click="close()">×</div>
    </div>
    <div class="modal-body">
        <div class="loginbox">
            <div class="flex-box">
                <div class="flex-grow-0" :class="{Tabs:isPhoneVerify}">
                    <div class="login-title" :class="{Tabs:isPhoneVerify}"><img width="40" height="42" src="/images/BSLogo.png" />
                        <span style="position:absolute;">{{Department_Name}}</span>
                    </div>
                     <div class="filter-nav clearfix" v-if="isPhoneVerify">
                        <div class="nav-box" style="position:absolute;overflow: hidden;" >
                            <div class="nav-li" :class="{selected:filternav==1}" @click="tabsClick(1)">账号密码登录</div>
                            <div class="nav-li" :class="{selected:filternav==2}" @click="tabsClick(2)">手机登录</div>
                        </div>
                    </div>
                    <div class="li" v-show="filternav==1">
                        <div class="lable-txt"><span class="iconfont icon-yonghu"></span></div>
                        <input type="text" ref="userName" class="key-focus" data-model='userName' @focus="tabIndex=1" v-model="userName" placeholder="请输入用户名" @keyup.enter="KeyClick(1)" />
                    </div>
                    <div class="li" v-show="filternav==1">
                        <div class="lable-txt"><span class="iconfont icon-password"></span></div>
                        <input type="password" ref="pasword" class="key-focus" @focus="tabIndex=2" data-model='userPW' v-model="userPW" placeholder="请输入密码" @keyup.enter="KeyClick(2)" />
                    </div>
                    <div class="li" v-show="filternav==2">
                        <div class="lable-txt"><span class="iconfont icon-yonghu"></span></div>
                        <input type="text" ref="moblie" class="key-focus" data-model='moblie' @focus="tabIndex=3" v-model="moblie" placeholder="请输入手机号" @keyup.enter="KeyClick(3)" />
                    </div>
                    <div class="li" v-show="filternav==2">
                        <div class="lable-txt"><span class="iconfont icon-password"></span></div>
                        <input type="text" ref="veriCode" class="key-focus" :class="{Code:filternav==2}" @focus="tabIndex=4" data-model='veriCode' v-model="veriCode" placeholder="请输入验证码" @keyup.enter="KeyClick(4)" />
                        <div class="div-Code" @click="codeClick()">{{codeTips}}</div>
                    </div>
                    <div class="li"><button type="button" class="btn bnt-login" @click="authorize()">授权</button></div>
                </div>
                <div class="flex-grow-1 keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    <div class="key-li"><span data-value="clean">清除</span><span class="bnt-confirm" data-value=""
                            @click="next">确认</span></div>
                </div>
            </div>
        </div>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard,newGuid} from '/src/common/index.js';
import { cacheData } from '/src/services'
/**全局初始化数据 */
let _data={
    OpenSMSCheckUserRights:false //启用【短信验证权限】功能 注：请配置短信账号
};

export default {
    name:'checkUserRightModel',
    emits:["confirm","closeModel"],
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.OpenSMSCheckUserRights = globalSysSetting.OpenSMSCheckUserRights;
        }
        return{
            Department_Name:'',
            userName:'',
            userPW:'',
            tabIndex:0,
            menuKey:'',
            //赠送，退菜，改是时操作的消费明细ID
            consumeID:'',
            //赠送，退菜，改是时的数量,尾数调整时的尾数金额"
            checkNumber:'',
            //赠送，退菜，改是时操作的订单ID"
            Check_OrderID:"",
            //确认
            confirm:null,
            //取消
            cancel:null,
            //是否开启读卡登录 功能
            isRedCardLogin:false,
            //是否停止读卡登录
            isStopRedCard:true,
            //输入框监听 卡号
            inputCard:"",
            //第一次输入时间
            inputStartTime:0,
            // 输入间隔 100毫秒
            inoutSpaceTime:500,
            //是否开启标签切换
            isPhoneVerify:true,
            /*当前选中的验证方式*/
            filternav:1,
            moblie:'',//手机号
            veriCode:'',//验证码
            codeDown:0,
            codeTips:'发送验证码',
            isCode:false
        }
    },
    props:{
         isShow:Boolean,
    },
    mounted(){
        if(!this.$global.devices || !this.$global.devices.uuid){
            this.$webBrowser.getDevices().then(data=>{
                if(data){
                    this.$global.devices=data;
                }
            })
        }
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el);
            //默认焦点
            setTimeout(() => {
                this.$refs?.userName?.focus();
            },300);
            this.isPhoneVerify = _data.OpenSMSCheckUserRights
            let userInfo=this.$auth.getUserInfo();
            if(userInfo){
				this.Department_Name=userInfo.Department_Name;
			}

            this.$webBrowser.getBaseSetup().then(d=>{
                if(d?.isRedCardLogin){
                    this.isRedCardLogin=d?.isRedCardLogin;
                    this.startCardRed();//开启读卡
                }
            })
        })
    },
    watch:{
        isShow(value){
            if(value){
                this.userName='';
                this.userPW='';
                this.isLock=true;
                this.isPhoneVerify = _data.OpenSMSCheckUserRights
                //默认焦点
                setTimeout(() => {
                    this.$refs?.userName?.focus();
                },300);
                this.$webBrowser.getBaseSetup().then(d=>{
                    if(d?.isRedCardLogin){
                        this.isRedCardLogin=d?.isRedCardLogin;
                        this.startCardRed();//开启读卡
                    }
                })
            }else{
                this.create();
            }
        },
        userName(newVal,oldVal){
            if(!oldVal || (oldVal && (newVal||"").indexOf(oldVal)==0)){
                let time=(new Date()).getTime();
                if(this.inputStartTime+this.inoutSpaceTime<time){
                    this.inputStartTime=time;
                    this.inputCard="";
                }
                let oldLeng=oldVal?.length||0;
                this.inputCard+=newVal.substring(oldLeng);
            }
        },
        codeDown:{
            handler(value) {
                setTimeout(() => {
                    if(this.codeDown>1){
                        this.codeDown--;
                        this.codeTips='重新发送('+this.codeDown+')'
                    }else{
                        this.codeDown=0
                        this.isCode=false
                        this.codeTips='发送验证码'
                    }
                }, 1000);
            },
            immediate: true
        }
    },
    methods:{
        //点击发送验证码
        codeClick(){
            if(this.isCode==false && this.codeDown==0){
                this.isCode=true
                const  reg = /^1[0-9]{10}$/
                if(this.moblie.length<10 || !reg.test(this.moblie)){
                    this.$message.warning('请输入正确的手机号');
                    this.$refs.moblie.select();
                    this.isCode=false
                    return
                }
                if(this.codeDown==0){
                    //默认焦点
                    setTimeout(() => {
                        this.$refs?.veriCode?.focus();
                    },500);
                    this.smsCodeAPI()
                }
                this.isCode=false
            }
        },
        //短信验证码
        smsCodeAPI(){
            let userInfo=this.$auth.getUserInfo();
            let param={
                Body_ID:newGuid(),
                Mobile:this.moblie, //手机号
                Operator_Name:userInfo?.Login_Name,//操作员
                Menu_Key:this.menuKey
            }
            const loading = this.$loading({
                text: "发送短信中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('param:'+JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.SendSMSCode",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc); 
                    this.codeDown=0
                }else{
                    //开始倒数
                    this.codeDown=d.ResponseBody?.InvalidSeconds;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.ResponseHeader.ResultDesc, "提示", {confirmButtonText: "确定"});
            })
        },
        //效验短信
        CheckUserSMS(){
            let userInfo=this.$auth.getUserInfo();
            let param={
                Body_ID:newGuid(),
                Mobile:this.moblie, //手机号
                SMS_Code:this.veriCode,//验证码
                Operator_Name:userInfo?.Login_Name,//操作员
            }
            const loading = this.$loading({
                text: "验证码校验中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CheckUserSMS",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc);    
                }else{
                    this.userName=d.ResponseBody?.User_Code;
                    this.userPW=d.ResponseBody?.Pwd;
                    this.authorizeAPI()
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.ResponseHeader.ResultDesc, "提示", {confirmButtonText: "确定"});
            })
        },
        tabsClick(type){
            this.filternav=type
            if(type==1){
                //默认焦点
                setTimeout(() => {
                    this.$refs?.userName?.focus();
                },500);
                this.userName='';
                this.userPW='';
            }
            if(type==2){
                //默认焦点
                setTimeout(() => {
                    this.$refs?.moblie?.focus();
                },500);
                if(this.codeDown<=0){
                    this.moblie='';
                    this.veriCode='';
                }
            }
        },
        close(){
            if(typeof(this.cancel)=="function"){
                this.cancel();
            }
            this.$emit("closeModel")
        },
        /**input回车 */
        KeyClick(type){
            if(this.isShow){
                let time=(new Date()).getTime();
                if(type==1 && this.inputCard?.length>6 && this.inputStartTime+this.inoutSpaceTime>time){//输入名称回撤
                    this.authorize({
                        Login_Type:3,//刷卡登录
                        Card_ID:this.inputCard
                    })
                    return;
                }

                if(type==1){
                    this.$refs.pasword.focus();
                }else if(type==3){
                    const  reg = /^1[0-9]{10}$/
                    if(this.moblie.length<10 || !reg.test(this.moblie)){
                        this.$message.warning('请输入正确的手机号');
                        this.$refs.moblie.select();
                        return
                    }
                    this.codeClick()
                }else{
                    this.authorize();
                }
            }
        },
        next(){
            if(this.isShow){
                if(this.tabIndex==1){
                    this.$refs.pasword.focus();
                }
                else if(this.tabIndex==2 || this.tabIndex==4){
                    this.authorize();
                }else if(this.tabIndex==3){
                    this.$refs.veriCode.focus();
                }
            }
        },
        //开启读卡
        startCardRed(){
            if(this.isRedCardLogin && this.isStopRedCard){
                this.isStopRedCard=false;
                this.redTime=setTimeout(()=>{
                    this.redCardLoain();
                },2000);//间隔2秒 读卡
            }
        },
        redCardLoain(){
            if(this.isStopRedCard || this.isLogin || this.layer || !this.isShow){
                return;
            }
            this.$webBrowser.redCardM1().then((d)=>{
                if(d && !this.isStopRedCard){
                    if(d.state==0){
                        if(!d.data.snr){
                            this.$message.warning({ message: "卡号为空,请换张卡!"});
                            return;
                        }
                        this.isStopRedCard=true;
                        this.authorize({
                            Login_Type:3,//刷卡登录
                            Card_ID:d.data.snr
                        })
                        return;
                    }
                    this.redDelayedTime=setTimeout(()=>{
                        this.redCardLoain();
                    },2000);//间隔2秒 读卡
                }
            })
        },
        //授权
        authorize(paramData){
            if(this.filternav==2){
                if(this.veriCode){
                    this.CheckUserSMS()
                }else{
                    this.$message.warning({ message: "验证码不能为空"});
                }
                
            }else{
                this.authorizeAPI(paramData)
            }
        },
        authorizeAPI(paramData){
            if(!paramData){
                if (this.userName == "" || this.userName == null) {
                    this.$message.warning({ message: "用户名不能为空"});
                    return;
                }
            }
            if(this.isLogin) return;
            this.isLogin=true;
            const loading = this.$loading({
                text: "请求加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Login_Type:0,//登录类型 0—账号密码登录 ，3—刷卡登录
                Login_Name:this.userName,
                Login_Pwd:this.userPW,
                MenuKey:this.menuKey,
                Order_Type:0 //订单类型 0—中餐 ，1—快餐
            }
            Object.assign(param,paramData);
            if(this.consumeID!=''){
                Object.assign(param, {Check_ConsumeID:this.consumeID})
            }
            if(this.checkNumber!=''){
                Object.assign(param, {Check_Number:this.checkNumber})
            }
            if(this.Check_OrderID){
                param.Check_OrderID=this.Check_OrderID;
            }
            this.$httpAES.post("Bestech.CloudPos.CheckUserRight",param,false).then((d)=>{
                loading.close();
                this.isLogin=false;
                if(d.ResponseHeader.ResultCode!=0){
                    this.$confirm(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '继续验证',
                        cancelButtonText: '我知道了',
                        type: 'warning'
                    }).then(() => {
                        this.userName='',this.userPW='',
                        //默认焦点
                        this.$refs?.userName?.focus();
                        this.startCardRed();//开启读卡
                    }).catch(()=>{ 
                        this.$emit("closeModel")
                    });
                }else{
                    if(typeof(this.confirm)=="function"){
                        this.confirm(d.ResponseBody);
                    }
                }
            }).catch((e)=>{
                this.isLogin=false;
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        create(){
            if(this.redDelayedTime){
                clearTimeout(this.redDelayedTime);
            }
            if(this.redTime){
                clearTimeout(this.redTime)
            }
            this.isStopRedCard=true;
        }
    },
    unmounted() {
        this.create();
    },
}
</script>

<style lang="scss">
   @import "./checkUserRightModel.scss"
</style>