<template>
  <modal class="demandFullModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">全单要求操作
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="口味或做法模糊检索"
                    @keyup.enter="onkeysearchText()"
                    isBlurClose isKeyEnterClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
            </div>
        </div>
        <div class="modal-body">
             <div class="clearfix">
                <div class="left-box float-box">
                    <div class="title">做法</div>
                    <div class="filter-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodPracticePage',isNavFirstSticky:true}">
                            <div class="nav-li " :class="{selected:masKindSelectIndex==-1}" @click="onClickAll(-1)" >全部</div>
                            <div class="nav-li" :class="{selected:masKindSelectIndex==index}" v-for="(item,index) in allMasKindList" :key="item" @click="onClickAll(index,item)">{{item.MasKind_Name}}</div>
                        </div>
                        <div class="float-right page-box"><span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up"></span>
                            <span class="page-number">{{foodPracticePage.pageIndex}}/{{foodPracticePage.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>
                    <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                        <div class="tab-pane" v-if="masKinds">
                            <div class="item-li category " :class="{selected:eat.Is_type==1}" v-for="(eat,index) in masKinds" :key="index"  @click="clickOptFlavor(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.Mas_Name}}</span></div>
                                <div class="left-tag nowrap" v-if="eat.Mas_Price">加{{eat.Mas_Price}}.00元</div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="left-box float-box">
                    <div class="title">口味</div>
                    <div class="filter-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up2',down:'nav-down2',fx:'x',liClass:'.nav-li',page:'foodPracticePage2',isNavFirstSticky:true}">
                            <div class="nav-li " :class="{selected:practiceSelectIndex==-1}" @click="onClickAll2(-1)" >全部</div>
                            <div class="nav-li" :class="{selected:practiceSelectIndex==index}"  v-for="(item,index) in practiceList" :key="item" @click="onClickAll2(index,item)">{{item.MasKind_Name}}</div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up2"></span>
                            <span class="page-number">{{foodPracticePage2.pageIndex}}/{{foodPracticePage2.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down2" ></span>
                        </div>
                    </div>
                    <div class="item-box" v-scroll-anime="{up:'item-up2',down:'item-down2',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}" >
                        <div class="tab-pane" v-if="practiceMasKinds" >
                            <div class="item-li category" :class="{selected:eat.Is_type==1}" v-for="eat in practiceMasKinds" :key="eat" @click="clickOptFlavor(eat)" >
                                <div class="category-title inlineNowrap2"><span>{{eat.Mas_Name}}</span></div>
                                <div style="float: left;position: relative;bottom: 13px;font-size: 12px;" v-if="eat.Mas_Price">加{{eat.Mas_Price}}.00元</div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up2" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down2" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix food-box">
                <div class="item-name">
                    已选择的做法口味
                </div>
                <div class="demand-tag clearfix">
                    <div class="tag-box" v-mouse-scroll>
                        <div class="tag-li" v-for="(item,index) in optFlavors" :key="item" @click="clickOptFlavorRedu(item,index)">
                            <div class="title inlineNowrap2"><span>{{item.Mas_Name}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="remarks-box">
                    <span class="lable">自定义要求</span>
                    <textarea v-board class="remarks" placeholder="请输入要求" ref="reName" v-model="remarksName" ></textarea>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import { deepClone } from '/src/common'
import { httpAES,cacheData } from '/src/services'
/**全单要求操作 */
export default {
    name:'demandFullModel',
    props:['isShow','masKindsList','orderMenuList'],
    data(){
        return{
            foodPracticePage:{pageIndex:0,pageSize:0},
            foodPracticePage2:{pageIndex:0,pageSize:0},
             /**显示口味数据 */
            masKinds:[],
            /**做法类别 */
            allMasKindList:[],
             /** 口味数据*/
            practiceMasKinds:[],
            /**做法导航 */
            masKindSelectIndex:-1,
            /**口味类别 */
            practiceList:[],
            /**做法导航 */
            practiceSelectIndex:-1,
            optFlavors:[], 
             /**检索 口味 或 做法*/
            searchText:'',
            /**自定义口味做法 */
            remarksName:''
        }
    },
    mounted(){
        this.$nextTick(()=> {
            //清空上次的选择
            this.openimp()
            this.clearData();
        })
    },
    watch:{
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        },
        searchText(newValue){
            if(newValue!=''){
                this.onClickAll(-1);
                this.onClickAll2(-1);
                let MenuTxt=newValue.toLowerCase();
                this.masKinds=this.masKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
                this.practiceMasKinds=this.practiceMasKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.onClickAll(-1);
                this.onClickAll2(-1);
            }
        }
    },
    methods:{
         /**打开执行 */
        openimp(){
            setTimeout(() => {
                this.$refs.reName.focus();
            },100);
            this.searchText=''
            let allMasKindList=[],practiceList=[];
            allMasKindList._all=[];
            practiceList._all=[];
            let masKindsList= deepClone(this.masKindsList);

            masKindsList.forEach((item)=>{
                if(item.TYPEGROUP==1 || item.TYPEGROUP==2){
                    let _all=[];
                    item.EatMasList.forEach(eat=>{
                        _all.push(Object.assign({},eat,{Is_type:0}));
                    })
                    item._all=_all;
                    if(item.TYPEGROUP==1){//做法
                        allMasKindList.push(item);
                        allMasKindList._all=allMasKindList._all.concat(_all);
                    }else if(item.TYPEGROUP==2){//做法
                        practiceList.push(item);
                        practiceList._all=practiceList._all.concat(_all);
                    }
                }
            })
            this.allMasKindList=allMasKindList;//做法
            this.practiceList=practiceList;//口味
            this.clearData();
            this.onClickAll(-1);
            this.onClickAll2(-1);
        },
        /**清空上次的选择 */
        clearData(){
            setTimeout(() => {
                this.$refs.reName.focus();
            },100);
            //清空上次的选择
            this.remarksName=''
            this.optFlavors=[];
            this.allMasKindList?._all?.forEach(item=>{
                item.Is_type=0;
            })
            this.practiceList?._all?.forEach(item=>{
                item.Is_type=0;
            })
            let _disRadioOrder=''
            if(this.orderMenuList){
                this.orderMenuList.map(order=>{
                    if(order.FoodModifys){
                        order.FoodModifys.map(food=>{
                            if(food?.IsAllRequired!='' && food?.IsAllRequired==1){
                                _disRadioOrder = order
                            }
                        })
                    }
                })
            }
            this.practiceList?._all?.forEach(item=>{
                if(_disRadioOrder.FoodModifys){
                    _disRadioOrder.FoodModifys.map(food=>{
                        if(food.Mas_AutoID==item.Mas_AutoID && food?.IsAllRequired==1){
                            item.Is_type=1;
                            this.optFlavors.push(food)
                        }
                    })
                }
            })
            this.masKinds.forEach(item=>{
                if(_disRadioOrder.FoodModifys){
                    _disRadioOrder.FoodModifys.map(food=>{
                        if(food.Mas_AutoID==item.Mas_AutoID && food?.IsAllRequired==1){
                            item.Is_type=1;
                            this.optFlavors.push(food)
                        }
                    })
                }
            })

            let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
                if(globalSysSetting?.DiyEatMas){//指定义口味
                    _disRadioOrder?.FoodModifys?.find(food=>{
                        if(food.Mas_AutoID==globalSysSetting.DiyEatMas.MAS_AUTOID && food?.IsAllRequired==1){
                            this.remarksName=food.Mas_Name;
                            return true; 
                        }
                        return false; 
                    })
                }
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        confirm(){
            if(sessionStorage.getItem("tempUserID")){
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            if(this.remarksName){
                let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
                if(globalSysSetting?.DiyEatMas){
                    let EatMas = {
                        Mas_AutoID:globalSysSetting.DiyEatMas.MAS_AUTOID,
                        Mas_Code:globalSysSetting.DiyEatMas.MAS_CODE,
                        Mas_Name:this.remarksName,
                        Is_AddMoney:globalSysSetting.DiyEatMas.IS_ADDMONEY,
                        Price_Kind:globalSysSetting.DiyEatMas.PRICE_KIND,
                        Mas_Price:globalSysSetting.DiyEatMas.MAS_PRICE
                    }
                    this.optFlavors.push(EatMas)
                }else{
                    this.$alert("门店缺少自定义口味的配置信息", {confirmButtonText: "确定"});
                    return
                }
            }
            if(this.optFlavors.length>10){
                this.$message.warning('超过选择的数量上限');
                return
            }
            this.$emit("confirmall",this.optFlavors)
        },
         /**点击口味类别  */
        onClickAll(index,item){
            if(item){
                setTimeout(() => {
                    this.$refs.reName.focus();
                },100);
            }
            if(index!=this.masKindSelectIndex){
                this.isSelectAllOptFlavor=false;
                this.masKindSelectIndex=index;
            }
            if(item){
                if(!item._all){
                    let _all=[]; //全部
                    item.EatMasList?.forEach(item=>{
                        _all = _all.concat(Object.assign({},item,{Is_type:0}));
                    })
                    item._all=_all;
                }
                this.masKinds=item._all;
            }else{
                this.masKinds = this.allMasKindList?._all;
            }
        },
        /**点击做法类别  */
        onClickAll2(index,item){
            if(item){
                setTimeout(() => {
                    this.$refs.reName.focus();
                },100);
            }
            if(index!=this.practiceSelectIndex){
                this.practiceSelectIndex=index;
            }
            if(item){
                if(!item._all){
                    let _all=[]; //全部
                    item.EatMasList.forEach(item=>{
                        _all = _all.concat(Object.assign({},item,{Is_type:0}));
                    })
                    item._all=_all;
                }
                this.practiceMasKinds=item._all;
            }else{
                this.practiceMasKinds = this.practiceList?._all;
            }
        },
        onkeysearchText(){
            if(this.searchText!=''){
                this.onClickAll(-1);
                this.onClickAll2(-1);
                let MenuTxt=this.searchText.toLowerCase();
                this.masKinds=this.masKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
                this.practiceMasKinds=this.practiceMasKinds.filter(t=>t.Mas_Name?.toLowerCase().indexOf(MenuTxt)>=0 || t.PINYIN?.toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.onClickAll(-1);
                this.onClickAll2(-1);
            }
        },
        /**选择的口味 */
        clickOptFlavor(item){
            setTimeout(() => {
                this.$refs.reName.focus();
            },100);
            if(item.Is_type==0){
                this.optFlavors.push(item);
                item.Is_type=1;
            }else if(item.Is_type==1){
                this.optFlavors.map((opt,index)=>{
                    if(item.Mas_AutoID==opt.Mas_AutoID){
                        this.optFlavors.splice(index,1);
                        item.Is_type=0;
                    }
                })
            }
        },
        /**还原选项 */
        clickOptFlavorRedu(item,index){
            if(item.Is_type==1){
                this.optFlavors.splice(index,1);
                item.Is_type=0;
            }
        }
    }
}
</script>

<style lang="scss">
@import './demandFullModel.scss';
</style>