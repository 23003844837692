<template>
    <modal class="customDishesModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">
            自定义菜品
        </div>
        <div class="modal-body clearfix">
            <div class="left-box">
                <div class="category-nav">
                    <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodSubNavPage',isNavFirstSticky:true}">
                        <div class="nav-li" :class="{selected:foodSubCategoryIndex==0}" @click="onFoodSubNav()">
                            <span class="lable-text">全部小类</span>
                        </div>
                        <div class="nav-li" :class="{selected:foodSubCategoryIndex==index+1}" v-for="(item, index) in foodSubCategorys" :key="index" @click="onFoodSubNav(item,index+1)">
                            <span class="lable-text">{{item.SubCategory_Name}}</span>
                        </div>
                    </div>
                    <div class="float-right page-box">
                        <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                        <span class="page-number">{{foodSubNavPage.pageIndex}}/{{foodSubNavPage.pageSize}}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down"></span>
                    </div>
                </div>

                <div class="menu" >
                    <!-- 菜单 -->
                    <div class="item-box clearfix"  v-show="foodsPages" >
                        <div class="item-li" :class="{selected:selectFood?.Food_ID==item.Food_ID}" rc-item v-for="item in foodsPages" :key="item" @click="selectFood=item" >
                            <div class="title">
                                {{item.Food_Name}}
                            </div>
                            <div class="bottom-box">
                                <span class="text-left" v-if="item.Is_Limited">剩: {{item.RemainNum}}</span>
                                <span class="text-right">￥{{item.Food_Price}}/份</span>
                            </div>
                            <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                            <div class="tag tag-grey" v-if="!item.Is_SoldOut && item.Is_Limited && item.RemainNum==0">售罄</div>
                        </div>
                    </div>
                </div>
                <div class="search-box">
                    <div class="search">
                        <input type="text" placeholder="菜品模糊检索"  v-model="varietySelect" ref="searchBox" @blur="searchInputBlur" />
                        <div class="deskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()" >
                            <span class="iconfont icon-jianpan"></span>
                        </div>
                    </div>
                    <div class="float-right">
                        <span class="last-page iconfont icon-fangxiangquan-left" @click="posFoodsPage.pageIndex<=1?1:posFoodsPage.pageIndex--"></span>
                        <span class="page-number">{{ posFoodsPage.pageIndex }}/{{ posFoodsPage.pageSize }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="posFoodsPage.pageIndex>=posFoodsPage.pageSize?posFoodsPage.pageSize:posFoodsPage.pageIndex++" ></span>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="title">编辑菜品</div>
                <div class="tr-li">
                    <div class="lable-txt">名称：</div>
                    <div class="from-input"><input @focus="(e)=>e.target.select()" v-board type="text" v-model="customInfo.Food_Name" :readonly="!this.selectFood"/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">单位：</div>
                    <div class="from-input"><input @focus="(e)=>e.target.select()" v-board type="text" v-model="customInfo.Food_Size" :readonly="!this.selectFood"/></div>
                </div>
                <!-- <div class="tr-li" v-if="customInfo.Food_AssSize">
                    <div class="lable-txt">辅助单位：</div>
                    <div class="from-input"><input type="text" v-model="customInfo.Food_AssSize" readonly /></div>
                </div>
                <div class="tr-li" v-if="customInfo.Food_AssSize">
                    <div class="lable-txt">辅助数量：</div>
                    <div class="from-input"><input type="text" v-model="assSizeNumber" :readonly="!this.selectFood"/></div>
                </div> -->
                <div class="tr-li">
                    <div class="lable-txt">价格：</div>
                    <div class="from-input"><input @focus="(e)=>e.target.select()" v-board type="number" v-model="customInfo.Food_Price" min="0" :readonly="!this.selectFood"/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">数量：</div>
                    <div class="from-input"><input @focus="(e)=>e.target.select()" v-board type="number" v-model="customInfo.Eat_Number" min="0" :readonly="!this.selectFood"/></div>
                </div>
                <div class="tr-li demand">
                    <div class="lable-txt">要求：</div>
                    <div class="from-input"><input type="text" v-model="customInfo.Eat_MasName" readonly/></div>
                    <div class="bnt" @click="selectDemand" >选择</div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-cancel confirm" @click="confirmSign()" >确定并退出</button>
            <button class="btn btn-confirm" @click="confirm()">确定并继续自定义</button>
        </div>

         <!-- 菜品要求操作 -->
        <modal-load :isShow="demandFullShow">
            <demand-full-model :isShow="demandFullShow" v-on:closeModel="demandFullShow=false" @confirmall="confirmall" :masKindsList="masKindsList" ></demand-full-model>
        </modal-load>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </modal>
</template>

<script>
import DemandFullModel from '../demandFullModel/demandFullModel.vue'

/**自定义菜品 */
export default {
    components:{DemandFullModel},
    name:"customDishesModel",
    props:{
        isShow:Boolean
    },
    data(){
        return {
            demandFullShow:false,
            /**菜品口味 */
            masKindsList:[],
            /**小类导航 选择 */
            foodSubCategoryIndex:0,
            /**菜品 小类数据*/
            foodSubCategorys:[],
            /**小类导航 分页 */
            foodSubNavPage:{
                pageIndex:0,
                pageSize:0
            },
            /**菜品展现数据 */
            posFoods:[],
            /**菜品页码 */
            posFoodsPage:{
                pageIndex:1,
                pageSize:1
            },
            /**菜品检索 */
            varietySelect:'',
            /**选中的菜品 */
            selectFood:undefined,
            /**辅助数量 */
            assSizeNumber:1,
            /**自定义数据信息 */
            customInfo:{
                Food_ID:'', /**菜品小类ID */
                Food_Code:'',/**菜品小类编号 */
                Food_Name:'',/**名称 */
                Food_EnglishName:'',/**菜品英文名称 */
                SubCategory_ID:'',/**菜品小类名称 */
                Food_Property:0,/**菜品属性，1：普通产品 ，2：尾数调整项目 ，3：服务费项目 ，4：最低消费补足项目 */
                Food_PinYin:'',/**菜品拼音简称 */
                Food_Size:'',/**单位 */
                Food_AssSize:'', /**辅助单位 */
                Food_Price:0,/*价格 */
                Food_CostPrice:0,/**菜品成本价 */
                Food_VipPrice:'',/**菜品会员价*/
                Is_SoldOut:false,/**是否沽清 true:是，false:否 */
                Is_Discounted:false,/**是否可折扣 true:是， false:否 */
                Is_Seasonal:false,/**是否时价菜 true:是， false:否 */
                Is_Limited:false,/**是否限量销售 true:是， false:否 */
                Is_ChangedNum:false,/**是否允许改量 true:是， false:否 */
                Is_ChargeForService:false,/**是否收取服务费 true:是， false:否 */
                Is_SetMeal:false,/**是否套餐菜品 true:是， false:否 */
                Is_Special:false,/**是否特色菜 true:是， false:否 */
                KitPlace_ID:'',/**出单位置ID */
                Kit_Level:0,/**上菜级别 */
                Is_MultipleSize:false,/**是否多规格菜品 true:是， false:否 */
                Is_BindEatMas:false,/**是否绑定做法 true:是， false:否 */
                Eat_Number:'',/*数量 */
                Eat_MasName:'',/**要求 */
                FoodModifys:null, /**口味 */
            }
        }
    },
    mounted(){
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        /**自定义菜品数据结构 */
        this.$cacheData.PosDiyFoods().then((d)=>{
            let all=d;
            all._all=[];//全部菜品
            if(all.length>0){
                all.forEach(sub => {
                    if(sub.PosFoods && sub.PosFoods.length>0)
                    all._all=all._all.concat(sub.PosFoods)
                });
            }
            this.foodSubCategorys=all; //小分类
            this.onFoodSubNav();
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到自定义菜品数据', "提示", {confirmButtonText: "确定"});
        })
    }, 
    watch: {
        isShow(newVal) {
            console.log('newVal:'+JSON.stringify(newVal))
            if(!this.isShow) return;
            if(newVal){
                this.loakersList=this.$cacheData.lockersCache.getLockersList();
                this.loakersDetailed=[];
                this.selectIndex=undefined;
                this.selectFood=undefined;
                this.customInfo={}
            }
        },
        /**菜品搜索 */
        varietySelect(){
            if(this.varietySelect!=''){
                this.foodSubCategoryIndex=0;
                this.mainCategoryIndex=0;
                let all=[];
                let MenuTxt=this.varietySelect.toLowerCase();
                this.foodSubCategorys._all.map(pos=>{
                    if((pos.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0){
                        all.push(pos);
                    }
                })
                this.posFoods =all;
            }else{
                 this.posFoods =this.foodSubCategorys._all;
            }
        },
        posFoods(){
            let size=5*4;
            this.posFoodsPage.pageIndex=1;
            let pageSize=Math.ceil(this.posFoods.length/size);
            if(pageSize==0){
                pageSize=1;
            }
            this.posFoodsPage.pageSize=pageSize;
        },
        /**选中的菜品 */
        selectFood(newVal){
            if(newVal){
                this.customInfo.Food_ID = newVal.Food_ID
                this.customInfo.Food_Code=newVal.Food_Code
                this.customInfo.Food_EnglishName=newVal.Food_EnglishName
                this.customInfo.SubCategory_ID=newVal.SubCategory_ID
                this.customInfo.Food_Property=newVal.Food_Property
                this.customInfo.Food_PinYin=newVal.Food_PinYin
                this.customInfo.Food_AssSize=newVal.Food_AssSize
                this.customInfo.Food_VipPrice=newVal.Food_VipPrice
                this.customInfo.Is_SoldOut=newVal.Is_SoldOut
                this.customInfo.Is_Discounted=newVal.Is_Discounted
                this.customInfo.Is_Seasonal=newVal.Is_Seasonal
                this.customInfo.Is_Limited=newVal.Is_Limited
                this.customInfo.Is_ChangedNum=newVal.Is_ChangedNum
                this.customInfo.Is_ChargeForService=newVal.Is_ChargeForService
                this.customInfo.Is_SetMeal=newVal.Is_SetMeal
                this.customInfo.Is_Special=newVal.Is_Special
                this.customInfo.KitPlace_ID=newVal.KitPlace_ID
                this.customInfo.Kit_Level=newVal.Kit_Level
                this.customInfo.Is_MultipleSize=newVal.Is_MultipleSize
                this.customInfo.Is_BindEatMas=newVal.Is_BindEatMas
                this.customInfo.Food_Name=newVal.Food_Name;
                this.customInfo.Food_Price=newVal.Food_Price;
                this.customInfo.Food_CostPrice = newVal?.Food_CostPrice;
                this.customInfo.Food_Size=newVal.Food_Size;
                this.customInfo.Eat_Number=1;
                this.customInfo.Eat_MasName="";
            }
        },
        'customInfo.Eat_Number':{
            handler: function() {
                /**数量最多只允许小数点后4位 */
                let num = /^\d+(\.\d{1,4})?$/;
                if(this.customInfo.Eat_Number!=undefined){
                    if (!num.test(this.customInfo.Eat_Number)){
                        this.customInfo.Eat_Number = this.customInfo.Eat_Number.slice(0,this.customInfo.Eat_Number.length-1)
                        return
                    }
                }
                
            }
        },
        'customInfo.Food_Price':{
            handler: function() {
                /**价格最多只允许小数点后4位 */
                let num = /^\d+(\.\d{1,4})?$/;
                if(this.customInfo.Food_Price!=undefined){
                    if (!num.test(this.customInfo.Food_Price)){
                        this.customInfo.Food_Price = this.customInfo.Food_Price.slice(0,this.customInfo.Food_Price.length-1)
                        return
                    }
                }
            }
        },
    },
    computed:{
        /**菜品分页显示 */
        foodsPages(){
            let size=5*4;
            return this.posFoods.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
    },
    methods:{
        /**点击分类 */
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.foodSubCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.foodSubCategoryIndex=0
            }
            this.varietySelect="";
        },
        selectDemand(){
            if(this.selectFood){
                this.demandFullShow=true;
            }
        },
        /**要求确认 */
        confirmall(data){
            if(data){
                this.demandFullShow=false;
                let _food=[]
                data.map(t=>{
                    _food.push({
                        Mas_AutoID:t.Mas_AutoID,
                        Mas_Code:t.Mas_Code,
                        Mas_Name:t.Mas_Name,
                        Mas_Num: Number(this.customInfo.Eat_Number).toFixed(2),
                        Mas_Price:t.Mas_Price,
                        Price_Kind:t.Price_Kind,
                        Is_AddMoney:t.Is_AddMoney,
                    })
                })
                this.customInfo.FoodModifys =_food
                this.customInfo.Eat_MasName=data.map(t=>t.Mas_Name).join('/')
            }
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**确认并退出 */
        confirmSign(){
            if(Object.keys(this.customInfo).length==0){
                this.$emit("closeModel")
                return
            }
            //开启不强制要求修改菜品名true:开启 false:不开启
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting?.RunDiyFoodNotForceModifyName && this.customInfo.Food_Name==this.selectFood.Food_Name){
                this.$message.error('自定义菜品名不能与选中的模板名称一致')
                return
            }
            if(this.customInfo.Eat_Number<=0){
                this.$message.error('自定义菜品数量不能为0')
                return
            }
            if(!this.customInfo.Food_Name){
                this.$message.error('自定义菜品名称不能为空')
                return
            }
            if(!this.customInfo.Food_Size){
                this.$message.error('自定义菜品单位不能为空')
                return
            }
            let num = /^\d+(\.\d{1,2})?$/;
            if (!num.test(this.customInfo.Eat_Number)){
                this.customInfo.Eat_Number = Number(this.customInfo.Eat_Number).toFixed(2)
            }
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit('customConfirm',this.customInfo,0)
        },
        /**确定并继续和回车事件 */
        confirm(){
            if(Object.keys(this.customInfo).length==0){
                this.$message.error('请选择对应的自定义菜品模板')
                return
            }
            //开启不强制要求修改菜品名true:开启 false:不开启
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting?.RunDiyFoodNotForceModifyName && this.customInfo.Food_Name==this.selectFood.Food_Name){
                this.$message.error('自定义菜品名不能与选中的模板名称一致')
                return
            }
            if(this.customInfo.Eat_Number<=0){
                this.$message.error('自定义菜品数量不能为0')
                return
            }
            if(!this.customInfo.Food_Name){
                this.$message.error('自定义菜品名称不能为空')
                return
            }
            if(!this.customInfo.Food_Size){
                this.$message.error('自定义菜品单位不能为空')
                return
            }
            this.$emit('customConfirm',this.customInfo,1)
            this.selectFood=undefined;
            this.customInfo={}
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }  
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    }
}
</script>

<style lang="sass">
@import "./customDishesModel.scss";
</style>