<template>
    <div @click="page()">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name:"pageTurning",
    emits:['update:modelValue'],
    props:{//当前页 从1开始
        modelValue: {
            type: [Number],
            default: 1
        },
        pageCount:{
            type: [Number],
            default: 1
        },
        //翻页类型 down 下一页  up 上一页  默认下一页
        type:{
            type:String,
            default:'down'
        }
    },
    methods:{
        page(){
            if(this.type=='up'){//上一页
                if(this.modelValue>1){
                    this.$emit('update:modelValue', this.modelValue-1);
                }else {
                    this.$message.warning('已到第一页');
                }
            }else{
                if(this.modelValue<this.pageCount){
                    this.$emit('update:modelValue', this.modelValue+1);
                }else {
                    this.$message.warning('已到最后一页');
                }
            }
        }
    }
}
</script>

<style>

</style>