<template>
    <modal class="ipdDemandModel" :isShow="isShow">
        <div class="header">
            <template v-if="data?.isAll">
                整单备注与要求
            </template>
            <template v-else>
                已选择菜品：<span class="name">{{data?.Food_Name||data?.EAT_XFName}}</span>
            </template>
            <div class="search-box">
                <div class="search-input">
                    <i class="iconfont icon-sousuo"></i>
                    <input type="text" v-model="searchText" ref="input" placeholder="口味或做法模糊检索" @click="showKey" @focus="(e)=>e.target.select()"/>
                    <i class="iconClose iconfont icon-a-bg-close3x" @click="searchText=''"></i>
                </div>
                <!-- <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="口味或做法模糊检索"
                    isBlurClose isKeyEnterClose @focus=""
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input> -->
            </div>
            <div class="close-bnt" @click="hide()"><i class="iconfont icon-guanbi"></i></div>
        </div>
        <div class="modal-body">
            <div class="displayFlex">
                <div class="flex-box">
                    <div class="title">做法</div>
                    <div class="filter-nav">
                        <div class="nav-box scrollnot" v-mouse-scroll>
                            <div class="nav-li" :class="{selected:masKindSelectIndex==-1}" @click="masKindSelectIndex=-1" >全部</div>
                            <div class="nav-li" :class="{selected:masKindSelectIndex==index}" v-for="(item,index) in allMasKindList" :key="item" @click="masKindSelectIndex=index">{{item.MasKind_Name}}</div>
                        </div>
                    </div>
                    <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                        <div class="tab-pane" v-if="masKinds" >
                            <div class="item-li" :class="{selected:optFlavors[eat.Mas_AutoID]}" v-for="(eat,index) in masKinds" :key="index" @click="clickOptFlavor(eat)" >
                                <div class="title inlineNowrap2"><span>{{eat.Mas_Name}}</span></div>
                                <div class="left-tag" v-if="eat.Mas_Price">+{{eat.Mas_Price}}</div>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="flex-box">
                    <div class="title">口味</div>
                    <div class="filter-nav">
                        <div class="nav-box scrollnot" v-mouse-scroll>
                            <div class="nav-li " :class="{selected:practiceSelectIndex==-1}" @click="practiceSelectIndex=-1" >全部</div>
                            <div class="nav-li" :class="{selected:practiceSelectIndex==index}" v-for="(item,index) in practiceList" :key="item" @click="practiceSelectIndex=index">{{item.MasKind_Name}}</div>
                        </div>
                    </div>
                    <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                        <div class="tab-pane" v-if="practiceMasKinds" >
                            <div class="item-li" :class="{selected:optFlavors[eat.Mas_AutoID]}" v-for="(eat,index) in practiceMasKinds" :key="index" @click="clickOptFlavor(eat)" >
                                <div class="title inlineNowrap2"><span>{{eat.Mas_Name}}</span></div>
                                <div class="left-tag" v-if="eat.Mas_Price">+{{eat.Mas_Price}}</div>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
            <div class="food-box">
                <div class="name"> 已选： </div>
                <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                    <div class="tag-box clearfix">
                        <div class="tag-li" v-for="(item) in optFlavorsArr" :key="item"  @click=" delete this.optFlavors[item.Mas_AutoID]">
                            <div class="title inlineNowrap"><span>{{item.Mas_Name}}</span></div>
                            <div class="left-tag" v-if="item.Mas_Price">+{{item.Mas_Price}}</div>
                            <div class="close-bnt"><i class="iconfont icon-a-bg-close3x"></i></div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="remarks-box">
                <span class="lable">自定义要求：</span>
                <textarea class="remarks" placeholder="请输入要求" v-model="remarksName"></textarea>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
import { deepClone } from '/src/common'
/**口味要求 */
export default {
    name:"ipdDemandModel",
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            default:null,
            type:Object
        },
    },
    data(){
        return {
            /**口味 原数据 */
            masKindsData:null,
            /**菜品有默认口味做法  */
            FoodMasGroups:null,
            /**做法导航 */
            masKindSelectIndex:-1,
            /**口味导航 */
            practiceSelectIndex:-1,
            /**已选择的做法 */
            optFlavors:{}, 
            /**检索 */
            searchText:"",
            /**自定义要求 */
            remarksName:"",
            /**菜品 */
            Food_Name:"",
            /**全单口味是 记录上次选择的口味 */
            tempKindsList:null,
        }
    },
    computed:{
        /**口味数据 */
        masKindsList(){
            if(this.FoodMasGroups){
                return this.FoodMasGroups;
            }
            return this.masKindsData;
        },
        /**做法 */
        allMasKindList(){
            return this.masKindsList?.filter(it=>it.TYPEGROUP==1)||[]; 
        },
        /**所有做法 */
        allMaskList(){
            let all=[];
            this.allMasKindList?.forEach(it=>{
                all=all.concat(it.EatMasList);
            })
            return all;
        },
        /**当前做法 */
        masKinds(){
            let data=this.allMaskList;
            let menuTxt=this.searchText.toLowerCase().trim();
            if(menuTxt){
                return data.filter(it=>it.Mas_Name?.toLowerCase().indexOf(menuTxt)>=0 || it.PINYIN?.toLowerCase().indexOf(menuTxt)>=0);
            }
            if(this.masKindSelectIndex>=0 && this.masKindsList){
                data= this.masKindsList[this.masKindSelectIndex]?.EatMasList;
            }
            return data;
        },
        /**口味类别 */
        practiceList(){
            return this.masKindsList?.filter(it=>it.TYPEGROUP==2)||[]; 
        },
        /**所有口味 */
        allPracticeList(){
            let all=[];
            this.practiceList?.forEach(it=>{
                all=all.concat(it.EatMasList);
            })
            return all;
        },
        /**当前口味数据 */
        practiceMasKinds(){
            let data=this.allPracticeList;
            let menuTxt=this.searchText.toLowerCase().trim();
            if(menuTxt){
                return data.filter(it=>it.Mas_Name?.toLowerCase().indexOf(menuTxt)>=0 || it.PINYIN?.toLowerCase().indexOf(menuTxt)>=0);
            }
            if(this.practiceSelectIndex>=0 && this.practiceList){
                data=this.practiceList[this.practiceSelectIndex]?.EatMasList;
            }
            return data;
        },
        optFlavorsArr(){
            if(this.optFlavors){
                return Object.keys(this.optFlavors)?.map(key=>this.optFlavors[key]);
            }
            return []
        }
    },
    watch:{
        isShow(){
            if(this.isShow==true){
               this.init();
            }
        },
        searchText(){
            if(this.searchText.trim()){//要检索时 导航在第一个位置
                this.masKindSelectIndex=-1;
                this.practiceSelectIndex=-1;
            }
        }
    },
    mounted(){
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsData = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        showKey(){//安卓 弹出软键盘
            if(this.$webBrowser.isMobile.Android){
                this.$webBrowser.openWindKeyBoard();
            }
        },
        init(){
            console.log(this.data)

            this.optFlavors={};
            this.searchText="";
            this.masKindSelectIndex=-1;
            this.practiceSelectIndex=-1;
            this.remarksName="";
            this.FoodMasGroups=null;
            let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
            if(this.data?.Eat_ConsumeID && this.data?._modifys){//修改口味数据
                this.data._modifys.forEach((it)=>{
                    if(globalSysSetting?.DiyEatMas?.MAS_AUTOID==it.Mas_AutoID){
                        this.remarksName=it.Mas_Name;
                    }else{
                        this.optFlavors[it.Mas_AutoID]=it;
                    }
                })
            }else if(this.data?.isAll){//全单口味 记录一下
                this.tempKindsList?.forEach(it=>{
                    if(globalSysSetting?.DiyEatMas?.MAS_AUTOID==it.Mas_AutoID){
                        this.remarksName=it.Mas_Name;
                    }else{
                        this.optFlavors[it.Mas_AutoID]=it;
                    }
                });
            }else if(this.data.FoodMasGroups){//有单独口味做法
                this.FoodMasGroups=this.data.FoodMasGroups;

                if(this.data?._modifys){
                    this.data._modifys.forEach((it)=>{
                        this.optFlavors[it.Mas_AutoID]=it;
                    })
                }
            }
        },
        hide(){
            this.$emit("close")
        },
        confirm(){
            let list=this.optFlavorsArr;
            if(this.remarksName){
                let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
                if(globalSysSetting?.DiyEatMas){
                    let EatMas = {
                        Mas_AutoID:globalSysSetting.DiyEatMas.MAS_AUTOID,
                        Mas_Code:globalSysSetting.DiyEatMas.MAS_CODE,
                        Mas_Name:this.remarksName,
                        Is_AddMoney:globalSysSetting.DiyEatMas.IS_ADDMONEY,
                        Price_Kind:globalSysSetting.DiyEatMas.PRICE_KIND,
                        Mas_Price:globalSysSetting.DiyEatMas.MAS_PRICE
                    }
                    list.push(EatMas);
                }else{
                    this.$message.warning('请先去后台配置自定义口味相关设置');
                    return
                }
            }
            this.$emit("confirm",deepClone(list));
            if(this.data?.isAll){//全单口味 记录一下
                this.tempKindsList=list;
            }
        },
        clickOptFlavor(item){
            if(this.optFlavors[item.Mas_AutoID]){
                delete this.optFlavors[item.Mas_AutoID];
            }else{
                this.optFlavors[item.Mas_AutoID]=item;
            }
            
        }
    }
}
</script>

<style lang="scss">
    @import './ipdDemandModel.scss'
</style>