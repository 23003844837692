<template>
    <modal class="setDekNumModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">修改席数</div>
        <div class="modal-body">
            <div class="tr-header">
                <div class="dek-icon">
                    <i class="iconfont icon-zhuotai"></i>
                    <div class="text">桌号</div>
                </div>
                <div class="dek-name">{{orderInfo?.Eat_DeskName}}</div>
            </div>
            <div class="tr-li">
                <div class="lable-text">原席数：</div>
                <div class="from-text">{{orderInfo.Feast_DeskNum}}</div>
                <div class="lable-text">席数变更为：</div>
                <div class="from-text number">
                    <input-pattern ref="dekNum" type="text" pattern="number" :dplaces="0" :min="1" v-model="Eat_DeskNum" />
                </div>
            </div>
            <div class="flex-box">
                <div class="left-box">
                    <div class="stretch-box">
                        <div class="panel-heading">
                            <div class="td order">序号</div>
                            <div class="td name">套餐名称</div>
                            <div class="td oldNum">原数量</div>
                            <div class="td newNum">数量变更为</div>
                        </div>
                        <div class="scroll-box" v-mouse-scroll v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper'}">
                            <div class="slide-wrapper" v-for="(item,index) in orderList" :key="item">
                                <div class="slide-content" >
                                    <div class="tr">
                                        <div class="td order">{{index+1}}</div>
                                        <div class="td name nowrap">{{item.EAT_XFName}}</div>
                                        <div class="td oldNum">{{item.Eat_Number}}</div>
                                        <div class="td newNum">
                                            <input type="text" v-model="item.newEat_Number" readonly/>
                                        </div>
                                    </div>
                                    <div class="describe">
                                        <div class="inlineBlock nowrap flavor">
                                            ￥{{item.Eat_CheckPrice}}/{{item.Eat_XFSize}}
                                        </div>
                                        <div class="inlineBlock tag-box">
                                            <!--套-->
                                            <span class="tag tao" :style="{visibility:(item.FoodProperty_Dimension?'':'hidden')}" >{{item.FoodProperty_Dimension||'套'}}</span>
                                            <!--厨房状态-->
                                            <span class="tag delimit" v-if="item.Food_Speed">{{item.Food_Speed}}</span>
                                        </div>
                                        <div class="more" v-if="item.Eat_TcItemList" style="display:inline-block;">
                                            <div class="more-bnt" v-if="eatXFBMID!=item.Eat_ConsumeID" @click="eatXFBMID=item.Eat_ConsumeID">
                                                <i class="iconfont icon-xiala"></i>
                                            </div>
                                            <div class="more-bnt" v-else @click="eatXFBMID=null">
                                                <i class="iconfont icon-shangla"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="border"></div>
                                <el-collapse-transition>
                                <div class="more-box" v-show="item.Eat_TcItemList && eatXFBMID==item.Eat_ConsumeID">
                                    <div class="slide-content" :title="item.EAT_XFName" v-for="(food,foodindex) in item.Eat_TcItemList" :key="foodindex">
                                        <div class="tr">
                                            <div class="td order"></div>
                                            <div class="td name nowrap">{{food.EAT_XFName}}</div>
                                            <div class="td oldNum">{{food.Eat_Number}}</div>
                                            <div class="td newNum"> </div>
                                        </div>
                                        <div class="describe ">
                                            <div class="inlineBlock flavor nowrap" >
                                                ￥{{item.Eat_CheckPrice}}/{{item.Eat_XFSize}}
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <!--厨房状态-->
                                                <span class="tag delimit" v-if="food.Food_Speed">{{food.Food_Speed}}</span>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                </el-collapse-transition>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard addkey" @mousedown="(e)=>e.preventDefault()">
                        <div class="key-li">
                            <span @click="add(1)">+ 一席</span><span @click="add(2)">+ 两席</span><span @click="add(3)">+ 三席</span>
                        </div>
                    </div>
                    <div ref="keyboard">
                        <div class="keyboard">
                            <div class="key-li">
                                <span data-value="7">7</span><span data-value="8">8</span><span data-value="9">9</span>
                            </div>
                            <div class="key-li">
                                <span data-value="4">4</span><span data-value="5">5</span><span data-value="6">6</span>
                            </div>
                            <div class="key-li">
                                <span data-value="1">1</span><span data-value="2">2</span><span data-value="3">3</span>
                            </div>
                            <div class="key-li">
                                <span data-value=".">.</span><span data-value="0">0</span><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()" >取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
import {initKeyBoard,deepClone} from '/src/common/index.js';
// 配菜单
export default {
    name:'setDekNumModel',
    emits:["closeModel","confirm"],
    props:{
        isShow:Boolean,
        //订单数据
        orderInfo:{
            default:null
        }
    },
    data(){
        return{
            //席数
            Eat_DeskNum:'',
            orderList:null,
            eatXFBMID:null,
            keyBoardObj:null,
            
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.keyBoardObj=initKeyBoard.call(this,this.$refs.keyboard,this.$refs.dekNum.$refs.input);
        })
    },
    watch:{
        isShow:{
            handler(newValue){
                if(newValue){
                    this.Eat_DeskNum="";
                    this.orderList=[];
                    if(this.orderInfo){
                        deepClone(this.orderInfo)?.Order_ConsumeList?.map((item)=>{
                            if(item.Eat_TcItemList && item.Eat_TcItemList.length>0){
                                this.orderList.push(item)
                            }
                        })
                    }
                    setTimeout(()=>{
                        this.$refs.dekNum.focus();
                    },100)
                    
                }
            },
            immediate:true
        },
        Eat_DeskNum(){
            this.orderList?.map((item)=>{
                item.newEat_Number=this.Eat_DeskNum;
            })
        }
    },
    methods:{
        add(num){
            this.Eat_DeskNum=this.orderInfo.Feast_DeskNum+num;
        },
        hide(){
            this.$emit("closeModel");
        },
        //修改席位数
        confirm(){
            if(!this.Eat_DeskNum>0){
                this.$message.warning("席数必须大于0!");
                return;
            }
            if(this.Eat_DeskNum==this.orderInfo.Feast_DeskNum){
                this.$message.warning("修改席数不能与原席数相同!");
                return;
            }

            if(this.orderList.find((item)=>item.Eat_Number!=this.orderInfo.Feast_DeskNum)){
                this.$message.warning("原席数必须与套餐原数量相同才可修改席数!");
                return;
            }

            

            //套餐明细信息 (只用到明细ID和数量，其他可不传
            let Order_TCConsumeList=[];
            for(let i=0;i<this.orderList.length;i++){
                let item=this.orderList[i];
                if(!item.newEat_Number>0){
                    this.$message.warning("套餐名称："+item.EAT_XFName+"数量必须大于0!");
                    return;
                }
                Order_TCConsumeList.push({
                    Eat_ConsumeID:item.Eat_ConsumeID,
                    Eat_Number:item.newEat_Number
                })
            }

            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.ModifyFeastDeskNum",{
                Eat_AutoID:this.orderInfo.Eat_AutoID,
                Eat_DeskNum:this.Eat_DeskNum,
                Order_TCConsumeList:Order_TCConsumeList,
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Timestamps:this.orderInfo.Timestamps
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    this.$emit("confirm",d.ResponseBody);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('修改席位数失败：'+e.message);
                console.log('修改席位数失败：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './setDekNumModel.scss'
</style>