<template>
    <modal class="scanCodeOrderModel" :isShow="isShow" @keyup.esc="close">
        <div class="header">扫码订单核销<span class="close" @click="close()">×</span></div>
        <div class="modal-body">
            <div class="li-box">
                请输入订单号或扫码：
                <div class="from-input" >
                    <i v-if="$webBrowser?.isMobile?.Android" class="iconfont icon-a-saoma13x" @click="scanCodeQr"></i>
                    <i v-else class="iconfont icon-a-saoma13x"></i>
                    <input type="text" ref="input" class="key-focus" v-mode-en v-model="scanCode"  @focus="(e)=>e.target.select()" @keyup.enter="qrOder()" />
                </div>
                <button class="btn" @click="qrOder()">核销</button>
            </div>
            <div class="diagram-box">
                <div class="title">请将手机订单中的二维码对准扫码器</div>
                <div class="img"><img src="/images/scanOrder.png"/></div>
            </div>
            <div class="message">{{message}}<span v-show="countdown>0">{{countdown}}秒</span></div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="close()">取消</button>
        </div>
    </modal>
</template>

<script>
export default {
    name:"scanCodeOrderModel",
    emits:["close"],
    props:{
        /**是否显示 */
        isShow:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.init();
            }else{
                this.stopCountDown();
            }
        },
        scanCode(){
            this.stopCountDown();
        },
    },
    data(){
        return {
            //扫码下单
			scanCode:"",
            message:"",//错误提示文字 
            countdown:0,//倒计时关闭弹层
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        /**初始参数 */
        init(){
            this.countdown=0;
            this.scanCode="";
            this.message="";
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        //关闭弹层
        close(){
            this.$emit("close");
        },
        /**安卓 扫码 */
		scanCodeQr() {
			if (window.webview) {
				this.$webBrowser.hasCamera().then((isQr) => {
					if (isQr == "1") {
						this.$webBrowser.qr().then((d) => {
							this.scanCode=d;
                            this.qrOder();
						}).catch((e) => {
							this.$message({ message: "扫码失败:" + e, type: "warning" });
						});
					} 
				});
			} 
		},
        qrOder(){
            this.stopCountDown();
            let scanCode=(this.scanCode||"").trim();
            if(!scanCode){
                this.message='核销码不能为空';
                return
            }
            const loading = this.$loading({
                text: "订单提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo()
			let param={
                User_ID:userInfo?.User_ID,  //用户id
                Eat_ID:scanCode, //订单ID
                Operator_Name:userInfo?.Login_Name, //操作员
                ElectronicInvoice_PrintForbidden:false,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
            }
            console.log('线上扫码买单打印提交:',param)
            this.$httpAES.post("Bestech.CloudPos.OnlineQrOderPrint", param).then((d)=>{
                this.$refs.input.select();
                console.log('线上扫码买单打印提交:',d)
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.message="请到前台人工核实处理!返回信息:"+d.ResponseHeader.ResultDesc;
                    if(d.ResponseHeader.ResultCode=="10004"){
                        this.message="取餐号【"+scanCode+"】已打印，请到前台人工核实处理！";
                    }
                }else {
                    this.scanCode="";
                    if(d.ResponseBody){//打印清单
                        this.$webBrowser.posPrint(d.ResponseBody);
                    }
                    this.message="扫码成功，请取票！";
                    this.$nextTick(()=>{
                        this.countdown=3;//倒计时3秒后关闭弹层
                        this.timing();
                    })
                }
            }).catch((e)=>{
                loading.close()
                this.message=e.message;
            });
		},
        stopCountDown(){
            if(this._time){
                clearTimeout(this._time);
            }
            this.countdown=0;
        },
        timing(){
            if(this._time){
                clearTimeout(this._time);
            }
            if(this.countdown<=0){
                this.close();
                return;
            }
            this._time=setTimeout(()=>{
                this.countdown--;
                if(this.countdown<=0){
                    this.close();
                }
                this.timing();
            },1000);
        }
    },
    unmounted(){
        if(this._time){
            clearTimeout(this._time);
        }
    }
}
</script>

<style lang="sass">
@import "./scanCodeOrderModel.scss";
</style>