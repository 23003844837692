<template>
  <div class="register">
    <header class="header clearfix">
		<div class="float-left title">正品贵德云POS管理中心</div>
		<div class="float-right">
			<div class="tel">
				<span class="iconfont icon-new_whiteboardphone"></span
				>咨询电话：400-888-2891
				</div>
			<div class="bnt_close" @click="close()">
				<span class="iconfont icon-tuichu"></span>
			</div>
      </div>
    </header>
    <div class="positionCenter">
      <div class="loginbox">
        <div class="title">Bestech.CloudPos</div>
		<div class="bnt-switch" :class="{scanCode:isScanCode}" @click="isScanCode=!isScanCode">
			<img class="erweima" src="images/erweima.png"/>
			<img class="zhanghao" src="images/zhanghao.png"/>
		</div>
        <div class="flex-box" v-show="!isScanCode">
			<div class="flex-grow-0">
				<div class="li">
					<div class="lable-txt">注册地址:</div>
					<input type="text" class="key-focus" ref="ip" data-model="ip" v-model="ip" placeholder="请输入注册地址" />
				</div>
				<div class="li">
					<div class="lable-txt">端口号:</div>
					<input type="text" class="key-focus input-td" data-model="port" v-model="port" placeholder="请输入端口号" />
					<div class="lable-txt" style="margin: 5px 0px 0px 8px">
						<span class="span-required">*</span>设备名称:
					</div>
					<input type="text" class="key-focus input-td" v-model="deviceName" placeholder="请输入设备名称" />
				</div>
				<div class="li">
					<div class="lable-txt">公钥:</div>
					<input type="text" class="key-focus" data-model="departmentKey" v-model="departmentKey" placeholder="请输入公钥" />
				</div>
				<div class="li">
					<div class="lable-txt">私钥:</div>
					<input type="text" class="key-focus" v-model="SecretId" placeholder="请输入私钥" />
				</div>
				<div class="li login-remember">
					<span class="float-left scan-code" @click="scanCode()">扫码注册</span>
				</div>
				<div class="li">
					<button type="button" class="btn bnt-register" @click="submit()">
						注册
					</button>
				</div>
			</div>
          <div class="flex-grow-1 keyboard">
            <div class="key-li">
              <span data-value="7">7</span><span data-value="8">8</span><span data-value="9">9</span>
            </div>
            <div class="key-li">
              <span data-value="4">4</span><span data-value="5">5</span><span data-value="6">6</span>
            </div>
            <div class="key-li">
              <span data-value="1">1</span><span data-value="2">2</span><span data-value="3">3</span>
            </div>
            <div class="key-li">
              <span data-value=".">.</span><span data-value="0">0</span><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span>
            </div>
            <div class="key-li">
              <span data-value="clean">清除</span><span class="bnt-confirm" data-value="next">确认</span>
            </div>
          </div>
        </div>
		<div class="flex-box qrCode-box" v-show="isScanCode">
			<div class="qrCode">
				<div class="qrCodeImg" :class="{expire:outTime<=0}" v-loading="qrLoading">
					<qr-code :text="codeText" logoImage="images/BSLogo.png"/>
					<div class="refresh" @click="getBestechQRCode()">
						<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M771.776 794.88A384 384 0 0 1 128 512h64a320 320 0 0 0 555.712 216.448H654.72a32 32 0 1 1 0-64h149.056a32 32 0 0 1 32 32v148.928a32 32 0 1 1-64 0v-50.56zM276.288 295.616h92.992a32 32 0 0 1 0 64H220.16a32 32 0 0 1-32-32V178.56a32 32 0 0 1 64 0v50.56A384 384 0 0 1 896.128 512h-64a320 320 0 0 0-555.776-216.384z"></path></svg>
					</div>
				</div>
				<p class="tip" v-if="outTime>0">二维码过期时间{{outTime}}s</p>
				<p class="tip" v-else>二维码已失效,请点击二维码刷新重新扫码</p>
				<p>请使用小程序扫码注册</p>
			</div>
			<div class="guide-box">
				<div class="li-box">1、打开Bestech小程序</div>
				<div class="arrow"></div>
				<div class="li-box">2、点击“我的”里面“扫码注册”</div>
				<div class="arrow"></div>
				<div class="li-box">3、扫描屏幕二维码注册</div>
			</div>
		</div>
      </div>
    </div>
    <footer class="footer">
      <span>版权所有 ：©2016-2020 上海正品贵德软件有限公司 </span>
    </footer>
    <modal class="scanCodeModal" :isShow="isShow">
		<div class="modal-header">
			<h6 class="modal-title" id="myModalLabel">扫码解析注册信息</h6>
			<div class="close" @click="isShow = false">×</div>
		</div>
		<div class="modal-body">
			<textarea placeholder="扫码输入智能解析注册信息" class="scanCodeTxt" v-model="scanCodeTxt"></textarea>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-analysis" @click="parseJSON()">
			确认解析
			</button>
		</div>
    </modal>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { webBrowser, initKeyBoard } from "/src/common";
import CryptoJS from "crypto-js"

export default {
	name: "register",
	data() {
		return {
			ip: "",
			port: "",
			departmentKey: "",
			deviceKey: "",
			isShow: false,
			scanCodeTxt: "",
			SecretId: "",
			DepartmentName: "",
			deviceName: "",
			/**是否扫码 授权注册 */
			isScanCode:true,
			/**到期时间 */
			expiresTime:0,
			outTime:0,
			QRCodeInfo:null,
			qrLoading:false
		};
	},
	watch:{
		isScanCode(){
			if(this.isScanCode && !this.outTime>0){
				this.getBestechQRCode();
			}
		}
	},
	computed:{
		codeText(){
			if(this.QRCodeInfo){
				return JSON.stringify({
					DEVICE_ID:this.QRCodeInfo.DEVICE_ID,
					QRCODE_TYPE:this.QRCodeInfo.QRCODE_TYPE,
					QRCODE_ID:this.QRCodeInfo.QRCODE_ID
				})
			}
			return null;
		}
	},
	mounted() {
		if (!this.$global.devices || !this.$global.devices.uuid) {
			this.$webBrowser.getDevices().then((data) => {
				if (data) {
					this.$global.devices = data;
				}
			});
		}
		this.$nextTick(() => {
			this.$refs.ip.focus();
			initKeyBoard.call(this, this.$el);
			this.$webBrowser.getConfig().then((config) => {
			
				if (!config) return;
				this.departmentKey = config.Access_Key || "";
				this.deviceKey = config.UUID || "";
				this.SecretId = config.Secret_key || "";
				(config.OpenAPIUrl || "").replace(/:\d+/, (port) => {
					this.port = port.replace(":", "");
					return "";
				});
				(config.OpenAPIUrl || "").replace(/^http[s]?:[/][/][^:/]+/, (url) => {
					this.ip = url;
					return "";
				});
			});
		});
		
		if(this.isScanCode && !this.outTime>0){
			this.getBestechQRCode();
		}
		
	},
	methods: {
		close() {
			webBrowser.close();
		},
		/**扫码 */
		scanCode() {
			if (window.webview) {
				this.$webBrowser.hasCamera().then((isQr) => {
					if (isQr == "1") {
						this.$webBrowser.qr().then((d) => {
							if (isNaN(d)) {
								try {
									let data = JSON.parse((d || "").trim().replaceAll(" ", ""));
									this.setScanCodeData(data);
								} catch (error) {
									console.log(error);
									this.$message({message: "JSON格式错误,无法解析"+error,type: "warning",});
								}
							} else {
								this.setScanCodeData(d);
							}
						})
						.catch((e) => {
							console.log(e);
							this.$message({ message: "扫码失败:" + e, type: "warning" });
						});
					} else {
						this.isShow = true;
					}
				});
			} else {
				this.isShow = true;
			}
		},
		parseJSON() {
			if (this.scanCodeTxt == "") {
				console.log();
			} else {
				try {
					let data = JSON.parse(this.scanCodeTxt.trim().replaceAll(" ", ""));
					this.setScanCodeData(data);
				} catch (error) {
					console.log(error);
					this.$message({ message: "JSON格式错误,无法解析"+error, type: "warning" });
				}
			}
		},
		setScanCodeData(data) {
			if (data) {
				if (data.AppKey) {
					this.departmentKey = data.AppKey;
				}
				if (data.SecretId) {
					this.SecretId = data.SecretId;
				}
				if (data.CloudPosOpenAPI) {
					data.CloudPosOpenAPI.replace(/:\d+/, (port) => {
						this.port = port.replace(":", "");
						return "";
					});
					data.CloudPosOpenAPI.replace(/^http[s]?:[/][/][^:/]+/, (url) => {
						this.ip = url;
						return "";
					});
				}
				if (data.DepartmentName) {
					this.DepartmentName = data.DepartmentName;
				}
				this.isShow = false;
				this.scanCodeTxt = "";
			}
		},
		submit(param) {
			if(!param){
				if (this.ip == "" || this.ip == null) {
					ElMessage.warning({message: "注册地址不能为空!",type: "warning",});
					return;
				}
				if (this.deviceName == "" || this.deviceName == null) {
					ElMessage.warning({message: "设备名称不能为空!",type: "warning",});
					return;
				}
				if (this.departmentKey == "" || this.departmentKey == null) {
					ElMessage.warning({message: "门店Key不能为空!",type: "warning",});
					return;
				}
				
				var url = "";
				var http="http://";
				if(this.ip.trim().indexOf("https://")==0){
					http="https://"
				}
				if (this.port) {
					url = http + (this.ip + ":" + this.port).trim().replace(/(http:[/][/])|(https:[/][/])/g, "" );
				} else {
					url = http + this.ip.trim().replace(/(http:[/][/])|(https:[/][/])/g, "");
				}
				param = {
					OpenAPIUrl: url,
					Access_Key: this.departmentKey,
					Secret_key: this.SecretId,
					// deviceKey: this.deviceKey,
					POS_Name: this.deviceName,
					Device_Info: JSON.stringify(this.$global?.devices) || "",
				};
			}

			const loading = this.$loading({text: "请求加载中",spinner: "el-icon-loading",background: "rgba(0, 0, 0, 0.7)",});
			this.$httpAES.post("Bestech.CloudPos.RegisterPos", param).then((data) => {
				console.log("注册返回:" + JSON.stringify(data));
				loading.close();
				if (data.ResponseHeader.ResultCode != 0) {
					if (data.ResponseHeader.ResultCode == 2001) {
						//已注册
						this.$webBrowser.saveConfig({
							OpenAPIUrl: param.OpenAPIUrl,
							Access_Key: param.Access_Key,
							Secret_key: param.Secret_key,
							UUID: data.ResponseBody.UUID,
							PortalUrl:data.ResponseBody.PortalUrl,
						});
						ElMessage.success({ message: data.ResponseHeader.ResultDesc });
						this.$router.push('/login');
						
					} else {
						ElMessage.error({message: data.ResponseHeader.ResultDesc,showClose: true,duration: 5000,});
					}
					return;
				}
				this.$webBrowser.saveConfig({
					OpenAPIUrl: param.OpenAPIUrl,
					Access_Key: param.Access_Key,
					Secret_key: param.Secret_key,
					UUID: data.ResponseBody.UUID,
					PortalUrl:data.ResponseBody.PortalUrl,
				});
				ElMessage.success({ message: "注册成功" });
				this.$router.push('/login');
			}).catch((e) => {
				loading.close();
				console.log(e);
				this.$alert(e.message, "提示", {
					confirmButtonText: "确定",
					callback: (action) => {
						console.log(action);
					}, 
				});
			});
		},
		/**获取二维码数据 */
		getBestechQRCode(){
			if(this.qrLoading){
				return;
			}
			this.qrLoading=true;
			this.$httpAES.tradeManageHttp("GetBestechQRCode",{
				DEVICE_ID:CryptoJS.MD5(this.$global.devices?.Sys_ID+this.$global.devices?.uuid).toString() ,
				QRCODE_TYPE:6,
				IsEditTime:true
			}).then(d=>{
				this.qrLoading=false;
				console.log(d);
				if(d.Status==0){
					this.QRCodeInfo=d.QRCodeInfo;
					this.expiresTime= new Date(d.QRCodeInfo.EXPIRES_TIME).getTime();
					this.codeTime();
					this.getBestechQRContentInfo();
				}else{
					this.$message.error({message: d.Message,showClose: true,duration: 0});
				}
			}).catch(e=>{
				this.qrLoading=false;
				console.log(e);
				this.$alert("二维码获取失败："+e.message);
			})
		},
		/**监听二维码过期时间 */
		codeTime(){
			let time=(new Date()).getTime();
			if(this.expiresTime>=time){
				this.outTime=parseInt((this.expiresTime-time)/1000);
				if(this._time){
					clearTimeout(this._time)
				}
				this._time=setTimeout(()=>{
					this.codeTime();
				},1000)
			}else{
				this.outTime=0;
			}
		},
		/**获取扫码返回数据 */
		getBestechQRContentInfo(){
			if(this._qrContentLoad || this.outTime<=0){
				return;
			}
			this._qrContentLoad=true;
			this.$httpAES.tradeManageHttp("GetBestechQRContentInfo",{
				DEVICE_ID:this.QRCodeInfo.DEVICE_ID,
				QRCODE_ID:this.QRCodeInfo.QRCODE_ID,
			}).then(d=>{
				this._qrContentLoad=false;
				if(d.Status==0){
					this.expiresTime=0;
					this.outTime=0;
					console.log(d);
					try {
						let data = JSON.parse((d.CONTENT || "").trim().replaceAll(" ", ""));
						console.log(data);
						if(!data.CloudPosOpenAPI){
							this.$message.warning({message: "注册信息格式错误,注册地址不能为空!",type: "warning",});
							return;
						}
						if(!data.AppKey || !data.SecretId){
							this.$message.warning({message: "注册信息格式错误,门店Key不能为空!",type: "warning",});
							return;
						}
						if(!data.DeviceName){
							this.$message.warning({message: "注册信息格式错误,设备名称不能为空!",type: "warning",});
							return;
						}
						//this.deviceName=data.DeviceName;
						//this.setScanCodeData(data);
						this.submit({
							OpenAPIUrl: data.CloudPosOpenAPI.replace(/[/]$/,''),
							Access_Key: data.AppKey,
							Secret_key: data.SecretId,
							POS_Name: data.DeviceName,
							Device_Info: JSON.stringify(this.$global?.devices) || "",
						});
						
						
					} catch (error) {//解析数据错误 无法注册
						console.log(error,d);
						this.$message({message: "JSON格式错误,无法解析"+error,type: "warning",});
					}
					return;
				}
				if(this.outTime>2){
					if(this._eqTime){
						clearTimeout(this._eqTime)
					}
					this._eqTime=setTimeout(()=>{
						this.getBestechQRContentInfo();
					},1500)
				}
			}).catch(e=>{
				this._qrContentLoad=false;
				console.log(e);
				if(this.outTime>3){
					if(this._eqTime){
						clearTimeout(this._eqTime)
					}
					this._eqTime=setTimeout(()=>{
						this.getBestechQRContentInfo();
					},1500)
				}
			})
		}
	},
	unmounted(){
		if(this._time){
			clearTimeout(this._time)
		}
		if(this._eqTime){
			clearTimeout(this._eqTime)
		}
	}
};
</script>

<style lang="scss">
@import "./register.scss";
</style>
