<template>
  <modal class="arAccountModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">AR挂账功能<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body">
            <div class="clearfix" ref="CardMoney">
                <div class="left-box">
                    <tab-pane>
                        <div class="search">
                            <input type="text" placeholder="账号、名称、联系人检索" v-model="idCode" @keyup.enter="onSelect(1)"  />
                        </div>
                        <button class="btn" @click="onSelect(1)">搜索</button>
                        <div class="content-box clearfix">
                            <div class="table-box grey-table"  v-table-el-height="'tableEl'" >
                                <el-table ref="tableEl" id="paytableEl" :data="corpInfoList"  style="width: 100%;"  :row-style="rowStyle" @row-click="TableClick" border  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                                    <el-table-column style="float:left;" label="账户编号" min-width="60">
                                        <template #default="scope">
                                            <span style="float:left;">{{scope.row.Corp_Code}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="账户名称" min-width="80">
                                        <template #default="scope">
                                            <span style="overflow: hidden;text-overflow: ellipsis; white-space: nowrap;float:left;">{{scope.row.Corp_Name}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="挂账余额" min-width="70">
                                        <template #default="scope">
                                            <span style="float:right;">{{Number(scope.row.Corp_Money).toFixed(2) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="信用额度" min-width="70">
                                        <template #default="scope">
                                            <span style="float:right;">{{Number(scope.row.Credit_Money).toFixed(2)}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="剩余可挂账余额" min-width="90">
                                        <template #default="scope">
                                            <span style="float:right;color:red;font-size:15px;font-weight:600">{{Number(scope.row.Credit_Money-scope.row.Corp_Money).toFixed(2)}}</span>
                                        </template>
                                    </el-table-column> 
                                    <el-table-column prop="Link_Man" label="联系人" min-width="50">
                                        <template #default="scope">
                                            <span style="float:left;">{{scope.row.Link_Man}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="备注" min-width="50">
                                        <template #default="scope">
                                            <span style="float:left;">{{scope.row.Remark}}</span>
                                        </template>
                                    </el-table-column> 
                                </el-table>
                            </div>
                        </div>
                    </tab-pane> 
                </div>
                <div class="right-box">
                    <div>
                        <span class="titlespan">待支付金额:</span>
                        <span class="spanMoney">{{ Number(unPaidMoney).toFixed(2)}}</span>
                    </div>
                    <div class="table-box">
                            <div class="tr-li">
                                <div class="lable-txt">账户编码：<span class="labelcorp">{{corpCode}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt">账户名称：
                                    <span class="labelcorp"> {{corpName}}</span>
                                </div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt">挂账余额：<span class="labelcorp">{{ Number(corpMoney).toFixed(2)}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt">信用额度：<span class="labelcorp">{{ Number(creditMoney).toFixed(2)}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt">剩余可挂账金额：<span class="labelcorp">{{ Number(creditMoney-corpMoney).toFixed(2)}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt">挂账金额：
                                        <span style="padding-left:100px;"><input type="text" ref="input" class="key-focus" v-model="chargeMoney" /></span>
                                    </div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt">备注：
                                    <span style="padding-left:30px;"><input type="text" v-board ref="payremark" class="pay-focus" v-model="payremark" @focus="(e)=>e.target.select()" /></span></div>
                            </div>
                    </div>
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
        </tabs>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>


<script>
import {initKeyBoard} from '/src/common' 
import { httpAES,cacheData } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import resizeTable from '../../../report/reportModel/tabelleAnpassen.js'
/**全局初始化数据 */
let _data={
    OpenArPayRemarkMustInput:false //是否启用AR挂账支付必须输入备注的控制
};

/**会员支付 */
export default {
    name:"arAccountModel",
    props:{
        isShow:Boolean,
        choicePayType:Object,
        unionEatAutoID:Object,
        unPaidMoney:Number,
        headingInfo:Object,
        Channellike:String,
        MantissaMoney:Number,
        orderMenuList:Object,
        RoomAutoID:String,
        DeskName:String,
        EatAutoID:String,
        Timestamps:String,
        //支付预览用会员信息
        member:{
            type:Object,
            default:null
        }
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.OpenArPayRemarkMustInput = globalSysSetting.OpenArPayRemarkMustInput;
        }
        return {
            idCode:'',
            arLiks:false,
            corpInfoList:[],
            corpAutoID:'',
            corpCode:'',
            corpName:'',
            corpMoney:'',
            payremark:'',
            creditMoney:'',
            /**临时订单数据 */
            chargeMoney:'',
            //unPaidMoney:0,
            /**支付记录 */
            Payments:[],
            /**是否执行支付提交 */
            isPaySubmit:0,
        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$el);
           //+this.memberorderInfo.Mantissa_Money
           //this.unPaidMoney = this.memberorderInfo.UnPaid_Money
           this.EmptyDatas()
           /**拖动表宽度 */
            resizeTable();
        })
    },
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听 +this.memberorderInfo.Mantissa_Money
        isShow(){ 
            if(this.isShow==true){
                this.isPaySubmit=0;
                //this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                this.EmptyDatas()
            }
        },
    },
    methods:{
         /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
            this.emptyData()
        },
        hide(){
            this.$emit('closeModel')
            //this.$emit("arAccountReturn",this.Payments,this.memberorderInfo.Timestamps,this.isPaySubmit)
        },
        /**获取AR账户信息GetARCorpInfo */
        onSelect(type){
            if(type==4){
                this.$message.error('该按钮暂时无效');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Query_Param:this.idCode
            }
            if(type==1){
                param.Corp_Code = this.idCode
            }
            if(type==2){
                param.Corp_Name = this.idCode
            }
            if(type==3){
                param.Link_Man = this.idCode
            }
            console.log('param:'+JSON.stringify(param))
            const loading = this.$loading({
                text: "账户信息查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetARCorpInfo",param).then((data)=>{
                loading.close();
                console.log('ar账返回:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.corpInfoList=''
                    this.corpInfoList=data.ResponseBody
                    this.corpInfoList =this.corpInfoList.sort((a, b) => a.Corp_Code - b.Corp_Code)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**确认 */
        confirm(){
            if(Number(this.chargeMoney)>(Number(this.creditMoney)-Number(this.corpMoney))){
                this.$message.error('结账金额超出可用信用额度');
                return
            }
            if(Number(this.chargeMoney)>(this.unPaidMoney.toFixed(2))){
                this.$message.error('挂账金额不可大于结账金额');
                return
            }
            if(_data.OpenArPayRemarkMustInput && !this.payremark){
                this.$message.error('备注信息不能为空');
                return
            }
            let IsMultipleCardPay = false
            if(Number(this.chargeMoney)<(this.unPaidMoney.toFixed(2))){
                IsMultipleCardPay=true
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            /**支付方式 */
            let _payment=[]
            _payment.push({
                Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                Payment_Money:Number(this.chargeMoney), //支付方式金额
                Payment_FactMoney:Number(this.chargeMoney),  //支付实收金额
                Qr_Code:"", //扫码付款码值
                Card_No:"", //会员卡号
                Corp_AutoID:this.corpAutoID,//挂账账户ID
                Is_PartialArPay:IsMultipleCardPay,
                Payment_Remark:this.payremark//备注
            })
            let _orderConsumes=this.$global.getFastOrderConsumes(this.orderMenuList);
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:'', //订单ID，当异常单重新处理时需要传入
                Timestamps:'',//订单单时间戳，当异常单重新处理时需要传入
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                Channel_ID:this.Channellike,//订单渠道ID
                Room_ID:this.RoomAutoID,//餐厅ID
                Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                Eat_DeskName:this.DeskName, //桌台名称
                Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                Eat_AllRemark:'', //订单全单备注
                Order_Remark:this.headingInfo.orderRemark,//订单备注
                Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                NotSendToKitPrint:false, //下单不送打厨房
                MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                Member:this.member,//会员信息
                OrderConsumes:_orderConsumes, //订单消费详细
                Payments:_payment//支付方式
            }
            //当异常单重新处理时需要传入
            if(this.EatAutoID){
                param.Eat_AutoID=this.EatAutoID
                param.Timestamps=this.Timestamps.toString()
            }
            /**支付预览 */
            const loading = this.$loading({
                text: "支付预览中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(data.ResponseBody?.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                    if(data.ResponseBody.Is_NeedSMSCode==1){
                        console.log('短信验证')
                    }
                    /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                    if(data.ResponseBody.Is_NeedPassWord==1){
                        console.log('密码验证')
                    }
                    /**支付提交判断 0不需要 1需要 */
                    if(data.ResponseBody.Is_NeedSubmit==1){
                        this.PaySubmit(data)
                    }else{
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**支付提交接口 */
        PaySubmit(data){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                Verify_Code:'', //验证码
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Timestamps:data.ResponseBody.Timestamps.toString(),
                Payments:data.ResponseBody.Payments
            }
            /**支付提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(data.ResponseBody.IsMultipleCardPay==false){
                        this.Payments = this.Payments.concat(data.ResponseBody.Payments)
                        this.isPaySubmit =1
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        ElMessage.success({ message: "支付完成"});
                        this.$emit('submiConfirm')
                    }else{
                        if(data.ResponseBody.IsMultipleCardPay==true){
                            this.$emit('subMultipleConfirm',data.ResponseBody);
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        TableClick(item){
            this.corpAutoID = item.Corp_AutoID
            this.corpCode = item.Corp_Code
            this.corpName = item.Corp_Name
            this.corpMoney = item.Corp_Money
            this.creditMoney = item.Credit_Money
            this.chargeMoney = this.unPaidMoney.toFixed(2)
            this.$refs.input.focus();
            setTimeout(()=>{
                this.$refs.input?.select();
            },100)
        },
        rowStyle({row, rowIndex}) {
            if (this.corpAutoID === row.Corp_AutoID) {
                // 此处返回选中行的样式对象，按需设置
                return {
                    'background-color': '#FCEED4'
                }
            }
        },
        /**清空页面数据 */
        emptyData(){
             this.corpInfoList=[]
             this.chargeMoney=''
             this.corpAutoID =''
             this.corpCode =''
             this.corpName =''
             this.payremark=''
             this.corpMoney =''
             this.creditMoney =''
        },
    }
}
</script>

<style lang="scss">
@import "./arAccountModel.scss";
</style>