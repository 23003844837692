<template>
  <modal class="memberCardPriceModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">会员价<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body" >
            <div ref="CardMoney">
            <div class="filter-nav">
                <div class="nav-box">
                    <tab-nav class="nav-li" >输入会员卡查询</tab-nav>
                </div>
            </div>
            <div class="clearfix" >
                <div class="left-box">
                    <tab-pane>
                        <div class="tr-li">
                            输入会员动态身份码查询：<br/>
                            <div class="from-input" style="width:435px">
                                <input type="text" class="key-focus" ref='idCode' data-model='idCode' v-model="idCode" @keyup.enter="onMemberInfo()" />
                                <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                            </div>
                            <button class="btn" @click="readCard()">读卡</button>
                        </div>
                    </tab-pane>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            </div>
        </tabs>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()" >取消</button>
            <button class="btn btn-confirm" @click="confirm()" >确认</button>
        </div>
        <!--会员卡信息-->
    <modal-load :isShow="cardMemberShow">
        <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
    </modal-load>
  </modal>
</template>


<script>
import {initKeyBoard} from '/src/common' 
import { newGuid } from '/src/common/index.js'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'

/**会员支付 */
export default {
    name:"memberCardPriceModel",
    components:{
        cardMemberModel
    },
    props:{
        isShow:Boolean,
        orderInfo:Object
    },
    data(){
        return {
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**身份码 */
            idCode:'',
            /**会员卡信息 */
            cardMemberList:[],
            cardMemberShow:false,
            meberInfo:'', //会员信息
            userInfo:''
        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           this.idCode=''
           this.meberInfo=''
           this.userInfo=this.$auth.getUserInfo(); //获取用户id
           this.EmptyDatas()
        })
    },
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.idCode=''
                this.meberInfo=''
                this.EmptyDatas()
            }
        }
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.idCode.focus();
            },100);
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    console.log('机器读卡:'+JSON.stringify(d))
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**查询会员信息 */
        confirm(){
            if(this.meberInfo){
                if(this.meberInfo.Is_Have_ParPrice!=1){
                    this.$message.warning('该卡不不享有会员价!');
                    return
                }else{
                    let param={
                        User_ID:this.userInfo?.User_ID,  //用户id
                        Operator_Name:this.userInfo?.Login_Name, //操作员
                        Eat_AutoID:this.orderInfo.Eat_AutoID, //订单ID
                        Member_CardNo:this.idCode, //会员卡号
                        Timestamps:this.orderInfo.Timestamps, //订单时间戳
                    }
                    if(sessionStorage.getItem("tempUserID")){
                        param.User_ID = sessionStorage.getItem("tempUserID")
                        param.Operator_Name = sessionStorage.getItem("tempName")
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    /**去执行会员价接口 */
                    const loading = this.$loading({
                        text: "会员价计算中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.DoVipPrice",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            this.$emit("cardPriceReturn",data,'')
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }
            }else{
                if(!this.idCode){
                    this.$message.error('查询码不能为空');
                    return
                }else{
                    this.onMemberInfo()
                    return
                }
            }
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode && !this.cardSNR){
                this.$message.error('查询码不能为空');
                return
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                        this.confirm()
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.confirm()
        },
    }
}
</script>

<style lang="sass">
@import "./memberCardPriceModel.scss";
</style>