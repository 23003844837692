<template>
    <modal class="authorizationModel" :isShow="isShow" >
        <div class="header">温馨提示</div>
        <div class="modal-body">
            <div class="title">请输入用户名和密码：</div>
            <div class="li-flex">
                <div class="lable-txt">用户名：</div>
                <div class="from-box"><input type="text" v-model="name" ref="name" @keydown.enter="$refs.pwd.focus();this.showKey();" @click="showKey()" /></div>
            </div>
            <div class="li-flex">
                <div class="lable-txt">密码：</div>
                <div class="from-box"><input type="password" v-model="pwd" ref="pwd" @keydown.enter="confirm()" @click="showKey()"/></div>
            </div>
        </div>
        <div class="footer-box">
            <div class="btn btn-cancel" @click="hide()">取消</div>
            <div class="btn btn-confirm" @click="confirm()">确认</div>
        </div>
    </modal>
</template>

<script>
/** 权限验证 */
export default {
    name:"authorizationModel",
    emits:["close"],
    props:{
        isShow:Boolean,
    },
    data(){
        return {
            /** 用户名 */
            name:"",
            /** 密码 */
            pwd:""
        }
    },
    
    watch:{
        isShow(){
            if(this.isShow){
               this.init();
            }
        }
    },
    mounted(){
        
    },
    methods:{
        init(){
            this.name="";
            this.pwd="";
            setTimeout(()=>{
                this.$refs.name?.focus();
                this.showKey();
            },500)
        },
        showKey(){//安卓 弹出软键盘
            if(this.$webBrowser.isMobile.Android){
                this.$webBrowser.openWindKeyBoard();
            }
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
            if(this.isLogin) return;
            this.isLogin=true;
            const loading = this.$loading({
                text: "请求加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Login_Type:0,//登录类型 0—账号密码登录 ，3—刷卡登录
                Login_Name:this.name,
                Login_Pwd:this.pwd,
                MenuKey:"SendDish",
                Order_Type:0 //订单类型 0—中餐 ，1—快餐
            }
            this.$httpAES.post("Bestech.CloudPos.CheckUserRight",param,false).then((d)=>{
                loading.close();
                this.isLogin=false;
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    this.$refs.name?.focus();
                }else{
                    this.$emit("confirm",d.ResponseBody);
                    this.hide();
                }
            }).catch((e)=>{
                this.isLogin=false;
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
           
        }
    }
}
</script>

<style lang="scss">
    @import './authorizationModel.scss'
</style>