<template>
    <modal class="editOrderOperatorModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">修改点菜员 </div>
        <div class="modal-body">
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">
                        <div class="td selectChecked">选择</div>
                        <div class="td name">菜品名称</div>
                        <div class="td number">数量/单位</div>
                        <div class="td operator">点菜人/时间</div>
                        <div class="td newOperator" @click="selectOperateor()">改为点菜人<span class="bact">【批量】</span></div>
                    </div>
                </div>
                <div class="panel-body"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                    <div class="tr" :class="{'current-row':currentIndex==index}" v-for="(item,index) in consumeList" :key="item" @click="currentIndex=index">
                        <div class="td selectChecked">
                            <div class="cell">
                                <el-checkbox class="selectChecked" v-model="item.selected" @change="foodClick(item)"/>
                            </div>
                        </div>
                        <div class="td name">
                            <div class="cell">
                                <div class="nowrap2">{{item.EAT_XFName}}</div>
                                <div class="assNum" v-if="item.Eat_AssSize" >辅助单位:<span>{{item.Eat_AssNumber}}{{item.Eat_AssSize}}</span></div>
                            </div>
                        </div>
                        <div class="td number">
                            <div class="cell">
                                {{item.Eat_Number}} <span class="unit">{{item.Eat_XFSize}}</span>
                            </div>
                        </div>
                        <div class="td operator">
                            <div class="cell">
                                {{item.Eat_Czy}} <span class="unit">{{shortTime(item.Eat_Time)}}</span>
                            </div>
                        </div>
                        <div class="td newOperator">
                            <div class="cell">
                                <div class="selectBnt" @click="selectOperateor(item)"><input type="text" v-model="item.newEat_Czy" placeholder="请选择点菜员" readonly maxlength="15"><i class="iconfont icon-kehu"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                <el-checkbox class="selectChecked" :model-value="isAllCheck" label="全选" @change="allCheck" />
                <div class="float-right">
                    <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <!-- 选择 点单员 -->
        <modal-load :isShow="orderOperatorShow">
            <select-order-operator-model ref='selectOrderOperator' 
                :isShow="orderOperatorShow" 
                :isPlace="false"
                @close="orderOperatorShow=false"
                @confirm="operatorChange"/>
        </modal-load>
  </modal>
</template>

<script>
import selectOrderOperatorModel from '../../model/selectOrderOperatorModel/selectOrderOperatorModel.vue'
export default {
    name:'editOrderOperatorModel',
    components:{
        selectOrderOperatorModel
    },
    emits:['close','confirm'],
    props:{
        //是否显示弹层
        isShow:{
            type:Boolean,
            default:false
        },
        //订单数据
        orderInfo:Object,
        //是否结账
        isCheckOut:Boolean,
        //是否联台
        unionType:Boolean
    },
    data(){
        return{
            //点菜员 弹层
            orderOperatorShow:false,
            /**用户数据 */
            userInfo:'',
            //订单明细数据
            consumeList:[],
            //高亮
            currentIndex:null,
            //要修改的明细数据
            editItem:null
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.init();
        })
    },
    computed:{
        isAllCheck(){
            if(this.consumeList.length>0){
                return !this.consumeList.some(it=>!it.selected);
            }
            return false;
        }
    },
    watch:{
        isShow(){
            if(this.isShow){
                this.init();
            }
        }
    },
    methods:{
        //初始化
        init(){
            let data=[];
            this.orderInfo?.Order_ConsumeList?.forEach(it=>{
                if(it.Food_Status>=0){//已经下单 
                    data.push(Object.assign({selected:false},it,{
                        newEat_Czy:'',//点菜员
                    }))

                    //组合明细
                    it.Eat_ComboItemList?.forEach(item=>{
                        data.push(Object.assign({selected:false},item,{
                            newEat_Czy:'',//点菜员
                        }))
                    })
                }
            })
            this.consumeList=data;
        },
        /**时间 取 时:分 */
        shortTime(cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('hh:mm');
        },
        //勾选 或 取消
        foodClick(item,isBo){
            if(isBo!=undefined){
                if(item.selected==isBo){
                    return;
                }
                item.selected=isBo;
            }
            if(item.selected){
                item.newEat_Czy=item.Eat_Czy;
            }else{
                item.newEat_Czy='';
            }
        },
        allCheck(){
            let isbo=!this.isAllCheck;
            this.consumeList.forEach((item)=>{
                item.selected=isbo;
                if(item.selected){
                    item.newEat_Czy=item.Eat_Czy;
                }else{
                    item.newEat_Czy='';
                }
            })
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("close")
        },
        /**选择点菜员 */
        selectOperateor(item){
            if(!item){//批量修改
                if(!(this.consumeList?.length>0)){
                    return;
                }
                if(!this.consumeList.some(it=>it.selected)){
                    this.$message.warning('请先选择需要修改的菜品!');
                    return;
                }
            }
            this.editItem=item;//记录要修改的数据
            this.orderOperatorShow=true;
        },
        /**选择的点菜员 */
        operatorChange(operatorName){
            if(!operatorName){
                this.$message.warning("请先选择点菜员！");
                return;
            }
            if(this.editItem){
                this.editItem.newEat_Czy=operatorName;
                this.editItem.selected=true;
            }else{//批量修改
                this.consumeList.filter(it=>it.selected).forEach((item)=>{
                    item.newEat_Czy=operatorName;
                });
            }
            this.orderOperatorShow=false;
        }, 
        confirm(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            if(this.consumeList.length==0){
                this.$message.error('没有可修改的数据!');
                return;
            }
            let consumes=[];
            for(let i=0;i<this.consumeList.length;i++){
                let item=this.consumeList[i];
                if(item.selected){
                    consumes.push({
                        Eat_ConsumeID:item.Eat_ConsumeID,
                        Eat_Czy:item.newEat_Czy,//点菜员
                    })
                }
            }
            if(consumes.length==0){
                this.$message.warning('没有修改的数据!请先勾选要修改的菜品!');
                return;
            }

            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_AutoID:this.orderInfo.Eat_AutoID,
                Consumes:consumes, //
                Timestamps:this.orderInfo.Timestamps.toString()
            }
            if(sessionStorage.getItem("tempUserID")){//权限验证， 上一个权限
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }

            const loading = this.$loading({
                text: "数据修改中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log("修改点菜员 请求",param)
            this.$httpAES.post("Bestech.CloudPos.ModifyOrderConsumeOperator",param).then((data)=>{
                console.log("修改点菜员 返回",param)
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: "修改成功"});
                    this.$emit("confirm",data.ResponseBody,param.Consumes)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        }
    }
}
</script>

<style lang="scss">
@import './editOrderOperatorModel.scss';
</style>