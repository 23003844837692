<template>
    <modal class="giveReturnModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">赠送操作</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品名称：</div>
                        <div class="from-input" style="width:310px">
                            <input type="text"  readonly :value="disRadioOrder.EAT_XFName" />
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">点单数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" placeholder="0"  readonly :value="disRadioOrder.Eat_Number" /></div>
                        <div class="lable-txt">赠送数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" placeholder="0" class="key-focus" data-model='orderNum' ref="input" @focus="$event.target.select()"  v-model="orderNum" oninput="value=value.replace(/[^\d.]/g,'')" /></div>
                    </div>
                    <div class="title">选择赠送原因</div>
                    <div class="reason-box" v-mouse-scroll>
                        <div class="reason-tag" :class="{selected:item.OperateReason_AutoID==OperateAutoID}" v-for="(item,index) in RefundReasonsList" v-show="item.OperateReason_Kind==1" :key="index" @click="clickReason(item)" >{{item.OperateReason_Name}}</div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-cancel" @click="changeUser()">变更用户</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <!--权限验证-->
        <modal-load :isShow="checkShow" :isUnload="true">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" 
                :checkBtnlist="recordFunctionCheck" 
                :orderType="1"
            @checkbtnReturn="checkbtnReturn"></check-user-button-model>
        </modal-load>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'

export default {
    name:'giveReturnModel',
    props:{
        isShow:Boolean,
        disRadioOrder:Object,
        foodOperationRecord:Object,
        recordFunctionCheck:Object,
        checkUser:Object
    },
    components:{
        checkUserButtonModel
    },
    data(){
        return{
            orderNum:'',
             /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            OperateAutoID:0,
            OperateContent:'',
            checkShow:false,
            TheLimitedGiftingMoney:0,
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$refs.recharRight);
            this.orderNum=this.disRadioOrder?.Eat_Number;
            if(!Object.keys(this.checkUser).length==0){
                this.userInfo=this.checkUser; //获取用户id
            }else{
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
            }
            /**赠送原因 */
            this.GiveReasons()
            setTimeout(()=>{
                this.$refs.input.focus();
            },100)
        })
    },
    watch:{
        isShow(){
            if(this.isShow==true){
                this.OperateAutoID=0;
                this.OperateContent='';
                this.TheLimitedGiftingMoney=0
                if(!Object.keys(this.checkUser).length==0){
                    this.userInfo=this.checkUser; //获取用户id
                }else{
                    this.userInfo=this.$auth.getUserInfo(); //获取用户id
                }
                this.orderNum=this.disRadioOrder?.Eat_Number
                this.$nextTick(()=> {
                    this.$refs.input.focus();
                })
                /**赠送原因 */
                this.GiveReasons()
            }
        },
        orderNum(){
            if(Number(this.orderNum)<0){
                this.orderNum=0
            }
        },
    },
    methods:{
        //变更用户
        changeUser(){
            this.checkShow=true
        },
        //权限验证返回
        checkbtnReturn(data){
            this.checkShow = false;
            this.userInfo.Login_Name=data?.Login_Name
            if(data?.TheLimitedGiftingMoney){
                this.userInfo.TheLimitedGiftingMoney= data.TheLimitedGiftingMoney
                this.confirm()
            }
        },
        hide(){
            this.$emit("closeModel")
        },
        /**赠送原因 */
        GiveReasons(){
            this.$cacheData.OperateReasons().then((d)=>{
                this.RefundReasonsList = d;
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
         /**原因 */
        clickReason(item){
            this.OperateAutoID = item.OperateReason_AutoID;
            this.OperateContent = item.OperateReason_Name;
        }, 
        confirm(){
            let _Price = Number(this.orderNum) * this.disRadioOrder.Eat_CheckPrice
            if(this.foodOperationRecord.length>0){
                this.foodOperationRecord.map(food=>{
                    if(food.foodType==1){
                        _Price +=food.givinPrice
                    }
                })
            }
            console.log(this.userInfo)
            if(this.userInfo.TheLimitedGiftingMoney<Number(_Price)){
                this.$confirm("本单赠送金额已达"+Number(_Price)+","+this.userInfo.Login_Name+"赠送权限金额只有:"+this.userInfo.TheLimitedGiftingMoney+",是否去变更用户", '提示', {
                    confirmButtonText: '去变更',cancelButtonText: '我知道了',type: 'warning'})
                .then(() =>{
                    this.changeUser()
                }).catch(() =>{});
                // this.$message.warning(");
                this.$refs.input.focus();
                return
            }
            if(this.OperateContent==''){
                this.$message.warning("赠送的原因不能为空!");
                return
            }
            if(this.disRadioOrder.Eat_Number<Number(this.orderNum)){
                this.$message.warning("赠送数量不能超过点单数量!请修改赠送数量");
                this.$refs.input.focus();
                return
            }
            this.$emit("GiveFoodFreeClick",this.orderNum,this.OperateAutoID,this.OperateContent,this.userInfo)
        }
    }
}
</script>

<style lang="scss">
@import './giveReturnModel.scss';
</style>