import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Element from 'element-plus'
import common from './common'
import './preheat.js'
import services from './services'
import template from './components/template'
/**自定义 svg 图标 */
import './common/iconSvg.js'
/**svg 多颜色icon */
import './assets/fonts/iconfont.js'
import 'element-plus/lib/theme-chalk/index.css';
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn';

createApp(App)
.use(router)//路由
.use(Element,{ locale })//ui
.use(VueAxios,axios)//http请求
.use(template)
.use(common)//公共插件 全局加载
.use(services)
.use({
  install(Vue){//全局 错误 监听
    Vue.config.errorHandler = (error, vm)=>{ 
      console.log("error:",error);
      console.log(vm)
    };
  }
})
.mount('#app');
 

if(process.env.NODE_ENV != "development"){//非开发环境  
	let originalLog=console.log;
	console.log=function(...args){
		let isConsole=localStorage.getItem("isConsole");
		if(isConsole==="true" || vConsole){
			return originalLog.apply(this, args);
		}
	}
}
//调试 用  2秒内 点击左上角 5 次 出现日志按钮
import VConsole from 'vconsole'
let startClickTime=0;
let clickNum=0;
let vConsole=null;
window.addEventListener("click",(e)=>{
	
	if(e.x<45 && e.y<45){
		let time=(new Date()).getTime();
		if(startClickTime+2000<time){//2秒内点击 5次 则弹出日志按钮
			startClickTime=time;
			clickNum=1;
		}else{
			clickNum++;
			if(clickNum==5){
				if(!vConsole){
					vConsole=new VConsole({
						defaultPlugins:["log","network"],
						onReady:()=>{
							vConsole.setSwitchPosition(20, 20);
						}
					})
				}else{
					vConsole.destroy();//移除日志
					vConsole=null;
				}
			}
		}
	}
})