<template>
  <modal class="adjustQuantityModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">数量调整
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品名称：</div>
                        <div class="from-input" style="width:310px"><input type="text" :value="Recordvarie.EAT_XFName" readonly /></div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">点单数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" :value="Recordvarie.Eat_Number" readonly/></div>
                        <div class="lable-txt">改后数量：</div>
                        <div class="from-input" style="width:115px"><input type="text"  class="key-focus" ref="numInput" data-model='number' @keyup.enter="inputCursor(1)" @focus="$event.target.select()" @input="changeRate($event,number)" v-model="number"/></div>
                    </div>
                    <div class="title" v-show="Recordvarie.Food_AssSize">辅助单位:{{Recordvarie.Food_AssSize}}</div>
                    <div class="tr-li" v-show="Recordvarie.Food_AssSize">
                        <div class="lable-txt">辅助数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" :value="Recordvarie.AssSizeNumber" readonly/></div>
                        <div class="lable-txt">改后数量：</div>
                        <div class="from-input" style="width:115px"><input type="text"  class="key-focus" ref="assSizeNumber" data-model='assSizeNumber' @keyup.enter="inputCursor(2)" @focus="$event.target.select()" @input="changeRate($event,number)" v-model="assSizeNumber"/></div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 

export default {
    name:"adjustQuantityModel",
    props:['isShow','Recordvarie'],
    data(){
        return {
            number:"",//数量
            assSizeNumber:'',//辅助数量
        }
    },
    mounted(){
       this.$nextTick(()=> {
            initKeyBoard.call(this,this.$refs.recharRight);
            this.number=this.Recordvarie?.Eat_Number;
            this.EmptyDatas()
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            this.number=this.Recordvarie?.Eat_Number;
            this.assSizeNumber=''
            this.EmptyDatas();
        },
    },
    methods:{
        changeRate(e,input){
            e.target.value=(e.target.value.match(/^\d*(\.?\d{0,3})/g)[0]) || null
            this.input= e.target.value
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.numInput.focus();
            },100);
        },
        inputCursor(type){
            if(type==1 && this.Recordvarie.Food_AssSize!=''){
                this.$refs.assSizeNumber.focus();
            }
            if(type==2){
                this.confirm()
            }
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**确定和回车事件 */
        confirm(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            if(this.Recordvarie.Food_AssSize){
                if(Number(this.assSizeNumber)==0){
                     this.$alert('辅助数量不能为0!', '提示', {confirmButtonText: '确定',});
                    return
                }
            }
            if(Number(this.number)>=100000000){
                this.$alert('超过数量修改上限', '提示', {confirmButtonText: '确定',});
                return
            }
            if(Number(this.number)==0){
                this.$alert('数量不能为0!', '提示', {confirmButtonText: '确定',});
                return
            }else{
                let num = /^\d+(\.\d{1,4})?$/;
                if (!num.test(this.number)){
                    this.number = Number(this.number).toFixed(3)
                }
                //this.$emit("quantityReturn",Number(this.number).toFixed(3),Number(this.assSizeNumber).toFixed(2))
                this.$emit("quantityReturn",this.number,Number(this.assSizeNumber).toFixed(2))
            }
        },
    }
}
</script>

<style lang="sass">
@import "./adjustQuantityModel.scss";
</style>