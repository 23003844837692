<template>
    <div class="lunarCalendar" @click="show" @mousedown="downStop">
        <div class="calendar__inner">
            <slot :lunarDate="lunarDate">{{calendarText}}</slot>
        </div>
        <div class="calendar__prefix"><i class="iconfont icon-rili"></i></div>
        <teleport to="body" >
            <div class="lunarCalendar__popper" :style="popperStyle" @click.stop>
                <div class="lunarCalendar__header">
                    <div class="last-month" @click="lastClick"></div>
                    <div class="month-text">{{currentYear}}年{{currentMonth}}月</div>
                    <div class="next-month"  @click="nextClick"></div>
                </div>
                <div class="lunarCalendar-panel__content">
                    <table cellspacing="0" cellpadding="0" >
                        <tr class="week__row">
                            <td v-for="item in ['一','二','三','四','五','六','日']" :key="item">周{{item}}</td>
                        </tr>
                        <tr class="calendar-table__row" v-for="(item,trIndex) in 6" :key="trIndex">
                            <td :class="setClass(monthArr[trIndex][rowIndex])" v-for="(row,rowIndex) in 7" :key="rowIndex" @click="dateClick(monthArr[trIndex][rowIndex])">
                                <div class="cell-box">
                                    <div class="day">{{monthArr[trIndex][rowIndex].day}}</div>
                                    <div class="lunarDay">{{monthArr[trIndex][rowIndex].holiday || monthArr[trIndex][rowIndex].lunar.lunarDay}}</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="lunarCalendar-foot__bnt">
                    <div class="bnt" @click="hide()">取消</div><div class="bnt confirm-bnt" @click="confirm">确认</div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import {getLunar} from 'chinese-lunar-calendar'
const heavenlyStemStr = '甲乙丙丁戊己庚辛壬癸';
//const earthlyBranchStr = '子丑寅卯辰巳午未申酉戌亥';
//显示 公历/农历 
export default {
    name:"lunarCalendar",
    emits:['update:modelValue','change'],
    props:{
        modelValue: {
            type: [String, Date],
            default: null
        },
        placeholder:{
            type:String,
            default:""
        },
        format:{
            type:String,
            default:"yyyy-MM-dd"
        },
        /**日期范围 最小日期 */
        minDate:{
            type: [String, Date],
            default: null
        }
    },
    data(){
        let date=new Date();
        return {
            //日期
            dateVal:date,
            nubmerStr:"一二三四五六七八九十",
            week:["日","一","二","三","四","五","六"],
            //农历节日
            lunarHoliday:{
                1:{1:{text:"春节",day:7},15:{text:"元宵节",day:0}},
                2:{2:{text:"龙抬头",day:0}},
                5:{5:{text:"端午节",day:3}},
                7:{7:{text:"七夕节"},15:{text:"中元节"}},
                8:{15:{text:"中秋节"}},
                9:{9:{text:"重阳节"}},
                10:{1:{text:"寒衣节"}},
                12:{8:{text:"腊八节"}}
            },
            //公历节日
            gLiHoliday:{
                1:{1:{text:"元旦"}},
                2:{14:{text:"情人节"}},
                3:{7:{text:"女生节"},18:{text:"妇女节"},12:{text:"植树节"}},
                4:{1:{text:"愚人节"},4:{text:"寒食节"},5:{text:"清明节"},20:{text:"谷雨"}},
                5:{1:{text:"劳动节"},4:{text:"青年节"},6:{text:"立夏",21:{text:"小满"}}},
                6:{1:{text:"儿童节"}},
                7:{1:{text:"建党节"}},
                8:{1:{text:"建军节"}},
                9:{10:{text:"教师节"}},
                10:{1:{text:"国庆节"},8:{text:"寒露"}},
                11:{1:{text:"万圣节"}},
                12:{24:{text:"平安夜"},25:{text:"圣诞节"}}
            },
            //显示日历
            isShow:false,
            popperStyle:null,
            current:date,
            //当前年
            currentYear:date.getFullYear(),
            //当前月
            currentMonth:date.getMonth()+1,
            //今天
            toDay:{
                year:date.getFullYear(),
                month:date.getMonth()+1,
                day:date.getDate()
            },
            //日期范围
            rangeMinDate:null
        }
    },
    watch:{
        minDate:{
            handler(newVal){
                if(newVal instanceof Date){
                    this.rangeMinDate=newVal;
                }else if(newVal){
                    this.rangeMinDate=new Date(newVal);
                }
            },
            immediate:true,
        },
        modelValue:{
            handler(newVal){
                if(newVal instanceof Date){
                    this.dateVal=newVal.Format(this.format);
                }else{
                    this.dateVal=newVal;
                }
            },
            immediate:true,
        },
        dateVal:{
            handler(newVal){
                if(this.dateVal!=this.current.Format(this.format)){
                    this.current=new Date(this.dateVal);
                }
                
                this.$emit('update:modelValue', newVal);
                this.$emit('change', this.dateVal,this.lunarDate);
            },
            immediate:true,
        },
        isShow:{
            handler(newVal){
                if(newVal){
                    let rect=this.$el.getBoundingClientRect();
                    let left=rect.left;
                    let top=(rect.top+rect.height+11);
                    let popperStyle={
                        display:"block"
                    };
                    if(top+488>window.innerHeight){
                        top=rect.top-488-11;
                        popperStyle['--hornTop']="471px";
                    }

                    if(left+460>window.innerWidth){
                        left=rect.right-460;
                        popperStyle['--hornLeft']="426px";
                    }
                    popperStyle.left=left+"px";
                    popperStyle.top=top+"px";
                    this.popperStyle= popperStyle;
                    let date=new Date(this.dateVal);
                    if(date.getFullYear()!=this.currentYear || date.getMonth()+1!=this.currentMonth){
                        this.current=new Date(this.dateVal);
                    }
                }else{
                    this.popperStyle=null;
                }
            },
            immediate:true,
        },
        current(newVal){
            if(newVal){
                //当前年
                this.currentYear=newVal.getFullYear();
                //当前月
                this.currentMonth=newVal.getMonth()+1;
            }
        },
    },
    computed:{
        calendarText(){//2024-04-18 (三月初十) 星期四
            let pDate=(new Date(this.dateVal)||new Date());
            const year = pDate.getFullYear();
            const month = pDate.getMonth() +1;
            const day = pDate.getDate();
            const result = getLunar(year,month,day);
            return pDate.Format(this.format||'yyyy-MM-dd')+" ("+result.dateStr+") "+"星期"+this.week[pDate.getDay()];
        },
        //农历
        lunarDate(){
            let pDate=(new Date(this.dateVal)||new Date());
            const year = pDate.getFullYear();
            const month = pDate.getMonth() +1;
            const day = pDate.getDate();
            return this.getLunar(year,month,day);
        },
        //当前月显示
        monthArr(){
            //月初
            let date=new Date(this.currentYear+"-"+this.currentMonth+"-01");
            //月末
            let dateStr=this.currentYear+"-"+(this.currentMonth+1)+"-01";
            if(this.currentMonth==12){
                dateStr=(this.currentYear+1)+"-01-01";
            }
            let monthEnd=new Date(dateStr);
            monthEnd.setDate(0);

            //月初周几
            let weekNum=date.getDay();
            if(weekNum==0){
                weekNum=7;
            }
            date.setDate(1-(weekNum-1));
            //上月年
            let lastYear=date.getFullYear();
            //上月月
            let lastMonth=date.getMonth()+1;
            //上月 周一天
            let lastDay=date.getDate();

            let data=[];
            let day=1;
            //月末是几号
            let dayMax=monthEnd.getDate();
            //下月初1号
            let nextDay=1;
            let nextMonth=this.currentMonth+1;
            let nextYear=this.currentYear;
            if(nextMonth>12){
                nextMonth=1;
                nextYear++;
            }
            for(let i=0;i<6;i++){
                let arr=[];
                for(let week=0;week<7;week++){
                    let json={}
                    if(i==0 && weekNum>week+1){
                        json={year:lastYear,month:lastMonth,day:lastDay+week};
                    }else if(day>dayMax){
                        json={year:nextYear,month:nextMonth,day:nextDay++};
                    }else{
                        json={year:this.currentYear,month:this.currentMonth,day:day++};
                    }
                    json.lunar=this.getLunar(json.year,json.month,json.day);
                    this.setDataHoliday(json);

                    if(this.rangeMinDate){//小于最小时间范围 禁用
                        if(this.rangeMinDate.getFullYear()>json.year){
                            json.disabled=true;
                        }else if(this.rangeMinDate.getFullYear()==json.year && this.rangeMinDate.getMonth()+1>json.month){
                            json.disabled=true;
                        }else if(this.rangeMinDate.getFullYear()==json.year && this.rangeMinDate.getMonth()+1==json.month && this.rangeMinDate.getDate()>json.day){
                            json.disabled=true;
                        }
                    }
                    arr.push(json);
                }
                data.push(arr);
            }
            return data;
        }
    },
    mounted(){
        document.addEventListener("click",this.hide)
    },
    methods:{
        downStop(e){
            if(this.isShow){
                e.preventDefault();
            }
        },
        show(){
            this.isShow=true;
        },
        hide(e){
            if(e){
                if(!this.$el.contains(e.target)){
                    this.isShow=false;
                }
            }else{
                this.isShow=false;
            }
        },
        //cell 设置类名
        setClass(cell){
            let className=[];
            if(cell.year==this.toDay.year && cell.month==this.toDay.month && cell.day==this.toDay.day){
                className.push("today");
            }
            if(cell.year==this.currentYear && cell.month==this.currentMonth){
                className.push("available");
            }else if(cell.year<=this.currentYear && cell.month<this.currentMonth){
                className.push("lastMonth");
            }else if(cell.year>=this.currentYear && cell.month>this.currentMonth){
                className.push("nextMonth");
            }

            if(this.current){
                if(cell.year==this.current.getFullYear() && cell.month==this.current.getMonth()+1 && cell.day==this.current.getDate()){
                    className.push("current");
                }
            }
            if(cell.disabled){
                className.push("disabled");
            }
            return className;
        },
        //点击日期
        dateClick(cell){
            if(cell.disabled){
                return;
            }
            this.current=new Date(cell.year+'-'+cell.month+"-"+cell.day);
        },
        getLunar(year,month,day){
            let lunar=getLunar(year,month,day);
            let date=lunar.lunarDate;
            if (date <= 10) {
                lunar.lunarDay=`初${this.nubmerStr[date - 1]}`;
            } else if (date < 20) {
                lunar.lunarDay=`十${this.nubmerStr[date - 11]}`;
            } else if (date == 20) {
                lunar.lunarDay=`廿十`;
            } else if (date > 20) {
                lunar.lunarDay=`廿${this.nubmerStr[date - 21]}`;
            } else {
                lunar.lunarDay=`三十`;
            }
 
            if(lunar.lunarYear){
                let heavenlyStem=heavenlyStemStr.indexOf(lunar.lunarYear[0]);
                if((year-(1900-6))%10 != heavenlyStem){
                    lunar.year= year-1;
                }else{
                    lunar.year= year;
                }
            }
            return lunar;
        },
        //计算节假日
        setDataHoliday(date){

            //农历节日
            let lunarHoliday=this.lunarHoliday[date.lunar.lunarMonth];
            if(lunarHoliday && lunarHoliday[date.lunar.lunarDate]){
                date.holiday=lunarHoliday[date.lunar.lunarDate].text;
            }
            //公立节日
            let gLiHoliday=this.gLiHoliday[date.month];
            if(gLiHoliday && gLiHoliday[date.day]){
                date.holiday=gLiHoliday[date.day].text;
            }
            
        },
        /**上月 */
        lastClick(){
            if(this.currentMonth<=1){
                this.currentMonth=12;
                this.currentYear--;
            }else{
                this.currentMonth--;
            }
        },
        /**下月 */
        nextClick(){
            if(this.currentMonth>=12){
                this.currentMonth=1;
                this.currentYear++;
            }else{
                this.currentMonth++;
            }
        },
        /**确认 */
        confirm(){
            if(this.current){
                this.dateVal=this.current;                
                this.hide();
            }
            
        }
    },
    //被卸载时
    unmounted(){
        document.removeEventListener("click",this.hide);
    }
}
</script>

<style lang="scss">
.lunarCalendar{
    display:inline-block;
    --height:28px;
    color: #000000;
    height: var(--height);
    font-size: 12px;
    font-weight: 500;
    box-shadow: inset 0px 1px 2px 1px rgba(0,0,0,0.3);
    border-radius: 2px 2px 2px 2px;
    border: 1px solid rgba(0,0,0,0.4);
    padding: 0 7px;
    padding-right: 35px;
    position: relative;

    .calendar__input{
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    .calendar__prefix{
        position: absolute;
        top: 0;
        right: 7px;
        height: 100%;

        .iconfont{
            font-size: 18px;
            line-height: var(--height);
        }
    }
}

.lunarCalendar__popper{
    position: absolute;
    border-radius: 4px;
    padding: 0;
    z-index: 2000;
    font-size: 12px;
    display: none;
    background-color: #fff;
    --hornLeft:7px;
    --hornTop:-7px;
    width: 460px;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);

    &::before{
        position: absolute;
        content: "";
        display: inline-block;
        top: var(--hornTop);
        left: var(--hornLeft);
        width: 24px;
        height: 26px;
        background: #FFFFFF;
        box-shadow: 2px 4px 10px 0px rgba(0,0,0,0.15);
        border-radius: 5px 5px 5px 5px;
        transform: rotate(45deg);
        z-index: -1;
    }

    .lunarCalendar__header{
        display: flex;
        padding: 10px 95px;
        border-radius: 4px;
        background-color: #fff;
        position: relative;
        z-index: 1;

        .last-month,.next-month{
            height: 24px;
            width: 60px;
            padding-left: 6px;

            &::before{
                content: "";
                display: inline-block;
                border: 12px solid #8590A6;
                border-color: transparent #8590A6 transparent transparent;
                border-width: 12px 16px;
            }
        }

        .next-month{
            padding-left: 22px;
            &::before{
                border-color: transparent transparent transparent #8590A6 ;
            }
        }

        .month-text{
            flex: 1;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            color: #000000;
        }
    }

    .lunarCalendar-panel__content{
        
        table{
            width: 100%;
            border: none;
            padding:0;
            margin: 0;
            td{
                border: none;
                text-align: center;
            }

            .week__row{
                font-size: 11px;
                color: #000000;
                background: #DFE8F6;
                height: 24px;

                td{

                }
            }

            .calendar-table__row{
                td{
                    padding: 7px;
                    .cell-box{
                        width: 45px;
                        height: 45px;
                        margin: 0 auto;
                        border-radius: 6px;
                        border: 1px solid transparent;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        cursor: pointer;

                        .day{
                            font-size: 16px;
                            line-height: 20px;
                        }

                        .lunarDay{
                            font-size: 9px;
                            line-height: 11px;
                        }
                    }

                    &.lastMonth,&.nextMonth{
                        color: #999999;
                    }

                    &.available{
                        &:hover{
                            color: #367BC5;
                        }
                    }

                    &.today{
                        .cell-box{
                            border: 1px solid #367BC5;

                            &::before{
                                position: absolute;
                                right: -6px;
                                top:-6px;
                                display: block;
                                content: "今";
                                width: 18px;
                                height: 18px;
                                line-height: 18px;
                                background: #367BC5;
                                font-weight: 500;
                                font-size: 12px;
                                color: #FFFFFF;
                                text-align: center;
                                border-radius: 18px;
                            }
                        }
                    }

                    &.current{
                        .cell-box{
                            background-color: #367BC5;
                            color: #fff;
                        }
                    }

                    &.disabled{
                        .cell-box {
                            background-color: #F5F7FA;
                            opacity: 1;
                            cursor: not-allowed;
                            color: #C0C4CC;
                        }
                    }
                }
            }
        }
    }

    .lunarCalendar-foot__bnt{
        display: flex;
        padding-top:10px ;
        padding-bottom: 22px;
        background-color: #fff;

        .bnt{
            margin:0 41px;
            flex: 1;
            height: 32px;
            background: #DEE0E3;
            border-radius: 4px;
            flex: 1;
            font-size: 14px;
            color: #487BB2;
            line-height: 32px;
            text-align: center;
            cursor: pointer;

            &.confirm-bnt{
                background: #487BB2;
                 color: #FFFFFF;
            }
        }
    }
}
</style>