<template>
    <modal class="promptDeskName" :isShow="isShow" @keyup.esc="hide">
      <div class="header">提示</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="title">您确定将【{{choodesk.Desk_Name}}】拆分虚拟台吗</div>
                <div class="custmize">自定义桌号名称:<input class="cusinput" type="text" ref="input" placeholder="不填则按系统自动分配" v-model="tempDeskName" @keyup.enter="confirm()"/></div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="confirm()">确认</button>
            <button class="btn btn-confirm" @click="hide()">取消</button>
        </div>
  </modal>
</template>
<script>

import {initKeyBoard} from '/src/common'
import { ElMessage } from "element-plus"

export default {
    name:'promptDeskName',
    props:{
        isShow:Boolean,
        choodesk:Object
    },
    data(){
        return{
            /**虚拟桌台名称*/
            tempDeskName:''
        }
    },
    watch: {
        isShow() { 
            if(this.isShow==true){
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.input);
            this.EmptyDatas()
        })
    },
    methods:{
        EmptyDatas(){
            this.tempDeskName=''
            setTimeout(() => {
                this.$refs.input?.focus();
            },100);
        },
        /**确认 */
        confirm(){
            this.$emit("prompDesk",this.tempDeskName)
        },
        /**取消 */
        hide(){
            this.$emit("closeModel")
        }
    }
}
</script>

<style lang="sass">
@import './promptDeskName.scss';
</style>