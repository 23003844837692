<template>
    <modal class="KitPrintMonitorModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">提示 </div>
        <div class="modal-body">
            <div class="name">待打印任务<i class="num">{{waitPrintCount}}</i>条</div>
            <div class="content">
                <div class="lable-text"><i class="iconfont icon-a-dayinmoban1"></i></div>
                <div class="tip-text">自动获取打印任务处理中<span class="dian1">.</span><span class="dian2">.</span><span class="dian3">.</span></div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>
// 打印监控服务
export default {
    name:'KitPrintMonitorModel',
    props:{
        isShow:{
            typeof:Boolean,
            default:false
        },
        /**打印条数 */
        waitPrintCount:{
            typeof:Int16Array,
            default:0
        }
    },
    data(){
        return{
        }
    },
    mounted(){
    },
    watch:{
    },
    methods:{
        hide(){
            this.$emit("closeModel");
        }
    }
}
</script>

<style lang="scss">
@import './KitPrintMonitorModel.scss'
</style>