function VTouch(app){
    let Distance = 20;
    app.directive('touch',{
      beforeMount:(el,binding)=>{
        let onTouch = false;
        let touches = 0,lastMoveTime;
        el.addEventListener('touchstart',(e)=>{
          touches = e.touches;
          onTouch = true;
          lastMoveTime = e.timeStamp || Date.now();
          el.addEventListener('touchmove', onTouchMove);
        })
        el.addEventListener('touchend', () => {
          touches = 0;
          el.removeEventListener('touchmove', onTouchMove);
        })

        el.addEventListener('mousedown',(e)=>{
           touches = [e];
           onTouch = true;
           lastMoveTime = e.timeStamp || Date.now();
           el.addEventListener('mousemove', onTouchMove);
         })
         el._touchMousedown= () => {
           if(onTouch){
            onTouch = false;
            touches = 0;
            el.removeEventListener('mousemove', onTouchMove);
           }
         };
         
         document.addEventListener('mouseup',el._touchMousedown);
        function onTouchMove(e){
          if (onTouch){
            if(e.type=="mousemove"){e.touches=[e];}
            let mTouches = e.touches;
            let pageX=touches[0].pageX - mTouches[0].pageX,pageY=touches[0].pageY - mTouches[0].pageY;
           
            if (pageX >= Distance) {
              if(typeof(binding.value)=="function"){
                binding.value(el,'left');
              }
              
              onTouch = false;
            } else if (pageX<= -Distance) {
              if(typeof(binding.value)=="function"){
                binding.value(el,'right');
              }
              onTouch = false;
            }
            if (pageY >= Distance) {
              if(typeof(binding.value)=="function"){
                binding.value(el,'up');
              }
              onTouch = false;
            } else if (pageY <= -Distance) {
              if(typeof(binding.value)=="function"){
                binding.value(el,'down');
              }
              onTouch = false;
            }

            let nowTime = e.timeStamp || Date.now();
            if(nowTime - lastMoveTime > 16) {
                lastMoveTime = nowTime;
                touches = mTouches;
            }
          }
          
        }
      },
      unmounted(el) {
         if(el._touchMousedown) document.removeEventListener('mouseup',el._touchMousedown);
      }
    })
  }



export default {
    install(app){
        VTouch(app);
    }
}