<template>
    <div class="tableVir el-table" :class="{'scrollable-y':isScrollY,'is-scrolling-left':scrollingLeft,'is-scrolling-right':scrollingRight}">
        <div class="hidden-columns">
            <slot></slot>
        </div>
        <div class="fixed-left-shadow" v-if="fiexdLeft>0" :style="{width:fiexdLeft+'px'}"></div>
        <div class="fixed-right-shadow" v-if="fiexdRight>0" :style="{width:(fiexdRight+(isScrollY?6:0))+'px'}"></div> 
        <div class="tableVir__header-wrapper" ref="headerWrapper">
            <table class="tableVir__header" cellspacing="0" cellpadding="0" border="0" :style="{width: bodyWidth+'px'}">
                <colgroup ref="colgroup">
                    <col v-for="column in columns" :key="column" :name="column.id" :width="column.realWidth"/>
                </colgroup>
                <thead class="table-fixed-thead table-thead">
                    <tr v-for="row in columnRows" :key="row">
                        <th v-for="column in row" :key="column" :rowspan="column.rowspan" :colspan="column.colspan" 
                            :class="getClassThead(column)"
                            :style="getStyle(column)"
                            @mousedown="(e)=>moveCol.handleMouseDown(e,column)"
                            @mousemove="(e)=>moveCol.handleMouseMove(e,column)"
                            @mouseout="(e)=>moveCol.handleMouseOut(e,column)"
                            @click="(e)=>handleTheadClick(e,column)"
                        >
                            <cell :renderCell="()=>column.renderHeader({$index:column.index,column:column,store})" ></cell>
                        </th>
                    </tr>
                </thead>
            </table>
            <div></div>
        </div>
        <table-vir-body 
            ref="tableVirBody"
            :bodyWidth="bodyWidth"
            :columns="columns" 
            :data="data"
            :isFoot="showSummary"
            :summaryMethod="summaryMethod"
            :spanMethod="spanMethod"
            :row-class-name="rowClassName"
            :cell-class-name="cellClassName"
            :highlightCurrentRow="highlightCurrentRow"
        ></table-vir-body>
        <div class="tableVir__footer-wrapper" v-if="showSummary" ref="footerWrapper" v-show="data.length>0">
            <table class="tableVir__footer" cellspacing="0" cellpadding="0" border="0" :style="{width: bodyWidth+'px'}">
                <colgroup>
                    <col v-for="column in columns" :key="column" :name="column.id" :width="column.realWidth" />
                </colgroup>
                <tbody>
                    <tr>
                        <td v-for="column in columns" :key="column" 
                            :class="getClass(column)"
                            :style="getStyle(column)"
                        >
                            <div class="cell">
                                {{footData[column.index]}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table__column-resize-proxy" v-show="moveCol.isMove" :style="{left:moveCol.left+'px'}"></div>
    </div>
</template>

<script>
import {render} from 'vue'
import tableVirBody from './tableVirBody.vue'
import cell from './cell.vue'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

let tableId=1;
const resizeEvent={
    addResizeListener(el,fn){
        if(!el.__ro__){
            el.__ro__= new ResizeObserver(fn);
            el.__ro__.observe(el)
        }
    },
    removeResizeListener(el){
        if(el.__ro__){
            el.__ro__.unobserve(el);
        }
    }
}
export default {
    name:"tableVir",
    components:{tableVirBody,cell},
    emits:['selection-change','sort-change','cell-dblclick','cell-click','current-change'],
    props:{
        showSummary:{//是否显示合计
            type:Boolean,
            default:false
        },
        summaryMethod:{//合计方法
            type:Function,
            default:null
        },
        data:{
            type:Array,
            default:null
        },
        spanMethod:{//合并行列方法
            type:Function,
            default:null
        },
        rowClassName:{//设置tr类名
            type:Function,
            default:null
        },
        cellClassName:{//设置td类名
            type:Function,
            default:null
        },
        highlightCurrentRow:{//高亮
            type:Boolean,
            default:false
        }
    },
    computed:{
        /**列 */
        columns(){
            let columns=[];
            if(this.columnRows?.length>0){
                this.columnRows[0].forEach((col)=>{
                    if(!col.children){
                        columns.push(col);
                    }else{
                        let getColumn=(children)=>{
                            children.forEach(it=>{
                                it.isFixed=col.isFixed;
                                if(it.children){
                                    getColumn(it.children);
                                }else{
                                    columns.push(it);
                                }
                            })
                        };
                        getColumn(col.children);
                    }
                })
            }

            columns.forEach((it,index)=>{
                it.index=index;
            })
            return columns;
        },
        columnsProp(){
            let props=[];
            this.columns?.forEach(it=>{
                props.pop(it.property);
            })
            return props;
        },
        columnRows(){
            let columnRows=this.tableColumns;
            let rowLeng=columnRows.length;//行数
            columnRows.forEach((rows,index)=>{
                rows.forEach(col=>{
                    if(!col.children){
                        col.rowspan=rowLeng-index;//更新行数
                    }else{
                        col.rowspan=1;
                    }
                })
            })
            return columnRows;
        },
        bodyWidth(){
            let width=0;
            this.columns.forEach((it)=>{
                width+=it.realWidth;
            })
            return width;
        },
        /**列 宽 */
        colWidth(){
            let width=0;
            this.columns.forEach((it)=>{
                if(it.minWidth>0 || it.width>0){
                    if(it.minWidth>it.width){
                        width+=it.minWidth;
                    }else{
                        width+=it.width;
                    }
                }else{
                    width+=80;
                }
            })
            return width;
        },
        spanData(){
            let spanData=[];
            this.data?.forEach((it,index)=>{
                spanData[index]=[];
                this.columns?.forEach((col,columnIndex)=>{
                    let span=[1,1];
                    if(typeof(this.spanMethod)=="function"){
                        span= this.spanMethod({ row:it, column:col, rowIndex:index, columnIndex});
                        if(!(span instanceof Array) || span?.length!=2){
                            span=[1,1];
                        }
                    }
                    spanData[index].push({
                        rowspan:span[0],
                        colspan:span[1],
                    })
                })
            })
            return spanData;
        }
    },
    watch:{
        columns(){
            this.columns.forEach((it)=>{
                if(it.minWidth>0 || it.width>0){
                    if(it.minWidth>it.width){
                        it.realWidth=it.minWidth;
                    }else{
                        it.realWidth=it.width;
                    }
                }else{
                    it.realWidth=80;
                }
            })
            this.$nextTick(()=>{
                this.updateColW();
            })
        },
        /**现实列变化了 */
        columnsProp(){
            this.$nextTick(()=>{
                this.setFootData();
            })
        },
        data(){
            this.$nextTick(()=>{
                this.setFootData();
                this.store.states.data=this.data;
                this.store.states.selection=[];
                this.store.states.isAllSelected=false;
            })
        },
        bodyWidth(){
            this.$nextTick(()=>{
                this.scrollingLeft=this.scrollLeft==0;
                let scrollWidth=this.$refs.headerWrapper.scrollWidth;
                let clientWidth=this.$refs.headerWrapper.clientWidth;
                this.scrollingRight=scrollWidth-this.scrollLeft<=clientWidth;
            })
        },
        scrollLeft(){
            if(this.$refs.headerWrapper){
                this.$refs.headerWrapper.scrollLeft=this.scrollLeft;
            }

            if(this.$refs.footerWrapper){
                this.$refs.footerWrapper.scrollLeft=this.scrollLeft;
            }
            this.scrollingLeft=this.scrollLeft==0;
            let scrollWidth=this.$refs.headerWrapper.scrollWidth;
            let clientWidth=this.$refs.headerWrapper.clientWidth;
            this.scrollingRight=scrollWidth-this.scrollLeft<=clientWidth;
        },
        visible(){
            this.updateColW();
        },
        isScrollY(){
            this.$nextTick(()=>{
                this.updateColW();
            })
        },
        "store.states.selection.length"(){
            this.$emit('selection-change',this.store.states.selection);
        }
    },
    data(){
        return {
            tableId:"table-vir_"+(tableId++),
            tableColumns:[],
            footData:[],
            scrollLeft:0,
            /**是否显示了 */
            visible:false,
            fiexdLeft:0,
            fiexdRight:0,
            isScrollY:false,
            scrollingLeft:false,
            scrollingRight:false,
            clientWidth:0,
            store:{},
            /**修改列宽 */
            moveCol:{
                isMove:false
            },
            /**排序 */
            orderBy:{
                order: "",//排序规则 
                prop: ""//排序 字段
            }
        }
    },
    mounted(){
        this.store.states={
            selection:[],
            data:this.data,
            isAllSelected:false
        }

        this.store.isSelected=(row)=>{
            let states=this.store.states;
            return states.selection.some(it=>row==it);
        }

        this.store.toggleAllSelection=(isBo)=>{
            let states=this.store.states;
            if(states.selection.length>0){
                states.selection.splice(0,states.selection.length);
            }
            if(isBo){
                states.data.forEach((it)=>{
                   states.selection.push(it);
                })
            }
            states.isAllSelected=isBo;
        }

        this.store.commit=(name,row)=>{
            if("rowSelectedChanged"==name){
                let states=this.store.states;
                let index= states.selection.findIndex(it=>it==row);
                if(index>=0){
                    states.selection.splice(index,1);
                }else{
                    states.selection.push(row);
                }
                states.isAllSelected=states.selection.length==states.data.length;
            }
        }

        let body=document.querySelector("body")

        //修改列宽
        this.moveCol={
            handleMouseDown:(e,column)=>{//移动点击
                if(!column.children && e.currentTarget.clientWidth-e.offsetX<10){
                    let target=e.currentTarget;
                    let moveCol=this.moveCol;
                    moveCol.isMove=true;
                    let tableLeft=this.$el.getBoundingClientRect().left;
                    let thRect=target.getBoundingClientRect();
                    let startLeft=thRect.left+thRect.width-tableLeft;
                    moveCol.left=startLeft;
                    target._mouseup=(event)=>{
                        moveCol.isMove=false;
                        let width=thRect.width+(event.x-e.x);
                        if(width<30){
                            width=30;
                        }
                        if(thRect.width!=width){
                            column.width=width;
                            this.updateColW();//更新列宽
                            this.$refs.tableVirBody?.doLayout();
                        }
                        window.removeEventListener("mouseup",target._mouseup);
                        window.removeEventListener("mousemove",target._mousemove);
                    }
                    target._mousemove=(event)=>{
                        let width=thRect.width+(event.x-e.x);
                        if(width<30){
                            width=thRect.left+30;
                        }else{
                            moveCol.left=startLeft+(event.x-e.x);
                        }
                    }
                    window.addEventListener("mouseup",target._mouseup)
                    window.addEventListener("mousemove",target._mousemove)
                }
            },
            handleMouseMove(e,column){//移动
                if(!column.children){
                    if(e.currentTarget.clientWidth-e.offsetX<10){
                        body.style.cursor='col-resize';
                    }else{
                        body.style.cursor='';
                    }
                }
            },
            handleMouseOut(e,column){//移出
                if(!column.children){
                    body.style.cursor='';
                }
            },
        }

        resizeEvent.addResizeListener(this.$el, this.doLayout);
    },
    errorCaptured(e){
        console.log(e)
    },
    methods:{
        doLayout(){
            this.$nextTick(()=>{
                this.visible=this.isVisible(this.$el);
                if(this.$refs.tableVirBody){
                    this.$refs.tableVirBody.visible=this.visible;
                    if(this.visible){
                        this.isScrollY=this.$refs.tableVirBody.$el.clientHeight<this.$refs.tableVirBody.bodyHeight;
                    }
                }
                if(this.visible){
                    this.$nextTick(()=>{
                        this.scrollingLeft=this.scrollLeft==0;
                        let scrollWidth=this.$refs.headerWrapper.scrollWidth;
                        let clientWidth=this.$refs.headerWrapper.clientWidth;
                        this.scrollingRight=scrollWidth-this.scrollLeft<=clientWidth;
                        this.$refs.tableVirBody?.doLayout();

                        if(this.colWidth<this.$el.clientWidth || this.colWidth<this.bodyWidth){
                            this.updateColW();
                        }
                    })
                }
            })
        },
        isVisible(elem){
            return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
        },
        setFootData(){
            if(this.showSummary!==false && typeof(this.summaryMethod)=="function"){
                let data=this.summaryMethod({ columns:this.columns, data:this.bodyData });
                if(Array.isArray(data)){
                    this.footData=data;
                }else{
                    this.footData=[];
                }
            }
        },
        /**更新 列宽 */
        updateColW(){
            if(this.visible){
                let clientWidth=this.$refs.headerWrapper.clientWidth;
                let totalWidth=0,autoCol=[];
                this.columns.forEach(it=>{
                    if(it.minWidth>0 || it.width>0){
                        if(it.minWidth>it.width){
                            it.realWidth=it.minWidth;
                        }else{
                            it.realWidth=it.width;
                        }
                    }else{
                        it.realWidth=80;
                    }
                    totalWidth+=it.realWidth;
                    if(!it.width){
                        autoCol.push(it);
                    }
                })
                let diffW=clientWidth-totalWidth;
                if(diffW>0){
                    let perW=Math.ceil(diffW/autoCol.length);
                    autoCol.forEach(it=>{
                        if(diffW-perW>=0){
                            it.realWidth+=perW;
                            diffW=diffW-perW
                        }else if(diffW>0){
                            it.realWidth+=diffW;
                            diffW=0
                        }
                    })
                }
                this.updateFixed();
            }
        },
        /**更新冻结列 */
        updateFixed(){
            //清除冻结列标识数据
            if(this.columnRows?.length>0){
                let createFixed=(col)=>{
                    if(col.fixed){
                        delete col.fixed;
                        delete col.fixedLeft;
                        delete col.fixedRight;
                        delete col.isFixedShadow;
                    }
                    if(col.children?.length>0){
                        col.children.forEach(it=>{
                            createFixed(it);
                        })
                    }
                }
                this.columnRows[0].forEach(col=>{
                    createFixed(col);
                })
            }


            let left=0;
            for(let i=0;i<this.columns.length;i++){
                if(!this.columns[i].isFixed){
                    if(i>0){
                        this.columns[i-1].isFixedShadow=true;
                    }
                    break;
                }
                this.columns[i].fixed="left";
                this.columns[i].fixedLeft=left;
                left+=this.columns[i].realWidth;
            }
            this.fiexdLeft=left;

            let right=0;
            for(let i=this.columns.length-1;i>=0;i--){
                if(!this.columns[i].isFixed){
                    if(i<this.columns.length-1){
                        this.columns[i+1].isFixedShadow=true;
                    }
                    break;
                }
                this.columns[i].fixed="right";
                this.columns[i].fixedRight=right;
                right+=this.columns[i].realWidth;
            }
            this.fiexdRight=right;


            if(this.columnRows?.length>0){
                this.columnRows[0].filter(it=>it.isFixed).forEach(col=>{
                    if(col.children?.length>0){
                        this.setChildrenFixed(col);
                    }
                   
                })
            }
        },
        /**更新子节点列冻结 */
        setChildrenFixed(col){
            col.children.forEach((it,index)=>{
                if(it.children?.length>0){
                    this.setChildrenFixed(it);
                }else{
                    if(it.fixed=="left" && index==0){//
                        col.fixed="left";
                        col.fixedLeft=it.fixedLeft;
                    }else if(it.fixed=="right" && index==col.children.length-1){//
                        col.fixed="right";
                        col.fixedRight=it.fixedRight;
                    }
                    if(it.isFixedShadow){
                        col.isFixedShadow=true;
                    }
                }
            })
        },
        /**返回类名 */
        getClass(column){
            let className=[column.id,column.className];
            if(column.align=="left"){
                className.push('is-left');
            }else if(column.align=="right"){
                className.push('is-right');
            }
            if(column.isFixed){
                className.push('is-fixed-'+column.fixed);
            }
            if(!column.children?.length>0){
                className.push('is-leaf')
            }
            return className;
        },
        /**返回头部类名 */
        getClassThead(column){
            let className=this.getClass(column);
            if(column.sortable){//排序
                className.push('is-sortable');
                if(this.orderBy.prop==column.property){
                    if(this.orderBy.order=="ascending"){//升序
                        className.push('ascending');
                    }
                    else if(this.orderBy.order=="descending"){//倒叙
                        className.push('descending');
                    }
                }
            }
            return className;
        },
        getStyle(column){
            let json={display:(column.rowspan>0 && column.colspan>0)?'':'none'}
            if(column.fixed=='left'){
                json.left= column.fixedLeft+'px';
            }else if(column.fixed=='right'){
                json.right= column.fixedRight+'px';
            }
            return json;
        },
        /**点击头部 th */
        handleTheadClick(e,column){
            if(column.sortable){
                if(this.orderBy.prop==column.property){
                    if(this.orderBy.order=="ascending"){//升序
                        this.orderBy.order="descending"//倒叙
                    }else if(this.orderBy.order=="descending"){//倒叙
                        this.orderBy.order="";
                    }else{
                        this.orderBy.order="ascending";
                    }
                }else{
                    this.orderBy.order="ascending";
                    this.orderBy.prop=column.property;
                }
                this.$emit('sort-change',this.orderBy);//排序
            }
        },
        /**清除勾选 */
        clearSelection(){
            this.store.toggleAllSelection(false);
        },
        /**设置 勾选状态 */
        toggleRowSelection(row,isBo){
            let states=this.store.states;
            let index= states.selection.findIndex(it=>it==row);
            if(isBo){
                if(index<0){
                    states.selection.push(row);
                }
            }else{
                if(index>=0){
                    states.selection.splice(index,1);
                }
            }
            states.isAllSelected=states.selection.length==states.data.length;
        },
        /**返回export格式数据 */
        getExportData(headArr){
            let exData=[];
            try {

                if(this.columnRows?.length>0){
                    //表头
                    let hData=[];
                    let _hDom=document.createElement("div");//虚拟dom
                    this.columnRows.forEach((tr,i)=>{
                        hData[i]=[]
                        tr.filter(it=>it.type!="selection").forEach((th,index)=>{
                            let value=th.label;
                            if(th.renderHeader){
                                render(th.renderHeader({$index:th.index,column:th,store:this.store}),_hDom);
                                value=_hDom.textContent;
                                render(null,_hDom);
                            }
                            hData[i][index]={value:value,col:th.colspan,row:th.rowspan}
                        })
                    })
                    //内容
                    let bodyData=[];
                    let _dom=document.createElement("div");//虚拟dom
                    let columns=this.columns.filter(it=>it.type!="selection")
                    if(this.data?.length>0){
                        this.data.forEach((it,rowIndex)=>{
                            let tds=[];
                            columns.forEach((td,columnIndex)=>{
                                let json={ value:"",  col:1, row:1, t:"s" };

                                if(td.format){
                                    //t  b布尔值，n数字，e错误，s字符串，d日期，z存根
                                    json.t=({"number":'n',"text":"s","boolean":"b","date":"d"})[td.format]||"s";

                                    if(td.formatz){
                                        json.z=td.formatz;
                                    }
                                }
                                
                                //是否不取渲染中的数据  isRender=false;
                                if(td.isRender || !td.property){//使用渲染中的数据
                                    render(td.renderCell({row:it,column:td,$index:rowIndex,isExcel:true}),_dom);
                                    json.value=_dom.textContent;
                                    render(null,_dom);
                                }else{//使用原数据值
                                    let value=it[td.property]||"";//默认赋值
                                    if(typeof(td.formatter)=="function"){//格式化
                                        value=td.formatter(it,td,it[td.property]);
                                    }
                                    json.value=value;
                                }

                                

                                if(json.t=="s"){
                                    json.value=(json.value||"").toString();
                                }
                                //合并单元格
                                if(typeof(this.spanMethod)=="function"){
                                    let span=this.spanMethod({row:it, column:td, rowIndex:rowIndex, columnIndex:columnIndex});
                                    if(span?.length==2){
                                        json.row=span[0];
                                        json.col=span[1];
                                    }
                                }
                                if(json.row>0 || json.col>0){
                                    tds.push(json);
                                }
                                
                            });
                            bodyData.push(tds);
                        })
                    }

                    //底部合计
                    let foodData=[]; 
                    if(typeof(this.summaryMethod)=="function"){
                        let arr=this.summaryMethod({ columns:columns, data:this.data});
                        foodData.push(arr.map((val)=>{
                            return { 
                                value:val,  
                                col:1, 
                                row:1, 
                                t:typeof(val)=="number"?"n":"s" 
                            };
                        }))
                    }
                    exData=exData.concat(hData,bodyData,foodData);
                }

           
                let cellist=[];
                cellist=cellist.concat(headArr||[],exData||[]);
                return cellist;
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e)
            }
            return [];
        },
        /**导出 */
        export(titleName,headArr){
            try {
                let cellist=this.getExportData(headArr);
                let ws=this.$excelCommon.addSheetCell(cellist);
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb,ws,titleName);
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), titleName+'.xlsx')
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e)
            }
        }
    },
    unmounted(){
        resizeEvent.removeResizeListener(this.$el);
    }
}
</script>

<style lang="scss">
.tableVir{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    --borderColor:#DCDCDC;
    border: 1px solid var(--borderColor);
    position: relative;

    &::after,&::before{
        content: "";
        position: absolute;
        background-color: #EBEEF5;
        z-index: 1;
    }

    &.is-scrolling-right{
        .fixed-right-shadow{
            box-shadow:none;
        }

        .tableVirBody{
            .fixed-right{
                box-shadow:none;
            }
        }
        
    }

    &.is-scrolling-left{
        .fixed-left-shadow{
            box-shadow:none;
        }
        .tableVirBody{
            .fixed-left{
                box-shadow:none;
            }
        }
        
    }

    .table__column-resize-proxy{
        position: absolute;
        left: 200px;
        top: 0;
        bottom: 0;
        width: 0;
        border-left: 1px solid #EBEEF5;
        z-index: 10;
    }

    .fixed-left-shadow,.fixed-right-shadow{
        position:absolute;
        top:0;
        height: 100%;
        background-color: #fff;
        -webkit-box-shadow:1px 0 10px rgba(0,0,0,0.21);
        box-shadow: 1px 0 10px rgba(0,0,0,0.21);
        z-index: 1;
    }
    .fixed-left-shadow{
        left: 0;
    }
    .fixed-right-shadow{
        right: 0;
        -webkit-box-shadow: 0 0 0 1px var(--borderColor),-1px 0 10px rgba(0,0,0,0.21);
        box-shadow: 0 0 0 1px var(--borderColor),-1px 0 10px rgba(0,0,0,0.21);
    }

    table{
        table-layout: fixed;
        border-collapse: separate;
    }

    .table-thead{
        // tr{
        //     &:nth-child(1){
        //         th{
        //             border-bottom:none;
        //         }
        //     }

        //     &:nth-child(2){
        //         th{
        //             border-top:1px solid var(--borderColor);
        //         }
        //     }
            
        // }
    }

    .is-fixed-left,.is-fixed-right{
        position:sticky;
        z-index: 9;
    }

    tr{
        th,td{
            background-color: #fff;
            border-right: 1px solid var(--borderColor);
            border-bottom: 1px solid var(--borderColor);
            text-align: center;
            
            &.is-left{
                text-align: left;
            }

            &.is-right{
                text-align: right;
            }
        }

        th{
            &.is-sortable{
                cursor: pointer;
            }
        }
    }


    .hidden-columns{
        display: none;
    }

    &.scrollable-y{
        .tableVir__header-wrapper,.tableVir__footer-wrapper{
           margin-right: 6px;
        }
    }

    .tableVir__header-wrapper,.tableVir__footer-wrapper{
        overflow: hidden;
    }

    .tableVir__header,.tableVir__footer{
        min-width: 100%;
    }

    .tableVir__footer{
        border-top: 1px solid var(--borderColor);
    }

    .tableVirBody{
        flex: 1;
        overflow: hidden;
        background-color: #fff;
        z-index: 1;
    }
    
}
</style>