<template>
  <div class="tab-pane" v-show="show" :paneIndex="index">
    <slot :isShow="show" ></slot>
  </div>
</template>

<script>
export default {
    name: "tabPane",
    data(){
        return {
          index:0,
        }
    },
    computed: {
      show(){
        if(this.$parent.currentName === this.index){
          return true;
        }
        return false;
      }
    },
    mounted() {
      //像父级添加当前对象的记录
      if(this.$parent && this.$parent.addPane)
      this.$parent.addPane(this);
      this.$nextTick(()=>{
      })
    },
    beforeUnmount() {
        if(this.$el && this.$el.parentNode){
            this.$el.parentNode.removeChild(this.$el);
        }
      // 当前组件销毁时清除父级 对当前对象的记录
      if(this.$parent && this.$parent.removePane)
      this.$parent.removePane(this);
    }
}
</script>

<style>

</style>