<template>
    <div>
        <modal class="OrderTrackModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">订单操作轨迹<i class="close iconfont icon-a-guanbi11" @click="hide()"></i></div>
        <div class="modal-body">
        <div class="track" v-for="(item,index) in tablelist" :key="index" >
            <div class="date">
                <div class="top">{{formatter(item.Operate_Time)}}</div>
                <div class="bottom">{{shortDateFormat(item.Operate_Time)}}</div>
            </div>
            <div class="line">
                <div class="index">{{index+1}}</div>
            </div>
            <div class="content-box">
                <div class="title">{{item.Operate_KindName}}</div>
                <div class="czy">操作人：<span class="name">{{item.Operator_Name}}</span>
                    <div class="details" v-if="item.Is_DoHaveDetail==1" @click="ShowDesc(item)">查看详情<svg class="iconfont icon-svg" aria-hidden="true"><use xlink:href="#icon-xiangqing"></use></svg>
                </div>
                </div>
                <div class="remake" v-if="item.Operate_Desc.trim()">
                    <div class="text">{{item.Operate_Desc}}</div>
                </div>
            </div>
        </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
    <!--订单轨迹详情-->
    <modal-load :isShow="trackdetailsShow">
        <order-track-details-model :isShow="trackdetailsShow" v-on:closeModel="trackdetailsShow=false" :tracksearchinfo="tracksearchinfo"></order-track-details-model>
    </modal-load>
    </div>
</template>
  
<script>
import { newGuid } from '/src/common/index.js'
import orderTrackDetailsModel from '../orderTrackDetailsModel/orderTrackDetailsModel.vue';
import { httpAES,auth,cacheData } from '/src/services'
export default {
    components: { orderTrackDetailsModel},
    name:'OrderTrackModel',
    props:{
        isShow:Boolean,
        /**传入账单数据 */
        orderInfo:Object,
    },
    data(){
        return{
            tablelist:[
                
            ],
            trackdetailsShow:false,
            tracksearchinfo:{}
        }
    },
    watch:{
        isShow(value){
            if(value==true){//显示弹层时 初始化数据
                this.loadData();
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.userInfo = this.$auth.getUserInfo(); //获取用户id
            this.loadData();
        })
    },
    methods:{
        /**加载桌台*/
        loadData(){
            if(this.orderInfo?.Eat_AutoID.length<=0){
                this.$alert("桌台信息不能为空", "提示", { confirmButtonText: "确定" });
            }
            let param = {
                Body_ID: newGuid(),
                User_ID: this.userInfo?.User_ID,
                Eat_AutoID: this.orderInfo.Eat_AutoID,
                Rpt_Date: this.orderInfo.Rpt_Date
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.GetOrderTracks", param).then((data) => {
                if (data.ResponseHeader.ResultCode != 0) {
                    this.$message.error(data.ResponseHeader.ResultDesc);
                } else {
                    this.tablelist=data.ResponseBody;
                    console.log(this.tablelist);
                }
            }).catch((e) => {
                this.$alert(e.message, "提示", { confirmButtonText: "确定" });
            })
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel");
        },
        /*时间格式 */
        formatter(cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            var _date=new Date(cellValue);
            return _date.getFullYear()+'.'+(_date.getMonth()+1)+'.'+_date.getDate();
        },
        /*时间格式 时分秒*/
        shortDateFormat(cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('hh:mm:ss');
        },
        ShowDesc(item){
            this.tracksearchinfo.Track_Kind=item.Track_Kind;
            this.tracksearchinfo.Detail_LinkID=item.Detail_LinkID;
            this.tracksearchinfo.Rpt_Date=this.orderInfo.Rpt_Date;
            this.tracksearchinfo.Operator_Name=item.Operator_Name;
            this.tracksearchinfo.Operate_Time=item.Operate_Time;
            this.tracksearchinfo.Operate_KindName=item.Operate_KindName;
            this.tracksearchinfo.Operate_Kind=item.Operate_Kind;
            this.trackdetailsShow=true;
            // if(item.Is_DoHaveDetail==1){
            //     this.$alert(item.Operate_Desc, '详细信息', {confirmButtonText: '确定',});
            // }
        }
    }
}
</script>
<style lang="scss">
    @import "./orderTrackModel.scss"
</style>