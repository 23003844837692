<template>
    <modal class="diyFoodsModel" :isShow="isShow" @keyup.esc="hide" @keyup.stop="" >
        <div class="header">
            编辑菜品
        </div>
        <div class="modal-body clearfix">
            <div class="tr-li">
                <div class="lable-txt">名称：</div>
                <div class="from-input"><input v-board type="text" ref="foodName" v-model="customInfo.Food_Name" @keyup.enter="KeyClick(1)" @focus="$event.target.select()" :readonly="!data"/></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">单位：</div>
                <div class="from-input"><input v-board type="text" ref="foodSize" v-model="customInfo.Food_Size" @keyup.enter="KeyClick(2)" @focus="$event.target.select()" :readonly="!data"/></div>
            </div>
            <!-- <div class="tr-li" v-if="customInfo.Food_AssSize">
                <div class="lable-txt">辅助单位：</div>
                <div class="from-input"><input type="text" v-model="customInfo.Food_AssSize" readonly /></div>
            </div>
            <div class="tr-li" v-if="customInfo.Food_AssSize">
                <div class="lable-txt">辅助数量：</div>
                <div class="from-input"><input type="text" v-model="assSizeNumber" :readonly="!this.selectFood"/></div>
            </div> -->
            <div class="tr-li">
                <div class="lable-txt">价格：</div>
                <div class="from-input"><input-pattern v-board v-model="customInfo.Food_Price" ref="price" pattern="number" @keyup.enter="KeyClick(3)" :dplaces="4" :min="0" :readonly="!data"/></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">数量：</div>
                <div class="from-input"><input-pattern v-board v-model="customInfo.Eat_Number" ref="EatNumber" pattern="number" @keyup.enter="KeyClick(4)" :dplaces="2" :min="0" :readonly="!data"/></div>
            </div>
            <!-- <div class="tr-li demand">
                <div class="lable-txt">要求：</div>
                <div class="from-input"><input type="text" v-model="customInfo.Eat_MasName" readonly/></div>
                <div class="bnt" @click="selectDemand" >选择</div>
            </div> -->
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-cancel confirm" @click="confirm()" >确定</button>
        </div>

         <!-- 菜品要求操作 -->
        <modal-load :isShow="demandFullShow">
            <demand-full-model :isShow="demandFullShow" v-on:closeModel="demandFullShow=false" @confirmall="confirmall" :masKindsList="masKindsList" ></demand-full-model>
        </modal-load>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </modal>
</template>

<script>
import DemandFullModel from '../../demandFullModel/demandFullModel.vue'

/**自定义菜品 */
export default {
    components:{DemandFullModel},
    name:"customDishesModel",
    props:{
        isShow:Boolean,
        data:{
            default:null
        }
    },
    data(){
        return {
            demandFullShow:false,
            /**自定义数据信息 */
            customInfo:{
                Food_ID:'', /**菜品小类ID */
                Food_Code:'',/**菜品小类编号 */
                Food_Name:'',/**名称 */
                Food_EnglishName:'',/**菜品英文名称 */
                SubCategory_ID:'',/**菜品小类名称 */
                Food_Property:0,/**菜品属性，1：普通产品 ，2：尾数调整项目 ，3：服务费项目 ，4：最低消费补足项目 */
                Food_PinYin:'',/**菜品拼音简称 */
                Food_Size:'',/**单位 */
                Food_AssSize:'', /**辅助单位 */
                Food_Price:0,/*价格 */
                Food_CostPrice:0, /**成本价 */
                Food_VipPrice:'',/**菜品会员价*/
                Is_SoldOut:false,/**是否沽清 true:是，false:否 */
                Is_Discounted:false,/**是否可折扣 true:是， false:否 */
                Is_Seasonal:false,/**是否时价菜 true:是， false:否 */
                Is_Limited:false,/**是否限量销售 true:是， false:否 */
                Is_ChangedNum:false,/**是否允许改量 true:是， false:否 */
                Is_ChargeForService:false,/**是否收取服务费 true:是， false:否 */
                Is_SetMeal:false,/**是否套餐菜品 true:是， false:否 */
                Is_Special:false,/**是否特色菜 true:是， false:否 */
                KitPlace_ID:'',/**出单位置ID */
                Kit_Level:0,/**上菜级别 */
                Is_MultipleSize:false,/**是否多规格菜品 true:是， false:否 */
                Is_BindEatMas:false,/**是否绑定做法 true:是， false:否 */
                Eat_Number:'',/*数量 */
                Eat_MasName:'',/**要求 */
                FoodModifys:null, /**口味 */
                isDiy:true,
            }
        }
    },
    mounted(){
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=>{
            this.init();
        })
    }, 
    watch: {
        isShow(newVal){
            if(newVal){
                this.$nextTick(()=>{
                    this.init();
                })
            }
        },
    },
    computed:{
        /**菜品分页显示 */
        foodsPages(){
            let size=5*4;
            return this.posFoods.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
    },
    methods:{
        init(){
            if(this.data){
                let newVal=this.data;
                this.customInfo.Food_ID = newVal.Food_ID
                this.customInfo.Food_Code=newVal.Food_Code
                this.customInfo.Food_EnglishName=newVal.Food_EnglishName
                this.customInfo.SubCategory_ID=newVal.SubCategory_ID
                this.customInfo.Food_Property=newVal.Food_Property
                this.customInfo.Food_PinYin=newVal.Food_PinYin
                this.customInfo.Food_AssSize=newVal.Food_AssSize
                this.customInfo.Food_VipPrice=newVal.Food_VipPrice
                this.customInfo.Food_CostPrice=newVal.Food_CostPrice
                this.customInfo.Is_SoldOut=newVal.Is_SoldOut
                this.customInfo.Is_Discounted=newVal.Is_Discounted
                this.customInfo.Is_Seasonal=newVal.Is_Seasonal
                this.customInfo.Is_Limited=newVal.Is_Limited
                this.customInfo.Is_ChangedNum=newVal.Is_ChangedNum
                this.customInfo.Is_ChargeForService=newVal.Is_ChargeForService
                this.customInfo.Is_SetMeal=newVal.Is_SetMeal
                this.customInfo.Is_Special=newVal.Is_Special
                this.customInfo.KitPlace_ID=newVal.KitPlace_ID
                this.customInfo.Kit_Level=newVal.Kit_Level
                this.customInfo.Is_MultipleSize=newVal.Is_MultipleSize
                this.customInfo.Is_BindEatMas=newVal.Is_BindEatMas
                this.customInfo.Food_Name=newVal.Food_Name;
                this.customInfo.Food_Price=newVal.Food_Price;
                this.customInfo.Food_Size=newVal.Food_Size;
                this.customInfo.Eat_Number=1;
                this.customInfo.Eat_MasName="";
            }
            setTimeout(() => {
                this.$refs?.foodName.focus();
            },100);
        },
        KeyClick(type){
            if(type==1){
                setTimeout(() => {
                    this.$refs?.foodSize.focus();
                },100);
            }
            if(type==2){
                setTimeout(() => {
                    this.$refs?.price.focus();
                },100);
            }
            if(type==3){
                setTimeout(() => {
                    this.$refs?.EatNumber.focus();
                },100);
            }
            if(type==4){
                this.confirm()
            }
        },
        /**点击分类 */
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.foodSubCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.foodSubCategoryIndex=0
            }
            this.varietySelect="";
        },
        selectDemand(){
            if(this.data){
                this.demandFullShow=true;
            }
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**要求确认 */
        confirmall(data){
            if(data){
                this.demandFullShow=false;
                let _food=[]
                data.map(t=>{
                    _food.push({
                        Mas_AutoID:t.Mas_AutoID,
                        Mas_Code:t.Mas_Code,
                        Mas_Name:t.Mas_Name,
                        Mas_Num: Number(this.customInfo.Eat_Number).toFixed(2),
                        Mas_Price:t.Mas_Price,
                        Price_Kind:t.Price_Kind,
                        Is_AddMoney:t.Is_AddMoney,
                    })
                })
                this.customInfo.FoodModifys =_food
                this.customInfo.Eat_MasName=data.map(t=>t.Mas_Name).join('/')
            }
        },
        /**确认并退出 */
        confirm(){
            if(Object.keys(this.customInfo).length==0){
                this.$emit("closeModel")
                return
            }
            //开启不强制要求修改菜品名true:开启 false:不开启
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting?.RunDiyFoodNotForceModifyName && this.customInfo.Food_Name==this.data.Food_Name){
                this.$message.warning('自定义菜品名不能与选中的模板名称一致')
                return
            }
            if(this.customInfo.Eat_Number<=0){
                this.$message.warning('自定义菜品数量不能为0')
                return
            }
            if(!this.customInfo.Food_Name){
                this.$message.warning('自定义菜品名称不能为空')
                return
            }
            if(!this.customInfo.Food_Size){
                this.$message.warning('自定义菜品单位不能为空')
                return
            }
            if(Number(this.customInfo.Food_Price)==0){
                this.$message.warning('自定义菜品价格不能为0')
                return
            }
            this.customInfo.Eat_Number = Number(Number(this.customInfo.Eat_Number).toFixed(2))
            this.customInfo.Food_Price=Number(this.customInfo.Food_Price);
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit('confirm',this.customInfo,0)
        }
    }
}
</script>

<style lang="sass">
@import "./diyFoodsModel.scss";
</style>