<template>
  <div class="layoutRc" :style='style' v-dom-resize @resize="resize()">
      <slot></slot>
  </div>
</template>

<script>
export default {
    name:'layoutRc',
    emits:["layoutChange"],
    props:{
        /**行 */
        row:{
            type:Number,
            default:0
        },
        /**列 */
        col:{
            type:Number,
            default:0
        },
        /**子级排列名称 */
        className:{
            type:String,
            default:""
        },
        /**元素的外边距 计算每个元素宽度需要*/
        marginW:{
            type:Number,
            default:0
        },
        /**元素的外边距 计算每个元素高度需要 */
        marginH:{
            type:Number,
            default:0
        },
        /**是否 通过 父级设置 宽高变量 控制元素宽高 */
        isVarCss:{
            type:Boolean,
            default:false
        },
        isLock:{//是否锁定不计算
            type:Boolean,
            default:false
        },
        list:{
            type:Array,
            default:null
        }
    },
    watch:{
        "list.length":{
            handler(){
               this.$nextTick(()=>{
                    this.changeRC();
                })
            },
            immediate:true
        }
    },
    data(){
        return {
            style:{ },
            time:null
        }
    },
    mounted(){
        this.$nextTick(()=>{
            setTimeout(()=>{
                this.changeRC();
            },0)
        })
    },
    methods:{
        /**排版 */
        changeRC(){
            if(this.isLock) return;
            let el=this.$el;
            let scrollEl=el.parentElement;
            el.style.minHeight='100%'
            let className='div[rc-item]';
            if(this.className){
                className="."+this.className;
            }
            let li= el.querySelectorAll(className);
            
            let w=el.offsetHeight;
            let h=scrollEl.offsetHeight- (scrollEl.scrollHeight- el.offsetHeight);
            if(!(w>0 && h>0)){return;}
            el.style.minHeight="";
            if(this.row>0){
                let height=Number(((h/this.row)-(this.marginH||0)-0.0005).toFixed(4));
                //let height = (h- (row -1)*5*2 -5*2)/row;
                if(this.isVarCss){
                    this.style['--rcHeight']=height+"px";
                }else{
                    li.forEach((item)=>{
                        item.style.height=height+"px";
                    })
                }
            }
            let rects=el.getBoundingClientRect();
            w=rects.width;
            if(this.col>0){
                if(li.length>(this.row||0)*this.col)
                {//一屏内容过多 
                    if(scrollEl.scrollHeight<=scrollEl.clientHeight){//不存在滚动条
                        w=w-6;//减去滚动条宽度
                        console.log("减去滚动条宽度")
                    }
                    scrollEl.style.overflow="";
                }else{//刚好在一屏内显示 
                    if(scrollEl.scrollHeight>scrollEl.clientHeight){//存在滚动条
                        // w=w+6;//减去滚动条宽度
                    }
                    scrollEl.style.overflow="hidden";
                    w=el.getBoundingClientRect().width;
                }
                let width= (w/this.col)-(this.marginW||0);
                width=Number((width-0.0005).toFixed(4));
                if(this.isVarCss){
                    this.style['--rcWidth']=width+"px";
                }else{
                    //let width = (w- (col -1)*5*2 -5*2)/col;
                    li.forEach((item)=>{
                        item.style.width=width+"px";
                    })
                }
                el.dispatchEvent(new Event("scroll"));
                this.$emit("layoutChange");
            }

        },
        resize(){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.changeRC();
                },0)
            })
        }
    }
}
</script>

<style>
</style>