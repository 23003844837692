
<script>
import { createVNode} from 'vue'
import { ElTableColumn} from 'element-plus'
export default {
    name:"cell",
    props:{
        renderCell:{
            type:Function,
            
        }
    },
    setup(props){
        return ()=>{
            let vnode=null;
            if(props.renderCell){
                vnode=props.renderCell();
            }
            return vnode;
        }
    }
}
</script>

<style>

</style>