/**滚动条动画 上下滚动 */
const scrollTop=function(el,top){
    let that=el;
    if(top<0){top=0;}
    else if(top>that.scrollHeight-that.offsetHeight){
        top=that.scrollHeight-that.offsetHeight;
        top=top<0?0:top;
    }
    if(el._scrollTopTimer){clearInterval(el._scrollTopTimer);}
    el._isAnim=true;
    el._scrollTopTimer=setInterval(() => {
        let ispeed = Math.ceil((top-that.scrollTop) / 5);
        let scrollTop=parseInt(that.scrollTop);
        that.scrollTop = scrollTop + ispeed;
        if(Math.abs(ispeed)<=0.1){
            that.scrollTop=top;
            setTimeout(()=>{
                el._isAnim=false;
                clearInterval(el._scrollTopTimer);
            },16)
        }
    }, 16);
    return top;
}

/**滚动条动画 左右滚动 */
const scrollLeft=function(el,left){
    let that=el;
    if(left<0){left=0;}
    else if(left>that.scrollWidth-that.offsetWidth){
        left=that.scrollWidth-that.offsetWidth;
        left=left<0?0:left;
    }
    if(el._scrollLeftTimer){clearInterval(el._scrollLeftTimer);}
    el._isAnim=true;
    el._scrollLeftTimer=setInterval(() => {
        let ispeed = Math.ceil((left-that.scrollLeft) / 5);
        let scrollLeft=that.scrollLeft;
        that.scrollLeft = scrollLeft + ispeed;
        if(Math.abs(ispeed)<=0.1){
            that.scrollLeft=left;
            setTimeout(()=>{
                el._isAnim=false;
                clearInterval(el._scrollLeftTimer);
            },16)
        }
    }, 16)
    return left;
}

function getProps(el,binding,props){
    let data={};
    for (let key in props) {
        if(binding.value && binding.value[key]!=undefined){
            data[key]=binding.value[key];
        }
        else if(el.dataset && el.dataset[key.toLocaleLowerCase()]!=undefined){
            data[key]=el.dataset[key.toLocaleLowerCase()];
        }else{
            data[key]=props[key];
        }
    }
    return data;
}

/**滚动翻页动画 */
function rollAway(app){
    let props={
        up:'',
        down:'',
        fx:'y',
        height:0,
        paddingH:0,//滚动条内边距
        liClass:'',//数据 div 类名
        marginH:0,//每条数据的外边距 
        isRow:true,//没条数据站一行
        page:'',
        pageCheage:'',//
        isNavFirstSticky:false,//是否允许导航第一个按钮 位置不滚动
    }
 
    function scrollDown(el,binding,data){
        let order=el;
        if(data.fx=="x"){//
            if(data.liClass){
                let divLi=order.querySelectorAll(data.liClass);
                if(divLi && divLi.length>0){
                    let left=order.scrollLeft;
                    let w=order.offsetWidth;
                    let scrollWidth=order.scrollWidth;
                    let firstIndex=0;
                    if(data.isNavFirstSticky){//可视区域宽度减去第一个nav 的宽度
                        w=w-divLi[0].offsetWidth;
                        scrollWidth=scrollWidth-divLi[0].offsetWidth;
                        firstIndex=1;
                    }
                    let liW=0;
                    for (let index = firstIndex; index < divLi.length; index++) {
                        liW+=divLi[index].offsetWidth;
                        if(left+w<liW){
                            liW=liW-divLi[index].offsetWidth;
                            break;
                        }
                    }
                    let endLeft=scrollLeft(order,liW);
                    let pageSize=Math.ceil(scrollWidth/w);
                    let pageIndex=Math.ceil((endLeft+w)/w);
                    if(data.page && binding.instance.$data[data.page]!=undefined){
                        binding.instance.$data[data.page].pageSize=pageSize;
                        binding.instance.$data[data.page].pageIndex=pageIndex;
                    }else if(data.pageCheage && typeof(binding.instance.pageCheage)=='function'){
                        binding.instance.pageCheage({pageSize:pageSize,pageIndex:pageIndex});
                    }
                }
            }
        }else{//上下滚动
            if(data.height>0 || (data.liClass && !data.isRow)){//固定单行高
                let height=data.height;
                if(!(height>0)){
                    let divLi=order.querySelector(data.liClass);
                    if(divLi){
                        height=divLi.getBoundingClientRect().height;
                    }else{
                        return;
                    }
                }
                height+=data.marginH;
                let top=order.scrollTop;
                let h=order.offsetHeight-data.paddingH;
                top=parseInt((top+h)/height)*height;
                scrollTop(order,Math.ceil(top))
            }else if(data.liClass && data.isRow){//非固定单行高 每行元素 类名
                let divLi=order.querySelectorAll(data.liClass);
                if(divLi && divLi.length>0){
                    let top=order.scrollTop;
                    let h=order.offsetHeight-data.paddingH;
                    let liH=0;
                    for (let index = 0; index < divLi.length; index++) {
                        liH+=divLi[index].offsetHeight;
                        if(top+h<liH){
                            if(divLi[index].offsetHeight<h){
                                liH=liH-divLi[index].offsetHeight;
                            }else{
                                liH=top+h;
                            }
                            break;
                        }
                    }
                    scrollTop(order,liH)
                }
            }else{
                let top=order.scrollTop+order.offsetHeight;
                scrollTop(order,top)
            }
        }
    }

    function scrollUp(el,binding,data){
        let order=el;
        if(data.fx=="x"){
            if(data.liClass){//非固定单行高 每行元素 类名
                let divLi=order.querySelectorAll(data.liClass);
                if(divLi && divLi.length>0){
                    let left=order.scrollLeft;
                    let w=order.offsetWidth;
                    let scrollWidth=order.scrollWidth;
                    let liW=0;
                    let firstIndex=0;
                    if(data.isNavFirstSticky){//可视区域宽度减去第一个nav 的宽度
                        w=w-divLi[0].offsetWidth;
                        scrollWidth=scrollWidth-divLi[0].offsetWidth;
                        firstIndex=1;
                    }
                    if(left-w>0){
                        for (let index = firstIndex; index < divLi.length; index++) {
                            liW+=divLi[index].offsetWidth;
                            if(left-w<liW){
                                break;
                            }
                        }
                    }
                    let endLeft= scrollLeft(order,liW);
                    let pageSize=Math.ceil(scrollWidth/w);
                    let pageIndex=Math.ceil((endLeft+w)/w);
                    if(data.page && binding.instance.$data[data.page]!=undefined){
                        binding.instance.$data[data.page].pageSize=pageSize;
                        binding.instance.$data[data.page].pageIndex=pageIndex;
                    }else if(data.pageCheage && typeof(binding.instance.pageCheage)=='function'){
                        binding.instance.pageCheage({pageSize:pageSize,pageIndex:pageIndex});
                    }
                }
            }
        }else{//上下滚动
            if(data.height>0 || (data.liClass && !data.isRow)){//固定单行高
                let height=data.height;
                if(!(height>0)){
                    let divLi=order.querySelector(data.liClass);
                    if(divLi){
                        height=divLi.getBoundingClientRect().height;
                    }else{
                        return;
                    }
                }
                height+=data.marginH;
                let top=order.scrollTop;
                let h=order.offsetHeight-data.paddingH;
                top=Math.ceil((top-h)/height)*height;
                scrollTop(order,Math.ceil(top))
            }else if(data.liClass && data.isRow){//非固定单行高 每行元素 类名
                let divLi=order.querySelectorAll(data.liClass);
                if(divLi && divLi.length>0){
                    let top=order.scrollTop;
                    let h=order.offsetHeight-data.paddingH;
                    let liH=0;
                    if(top-h>0){
                        for (let index = 0; index < divLi.length; index++) {
                            liH+=divLi[index].offsetHeight;
                            if(top-h<liH){
                                //liH=liH-divLi[index].offsetHeight;
                                if(divLi[index].offsetHeight>h){
                                    liH=top-h;
                                }
                                break;
                            }
                        }
                    }
                    scrollTop(order,liH)
                }
            }else{
                let top=order.scrollTop-order.offsetHeight;
                scrollTop(order,top)
            }
        }
    }
    function page(el,binding,data){
        if(data.fx=="x"){
            let left=el.scrollLeft;
            let w=el.offsetWidth;
            let scrollWidth=el.scrollWidth;
            if(data.isNavFirstSticky){//可视区域宽度减去第一个nav 的宽度
                let divLi=el.querySelectorAll(data.liClass);
                w=w-divLi[0].offsetWidth;
                scrollWidth=scrollWidth-divLi[0].offsetWidth;
            }
            let pageSize=Math.ceil(scrollWidth/w)||1;
            let pageIndex=Math.ceil((left+w)/w)||1;
            if(data.page && binding.instance.$data[data.page]!=undefined){
                binding.instance.$data[data.page].pageSize=pageSize;
                binding.instance.$data[data.page].pageIndex=pageIndex;
            }else if(data.pageCheage && typeof(binding.instance.pageCheage)=='function'){
                binding.instance.pageCheage({pageSize:pageSize,pageIndex:pageIndex});
            }
        }
    }

    function navClick(el,binding,data){
        if(!data.liClass)return;
        let divLi=el.querySelectorAll(data.liClass);
        (divLi||[]).forEach((item,i)=>{
            if(item.className.indexOf("selected")>=0){
                if(el._selectedIndex!=i){
                    el._selectedIndex=i;
                    click(item);
                }
            }
            if(!item._isClickNav){
                item._isClickNav=true;
                item.addEventListener("click",()=>{
                    click(item);
                })
            }
        })
        function click(item){
            let left=el.scrollLeft;
            let w=el.offsetWidth;
            let x=item.offsetLeft;
            if(data.isNavFirstSticky){//可视区域宽度减去第一个nav 的宽度
                w=w-divLi[0].offsetWidth;
                x=x-divLi[0].offsetWidth;
                if(item==divLi[0]){return;}
            }
            if(left>x){
                scrollLeft(el,x);
            }else if(left+w<x+item.offsetWidth){
                scrollLeft(el,(x+item.offsetWidth)-w);
            }
        }
    }

    app.directive('scroll-anime',{
        mounted(el,binding){
            let data=getProps(el,binding,props);
            let up=binding.instance.$refs[data.up];
            let down=binding.instance.$refs[data.down];
            page(el,binding,data);
            if(up){//上 或 左 点击
                up.addEventListener("click",()=>{scrollUp(el,binding,data)});
            }
            if(down){//下 或 右 点击
                down.addEventListener("click",()=>{scrollDown(el,binding,data)});
            }
            if(data.fx=="x"){
                let setT
                el.addEventListener('scroll',()=>{
                    if(setT) {clearTimeout(setT);}
                    setT=setTimeout(()=>{
                        if(el._isAnim){return;}
                        page(el,binding,data);
                    },100);
                })
                navClick(el,binding,data);
            }
        },
        updated(el,binding){
            let data=getProps(el,binding,props);
            if(!el._isAnim){
                page(el,binding,data);
                navClick(el,binding,data);
            }
        }
    })

   
}

/**导航滚动动画  */
function navRoll(app){
    let props={
        nav:'',
        navName:'.nav-li',
        paneName:'.pane-box',
    }

    function scroll(el,nav,data){
        if(el._isAnim){return;}
        let top=el.scrollTop;
        let paneBoxs=el.querySelectorAll(data.paneName);
        let navLis=nav.querySelectorAll(data.navName);
        let index=0;
        (paneBoxs||[]).forEach((item,i)=>{
            if(item.offsetTop<=top){
                index=i;
            }
        });
        let navli;
        (navLis||[]).forEach((item)=>{
            item.className=item.className.replace("selected","");
            if(item.dataset["index"]==index){
                navli=item;
            }
        })
        if(navli){
            navli.className=navli.className+" selected";
        }
    }
    function goNav(el,nav,data){
        
        let navLis= nav.querySelectorAll(data.navName);
        (navLis||[]).forEach((item,i)=>{
            if(!item._isClickNavRoll){
                item._isClickNavRoll=true;
                item.addEventListener("click",()=>{
                    let selected=nav.querySelector(data.navName+".selected");
                    if(selected){
                        selected.className=selected.className.replace("selected","");
                    }
                    navLis[i].className=navLis[i].className.replace(/^[ ]|[ ]$/g,'') +" selected";
                    let index=navLis[i].dataset["index"];
                    let paneBoxs=el.querySelectorAll(data.paneName);
                    let elRect=el.getBoundingClientRect();
                    let rect=paneBoxs[index].getBoundingClientRect();
                    let top=rect.top-elRect.top+el.scrollTop;

                    if(index) scrollTop(el, top);
                })
            }
        })
    }

    app.directive('nav-roll',{
        mounted(el,binding){
            let data=getProps(el,binding,props);
            let nav=binding.instance.$refs[data.nav];
            el.addEventListener('scroll',()=>{
                scroll(el,nav,data);
            })
            goNav(el,nav,data)
        },
        updated(el,binding){
            let data=getProps(el,binding,props);
            let nav=binding.instance.$refs[data.nav];
            goNav(el,nav,data)
        }
    })
}


/**鼠标移动 模拟触摸滑动滚动条 */
function mouseScroll(app){
    let props={
        scrollName:''
    }
    app.directive('mouse-scroll',{
        beforeMount:(el,binding)=>{
            if(('ontouchstart' in window) || window.DocumentTouch){
                return;
            }
            let data=getProps(el,binding,props);
            let oy,ox,sLeft,sTop;
            let fx="";
            let isDown=false;
            let iSpeed=0,lastMoveTime,lastMoveStart;
            let shake=10;//防止抖动
            let scrollObj=el;
            if(data.scrollName){
                let obj=el.querySelector(data.scrollName);
                if(obj){
                    scrollObj=obj;
                }
            }


            scrollObj.addEventListener('selectstart',()=>{return false});
            scrollObj.addEventListener('mousedown',(e)=>{
                isDown=true;
                fx=""; 
                sLeft=scrollObj.scrollLeft;
                sTop=scrollObj.scrollTop;
                ox=e.pageX;
                oy = e.pageY;
                clearTime();
            })
            scrollObj._mousemove=(e)=>{
                if(isDown){
                    if(!fx){
                        let x = e.pageX - ox,y = e.pageY - oy;
                        if(Math.abs(x)>Math.abs(y)){
                            if(Math.abs(x)<shake) return;
                            ox=x>0?ox+shake:ox-shake;
                            fx="x";
                            lastMoveStart = e.pageX;
                        }else{
                            if(Math.abs(y)<shake) return;
                            oy=y>0?oy+shake:oy-shake;
                            fx="y";
                            lastMoveStart = e.pageY;
                        }
                        lastMoveTime = e.timeStamp || Date.now();
                        if((fx=="x" && scrollObj.clientWidth<scrollObj.scrollWidth) || (fx=="y" && scrollObj.clientHeight<scrollObj.scrollHeight)){
                            scrollObj.style.pointerEvents="none";
                        }
                    }
                    
                    if(fx=="x"){
                        scrollObj.scrollLeft =sLeft+ ox-e.pageX ;
                    }else{  
                        scrollObj.scrollTop = sTop+ oy-e.pageY;
                    }
        
                    let nowTime = e.timeStamp || Date.now();
                    if(nowTime - lastMoveTime > 64) {
                        lastMoveTime = nowTime;
                        lastMoveStart= fx=="x"? e.pageX:e.pageY;
                    }
                }
            }
            
            document.addEventListener('mousemove',scrollObj._mousemove);
            scrollObj._mouseup=(e)=>{
                if(isDown){
                    scrollObj.style.pointerEvents="";
                    isDown=false;
                    if(fx=="x" || fx=="y"){
                        let nowTime = e.timeStamp || Date.now();
                        let nowY= fx=="x"? e.pageX:e.pageY;
                        let v = (nowY - lastMoveStart) / (nowTime - lastMoveTime); //最后一段时间手指划动速度
                        iSpeed=v*16;
                        startMove(e);
                    }
                }
            }
            document.addEventListener('mouseup',scrollObj._mouseup);
            
            function clearTime(){
                iSpeed=0;
                scrollObj.style.pointerEvents="";
                scrollObj._isAnim=false;
                if(scrollObj._scrollTopTimer){clearInterval(scrollObj._scrollTopTimer);}
                if(scrollObj._scrollLeftTimer){clearInterval(scrollObj._scrollLeftTimer);}
            }
            function startMove(){    //移动函数，主要操作是计算鼠标移动速度和移动方向。
                if(fx=="x"){
                    if(scrollObj._scrollTopTimer){clearInterval(scrollObj._scrollTopTimer);}
                    scrollObj._isAnim=true;
                    scrollObj._scrollLeftTimer=setInterval(anim,16);
                }else{
                    if(scrollObj._scrollLeftTimer){clearInterval(scrollObj._scrollLeftTimer);}
                    scrollObj._isAnim=true;
                    scrollObj._scrollTopTimer=setInterval(anim,16); 
                }
                function anim(){ 
                    if(isNaN(iSpeed)) iSpeed=0;
                    iSpeed*=0.93;
                    if(fx=="x"){
                        if(iSpeed>0 && scrollObj.scrollLeft-iSpeed<=0){
                            scrollObj.scrollLeft =0;
                            iSpeed=0;
                        }else if(iSpeed<0 && scrollObj.scrollLeft-iSpeed>=scrollObj.scrollWidth-scrollObj.clientHeight){
                            scrollObj.scrollLeft =scrollObj.scrollWidth-scrollObj.clientHeight;
                            iSpeed=0;
                        }
                        else{
                            scrollObj.scrollLeft -= iSpeed ;
                        }
                        
                    }else{
                        if(iSpeed>0 && scrollObj.scrollTop-iSpeed<=0){
                            scrollObj.scrollTop =0;
                            iSpeed=0;
                        }else if(iSpeed<0 && scrollObj.scrollTop>=scrollObj.scrollHeight-scrollObj.clientHeight){
                            scrollObj.scrollTop =scrollObj.scrollHeight-scrollObj.clientHeight;
                            iSpeed=0;
                        }else{
                            scrollObj.scrollTop -= iSpeed;
                        }
                    }   
                    if(Math.abs(iSpeed)<1){
                        clearTime();
                    }
                }
            } 
        },unmounted(el,binding) {
            let data=getProps(el,binding,props);
            let scrollObj=el;
            if(data.scrollName){
                let obj=el.querySelector(data.scrollName);
                if(obj){
                    scrollObj=obj;
                }
            }
           if(scrollObj._mousemove) document.removeEventListener('mousemove',scrollObj._mousemove);
           if(scrollObj._mouseup) document.removeEventListener('mouseup',scrollObj._mouseup);
        }
    })
}

export default {
    scrollTop,
    scrollLeft,
    install(app){
        rollAway(app);
        navRoll(app);
        mouseScroll(app);
    },
    /**滚动到锚点位置 */
    scrollTopAnchor(scrollObj,anchorClassName,isAni){
        if(scrollObj){
            let anchor=scrollObj.querySelector(anchorClassName);
            if(anchor){
                let scrollRC=scrollObj.getBoundingClientRect();
                let anchorRC=anchor.getBoundingClientRect();
                let offsetTop=scrollObj.scrollTop+(anchorRC.y-scrollRC.y);
                if(scrollObj.scrollTop>offsetTop){
                    if(isAni){
                        scrollTop(scrollObj,offsetTop);
                    }else{
                        scrollObj.scrollTop=offsetTop;
                    }
                    
                }else if(scrollObj.scrollTop+scrollObj.offsetHeight<offsetTop+anchor.offsetHeight){
                    if(anchor.offsetHeight>=scrollObj.offsetHeight){
                        if(isAni){
                            scrollTop(scrollObj,offsetTop);
                        }else{
                            scrollObj.scrollTop=offsetTop;
                        }
                    }else{
                        let top=offsetTop-scrollObj.offsetHeight+anchor.offsetHeight;
                        if(isAni){
                            scrollTop(scrollObj,top);
                        }else{
                            scrollObj.scrollTop=top;
                        }
                    }
                    
                }
            }
        }
    }
}