<template>
  <modal class="popupInputModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">应付金额
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    
                </div>
                <div class="right-box">
                    <div class="tr-li">
                        <div class="lable-txt">调整数量：</div>
                        <div class="from-input" style="width:50%"><input type="text" ref="numInput" data-model='number' @focus="$event.target.select()"  v-model="number"/></div>
                    </div>
                    <div class="keyboard" style="margin-top:5%">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 

/**会员支付 */
export default {
    name:"popupInputModel",
    props:{
        isShow:Boolean,
        orderInfo:Object
    },
    data(){
        return {
            number:"",//付款码
        }
    },
    mounted(){
       this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.numInput);
            this.number= Number(Number(this.orderInfo.Payable_Money).toFixed(2))
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            this.number= Number(Number(this.orderInfo.Payable_Money).toFixed(2))
            this.EmptyDatas();
        }
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.numInput.focus();
            },100);
        },
        hide(){
            this.$emit("closeModel")
        },
        /**确定和回车事件 */
        confirm(){
            if(this.number==0){
                this.$alert('数量不能为0!', '提示', {
                        confirmButtonText: '确定',
                });
                return
            }else{
                this.$emit("popupReturn",this.number)
            }
        },
    }
}
</script>

<style lang="sass">
@import "./popupInputModel.scss";
</style>