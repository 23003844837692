<template>
    <modal class="unionOrderDishModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">联台下单</div>
        <div class="modal-body">
            <div class="name">
                当前桌台：<span>{{data?.Eat_DeskName}}</span>
            </div>
            <div class="title">关联桌台</div>
            <!-- 桌台 -->
            <div class="content-box">
                <div class="item-li" :class="{selected:selectDeskKey[item.Eat_DeskID]}" v-for="item in deskPageList" :key="item"  @click="addDesk(item)">
                    <div class="title">
                        {{item.Eat_DeskName}}
                    </div>
                    <i class="iconfont icon-gou"></i>
                </div>
            </div>
            <div class="item-operation">
                <key-board-input class="search"
                    type="text" v-model="filterText" placeholder="按桌台名称检索"
                    isKeyEnterClose isBlurClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
                <div class="bnt" @click="allClick()">反选</div>
                <div class="bnt" @click="allClick(true)">全选</div>
                <div class="flex-1">
                    
                </div>
                <div class="page-box">
                    <page-turning class="last-page iconfont icon-zuo" type="up" v-model="deskPageIndex" :pageCount="deskTotalSize"></page-turning>
                    <span class="page-number">{{deskPageIndex}}/{{deskTotalSize}} 页</span>
                    <page-turning class="next-page iconfont icon-you" v-model="deskPageIndex" :pageCount="deskTotalSize"></page-turning>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()">确认下单</button>
        </div>
    </modal>
</template>

<script>
//多台开单
export default {
    name: 'multipleBillingModel',
    emits:['close','confirm'],
    props:{
        isShow:Boolean,
        //传入联台数据
        data:{
            type:Object,
            default:null
        }
    },
    data() {
        return {
            //分类
            filternav:-1,
            //分类 分页
            navPage:{
                pageIndex:1,
                pageSize:1
            },
            //桌台检索
            filterText:"",
            deskPageIndex:1,
            arg:{
                row:8,
                col:6
            },
            selectDeskList:[],
            selectPageIndex:1,
            /**参数传递 */
            param:null
        }
    },
    computed:{
        //筛选桌台
        deskFilter(){
            let list=this.data?.UnionOrderInfoList||[];
            let filterText=(this.filterText||"").trim().toUpperCase();
            if(filterText){
                //检索
                list=list.filter(item=>(item.Eat_DeskName||"").toUpperCase().indexOf(filterText)>=0);
            }
            return list; 
        },
        deskTotalSize(){
            let pageSize=6*7;
            return Math.ceil(this.selectDeskList.length/pageSize)||1;
        },
        //桌台分页
        deskPageList(){
            let pageSize=6*7;
            return this.deskFilter.slice((this.deskPageIndex-1)* pageSize, this.deskPageIndex * pageSize);
        },
        selectDeskKey(){
            let key={};
            this.selectDeskList?.forEach(it=>{
                key[it.Eat_DeskID]=it;
            })
            return key;
        },
    },
    watch:{
        isShow:{
            handler(newVal){
                if(newVal){
                    this.filternav=-1;
                    this.navPage.pageIndex=1;
                    this.filterText="";
                    this.deskPageIndex=1;
                    this.selectDeskList=[];
                }
            },
            immediate:true
        },
        filterText(){
            this.deskPageIndex=1;
        }
    },
    mounted(){
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        addDesk(item){
            let index=this.selectDeskList.findIndex(it=>it.Eat_DeskID==item.Eat_DeskID);
            if(index>=0){
                this.selectDeskList.splice(index,1);
            }else{
                this.selectDeskList.push({Eat_DeskID:item.Eat_DeskID,Eat_DeskName:item.Eat_DeskName,Eat_AutoID:item.Eat_AutoID});
            }
        },
        //全选 或 反选
        allClick(isAll){
            if(isAll){//全选
                this.selectDeskList=[];
                this.deskFilter.forEach(item=>{
                    this.selectDeskList.push({Eat_DeskID:item.Eat_DeskID,Eat_DeskName:item.Eat_DeskName,Eat_AutoID:item.Eat_AutoID});
                })
            }else{//反选
                this.deskFilter.forEach(item=>{
                    this.addDesk(item);
                })
            }
        },
        confirm(){
            let list=this.selectDeskList.map(it=>{
                return {
                    Union_DeskID:it.Eat_DeskID,//联台桌台ID
                    Union_OrderID:it.Eat_AutoID//联台订单ID
                }
            })
            if(list.length>0){
                this.$confirm('您确认本次下单关联选择的桌台，共'+(list.length+1)+'桌一起下单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$emit("confirm",list,this.param);
                }).catch(()=>{});
            }else{
                this.$confirm("您确认本次不关联桌台，只往【"+this.data?.Eat_DeskName+"】桌台下单吗?", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$emit("confirm",list,this.param);
                }).catch(()=>{});
            }
            
        }
    }
}

</script>

<style lang="scss">
@import "./unionOrderDishModel.scss";
</style>