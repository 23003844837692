<template>
    <modal class="paymentCouponsModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">
            <span>{{data?.PAY_NAME}}</span>
            <span class="unMoney" >待支付金额:<span class="money">¥{{Number(memberorderInfo.UnPaid_Money).toFixed(2)}}</span></span>
            <span class="payCoupons">已选券抵扣:<span class="money">¥{{Number(counMoney).toFixed(2)}}</span></span>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel">
                        <div class="panel-heading">
                            {{data?.PAY_NAME}}选择
                        </div>
                        <div class="panel-body item-box clearfix" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item,1)"
                             v-for="(item,index) in ticketInfos" :key="index">
                                <template v-if="item.KIND==1 && item.CashCouponPrices?.length>0">
                                    <el-popover popper-class="popover-box" :placement="index%4==3?'left-start':'right-start'" :width="128" trigger="click">
                                        <template #reference>
                                            <div>
                                                <div class="coupons-name verticalCenter">
                                                    <template v-if="item.CashCouponPrices?.length>0">
                                                        <div class="name inlineNowrap2 money">组合券</div>
                                                    </template>
                                                    <template>
                                                        <div class="money-ico" v-if="data?.INTERFACE_TYPE==7">￥</div>
                                                        <div class="name inlineNowrap2 money" v-if="data?.INTERFACE_TYPE==7">
                                                            {{item.CashCouponPrices?.length>0?"组合券":item.EAT_PRICE}}
                                                        </div>
                                                        <div class="name inlineNowrap2" v-else>{{item.TK_NAME}}</div>
                                                    </template>
                                                </div>
                                                <div class="coupons-describe" v-if="data?.INTERFACE_TYPE==7">
                                                    <div class="nowrap2">
                                                        {{item.TK_NAME}}
                                                    </div>
                                                </div>
                                                <div class="coupons-describe nowrap" v-else>
                                                    {{({2:'无面值商品券',3:'有面值商品券'})[item.KIND]}}
                                                </div>
                                            </div>
                                        </template>
                                        <div class="pop-box">
                                            <div class="pop-li" :class="{selected:coupon._selected}" v-for="coupon in item.CashCouponPrices" :key="coupon" @click="onTicket(coupon)">
                                                <p class="money">￥{{coupon.Face_Price}}</p>
                                                <p class="money-explain">售价:<span class="unit">￥</span><span class="price">{{coupon.Sale_Price}}</span></p>
                                            </div>
                                        </div>
                                    </el-popover>
                                </template>
                                <template v-else>
                                    <div class="coupons-name verticalCenter">
                                        <div class="money-ico" v-if="data?.INTERFACE_TYPE==7">￥</div>
                                        <div class="name inlineNowrap2 money" v-if="data?.INTERFACE_TYPE==7">{{item.EAT_PRICE}}</div>
                                        <div class="name inlineNowrap2" v-else>{{item.TK_NAME}}</div>
                                    </div>
                                    <div class="coupons-describe" v-if="data?.INTERFACE_TYPE==7">
                                        <div class="nowrap2">
                                            {{item.TK_NAME}}
                                        </div>
                                    </div>
                                    <div class="coupons-describe nowrap" v-else>
                                        {{({2:'无面值商品券',3:'有面值商品券'})[item.KIND]}}
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="panel">
                        <div class="panel-heading">
                            已选{{data?.PAY_NAME}}
                        </div>
                        <div class="panel-body select-item-box" v-scroll-anime="{up:'right-order-up',down:'right-order-down',liClass:'.tr'}">
                            <div class="tr" v-for="(item,index) in selectTicketList" :key="item">
                                <div class="td num">{{index+1}}</div>
                                <div class="td name">
                                    <div>
                                        {{item.TK_NAME}} 
                                        <p class="red" v-if="item.KIND==1">售价:{{item.EAT_SALEPRICE}}</p>
                                        <div class="tktitle" v-if="item.KIND==3&&item.TK_ONETOMANY"><span class="td tkremark">剩余可用{{item.maxnum}}张</span></div>
                                    </div>
                                </div>
                                <div class="td tally-box">
                                    <div class="bnt-jian" @click="setNumber(item,0,1)"><b class="iconfont icon-jian"></b></div>
                                    <div class="number" >
                                        <key-board-input class="number-input"
                                            onkeyup="value=value.replace(/[^\d]/g,'')"
                                            type="text" v-model="item.number" 
                                            pattern="money"
                                            @keydown.enter="(e)=>enter(e,item)"
                                            @blur.capture="enter('',item)"
                                            @click="foodClick(item)"
                                            isBlurClose isKeyEnterClose isFocusKeyBoard
                                            :keyOptions="{isKeyDownEnter:true,isNumber:true}"></key-board-input>
                                    </div>
                                    <div class="bnt-jia" @click="setNumber(item,2,1,false)"><b class="iconfont icon-jia"></b></div>
                                </div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="right-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="right-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </modal>
</template>

<script>

import { deepClone } from '/src/common' 
import { newGuid } from '/src/common/index.js'

/**现金券 或 商品券 选择 */
export default {
    name:"paymentCouponsModel",
    props:{
        isShow:Boolean,
        orderInfo:{},
        choicePayType:Object,
        unionEatAutoID:Object,
        unionType:Boolean,
        wlivePayExist:Boolean,
        data:{
            default:{
                INTERFACE_TYPE: 7,
                PAY_NAME: "现金券",
                ticketInfos:[]
            }
        }
    },
    data(){
        return {
            /**券数据 */
            ticketInfos:[],
            selectTicketList:[],
            selectCountNumber:0, /**券的总数量 */
            memberorderInfo:'',  //临时订单数据
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**选择券的总金额 */
            counMoney:0,
            CouNumber:1,
            isKey:1,/**是否执触发过键盘修改券数量 */
            /**交易记录 */
            Payments:[],
            /**菜品和券绑定关系 */
            FoodticketList:[],
            /**选择的券 */
            selectFood:'',


            Foodcounlogs:[],//记录每一张券对应菜品的使用情况
            currentId:0,
            totalamount:0
        }
    },
    mounted(){
        this.init();
    }, 
    watch: {
        isShow(newVal) {
            if(newVal){
                this.init();
            }
        },
        orderInfo:{
            immediate:true,
            handler(vla){
                this.init()
            }
        },
        selectTicketList:{
            handler(newVal,oldVal){
                if(this.selectTicketList){
                    this.counMoney=0
                    this.selectTicketList.map(tick=>{
                        this.counMoney = Number(Number(this.counMoney + Number(tick.EAT_PRICE) * Number(tick.number)).toFixed(2))
                        this.selectCountNumber += tick.number
                    })
                }
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        init(){
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            //合并联台的订单信息
            if(this.unionType==true){
                this.memberorderInfo.UnionOrderInfoList.forEach(union=>{
                    union.Order_ConsumeList.forEach(unOrder=>{
                        this.memberorderInfo.Order_ConsumeList.push(unOrder)
                    })
                })
            }
            this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                Object.assign(order,{deductionNumber:0,deductionMoney:0})
            })
            this.ticketInfos=deepClone(this.data.ticketInfos);

            this.ticketInfos?.forEach(item=>{
                item.CashCouponPrices?.forEach(it=>{
                    it.TK_AUTOID=item.TK_AUTOID;
                    it.KIND=1;
                    it.TK_CODE=item.TK_CODE;
                    it.TK_NAME=item.TK_NAME;
                    it.EAT_PRICE=it.Face_Price;
                    it.EAT_SALEPRICE=it.Sale_Price
                    it.PAY_ID=item.PAY_ID;//对应支付方式ID
                    it.PAY_NAME=item.PAY_NAME;//对应支付方式名称
                    it.OLPOINTS=item.OLPOINTS;	//积分次数
                    it.TK_ONETOMANY=item.TK_ONETOMANY;//一张券可低多个商品
                    it.BEGIN_DATE=item.BEGIN_DATE;	//开始日期
                    it.END_DATE=item.END_DATE;	//结束日期
                    it.REMARK=item.REMARK;	//备注
                    it.AutoIDKind=0;//主键list类型 1：指定菜品ID， 2.指定菜品分类 ，3：排除菜品分类
                    it.isCashCoupon=true;//组合券
                })
            })
            console.log(this.ticketInfos)
            this.selectTicketList=[];
            this.FoodticketList=[]
            this.selectCountNumber=0
            this.Payments=[],
            this.counMoney=0
        },
        foodClick(item){
            this.CouNumber=Number(item.number)
        },
        /**键盘数量调整 */
        enter(el,item){
            if(item?.number){
                if(el!=""&&this.chekckIndex(item))
                {
                    item.number = Number(this.CouNumber)
                    return
                }
                if(Number(item.number)<=0){
                    this.$message.warning('数量不能为0');
                    item.number = Number(this.CouNumber)
                    return
                }
                if((item.number+'').indexOf('.')!=-1){
                    this.$message.warning('券数量不支持小数格式');
                    item.number = Number(this.CouNumber)
                    return
                }
                if(Number(item.number)>9999){
                    this.$message.warning('单张券单次数量最多为9999张');
                    item.number=Number(this.CouNumber)
                    return
                }
                this.isKey=1
                let _number = 0
                if(Number(item.number)>Number(this.CouNumber)){
                    if(item.KIND==3&&item.TK_ONETOMANY){
                        let mount= this.computeMaxCouponsNum(item);
                        _number = Number(item.number)-Number(this.CouNumber)
                        if(mount<_number){
                            this.$message.warning('订单剩余可用券的的菜品不足'+ Number(Number(mount).toFixed(2))+'份');
                            item.number = Number(this.CouNumber)
                            return
                        }
                    }else{
                        //判断菜品数量还剩多少可用
                        let mount= this.orderNumberUsemethod(item)
                        _number = Number(item.number)-Number(this.CouNumber)
                        if(mount<_number && item.KIND!=1){
                            this.$message.warning('该菜品或订单剩余可用券的的菜品不足'+ Number(Number(mount).toFixed(2))+'份');
                            item.number = Number(this.CouNumber)
                            return
                        }
                    }
                    this.setNumber(item,1,_number,true)
                }
                //数量减少
                if(Number(item.number)<Number(this.CouNumber)){
                    _number = Number(this.CouNumber) - Number(item.number)
                    item.number=Number(this.CouNumber)
                    this.setNumber(item,0,_number)
                }
            }else{
                item.number = Number(this.CouNumber)
                this.$message.warning('数量不能为空');
            }
            if(el){
                el.srcElement.blur();
            }
            this.selectTicketList.forEach(order=>{
                if(order.KIND==3&&order.TK_ONETOMANY){
                    order.maxnum=this.computeMaxCouponsNum(order);
                }
            })
        },
        //菜品数量还剩多少可用
        orderNumberUsemethod(item){
            let num=0,eatNum=0
            if(item.KIND!=1){
                this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                    if(item.AutoIDKind==1){
                        if(item.AutoIDList.indexOf(order.EAT_XFBMID)!=-1){
                            if(order.deductionMoney>=order.Eat_CheckMoney){
                                return;
                            }
                            eatNum += order.Eat_Number;
                            num += order.deductionNumber;
                        }
                    }else if(item.AutoIDKind==2){
                        if(item.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                            if(order.deductionMoney>=order.Eat_CheckMoney){
                                return;
                            }
                            eatNum += order.Eat_Number;
                            num += order.deductionNumber;
                        }
                    }else if(item.AutoIDKind==3){
                        if(item.AutoIDList.indexOf(order.SubCategory_ID)==-1){
                            if(order.deductionMoney>=order.Eat_CheckMoney){
                                return;
                            }
                            eatNum += order.Eat_Number;
                            num += order.deductionNumber;
                        }
                    }
                })
            }
            return eatNum-num;
        },
        /**点击商品券 或 现金券 */
        onTicket(ticket,type){
            if(Number(this.memberorderInfo.UnPaid_Money)<=0){
                this.$message.warning("不存在待支付金额,不允许操作");
                return;
            }
            if(ticket._selected==true){
                if(this.chekckIndex(ticket)){
                    return;
                }
                this.tickPitch(ticket);
                return;
            }

            let tickeClick=0;
            if(type==1){
                /**1：现金券 2：无面值商品券 3.有面值商品券 */
                if(ticket.KIND==2||(ticket.KIND==3&&!ticket.TK_ONETOMANY)){
                    /**1：指定菜品ID  2.指定菜品分类  3.排除菜品分类 */
                    if(ticket.AutoIDKind==1){
                        let autotype=0;
                        this.memberorderInfo.Order_ConsumeList.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1 && order.deductionNumber<order.Eat_Number){      
                                if(order.deductionMoney<order.Eat_CheckMoney){             
                                    if(ticket.KIND==2){
                                        ticket.EAT_PRICE=order.Eat_CheckPrice;
                                    }
                                    let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                    this.FoodticketList.push(food);
                                    order.deductionNumber++;

                                    //将单个商品券的使用记录下来
                                    let money=order.Eat_CheckMoney-order.deductionMoney;
                                    if(money>=ticket.EAT_PRICE){
                                        money=ticket.EAT_PRICE;
                                    }
                                    let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                    order.deductionMoney+=money;
                                    let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                    this.Foodcounlogs.push(log);
                                    //this.counMoney += ticket.EAT_PRICE
                                    autotype=1;
                                    return true;
                                }      
                            }
                        })
                        if(autotype==0){
                            this.$message.warning("已存在抵扣或不符合该券的使用规则");
                            tickeClick=1;
                            return;
                        }
                    }else if(ticket.AutoIDKind==2){
                        let _ticheType=0;
                        this.memberorderInfo.Order_ConsumeList.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1 && order.deductionNumber<order.Eat_Number){
                                if(order.deductionMoney<order.Eat_CheckMoney){    
                                    if(ticket.KIND==2){
                                        ticket.EAT_PRICE=order.Eat_CheckPrice;
                                    }
                                    let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                    this.FoodticketList.push(food);
                                    order.deductionNumber++;

                                    //将单个商品券的使用记录下来
                                    let money=order.Eat_CheckMoney-order.deductionMoney;
                                    if(money>=ticket.EAT_PRICE){
                                        money=ticket.EAT_PRICE;
                                    }
                                    let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                    order.deductionMoney+=money;
                                    let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                    this.Foodcounlogs.push(log);
                                    //this.counMoney += ticket.EAT_PRICE
                                    _ticheType=1;
                                    return true;
                                } 
                            }
                        })
                        if(_ticheType==0){
                            this.$message.warning("已存在抵扣或不符合该券的使用规则");
                            tickeClick=1;
                            return true;
                        }
                    }else if(ticket.AutoIDKind==3){
                        let _orderType=0;
                        this.memberorderInfo.Order_ConsumeList.some((order,index)=>{
                            if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                                // tickeClick=1
                            }else{
                                if(_orderType==0){
                                    if(order.deductionNumber<order.Eat_Number){
                                        if(order.deductionMoney<order.Eat_CheckMoney){
                                            if(ticket.KIND==2){
                                                ticket.EAT_PRICE=order.Eat_CheckPrice;
                                            }
                                            let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice: ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                            this.FoodticketList.push(food);
                                            //this.counMoney += ticket.EAT_PRICE
                                            order.deductionNumber++;
                                            //将单个商品券的使用记录下来
                                            let money=order.Eat_CheckMoney-order.deductionMoney;
                                            if(money>=ticket.EAT_PRICE)                                                                                                                                   {
                                                money=ticket.EAT_PRICE;
                                            }
                                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                            order.deductionMoney+=money;
                                            let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                            this.Foodcounlogs.push(log);
                                            _orderType=1;
                                            return true;
                                        
                                        }     
                                    }
                                }
                            }
                        })
                        if(_orderType==0){
                            this.$message.warning("已存在抵扣或不符合该券的使用规则");
                            tickeClick=1;
                            return;
                        }
                    }
                }else if(ticket.KIND==3&&ticket.TK_ONETOMANY){//有面值券，且适用多个商品
                    /**1：指定菜品ID  2.指定菜品分类  3.排除菜品分类 */
                    if(ticket.AutoIDKind==1){
                        let autotype=0;
                        let UsableSumMoney=0;//适用当前券的总结算金额
                        this.memberorderInfo.Order_ConsumeList.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1){
                                UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                            }
                        })
                        if(UsableSumMoney>0){//适用当前券
                            ticket.maxnum=this.computeMaxCouponsNum(ticket);
                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                            let tickbalance=ticket.EAT_PRICE;//当前券可用余额
                            let lastindex=0;
                            let lastEat_ConsumeID='';
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                lastindex++;
                                if(tickbalance>0 && ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1 && (order.Eat_CheckMoney-order.deductionMoney)>0){
                                    if(tickbalance==ticket.EAT_PRICE && tickbalance<=(order.Eat_CheckMoney-order.deductionMoney)){
                                        order.deductionMoney+=tickbalance;
                                        tickbalance=0;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:ticket.EAT_PRICE};
                                        this.Foodcounlogs.push(log);
                                    }else{
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=tickbalance){
                                            money=tickbalance;
                                            tickbalance=0;
                                        }else{
                                            tickbalance=tickbalance-money;
                                        }
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money}
                                        this.Foodcounlogs.push(log);
                                    }
                                    if(tickbalance==0||(tickbalance>0&&lastindex==this.memberorderInfo.Order_ConsumeList.length)){
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food);
                                        autotype=1;
                                        return true;
                                    }else{
                                        lastEat_ConsumeID=order.Eat_ConsumeID;
                                    }
                                }
                            })
                            if(lastEat_ConsumeID!=''){
                                let food={Eat_ConsumeID:lastEat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                this.FoodticketList.push(food);
                                autotype=1
                            }
                        }
                        if(autotype==0){
                            this.$message.warning("已存在抵扣或不符合该券的使用规则");
                            tickeClick=1;
                            return;
                        }
                    }else if(ticket.AutoIDKind==2){
                        let _ticheType=0;
                        let UsableSumMoney=0;//适用当前券的总结算金额
                        this.memberorderInfo.Order_ConsumeList.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                                UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                            }
                        })
                        if(UsableSumMoney>0){
                            ticket.maxnum=this.computeMaxCouponsNum(ticket);
                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                            let tickbalance=ticket.EAT_PRICE;//当前券可用余额
                            let lastindex=0;
                            let lastEat_ConsumeID='';
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                lastindex++;
                                if(tickbalance>0 && ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1 && (order.Eat_CheckMoney-order.deductionMoney)>0){
                                    if(tickbalance==ticket.EAT_PRICE &&  tickbalance<=(order.Eat_CheckMoney-order.deductionMoney)){
                                        order.deductionMoney+=tickbalance;
                                        tickbalance=0;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:ticket.EAT_PRICE};
                                        this.Foodcounlogs.push(log);
                                    }else{
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=tickbalance){
                                            money=tickbalance;
                                            tickbalance=0;
                                        }else{
                                            tickbalance=tickbalance-money;
                                        }
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    if(tickbalance==0||(tickbalance>0&&lastindex==this.memberorderInfo.Order_ConsumeList.length)){
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food);
                                        _ticheType=1;
                                        return true;
                                    }else{
                                        lastEat_ConsumeID=order.Eat_ConsumeID;
                                    }
                                }
                            })
                            if(lastEat_ConsumeID!=''){
                                let food={Eat_ConsumeID:lastEat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                this.FoodticketList.push(food);
                                _ticheType=1
                            }              
                        }
                        if(_ticheType==0){
                            this.$message.warning("已存在抵扣或不符合该券的使用规则");
                            tickeClick=1
                            return true;
                        }
                    }else if(ticket.AutoIDKind==3){
                        let _orderType=0;
                        let UsableSumMoney=0;//适用当前券的总结算金额
                        this.memberorderInfo.Order_ConsumeList.some((order,index)=>{
                            if(ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1){
                                UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                            }
                        })
                        if(UsableSumMoney>0){
                            ticket.maxnum=this.computeMaxCouponsNum(ticket);
                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                            let tickbalance=ticket.EAT_PRICE;//当前券可用余额
                            let lastindex=0;
                            let lastEat_ConsumeID='';
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                lastindex++;
                                if(tickbalance>0 && ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1 && (order.Eat_CheckMoney-order.deductionMoney)>0){
                                    if(tickbalance==ticket.EAT_PRICE &&  tickbalance<=(order.Eat_CheckMoney-order.deductionMoney)){
                                        order.deductionMoney+=tickbalance;
                                        tickbalance=0;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:ticket.EAT_PRICE};
                                        this.Foodcounlogs.push(log);
                                    }else{
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=tickbalance) {
                                            money=tickbalance;
                                            tickbalance=0;
                                        }else{
                                            tickbalance=tickbalance-money;
                                        }
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    if(tickbalance==0||(tickbalance>0&&lastindex==this.memberorderInfo.Order_ConsumeList.length)){
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food);
                                        _orderType=1
                                        return true;
                                    }else{
                                        lastEat_ConsumeID=order.Eat_ConsumeID;
                                    }
                                }
                            })
                            if(lastEat_ConsumeID!=''){
                                let food={Eat_ConsumeID:lastEat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                this.FoodticketList.push(food);
                                _orderType=1;
                            }                        
                            if(_orderType==0){
                                this.$message.warning("已存在抵扣或不符合该券的使用规则");
                                tickeClick=1;
                                return true;
                            }
                        }
                        else{
                            tickeClick=1
                        }
                        if(tickeClick==1){
                            this.$message.warning("已存在抵扣或不符合该券的使用规则");
                            return true;
                        }
                    }
                }
            }
            if(tickeClick==0){
                this.tickPitch(ticket)
            }

        },
        //券的选中判断
        tickPitch(ticket){
            if(ticket._selected){
                let index=this.selectTicketList.findIndex(t=>t.TK_AUTOID==ticket.TK_AUTOID && t.EAT_PRICE==ticket.EAT_PRICE && t.EAT_SALEPRICE==ticket.EAT_SALEPRICE);
                if(index>=0){
                    this.selectTicketList.splice(index,1);
                    if(ticket.KIND!=1){//取消选中，找出这个券对应的log日志，逐次还原
                        let logsList = this.Foodcounlogs.filter(item => item.TK_AUTOID == ticket.TK_AUTOID).sort((a, b) => b.logtime - a.logtime);
                        logsList.forEach((loginfo) => {
                            this.memberorderInfo.Order_ConsumeList.forEach(item => {
                                if(item.deductionMoney>0&&loginfo.Eat_ConsumeID==item.Eat_ConsumeID){
                                    item.deductionMoney=item.deductionMoney-loginfo.deductionMoney;
                                }
                            });
                        });
                        this.Foodcounlogs=this.Foodcounlogs.filter(item => item.TK_AUTOID != ticket.TK_AUTOID);
                        this.counMoney =this.counMoney - ticket.EAT_PRICE;
                        for(let i=0; i<this.FoodticketList.length; i++){
                            if(this.FoodticketList[i].TK_AUTOID==ticket.TK_AUTOID){
                                this.memberorderInfo.Order_ConsumeList.some(order=>{
                                    if(ticket.KIND==3&&ticket.TK_ONETOMANY){
                                        // order.deductionNumber=0;
                                    }else{
                                        if(order.deductionNumber>0 && order.Eat_ConsumeID==this.FoodticketList[i].Eat_ConsumeID){
                                            if(order.deductionNumber>0 && order.deductionNumber<1){
                                                order.deductionNumber = this.accSub(order.deductionNumber,order.deductionNumber);
                                            }else{
                                                order.deductionNumber = this.accSub(1,order.deductionNumber);
                                            }
                                            
                                            return true;
                                        }
                                    }
                                })
                                this.FoodticketList.splice(i, 1);
                                i--;
                            }
                        }
                        this.selectFood=''
                    }
                }
            }else{
                ticket.number=1;
                this.CouNumber = ticket.number
                this.selectTicketList.push(ticket);
            }
            ticket._selected=!ticket._selected;
            
            console.log(this.Foodcounlogs);
            console.log(this.memberorderInfo.Order_ConsumeList);
            console.log(this.FoodticketList);
            this.selectTicketList.forEach(order=>{
                if(order.KIND==3&&order.TK_ONETOMANY){
                    order.maxnum=this.computeMaxCouponsNum(order);
                }
            })
            
        },
        chekckIndex(item){
            var isexit=false;
            isexit=this.selectTicketList.some(order=>{
                if(order.KIND==3 && order.TK_ONETOMANY){
                    return true;
                }
            })
            if(isexit){
                let index=this.selectTicketList.findIndex(t=>t.TK_AUTOID==item.TK_AUTOID);
                if(index!=this.selectTicketList.length-1){//当操作的不是最后一个时;
                    
                    this.$alert("修改当前券会导致使用顺序发生变化,如需修改,请重新分配券的使用", "提示", {confirmButtonText: "确定"});
                    return true;
                }
            }
        },
        /**数量增加/减少 */
        setNumber(item,type,keyNumber,methshow){
            if(this.chekckIndex(item)){
                return 1
            }
            if(type>0 || type==2){//加
                if(item.KIND==2 ||(item.KIND==3&&!item.TK_ONETOMANY) ){
                    let _foodnumber = this.foodtickeAdd(item,type,keyNumber)
                    if(_foodnumber==0){
                        this.$message.warning("已抵扣或已达到本次订单最多可使用券的次数");
                        return 1
                    }
                    if(_foodnumber==2){
                        this.$message.warning("超过本次订单最多可使用商品券的次数,请重新去分配券的使用");
                        return 1
                    }
                }else if(item.KIND==3 && item.TK_ONETOMANY){
                    let _foodnumber = this.computeMaxCouponsNum(item)
                    if(Number(keyNumber) >_foodnumber){
                        this.$message.warning("已抵扣或已达到本次订单最多可使用券的次数");
                        return 1
                    }
                    this.foodtickeAddByMoney(item,type,keyNumber);
                }
                else{
                    if(methshow==false){
                        item.number =this.addNum(1,item.number)
                    }
                }
            }else{//减
                if(item.number>1){
                    let sometype=0,_keyNumber=Number(keyNumber)
                    //找到选择的券
                    let _number= _keyNumber
                    for(let i=0;i< _keyNumber;i++){
                        this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                            if(_number>0){
                                this.FoodticketList.some((food,i)=>{
                                    if(order.Eat_ConsumeID==food.Eat_ConsumeID && food.TK_AUTOID==item.TK_AUTOID && _number>0&&order.deductionNumber>0){
                                        if(item.KIND==3&&item.TK_ONETOMANY){
                                            //order.deductionNumber==0
                                        }
                                        else{
                                            if(order.deductionNumber<1 && order.deductionNumber>0){
                                                order.deductionNumber=1
                                            }
                                            order.deductionNumber= this.accSub(1,order.deductionNumber)
                                            if(order.deductionNumber==0){
                                                return true
                                            }
                                        }
                                        this.FoodticketList.splice(i,1)
                                        i--
                                        _number--
                                    }
                                })
                            }
                        })
                    }
                    //找到选择的券
                    for(let i=0;i< _keyNumber;i++){
                        //根据日志将操作的菜品倒回 首先找到logtime最大值
                        let maxLogtime = Math.max(...this.Foodcounlogs.filter(x=>x.TK_AUTOID===item.TK_AUTOID).map(it => it.logtime));
                        // 然后找到对应的对象
                        let logsList = this.Foodcounlogs.filter(item => item.logtime === maxLogtime);
                        logsList.forEach((loginfo) => {
                            this.memberorderInfo.Order_ConsumeList.forEach(item => {
                                if(item.deductionMoney>0&&loginfo.Eat_ConsumeID==item.Eat_ConsumeID){
                                    item.deductionMoney=item.deductionMoney-loginfo.deductionMoney;
                                }
                            });
                        });
                        this.Foodcounlogs=this.Foodcounlogs.filter(item => item.logtime != maxLogtime);
                    }
                    if(!keyNumber){
                        item.number = this.accSub(1,item.number)
                        this.counMoney = this.accSub(item.EAT_PRICE,this.counMoney)
                    }else{
                        item.number = this.accSub(Number(keyNumber),item.number)
                        this.counMoney =this.counMoney - item.EAT_PRICE * Number(keyNumber)
                    }
                }else{//移除
                    this.onTicket(item,2)
                }
            }
            this.selectTicketList.forEach(order=>{
                if(order.KIND==3&&order.TK_ONETOMANY){
                    order.maxnum=this.computeMaxCouponsNum(order);
                }
            })
            this.CouNumber = item.number
        },
        foodtickeAdd(ticket,type,keyNumber){
            let retNumber=0
            /**1：指定菜品ID  2.指定菜品分类  3.排除菜品分类 */
            /**1：指定菜品ID  2.指定菜品分类  3.排除菜品分类 */
            if(ticket.AutoIDKind==1){
                this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                    if(Number(keyNumber)>0){
                        if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1 && Number(keyNumber)>=1){
                            if(order.deductionMoney>=order.Eat_CheckMoney){//肯能存在已经抵扣过
                                return;
                            }
                            let deduNumber = this.accSub(order.deductionNumber,order.Eat_Number);
                            if(deduNumber<=0){
                                return;
                            }
                            if(ticket.KIND==2){
                                ticket.EAT_PRICE=order.Eat_CheckPrice
                            }
                            if(type==2){
                                ticket.number=this.addNum(1,ticket.number)
                            }
                            if(Number(keyNumber)>0){
                                console.log(deduNumber);
                                if(deduNumber>Number(keyNumber)){
                                    order.deductionNumber = this.addNum(Number(keyNumber),order.deductionNumber);
                                    for(let index = 0; index < Number(keyNumber); index++) {
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food);

                                        //将单个商品券的使用记录下来
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=ticket.EAT_PRICE){
                                            money=ticket.EAT_PRICE;
                                        }
                                        let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                       
                                    }
                                    keyNumber = 0
                                }else{
                                    keyNumber = this.accSub(deduNumber,Number(keyNumber));
                                    let _tempNumber=order.deductionNumber;
                                    order.deductionNumber =order.Eat_Number;
                                    for(let index = 0; index < deduNumber; index++) {
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food);

                                        //将单个商品券的使用记录下来
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=ticket.EAT_PRICE){
                                            money=ticket.EAT_PRICE;
                                        }
                                        let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    let _ordertempNumber=this.accSub(_tempNumber,order.deductionNumber);
                                    if(_ordertempNumber>0 && _ordertempNumber<=1){
                                        _ordertempNumber=1;
                                    }
                                }
                            }
                            retNumber = 1
                            return true
                        }
                    }
                })
            }else if(ticket.AutoIDKind==2){
                this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                    if(Number(keyNumber)>0){
                        if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1  && Number(keyNumber)>=1){      
                            if(order.deductionMoney>=order.Eat_CheckMoney){//肯能存在已经抵扣过
                                return;
                            }      
                            let deduNumber = this.accSub(order.deductionNumber,order.Eat_Number);
                            if(deduNumber<=0){
                                return;
                            }              
                            if(ticket.KIND==2){
                                ticket.EAT_PRICE=order.Eat_CheckPrice
                            }
                            if(type==2){
                                ticket.number=this.addNum(1,ticket.number)
                            }
                            if(Number(keyNumber)>0){
                                if(deduNumber>Number(keyNumber)){
                                    order.deductionNumber = this.addNum(Number(keyNumber),order.deductionNumber);
                                    for(let index = 0; index < Number(keyNumber); index++) {
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food)

                                        //将单个商品券的使用记录下来
                                        let money=order.Eat_CheckMoney-order.deductionMoney
                                        if(money>=ticket.EAT_PRICE){
                                            money=ticket.EAT_PRICE;
                                        }
                                        let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    keyNumber = 0
                                }else{
                                    keyNumber = this.accSub(deduNumber,Number(keyNumber));
                                    let _tempNumber=order.deductionNumber;
                                    order.deductionNumber =order.Eat_Number;
                                    for(let index = 0; index < deduNumber; index++) {
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                        this.FoodticketList.push(food)
                                        //将单个商品券的使用记录下来
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=ticket.EAT_PRICE){
                                            money=ticket.EAT_PRICE;
                                        }
                                        let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    let _ordertempNumber=this.accSub(_tempNumber,order.deductionNumber);
                                    if(_ordertempNumber>0 && _ordertempNumber<=1){
                                        _ordertempNumber=1;
                                    }
                                }
                            }
                            retNumber = 1;
                            return true
                        }
                    }
                })
            }else if(ticket.AutoIDKind==3){
                this.memberorderInfo.Order_ConsumeList.forEach(order=>{
                    if(Number(keyNumber)>0){
                        if(ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1  && Number(keyNumber)>=1){   
                            if(order.deductionMoney>=order.Eat_CheckMoney){//肯能存在已经抵扣过
                                return;
                            }  
                            let deduNumber = this.accSub(order.deductionNumber,order.Eat_Number);
                            if(deduNumber<=0){
                                return;
                            } 
                            if(ticket.KIND==2){
                                ticket.EAT_PRICE=order.Eat_CheckPrice;
                            }
                            if(type==2){
                                ticket.number=this.addNum(1,ticket.number);
                            }
                            if(Number(keyNumber)>0){
                                if(deduNumber>Number(keyNumber)){
                                    order.deductionNumber = this.addNum(Number(keyNumber),order.deductionNumber);
                                    for(let index = 0; index < Number(keyNumber); index++) {
                                       let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                       this.FoodticketList.push(food);
                                        //将单个商品券的使用记录下来
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=ticket.EAT_PRICE){
                                            money=ticket.EAT_PRICE;
                                        }
                                        let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    keyNumber = 0
                                }else{
                                    keyNumber = this.accSub(deduNumber,Number(keyNumber));
                                    let _tempNumber=order.deductionNumber
                                    order.deductionNumber =order.Eat_Number
                                    for(let index = 0; index < deduNumber; index++) {
                                       let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID};
                                       this.FoodticketList.push(food)
                                        //将单个商品券的使用记录下来
                                        let money=order.Eat_CheckMoney-order.deductionMoney;
                                        if(money>=ticket.EAT_PRICE){
                                            money=ticket.EAT_PRICE;
                                        }
                                        let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money};
                                        this.Foodcounlogs.push(log);
                                    }
                                    let _ordertempNumber=this.accSub(_tempNumber,order.deductionNumber);
                                    if(_ordertempNumber>0 && _ordertempNumber<=1){
                                        _ordertempNumber=1
                                    }
                                }
                            }
                            retNumber = 1
                            return true
                        }
                    }
                })
            }
            return retNumber
        },
        conputeonecouponsnum(ticket){//计算单张券还可以用几张
            var _num=0;
            if(ticket==2||(ticket.KIND==3&&!ticket.TK_ONETOMANY)){
                if(ticket.AutoIDKind==1){
                    this.memberorderInfo.Order_ConsumeList.some(order=>{
                        if(order.deductionMoney==order.Eat_CheckMoney&&order.Eat_Number!=order.deductionNumber){
                            _num+=this.accSub(order.deductionNumber,order.Eat_Number);
                        }
                    })
                }else if(ticket.AutoIDKind==2){
                    this.memberorderInfo.Order_ConsumeList.some(order=>{
                        if(order.deductionMoney==order.Eat_CheckMoney&&order.Eat_Number!=order.deductionNumber){
                            _num+=this.accSub(order.deductionNumber,order.Eat_Number);
                        }
                    })
                }else if(ticket.AutoIDKind==3){
                    this.memberorderInfo.Order_ConsumeList.some((order)=>{
                        if(ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1){
                            if(order.deductionMoney==order.Eat_CheckMoney&&order.Eat_Number!=order.deductionNumber) {
                                _num+=this.accSub(order.deductionNumber,order.Eat_Number);
                            }
                        }
                    })
                }            
            }
            return _num;
        },
        foodtickeAddByMoney(ticket,type,keyNumber){
            let retNumber=0
            if(ticket.AutoIDKind==1){
                let UsableSumMoney=0;//适用当前券的总结算金额
                this.memberorderInfo.Order_ConsumeList.some(order=>{
                    if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1){
                        UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                    }
                })
                if(UsableSumMoney>0&&Number(keyNumber)>0){
                    if(type==2){
                        ticket.number=this.addNum(1,ticket.number)
                    }
                    for (let i = 1; i <= Number(keyNumber); i++) {
                        //  setTimeout(() => {//延迟10ms
                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                            let tickbalance=ticket.EAT_PRICE;//当前券可用余额
                            let lastindex=0;
                            let lastEat_ConsumeID='';
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                lastindex++;
                                if(tickbalance>0 && ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1 && (order.Eat_CheckMoney-order.deductionMoney)>0){
                                    if(tickbalance==ticket.EAT_PRICE && tickbalance<=(order.Eat_CheckMoney-order.deductionMoney)){
                                        order.deductionMoney+=tickbalance;
                                        tickbalance=0;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:ticket.EAT_PRICE}
                                        this.Foodcounlogs.push(log);
                                    }
                                    else{
                                        let money=order.Eat_CheckMoney-order.deductionMoney
                                        if(money>=tickbalance){
                                            money=tickbalance;
                                            tickbalance=0;
                                        }
                                        else{
                                            tickbalance=tickbalance-money;
                                        }
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money}
                                        this.Foodcounlogs.push(log);
                                    }
                                    if(tickbalance==0||(tickbalance>0&&lastindex==this.memberorderInfo.Order_ConsumeList.length)){
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                        this.FoodticketList.push(food)
                                        retNumber = 1
                                        return true
                                    }
                                    else{
                                        lastEat_ConsumeID=order.Eat_ConsumeID;
                                    }
                                }
                            })
                            if(lastEat_ConsumeID!=''){
                                let food={Eat_ConsumeID:lastEat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                this.FoodticketList.push(food)
                                retNumber = 1
                            }
                        //  }, 10);
                    }
                }
            }else if(ticket.AutoIDKind==2){
                let UsableSumMoney=0;//适用当前券的总结算金额
                this.memberorderInfo.Order_ConsumeList.some(order=>{
                    if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                        UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                    }
                })
                if(UsableSumMoney>0 && Number(keyNumber)>0) {
                    if(type==2){
                        ticket.number=this.addNum(1,ticket.number)
                    }
                    for (let i = 1; i <= Number(keyNumber); i++) {
                        //  setTimeout(() => {//延迟10ms
                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                            let tickbalance=ticket.EAT_PRICE;//当前券可用余额
                            let lastindex=0;
                            let lastEat_ConsumeID='';
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                lastindex++;
                                if(tickbalance>0 && ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1 && (order.Eat_CheckMoney-order.deductionMoney)>0){
                                    if(tickbalance==ticket.EAT_PRICE && tickbalance<=(order.Eat_CheckMoney-order.deductionMoney)){
                                        order.deductionMoney+=tickbalance;
                                        tickbalance=0;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:ticket.EAT_PRICE}
                                        this.Foodcounlogs.push(log);
                                    }
                                    else{
                                        let money=order.Eat_CheckMoney-order.deductionMoney
                                        if(money>=tickbalance){
                                            money=tickbalance;
                                            tickbalance=0;
                                        }else{
                                            tickbalance=tickbalance-money;
                                        }
                                        order.deductionMoney+=money;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money}
                                        this.Foodcounlogs.push(log);
                                    }
                                    if(tickbalance==0||(tickbalance>0&&lastindex==this.memberorderInfo.Order_ConsumeList.length)) {
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                        this.FoodticketList.push(food)
                                        retNumber = 1
                                        return true;
                                    }else{
                                        lastEat_ConsumeID=order.Eat_ConsumeID;
                                    }
                                }
                            })
                            if(lastEat_ConsumeID!=''){
                                let food={Eat_ConsumeID:lastEat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                this.FoodticketList.push(food)
                                retNumber = 1
                            }   
                        //  }, 10);
                    }       
                }
            }else if(ticket.AutoIDKind==3){
                let UsableSumMoney=0;//适用当前券的总结算金额
                this.memberorderInfo.Order_ConsumeList.some((order,index)=>{
                    if(ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1){
                        UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                    }
                })
                if(UsableSumMoney>0 && Number(keyNumber)>0){
                    if(type==2){
                        ticket.number=this.addNum(1,ticket.number)
                    }
                    for (let i = 1; i <= Number(keyNumber); i++) {
                        //  setTimeout(() => {//延迟10ms
                            let timespan=this.generateNextId();//定义一个时间戳，用来记录这次券的操作
                            let tickbalance=ticket.EAT_PRICE;//当前券可用余额
                            let lastindex=0;
                            let lastEat_ConsumeID='';
                            this.memberorderInfo.Order_ConsumeList.some(order=>{
                                lastindex++
                                if(tickbalance>0 && ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1 && (order.Eat_CheckMoney-order.deductionMoney)>0){
                                    if(tickbalance==ticket.EAT_PRICE && tickbalance<=(order.Eat_CheckMoney-order.deductionMoney)){
                                        order.deductionMoney+=tickbalance;
                                        tickbalance=0;
                                        let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:ticket.EAT_PRICE}
                                        this.Foodcounlogs.push(log);
                                    }else{
                                        let money=order.Eat_CheckMoney-order.deductionMoney
                                            if(money>=tickbalance){
                                                money=tickbalance;
                                                tickbalance=0;
                                            }else{
                                                tickbalance=tickbalance-money;
                                            }
                                            order.deductionMoney+=money;
                                            let log={logtime:timespan,Eat_ConsumeID:order.Eat_ConsumeID,TK_AUTOID:ticket.TK_AUTOID,deductionMoney:money}
                                            this.Foodcounlogs.push(log);
                                    }
                                    if(tickbalance==0||(tickbalance>0&&lastindex==this.memberorderInfo.Order_ConsumeList.length)) {
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                        this.FoodticketList.push(food)
                                        retNumber = 1
                                        return true;
                                    }else{
                                        lastEat_ConsumeID=order.Eat_ConsumeID;
                                    }
                                }
                            })
                            if(lastEat_ConsumeID!=''){
                                let food={Eat_ConsumeID:lastEat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                this.FoodticketList.push(food)
                                retNumber = 1
                            }
                        //  }, 10);
                    }
                }
            }
            return retNumber;
        },
        generateNextId(){
            this.currentId = (this.currentId + 1) % 10000000000;
            return this.currentId;
        },
        computeMaxCouponsNum(ticket){
            let retNumber=0
            if(ticket.AutoIDKind==1){
                let UsableSumMoney=0;//适用当前券的总结算金额
                this.memberorderInfo.Order_ConsumeList.some(order=>{
                    if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1){
                        UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                    }
                })
                if(UsableSumMoney>0&&ticket.EAT_PRICE){
                    retNumber=Math.ceil(UsableSumMoney/ticket.EAT_PRICE);
                }
            }
            if(ticket.AutoIDKind==2){
                let UsableSumMoney=0;//适用当前券的总结算金额
                this.memberorderInfo.Order_ConsumeList.some(order=>{
                    if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                        UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                    }
                })
                if(UsableSumMoney>0&&ticket.EAT_PRICE){
                    retNumber=Math.ceil(UsableSumMoney/ticket.EAT_PRICE);
                }
            }
            if(ticket.AutoIDKind==3){
                var UsableSumMoney=0;//适用当前券的总结算金额
                this.memberorderInfo.Order_ConsumeList.some((order,index)=>{
                    if(ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1){
                        UsableSumMoney+=order.Eat_CheckMoney-order.deductionMoney;//结算金额-已抵扣的金额
                    }
                })
                if(UsableSumMoney>0&&ticket.EAT_PRICE){
                    retNumber=Math.ceil(UsableSumMoney/ticket.EAT_PRICE);
                }
            }
            return retNumber;
        },
        hide(){
            this.$emit("closeModel")
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return  Number(((arg2*m-arg1*m)/m).toFixed(n));
        },
        addNum(arg1, arg2) {
            var r1, r2, m, c;
            try {
                r1 = arg1.toString().split(".")[1].length;
            } catch (e) {
                r1 = 0;
            }
            try {
                r2 = arg2.toString().split(".")[1].length;
            } catch (e) {
                r2 = 0;
            }
            c = Math.abs(r1 - r2);
            m = Math.pow(10, Math.max(r1, r2));
            if (c > 0) {
                var cm = Math.pow(10, c);
                if (r1 > r2) {
                    arg1 = Number(arg1.toString().replace(".", ""));
                    arg2 = Number(arg2.toString().replace(".", "")) * cm;
                } else {
                    arg1 = Number(arg1.toString().replace(".", "")) * cm;
                    arg2 = Number(arg2.toString().replace(".", ""));
                }
            } else {
                arg1 = Number(arg1.toString().replace(".", ""));
                arg2 = Number(arg2.toString().replace(".", ""));
            }
            return (arg1 + arg2) / m;
        },
        /**确定和回车事件 */
        confirm(){ 
            if(this.selectTicketList.length==0){
                this.$message.warning('请先选择'+this.data?.PAY_NAME+"!");
                return;
            }
            let sltype=false;
            sltype=this.selectTicketList.some(sl=>{
                if(!sl.number || sl.number==0){
                    return true
                }
            })
            if(sltype==true){
                this.$message.warning('券的数量不能为0或者空');
                return;
            }
            let couponAmounts=this.CheckOneCouponUseMoney({});
            if(Object.keys(couponAmounts).length>0){
                let OverChargeMoney=0
                this.selectTicketList.map(tick=>{
                    let totalAmount = couponAmounts[tick.TK_AUTOID];
                    if(Number(tick.EAT_PRICE) * tick.number >= totalAmount){
                        OverChargeMoney += Number(tick.EAT_PRICE) * tick.number - totalAmount
                    }
                })
                if(OverChargeMoney!=0){
                    this.$confirm('您确定使用券抵扣吗?本次多收券:'+ Number(OverChargeMoney).toFixed(2)+'', '提示',{
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'})
                    .then(() => {
                        this.PayPreview()
                    }).catch(()=>{});
                }else{
                    if(this.counMoney<=Number(this.memberorderInfo.UnPaid_Money)){
                        this.$confirm('您确定使用券抵扣吗?', '提示',{
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning'})
                        .then(() => {
                            this.PayPreview()
                        }).catch(()=>{});
                    }
                }
            }else{
                if(this.counMoney<=Number(this.memberorderInfo.UnPaid_Money)){
                    this.$confirm('您确定使用券抵扣吗?', '提示',{
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'})
                    .then(() => {
                        this.PayPreview()
                    }).catch(()=>{});
                }
                if(this.counMoney>Number(this.memberorderInfo.UnPaid_Money)){
                    let moreCouponsMoney = this.counMoney - Number(this.memberorderInfo.UnPaid_Money)
                    this.$confirm('您确定使用券抵扣吗?本次多收券:'+ Number(moreCouponsMoney).toFixed(2)+'', '提示',{
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'})
                    .then(() => {
                        this.PayPreview()
                    }).catch(()=>{});
                }
            }
            
        },
        CheckOneCouponUseMoney(couponAmounts){
            this.totalamount=0;
            // 统计每张券抵扣的总金额
            this.Foodcounlogs.forEach(log => {
                let couponId = log.TK_AUTOID;
                let amount = log.deductionMoney;
                this.totalamount+=amount;
                if (couponAmounts[couponId]) {
                    couponAmounts[couponId] += amount;
                } else {
                    couponAmounts[couponId] = amount;
                }
            });
            return couponAmounts;
        },
        /**支付预览 */
        PayPreview(){
            /**支付方式 */
            let _payment=[]
            let _unpaiMoney = Number(this.memberorderInfo.UnPaid_Money)
            let couponAmounts=this.CheckOneCouponUseMoney({});
            // let isequal=false;//券的多收的金额从新计算，之前都会算到最后一张上，现在如果两金额一值，就用券日志中的金额去计算
            // if((this.counMoney-Number(this.totalamount))==(this.counMoney - Number(this.memberorderInfo.UnPaid_Money)))
            // {
            //     isequal=true
            // }
            this.selectTicketList.map(tick=>{
                let _choice={
                    Coupon_AutoId:tick.TK_AUTOID,
                    Coupon_Code:tick.TK_CODE,
                    Coupon_Name:tick.TK_NAME,
                    Coupon_Kind:tick.KIND,
                    Coupon_Num:tick.number,
                    Coupon_CostPrice:tick.EAT_SALEPRICE
                }
                let OverChargeMoney=0
                if(Object.keys(couponAmounts).length>0){
                    let totalAmount = couponAmounts[tick.TK_AUTOID];
                    if(Number(tick.EAT_PRICE) * tick.number >= totalAmount){
                        OverChargeMoney = Number(tick.EAT_PRICE) * tick.number - totalAmount
                    }
                }
                else{
                    if(Number(tick.EAT_PRICE) * tick.number >= _unpaiMoney){
                        OverChargeMoney = Number(tick.EAT_PRICE) * tick.number - _unpaiMoney
                        _unpaiMoney=0
                    }else{
                        _unpaiMoney = _unpaiMoney - Number(tick.EAT_PRICE) * tick.number
                    }
                }
                // if(isequal)
                // {
                //     let totalAmount = couponAmounts[tick.TK_AUTOID];
                //     if(Number(tick.EAT_PRICE) * tick.number >= totalAmount){
                //         OverChargeMoney = Number(tick.EAT_PRICE) * tick.number - totalAmount
                //     }
                // }
                // else{
                //     if(Number(tick.EAT_PRICE) * tick.number >= _unpaiMoney){
                //         OverChargeMoney = Number(tick.EAT_PRICE) * tick.number - _unpaiMoney
                //         _unpaiMoney=0
                //     }else{
                //         _unpaiMoney = _unpaiMoney - Number(tick.EAT_PRICE) * tick.number
                //     }
                // }
                _payment.push({
                    Payment_ID:this.data.PAY_ID,  //支付方式ID
                    Payment_Code:this.data.PAY_CODE, //支付方式编号
                    Payment_Name:this.data.PAY_NAME, //支付方式名称
                    Payment_Type:this.data.INTERFACE_TYPE, //支付方式类型
                    Payment_Money: Number(Number(Number(tick.EAT_PRICE) * tick.number).toFixed(2)), //支付金额
                    OverCharge_Money: Number(Number(OverChargeMoney).toFixed(2)),  //多收金额
                    Payment_FactMoney:0, //计营业额
                    Qr_Code:'', //扫码付款的码值
                    Card_No:'', //卡号
                    CouponInfo:_choice, //券的详情
                })
            })
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                UnionOrders:this.unionEatAutoID,//联台订单ID集合
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Eat_DeskName:this.memberorderInfo.Eat_DeskName, //桌牌号
                Service_Charge:this.memberorderInfo.Service_Money,  //服务费
                MantissaAdjustment_Money:this.memberorderInfo.Mantissa_Money,  //尾数调整金额
                Member:null,//会员信息
                Payments:_payment, //支付方式列表
            }
            this.$emit("publicPayPreview",param,'paymentCouponsShow')
        },
        showKeyBoard(obj){
            this.$keyBoard.show(obj,{isNumber:true,isKeyDownEnter:true,isFocusKeyBoard:true});
        },
    }
}
</script>

<style lang="sass">
@import "./paymentCouponsModel.scss";
</style>