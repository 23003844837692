<template>
    <div class="keyBoardInput" @click="openBoard">
        <input v-if="type=='password'" ref="input" v-model="val" type="password" :placeholder="placeholder" :maxlength="maxlength"
            @keydown="keydown" 
            @blur="blur" @focus="openBoard"
            :focusLock="focusLock"
        />
        <input-pattern v-else ref="input" v-model="val" :type="type" :placeholder="placeholder" :pattern="pattern" :min="min" :max="max" :dplaces="dplaces" 
            @keydown="keydown" 
            @blur="blur" @focus="openBoard"
            :readonly="readonly"
            :focusLock="focusLock"
        />
        <div class="jianpan" @click="clickJianPan()" @mousedown="(e)=>e.preventDefault()" v-if="!isFocusKeyBoard && !isClickKeyBoard">
            <span class="iconfont icon-jianpan"></span>
        </div>
    </div>
</template>

<script>
const resizeEvent={
    addResizeListener(el,fn){
        if(!el.__ro__){
            el.__ro__= new ResizeObserver(fn);
            el.__ro__.observe(el)
        }
    },
    removeResizeListener(el){
        if(el.__ro__){
            el.__ro__.unobserve(el);
        }
    }
}
export default {
    name:"keyBoardInput",
    emits:['update:modelValue',"keydown","barcode","change"],
    props:{
        modelValue: {
            type: [String, Number],
            default: ''
        },
        type:{
            type: String,
            default: 'text'
        },
        readonly:{
            type: Boolean,
            default: false
        },
        /**过滤类型 money 类型 正数 小数位不能超过2位  number //数字类型  phone手机号类型
         * 正则 /^/g.test()
        */
        pattern:[String,RegExp],
        /**最小范围 */
        min:{
            type:Number,
            default:null
        },
        /**最大范围 */
        max:{
            type:Number,
            default:null
        },
        /**小数位保留多少 类型只能是number时限制 默认不限制小数位*/
        dplaces:{
            type:Number,
            default:null
        },
        /**设置 字符串类型长度  默认不限制*/
        maxlength:{
            type:Number,
            default:null
        },
        /**input 提示 */
        placeholder:{
            type: String,
            default: ''
        },
        /**键盘配置
         * {
         *  isKeyDownEnter: 是否响应回车
         *  isNumber: 是否展现位数字键盘
         * }
        */
        keyOptions:{
            type: Object,
            default: null
        },
        /**是否获取焦点时打开写字板 */
        isBoard:{
            type:Boolean,
            default:false
        },
        /**是否失去焦点时关闭虚拟键盘 */
        isBlurClose:{
            type:Boolean,
            default:false
        },
        /**是否键盘输入回测时关闭虚拟键盘 */
        isKeyEnterClose:{
            type:Boolean,
            default:false
        },
        /**是否键盘输入回测时 失去焦点 */
        isKeyEnterBlur:{
            type:Boolean,
            default:false
        },
        /**是否启动检测 扫描枪输入 */
        isBarcode:{
            type:Boolean,
            default:false
        },
        /**是否 通过获取焦点启动键盘*/
        isFocusKeyBoard:{
            type:Boolean,
            default:false
        },
        /**是否 通过点击启动键盘*/
        isClickKeyBoard:{
            type:Boolean,
            default:false
        },
        focusLock:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            val:this.modelValue,
            //输入框监听 卡号
            inputCard:"",
            //第一次输入时间
            inputStartTime:0,
            // 输入间隔 800毫秒
            inoutSpaceTime:800,
            /**是否修改了数据 */
            isChange:false,
            /**记录上一次显示出键盘的记录 */
            oldIsShow:false,
            //是否看到见
            isVisible:false,
        }
    },
    watch:{
        modelValue(newVal){
            this.val=newVal;
            this.isChange=false;
        },
        val(newVal,oldVal){
            this.isChange=true;
            if(this.maxlength>0 && newVal.length>this.maxlength){
                this.val=newVal.substr(0,this.maxlength);
            }else{
                this.$emit('update:modelValue', this.val);
            }
            if(this.type!="text" || this.pattern=="money" || this.pattern=="number"){
                return;
            }
            if(!oldVal || (oldVal && (newVal||"").indexOf(oldVal)==0)){//监听输入速度
                let time=(new Date()).getTime();
                if(this.inputStartTime+this.inoutSpaceTime<time){
                    this.inputStartTime=time;
                    this.inputCard="";
                }
                let oldLeng=oldVal?.length||0;
                this.inputCard+=(newVal||"").substring(oldLeng);
            }
        },
        isVisible(newVal){
            if(!newVal){
                this.close(true);//看不见时关闭 键盘
            }else{
                setTimeout(()=>{
                    if(this.oldIsShow){
                        this.showKeyBoard();
                    }
                },0)
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            resizeEvent.addResizeListener(this.$el, (obj)=>{
                if(obj?.length>0 && obj[0].contentRect?.height==0 && obj[0].contentRect?.width==0){
                    this.isVisible=false;
                }else{
                    this.isVisible=true;
                }
            });
        })
        
    },
    //卸载前
    beforeUnmount() {
        this.close();
    },
    deactivated(){
        this.close();
    },
    methods:{
        getBoundingClientRect(){
            return (this.$refs.input?.$el||this.$el).getBoundingClientRect();
        },
        showKeyBoard(){
            let input;
            if(this.type=='password'){
                input=this.$refs.input;
            }else{
                input=this.$refs.input.$el;
            }
            //启动虚拟键盘
            this.$keyBoard.show(input,this.keyOptions);
        },
        clickJianPan(){
            let input;
            if(this.type=='password'){
                input=this.$refs.input;
            }else{
                input=this.$refs.input.$el;
            }
            if(this.$keyBoard.inputObj==input && this.$keyBoard.isShow){
                this.closeKeyBoard();
            }else{
                //启动虚拟键盘
                this.$keyBoard.show(input,this.keyOptions);
            }
        },
        closeKeyBoard(){//关闭虚拟键盘
            this.$keyBoard.close();
        },
        close(bo){
            let input;
            if(this.type=='password'){
                input=this.$refs.input;
            }else{
                input=this.$refs.input?.$el;
            }
           
            if(bo){
                if(!this.oldIsShow && this.$keyBoard.isShow && this.$keyBoard.inputObj==input){
                    this.oldIsShow=true;
                }
            }else{
                this.oldIsShow=false;
            }
            this.$keyBoard.close(input);
        },
        openBoard(){
            if(this.isFocusKeyBoard || this.isClickKeyBoard){
                this.showKeyBoard();//打开键盘
                this.selectAll();
            }
            else if(this.isBoard){//打开写字板
                this.$webBrowser.openInputBoard();
            }
        },
        focus(){
            let input;
            if(this.type=='password'){
                input=this.$refs.input;
            }else{
                input=this.$refs.input.$el;
            }
            input.focus()
        },
        selectAll(){
            let input;
            if(this.type=='password'){
                input=this.$refs.input;
            }else{
                input=this.$refs.input.$el;
            }
            input.select();
        },
        blur(){
            if(this.isBlurClose){
                this.closeKeyBoard()
            }
            if(this.isChange){
                this.$emit("change",this.val);

                if(this.val!=this.modelValue){
                    console.log(this.modelValue)
                    this.val=this.modelValue;
                }
            }
        },
        downEnter(){
            if(this.isKeyEnterClose){
                this.closeKeyBoard()
            }
            if(this.isKeyEnterBlur){
                this.$nextTick(()=>{
                    let input;
                    if(this.type=='password'){
                        input=this.$refs.input;
                    }else{
                        input=this.$refs.input.$el;
                    }
                    input.blur();
                })
                
            }
        },
        keydown(e){
            if(this.readonly) return;
            if(e.key== 'Enter'){
                
                if(this.isBarcode){//是否启动检测 扫描枪输入 
                    console.log(e)
                    let time=(new Date()).getTime();
                    if(this.inputCard?.length>=5 && this.inputStartTime+this.inoutSpaceTime>time){//扫描枪输入
                        console.log("扫描枪输入 :"+this.inputCard)
                        this.inputStartTime=time;
                        this.inputCard="";
                        this.val=this.inputCard.trim();
                        this.$emit("barcode",e)
                        return;
                    }
                    this.inputStartTime=time;
                    this.inputCard="";
                }

                this.downEnter();
            }
            this.$emit("keydown",e)
        },
    }
}
</script>

<style lang="scss">
.keyBoardInput{
    position: relative;

    input{
        width: 100%;
        padding:0 8px;
    }

    .jianpan{
        position: absolute;
        right: 10px;
        top: 0px;
        font-size:16px;
        color: #BCBDBE;
    }

}
</style>