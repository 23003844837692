<template>
<modal class="changeing" :isShow="openischangeShow" @keyup.esc="closeClicks">
<!-- <modal class="OpendingPopup" :isShow="openischangeShow"> -->
        <div class="Popup-Title">
          <span class="Popup-Title-left">换台操作</span>
          <div class="float-left search">
            <div class="DeskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                <span class="iconfont icon-jianpan"></span>
            </div>
            <input type="text" placeholder="桌号检索" v-model="deskAutoSelce" ref="searchBox" @blur="searchInputBlur"  />
          </div>
        </div>
        <div class="filter-nav clearfix">
            <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage'}">
                <div class="nav-li" :class="{ selected: filTernav == -1 }" @click="navigationClick(-1)" >全部</div>
                <div class="nav-li" :class="{ selected: filTernav == index }" v-for="(item,index) in posRooms" :key="item"  @click="navigationClick(index)" >
                    {{item.Room_Name}}
                </div>
            </div>
            <div class="float-right page-box">
                <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
            </div>
        </div>
        <div class="desk content clearfix" style="width: 100%;height: 40%; overflow: auto;">
            <div v-if="deskList">
                <div class="desk-box" v-show="item.Is_Desk_Locked==false" :class="{ 'empty': item.Desk_Status== 0,'opening':item.Desk_Status==1,'reserve':item.Desk_Status==2,'preKnot':item.Desk_Status==3}" v-for="item in deskList" :key="item" @click="deskChangeClick(item)" >
                    <div class="top" v-if="item.OpenDeskInfo" >
                        <span>{{item.OpenDeskInfo.Person_Num}}人</span>
                        <span class="float-right">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span> 
                    </div>
                    <p class="title">{{item.Desk_Name}}</p>
                    <div class="bottom">
                    <span v-if="item.OpenDeskInfo">{{differenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> 
                    <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_CheckMoney}}</span>
                    </div>
                </div> 
            </div>
        </div>
        <div class="Popup-InterTxt">
            <span>如下台号将交换,请确认桌台号</span>
        </div>

        <div class="desk content clearfix" style="width: 100%;height: 20%;">
            <div class="desk-box" v-show="item.Is_Desk_Locked==false" :class="{'opening':item.Desk_Status==1,'reserve':item.Desk_Status==2,'preKnot':item.Desk_Status==3}" v-for="item in posDeskList" :key="item" @click="revokeDeskChangeClick()">
                <div class="top">
                    <span v-if="item.OpenDeskInfo">{{item.OpenDeskInfo.Person_Num}}人</span>  <!--人数-->
                    <span class="float-right" v-if="item.OpenDeskInfo">{{item.OpenDeskInfo.Crossed_FoodCounts}}/{{item.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
                </div>
                <p class="title">{{item.Desk_Name}}</p>
                <div class="bottom" v-if="item.OpenDeskInfo">
                  <span >{{differenceTime(item.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                  <span class="float-right" v-if="item.OpenDeskInfo && MapConsumeMoney==false">￥{{item.OpenDeskInfo.Eat_SaleMoney}}</span> <!--点餐总金额-->
                </div>
            </div>
        </div>
        <div class="Popup-Bsic-bottom">
            <button class="Popup-Basic-left-bottom" @click="closeClicks()" >取消</button>
            <button class="Popup-Basic-right-bottom" @click="deteClicks()">确定</button>
        </div>
        <analog-key-board ref="keyBoard" ></analog-key-board>
    </modal>
</template>

<script>
import modal from "../../../template/modal/modal";
import { httpAES,cacheData } from '/src/services'
import { differenceTime } from '/src/common';

/**全局初始化数据 */
let _data={
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
};

export default {
    name: 'changeing',
    components:{
        modal
    },
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
        }
        return {
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            filTernav:-1,
            deskAutoSelce:'', //查询条件栏
            showPosDeskList:[],//显示桌台数据
            posDeskList:[], //
            deskStatus:1, //桌台状态，0 未开台 1 已开台
            MapConsumeMoney:false
        }
    },
    props:['openischangeShow','posRooms'],
    mounted(){
    },
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        openischangeShow() { 
            this.emptyDatas();
        }
    },
    methods:{
        //每次打开弹窗都是一次新换台，需要清理之前的数据
        emptyDatas(){
            if(this.openischangeShow==true){
                this.posDeskList=[];
                this.deskStatus = 1;
                this.MapConsumeMoney = _data.RunCloudPosHideDeskMapConsumeMoney
                this.navigationClick(-1);
            }
        },
        //获取开台时间和当前时间的时间差
        differenceTime(beginTime) {
            return differenceTime(beginTime);
        },
        //餐厅名称导航条切换
        navigationClick(index) {
            //清空查询条件栏
            if(this.deskAutoSelce.length>0){
                this.deskAutoSelce=''
            }
            this.filTernav = index;
            if(index==-1){
                this.showPosDeskList=this.posRooms._allDesk;
            }else{
                this.showPosDeskList=this.posRooms[index].PosDesks;
            }
        },
        //获取点击的桌台数据,顶部则显示空台数据
        deskChangeClick(desk){
            if(this.deskStatus==1){
                this.deskStatus = 0;
                this.posDeskList=[desk];
            }else{
                if(this.posDeskList.length!=1){return;}
                this.$confirm('是否确认换往'+desk.Desk_Name+'', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                .then(() => {
                    //修改本地数据1.获得已选择开台的桌台,2.获得选择的空桌台,3.开台数据转移到空台
                    let userInfo=this.$auth.getUserInfo(); //获取用户id
                    let param ={
                        User_ID:userInfo?.User_ID,  //用户id
                        Desk_AutoID:this.posDeskList[0].Desk_AutoID, //桌台id
                        ChangeDesk_AutoID:desk.Desk_AutoID, //换往目标桌台的ID
                        Operator_Name:userInfo?.Login_Name,
                    }
                    if(sessionStorage.getItem("tempUserID")){
                        param.User_ID = sessionStorage.getItem("tempUserID")
                        param.Operator_Name = sessionStorage.getItem("tempName")
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    const loading = this.$loading({
                        text: "数据传输中",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    httpAES.post("Bestech.CloudPos.ChangeDesk", param).then((d)=>{
                        loading.close();
                        if(d.ResponseHeader.ResultCode!=0){
                            this.$message.error(d.ResponseHeader.ResultDesc)
                            return;
                        }else {
                            let tempList = this.posDeskList[0].OpenDeskInfo;
                            let setAutoID=this.posDeskList[0].Desk_AutoID;
                            this.$emit("SetChangeDesk",setAutoID,0,tempList,desk.Desk_AutoID,this.posDeskList[0].Desk_Status);
                            this.deskStatus = 1;
                            this.posDeskList = [];
                            this.$message({type: 'success',message: '换台成功'});
                            this.$emit("CloseChangeingClick");
                            return;
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    });
                }).catch(() => {
                    this.$message({type: 'info',message: '换台取消'});          
                });
            }
        },
        //撤销上面选择的数据,清空下面数组数据
        revokeDeskChangeClick(){
            this.deskStatus = 1;
            this.posDeskList = [];
        },
        //关闭弹出框
        closeClicks(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("CloseChangeingClick");
        },
        deteClicks(){
            if(this.deskStatus==0){
                this.$message.warning('请选择需要转移到的桌台号')
                return
            }else{
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
                this.$emit("CloseChangeingClick");
            }
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    },
    computed:{
        deskList(){
            let data=this.showPosDeskList.filter((item)=>{
                if(this.deskStatus==1){
                    if(item.Desk_Status==1){
                        return item
                    }else if(item.Desk_Status==2){
                        return item
                    }else if(item.Desk_Status==3){
                        return item
                    }
                }else{
                    return item.Desk_Status==this.deskStatus;
                }
            });
            if(this.deskAutoSelce!=''){
                let searchTxt=this.deskAutoSelce.toLowerCase();
                return data.filter((item)=>{
                    return (item.Desk_Code||"").toLowerCase().indexOf(searchTxt)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(searchTxt)>=0;
                });
            }
            return data;
        },
    }
}
</script>

<style lang="scss">
@import "./changeing.scss";
</style>