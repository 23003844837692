<!--滑动展开组件-->
<template>
    <transition name='my-transition' v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:after-enter="afterEnter"
            v-on:enter-cancelled="enterCancelled"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
            v-on:after-leave="afterLeave"
            v-on:leave-cancelled="leaveCancelled">
            <slot></slot>
        </transition>
</template>

<script>
    export default {
        props: {
            value: Boolean
        },
        methods: {
            beforeEnter(el){
            el.className=el.className+'my-transition';
            el.style.height = '0';
            },
            enter(el){
                console.log('enter:el:'+JSON.stringify(el.scrollHeight));
                el.style.height = el.scrollHeight + 'px';
            },
            afterEnter(el){
                console.log('afterEnter:el:'+JSON.stringify(el));
                el.className=el.className.replace("my-transition",'');
                el.style.height = '';
            },
            enterCancelled(){
    
            },
            beforeLeave(el){
                console.log('beforeLeave:el:'+el);
                el.style.height = el.scrollHeight + 'px';
            },
            leave(el){
                //let ht=el.scrollHeight;
                el.className=el.className+' my-transition';
                el.style.height = 0;
            },
            afterLeave(el){
                el.className=el.className.replace("my-transition",'');
                el.style.height = '';
            },
            leaveCancelled(){
                
            },
        },
        data () {
            return {
                contentHeight: 0,
                mIsOpen:0,
            }
        }
    }
</script>

<style scoped>
    .my-transition{ transition: 0.2s height ease-in-out/* , 10.5s padding-top ease-in-out, 10.5s padding-bottom ease-in-out*/} 
</style>